.form-group-toggle-variant(@placeholder-font-size, @line-height){
  .togglebutton {
    label {
      font-size: @placeholder-font-size;
      line-height: @line-height;
      font-weight: normal;
    }
  }
}

.form-group {

  // default label size/location
  .form-group-toggle-variant(@md-input-font-size-base, @md-input-line-height-base);

  // sm label size/location
  &.form-group-sm {
    .form-group-toggle-variant(@md-input-font-size-small, @md-input-line-height-small);
  }

  // lg label size/location
  &.form-group-lg {
    .form-group-toggle-variant(@md-input-font-size-large, @md-input-line-height-large);
  }

  .togglebutton {
    vertical-align: middle;
    &, label, input, .toggle {
      user-select: none;
    }
    label {
      cursor: pointer;
      color: @togglebutton-label-color;

      // Hide original checkbox
      input[type=checkbox] {
        opacity: 0;
        width: 0;
        height: 0;
      }
      // Switch bg off and disabled
      .toggle,
      input[type=checkbox][disabled] + .toggle {
        content: "";
        display: inline-block;
        width: 30px;
        height: 15px;
        background-color: rgba(80, 80, 80, 0.7);
        border-radius: 15px;
        margin-right: 10px;
        transition: background 0.3s ease;
        vertical-align: middle;
      }
      // Handle off
      .toggle:after {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        background-color: #F1F1F1;
        border-radius: 20px;
        position: relative;
        box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
        left: -5px;
        top: -2px;
        transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease;
      }
      // Handle disabled
      input[type=checkbox][disabled] + .toggle:after,
      input[type=checkbox][disabled]:checked + .toggle:after {
        background-color: #BDBDBD;
      }
      // Ripple off and disabled
      input[type=checkbox] + .toggle:active:after,
      input[type=checkbox][disabled] + .toggle:active:after {
        box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.1);
      }
      input[type=checkbox]:checked + .toggle:after {
        left: 15px;
      }
    }

    // Switch bg on
    // SASS conversion note: please mirror any content change in _mixins-shared.scss bg-color-variations-content
    .generic-variations(~" label input[type=checkbox]:checked + .toggle", @primary, {
      background-color: fade(@material-color, 50%);
    });
    // Handle on
    // SASS conversion note: please mirror any content change in _mixins-shared.scss variations-content
    .variations(~" label input[type=checkbox]:checked + .toggle:after", background-color, @primary);
    // Ripple on
    .generic-variations(~" label input[type=checkbox]:checked + .toggle:active:after", @primary, {
      box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px fade(@material-color, 10%);
    });
  }
}
