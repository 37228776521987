.form-horizontal .radio {
  margin-bottom: 10px;
}

.form-group-radio-variant(@placeholder-font-size, @line-height){
  .radio {
    label {
      font-size: @placeholder-font-size;
      line-height: @line-height;
      font-weight: normal;
    }
  }
}

.form-group {

  // default label size/location
  .form-group-radio-variant(@md-input-font-size-base, @md-input-line-height-base);

  // sm label size/location
  &.form-group-sm {
    .form-group-radio-variant(@md-input-font-size-small, @md-input-line-height-small);
  }

  // lg label size/location
  &.form-group-lg {
    .form-group-radio-variant(@md-input-font-size-large, @md-input-line-height-large);
  }     
}

.radio {
  label {
    cursor: pointer;
    padding-left: 45px;
    position: relative;
    color: @radio-label-color;

    span {
      display: block;
      position: absolute;
      left: 10px;
      top: 2px;
      transition-duration: 0.2s;
    }
    .circle {
      border: 2px solid @radio-border-color;
      height: 15px;
      width: 15px;
      border-radius: 100%;
    }
    .check {
      height: 15px;
      width: 15px;
      border-radius: 100%;
      background-color: @radio-default;
      transform: scale3d(0, 0, 0);
    }
    .check:after {
      display: block;
      position: absolute;
      content: "";
      background-color: @lightbg-text;
      left: -18px;
      top: -18px;
      height: 50px;
      width: 50px;
      border-radius: 100%;
      z-index: 1;
      opacity: 0;
      margin: 0;
      transform: scale3d(1.5, 1.5, 1);
    }
    input[type=radio]:not(:checked) ~ .check:after {
      animation: rippleOff 500ms;
    }
    input[type=radio]:checked ~ .check:after {
      animation: rippleOn 500ms;
    }

  }
  .variations(~" input[type=radio]:checked ~ .check", background-color, @radio-default);
  .variations(~" input[type=radio]:checked ~ .circle", border-color, @radio-default);

  input[type=radio][disabled] ~ .check,
  input[type=radio][disabled] ~ .circle {
    opacity: 0.5;
  }

  input[type=radio] {
    opacity: 0;
    height: 0;
    width: 0;
    overflow: hidden;
  }
  input[type=radio]:checked ~ .check {
    transform: scale3d(0.55, 0.55, 1);
  }
  input[type=radio][disabled] ~ .circle {
    border-color: @lightbg-text;
  }
  input[type=radio][disabled] ~ .check {
    background-color: @lightbg-text;
  }
}

@keyframes rippleOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}

@keyframes rippleOff {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
