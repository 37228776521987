// Placeholder text
.material-placeholder(@rules) {
  &::-moz-placeholder { @rules(); } // Firefox
  &:-ms-input-placeholder { @rules(); } // Internet Explorer 10+
  &::-webkit-input-placeholder  { @rules(); } // Safari and Chrome
}

// usage: .variations(~" .check", color, transparent);
.variations(@extra, @property, @default) {
  .generic-variations(@extra, @default, {
    @{property}: @material-color;
  });
}

.background-variations(@extra, @default) {
  .generic-variations(@extra, @default, {
    background-color: @material-color;
    & when (@material-color = @btn-default) {
      color: @lightbg-text;
    }
    & when not (@material-color = @btn-default) {
      color: @material-text-color;
    }
  });
}

.text-variations(@extra, @default) {
  .generic-variations(@extra, @default, {
    color: @material-color;
  });
}
