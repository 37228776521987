/*

To get this list of colors inject jQuery at http://www.google.com/design/spec/style/color.html#color-color-palette

Then, run this script to get the list.


(function() {
  var colors = {}, main = {};
  $(".color-group").each(function() {
    var color = $(this).find(".name").text().trim().toLowerCase().replace(" ", "-");
    colors[color] = {};

    $(this).find(".color").not(".main-color").each(function() {
      var shade = $(this).find(".shade").text().trim(),
          hex   = $(this).find(".hex").text().trim();

      colors[color][shade] = hex;
    });
    main[color] = color + "-" + $(this).find(".main-color .shade").text().trim();

  });
  var LESS = "";
  $.each(colors, function(name, shades) {
    LESS += "\n\n";
    $.each(shades, function(shade, hex) {
      LESS += "@" + name + "-" + shade + ": " + hex + ";\n";
    });
    if (main[name]) {
      LESS += "@" + name + ": " + main[name] + ";\n";
    }
  });
  console.log(LESS);
})();


*/
@font-face {
  font-family: 'Material-Design-Icons';
  src: url('../fonts/Material-Design-Icons.eot?3ocs8m');
  src: url('../fonts/Material-Design-Icons.eot?#iefix3ocs8m') format('embedded-opentype'), url('../fonts/Material-Design-Icons.woff?3ocs8m') format('woff'), url('../fonts/Material-Design-Icons.ttf?3ocs8m') format('truetype'), url('../fonts/Material-Design-Icons.svg?3ocs8m#Material-Design-Icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="mdi-"],
[class*="mdi-"] {
  speak: none;
  display: inline-block;
  font: normal normal normal 24px/1 'Material-Design-Icons';
  text-rendering: auto;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}
[class^="mdi-"]:before,
[class*="mdi-"]:before {
  display: inline-block;
  speak: none;
  text-decoration: inherit;
}
[class^="mdi-"].pull-left,
[class*="mdi-"].pull-left {
  margin-right: .3em;
}
[class^="mdi-"].pull-right,
[class*="mdi-"].pull-right {
  margin-left: .3em;
}
[class^="mdi-"].mdi-lg:before,
[class*="mdi-"].mdi-lg:before,
[class^="mdi-"].mdi-lg:after,
[class*="mdi-"].mdi-lg:after {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
[class^="mdi-"].mdi-2x:before,
[class*="mdi-"].mdi-2x:before,
[class^="mdi-"].mdi-2x:after,
[class*="mdi-"].mdi-2x:after {
  font-size: 2em;
}
[class^="mdi-"].mdi-3x:before,
[class*="mdi-"].mdi-3x:before,
[class^="mdi-"].mdi-3x:after,
[class*="mdi-"].mdi-3x:after {
  font-size: 3em;
}
[class^="mdi-"].mdi-4x:before,
[class*="mdi-"].mdi-4x:before,
[class^="mdi-"].mdi-4x:after,
[class*="mdi-"].mdi-4x:after {
  font-size: 4em;
}
[class^="mdi-"].mdi-5x:before,
[class*="mdi-"].mdi-5x:before,
[class^="mdi-"].mdi-5x:after,
[class*="mdi-"].mdi-5x:after {
  font-size: 5em;
}
[class^="mdi-device-signal-cellular-"]:after,
[class^="mdi-device-battery-"]:after,
[class^="mdi-device-battery-charging-"]:after,
[class^="mdi-device-signal-cellular-connected-no-internet-"]:after,
[class^="mdi-device-signal-wifi-"]:after,
[class^="mdi-device-signal-wifi-statusbar-not-connected"]:after,
.mdi-device-network-wifi:after {
  opacity: .3;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  display: inline-block;
  speak: none;
  text-decoration: inherit;
}
[class^="mdi-device-signal-cellular-"]:after {
  content: "\e758";
}
[class^="mdi-device-battery-"]:after {
  content: "\e735";
}
[class^="mdi-device-battery-charging-"]:after {
  content: "\e733";
}
[class^="mdi-device-signal-cellular-connected-no-internet-"]:after {
  content: "\e75d";
}
[class^="mdi-device-signal-wifi-"]:after,
.mdi-device-network-wifi:after {
  content: "\e765";
}
[class^="mdi-device-signal-wifi-statusbasr-not-connected"]:after {
  content: "\e8f7";
}
.mdi-device-signal-cellular-off:after,
.mdi-device-signal-cellular-null:after,
.mdi-device-signal-cellular-no-sim:after,
.mdi-device-signal-wifi-off:after,
.mdi-device-signal-wifi-4-bar:after,
.mdi-device-signal-cellular-4-bar:after,
.mdi-device-battery-alert:after,
.mdi-device-signal-cellular-connected-no-internet-4-bar:after,
.mdi-device-battery-std:after,
.mdi-device-battery-full .mdi-device-battery-unknown:after {
  content: "";
}
.mdi-fw {
  width: 1.28571429em;
  text-align: center;
}
.mdi-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}
.mdi-ul > li {
  position: relative;
}
.mdi-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: 0.14285714em;
  text-align: center;
}
.mdi-li.mdi-lg {
  left: -1.85714286em;
}
.mdi-border {
  padding: .2em .25em .15em;
  border: solid 0.08em #eeeeee;
  border-radius: .1em;
}
.mdi-spin {
  -webkit-animation: mdi-spin 2s infinite linear;
  animation: mdi-spin 2s infinite linear;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}
.mdi-pulse {
  -webkit-animation: mdi-spin 1s steps(8) infinite;
  animation: mdi-spin 1s steps(8) infinite;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}
@-webkit-keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.mdi-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.mdi-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.mdi-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.mdi-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.mdi-flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}
:root .mdi-rotate-90,
:root .mdi-rotate-180,
:root .mdi-rotate-270,
:root .mdi-flip-horizontal,
:root .mdi-flip-vertical {
  filter: none;
}
.mdi-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}
.mdi-stack-1x,
.mdi-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}
.mdi-stack-1x {
  line-height: inherit;
}
.mdi-stack-2x {
  font-size: 2em;
}
.mdi-inverse {
  color: #ffffff;
}
/* Start Icons */
.mdi-action-3d-rotation:before {
  content: "\e600";
}
.mdi-action-accessibility:before {
  content: "\e601";
}
.mdi-action-account-balance-wallet:before {
  content: "\e602";
}
.mdi-action-account-balance:before {
  content: "\e603";
}
.mdi-action-account-box:before {
  content: "\e604";
}
.mdi-action-account-child:before {
  content: "\e605";
}
.mdi-action-account-circle:before {
  content: "\e606";
}
.mdi-action-add-shopping-cart:before {
  content: "\e607";
}
.mdi-action-alarm-add:before {
  content: "\e608";
}
.mdi-action-alarm-off:before {
  content: "\e609";
}
.mdi-action-alarm-on:before {
  content: "\e60a";
}
.mdi-action-alarm:before {
  content: "\e60b";
}
.mdi-action-android:before {
  content: "\e60c";
}
.mdi-action-announcement:before {
  content: "\e60d";
}
.mdi-action-aspect-ratio:before {
  content: "\e60e";
}
.mdi-action-assessment:before {
  content: "\e60f";
}
.mdi-action-assignment-ind:before {
  content: "\e610";
}
.mdi-action-assignment-late:before {
  content: "\e611";
}
.mdi-action-assignment-return:before {
  content: "\e612";
}
.mdi-action-assignment-returned:before {
  content: "\e613";
}
.mdi-action-assignment-turned-in:before {
  content: "\e614";
}
.mdi-action-assignment:before {
  content: "\e615";
}
.mdi-action-autorenew:before {
  content: "\e616";
}
.mdi-action-backup:before {
  content: "\e617";
}
.mdi-action-book:before {
  content: "\e618";
}
.mdi-action-bookmark-outline:before {
  content: "\e619";
}
.mdi-action-bookmark:before {
  content: "\e61a";
}
.mdi-action-bug-report:before {
  content: "\e61b";
}
.mdi-action-cached:before {
  content: "\e61c";
}
.mdi-action-check-circle:before {
  content: "\e61d";
}
.mdi-action-class:before {
  content: "\e61e";
}
.mdi-action-credit-card:before {
  content: "\e61f";
}
.mdi-action-dashboard:before {
  content: "\e620";
}
.mdi-action-delete:before {
  content: "\e621";
}
.mdi-action-description:before {
  content: "\e622";
}
.mdi-action-dns:before {
  content: "\e623";
}
.mdi-action-done-all:before {
  content: "\e624";
}
.mdi-action-done:before {
  content: "\e625";
}
.mdi-action-event:before {
  content: "\e626";
}
.mdi-action-exit-to-app:before {
  content: "\e627";
}
.mdi-action-explore:before {
  content: "\e628";
}
.mdi-action-extension:before {
  content: "\e629";
}
.mdi-action-face-unlock:before {
  content: "\e62a";
}
.mdi-action-favorite-outline:before {
  content: "\e62b";
}
.mdi-action-favorite:before {
  content: "\e62c";
}
.mdi-action-find-in-page:before {
  content: "\e62d";
}
.mdi-action-find-replace:before {
  content: "\e62e";
}
.mdi-action-flip-to-back:before {
  content: "\e62f";
}
.mdi-action-flip-to-front:before {
  content: "\e630";
}
.mdi-action-get-app:before {
  content: "\e631";
}
.mdi-action-grade:before {
  content: "\e632";
}
.mdi-action-group-work:before {
  content: "\e633";
}
.mdi-action-help:before {
  content: "\e634";
}
.mdi-action-highlight-remove:before {
  content: "\e635";
}
.mdi-action-history:before {
  content: "\e636";
}
.mdi-action-home:before {
  content: "\e637";
}
.mdi-action-https:before {
  content: "\e638";
}
.mdi-action-info-outline:before {
  content: "\e639";
}
.mdi-action-info:before {
  content: "\e63a";
}
.mdi-action-input:before {
  content: "\e63b";
}
.mdi-action-invert-colors:before {
  content: "\e63c";
}
.mdi-action-label-outline:before {
  content: "\e63d";
}
.mdi-action-label:before {
  content: "\e63e";
}
.mdi-action-language:before {
  content: "\e63f";
}
.mdi-action-launch:before {
  content: "\e640";
}
.mdi-action-list:before {
  content: "\e641";
}
.mdi-action-lock-open:before {
  content: "\e642";
}
.mdi-action-lock-outline:before {
  content: "\e643";
}
.mdi-action-lock:before {
  content: "\e644";
}
.mdi-action-loyalty:before {
  content: "\e645";
}
.mdi-action-markunread-mailbox:before {
  content: "\e646";
}
.mdi-action-note-add:before {
  content: "\e647";
}
.mdi-action-open-in-browser:before {
  content: "\e648";
}
.mdi-action-open-in-new:before {
  content: "\e649";
}
.mdi-action-open-with:before {
  content: "\e64a";
}
.mdi-action-pageview:before {
  content: "\e64b";
}
.mdi-action-payment:before {
  content: "\e64c";
}
.mdi-action-perm-camera-mic:before {
  content: "\e64d";
}
.mdi-action-perm-contact-cal:before {
  content: "\e64e";
}
.mdi-action-perm-data-setting:before {
  content: "\e64f";
}
.mdi-action-perm-device-info:before {
  content: "\e650";
}
.mdi-action-perm-identity:before {
  content: "\e651";
}
.mdi-action-perm-media:before {
  content: "\e652";
}
.mdi-action-perm-phone-msg:before {
  content: "\e653";
}
.mdi-action-perm-scan-wifi:before {
  content: "\e654";
}
.mdi-action-picture-in-picture:before {
  content: "\e655";
}
.mdi-action-polymer:before {
  content: "\e656";
}
.mdi-action-print:before {
  content: "\e657";
}
.mdi-action-query-builder:before {
  content: "\e658";
}
.mdi-action-question-answer:before {
  content: "\e659";
}
.mdi-action-receipt:before {
  content: "\e65a";
}
.mdi-action-redeem:before {
  content: "\e65b";
}
.mdi-action-reorder:before {
  content: "\e65c";
}
.mdi-action-report-problem:before {
  content: "\e65d";
}
.mdi-action-restore:before {
  content: "\e65e";
}
.mdi-action-room:before {
  content: "\e65f";
}
.mdi-action-schedule:before {
  content: "\e660";
}
.mdi-action-search:before {
  content: "\e661";
}
.mdi-action-settings-applications:before {
  content: "\e662";
}
.mdi-action-settings-backup-restore:before {
  content: "\e663";
}
.mdi-action-settings-bluetooth:before {
  content: "\e664";
}
.mdi-action-settings-cell:before {
  content: "\e665";
}
.mdi-action-settings-display:before {
  content: "\e666";
}
.mdi-action-settings-ethernet:before {
  content: "\e667";
}
.mdi-action-settings-input-antenna:before {
  content: "\e668";
}
.mdi-action-settings-input-component:before {
  content: "\e669";
}
.mdi-action-settings-input-composite:before {
  content: "\e66a";
}
.mdi-action-settings-input-hdmi:before {
  content: "\e66b";
}
.mdi-action-settings-input-svideo:before {
  content: "\e66c";
}
.mdi-action-settings-overscan:before {
  content: "\e66d";
}
.mdi-action-settings-phone:before {
  content: "\e66e";
}
.mdi-action-settings-power:before {
  content: "\e66f";
}
.mdi-action-settings-remote:before {
  content: "\e670";
}
.mdi-action-settings-voice:before {
  content: "\e671";
}
.mdi-action-settings:before {
  content: "\e672";
}
.mdi-action-shop-two:before {
  content: "\e673";
}
.mdi-action-shop:before {
  content: "\e674";
}
.mdi-action-shopping-basket:before {
  content: "\e675";
}
.mdi-action-shopping-cart:before {
  content: "\e676";
}
.mdi-action-speaker-notes:before {
  content: "\e677";
}
.mdi-action-spellcheck:before {
  content: "\e678";
}
.mdi-action-star-rate:before {
  content: "\e679";
}
.mdi-action-stars:before {
  content: "\e67a";
}
.mdi-action-store:before {
  content: "\e67b";
}
.mdi-action-subject:before {
  content: "\e67c";
}
.mdi-action-supervisor-account:before {
  content: "\e67d";
}
.mdi-action-swap-horiz:before {
  content: "\e67e";
}
.mdi-action-swap-vert-circle:before {
  content: "\e67f";
}
.mdi-action-swap-vert:before {
  content: "\e680";
}
.mdi-action-system-update-tv:before {
  content: "\e681";
}
.mdi-action-tab-unselected:before {
  content: "\e682";
}
.mdi-action-tab:before {
  content: "\e683";
}
.mdi-action-theaters:before {
  content: "\e684";
}
.mdi-action-thumb-down:before {
  content: "\e685";
}
.mdi-action-thumb-up:before {
  content: "\e686";
}
.mdi-action-thumbs-up-down:before {
  content: "\e687";
}
.mdi-action-toc:before {
  content: "\e688";
}
.mdi-action-today:before {
  content: "\e689";
}
.mdi-action-track-changes:before {
  content: "\e68a";
}
.mdi-action-translate:before {
  content: "\e68b";
}
.mdi-action-trending-down:before {
  content: "\e68c";
}
.mdi-action-trending-neutral:before {
  content: "\e68d";
}
.mdi-action-trending-up:before {
  content: "\e68e";
}
.mdi-action-turned-in-not:before {
  content: "\e68f";
}
.mdi-action-turned-in:before {
  content: "\e690";
}
.mdi-action-verified-user:before {
  content: "\e691";
}
.mdi-action-view-agenda:before {
  content: "\e692";
}
.mdi-action-view-array:before {
  content: "\e693";
}
.mdi-action-view-carousel:before {
  content: "\e694";
}
.mdi-action-view-column:before {
  content: "\e695";
}
.mdi-action-view-day:before {
  content: "\e696";
}
.mdi-action-view-headline:before {
  content: "\e697";
}
.mdi-action-view-list:before {
  content: "\e698";
}
.mdi-action-view-module:before {
  content: "\e699";
}
.mdi-action-view-quilt:before {
  content: "\e69a";
}
.mdi-action-view-stream:before {
  content: "\e69b";
}
.mdi-action-view-week:before {
  content: "\e69c";
}
.mdi-action-visibility-off:before {
  content: "\e69d";
}
.mdi-action-visibility:before {
  content: "\e69e";
}
.mdi-action-wallet-giftcard:before {
  content: "\e69f";
}
.mdi-action-wallet-membership:before {
  content: "\e6a0";
}
.mdi-action-wallet-travel:before {
  content: "\e6a1";
}
.mdi-action-work:before {
  content: "\e6a2";
}
.mdi-alert-error:before {
  content: "\e6a3";
}
.mdi-alert-warning:before {
  content: "\e6a4";
}
.mdi-av-album:before {
  content: "\e6a5";
}
.mdi-av-closed-caption:before {
  content: "\e6a6";
}
.mdi-av-equalizer:before {
  content: "\e6a7";
}
.mdi-av-explicit:before {
  content: "\e6a8";
}
.mdi-av-fast-forward:before {
  content: "\e6a9";
}
.mdi-av-fast-rewind:before {
  content: "\e6aa";
}
.mdi-av-games:before {
  content: "\e6ab";
}
.mdi-av-hearing:before {
  content: "\e6ac";
}
.mdi-av-high-quality:before {
  content: "\e6ad";
}
.mdi-av-loop:before {
  content: "\e6ae";
}
.mdi-av-mic-none:before {
  content: "\e6af";
}
.mdi-av-mic-off:before {
  content: "\e6b0";
}
.mdi-av-mic:before {
  content: "\e6b1";
}
.mdi-av-movie:before {
  content: "\e6b2";
}
.mdi-av-my-library-add:before {
  content: "\e6b3";
}
.mdi-av-my-library-books:before {
  content: "\e6b4";
}
.mdi-av-my-library-music:before {
  content: "\e6b5";
}
.mdi-av-new-releases:before {
  content: "\e6b6";
}
.mdi-av-not-interested:before {
  content: "\e6b7";
}
.mdi-av-pause-circle-fill:before {
  content: "\e6b8";
}
.mdi-av-pause-circle-outline:before {
  content: "\e6b9";
}
.mdi-av-pause:before {
  content: "\e6ba";
}
.mdi-av-play-arrow:before {
  content: "\e6bb";
}
.mdi-av-play-circle-fill:before {
  content: "\e6bc";
}
.mdi-av-play-circle-outline:before {
  content: "\e6bd";
}
.mdi-av-play-shopping-bag:before {
  content: "\e6be";
}
.mdi-av-playlist-add:before {
  content: "\e6bf";
}
.mdi-av-queue-music:before {
  content: "\e6c0";
}
.mdi-av-queue:before {
  content: "\e6c1";
}
.mdi-av-radio:before {
  content: "\e6c2";
}
.mdi-av-recent-actors:before {
  content: "\e6c3";
}
.mdi-av-repeat-one:before {
  content: "\e6c4";
}
.mdi-av-repeat:before {
  content: "\e6c5";
}
.mdi-av-replay:before {
  content: "\e6c6";
}
.mdi-av-shuffle:before {
  content: "\e6c7";
}
.mdi-av-skip-next:before {
  content: "\e6c8";
}
.mdi-av-skip-previous:before {
  content: "\e6c9";
}
.mdi-av-snooze:before {
  content: "\e6ca";
}
.mdi-av-stop:before {
  content: "\e6cb";
}
.mdi-av-subtitles:before {
  content: "\e6cc";
}
.mdi-av-surround-sound:before {
  content: "\e6cd";
}
.mdi-av-timer:before {
  content: "\e6ce";
}
.mdi-av-video-collection:before {
  content: "\e6cf";
}
.mdi-av-videocam-off:before {
  content: "\e6d0";
}
.mdi-av-videocam:before {
  content: "\e6d1";
}
.mdi-av-volume-down:before {
  content: "\e6d2";
}
.mdi-av-volume-mute:before {
  content: "\e6d3";
}
.mdi-av-volume-off:before {
  content: "\e6d4";
}
.mdi-av-volume-up:before {
  content: "\e6d5";
}
.mdi-av-web:before {
  content: "\e6d6";
}
.mdi-communication-business:before {
  content: "\e6d7";
}
.mdi-communication-call-end:before {
  content: "\e6d8";
}
.mdi-communication-call-made:before {
  content: "\e6d9";
}
.mdi-communication-call-merge:before {
  content: "\e6da";
}
.mdi-communication-call-missed:before {
  content: "\e6db";
}
.mdi-communication-call-received:before {
  content: "\e6dc";
}
.mdi-communication-call-split:before {
  content: "\e6dd";
}
.mdi-communication-call:before {
  content: "\e6de";
}
.mdi-communication-chat:before {
  content: "\e6df";
}
.mdi-communication-clear-all:before {
  content: "\e6e0";
}
.mdi-communication-comment:before {
  content: "\e6e1";
}
.mdi-communication-contacts:before {
  content: "\e6e2";
}
.mdi-communication-dialer-sip:before {
  content: "\e6e3";
}
.mdi-communication-dialpad:before {
  content: "\e6e4";
}
.mdi-communication-dnd-on:before {
  content: "\e6e5";
}
.mdi-communication-email:before {
  content: "\e6e6";
}
.mdi-communication-forum:before {
  content: "\e6e7";
}
.mdi-communication-import-export:before {
  content: "\e6e8";
}
.mdi-communication-invert-colors-off:before {
  content: "\e6e9";
}
.mdi-communication-invert-colors-on:before {
  content: "\e6ea";
}
.mdi-communication-live-help:before {
  content: "\e6eb";
}
.mdi-communication-location-off:before {
  content: "\e6ec";
}
.mdi-communication-location-on:before {
  content: "\e6ed";
}
.mdi-communication-message:before {
  content: "\e6ee";
}
.mdi-communication-messenger:before {
  content: "\e6ef";
}
.mdi-communication-no-sim:before {
  content: "\e6f0";
}
.mdi-communication-phone:before {
  content: "\e6f1";
}
.mdi-communication-portable-wifi-off:before {
  content: "\e6f2";
}
.mdi-communication-quick-contacts-dialer:before {
  content: "\e6f3";
}
.mdi-communication-quick-contacts-mail:before {
  content: "\e6f4";
}
.mdi-communication-ring-volume:before {
  content: "\e6f5";
}
.mdi-communication-stay-current-landscape:before {
  content: "\e6f6";
}
.mdi-communication-stay-current-portrait:before {
  content: "\e6f7";
}
.mdi-communication-stay-primary-landscape:before {
  content: "\e6f8";
}
.mdi-communication-stay-primary-portrait:before {
  content: "\e6f9";
}
.mdi-communication-swap-calls:before {
  content: "\e6fa";
}
.mdi-communication-textsms:before {
  content: "\e6fb";
}
.mdi-communication-voicemail:before {
  content: "\e6fc";
}
.mdi-communication-vpn-key:before {
  content: "\e6fd";
}
.mdi-content-add-box:before {
  content: "\e6fe";
}
.mdi-content-add-circle-outline:before {
  content: "\e6ff";
}
.mdi-content-add-circle:before {
  content: "\e700";
}
.mdi-content-add:before {
  content: "\e701";
}
.mdi-content-archive:before {
  content: "\e702";
}
.mdi-content-backspace:before {
  content: "\e703";
}
.mdi-content-block:before {
  content: "\e704";
}
.mdi-content-clear:before {
  content: "\e705";
}
.mdi-content-content-copy:before {
  content: "\e706";
}
.mdi-content-content-cut:before {
  content: "\e707";
}
.mdi-content-content-paste:before {
  content: "\e708";
}
.mdi-content-create:before {
  content: "\e709";
}
.mdi-content-drafts:before {
  content: "\e70a";
}
.mdi-content-filter-list:before {
  content: "\e70b";
}
.mdi-content-flag:before {
  content: "\e70c";
}
.mdi-content-forward:before {
  content: "\e70d";
}
.mdi-content-gesture:before {
  content: "\e70e";
}
.mdi-content-inbox:before {
  content: "\e70f";
}
.mdi-content-link:before {
  content: "\e710";
}
.mdi-content-mail:before {
  content: "\e711";
}
.mdi-content-markunread:before {
  content: "\e712";
}
.mdi-content-redo:before {
  content: "\e713";
}
.mdi-content-remove-circle-outline:before {
  content: "\e714";
}
.mdi-content-remove-circle:before {
  content: "\e715";
}
.mdi-content-remove:before {
  content: "\e716";
}
.mdi-content-reply-all:before {
  content: "\e717";
}
.mdi-content-reply:before {
  content: "\e718";
}
.mdi-content-report:before {
  content: "\e719";
}
.mdi-content-save:before {
  content: "\e71a";
}
.mdi-content-select-all:before {
  content: "\e71b";
}
.mdi-content-send:before {
  content: "\e71c";
}
.mdi-content-sort:before {
  content: "\e71d";
}
.mdi-content-text-format:before {
  content: "\e71e";
}
.mdi-content-undo:before {
  content: "\e71f";
}
.mdi-editor-attach-file:before {
  content: "\e776";
}
.mdi-editor-attach-money:before {
  content: "\e777";
}
.mdi-editor-border-all:before {
  content: "\e778";
}
.mdi-editor-border-bottom:before {
  content: "\e779";
}
.mdi-editor-border-clear:before {
  content: "\e77a";
}
.mdi-editor-border-color:before {
  content: "\e77b";
}
.mdi-editor-border-horizontal:before {
  content: "\e77c";
}
.mdi-editor-border-inner:before {
  content: "\e77d";
}
.mdi-editor-border-left:before {
  content: "\e77e";
}
.mdi-editor-border-outer:before {
  content: "\e77f";
}
.mdi-editor-border-right:before {
  content: "\e780";
}
.mdi-editor-border-style:before {
  content: "\e781";
}
.mdi-editor-border-top:before {
  content: "\e782";
}
.mdi-editor-border-vertical:before {
  content: "\e783";
}
.mdi-editor-format-align-center:before {
  content: "\e784";
}
.mdi-editor-format-align-justify:before {
  content: "\e785";
}
.mdi-editor-format-align-left:before {
  content: "\e786";
}
.mdi-editor-format-align-right:before {
  content: "\e787";
}
.mdi-editor-format-bold:before {
  content: "\e788";
}
.mdi-editor-format-clear:before {
  content: "\e789";
}
.mdi-editor-format-color-fill:before {
  content: "\e78a";
}
.mdi-editor-format-color-reset:before {
  content: "\e78b";
}
.mdi-editor-format-color-text:before {
  content: "\e78c";
}
.mdi-editor-format-indent-decrease:before {
  content: "\e78d";
}
.mdi-editor-format-indent-increase:before {
  content: "\e78e";
}
.mdi-editor-format-italic:before {
  content: "\e78f";
}
.mdi-editor-format-line-spacing:before {
  content: "\e790";
}
.mdi-editor-format-list-bulleted:before {
  content: "\e791";
}
.mdi-editor-format-list-numbered:before {
  content: "\e792";
}
.mdi-editor-format-paint:before {
  content: "\e793";
}
.mdi-editor-format-quote:before {
  content: "\e794";
}
.mdi-editor-format-size:before {
  content: "\e795";
}
.mdi-editor-format-strikethrough:before {
  content: "\e796";
}
.mdi-editor-format-textdirection-l-to-r:before {
  content: "\e797";
}
.mdi-editor-format-textdirection-r-to-l:before {
  content: "\e798";
}
.mdi-editor-format-underline:before {
  content: "\e799";
}
.mdi-editor-functions:before {
  content: "\e79a";
}
.mdi-editor-insert-chart:before {
  content: "\e79b";
}
.mdi-editor-insert-comment:before {
  content: "\e79c";
}
.mdi-editor-insert-drive-file:before {
  content: "\e79d";
}
.mdi-editor-insert-emoticon:before {
  content: "\e79e";
}
.mdi-editor-insert-invitation:before {
  content: "\e79f";
}
.mdi-editor-insert-link:before {
  content: "\e7a0";
}
.mdi-editor-insert-photo:before {
  content: "\e7a1";
}
.mdi-editor-merge-type:before {
  content: "\e7a2";
}
.mdi-editor-mode-comment:before {
  content: "\e7a3";
}
.mdi-editor-mode-edit:before {
  content: "\e7a4";
}
.mdi-editor-publish:before {
  content: "\e7a5";
}
.mdi-editor-vertical-align-bottom:before {
  content: "\e7a6";
}
.mdi-editor-vertical-align-center:before {
  content: "\e7a7";
}
.mdi-editor-vertical-align-top:before {
  content: "\e7a8";
}
.mdi-editor-wrap-text:before {
  content: "\e7a9";
}
.mdi-file-attachment:before {
  content: "\e7aa";
}
.mdi-file-cloud-circle:before {
  content: "\e7ab";
}
.mdi-file-cloud-done:before {
  content: "\e7ac";
}
.mdi-file-cloud-download:before {
  content: "\e7ad";
}
.mdi-file-cloud-off:before {
  content: "\e7ae";
}
.mdi-file-cloud-queue:before {
  content: "\e7af";
}
.mdi-file-cloud-upload:before {
  content: "\e7b0";
}
.mdi-file-cloud:before {
  content: "\e7b1";
}
.mdi-file-file-download:before {
  content: "\e7b2";
}
.mdi-file-file-upload:before {
  content: "\e7b3";
}
.mdi-file-folder-open:before {
  content: "\e7b4";
}
.mdi-file-folder-shared:before {
  content: "\e7b5";
}
.mdi-file-folder:before {
  content: "\e7b6";
}
.mdi-device-access-alarm:before {
  content: "\e720";
}
.mdi-device-access-alarms:before {
  content: "\e721";
}
.mdi-device-access-time:before {
  content: "\e722";
}
.mdi-device-add-alarm:before {
  content: "\e723";
}
.mdi-device-airplanemode-off:before {
  content: "\e724";
}
.mdi-device-airplanemode-on:before {
  content: "\e725";
}
.mdi-device-battery-20:before {
  content: "\e726";
}
.mdi-device-battery-30:before {
  content: "\e727";
}
.mdi-device-battery-50:before {
  content: "\e728";
}
.mdi-device-battery-60:before {
  content: "\e729";
}
.mdi-device-battery-80:before {
  content: "\e72a";
}
.mdi-device-battery-90:before {
  content: "\e72b";
}
.mdi-device-battery-alert:before {
  content: "\e72c";
}
.mdi-device-battery-charging-20:before {
  content: "\e72d";
}
.mdi-device-battery-charging-30:before {
  content: "\e72e";
}
.mdi-device-battery-charging-50:before {
  content: "\e72f";
}
.mdi-device-battery-charging-60:before {
  content: "\e730";
}
.mdi-device-battery-charging-80:before {
  content: "\e731";
}
.mdi-device-battery-charging-90:before {
  content: "\e732";
}
.mdi-device-battery-charging-full:before {
  content: "\e733";
}
.mdi-device-battery-full:before {
  content: "\e734";
}
.mdi-device-battery-std:before {
  content: "\e735";
}
.mdi-device-battery-unknown:before {
  content: "\e736";
}
.mdi-device-bluetooth-connected:before {
  content: "\e737";
}
.mdi-device-bluetooth-disabled:before {
  content: "\e738";
}
.mdi-device-bluetooth-searching:before {
  content: "\e739";
}
.mdi-device-bluetooth:before {
  content: "\e73a";
}
.mdi-device-brightness-auto:before {
  content: "\e73b";
}
.mdi-device-brightness-high:before {
  content: "\e73c";
}
.mdi-device-brightness-low:before {
  content: "\e73d";
}
.mdi-device-brightness-medium:before {
  content: "\e73e";
}
.mdi-device-data-usage:before {
  content: "\e73f";
}
.mdi-device-developer-mode:before {
  content: "\e740";
}
.mdi-device-devices:before {
  content: "\e741";
}
.mdi-device-dvr:before {
  content: "\e742";
}
.mdi-device-gps-fixed:before {
  content: "\e743";
}
.mdi-device-gps-not-fixed:before {
  content: "\e744";
}
.mdi-device-gps-off:before {
  content: "\e745";
}
.mdi-device-location-disabled:before {
  content: "\e746";
}
.mdi-device-location-searching:before {
  content: "\e747";
}
.mdi-device-multitrack-audio:before {
  content: "\e748";
}
.mdi-device-network-cell:before {
  content: "\e749";
}
.mdi-device-network-wifi:before {
  content: "\e74a";
}
.mdi-device-nfc:before {
  content: "\e74b";
}
.mdi-device-now-wallpaper:before {
  content: "\e74c";
}
.mdi-device-now-widgets:before {
  content: "\e74d";
}
.mdi-device-screen-lock-landscape:before {
  content: "\e74e";
}
.mdi-device-screen-lock-portrait:before {
  content: "\e74f";
}
.mdi-device-screen-lock-rotation:before {
  content: "\e750";
}
.mdi-device-screen-rotation:before {
  content: "\e751";
}
.mdi-device-sd-storage:before {
  content: "\e752";
}
.mdi-device-settings-system-daydream:before {
  content: "\e753";
}
.mdi-device-signal-cellular-0-bar:before {
  content: "\e754";
}
.mdi-device-signal-cellular-1-bar:before {
  content: "\e755";
}
.mdi-device-signal-cellular-2-bar:before {
  content: "\e756";
}
.mdi-device-signal-cellular-3-bar:before {
  content: "\e757";
}
.mdi-device-signal-cellular-4-bar:before {
  content: "\e758";
}
.mdi-signal-wifi-statusbar-connected-no-internet-after:before {
  content: "\e8f6";
}
.mdi-device-signal-cellular-connected-no-internet-0-bar:before {
  content: "\e759";
}
.mdi-device-signal-cellular-connected-no-internet-1-bar:before {
  content: "\e75a";
}
.mdi-device-signal-cellular-connected-no-internet-2-bar:before {
  content: "\e75b";
}
.mdi-device-signal-cellular-connected-no-internet-3-bar:before {
  content: "\e75c";
}
.mdi-device-signal-cellular-connected-no-internet-4-bar:before {
  content: "\e75d";
}
.mdi-device-signal-cellular-no-sim:before {
  content: "\e75e";
}
.mdi-device-signal-cellular-null:before {
  content: "\e75f";
}
.mdi-device-signal-cellular-off:before {
  content: "\e760";
}
.mdi-device-signal-wifi-0-bar:before {
  content: "\e761";
}
.mdi-device-signal-wifi-1-bar:before {
  content: "\e762";
}
.mdi-device-signal-wifi-2-bar:before {
  content: "\e763";
}
.mdi-device-signal-wifi-3-bar:before {
  content: "\e764";
}
.mdi-device-signal-wifi-4-bar:before {
  content: "\e765";
}
.mdi-device-signal-wifi-off:before {
  content: "\e766";
}
.mdi-device-signal-wifi-statusbar-1-bar:before {
  content: "\e767";
}
.mdi-device-signal-wifi-statusbar-2-bar:before {
  content: "\e768";
}
.mdi-device-signal-wifi-statusbar-3-bar:before {
  content: "\e769";
}
.mdi-device-signal-wifi-statusbar-4-bar:before {
  content: "\e76a";
}
.mdi-device-signal-wifi-statusbar-connected-no-internet-:before {
  content: "\e76b";
}
.mdi-device-signal-wifi-statusbar-connected-no-internet:before {
  content: "\e76f";
}
.mdi-device-signal-wifi-statusbar-connected-no-internet-2:before {
  content: "\e76c";
}
.mdi-device-signal-wifi-statusbar-connected-no-internet-3:before {
  content: "\e76d";
}
.mdi-device-signal-wifi-statusbar-connected-no-internet-4:before {
  content: "\e76e";
}
.mdi-signal-wifi-statusbar-not-connected-after:before {
  content: "\e8f7";
}
.mdi-device-signal-wifi-statusbar-not-connected:before {
  content: "\e770";
}
.mdi-device-signal-wifi-statusbar-null:before {
  content: "\e771";
}
.mdi-device-storage:before {
  content: "\e772";
}
.mdi-device-usb:before {
  content: "\e773";
}
.mdi-device-wifi-lock:before {
  content: "\e774";
}
.mdi-device-wifi-tethering:before {
  content: "\e775";
}
.mdi-hardware-cast-connected:before {
  content: "\e7b7";
}
.mdi-hardware-cast:before {
  content: "\e7b8";
}
.mdi-hardware-computer:before {
  content: "\e7b9";
}
.mdi-hardware-desktop-mac:before {
  content: "\e7ba";
}
.mdi-hardware-desktop-windows:before {
  content: "\e7bb";
}
.mdi-hardware-dock:before {
  content: "\e7bc";
}
.mdi-hardware-gamepad:before {
  content: "\e7bd";
}
.mdi-hardware-headset-mic:before {
  content: "\e7be";
}
.mdi-hardware-headset:before {
  content: "\e7bf";
}
.mdi-hardware-keyboard-alt:before {
  content: "\e7c0";
}
.mdi-hardware-keyboard-arrow-down:before {
  content: "\e7c1";
}
.mdi-hardware-keyboard-arrow-left:before {
  content: "\e7c2";
}
.mdi-hardware-keyboard-arrow-right:before {
  content: "\e7c3";
}
.mdi-hardware-keyboard-arrow-up:before {
  content: "\e7c4";
}
.mdi-hardware-keyboard-backspace:before {
  content: "\e7c5";
}
.mdi-hardware-keyboard-capslock:before {
  content: "\e7c6";
}
.mdi-hardware-keyboard-control:before {
  content: "\e7c7";
}
.mdi-hardware-keyboard-hide:before {
  content: "\e7c8";
}
.mdi-hardware-keyboard-return:before {
  content: "\e7c9";
}
.mdi-hardware-keyboard-tab:before {
  content: "\e7ca";
}
.mdi-hardware-keyboard-voice:before {
  content: "\e7cb";
}
.mdi-hardware-keyboard:before {
  content: "\e7cc";
}
.mdi-hardware-laptop-chromebook:before {
  content: "\e7cd";
}
.mdi-hardware-laptop-mac:before {
  content: "\e7ce";
}
.mdi-hardware-laptop-windows:before {
  content: "\e7cf";
}
.mdi-hardware-laptop:before {
  content: "\e7d0";
}
.mdi-hardware-memory:before {
  content: "\e7d1";
}
.mdi-hardware-mouse:before {
  content: "\e7d2";
}
.mdi-hardware-phone-android:before {
  content: "\e7d3";
}
.mdi-hardware-phone-iphone:before {
  content: "\e7d4";
}
.mdi-hardware-phonelink-off:before {
  content: "\e7d5";
}
.mdi-hardware-phonelink:before {
  content: "\e7d6";
}
.mdi-hardware-security:before {
  content: "\e7d7";
}
.mdi-hardware-sim-card:before {
  content: "\e7d8";
}
.mdi-hardware-smartphone:before {
  content: "\e7d9";
}
.mdi-hardware-speaker:before {
  content: "\e7da";
}
.mdi-hardware-tablet-android:before {
  content: "\e7db";
}
.mdi-hardware-tablet-mac:before {
  content: "\e7dc";
}
.mdi-hardware-tablet:before {
  content: "\e7dd";
}
.mdi-hardware-tv:before {
  content: "\e7de";
}
.mdi-hardware-watch:before {
  content: "\e7df";
}
.mdi-image-add-to-photos:before {
  content: "\e7e0";
}
.mdi-image-adjust:before {
  content: "\e7e1";
}
.mdi-image-assistant-photo:before {
  content: "\e7e2";
}
.mdi-image-audiotrack:before {
  content: "\e7e3";
}
.mdi-image-blur-circular:before {
  content: "\e7e4";
}
.mdi-image-blur-linear:before {
  content: "\e7e5";
}
.mdi-image-blur-off:before {
  content: "\e7e6";
}
.mdi-image-blur-on:before {
  content: "\e7e7";
}
.mdi-image-brightness-1:before {
  content: "\e7e8";
}
.mdi-image-brightness-2:before {
  content: "\e7e9";
}
.mdi-image-brightness-3:before {
  content: "\e7ea";
}
.mdi-image-brightness-4:before {
  content: "\e7eb";
}
.mdi-image-brightness-5:before {
  content: "\e7ec";
}
.mdi-image-brightness-6:before {
  content: "\e7ed";
}
.mdi-image-brightness-7:before {
  content: "\e7ee";
}
.mdi-image-brush:before {
  content: "\e7ef";
}
.mdi-image-camera-alt:before {
  content: "\e7f0";
}
.mdi-image-camera-front:before {
  content: "\e7f1";
}
.mdi-image-camera-rear:before {
  content: "\e7f2";
}
.mdi-image-camera-roll:before {
  content: "\e7f3";
}
.mdi-image-camera:before {
  content: "\e7f4";
}
.mdi-image-center-focus-strong:before {
  content: "\e7f5";
}
.mdi-image-center-focus-weak:before {
  content: "\e7f6";
}
.mdi-image-collections:before {
  content: "\e7f7";
}
.mdi-image-color-lens:before {
  content: "\e7f8";
}
.mdi-image-colorize:before {
  content: "\e7f9";
}
.mdi-image-compare:before {
  content: "\e7fa";
}
.mdi-image-control-point-duplicate:before {
  content: "\e7fb";
}
.mdi-image-control-point:before {
  content: "\e7fc";
}
.mdi-image-crop-3-2:before {
  content: "\e7fd";
}
.mdi-image-crop-5-4:before {
  content: "\e7fe";
}
.mdi-image-crop-7-5:before {
  content: "\e7ff";
}
.mdi-image-crop-16-9:before {
  content: "\e800";
}
.mdi-image-crop-din:before {
  content: "\e801";
}
.mdi-image-crop-free:before {
  content: "\e802";
}
.mdi-image-crop-landscape:before {
  content: "\e803";
}
.mdi-image-crop-original:before {
  content: "\e804";
}
.mdi-image-crop-portrait:before {
  content: "\e805";
}
.mdi-image-crop-square:before {
  content: "\e806";
}
.mdi-image-crop:before {
  content: "\e807";
}
.mdi-image-dehaze:before {
  content: "\e808";
}
.mdi-image-details:before {
  content: "\e809";
}
.mdi-image-edit:before {
  content: "\e80a";
}
.mdi-image-exposure-minus-1:before {
  content: "\e80b";
}
.mdi-image-exposure-minus-2:before {
  content: "\e80c";
}
.mdi-image-exposure-plus-1:before {
  content: "\e80d";
}
.mdi-image-exposure-plus-2:before {
  content: "\e80e";
}
.mdi-image-exposure-zero:before {
  content: "\e80f";
}
.mdi-image-exposure:before {
  content: "\e810";
}
.mdi-image-filter-1:before {
  content: "\e811";
}
.mdi-image-filter-2:before {
  content: "\e812";
}
.mdi-image-filter-3:before {
  content: "\e813";
}
.mdi-image-filter-4:before {
  content: "\e814";
}
.mdi-image-filter-5:before {
  content: "\e815";
}
.mdi-image-filter-6:before {
  content: "\e816";
}
.mdi-image-filter-7:before {
  content: "\e817";
}
.mdi-image-filter-8:before {
  content: "\e818";
}
.mdi-image-filter-9-plus:before {
  content: "\e819";
}
.mdi-image-filter-9:before {
  content: "\e81a";
}
.mdi-image-filter-b-and-w:before {
  content: "\e81b";
}
.mdi-image-filter-center-focus:before {
  content: "\e81c";
}
.mdi-image-filter-drama:before {
  content: "\e81d";
}
.mdi-image-filter-frames:before {
  content: "\e81e";
}
.mdi-image-filter-hdr:before {
  content: "\e81f";
}
.mdi-image-filter-none:before {
  content: "\e820";
}
.mdi-image-filter-tilt-shift:before {
  content: "\e821";
}
.mdi-image-filter-vintage:before {
  content: "\e822";
}
.mdi-image-filter:before {
  content: "\e823";
}
.mdi-image-flare:before {
  content: "\e824";
}
.mdi-image-flash-auto:before {
  content: "\e825";
}
.mdi-image-flash-off:before {
  content: "\e826";
}
.mdi-image-flash-on:before {
  content: "\e827";
}
.mdi-image-flip:before {
  content: "\e828";
}
.mdi-image-gradient:before {
  content: "\e829";
}
.mdi-image-grain:before {
  content: "\e82a";
}
.mdi-image-grid-off:before {
  content: "\e82b";
}
.mdi-image-grid-on:before {
  content: "\e82c";
}
.mdi-image-hdr-off:before {
  content: "\e82d";
}
.mdi-image-hdr-on:before {
  content: "\e82e";
}
.mdi-image-hdr-strong:before {
  content: "\e82f";
}
.mdi-image-hdr-weak:before {
  content: "\e830";
}
.mdi-image-healing:before {
  content: "\e831";
}
.mdi-image-image-aspect-ratio:before {
  content: "\e832";
}
.mdi-image-image:before {
  content: "\e833";
}
.mdi-image-iso:before {
  content: "\e834";
}
.mdi-image-landscape:before {
  content: "\e835";
}
.mdi-image-leak-add:before {
  content: "\e836";
}
.mdi-image-leak-remove:before {
  content: "\e837";
}
.mdi-image-lens:before {
  content: "\e838";
}
.mdi-image-looks-3:before {
  content: "\e839";
}
.mdi-image-looks-4:before {
  content: "\e83a";
}
.mdi-image-looks-5:before {
  content: "\e83b";
}
.mdi-image-looks-6:before {
  content: "\e83c";
}
.mdi-image-looks-one:before {
  content: "\e83d";
}
.mdi-image-looks-two:before {
  content: "\e83e";
}
.mdi-image-looks:before {
  content: "\e83f";
}
.mdi-image-loupe:before {
  content: "\e840";
}
.mdi-image-movie-creation:before {
  content: "\e841";
}
.mdi-image-nature-people:before {
  content: "\e842";
}
.mdi-image-nature:before {
  content: "\e843";
}
.mdi-image-navigate-before:before {
  content: "\e844";
}
.mdi-image-navigate-next:before {
  content: "\e845";
}
.mdi-image-palette:before {
  content: "\e846";
}
.mdi-image-panorama-fisheye:before {
  content: "\e847";
}
.mdi-image-panorama-horizontal:before {
  content: "\e848";
}
.mdi-image-panorama-vertical:before {
  content: "\e849";
}
.mdi-image-panorama-wide-angle:before {
  content: "\e84a";
}
.mdi-image-panorama:before {
  content: "\e84b";
}
.mdi-image-photo-album:before {
  content: "\e84c";
}
.mdi-image-photo-camera:before {
  content: "\e84d";
}
.mdi-image-photo-library:before {
  content: "\e84e";
}
.mdi-image-photo:before {
  content: "\e84f";
}
.mdi-image-portrait:before {
  content: "\e850";
}
.mdi-image-remove-red-eye:before {
  content: "\e851";
}
.mdi-image-rotate-left:before {
  content: "\e852";
}
.mdi-image-rotate-right:before {
  content: "\e853";
}
.mdi-image-slideshow:before {
  content: "\e854";
}
.mdi-image-straighten:before {
  content: "\e855";
}
.mdi-image-style:before {
  content: "\e856";
}
.mdi-image-switch-camera:before {
  content: "\e857";
}
.mdi-image-switch-video:before {
  content: "\e858";
}
.mdi-image-tag-faces:before {
  content: "\e859";
}
.mdi-image-texture:before {
  content: "\e85a";
}
.mdi-image-timelapse:before {
  content: "\e85b";
}
.mdi-image-timer-3:before {
  content: "\e85c";
}
.mdi-image-timer-10:before {
  content: "\e85d";
}
.mdi-image-timer-auto:before {
  content: "\e85e";
}
.mdi-image-timer-off:before {
  content: "\e85f";
}
.mdi-image-timer:before {
  content: "\e860";
}
.mdi-image-tonality:before {
  content: "\e861";
}
.mdi-image-transform:before {
  content: "\e862";
}
.mdi-image-tune:before {
  content: "\e863";
}
.mdi-image-wb-auto:before {
  content: "\e864";
}
.mdi-image-wb-cloudy:before {
  content: "\e865";
}
.mdi-image-wb-incandescent:before {
  content: "\e866";
}
.mdi-image-wb-irradescent:before {
  content: "\e867";
}
.mdi-image-wb-sunny:before {
  content: "\e868";
}
.mdi-maps-beenhere:before {
  content: "\e869";
}
.mdi-maps-directions-bike:before {
  content: "\e86a";
}
.mdi-maps-directions-bus:before {
  content: "\e86b";
}
.mdi-maps-directions-car:before {
  content: "\e86c";
}
.mdi-maps-directions-ferry:before {
  content: "\e86d";
}
.mdi-maps-directions-subway:before {
  content: "\e86e";
}
.mdi-maps-directions-train:before {
  content: "\e86f";
}
.mdi-maps-directions-transit:before {
  content: "\e870";
}
.mdi-maps-directions-walk:before {
  content: "\e871";
}
.mdi-maps-directions:before {
  content: "\e872";
}
.mdi-maps-flight:before {
  content: "\e873";
}
.mdi-maps-hotel:before {
  content: "\e874";
}
.mdi-maps-layers-clear:before {
  content: "\e875";
}
.mdi-maps-layers:before {
  content: "\e876";
}
.mdi-maps-local-airport:before {
  content: "\e877";
}
.mdi-maps-local-atm:before {
  content: "\e878";
}
.mdi-maps-local-attraction:before {
  content: "\e879";
}
.mdi-maps-local-bar:before {
  content: "\e87a";
}
.mdi-maps-local-cafe:before {
  content: "\e87b";
}
.mdi-maps-local-car-wash:before {
  content: "\e87c";
}
.mdi-maps-local-convenience-store:before {
  content: "\e87d";
}
.mdi-maps-local-drink:before {
  content: "\e87e";
}
.mdi-maps-local-florist:before {
  content: "\e87f";
}
.mdi-maps-local-gas-station:before {
  content: "\e880";
}
.mdi-maps-local-grocery-store:before {
  content: "\e881";
}
.mdi-maps-local-hospital:before {
  content: "\e882";
}
.mdi-maps-local-hotel:before {
  content: "\e883";
}
.mdi-maps-local-laundry-service:before {
  content: "\e884";
}
.mdi-maps-local-library:before {
  content: "\e885";
}
.mdi-maps-local-mall:before {
  content: "\e886";
}
.mdi-maps-local-movies:before {
  content: "\e887";
}
.mdi-maps-local-offer:before {
  content: "\e888";
}
.mdi-maps-local-parking:before {
  content: "\e889";
}
.mdi-maps-local-pharmacy:before {
  content: "\e88a";
}
.mdi-maps-local-phone:before {
  content: "\e88b";
}
.mdi-maps-local-pizza:before {
  content: "\e88c";
}
.mdi-maps-local-play:before {
  content: "\e88d";
}
.mdi-maps-local-post-office:before {
  content: "\e88e";
}
.mdi-maps-local-print-shop:before {
  content: "\e88f";
}
.mdi-maps-local-restaurant:before {
  content: "\e890";
}
.mdi-maps-local-see:before {
  content: "\e891";
}
.mdi-maps-local-shipping:before {
  content: "\e892";
}
.mdi-maps-local-taxi:before {
  content: "\e893";
}
.mdi-maps-location-history:before {
  content: "\e894";
}
.mdi-maps-map:before {
  content: "\e895";
}
.mdi-maps-my-location:before {
  content: "\e896";
}
.mdi-maps-navigation:before {
  content: "\e897";
}
.mdi-maps-pin-drop:before {
  content: "\e898";
}
.mdi-maps-place:before {
  content: "\e899";
}
.mdi-maps-rate-review:before {
  content: "\e89a";
}
.mdi-maps-restaurant-menu:before {
  content: "\e89b";
}
.mdi-maps-satellite:before {
  content: "\e89c";
}
.mdi-maps-store-mall-directory:before {
  content: "\e89d";
}
.mdi-maps-terrain:before {
  content: "\e89e";
}
.mdi-maps-traffic:before {
  content: "\e89f";
}
.mdi-navigation-apps:before {
  content: "\e8a0";
}
.mdi-navigation-arrow-back:before {
  content: "\e8a1";
}
.mdi-navigation-arrow-drop-down-circle:before {
  content: "\e8a2";
}
.mdi-navigation-arrow-drop-down:before {
  content: "\e8a3";
}
.mdi-navigation-arrow-drop-up:before {
  content: "\e8a4";
}
.mdi-navigation-arrow-forward:before {
  content: "\e8a5";
}
.mdi-navigation-cancel:before {
  content: "\e8a6";
}
.mdi-navigation-check:before {
  content: "\e8a7";
}
.mdi-navigation-chevron-left:before {
  content: "\e8a8";
}
.mdi-navigation-chevron-right:before {
  content: "\e8a9";
}
.mdi-navigation-close:before {
  content: "\e8aa";
}
.mdi-navigation-expand-less:before {
  content: "\e8ab";
}
.mdi-navigation-expand-more:before {
  content: "\e8ac";
}
.mdi-navigation-fullscreen-exit:before {
  content: "\e8ad";
}
.mdi-navigation-fullscreen:before {
  content: "\e8ae";
}
.mdi-navigation-menu:before {
  content: "\e8af";
}
.mdi-navigation-more-horiz:before {
  content: "\e8b0";
}
.mdi-navigation-more-vert:before {
  content: "\e8b1";
}
.mdi-navigation-refresh:before {
  content: "\e8b2";
}
.mdi-navigation-unfold-less:before {
  content: "\e8b3";
}
.mdi-navigation-unfold-more:before {
  content: "\e8b4";
}
.mdi-notification-adb:before {
  content: "\e8b5";
}
.mdi-notification-bluetooth-audio:before {
  content: "\e8b6";
}
.mdi-notification-disc-full:before {
  content: "\e8b7";
}
.mdi-notification-dnd-forwardslash:before {
  content: "\e8b8";
}
.mdi-notification-do-not-disturb:before {
  content: "\e8b9";
}
.mdi-notification-drive-eta:before {
  content: "\e8ba";
}
.mdi-notification-event-available:before {
  content: "\e8bb";
}
.mdi-notification-event-busy:before {
  content: "\e8bc";
}
.mdi-notification-event-note:before {
  content: "\e8bd";
}
.mdi-notification-folder-special:before {
  content: "\e8be";
}
.mdi-notification-mms:before {
  content: "\e8bf";
}
.mdi-notification-more:before {
  content: "\e8c0";
}
.mdi-notification-network-locked:before {
  content: "\e8c1";
}
.mdi-notification-phone-bluetooth-speaker:before {
  content: "\e8c2";
}
.mdi-notification-phone-forwarded:before {
  content: "\e8c3";
}
.mdi-notification-phone-in-talk:before {
  content: "\e8c4";
}
.mdi-notification-phone-locked:before {
  content: "\e8c5";
}
.mdi-notification-phone-missed:before {
  content: "\e8c6";
}
.mdi-notification-phone-paused:before {
  content: "\e8c7";
}
.mdi-notification-play-download:before {
  content: "\e8c8";
}
.mdi-notification-play-install:before {
  content: "\e8c9";
}
.mdi-notification-sd-card:before {
  content: "\e8ca";
}
.mdi-notification-sim-card-alert:before {
  content: "\e8cb";
}
.mdi-notification-sms-failed:before {
  content: "\e8cc";
}
.mdi-notification-sms:before {
  content: "\e8cd";
}
.mdi-notification-sync-disabled:before {
  content: "\e8ce";
}
.mdi-notification-sync-problem:before {
  content: "\e8cf";
}
.mdi-notification-sync:before {
  content: "\e8d0";
}
.mdi-notification-system-update:before {
  content: "\e8d1";
}
.mdi-notification-tap-and-play:before {
  content: "\e8d2";
}
.mdi-notification-time-to-leave:before {
  content: "\e8d3";
}
.mdi-notification-vibration:before {
  content: "\e8d4";
}
.mdi-notification-voice-chat:before {
  content: "\e8d5";
}
.mdi-notification-vpn-lock:before {
  content: "\e8d6";
}
.mdi-social-cake:before {
  content: "\e8d7";
}
.mdi-social-domain:before {
  content: "\e8d8";
}
.mdi-social-group-add:before {
  content: "\e8d9";
}
.mdi-social-group:before {
  content: "\e8da";
}
.mdi-social-location-city:before {
  content: "\e8db";
}
.mdi-social-mood:before {
  content: "\e8dc";
}
.mdi-social-notifications-none:before {
  content: "\e8dd";
}
.mdi-social-notifications-off:before {
  content: "\e8de";
}
.mdi-social-notifications-on:before {
  content: "\e8df";
}
.mdi-social-notifications-paused:before {
  content: "\e8e0";
}
.mdi-social-notifications:before {
  content: "\e8e1";
}
.mdi-social-pages:before {
  content: "\e8e2";
}
.mdi-social-party-mode:before {
  content: "\e8e3";
}
.mdi-social-people-outline:before {
  content: "\e8e4";
}
.mdi-social-people:before {
  content: "\e8e5";
}
.mdi-social-person-add:before {
  content: "\e8e6";
}
.mdi-social-person-outline:before {
  content: "\e8e7";
}
.mdi-social-person:before {
  content: "\e8e8";
}
.mdi-social-plus-one:before {
  content: "\e8e9";
}
.mdi-social-poll:before {
  content: "\e8ea";
}
.mdi-social-public:before {
  content: "\e8eb";
}
.mdi-social-school:before {
  content: "\e8ec";
}
.mdi-social-share:before {
  content: "\e8ed";
}
.mdi-social-whatshot:before {
  content: "\e8ee";
}
.mdi-toggle-check-box-outline-blank:before {
  content: "\e8ef";
}
.mdi-toggle-check-box:before {
  content: "\e8f0";
}
.mdi-toggle-radio-button-off:before {
  content: "\e8f1";
}
.mdi-toggle-radio-button-on:before {
  content: "\e8f2";
}
.mdi-toggle-star-half:before {
  content: "\e8f3";
}
.mdi-toggle-star-outline:before {
  content: "\e8f4";
}
.mdi-toggle-star:before {
  content: "\e8f5";
}
.shadow-z-1 {
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
}
.shadow-z-1-hover {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.shadow-z-2 {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.shadow-z-3 {
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.shadow-z-4 {
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
}
.shadow-z-5 {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
}
body {
  background-color: #EEEEEE;
}
body.inverse {
  background: #333333;
}
body.inverse,
body.inverse .form-control {
  color: rgba(255, 255, 255, 0.84);
}
body.inverse .modal,
body.inverse .panel-default,
body.inverse .card,
body.inverse .modal .form-control,
body.inverse .panel-default .form-control,
body.inverse .card .form-control {
  background-color: initial;
  color: initial;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4 {
  font-family: "RobotoDraft", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
}
h5,
h6 {
  font-weight: 400;
}
a,
a:hover,
a:focus {
  color: #009688;
}
body .well.well-sm,
.container .well.well-sm,
.container-fluid .well.well-sm {
  padding: 10px;
}
body .well.well-lg,
.container .well.well-lg,
.container-fluid .well.well-lg {
  padding: 26px;
}
body .well,
.container .well,
.container-fluid .well,
body .jumbotron,
.container .jumbotron,
.container-fluid .jumbotron {
  background-color: #fff;
  padding: 19px;
  margin-bottom: 20px;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 2px;
  border: 0;
}
body .well p,
.container .well p,
.container-fluid .well p,
body .jumbotron p,
.container .jumbotron p,
.container-fluid .jumbotron p {
  font-weight: 300;
}
body .well,
.container .well,
.container-fluid .well,
body .jumbotron,
.container .jumbotron,
.container-fluid .jumbotron,
body .well-default,
.container .well-default,
.container-fluid .well-default,
body .jumbotron-default,
.container .jumbotron-default,
.container-fluid .jumbotron-default {
  background-color: #FFF;
}
body .well-black,
.container .well-black,
.container-fluid .well-black,
body .jumbotron-black,
.container .jumbotron-black,
.container-fluid .jumbotron-black {
  background-color: #000000;
}
body .well-white,
.container .well-white,
.container-fluid .well-white,
body .jumbotron-white,
.container .jumbotron-white,
.container-fluid .jumbotron-white {
  background-color: #ffffff;
}
body .well-inverse,
.container .well-inverse,
.container-fluid .well-inverse,
body .jumbotron-inverse,
.container .jumbotron-inverse,
.container-fluid .jumbotron-inverse {
  background-color: #3f51b5;
}
body .well-primary,
.container .well-primary,
.container-fluid .well-primary,
body .jumbotron-primary,
.container .jumbotron-primary,
.container-fluid .jumbotron-primary {
  background-color: #009688;
}
body .well-success,
.container .well-success,
.container-fluid .well-success,
body .jumbotron-success,
.container .jumbotron-success,
.container-fluid .jumbotron-success {
  background-color: #4caf50;
}
body .well-info,
.container .well-info,
.container-fluid .well-info,
body .jumbotron-info,
.container .jumbotron-info,
.container-fluid .jumbotron-info {
  background-color: #03a9f4;
}
body .well-warning,
.container .well-warning,
.container-fluid .well-warning,
body .jumbotron-warning,
.container .jumbotron-warning,
.container-fluid .jumbotron-warning {
  background-color: #ff5722;
}
body .well-danger,
.container .well-danger,
.container-fluid .well-danger,
body .jumbotron-danger,
.container .jumbotron-danger,
.container-fluid .jumbotron-danger {
  background-color: #f44336;
}
body .well-material-red,
.container .well-material-red,
.container-fluid .well-material-red,
body .jumbotron-material-red,
.container .jumbotron-material-red,
.container-fluid .jumbotron-material-red {
  background-color: #f44336;
}
body .well-material-red-50,
.container .well-material-red-50,
.container-fluid .well-material-red-50,
body .jumbotron-material-red-50,
.container .jumbotron-material-red-50,
.container-fluid .jumbotron-material-red-50 {
  background-color: #ffebee;
}
body .well-material-red-100,
.container .well-material-red-100,
.container-fluid .well-material-red-100,
body .jumbotron-material-red-100,
.container .jumbotron-material-red-100,
.container-fluid .jumbotron-material-red-100 {
  background-color: #ffcdd2;
}
body .well-material-red-200,
.container .well-material-red-200,
.container-fluid .well-material-red-200,
body .jumbotron-material-red-200,
.container .jumbotron-material-red-200,
.container-fluid .jumbotron-material-red-200 {
  background-color: #ef9a9a;
}
body .well-material-red-300,
.container .well-material-red-300,
.container-fluid .well-material-red-300,
body .jumbotron-material-red-300,
.container .jumbotron-material-red-300,
.container-fluid .jumbotron-material-red-300 {
  background-color: #e57373;
}
body .well-material-red-400,
.container .well-material-red-400,
.container-fluid .well-material-red-400,
body .jumbotron-material-red-400,
.container .jumbotron-material-red-400,
.container-fluid .jumbotron-material-red-400 {
  background-color: #ef5350;
}
body .well-material-red-500,
.container .well-material-red-500,
.container-fluid .well-material-red-500,
body .jumbotron-material-red-500,
.container .jumbotron-material-red-500,
.container-fluid .jumbotron-material-red-500 {
  background-color: #f44336;
}
body .well-material-red-600,
.container .well-material-red-600,
.container-fluid .well-material-red-600,
body .jumbotron-material-red-600,
.container .jumbotron-material-red-600,
.container-fluid .jumbotron-material-red-600 {
  background-color: #e53935;
}
body .well-material-red-700,
.container .well-material-red-700,
.container-fluid .well-material-red-700,
body .jumbotron-material-red-700,
.container .jumbotron-material-red-700,
.container-fluid .jumbotron-material-red-700 {
  background-color: #d32f2f;
}
body .well-material-red-800,
.container .well-material-red-800,
.container-fluid .well-material-red-800,
body .jumbotron-material-red-800,
.container .jumbotron-material-red-800,
.container-fluid .jumbotron-material-red-800 {
  background-color: #c62828;
}
body .well-material-red-900,
.container .well-material-red-900,
.container-fluid .well-material-red-900,
body .jumbotron-material-red-900,
.container .jumbotron-material-red-900,
.container-fluid .jumbotron-material-red-900 {
  background-color: #b71c1c;
}
body .well-material-red-A100,
.container .well-material-red-A100,
.container-fluid .well-material-red-A100,
body .jumbotron-material-red-A100,
.container .jumbotron-material-red-A100,
.container-fluid .jumbotron-material-red-A100 {
  background-color: #ff8a80;
}
body .well-material-red-A200,
.container .well-material-red-A200,
.container-fluid .well-material-red-A200,
body .jumbotron-material-red-A200,
.container .jumbotron-material-red-A200,
.container-fluid .jumbotron-material-red-A200 {
  background-color: #ff5252;
}
body .well-material-red-A400,
.container .well-material-red-A400,
.container-fluid .well-material-red-A400,
body .jumbotron-material-red-A400,
.container .jumbotron-material-red-A400,
.container-fluid .jumbotron-material-red-A400 {
  background-color: #ff1744;
}
body .well-material-red-A700,
.container .well-material-red-A700,
.container-fluid .well-material-red-A700,
body .jumbotron-material-red-A700,
.container .jumbotron-material-red-A700,
.container-fluid .jumbotron-material-red-A700 {
  background-color: #d50000;
}
body .well-material-pink,
.container .well-material-pink,
.container-fluid .well-material-pink,
body .jumbotron-material-pink,
.container .jumbotron-material-pink,
.container-fluid .jumbotron-material-pink {
  background-color: #e91e63;
}
body .well-material-pink-50,
.container .well-material-pink-50,
.container-fluid .well-material-pink-50,
body .jumbotron-material-pink-50,
.container .jumbotron-material-pink-50,
.container-fluid .jumbotron-material-pink-50 {
  background-color: #fce4ec;
}
body .well-material-pink-100,
.container .well-material-pink-100,
.container-fluid .well-material-pink-100,
body .jumbotron-material-pink-100,
.container .jumbotron-material-pink-100,
.container-fluid .jumbotron-material-pink-100 {
  background-color: #f8bbd0;
}
body .well-material-pink-200,
.container .well-material-pink-200,
.container-fluid .well-material-pink-200,
body .jumbotron-material-pink-200,
.container .jumbotron-material-pink-200,
.container-fluid .jumbotron-material-pink-200 {
  background-color: #f48fb1;
}
body .well-material-pink-300,
.container .well-material-pink-300,
.container-fluid .well-material-pink-300,
body .jumbotron-material-pink-300,
.container .jumbotron-material-pink-300,
.container-fluid .jumbotron-material-pink-300 {
  background-color: #f06292;
}
body .well-material-pink-400,
.container .well-material-pink-400,
.container-fluid .well-material-pink-400,
body .jumbotron-material-pink-400,
.container .jumbotron-material-pink-400,
.container-fluid .jumbotron-material-pink-400 {
  background-color: #ec407a;
}
body .well-material-pink-500,
.container .well-material-pink-500,
.container-fluid .well-material-pink-500,
body .jumbotron-material-pink-500,
.container .jumbotron-material-pink-500,
.container-fluid .jumbotron-material-pink-500 {
  background-color: #e91e63;
}
body .well-material-pink-600,
.container .well-material-pink-600,
.container-fluid .well-material-pink-600,
body .jumbotron-material-pink-600,
.container .jumbotron-material-pink-600,
.container-fluid .jumbotron-material-pink-600 {
  background-color: #d81b60;
}
body .well-material-pink-700,
.container .well-material-pink-700,
.container-fluid .well-material-pink-700,
body .jumbotron-material-pink-700,
.container .jumbotron-material-pink-700,
.container-fluid .jumbotron-material-pink-700 {
  background-color: #c2185b;
}
body .well-material-pink-800,
.container .well-material-pink-800,
.container-fluid .well-material-pink-800,
body .jumbotron-material-pink-800,
.container .jumbotron-material-pink-800,
.container-fluid .jumbotron-material-pink-800 {
  background-color: #ad1457;
}
body .well-material-pink-900,
.container .well-material-pink-900,
.container-fluid .well-material-pink-900,
body .jumbotron-material-pink-900,
.container .jumbotron-material-pink-900,
.container-fluid .jumbotron-material-pink-900 {
  background-color: #880e4f;
}
body .well-material-pink-A100,
.container .well-material-pink-A100,
.container-fluid .well-material-pink-A100,
body .jumbotron-material-pink-A100,
.container .jumbotron-material-pink-A100,
.container-fluid .jumbotron-material-pink-A100 {
  background-color: #ff80ab;
}
body .well-material-pink-A200,
.container .well-material-pink-A200,
.container-fluid .well-material-pink-A200,
body .jumbotron-material-pink-A200,
.container .jumbotron-material-pink-A200,
.container-fluid .jumbotron-material-pink-A200 {
  background-color: #ff4081;
}
body .well-material-pink-A400,
.container .well-material-pink-A400,
.container-fluid .well-material-pink-A400,
body .jumbotron-material-pink-A400,
.container .jumbotron-material-pink-A400,
.container-fluid .jumbotron-material-pink-A400 {
  background-color: #f50057;
}
body .well-material-pink-A700,
.container .well-material-pink-A700,
.container-fluid .well-material-pink-A700,
body .jumbotron-material-pink-A700,
.container .jumbotron-material-pink-A700,
.container-fluid .jumbotron-material-pink-A700 {
  background-color: #c51162;
}
body .well-material-purple,
.container .well-material-purple,
.container-fluid .well-material-purple,
body .jumbotron-material-purple,
.container .jumbotron-material-purple,
.container-fluid .jumbotron-material-purple {
  background-color: #9c27b0;
}
body .well-material-purple-50,
.container .well-material-purple-50,
.container-fluid .well-material-purple-50,
body .jumbotron-material-purple-50,
.container .jumbotron-material-purple-50,
.container-fluid .jumbotron-material-purple-50 {
  background-color: #f3e5f5;
}
body .well-material-purple-100,
.container .well-material-purple-100,
.container-fluid .well-material-purple-100,
body .jumbotron-material-purple-100,
.container .jumbotron-material-purple-100,
.container-fluid .jumbotron-material-purple-100 {
  background-color: #e1bee7;
}
body .well-material-purple-200,
.container .well-material-purple-200,
.container-fluid .well-material-purple-200,
body .jumbotron-material-purple-200,
.container .jumbotron-material-purple-200,
.container-fluid .jumbotron-material-purple-200 {
  background-color: #ce93d8;
}
body .well-material-purple-300,
.container .well-material-purple-300,
.container-fluid .well-material-purple-300,
body .jumbotron-material-purple-300,
.container .jumbotron-material-purple-300,
.container-fluid .jumbotron-material-purple-300 {
  background-color: #ba68c8;
}
body .well-material-purple-400,
.container .well-material-purple-400,
.container-fluid .well-material-purple-400,
body .jumbotron-material-purple-400,
.container .jumbotron-material-purple-400,
.container-fluid .jumbotron-material-purple-400 {
  background-color: #ab47bc;
}
body .well-material-purple-500,
.container .well-material-purple-500,
.container-fluid .well-material-purple-500,
body .jumbotron-material-purple-500,
.container .jumbotron-material-purple-500,
.container-fluid .jumbotron-material-purple-500 {
  background-color: #9c27b0;
}
body .well-material-purple-600,
.container .well-material-purple-600,
.container-fluid .well-material-purple-600,
body .jumbotron-material-purple-600,
.container .jumbotron-material-purple-600,
.container-fluid .jumbotron-material-purple-600 {
  background-color: #8e24aa;
}
body .well-material-purple-700,
.container .well-material-purple-700,
.container-fluid .well-material-purple-700,
body .jumbotron-material-purple-700,
.container .jumbotron-material-purple-700,
.container-fluid .jumbotron-material-purple-700 {
  background-color: #7b1fa2;
}
body .well-material-purple-800,
.container .well-material-purple-800,
.container-fluid .well-material-purple-800,
body .jumbotron-material-purple-800,
.container .jumbotron-material-purple-800,
.container-fluid .jumbotron-material-purple-800 {
  background-color: #6a1b9a;
}
body .well-material-purple-900,
.container .well-material-purple-900,
.container-fluid .well-material-purple-900,
body .jumbotron-material-purple-900,
.container .jumbotron-material-purple-900,
.container-fluid .jumbotron-material-purple-900 {
  background-color: #4a148c;
}
body .well-material-purple-A100,
.container .well-material-purple-A100,
.container-fluid .well-material-purple-A100,
body .jumbotron-material-purple-A100,
.container .jumbotron-material-purple-A100,
.container-fluid .jumbotron-material-purple-A100 {
  background-color: #ea80fc;
}
body .well-material-purple-A200,
.container .well-material-purple-A200,
.container-fluid .well-material-purple-A200,
body .jumbotron-material-purple-A200,
.container .jumbotron-material-purple-A200,
.container-fluid .jumbotron-material-purple-A200 {
  background-color: #e040fb;
}
body .well-material-purple-A400,
.container .well-material-purple-A400,
.container-fluid .well-material-purple-A400,
body .jumbotron-material-purple-A400,
.container .jumbotron-material-purple-A400,
.container-fluid .jumbotron-material-purple-A400 {
  background-color: #d500f9;
}
body .well-material-purple-A700,
.container .well-material-purple-A700,
.container-fluid .well-material-purple-A700,
body .jumbotron-material-purple-A700,
.container .jumbotron-material-purple-A700,
.container-fluid .jumbotron-material-purple-A700 {
  background-color: #aa00ff;
}
body .well-material-deep-purple,
.container .well-material-deep-purple,
.container-fluid .well-material-deep-purple,
body .jumbotron-material-deep-purple,
.container .jumbotron-material-deep-purple,
.container-fluid .jumbotron-material-deep-purple {
  background-color: #673ab7;
}
body .well-material-deep-purple-50,
.container .well-material-deep-purple-50,
.container-fluid .well-material-deep-purple-50,
body .jumbotron-material-deep-purple-50,
.container .jumbotron-material-deep-purple-50,
.container-fluid .jumbotron-material-deep-purple-50 {
  background-color: #ede7f6;
}
body .well-material-deep-purple-100,
.container .well-material-deep-purple-100,
.container-fluid .well-material-deep-purple-100,
body .jumbotron-material-deep-purple-100,
.container .jumbotron-material-deep-purple-100,
.container-fluid .jumbotron-material-deep-purple-100 {
  background-color: #d1c4e9;
}
body .well-material-deep-purple-200,
.container .well-material-deep-purple-200,
.container-fluid .well-material-deep-purple-200,
body .jumbotron-material-deep-purple-200,
.container .jumbotron-material-deep-purple-200,
.container-fluid .jumbotron-material-deep-purple-200 {
  background-color: #b39ddb;
}
body .well-material-deep-purple-300,
.container .well-material-deep-purple-300,
.container-fluid .well-material-deep-purple-300,
body .jumbotron-material-deep-purple-300,
.container .jumbotron-material-deep-purple-300,
.container-fluid .jumbotron-material-deep-purple-300 {
  background-color: #9575cd;
}
body .well-material-deep-purple-400,
.container .well-material-deep-purple-400,
.container-fluid .well-material-deep-purple-400,
body .jumbotron-material-deep-purple-400,
.container .jumbotron-material-deep-purple-400,
.container-fluid .jumbotron-material-deep-purple-400 {
  background-color: #7e57c2;
}
body .well-material-deep-purple-500,
.container .well-material-deep-purple-500,
.container-fluid .well-material-deep-purple-500,
body .jumbotron-material-deep-purple-500,
.container .jumbotron-material-deep-purple-500,
.container-fluid .jumbotron-material-deep-purple-500 {
  background-color: #673ab7;
}
body .well-material-deep-purple-600,
.container .well-material-deep-purple-600,
.container-fluid .well-material-deep-purple-600,
body .jumbotron-material-deep-purple-600,
.container .jumbotron-material-deep-purple-600,
.container-fluid .jumbotron-material-deep-purple-600 {
  background-color: #5e35b1;
}
body .well-material-deep-purple-700,
.container .well-material-deep-purple-700,
.container-fluid .well-material-deep-purple-700,
body .jumbotron-material-deep-purple-700,
.container .jumbotron-material-deep-purple-700,
.container-fluid .jumbotron-material-deep-purple-700 {
  background-color: #512da8;
}
body .well-material-deep-purple-800,
.container .well-material-deep-purple-800,
.container-fluid .well-material-deep-purple-800,
body .jumbotron-material-deep-purple-800,
.container .jumbotron-material-deep-purple-800,
.container-fluid .jumbotron-material-deep-purple-800 {
  background-color: #4527a0;
}
body .well-material-deep-purple-900,
.container .well-material-deep-purple-900,
.container-fluid .well-material-deep-purple-900,
body .jumbotron-material-deep-purple-900,
.container .jumbotron-material-deep-purple-900,
.container-fluid .jumbotron-material-deep-purple-900 {
  background-color: #311b92;
}
body .well-material-deep-purple-A100,
.container .well-material-deep-purple-A100,
.container-fluid .well-material-deep-purple-A100,
body .jumbotron-material-deep-purple-A100,
.container .jumbotron-material-deep-purple-A100,
.container-fluid .jumbotron-material-deep-purple-A100 {
  background-color: #b388ff;
}
body .well-material-deep-purple-A200,
.container .well-material-deep-purple-A200,
.container-fluid .well-material-deep-purple-A200,
body .jumbotron-material-deep-purple-A200,
.container .jumbotron-material-deep-purple-A200,
.container-fluid .jumbotron-material-deep-purple-A200 {
  background-color: #7c4dff;
}
body .well-material-deep-purple-A400,
.container .well-material-deep-purple-A400,
.container-fluid .well-material-deep-purple-A400,
body .jumbotron-material-deep-purple-A400,
.container .jumbotron-material-deep-purple-A400,
.container-fluid .jumbotron-material-deep-purple-A400 {
  background-color: #651fff;
}
body .well-material-deep-purple-A700,
.container .well-material-deep-purple-A700,
.container-fluid .well-material-deep-purple-A700,
body .jumbotron-material-deep-purple-A700,
.container .jumbotron-material-deep-purple-A700,
.container-fluid .jumbotron-material-deep-purple-A700 {
  background-color: #6200ea;
}
body .well-material-indigo,
.container .well-material-indigo,
.container-fluid .well-material-indigo,
body .jumbotron-material-indigo,
.container .jumbotron-material-indigo,
.container-fluid .jumbotron-material-indigo {
  background-color: #3f51b5;
}
body .well-material-indigo-50,
.container .well-material-indigo-50,
.container-fluid .well-material-indigo-50,
body .jumbotron-material-indigo-50,
.container .jumbotron-material-indigo-50,
.container-fluid .jumbotron-material-indigo-50 {
  background-color: #e8eaf6;
}
body .well-material-indigo-100,
.container .well-material-indigo-100,
.container-fluid .well-material-indigo-100,
body .jumbotron-material-indigo-100,
.container .jumbotron-material-indigo-100,
.container-fluid .jumbotron-material-indigo-100 {
  background-color: #c5cae9;
}
body .well-material-indigo-200,
.container .well-material-indigo-200,
.container-fluid .well-material-indigo-200,
body .jumbotron-material-indigo-200,
.container .jumbotron-material-indigo-200,
.container-fluid .jumbotron-material-indigo-200 {
  background-color: #9fa8da;
}
body .well-material-indigo-300,
.container .well-material-indigo-300,
.container-fluid .well-material-indigo-300,
body .jumbotron-material-indigo-300,
.container .jumbotron-material-indigo-300,
.container-fluid .jumbotron-material-indigo-300 {
  background-color: #7986cb;
}
body .well-material-indigo-400,
.container .well-material-indigo-400,
.container-fluid .well-material-indigo-400,
body .jumbotron-material-indigo-400,
.container .jumbotron-material-indigo-400,
.container-fluid .jumbotron-material-indigo-400 {
  background-color: #5c6bc0;
}
body .well-material-indigo-500,
.container .well-material-indigo-500,
.container-fluid .well-material-indigo-500,
body .jumbotron-material-indigo-500,
.container .jumbotron-material-indigo-500,
.container-fluid .jumbotron-material-indigo-500 {
  background-color: #3f51b5;
}
body .well-material-indigo-600,
.container .well-material-indigo-600,
.container-fluid .well-material-indigo-600,
body .jumbotron-material-indigo-600,
.container .jumbotron-material-indigo-600,
.container-fluid .jumbotron-material-indigo-600 {
  background-color: #3949ab;
}
body .well-material-indigo-700,
.container .well-material-indigo-700,
.container-fluid .well-material-indigo-700,
body .jumbotron-material-indigo-700,
.container .jumbotron-material-indigo-700,
.container-fluid .jumbotron-material-indigo-700 {
  background-color: #303f9f;
}
body .well-material-indigo-800,
.container .well-material-indigo-800,
.container-fluid .well-material-indigo-800,
body .jumbotron-material-indigo-800,
.container .jumbotron-material-indigo-800,
.container-fluid .jumbotron-material-indigo-800 {
  background-color: #283593;
}
body .well-material-indigo-900,
.container .well-material-indigo-900,
.container-fluid .well-material-indigo-900,
body .jumbotron-material-indigo-900,
.container .jumbotron-material-indigo-900,
.container-fluid .jumbotron-material-indigo-900 {
  background-color: #1a237e;
}
body .well-material-indigo-A100,
.container .well-material-indigo-A100,
.container-fluid .well-material-indigo-A100,
body .jumbotron-material-indigo-A100,
.container .jumbotron-material-indigo-A100,
.container-fluid .jumbotron-material-indigo-A100 {
  background-color: #8c9eff;
}
body .well-material-indigo-A200,
.container .well-material-indigo-A200,
.container-fluid .well-material-indigo-A200,
body .jumbotron-material-indigo-A200,
.container .jumbotron-material-indigo-A200,
.container-fluid .jumbotron-material-indigo-A200 {
  background-color: #536dfe;
}
body .well-material-indigo-A400,
.container .well-material-indigo-A400,
.container-fluid .well-material-indigo-A400,
body .jumbotron-material-indigo-A400,
.container .jumbotron-material-indigo-A400,
.container-fluid .jumbotron-material-indigo-A400 {
  background-color: #3d5afe;
}
body .well-material-indigo-A700,
.container .well-material-indigo-A700,
.container-fluid .well-material-indigo-A700,
body .jumbotron-material-indigo-A700,
.container .jumbotron-material-indigo-A700,
.container-fluid .jumbotron-material-indigo-A700 {
  background-color: #304ffe;
}
body .well-material-blue,
.container .well-material-blue,
.container-fluid .well-material-blue,
body .jumbotron-material-blue,
.container .jumbotron-material-blue,
.container-fluid .jumbotron-material-blue {
  background-color: #2196f3;
}
body .well-material-blue-50,
.container .well-material-blue-50,
.container-fluid .well-material-blue-50,
body .jumbotron-material-blue-50,
.container .jumbotron-material-blue-50,
.container-fluid .jumbotron-material-blue-50 {
  background-color: #e3f2fd;
}
body .well-material-blue-100,
.container .well-material-blue-100,
.container-fluid .well-material-blue-100,
body .jumbotron-material-blue-100,
.container .jumbotron-material-blue-100,
.container-fluid .jumbotron-material-blue-100 {
  background-color: #bbdefb;
}
body .well-material-blue-200,
.container .well-material-blue-200,
.container-fluid .well-material-blue-200,
body .jumbotron-material-blue-200,
.container .jumbotron-material-blue-200,
.container-fluid .jumbotron-material-blue-200 {
  background-color: #90caf9;
}
body .well-material-blue-300,
.container .well-material-blue-300,
.container-fluid .well-material-blue-300,
body .jumbotron-material-blue-300,
.container .jumbotron-material-blue-300,
.container-fluid .jumbotron-material-blue-300 {
  background-color: #64b5f6;
}
body .well-material-blue-400,
.container .well-material-blue-400,
.container-fluid .well-material-blue-400,
body .jumbotron-material-blue-400,
.container .jumbotron-material-blue-400,
.container-fluid .jumbotron-material-blue-400 {
  background-color: #42a5f5;
}
body .well-material-blue-500,
.container .well-material-blue-500,
.container-fluid .well-material-blue-500,
body .jumbotron-material-blue-500,
.container .jumbotron-material-blue-500,
.container-fluid .jumbotron-material-blue-500 {
  background-color: #2196f3;
}
body .well-material-blue-600,
.container .well-material-blue-600,
.container-fluid .well-material-blue-600,
body .jumbotron-material-blue-600,
.container .jumbotron-material-blue-600,
.container-fluid .jumbotron-material-blue-600 {
  background-color: #1e88e5;
}
body .well-material-blue-700,
.container .well-material-blue-700,
.container-fluid .well-material-blue-700,
body .jumbotron-material-blue-700,
.container .jumbotron-material-blue-700,
.container-fluid .jumbotron-material-blue-700 {
  background-color: #1976d2;
}
body .well-material-blue-800,
.container .well-material-blue-800,
.container-fluid .well-material-blue-800,
body .jumbotron-material-blue-800,
.container .jumbotron-material-blue-800,
.container-fluid .jumbotron-material-blue-800 {
  background-color: #1565c0;
}
body .well-material-blue-900,
.container .well-material-blue-900,
.container-fluid .well-material-blue-900,
body .jumbotron-material-blue-900,
.container .jumbotron-material-blue-900,
.container-fluid .jumbotron-material-blue-900 {
  background-color: #0d47a1;
}
body .well-material-blue-A100,
.container .well-material-blue-A100,
.container-fluid .well-material-blue-A100,
body .jumbotron-material-blue-A100,
.container .jumbotron-material-blue-A100,
.container-fluid .jumbotron-material-blue-A100 {
  background-color: #82b1ff;
}
body .well-material-blue-A200,
.container .well-material-blue-A200,
.container-fluid .well-material-blue-A200,
body .jumbotron-material-blue-A200,
.container .jumbotron-material-blue-A200,
.container-fluid .jumbotron-material-blue-A200 {
  background-color: #448aff;
}
body .well-material-blue-A400,
.container .well-material-blue-A400,
.container-fluid .well-material-blue-A400,
body .jumbotron-material-blue-A400,
.container .jumbotron-material-blue-A400,
.container-fluid .jumbotron-material-blue-A400 {
  background-color: #2979ff;
}
body .well-material-blue-A700,
.container .well-material-blue-A700,
.container-fluid .well-material-blue-A700,
body .jumbotron-material-blue-A700,
.container .jumbotron-material-blue-A700,
.container-fluid .jumbotron-material-blue-A700 {
  background-color: #2962ff;
}
body .well-material-light-blue,
.container .well-material-light-blue,
.container-fluid .well-material-light-blue,
body .jumbotron-material-light-blue,
.container .jumbotron-material-light-blue,
.container-fluid .jumbotron-material-light-blue {
  background-color: #03a9f4;
}
body .well-material-light-blue-50,
.container .well-material-light-blue-50,
.container-fluid .well-material-light-blue-50,
body .jumbotron-material-light-blue-50,
.container .jumbotron-material-light-blue-50,
.container-fluid .jumbotron-material-light-blue-50 {
  background-color: #e1f5fe;
}
body .well-material-light-blue-100,
.container .well-material-light-blue-100,
.container-fluid .well-material-light-blue-100,
body .jumbotron-material-light-blue-100,
.container .jumbotron-material-light-blue-100,
.container-fluid .jumbotron-material-light-blue-100 {
  background-color: #b3e5fc;
}
body .well-material-light-blue-200,
.container .well-material-light-blue-200,
.container-fluid .well-material-light-blue-200,
body .jumbotron-material-light-blue-200,
.container .jumbotron-material-light-blue-200,
.container-fluid .jumbotron-material-light-blue-200 {
  background-color: #81d4fa;
}
body .well-material-light-blue-300,
.container .well-material-light-blue-300,
.container-fluid .well-material-light-blue-300,
body .jumbotron-material-light-blue-300,
.container .jumbotron-material-light-blue-300,
.container-fluid .jumbotron-material-light-blue-300 {
  background-color: #4fc3f7;
}
body .well-material-light-blue-400,
.container .well-material-light-blue-400,
.container-fluid .well-material-light-blue-400,
body .jumbotron-material-light-blue-400,
.container .jumbotron-material-light-blue-400,
.container-fluid .jumbotron-material-light-blue-400 {
  background-color: #29b6f6;
}
body .well-material-light-blue-500,
.container .well-material-light-blue-500,
.container-fluid .well-material-light-blue-500,
body .jumbotron-material-light-blue-500,
.container .jumbotron-material-light-blue-500,
.container-fluid .jumbotron-material-light-blue-500 {
  background-color: #03a9f4;
}
body .well-material-light-blue-600,
.container .well-material-light-blue-600,
.container-fluid .well-material-light-blue-600,
body .jumbotron-material-light-blue-600,
.container .jumbotron-material-light-blue-600,
.container-fluid .jumbotron-material-light-blue-600 {
  background-color: #039be5;
}
body .well-material-light-blue-700,
.container .well-material-light-blue-700,
.container-fluid .well-material-light-blue-700,
body .jumbotron-material-light-blue-700,
.container .jumbotron-material-light-blue-700,
.container-fluid .jumbotron-material-light-blue-700 {
  background-color: #0288d1;
}
body .well-material-light-blue-800,
.container .well-material-light-blue-800,
.container-fluid .well-material-light-blue-800,
body .jumbotron-material-light-blue-800,
.container .jumbotron-material-light-blue-800,
.container-fluid .jumbotron-material-light-blue-800 {
  background-color: #0277bd;
}
body .well-material-light-blue-900,
.container .well-material-light-blue-900,
.container-fluid .well-material-light-blue-900,
body .jumbotron-material-light-blue-900,
.container .jumbotron-material-light-blue-900,
.container-fluid .jumbotron-material-light-blue-900 {
  background-color: #01579b;
}
body .well-material-light-blue-A100,
.container .well-material-light-blue-A100,
.container-fluid .well-material-light-blue-A100,
body .jumbotron-material-light-blue-A100,
.container .jumbotron-material-light-blue-A100,
.container-fluid .jumbotron-material-light-blue-A100 {
  background-color: #80d8ff;
}
body .well-material-light-blue-A200,
.container .well-material-light-blue-A200,
.container-fluid .well-material-light-blue-A200,
body .jumbotron-material-light-blue-A200,
.container .jumbotron-material-light-blue-A200,
.container-fluid .jumbotron-material-light-blue-A200 {
  background-color: #40c4ff;
}
body .well-material-light-blue-A400,
.container .well-material-light-blue-A400,
.container-fluid .well-material-light-blue-A400,
body .jumbotron-material-light-blue-A400,
.container .jumbotron-material-light-blue-A400,
.container-fluid .jumbotron-material-light-blue-A400 {
  background-color: #00b0ff;
}
body .well-material-light-blue-A700,
.container .well-material-light-blue-A700,
.container-fluid .well-material-light-blue-A700,
body .jumbotron-material-light-blue-A700,
.container .jumbotron-material-light-blue-A700,
.container-fluid .jumbotron-material-light-blue-A700 {
  background-color: #0091ea;
}
body .well-material-cyan,
.container .well-material-cyan,
.container-fluid .well-material-cyan,
body .jumbotron-material-cyan,
.container .jumbotron-material-cyan,
.container-fluid .jumbotron-material-cyan {
  background-color: #00bcd4;
}
body .well-material-cyan-50,
.container .well-material-cyan-50,
.container-fluid .well-material-cyan-50,
body .jumbotron-material-cyan-50,
.container .jumbotron-material-cyan-50,
.container-fluid .jumbotron-material-cyan-50 {
  background-color: #e0f7fa;
}
body .well-material-cyan-100,
.container .well-material-cyan-100,
.container-fluid .well-material-cyan-100,
body .jumbotron-material-cyan-100,
.container .jumbotron-material-cyan-100,
.container-fluid .jumbotron-material-cyan-100 {
  background-color: #b2ebf2;
}
body .well-material-cyan-200,
.container .well-material-cyan-200,
.container-fluid .well-material-cyan-200,
body .jumbotron-material-cyan-200,
.container .jumbotron-material-cyan-200,
.container-fluid .jumbotron-material-cyan-200 {
  background-color: #80deea;
}
body .well-material-cyan-300,
.container .well-material-cyan-300,
.container-fluid .well-material-cyan-300,
body .jumbotron-material-cyan-300,
.container .jumbotron-material-cyan-300,
.container-fluid .jumbotron-material-cyan-300 {
  background-color: #4dd0e1;
}
body .well-material-cyan-400,
.container .well-material-cyan-400,
.container-fluid .well-material-cyan-400,
body .jumbotron-material-cyan-400,
.container .jumbotron-material-cyan-400,
.container-fluid .jumbotron-material-cyan-400 {
  background-color: #26c6da;
}
body .well-material-cyan-500,
.container .well-material-cyan-500,
.container-fluid .well-material-cyan-500,
body .jumbotron-material-cyan-500,
.container .jumbotron-material-cyan-500,
.container-fluid .jumbotron-material-cyan-500 {
  background-color: #00bcd4;
}
body .well-material-cyan-600,
.container .well-material-cyan-600,
.container-fluid .well-material-cyan-600,
body .jumbotron-material-cyan-600,
.container .jumbotron-material-cyan-600,
.container-fluid .jumbotron-material-cyan-600 {
  background-color: #00acc1;
}
body .well-material-cyan-700,
.container .well-material-cyan-700,
.container-fluid .well-material-cyan-700,
body .jumbotron-material-cyan-700,
.container .jumbotron-material-cyan-700,
.container-fluid .jumbotron-material-cyan-700 {
  background-color: #0097a7;
}
body .well-material-cyan-800,
.container .well-material-cyan-800,
.container-fluid .well-material-cyan-800,
body .jumbotron-material-cyan-800,
.container .jumbotron-material-cyan-800,
.container-fluid .jumbotron-material-cyan-800 {
  background-color: #00838f;
}
body .well-material-cyan-900,
.container .well-material-cyan-900,
.container-fluid .well-material-cyan-900,
body .jumbotron-material-cyan-900,
.container .jumbotron-material-cyan-900,
.container-fluid .jumbotron-material-cyan-900 {
  background-color: #006064;
}
body .well-material-cyan-A100,
.container .well-material-cyan-A100,
.container-fluid .well-material-cyan-A100,
body .jumbotron-material-cyan-A100,
.container .jumbotron-material-cyan-A100,
.container-fluid .jumbotron-material-cyan-A100 {
  background-color: #84ffff;
}
body .well-material-cyan-A200,
.container .well-material-cyan-A200,
.container-fluid .well-material-cyan-A200,
body .jumbotron-material-cyan-A200,
.container .jumbotron-material-cyan-A200,
.container-fluid .jumbotron-material-cyan-A200 {
  background-color: #18ffff;
}
body .well-material-cyan-A400,
.container .well-material-cyan-A400,
.container-fluid .well-material-cyan-A400,
body .jumbotron-material-cyan-A400,
.container .jumbotron-material-cyan-A400,
.container-fluid .jumbotron-material-cyan-A400 {
  background-color: #00e5ff;
}
body .well-material-cyan-A700,
.container .well-material-cyan-A700,
.container-fluid .well-material-cyan-A700,
body .jumbotron-material-cyan-A700,
.container .jumbotron-material-cyan-A700,
.container-fluid .jumbotron-material-cyan-A700 {
  background-color: #00b8d4;
}
body .well-material-teal,
.container .well-material-teal,
.container-fluid .well-material-teal,
body .jumbotron-material-teal,
.container .jumbotron-material-teal,
.container-fluid .jumbotron-material-teal {
  background-color: #009688;
}
body .well-material-teal-50,
.container .well-material-teal-50,
.container-fluid .well-material-teal-50,
body .jumbotron-material-teal-50,
.container .jumbotron-material-teal-50,
.container-fluid .jumbotron-material-teal-50 {
  background-color: #e0f2f1;
}
body .well-material-teal-100,
.container .well-material-teal-100,
.container-fluid .well-material-teal-100,
body .jumbotron-material-teal-100,
.container .jumbotron-material-teal-100,
.container-fluid .jumbotron-material-teal-100 {
  background-color: #b2dfdb;
}
body .well-material-teal-200,
.container .well-material-teal-200,
.container-fluid .well-material-teal-200,
body .jumbotron-material-teal-200,
.container .jumbotron-material-teal-200,
.container-fluid .jumbotron-material-teal-200 {
  background-color: #80cbc4;
}
body .well-material-teal-300,
.container .well-material-teal-300,
.container-fluid .well-material-teal-300,
body .jumbotron-material-teal-300,
.container .jumbotron-material-teal-300,
.container-fluid .jumbotron-material-teal-300 {
  background-color: #4db6ac;
}
body .well-material-teal-400,
.container .well-material-teal-400,
.container-fluid .well-material-teal-400,
body .jumbotron-material-teal-400,
.container .jumbotron-material-teal-400,
.container-fluid .jumbotron-material-teal-400 {
  background-color: #26a69a;
}
body .well-material-teal-500,
.container .well-material-teal-500,
.container-fluid .well-material-teal-500,
body .jumbotron-material-teal-500,
.container .jumbotron-material-teal-500,
.container-fluid .jumbotron-material-teal-500 {
  background-color: #009688;
}
body .well-material-teal-600,
.container .well-material-teal-600,
.container-fluid .well-material-teal-600,
body .jumbotron-material-teal-600,
.container .jumbotron-material-teal-600,
.container-fluid .jumbotron-material-teal-600 {
  background-color: #00897b;
}
body .well-material-teal-700,
.container .well-material-teal-700,
.container-fluid .well-material-teal-700,
body .jumbotron-material-teal-700,
.container .jumbotron-material-teal-700,
.container-fluid .jumbotron-material-teal-700 {
  background-color: #00796b;
}
body .well-material-teal-800,
.container .well-material-teal-800,
.container-fluid .well-material-teal-800,
body .jumbotron-material-teal-800,
.container .jumbotron-material-teal-800,
.container-fluid .jumbotron-material-teal-800 {
  background-color: #00695c;
}
body .well-material-teal-900,
.container .well-material-teal-900,
.container-fluid .well-material-teal-900,
body .jumbotron-material-teal-900,
.container .jumbotron-material-teal-900,
.container-fluid .jumbotron-material-teal-900 {
  background-color: #004d40;
}
body .well-material-teal-A100,
.container .well-material-teal-A100,
.container-fluid .well-material-teal-A100,
body .jumbotron-material-teal-A100,
.container .jumbotron-material-teal-A100,
.container-fluid .jumbotron-material-teal-A100 {
  background-color: #a7ffeb;
}
body .well-material-teal-A200,
.container .well-material-teal-A200,
.container-fluid .well-material-teal-A200,
body .jumbotron-material-teal-A200,
.container .jumbotron-material-teal-A200,
.container-fluid .jumbotron-material-teal-A200 {
  background-color: #64ffda;
}
body .well-material-teal-A400,
.container .well-material-teal-A400,
.container-fluid .well-material-teal-A400,
body .jumbotron-material-teal-A400,
.container .jumbotron-material-teal-A400,
.container-fluid .jumbotron-material-teal-A400 {
  background-color: #1de9b6;
}
body .well-material-teal-A700,
.container .well-material-teal-A700,
.container-fluid .well-material-teal-A700,
body .jumbotron-material-teal-A700,
.container .jumbotron-material-teal-A700,
.container-fluid .jumbotron-material-teal-A700 {
  background-color: #00bfa5;
}
body .well-material-green,
.container .well-material-green,
.container-fluid .well-material-green,
body .jumbotron-material-green,
.container .jumbotron-material-green,
.container-fluid .jumbotron-material-green {
  background-color: #4caf50;
}
body .well-material-green-50,
.container .well-material-green-50,
.container-fluid .well-material-green-50,
body .jumbotron-material-green-50,
.container .jumbotron-material-green-50,
.container-fluid .jumbotron-material-green-50 {
  background-color: #e8f5e9;
}
body .well-material-green-100,
.container .well-material-green-100,
.container-fluid .well-material-green-100,
body .jumbotron-material-green-100,
.container .jumbotron-material-green-100,
.container-fluid .jumbotron-material-green-100 {
  background-color: #c8e6c9;
}
body .well-material-green-200,
.container .well-material-green-200,
.container-fluid .well-material-green-200,
body .jumbotron-material-green-200,
.container .jumbotron-material-green-200,
.container-fluid .jumbotron-material-green-200 {
  background-color: #a5d6a7;
}
body .well-material-green-300,
.container .well-material-green-300,
.container-fluid .well-material-green-300,
body .jumbotron-material-green-300,
.container .jumbotron-material-green-300,
.container-fluid .jumbotron-material-green-300 {
  background-color: #81c784;
}
body .well-material-green-400,
.container .well-material-green-400,
.container-fluid .well-material-green-400,
body .jumbotron-material-green-400,
.container .jumbotron-material-green-400,
.container-fluid .jumbotron-material-green-400 {
  background-color: #66bb6a;
}
body .well-material-green-500,
.container .well-material-green-500,
.container-fluid .well-material-green-500,
body .jumbotron-material-green-500,
.container .jumbotron-material-green-500,
.container-fluid .jumbotron-material-green-500 {
  background-color: #4caf50;
}
body .well-material-green-600,
.container .well-material-green-600,
.container-fluid .well-material-green-600,
body .jumbotron-material-green-600,
.container .jumbotron-material-green-600,
.container-fluid .jumbotron-material-green-600 {
  background-color: #43a047;
}
body .well-material-green-700,
.container .well-material-green-700,
.container-fluid .well-material-green-700,
body .jumbotron-material-green-700,
.container .jumbotron-material-green-700,
.container-fluid .jumbotron-material-green-700 {
  background-color: #388e3c;
}
body .well-material-green-800,
.container .well-material-green-800,
.container-fluid .well-material-green-800,
body .jumbotron-material-green-800,
.container .jumbotron-material-green-800,
.container-fluid .jumbotron-material-green-800 {
  background-color: #2e7d32;
}
body .well-material-green-900,
.container .well-material-green-900,
.container-fluid .well-material-green-900,
body .jumbotron-material-green-900,
.container .jumbotron-material-green-900,
.container-fluid .jumbotron-material-green-900 {
  background-color: #1b5e20;
}
body .well-material-green-A100,
.container .well-material-green-A100,
.container-fluid .well-material-green-A100,
body .jumbotron-material-green-A100,
.container .jumbotron-material-green-A100,
.container-fluid .jumbotron-material-green-A100 {
  background-color: #b9f6ca;
}
body .well-material-green-A200,
.container .well-material-green-A200,
.container-fluid .well-material-green-A200,
body .jumbotron-material-green-A200,
.container .jumbotron-material-green-A200,
.container-fluid .jumbotron-material-green-A200 {
  background-color: #69f0ae;
}
body .well-material-green-A400,
.container .well-material-green-A400,
.container-fluid .well-material-green-A400,
body .jumbotron-material-green-A400,
.container .jumbotron-material-green-A400,
.container-fluid .jumbotron-material-green-A400 {
  background-color: #00e676;
}
body .well-material-green-A700,
.container .well-material-green-A700,
.container-fluid .well-material-green-A700,
body .jumbotron-material-green-A700,
.container .jumbotron-material-green-A700,
.container-fluid .jumbotron-material-green-A700 {
  background-color: #00c853;
}
body .well-material-light-green,
.container .well-material-light-green,
.container-fluid .well-material-light-green,
body .jumbotron-material-light-green,
.container .jumbotron-material-light-green,
.container-fluid .jumbotron-material-light-green {
  background-color: #8bc34a;
}
body .well-material-light-green-50,
.container .well-material-light-green-50,
.container-fluid .well-material-light-green-50,
body .jumbotron-material-light-green-50,
.container .jumbotron-material-light-green-50,
.container-fluid .jumbotron-material-light-green-50 {
  background-color: #f1f8e9;
}
body .well-material-light-green-100,
.container .well-material-light-green-100,
.container-fluid .well-material-light-green-100,
body .jumbotron-material-light-green-100,
.container .jumbotron-material-light-green-100,
.container-fluid .jumbotron-material-light-green-100 {
  background-color: #dcedc8;
}
body .well-material-light-green-200,
.container .well-material-light-green-200,
.container-fluid .well-material-light-green-200,
body .jumbotron-material-light-green-200,
.container .jumbotron-material-light-green-200,
.container-fluid .jumbotron-material-light-green-200 {
  background-color: #c5e1a5;
}
body .well-material-light-green-300,
.container .well-material-light-green-300,
.container-fluid .well-material-light-green-300,
body .jumbotron-material-light-green-300,
.container .jumbotron-material-light-green-300,
.container-fluid .jumbotron-material-light-green-300 {
  background-color: #aed581;
}
body .well-material-light-green-400,
.container .well-material-light-green-400,
.container-fluid .well-material-light-green-400,
body .jumbotron-material-light-green-400,
.container .jumbotron-material-light-green-400,
.container-fluid .jumbotron-material-light-green-400 {
  background-color: #9ccc65;
}
body .well-material-light-green-500,
.container .well-material-light-green-500,
.container-fluid .well-material-light-green-500,
body .jumbotron-material-light-green-500,
.container .jumbotron-material-light-green-500,
.container-fluid .jumbotron-material-light-green-500 {
  background-color: #8bc34a;
}
body .well-material-light-green-600,
.container .well-material-light-green-600,
.container-fluid .well-material-light-green-600,
body .jumbotron-material-light-green-600,
.container .jumbotron-material-light-green-600,
.container-fluid .jumbotron-material-light-green-600 {
  background-color: #7cb342;
}
body .well-material-light-green-700,
.container .well-material-light-green-700,
.container-fluid .well-material-light-green-700,
body .jumbotron-material-light-green-700,
.container .jumbotron-material-light-green-700,
.container-fluid .jumbotron-material-light-green-700 {
  background-color: #689f38;
}
body .well-material-light-green-800,
.container .well-material-light-green-800,
.container-fluid .well-material-light-green-800,
body .jumbotron-material-light-green-800,
.container .jumbotron-material-light-green-800,
.container-fluid .jumbotron-material-light-green-800 {
  background-color: #558b2f;
}
body .well-material-light-green-900,
.container .well-material-light-green-900,
.container-fluid .well-material-light-green-900,
body .jumbotron-material-light-green-900,
.container .jumbotron-material-light-green-900,
.container-fluid .jumbotron-material-light-green-900 {
  background-color: #33691e;
}
body .well-material-light-green-A100,
.container .well-material-light-green-A100,
.container-fluid .well-material-light-green-A100,
body .jumbotron-material-light-green-A100,
.container .jumbotron-material-light-green-A100,
.container-fluid .jumbotron-material-light-green-A100 {
  background-color: #ccff90;
}
body .well-material-light-green-A200,
.container .well-material-light-green-A200,
.container-fluid .well-material-light-green-A200,
body .jumbotron-material-light-green-A200,
.container .jumbotron-material-light-green-A200,
.container-fluid .jumbotron-material-light-green-A200 {
  background-color: #b2ff59;
}
body .well-material-light-green-A400,
.container .well-material-light-green-A400,
.container-fluid .well-material-light-green-A400,
body .jumbotron-material-light-green-A400,
.container .jumbotron-material-light-green-A400,
.container-fluid .jumbotron-material-light-green-A400 {
  background-color: #76ff03;
}
body .well-material-light-green-A700,
.container .well-material-light-green-A700,
.container-fluid .well-material-light-green-A700,
body .jumbotron-material-light-green-A700,
.container .jumbotron-material-light-green-A700,
.container-fluid .jumbotron-material-light-green-A700 {
  background-color: #64dd17;
}
body .well-material-lime,
.container .well-material-lime,
.container-fluid .well-material-lime,
body .jumbotron-material-lime,
.container .jumbotron-material-lime,
.container-fluid .jumbotron-material-lime {
  background-color: #cddc39;
}
body .well-material-lime-50,
.container .well-material-lime-50,
.container-fluid .well-material-lime-50,
body .jumbotron-material-lime-50,
.container .jumbotron-material-lime-50,
.container-fluid .jumbotron-material-lime-50 {
  background-color: #f9fbe7;
}
body .well-material-lime-100,
.container .well-material-lime-100,
.container-fluid .well-material-lime-100,
body .jumbotron-material-lime-100,
.container .jumbotron-material-lime-100,
.container-fluid .jumbotron-material-lime-100 {
  background-color: #f0f4c3;
}
body .well-material-lime-200,
.container .well-material-lime-200,
.container-fluid .well-material-lime-200,
body .jumbotron-material-lime-200,
.container .jumbotron-material-lime-200,
.container-fluid .jumbotron-material-lime-200 {
  background-color: #e6ee9c;
}
body .well-material-lime-300,
.container .well-material-lime-300,
.container-fluid .well-material-lime-300,
body .jumbotron-material-lime-300,
.container .jumbotron-material-lime-300,
.container-fluid .jumbotron-material-lime-300 {
  background-color: #dce775;
}
body .well-material-lime-400,
.container .well-material-lime-400,
.container-fluid .well-material-lime-400,
body .jumbotron-material-lime-400,
.container .jumbotron-material-lime-400,
.container-fluid .jumbotron-material-lime-400 {
  background-color: #d4e157;
}
body .well-material-lime-500,
.container .well-material-lime-500,
.container-fluid .well-material-lime-500,
body .jumbotron-material-lime-500,
.container .jumbotron-material-lime-500,
.container-fluid .jumbotron-material-lime-500 {
  background-color: #cddc39;
}
body .well-material-lime-600,
.container .well-material-lime-600,
.container-fluid .well-material-lime-600,
body .jumbotron-material-lime-600,
.container .jumbotron-material-lime-600,
.container-fluid .jumbotron-material-lime-600 {
  background-color: #c0ca33;
}
body .well-material-lime-700,
.container .well-material-lime-700,
.container-fluid .well-material-lime-700,
body .jumbotron-material-lime-700,
.container .jumbotron-material-lime-700,
.container-fluid .jumbotron-material-lime-700 {
  background-color: #afb42b;
}
body .well-material-lime-800,
.container .well-material-lime-800,
.container-fluid .well-material-lime-800,
body .jumbotron-material-lime-800,
.container .jumbotron-material-lime-800,
.container-fluid .jumbotron-material-lime-800 {
  background-color: #9e9d24;
}
body .well-material-lime-900,
.container .well-material-lime-900,
.container-fluid .well-material-lime-900,
body .jumbotron-material-lime-900,
.container .jumbotron-material-lime-900,
.container-fluid .jumbotron-material-lime-900 {
  background-color: #827717;
}
body .well-material-lime-A100,
.container .well-material-lime-A100,
.container-fluid .well-material-lime-A100,
body .jumbotron-material-lime-A100,
.container .jumbotron-material-lime-A100,
.container-fluid .jumbotron-material-lime-A100 {
  background-color: #f4ff81;
}
body .well-material-lime-A200,
.container .well-material-lime-A200,
.container-fluid .well-material-lime-A200,
body .jumbotron-material-lime-A200,
.container .jumbotron-material-lime-A200,
.container-fluid .jumbotron-material-lime-A200 {
  background-color: #eeff41;
}
body .well-material-lime-A400,
.container .well-material-lime-A400,
.container-fluid .well-material-lime-A400,
body .jumbotron-material-lime-A400,
.container .jumbotron-material-lime-A400,
.container-fluid .jumbotron-material-lime-A400 {
  background-color: #c6ff00;
}
body .well-material-lime-A700,
.container .well-material-lime-A700,
.container-fluid .well-material-lime-A700,
body .jumbotron-material-lime-A700,
.container .jumbotron-material-lime-A700,
.container-fluid .jumbotron-material-lime-A700 {
  background-color: #aeea00;
}
body .well-material-yellow,
.container .well-material-yellow,
.container-fluid .well-material-yellow,
body .jumbotron-material-yellow,
.container .jumbotron-material-yellow,
.container-fluid .jumbotron-material-yellow {
  background-color: #ffeb3b;
}
body .well-material-yellow-50,
.container .well-material-yellow-50,
.container-fluid .well-material-yellow-50,
body .jumbotron-material-yellow-50,
.container .jumbotron-material-yellow-50,
.container-fluid .jumbotron-material-yellow-50 {
  background-color: #fffde7;
}
body .well-material-yellow-100,
.container .well-material-yellow-100,
.container-fluid .well-material-yellow-100,
body .jumbotron-material-yellow-100,
.container .jumbotron-material-yellow-100,
.container-fluid .jumbotron-material-yellow-100 {
  background-color: #fff9c4;
}
body .well-material-yellow-200,
.container .well-material-yellow-200,
.container-fluid .well-material-yellow-200,
body .jumbotron-material-yellow-200,
.container .jumbotron-material-yellow-200,
.container-fluid .jumbotron-material-yellow-200 {
  background-color: #fff59d;
}
body .well-material-yellow-300,
.container .well-material-yellow-300,
.container-fluid .well-material-yellow-300,
body .jumbotron-material-yellow-300,
.container .jumbotron-material-yellow-300,
.container-fluid .jumbotron-material-yellow-300 {
  background-color: #fff176;
}
body .well-material-yellow-400,
.container .well-material-yellow-400,
.container-fluid .well-material-yellow-400,
body .jumbotron-material-yellow-400,
.container .jumbotron-material-yellow-400,
.container-fluid .jumbotron-material-yellow-400 {
  background-color: #ffee58;
}
body .well-material-yellow-500,
.container .well-material-yellow-500,
.container-fluid .well-material-yellow-500,
body .jumbotron-material-yellow-500,
.container .jumbotron-material-yellow-500,
.container-fluid .jumbotron-material-yellow-500 {
  background-color: #ffeb3b;
}
body .well-material-yellow-600,
.container .well-material-yellow-600,
.container-fluid .well-material-yellow-600,
body .jumbotron-material-yellow-600,
.container .jumbotron-material-yellow-600,
.container-fluid .jumbotron-material-yellow-600 {
  background-color: #fdd835;
}
body .well-material-yellow-700,
.container .well-material-yellow-700,
.container-fluid .well-material-yellow-700,
body .jumbotron-material-yellow-700,
.container .jumbotron-material-yellow-700,
.container-fluid .jumbotron-material-yellow-700 {
  background-color: #fbc02d;
}
body .well-material-yellow-800,
.container .well-material-yellow-800,
.container-fluid .well-material-yellow-800,
body .jumbotron-material-yellow-800,
.container .jumbotron-material-yellow-800,
.container-fluid .jumbotron-material-yellow-800 {
  background-color: #f9a825;
}
body .well-material-yellow-900,
.container .well-material-yellow-900,
.container-fluid .well-material-yellow-900,
body .jumbotron-material-yellow-900,
.container .jumbotron-material-yellow-900,
.container-fluid .jumbotron-material-yellow-900 {
  background-color: #f57f17;
}
body .well-material-yellow-A100,
.container .well-material-yellow-A100,
.container-fluid .well-material-yellow-A100,
body .jumbotron-material-yellow-A100,
.container .jumbotron-material-yellow-A100,
.container-fluid .jumbotron-material-yellow-A100 {
  background-color: #ffff8d;
}
body .well-material-yellow-A200,
.container .well-material-yellow-A200,
.container-fluid .well-material-yellow-A200,
body .jumbotron-material-yellow-A200,
.container .jumbotron-material-yellow-A200,
.container-fluid .jumbotron-material-yellow-A200 {
  background-color: #ffff00;
}
body .well-material-yellow-A400,
.container .well-material-yellow-A400,
.container-fluid .well-material-yellow-A400,
body .jumbotron-material-yellow-A400,
.container .jumbotron-material-yellow-A400,
.container-fluid .jumbotron-material-yellow-A400 {
  background-color: #ffea00;
}
body .well-material-yellow-A700,
.container .well-material-yellow-A700,
.container-fluid .well-material-yellow-A700,
body .jumbotron-material-yellow-A700,
.container .jumbotron-material-yellow-A700,
.container-fluid .jumbotron-material-yellow-A700 {
  background-color: #ffd600;
}
body .well-material-amber,
.container .well-material-amber,
.container-fluid .well-material-amber,
body .jumbotron-material-amber,
.container .jumbotron-material-amber,
.container-fluid .jumbotron-material-amber {
  background-color: #ffc107;
}
body .well-material-amber-50,
.container .well-material-amber-50,
.container-fluid .well-material-amber-50,
body .jumbotron-material-amber-50,
.container .jumbotron-material-amber-50,
.container-fluid .jumbotron-material-amber-50 {
  background-color: #fff8e1;
}
body .well-material-amber-100,
.container .well-material-amber-100,
.container-fluid .well-material-amber-100,
body .jumbotron-material-amber-100,
.container .jumbotron-material-amber-100,
.container-fluid .jumbotron-material-amber-100 {
  background-color: #ffecb3;
}
body .well-material-amber-200,
.container .well-material-amber-200,
.container-fluid .well-material-amber-200,
body .jumbotron-material-amber-200,
.container .jumbotron-material-amber-200,
.container-fluid .jumbotron-material-amber-200 {
  background-color: #ffe082;
}
body .well-material-amber-300,
.container .well-material-amber-300,
.container-fluid .well-material-amber-300,
body .jumbotron-material-amber-300,
.container .jumbotron-material-amber-300,
.container-fluid .jumbotron-material-amber-300 {
  background-color: #ffd54f;
}
body .well-material-amber-400,
.container .well-material-amber-400,
.container-fluid .well-material-amber-400,
body .jumbotron-material-amber-400,
.container .jumbotron-material-amber-400,
.container-fluid .jumbotron-material-amber-400 {
  background-color: #ffca28;
}
body .well-material-amber-500,
.container .well-material-amber-500,
.container-fluid .well-material-amber-500,
body .jumbotron-material-amber-500,
.container .jumbotron-material-amber-500,
.container-fluid .jumbotron-material-amber-500 {
  background-color: #ffc107;
}
body .well-material-amber-600,
.container .well-material-amber-600,
.container-fluid .well-material-amber-600,
body .jumbotron-material-amber-600,
.container .jumbotron-material-amber-600,
.container-fluid .jumbotron-material-amber-600 {
  background-color: #ffb300;
}
body .well-material-amber-700,
.container .well-material-amber-700,
.container-fluid .well-material-amber-700,
body .jumbotron-material-amber-700,
.container .jumbotron-material-amber-700,
.container-fluid .jumbotron-material-amber-700 {
  background-color: #ffa000;
}
body .well-material-amber-800,
.container .well-material-amber-800,
.container-fluid .well-material-amber-800,
body .jumbotron-material-amber-800,
.container .jumbotron-material-amber-800,
.container-fluid .jumbotron-material-amber-800 {
  background-color: #ff8f00;
}
body .well-material-amber-900,
.container .well-material-amber-900,
.container-fluid .well-material-amber-900,
body .jumbotron-material-amber-900,
.container .jumbotron-material-amber-900,
.container-fluid .jumbotron-material-amber-900 {
  background-color: #ff6f00;
}
body .well-material-amber-A100,
.container .well-material-amber-A100,
.container-fluid .well-material-amber-A100,
body .jumbotron-material-amber-A100,
.container .jumbotron-material-amber-A100,
.container-fluid .jumbotron-material-amber-A100 {
  background-color: #ffe57f;
}
body .well-material-amber-A200,
.container .well-material-amber-A200,
.container-fluid .well-material-amber-A200,
body .jumbotron-material-amber-A200,
.container .jumbotron-material-amber-A200,
.container-fluid .jumbotron-material-amber-A200 {
  background-color: #ffd740;
}
body .well-material-amber-A400,
.container .well-material-amber-A400,
.container-fluid .well-material-amber-A400,
body .jumbotron-material-amber-A400,
.container .jumbotron-material-amber-A400,
.container-fluid .jumbotron-material-amber-A400 {
  background-color: #ffc400;
}
body .well-material-amber-A700,
.container .well-material-amber-A700,
.container-fluid .well-material-amber-A700,
body .jumbotron-material-amber-A700,
.container .jumbotron-material-amber-A700,
.container-fluid .jumbotron-material-amber-A700 {
  background-color: #ffab00;
}
body .well-material-orange,
.container .well-material-orange,
.container-fluid .well-material-orange,
body .jumbotron-material-orange,
.container .jumbotron-material-orange,
.container-fluid .jumbotron-material-orange {
  background-color: #ff9800;
}
body .well-material-orange-50,
.container .well-material-orange-50,
.container-fluid .well-material-orange-50,
body .jumbotron-material-orange-50,
.container .jumbotron-material-orange-50,
.container-fluid .jumbotron-material-orange-50 {
  background-color: #fff3e0;
}
body .well-material-orange-100,
.container .well-material-orange-100,
.container-fluid .well-material-orange-100,
body .jumbotron-material-orange-100,
.container .jumbotron-material-orange-100,
.container-fluid .jumbotron-material-orange-100 {
  background-color: #ffe0b2;
}
body .well-material-orange-200,
.container .well-material-orange-200,
.container-fluid .well-material-orange-200,
body .jumbotron-material-orange-200,
.container .jumbotron-material-orange-200,
.container-fluid .jumbotron-material-orange-200 {
  background-color: #ffcc80;
}
body .well-material-orange-300,
.container .well-material-orange-300,
.container-fluid .well-material-orange-300,
body .jumbotron-material-orange-300,
.container .jumbotron-material-orange-300,
.container-fluid .jumbotron-material-orange-300 {
  background-color: #ffb74d;
}
body .well-material-orange-400,
.container .well-material-orange-400,
.container-fluid .well-material-orange-400,
body .jumbotron-material-orange-400,
.container .jumbotron-material-orange-400,
.container-fluid .jumbotron-material-orange-400 {
  background-color: #ffa726;
}
body .well-material-orange-500,
.container .well-material-orange-500,
.container-fluid .well-material-orange-500,
body .jumbotron-material-orange-500,
.container .jumbotron-material-orange-500,
.container-fluid .jumbotron-material-orange-500 {
  background-color: #ff9800;
}
body .well-material-orange-600,
.container .well-material-orange-600,
.container-fluid .well-material-orange-600,
body .jumbotron-material-orange-600,
.container .jumbotron-material-orange-600,
.container-fluid .jumbotron-material-orange-600 {
  background-color: #fb8c00;
}
body .well-material-orange-700,
.container .well-material-orange-700,
.container-fluid .well-material-orange-700,
body .jumbotron-material-orange-700,
.container .jumbotron-material-orange-700,
.container-fluid .jumbotron-material-orange-700 {
  background-color: #f57c00;
}
body .well-material-orange-800,
.container .well-material-orange-800,
.container-fluid .well-material-orange-800,
body .jumbotron-material-orange-800,
.container .jumbotron-material-orange-800,
.container-fluid .jumbotron-material-orange-800 {
  background-color: #ef6c00;
}
body .well-material-orange-900,
.container .well-material-orange-900,
.container-fluid .well-material-orange-900,
body .jumbotron-material-orange-900,
.container .jumbotron-material-orange-900,
.container-fluid .jumbotron-material-orange-900 {
  background-color: #e65100;
}
body .well-material-orange-A100,
.container .well-material-orange-A100,
.container-fluid .well-material-orange-A100,
body .jumbotron-material-orange-A100,
.container .jumbotron-material-orange-A100,
.container-fluid .jumbotron-material-orange-A100 {
  background-color: #ffd180;
}
body .well-material-orange-A200,
.container .well-material-orange-A200,
.container-fluid .well-material-orange-A200,
body .jumbotron-material-orange-A200,
.container .jumbotron-material-orange-A200,
.container-fluid .jumbotron-material-orange-A200 {
  background-color: #ffab40;
}
body .well-material-orange-A400,
.container .well-material-orange-A400,
.container-fluid .well-material-orange-A400,
body .jumbotron-material-orange-A400,
.container .jumbotron-material-orange-A400,
.container-fluid .jumbotron-material-orange-A400 {
  background-color: #ff9100;
}
body .well-material-orange-A700,
.container .well-material-orange-A700,
.container-fluid .well-material-orange-A700,
body .jumbotron-material-orange-A700,
.container .jumbotron-material-orange-A700,
.container-fluid .jumbotron-material-orange-A700 {
  background-color: #ff6d00;
}
body .well-material-deep-orange,
.container .well-material-deep-orange,
.container-fluid .well-material-deep-orange,
body .jumbotron-material-deep-orange,
.container .jumbotron-material-deep-orange,
.container-fluid .jumbotron-material-deep-orange {
  background-color: #ff5722;
}
body .well-material-deep-orange-50,
.container .well-material-deep-orange-50,
.container-fluid .well-material-deep-orange-50,
body .jumbotron-material-deep-orange-50,
.container .jumbotron-material-deep-orange-50,
.container-fluid .jumbotron-material-deep-orange-50 {
  background-color: #fbe9e7;
}
body .well-material-deep-orange-100,
.container .well-material-deep-orange-100,
.container-fluid .well-material-deep-orange-100,
body .jumbotron-material-deep-orange-100,
.container .jumbotron-material-deep-orange-100,
.container-fluid .jumbotron-material-deep-orange-100 {
  background-color: #ffccbc;
}
body .well-material-deep-orange-200,
.container .well-material-deep-orange-200,
.container-fluid .well-material-deep-orange-200,
body .jumbotron-material-deep-orange-200,
.container .jumbotron-material-deep-orange-200,
.container-fluid .jumbotron-material-deep-orange-200 {
  background-color: #ffab91;
}
body .well-material-deep-orange-300,
.container .well-material-deep-orange-300,
.container-fluid .well-material-deep-orange-300,
body .jumbotron-material-deep-orange-300,
.container .jumbotron-material-deep-orange-300,
.container-fluid .jumbotron-material-deep-orange-300 {
  background-color: #ff8a65;
}
body .well-material-deep-orange-400,
.container .well-material-deep-orange-400,
.container-fluid .well-material-deep-orange-400,
body .jumbotron-material-deep-orange-400,
.container .jumbotron-material-deep-orange-400,
.container-fluid .jumbotron-material-deep-orange-400 {
  background-color: #ff7043;
}
body .well-material-deep-orange-500,
.container .well-material-deep-orange-500,
.container-fluid .well-material-deep-orange-500,
body .jumbotron-material-deep-orange-500,
.container .jumbotron-material-deep-orange-500,
.container-fluid .jumbotron-material-deep-orange-500 {
  background-color: #ff5722;
}
body .well-material-deep-orange-600,
.container .well-material-deep-orange-600,
.container-fluid .well-material-deep-orange-600,
body .jumbotron-material-deep-orange-600,
.container .jumbotron-material-deep-orange-600,
.container-fluid .jumbotron-material-deep-orange-600 {
  background-color: #f4511e;
}
body .well-material-deep-orange-700,
.container .well-material-deep-orange-700,
.container-fluid .well-material-deep-orange-700,
body .jumbotron-material-deep-orange-700,
.container .jumbotron-material-deep-orange-700,
.container-fluid .jumbotron-material-deep-orange-700 {
  background-color: #e64a19;
}
body .well-material-deep-orange-800,
.container .well-material-deep-orange-800,
.container-fluid .well-material-deep-orange-800,
body .jumbotron-material-deep-orange-800,
.container .jumbotron-material-deep-orange-800,
.container-fluid .jumbotron-material-deep-orange-800 {
  background-color: #d84315;
}
body .well-material-deep-orange-900,
.container .well-material-deep-orange-900,
.container-fluid .well-material-deep-orange-900,
body .jumbotron-material-deep-orange-900,
.container .jumbotron-material-deep-orange-900,
.container-fluid .jumbotron-material-deep-orange-900 {
  background-color: #bf360c;
}
body .well-material-deep-orange-A100,
.container .well-material-deep-orange-A100,
.container-fluid .well-material-deep-orange-A100,
body .jumbotron-material-deep-orange-A100,
.container .jumbotron-material-deep-orange-A100,
.container-fluid .jumbotron-material-deep-orange-A100 {
  background-color: #ff9e80;
}
body .well-material-deep-orange-A200,
.container .well-material-deep-orange-A200,
.container-fluid .well-material-deep-orange-A200,
body .jumbotron-material-deep-orange-A200,
.container .jumbotron-material-deep-orange-A200,
.container-fluid .jumbotron-material-deep-orange-A200 {
  background-color: #ff6e40;
}
body .well-material-deep-orange-A400,
.container .well-material-deep-orange-A400,
.container-fluid .well-material-deep-orange-A400,
body .jumbotron-material-deep-orange-A400,
.container .jumbotron-material-deep-orange-A400,
.container-fluid .jumbotron-material-deep-orange-A400 {
  background-color: #ff3d00;
}
body .well-material-deep-orange-A700,
.container .well-material-deep-orange-A700,
.container-fluid .well-material-deep-orange-A700,
body .jumbotron-material-deep-orange-A700,
.container .jumbotron-material-deep-orange-A700,
.container-fluid .jumbotron-material-deep-orange-A700 {
  background-color: #dd2c00;
}
body .well-material-brown,
.container .well-material-brown,
.container-fluid .well-material-brown,
body .jumbotron-material-brown,
.container .jumbotron-material-brown,
.container-fluid .jumbotron-material-brown {
  background-color: #795548;
}
body .well-material-brown-50,
.container .well-material-brown-50,
.container-fluid .well-material-brown-50,
body .jumbotron-material-brown-50,
.container .jumbotron-material-brown-50,
.container-fluid .jumbotron-material-brown-50 {
  background-color: #efebe9;
}
body .well-material-brown-100,
.container .well-material-brown-100,
.container-fluid .well-material-brown-100,
body .jumbotron-material-brown-100,
.container .jumbotron-material-brown-100,
.container-fluid .jumbotron-material-brown-100 {
  background-color: #d7ccc8;
}
body .well-material-brown-200,
.container .well-material-brown-200,
.container-fluid .well-material-brown-200,
body .jumbotron-material-brown-200,
.container .jumbotron-material-brown-200,
.container-fluid .jumbotron-material-brown-200 {
  background-color: #bcaaa4;
}
body .well-material-brown-300,
.container .well-material-brown-300,
.container-fluid .well-material-brown-300,
body .jumbotron-material-brown-300,
.container .jumbotron-material-brown-300,
.container-fluid .jumbotron-material-brown-300 {
  background-color: #a1887f;
}
body .well-material-brown-400,
.container .well-material-brown-400,
.container-fluid .well-material-brown-400,
body .jumbotron-material-brown-400,
.container .jumbotron-material-brown-400,
.container-fluid .jumbotron-material-brown-400 {
  background-color: #8d6e63;
}
body .well-material-brown-500,
.container .well-material-brown-500,
.container-fluid .well-material-brown-500,
body .jumbotron-material-brown-500,
.container .jumbotron-material-brown-500,
.container-fluid .jumbotron-material-brown-500 {
  background-color: #795548;
}
body .well-material-brown-600,
.container .well-material-brown-600,
.container-fluid .well-material-brown-600,
body .jumbotron-material-brown-600,
.container .jumbotron-material-brown-600,
.container-fluid .jumbotron-material-brown-600 {
  background-color: #6d4c41;
}
body .well-material-brown-700,
.container .well-material-brown-700,
.container-fluid .well-material-brown-700,
body .jumbotron-material-brown-700,
.container .jumbotron-material-brown-700,
.container-fluid .jumbotron-material-brown-700 {
  background-color: #5d4037;
}
body .well-material-brown-800,
.container .well-material-brown-800,
.container-fluid .well-material-brown-800,
body .jumbotron-material-brown-800,
.container .jumbotron-material-brown-800,
.container-fluid .jumbotron-material-brown-800 {
  background-color: #4e342e;
}
body .well-material-brown-900,
.container .well-material-brown-900,
.container-fluid .well-material-brown-900,
body .jumbotron-material-brown-900,
.container .jumbotron-material-brown-900,
.container-fluid .jumbotron-material-brown-900 {
  background-color: #3e2723;
}
body .well-material-brown-A100,
.container .well-material-brown-A100,
.container-fluid .well-material-brown-A100,
body .jumbotron-material-brown-A100,
.container .jumbotron-material-brown-A100,
.container-fluid .jumbotron-material-brown-A100 {
  background-color: #d7ccc8;
}
body .well-material-brown-A200,
.container .well-material-brown-A200,
.container-fluid .well-material-brown-A200,
body .jumbotron-material-brown-A200,
.container .jumbotron-material-brown-A200,
.container-fluid .jumbotron-material-brown-A200 {
  background-color: #bcaaa4;
}
body .well-material-brown-A400,
.container .well-material-brown-A400,
.container-fluid .well-material-brown-A400,
body .jumbotron-material-brown-A400,
.container .jumbotron-material-brown-A400,
.container-fluid .jumbotron-material-brown-A400 {
  background-color: #8d6e63;
}
body .well-material-brown-A700,
.container .well-material-brown-A700,
.container-fluid .well-material-brown-A700,
body .jumbotron-material-brown-A700,
.container .jumbotron-material-brown-A700,
.container-fluid .jumbotron-material-brown-A700 {
  background-color: #5d4037;
}
body .well-material-grey,
.container .well-material-grey,
.container-fluid .well-material-grey,
body .jumbotron-material-grey,
.container .jumbotron-material-grey,
.container-fluid .jumbotron-material-grey {
  background-color: #9e9e9e;
}
body .well-material-grey-50,
.container .well-material-grey-50,
.container-fluid .well-material-grey-50,
body .jumbotron-material-grey-50,
.container .jumbotron-material-grey-50,
.container-fluid .jumbotron-material-grey-50 {
  background-color: #fafafa;
}
body .well-material-grey-100,
.container .well-material-grey-100,
.container-fluid .well-material-grey-100,
body .jumbotron-material-grey-100,
.container .jumbotron-material-grey-100,
.container-fluid .jumbotron-material-grey-100 {
  background-color: #f5f5f5;
}
body .well-material-grey-200,
.container .well-material-grey-200,
.container-fluid .well-material-grey-200,
body .jumbotron-material-grey-200,
.container .jumbotron-material-grey-200,
.container-fluid .jumbotron-material-grey-200 {
  background-color: #eeeeee;
}
body .well-material-grey-300,
.container .well-material-grey-300,
.container-fluid .well-material-grey-300,
body .jumbotron-material-grey-300,
.container .jumbotron-material-grey-300,
.container-fluid .jumbotron-material-grey-300 {
  background-color: #e0e0e0;
}
body .well-material-grey-400,
.container .well-material-grey-400,
.container-fluid .well-material-grey-400,
body .jumbotron-material-grey-400,
.container .jumbotron-material-grey-400,
.container-fluid .jumbotron-material-grey-400 {
  background-color: #bdbdbd;
}
body .well-material-grey-500,
.container .well-material-grey-500,
.container-fluid .well-material-grey-500,
body .jumbotron-material-grey-500,
.container .jumbotron-material-grey-500,
.container-fluid .jumbotron-material-grey-500 {
  background-color: #9e9e9e;
}
body .well-material-grey-600,
.container .well-material-grey-600,
.container-fluid .well-material-grey-600,
body .jumbotron-material-grey-600,
.container .jumbotron-material-grey-600,
.container-fluid .jumbotron-material-grey-600 {
  background-color: #757575;
}
body .well-material-grey-700,
.container .well-material-grey-700,
.container-fluid .well-material-grey-700,
body .jumbotron-material-grey-700,
.container .jumbotron-material-grey-700,
.container-fluid .jumbotron-material-grey-700 {
  background-color: #616161;
}
body .well-material-grey-800,
.container .well-material-grey-800,
.container-fluid .well-material-grey-800,
body .jumbotron-material-grey-800,
.container .jumbotron-material-grey-800,
.container-fluid .jumbotron-material-grey-800 {
  background-color: #424242;
}
body .well-material-grey-900,
.container .well-material-grey-900,
.container-fluid .well-material-grey-900,
body .jumbotron-material-grey-900,
.container .jumbotron-material-grey-900,
.container-fluid .jumbotron-material-grey-900 {
  background-color: #212121;
}
body .well-material-grey-A100,
.container .well-material-grey-A100,
.container-fluid .well-material-grey-A100,
body .jumbotron-material-grey-A100,
.container .jumbotron-material-grey-A100,
.container-fluid .jumbotron-material-grey-A100 {
  background-color: #f5f5f5;
}
body .well-material-grey-A200,
.container .well-material-grey-A200,
.container-fluid .well-material-grey-A200,
body .jumbotron-material-grey-A200,
.container .jumbotron-material-grey-A200,
.container-fluid .jumbotron-material-grey-A200 {
  background-color: #eeeeee;
}
body .well-material-grey-A400,
.container .well-material-grey-A400,
.container-fluid .well-material-grey-A400,
body .jumbotron-material-grey-A400,
.container .jumbotron-material-grey-A400,
.container-fluid .jumbotron-material-grey-A400 {
  background-color: #bdbdbd;
}
body .well-material-grey-A700,
.container .well-material-grey-A700,
.container-fluid .well-material-grey-A700,
body .jumbotron-material-grey-A700,
.container .jumbotron-material-grey-A700,
.container-fluid .jumbotron-material-grey-A700 {
  background-color: #616161;
}
body .well-material-blue-grey,
.container .well-material-blue-grey,
.container-fluid .well-material-blue-grey,
body .jumbotron-material-blue-grey,
.container .jumbotron-material-blue-grey,
.container-fluid .jumbotron-material-blue-grey {
  background-color: #607d8b;
}
body .well-material-blue-grey-50,
.container .well-material-blue-grey-50,
.container-fluid .well-material-blue-grey-50,
body .jumbotron-material-blue-grey-50,
.container .jumbotron-material-blue-grey-50,
.container-fluid .jumbotron-material-blue-grey-50 {
  background-color: #eceff1;
}
body .well-material-blue-grey-100,
.container .well-material-blue-grey-100,
.container-fluid .well-material-blue-grey-100,
body .jumbotron-material-blue-grey-100,
.container .jumbotron-material-blue-grey-100,
.container-fluid .jumbotron-material-blue-grey-100 {
  background-color: #cfd8dc;
}
body .well-material-blue-grey-200,
.container .well-material-blue-grey-200,
.container-fluid .well-material-blue-grey-200,
body .jumbotron-material-blue-grey-200,
.container .jumbotron-material-blue-grey-200,
.container-fluid .jumbotron-material-blue-grey-200 {
  background-color: #b0bec5;
}
body .well-material-blue-grey-300,
.container .well-material-blue-grey-300,
.container-fluid .well-material-blue-grey-300,
body .jumbotron-material-blue-grey-300,
.container .jumbotron-material-blue-grey-300,
.container-fluid .jumbotron-material-blue-grey-300 {
  background-color: #90a4ae;
}
body .well-material-blue-grey-400,
.container .well-material-blue-grey-400,
.container-fluid .well-material-blue-grey-400,
body .jumbotron-material-blue-grey-400,
.container .jumbotron-material-blue-grey-400,
.container-fluid .jumbotron-material-blue-grey-400 {
  background-color: #78909c;
}
body .well-material-blue-grey-500,
.container .well-material-blue-grey-500,
.container-fluid .well-material-blue-grey-500,
body .jumbotron-material-blue-grey-500,
.container .jumbotron-material-blue-grey-500,
.container-fluid .jumbotron-material-blue-grey-500 {
  background-color: #607d8b;
}
body .well-material-blue-grey-600,
.container .well-material-blue-grey-600,
.container-fluid .well-material-blue-grey-600,
body .jumbotron-material-blue-grey-600,
.container .jumbotron-material-blue-grey-600,
.container-fluid .jumbotron-material-blue-grey-600 {
  background-color: #546e7a;
}
body .well-material-blue-grey-700,
.container .well-material-blue-grey-700,
.container-fluid .well-material-blue-grey-700,
body .jumbotron-material-blue-grey-700,
.container .jumbotron-material-blue-grey-700,
.container-fluid .jumbotron-material-blue-grey-700 {
  background-color: #455a64;
}
body .well-material-blue-grey-800,
.container .well-material-blue-grey-800,
.container-fluid .well-material-blue-grey-800,
body .jumbotron-material-blue-grey-800,
.container .jumbotron-material-blue-grey-800,
.container-fluid .jumbotron-material-blue-grey-800 {
  background-color: #37474f;
}
body .well-material-blue-grey-900,
.container .well-material-blue-grey-900,
.container-fluid .well-material-blue-grey-900,
body .jumbotron-material-blue-grey-900,
.container .jumbotron-material-blue-grey-900,
.container-fluid .jumbotron-material-blue-grey-900 {
  background-color: #263238;
}
body .well-material-blue-grey-A100,
.container .well-material-blue-grey-A100,
.container-fluid .well-material-blue-grey-A100,
body .jumbotron-material-blue-grey-A100,
.container .jumbotron-material-blue-grey-A100,
.container-fluid .jumbotron-material-blue-grey-A100 {
  background-color: #cfd8dc;
}
body .well-material-blue-grey-A200,
.container .well-material-blue-grey-A200,
.container-fluid .well-material-blue-grey-A200,
body .jumbotron-material-blue-grey-A200,
.container .jumbotron-material-blue-grey-A200,
.container-fluid .jumbotron-material-blue-grey-A200 {
  background-color: #b0bec5;
}
body .well-material-blue-grey-A400,
.container .well-material-blue-grey-A400,
.container-fluid .well-material-blue-grey-A400,
body .jumbotron-material-blue-grey-A400,
.container .jumbotron-material-blue-grey-A400,
.container-fluid .jumbotron-material-blue-grey-A400 {
  background-color: #78909c;
}
body .well-material-blue-grey-A700,
.container .well-material-blue-grey-A700,
.container-fluid .well-material-blue-grey-A700,
body .jumbotron-material-blue-grey-A700,
.container .jumbotron-material-blue-grey-A700,
.container-fluid .jumbotron-material-blue-grey-A700 {
  background-color: #455a64;
}
.btn {
  position: relative;
  padding: 8px 30px;
  border: 0;
  margin: 10px 1px;
  cursor: pointer;
  border-radius: 2px;
  text-transform: uppercase;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.84);
  transition: background-color 0.2s ease, box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  outline: none !important;
}
.btn:hover:not(.btn-link):not(.btn-flat):not(.btn-fab) {
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
}
.btn:active:not(.btn-link):not(.btn-flat):not(.btn-fab) {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.btn.btn-flat:not(.btn-link),
.btn-default.btn-flat:not(.btn-link) {
  color: rgba(0, 0, 0, 0.84);
}
.btn-black.btn-flat:not(.btn-link) {
  color: #000000;
}
.btn-white.btn-flat:not(.btn-link) {
  color: #ffffff;
}
.btn-inverse.btn-flat:not(.btn-link) {
  color: #3f51b5;
}
.btn-primary.btn-flat:not(.btn-link) {
  color: #009688;
}
.btn-success.btn-flat:not(.btn-link) {
  color: #4caf50;
}
.btn-info.btn-flat:not(.btn-link) {
  color: #03a9f4;
}
.btn-warning.btn-flat:not(.btn-link) {
  color: #ff5722;
}
.btn-danger.btn-flat:not(.btn-link) {
  color: #f44336;
}
.btn-material-red.btn-flat:not(.btn-link) {
  color: #f44336;
}
.btn-material-red-50.btn-flat:not(.btn-link) {
  color: #ffebee;
}
.btn-material-red-100.btn-flat:not(.btn-link) {
  color: #ffcdd2;
}
.btn-material-red-200.btn-flat:not(.btn-link) {
  color: #ef9a9a;
}
.btn-material-red-300.btn-flat:not(.btn-link) {
  color: #e57373;
}
.btn-material-red-400.btn-flat:not(.btn-link) {
  color: #ef5350;
}
.btn-material-red-500.btn-flat:not(.btn-link) {
  color: #f44336;
}
.btn-material-red-600.btn-flat:not(.btn-link) {
  color: #e53935;
}
.btn-material-red-700.btn-flat:not(.btn-link) {
  color: #d32f2f;
}
.btn-material-red-800.btn-flat:not(.btn-link) {
  color: #c62828;
}
.btn-material-red-900.btn-flat:not(.btn-link) {
  color: #b71c1c;
}
.btn-material-red-A100.btn-flat:not(.btn-link) {
  color: #ff8a80;
}
.btn-material-red-A200.btn-flat:not(.btn-link) {
  color: #ff5252;
}
.btn-material-red-A400.btn-flat:not(.btn-link) {
  color: #ff1744;
}
.btn-material-red-A700.btn-flat:not(.btn-link) {
  color: #d50000;
}
.btn-material-pink.btn-flat:not(.btn-link) {
  color: #e91e63;
}
.btn-material-pink-50.btn-flat:not(.btn-link) {
  color: #fce4ec;
}
.btn-material-pink-100.btn-flat:not(.btn-link) {
  color: #f8bbd0;
}
.btn-material-pink-200.btn-flat:not(.btn-link) {
  color: #f48fb1;
}
.btn-material-pink-300.btn-flat:not(.btn-link) {
  color: #f06292;
}
.btn-material-pink-400.btn-flat:not(.btn-link) {
  color: #ec407a;
}
.btn-material-pink-500.btn-flat:not(.btn-link) {
  color: #e91e63;
}
.btn-material-pink-600.btn-flat:not(.btn-link) {
  color: #d81b60;
}
.btn-material-pink-700.btn-flat:not(.btn-link) {
  color: #c2185b;
}
.btn-material-pink-800.btn-flat:not(.btn-link) {
  color: #ad1457;
}
.btn-material-pink-900.btn-flat:not(.btn-link) {
  color: #880e4f;
}
.btn-material-pink-A100.btn-flat:not(.btn-link) {
  color: #ff80ab;
}
.btn-material-pink-A200.btn-flat:not(.btn-link) {
  color: #ff4081;
}
.btn-material-pink-A400.btn-flat:not(.btn-link) {
  color: #f50057;
}
.btn-material-pink-A700.btn-flat:not(.btn-link) {
  color: #c51162;
}
.btn-material-purple.btn-flat:not(.btn-link) {
  color: #9c27b0;
}
.btn-material-purple-50.btn-flat:not(.btn-link) {
  color: #f3e5f5;
}
.btn-material-purple-100.btn-flat:not(.btn-link) {
  color: #e1bee7;
}
.btn-material-purple-200.btn-flat:not(.btn-link) {
  color: #ce93d8;
}
.btn-material-purple-300.btn-flat:not(.btn-link) {
  color: #ba68c8;
}
.btn-material-purple-400.btn-flat:not(.btn-link) {
  color: #ab47bc;
}
.btn-material-purple-500.btn-flat:not(.btn-link) {
  color: #9c27b0;
}
.btn-material-purple-600.btn-flat:not(.btn-link) {
  color: #8e24aa;
}
.btn-material-purple-700.btn-flat:not(.btn-link) {
  color: #7b1fa2;
}
.btn-material-purple-800.btn-flat:not(.btn-link) {
  color: #6a1b9a;
}
.btn-material-purple-900.btn-flat:not(.btn-link) {
  color: #4a148c;
}
.btn-material-purple-A100.btn-flat:not(.btn-link) {
  color: #ea80fc;
}
.btn-material-purple-A200.btn-flat:not(.btn-link) {
  color: #e040fb;
}
.btn-material-purple-A400.btn-flat:not(.btn-link) {
  color: #d500f9;
}
.btn-material-purple-A700.btn-flat:not(.btn-link) {
  color: #aa00ff;
}
.btn-material-deep-purple.btn-flat:not(.btn-link) {
  color: #673ab7;
}
.btn-material-deep-purple-50.btn-flat:not(.btn-link) {
  color: #ede7f6;
}
.btn-material-deep-purple-100.btn-flat:not(.btn-link) {
  color: #d1c4e9;
}
.btn-material-deep-purple-200.btn-flat:not(.btn-link) {
  color: #b39ddb;
}
.btn-material-deep-purple-300.btn-flat:not(.btn-link) {
  color: #9575cd;
}
.btn-material-deep-purple-400.btn-flat:not(.btn-link) {
  color: #7e57c2;
}
.btn-material-deep-purple-500.btn-flat:not(.btn-link) {
  color: #673ab7;
}
.btn-material-deep-purple-600.btn-flat:not(.btn-link) {
  color: #5e35b1;
}
.btn-material-deep-purple-700.btn-flat:not(.btn-link) {
  color: #512da8;
}
.btn-material-deep-purple-800.btn-flat:not(.btn-link) {
  color: #4527a0;
}
.btn-material-deep-purple-900.btn-flat:not(.btn-link) {
  color: #311b92;
}
.btn-material-deep-purple-A100.btn-flat:not(.btn-link) {
  color: #b388ff;
}
.btn-material-deep-purple-A200.btn-flat:not(.btn-link) {
  color: #7c4dff;
}
.btn-material-deep-purple-A400.btn-flat:not(.btn-link) {
  color: #651fff;
}
.btn-material-deep-purple-A700.btn-flat:not(.btn-link) {
  color: #6200ea;
}
.btn-material-indigo.btn-flat:not(.btn-link) {
  color: #3f51b5;
}
.btn-material-indigo-50.btn-flat:not(.btn-link) {
  color: #e8eaf6;
}
.btn-material-indigo-100.btn-flat:not(.btn-link) {
  color: #c5cae9;
}
.btn-material-indigo-200.btn-flat:not(.btn-link) {
  color: #9fa8da;
}
.btn-material-indigo-300.btn-flat:not(.btn-link) {
  color: #7986cb;
}
.btn-material-indigo-400.btn-flat:not(.btn-link) {
  color: #5c6bc0;
}
.btn-material-indigo-500.btn-flat:not(.btn-link) {
  color: #3f51b5;
}
.btn-material-indigo-600.btn-flat:not(.btn-link) {
  color: #3949ab;
}
.btn-material-indigo-700.btn-flat:not(.btn-link) {
  color: #303f9f;
}
.btn-material-indigo-800.btn-flat:not(.btn-link) {
  color: #283593;
}
.btn-material-indigo-900.btn-flat:not(.btn-link) {
  color: #1a237e;
}
.btn-material-indigo-A100.btn-flat:not(.btn-link) {
  color: #8c9eff;
}
.btn-material-indigo-A200.btn-flat:not(.btn-link) {
  color: #536dfe;
}
.btn-material-indigo-A400.btn-flat:not(.btn-link) {
  color: #3d5afe;
}
.btn-material-indigo-A700.btn-flat:not(.btn-link) {
  color: #304ffe;
}
.btn-material-blue.btn-flat:not(.btn-link) {
  color: #2196f3;
}
.btn-material-blue-50.btn-flat:not(.btn-link) {
  color: #e3f2fd;
}
.btn-material-blue-100.btn-flat:not(.btn-link) {
  color: #bbdefb;
}
.btn-material-blue-200.btn-flat:not(.btn-link) {
  color: #90caf9;
}
.btn-material-blue-300.btn-flat:not(.btn-link) {
  color: #64b5f6;
}
.btn-material-blue-400.btn-flat:not(.btn-link) {
  color: #42a5f5;
}
.btn-material-blue-500.btn-flat:not(.btn-link) {
  color: #2196f3;
}
.btn-material-blue-600.btn-flat:not(.btn-link) {
  color: #1e88e5;
}
.btn-material-blue-700.btn-flat:not(.btn-link) {
  color: #1976d2;
}
.btn-material-blue-800.btn-flat:not(.btn-link) {
  color: #1565c0;
}
.btn-material-blue-900.btn-flat:not(.btn-link) {
  color: #0d47a1;
}
.btn-material-blue-A100.btn-flat:not(.btn-link) {
  color: #82b1ff;
}
.btn-material-blue-A200.btn-flat:not(.btn-link) {
  color: #448aff;
}
.btn-material-blue-A400.btn-flat:not(.btn-link) {
  color: #2979ff;
}
.btn-material-blue-A700.btn-flat:not(.btn-link) {
  color: #2962ff;
}
.btn-material-light-blue.btn-flat:not(.btn-link) {
  color: #03a9f4;
}
.btn-material-light-blue-50.btn-flat:not(.btn-link) {
  color: #e1f5fe;
}
.btn-material-light-blue-100.btn-flat:not(.btn-link) {
  color: #b3e5fc;
}
.btn-material-light-blue-200.btn-flat:not(.btn-link) {
  color: #81d4fa;
}
.btn-material-light-blue-300.btn-flat:not(.btn-link) {
  color: #4fc3f7;
}
.btn-material-light-blue-400.btn-flat:not(.btn-link) {
  color: #29b6f6;
}
.btn-material-light-blue-500.btn-flat:not(.btn-link) {
  color: #03a9f4;
}
.btn-material-light-blue-600.btn-flat:not(.btn-link) {
  color: #039be5;
}
.btn-material-light-blue-700.btn-flat:not(.btn-link) {
  color: #0288d1;
}
.btn-material-light-blue-800.btn-flat:not(.btn-link) {
  color: #0277bd;
}
.btn-material-light-blue-900.btn-flat:not(.btn-link) {
  color: #01579b;
}
.btn-material-light-blue-A100.btn-flat:not(.btn-link) {
  color: #80d8ff;
}
.btn-material-light-blue-A200.btn-flat:not(.btn-link) {
  color: #40c4ff;
}
.btn-material-light-blue-A400.btn-flat:not(.btn-link) {
  color: #00b0ff;
}
.btn-material-light-blue-A700.btn-flat:not(.btn-link) {
  color: #0091ea;
}
.btn-material-cyan.btn-flat:not(.btn-link) {
  color: #00bcd4;
}
.btn-material-cyan-50.btn-flat:not(.btn-link) {
  color: #e0f7fa;
}
.btn-material-cyan-100.btn-flat:not(.btn-link) {
  color: #b2ebf2;
}
.btn-material-cyan-200.btn-flat:not(.btn-link) {
  color: #80deea;
}
.btn-material-cyan-300.btn-flat:not(.btn-link) {
  color: #4dd0e1;
}
.btn-material-cyan-400.btn-flat:not(.btn-link) {
  color: #26c6da;
}
.btn-material-cyan-500.btn-flat:not(.btn-link) {
  color: #00bcd4;
}
.btn-material-cyan-600.btn-flat:not(.btn-link) {
  color: #00acc1;
}
.btn-material-cyan-700.btn-flat:not(.btn-link) {
  color: #0097a7;
}
.btn-material-cyan-800.btn-flat:not(.btn-link) {
  color: #00838f;
}
.btn-material-cyan-900.btn-flat:not(.btn-link) {
  color: #006064;
}
.btn-material-cyan-A100.btn-flat:not(.btn-link) {
  color: #84ffff;
}
.btn-material-cyan-A200.btn-flat:not(.btn-link) {
  color: #18ffff;
}
.btn-material-cyan-A400.btn-flat:not(.btn-link) {
  color: #00e5ff;
}
.btn-material-cyan-A700.btn-flat:not(.btn-link) {
  color: #00b8d4;
}
.btn-material-teal.btn-flat:not(.btn-link) {
  color: #009688;
}
.btn-material-teal-50.btn-flat:not(.btn-link) {
  color: #e0f2f1;
}
.btn-material-teal-100.btn-flat:not(.btn-link) {
  color: #b2dfdb;
}
.btn-material-teal-200.btn-flat:not(.btn-link) {
  color: #80cbc4;
}
.btn-material-teal-300.btn-flat:not(.btn-link) {
  color: #4db6ac;
}
.btn-material-teal-400.btn-flat:not(.btn-link) {
  color: #26a69a;
}
.btn-material-teal-500.btn-flat:not(.btn-link) {
  color: #009688;
}
.btn-material-teal-600.btn-flat:not(.btn-link) {
  color: #00897b;
}
.btn-material-teal-700.btn-flat:not(.btn-link) {
  color: #00796b;
}
.btn-material-teal-800.btn-flat:not(.btn-link) {
  color: #00695c;
}
.btn-material-teal-900.btn-flat:not(.btn-link) {
  color: #004d40;
}
.btn-material-teal-A100.btn-flat:not(.btn-link) {
  color: #a7ffeb;
}
.btn-material-teal-A200.btn-flat:not(.btn-link) {
  color: #64ffda;
}
.btn-material-teal-A400.btn-flat:not(.btn-link) {
  color: #1de9b6;
}
.btn-material-teal-A700.btn-flat:not(.btn-link) {
  color: #00bfa5;
}
.btn-material-green.btn-flat:not(.btn-link) {
  color: #4caf50;
}
.btn-material-green-50.btn-flat:not(.btn-link) {
  color: #e8f5e9;
}
.btn-material-green-100.btn-flat:not(.btn-link) {
  color: #c8e6c9;
}
.btn-material-green-200.btn-flat:not(.btn-link) {
  color: #a5d6a7;
}
.btn-material-green-300.btn-flat:not(.btn-link) {
  color: #81c784;
}
.btn-material-green-400.btn-flat:not(.btn-link) {
  color: #66bb6a;
}
.btn-material-green-500.btn-flat:not(.btn-link) {
  color: #4caf50;
}
.btn-material-green-600.btn-flat:not(.btn-link) {
  color: #43a047;
}
.btn-material-green-700.btn-flat:not(.btn-link) {
  color: #388e3c;
}
.btn-material-green-800.btn-flat:not(.btn-link) {
  color: #2e7d32;
}
.btn-material-green-900.btn-flat:not(.btn-link) {
  color: #1b5e20;
}
.btn-material-green-A100.btn-flat:not(.btn-link) {
  color: #b9f6ca;
}
.btn-material-green-A200.btn-flat:not(.btn-link) {
  color: #69f0ae;
}
.btn-material-green-A400.btn-flat:not(.btn-link) {
  color: #00e676;
}
.btn-material-green-A700.btn-flat:not(.btn-link) {
  color: #00c853;
}
.btn-material-light-green.btn-flat:not(.btn-link) {
  color: #8bc34a;
}
.btn-material-light-green-50.btn-flat:not(.btn-link) {
  color: #f1f8e9;
}
.btn-material-light-green-100.btn-flat:not(.btn-link) {
  color: #dcedc8;
}
.btn-material-light-green-200.btn-flat:not(.btn-link) {
  color: #c5e1a5;
}
.btn-material-light-green-300.btn-flat:not(.btn-link) {
  color: #aed581;
}
.btn-material-light-green-400.btn-flat:not(.btn-link) {
  color: #9ccc65;
}
.btn-material-light-green-500.btn-flat:not(.btn-link) {
  color: #8bc34a;
}
.btn-material-light-green-600.btn-flat:not(.btn-link) {
  color: #7cb342;
}
.btn-material-light-green-700.btn-flat:not(.btn-link) {
  color: #689f38;
}
.btn-material-light-green-800.btn-flat:not(.btn-link) {
  color: #558b2f;
}
.btn-material-light-green-900.btn-flat:not(.btn-link) {
  color: #33691e;
}
.btn-material-light-green-A100.btn-flat:not(.btn-link) {
  color: #ccff90;
}
.btn-material-light-green-A200.btn-flat:not(.btn-link) {
  color: #b2ff59;
}
.btn-material-light-green-A400.btn-flat:not(.btn-link) {
  color: #76ff03;
}
.btn-material-light-green-A700.btn-flat:not(.btn-link) {
  color: #64dd17;
}
.btn-material-lime.btn-flat:not(.btn-link) {
  color: #cddc39;
}
.btn-material-lime-50.btn-flat:not(.btn-link) {
  color: #f9fbe7;
}
.btn-material-lime-100.btn-flat:not(.btn-link) {
  color: #f0f4c3;
}
.btn-material-lime-200.btn-flat:not(.btn-link) {
  color: #e6ee9c;
}
.btn-material-lime-300.btn-flat:not(.btn-link) {
  color: #dce775;
}
.btn-material-lime-400.btn-flat:not(.btn-link) {
  color: #d4e157;
}
.btn-material-lime-500.btn-flat:not(.btn-link) {
  color: #cddc39;
}
.btn-material-lime-600.btn-flat:not(.btn-link) {
  color: #c0ca33;
}
.btn-material-lime-700.btn-flat:not(.btn-link) {
  color: #afb42b;
}
.btn-material-lime-800.btn-flat:not(.btn-link) {
  color: #9e9d24;
}
.btn-material-lime-900.btn-flat:not(.btn-link) {
  color: #827717;
}
.btn-material-lime-A100.btn-flat:not(.btn-link) {
  color: #f4ff81;
}
.btn-material-lime-A200.btn-flat:not(.btn-link) {
  color: #eeff41;
}
.btn-material-lime-A400.btn-flat:not(.btn-link) {
  color: #c6ff00;
}
.btn-material-lime-A700.btn-flat:not(.btn-link) {
  color: #aeea00;
}
.btn-material-yellow.btn-flat:not(.btn-link) {
  color: #ffeb3b;
}
.btn-material-yellow-50.btn-flat:not(.btn-link) {
  color: #fffde7;
}
.btn-material-yellow-100.btn-flat:not(.btn-link) {
  color: #fff9c4;
}
.btn-material-yellow-200.btn-flat:not(.btn-link) {
  color: #fff59d;
}
.btn-material-yellow-300.btn-flat:not(.btn-link) {
  color: #fff176;
}
.btn-material-yellow-400.btn-flat:not(.btn-link) {
  color: #ffee58;
}
.btn-material-yellow-500.btn-flat:not(.btn-link) {
  color: #ffeb3b;
}
.btn-material-yellow-600.btn-flat:not(.btn-link) {
  color: #fdd835;
}
.btn-material-yellow-700.btn-flat:not(.btn-link) {
  color: #fbc02d;
}
.btn-material-yellow-800.btn-flat:not(.btn-link) {
  color: #f9a825;
}
.btn-material-yellow-900.btn-flat:not(.btn-link) {
  color: #f57f17;
}
.btn-material-yellow-A100.btn-flat:not(.btn-link) {
  color: #ffff8d;
}
.btn-material-yellow-A200.btn-flat:not(.btn-link) {
  color: #ffff00;
}
.btn-material-yellow-A400.btn-flat:not(.btn-link) {
  color: #ffea00;
}
.btn-material-yellow-A700.btn-flat:not(.btn-link) {
  color: #ffd600;
}
.btn-material-amber.btn-flat:not(.btn-link) {
  color: #ffc107;
}
.btn-material-amber-50.btn-flat:not(.btn-link) {
  color: #fff8e1;
}
.btn-material-amber-100.btn-flat:not(.btn-link) {
  color: #ffecb3;
}
.btn-material-amber-200.btn-flat:not(.btn-link) {
  color: #ffe082;
}
.btn-material-amber-300.btn-flat:not(.btn-link) {
  color: #ffd54f;
}
.btn-material-amber-400.btn-flat:not(.btn-link) {
  color: #ffca28;
}
.btn-material-amber-500.btn-flat:not(.btn-link) {
  color: #ffc107;
}
.btn-material-amber-600.btn-flat:not(.btn-link) {
  color: #ffb300;
}
.btn-material-amber-700.btn-flat:not(.btn-link) {
  color: #ffa000;
}
.btn-material-amber-800.btn-flat:not(.btn-link) {
  color: #ff8f00;
}
.btn-material-amber-900.btn-flat:not(.btn-link) {
  color: #ff6f00;
}
.btn-material-amber-A100.btn-flat:not(.btn-link) {
  color: #ffe57f;
}
.btn-material-amber-A200.btn-flat:not(.btn-link) {
  color: #ffd740;
}
.btn-material-amber-A400.btn-flat:not(.btn-link) {
  color: #ffc400;
}
.btn-material-amber-A700.btn-flat:not(.btn-link) {
  color: #ffab00;
}
.btn-material-orange.btn-flat:not(.btn-link) {
  color: #ff9800;
}
.btn-material-orange-50.btn-flat:not(.btn-link) {
  color: #fff3e0;
}
.btn-material-orange-100.btn-flat:not(.btn-link) {
  color: #ffe0b2;
}
.btn-material-orange-200.btn-flat:not(.btn-link) {
  color: #ffcc80;
}
.btn-material-orange-300.btn-flat:not(.btn-link) {
  color: #ffb74d;
}
.btn-material-orange-400.btn-flat:not(.btn-link) {
  color: #ffa726;
}
.btn-material-orange-500.btn-flat:not(.btn-link) {
  color: #ff9800;
}
.btn-material-orange-600.btn-flat:not(.btn-link) {
  color: #fb8c00;
}
.btn-material-orange-700.btn-flat:not(.btn-link) {
  color: #f57c00;
}
.btn-material-orange-800.btn-flat:not(.btn-link) {
  color: #ef6c00;
}
.btn-material-orange-900.btn-flat:not(.btn-link) {
  color: #e65100;
}
.btn-material-orange-A100.btn-flat:not(.btn-link) {
  color: #ffd180;
}
.btn-material-orange-A200.btn-flat:not(.btn-link) {
  color: #ffab40;
}
.btn-material-orange-A400.btn-flat:not(.btn-link) {
  color: #ff9100;
}
.btn-material-orange-A700.btn-flat:not(.btn-link) {
  color: #ff6d00;
}
.btn-material-deep-orange.btn-flat:not(.btn-link) {
  color: #ff5722;
}
.btn-material-deep-orange-50.btn-flat:not(.btn-link) {
  color: #fbe9e7;
}
.btn-material-deep-orange-100.btn-flat:not(.btn-link) {
  color: #ffccbc;
}
.btn-material-deep-orange-200.btn-flat:not(.btn-link) {
  color: #ffab91;
}
.btn-material-deep-orange-300.btn-flat:not(.btn-link) {
  color: #ff8a65;
}
.btn-material-deep-orange-400.btn-flat:not(.btn-link) {
  color: #ff7043;
}
.btn-material-deep-orange-500.btn-flat:not(.btn-link) {
  color: #ff5722;
}
.btn-material-deep-orange-600.btn-flat:not(.btn-link) {
  color: #f4511e;
}
.btn-material-deep-orange-700.btn-flat:not(.btn-link) {
  color: #e64a19;
}
.btn-material-deep-orange-800.btn-flat:not(.btn-link) {
  color: #d84315;
}
.btn-material-deep-orange-900.btn-flat:not(.btn-link) {
  color: #bf360c;
}
.btn-material-deep-orange-A100.btn-flat:not(.btn-link) {
  color: #ff9e80;
}
.btn-material-deep-orange-A200.btn-flat:not(.btn-link) {
  color: #ff6e40;
}
.btn-material-deep-orange-A400.btn-flat:not(.btn-link) {
  color: #ff3d00;
}
.btn-material-deep-orange-A700.btn-flat:not(.btn-link) {
  color: #dd2c00;
}
.btn-material-brown.btn-flat:not(.btn-link) {
  color: #795548;
}
.btn-material-brown-50.btn-flat:not(.btn-link) {
  color: #efebe9;
}
.btn-material-brown-100.btn-flat:not(.btn-link) {
  color: #d7ccc8;
}
.btn-material-brown-200.btn-flat:not(.btn-link) {
  color: #bcaaa4;
}
.btn-material-brown-300.btn-flat:not(.btn-link) {
  color: #a1887f;
}
.btn-material-brown-400.btn-flat:not(.btn-link) {
  color: #8d6e63;
}
.btn-material-brown-500.btn-flat:not(.btn-link) {
  color: #795548;
}
.btn-material-brown-600.btn-flat:not(.btn-link) {
  color: #6d4c41;
}
.btn-material-brown-700.btn-flat:not(.btn-link) {
  color: #5d4037;
}
.btn-material-brown-800.btn-flat:not(.btn-link) {
  color: #4e342e;
}
.btn-material-brown-900.btn-flat:not(.btn-link) {
  color: #3e2723;
}
.btn-material-brown-A100.btn-flat:not(.btn-link) {
  color: #d7ccc8;
}
.btn-material-brown-A200.btn-flat:not(.btn-link) {
  color: #bcaaa4;
}
.btn-material-brown-A400.btn-flat:not(.btn-link) {
  color: #8d6e63;
}
.btn-material-brown-A700.btn-flat:not(.btn-link) {
  color: #5d4037;
}
.btn-material-grey.btn-flat:not(.btn-link) {
  color: #9e9e9e;
}
.btn-material-grey-50.btn-flat:not(.btn-link) {
  color: #fafafa;
}
.btn-material-grey-100.btn-flat:not(.btn-link) {
  color: #f5f5f5;
}
.btn-material-grey-200.btn-flat:not(.btn-link) {
  color: #eeeeee;
}
.btn-material-grey-300.btn-flat:not(.btn-link) {
  color: #e0e0e0;
}
.btn-material-grey-400.btn-flat:not(.btn-link) {
  color: #bdbdbd;
}
.btn-material-grey-500.btn-flat:not(.btn-link) {
  color: #9e9e9e;
}
.btn-material-grey-600.btn-flat:not(.btn-link) {
  color: #757575;
}
.btn-material-grey-700.btn-flat:not(.btn-link) {
  color: #616161;
}
.btn-material-grey-800.btn-flat:not(.btn-link) {
  color: #424242;
}
.btn-material-grey-900.btn-flat:not(.btn-link) {
  color: #212121;
}
.btn-material-grey-A100.btn-flat:not(.btn-link) {
  color: #f5f5f5;
}
.btn-material-grey-A200.btn-flat:not(.btn-link) {
  color: #eeeeee;
}
.btn-material-grey-A400.btn-flat:not(.btn-link) {
  color: #bdbdbd;
}
.btn-material-grey-A700.btn-flat:not(.btn-link) {
  color: #616161;
}
.btn-material-blue-grey.btn-flat:not(.btn-link) {
  color: #607d8b;
}
.btn-material-blue-grey-50.btn-flat:not(.btn-link) {
  color: #eceff1;
}
.btn-material-blue-grey-100.btn-flat:not(.btn-link) {
  color: #cfd8dc;
}
.btn-material-blue-grey-200.btn-flat:not(.btn-link) {
  color: #b0bec5;
}
.btn-material-blue-grey-300.btn-flat:not(.btn-link) {
  color: #90a4ae;
}
.btn-material-blue-grey-400.btn-flat:not(.btn-link) {
  color: #78909c;
}
.btn-material-blue-grey-500.btn-flat:not(.btn-link) {
  color: #607d8b;
}
.btn-material-blue-grey-600.btn-flat:not(.btn-link) {
  color: #546e7a;
}
.btn-material-blue-grey-700.btn-flat:not(.btn-link) {
  color: #455a64;
}
.btn-material-blue-grey-800.btn-flat:not(.btn-link) {
  color: #37474f;
}
.btn-material-blue-grey-900.btn-flat:not(.btn-link) {
  color: #263238;
}
.btn-material-blue-grey-A100.btn-flat:not(.btn-link) {
  color: #cfd8dc;
}
.btn-material-blue-grey-A200.btn-flat:not(.btn-link) {
  color: #b0bec5;
}
.btn-material-blue-grey-A400.btn-flat:not(.btn-link) {
  color: #78909c;
}
.btn-material-blue-grey-A700.btn-flat:not(.btn-link) {
  color: #455a64;
}
.btn:not(.btn-link):not(.btn-flat),
.btn-default:not(.btn-link):not(.btn-flat) {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.84);
}
.btn-black:not(.btn-link):not(.btn-flat) {
  background-color: #000000;
  color: rgba(255, 255, 255, 0.84);
}
.btn-white:not(.btn-link):not(.btn-flat) {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.84);
}
.btn-inverse:not(.btn-link):not(.btn-flat) {
  background-color: #3f51b5;
  color: rgba(255, 255, 255, 0.84);
}
.btn-primary:not(.btn-link):not(.btn-flat) {
  background-color: #009688;
  color: rgba(255, 255, 255, 0.84);
}
.btn-success:not(.btn-link):not(.btn-flat) {
  background-color: #4caf50;
  color: rgba(255, 255, 255, 0.84);
}
.btn-info:not(.btn-link):not(.btn-flat) {
  background-color: #03a9f4;
  color: rgba(255, 255, 255, 0.84);
}
.btn-warning:not(.btn-link):not(.btn-flat) {
  background-color: #ff5722;
  color: rgba(255, 255, 255, 0.84);
}
.btn-danger:not(.btn-link):not(.btn-flat) {
  background-color: #f44336;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-red:not(.btn-link):not(.btn-flat) {
  background-color: #f44336;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-red-50:not(.btn-link):not(.btn-flat) {
  background-color: #ffebee;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-red-100:not(.btn-link):not(.btn-flat) {
  background-color: #ffcdd2;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-red-200:not(.btn-link):not(.btn-flat) {
  background-color: #ef9a9a;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-red-300:not(.btn-link):not(.btn-flat) {
  background-color: #e57373;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-red-400:not(.btn-link):not(.btn-flat) {
  background-color: #ef5350;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-red-500:not(.btn-link):not(.btn-flat) {
  background-color: #f44336;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-red-600:not(.btn-link):not(.btn-flat) {
  background-color: #e53935;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-red-700:not(.btn-link):not(.btn-flat) {
  background-color: #d32f2f;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-red-800:not(.btn-link):not(.btn-flat) {
  background-color: #c62828;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-red-900:not(.btn-link):not(.btn-flat) {
  background-color: #b71c1c;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-red-A100:not(.btn-link):not(.btn-flat) {
  background-color: #ff8a80;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-red-A200:not(.btn-link):not(.btn-flat) {
  background-color: #ff5252;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-red-A400:not(.btn-link):not(.btn-flat) {
  background-color: #ff1744;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-red-A700:not(.btn-link):not(.btn-flat) {
  background-color: #d50000;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-pink:not(.btn-link):not(.btn-flat) {
  background-color: #e91e63;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-pink-50:not(.btn-link):not(.btn-flat) {
  background-color: #fce4ec;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-pink-100:not(.btn-link):not(.btn-flat) {
  background-color: #f8bbd0;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-pink-200:not(.btn-link):not(.btn-flat) {
  background-color: #f48fb1;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-pink-300:not(.btn-link):not(.btn-flat) {
  background-color: #f06292;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-pink-400:not(.btn-link):not(.btn-flat) {
  background-color: #ec407a;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-pink-500:not(.btn-link):not(.btn-flat) {
  background-color: #e91e63;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-pink-600:not(.btn-link):not(.btn-flat) {
  background-color: #d81b60;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-pink-700:not(.btn-link):not(.btn-flat) {
  background-color: #c2185b;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-pink-800:not(.btn-link):not(.btn-flat) {
  background-color: #ad1457;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-pink-900:not(.btn-link):not(.btn-flat) {
  background-color: #880e4f;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-pink-A100:not(.btn-link):not(.btn-flat) {
  background-color: #ff80ab;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-pink-A200:not(.btn-link):not(.btn-flat) {
  background-color: #ff4081;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-pink-A400:not(.btn-link):not(.btn-flat) {
  background-color: #f50057;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-pink-A700:not(.btn-link):not(.btn-flat) {
  background-color: #c51162;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-purple:not(.btn-link):not(.btn-flat) {
  background-color: #9c27b0;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-purple-50:not(.btn-link):not(.btn-flat) {
  background-color: #f3e5f5;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-purple-100:not(.btn-link):not(.btn-flat) {
  background-color: #e1bee7;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-purple-200:not(.btn-link):not(.btn-flat) {
  background-color: #ce93d8;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-purple-300:not(.btn-link):not(.btn-flat) {
  background-color: #ba68c8;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-purple-400:not(.btn-link):not(.btn-flat) {
  background-color: #ab47bc;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-purple-500:not(.btn-link):not(.btn-flat) {
  background-color: #9c27b0;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-purple-600:not(.btn-link):not(.btn-flat) {
  background-color: #8e24aa;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-purple-700:not(.btn-link):not(.btn-flat) {
  background-color: #7b1fa2;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-purple-800:not(.btn-link):not(.btn-flat) {
  background-color: #6a1b9a;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-purple-900:not(.btn-link):not(.btn-flat) {
  background-color: #4a148c;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-purple-A100:not(.btn-link):not(.btn-flat) {
  background-color: #ea80fc;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-purple-A200:not(.btn-link):not(.btn-flat) {
  background-color: #e040fb;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-purple-A400:not(.btn-link):not(.btn-flat) {
  background-color: #d500f9;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-purple-A700:not(.btn-link):not(.btn-flat) {
  background-color: #aa00ff;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-purple:not(.btn-link):not(.btn-flat) {
  background-color: #673ab7;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-purple-50:not(.btn-link):not(.btn-flat) {
  background-color: #ede7f6;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-deep-purple-100:not(.btn-link):not(.btn-flat) {
  background-color: #d1c4e9;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-deep-purple-200:not(.btn-link):not(.btn-flat) {
  background-color: #b39ddb;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-purple-300:not(.btn-link):not(.btn-flat) {
  background-color: #9575cd;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-purple-400:not(.btn-link):not(.btn-flat) {
  background-color: #7e57c2;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-purple-500:not(.btn-link):not(.btn-flat) {
  background-color: #673ab7;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-purple-600:not(.btn-link):not(.btn-flat) {
  background-color: #5e35b1;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-purple-700:not(.btn-link):not(.btn-flat) {
  background-color: #512da8;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-purple-800:not(.btn-link):not(.btn-flat) {
  background-color: #4527a0;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-purple-900:not(.btn-link):not(.btn-flat) {
  background-color: #311b92;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-purple-A100:not(.btn-link):not(.btn-flat) {
  background-color: #b388ff;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-purple-A200:not(.btn-link):not(.btn-flat) {
  background-color: #7c4dff;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-purple-A400:not(.btn-link):not(.btn-flat) {
  background-color: #651fff;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-purple-A700:not(.btn-link):not(.btn-flat) {
  background-color: #6200ea;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-indigo:not(.btn-link):not(.btn-flat) {
  background-color: #3f51b5;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-indigo-50:not(.btn-link):not(.btn-flat) {
  background-color: #e8eaf6;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-indigo-100:not(.btn-link):not(.btn-flat) {
  background-color: #c5cae9;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-indigo-200:not(.btn-link):not(.btn-flat) {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-indigo-300:not(.btn-link):not(.btn-flat) {
  background-color: #7986cb;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-indigo-400:not(.btn-link):not(.btn-flat) {
  background-color: #5c6bc0;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-indigo-500:not(.btn-link):not(.btn-flat) {
  background-color: #3f51b5;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-indigo-600:not(.btn-link):not(.btn-flat) {
  background-color: #3949ab;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-indigo-700:not(.btn-link):not(.btn-flat) {
  background-color: #303f9f;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-indigo-800:not(.btn-link):not(.btn-flat) {
  background-color: #283593;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-indigo-900:not(.btn-link):not(.btn-flat) {
  background-color: #1a237e;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-indigo-A100:not(.btn-link):not(.btn-flat) {
  background-color: #8c9eff;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-indigo-A200:not(.btn-link):not(.btn-flat) {
  background-color: #536dfe;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-indigo-A400:not(.btn-link):not(.btn-flat) {
  background-color: #3d5afe;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-indigo-A700:not(.btn-link):not(.btn-flat) {
  background-color: #304ffe;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue:not(.btn-link):not(.btn-flat) {
  background-color: #2196f3;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-50:not(.btn-link):not(.btn-flat) {
  background-color: #e3f2fd;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-blue-100:not(.btn-link):not(.btn-flat) {
  background-color: #bbdefb;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-blue-200:not(.btn-link):not(.btn-flat) {
  background-color: #90caf9;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-blue-300:not(.btn-link):not(.btn-flat) {
  background-color: #64b5f6;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-blue-400:not(.btn-link):not(.btn-flat) {
  background-color: #42a5f5;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-500:not(.btn-link):not(.btn-flat) {
  background-color: #2196f3;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-600:not(.btn-link):not(.btn-flat) {
  background-color: #1e88e5;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-700:not(.btn-link):not(.btn-flat) {
  background-color: #1976d2;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-800:not(.btn-link):not(.btn-flat) {
  background-color: #1565c0;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-900:not(.btn-link):not(.btn-flat) {
  background-color: #0d47a1;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-A100:not(.btn-link):not(.btn-flat) {
  background-color: #82b1ff;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-blue-A200:not(.btn-link):not(.btn-flat) {
  background-color: #448aff;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-A400:not(.btn-link):not(.btn-flat) {
  background-color: #2979ff;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-A700:not(.btn-link):not(.btn-flat) {
  background-color: #2962ff;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-light-blue:not(.btn-link):not(.btn-flat) {
  background-color: #03a9f4;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-light-blue-50:not(.btn-link):not(.btn-flat) {
  background-color: #e1f5fe;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-blue-100:not(.btn-link):not(.btn-flat) {
  background-color: #b3e5fc;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-blue-200:not(.btn-link):not(.btn-flat) {
  background-color: #81d4fa;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-blue-300:not(.btn-link):not(.btn-flat) {
  background-color: #4fc3f7;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-blue-400:not(.btn-link):not(.btn-flat) {
  background-color: #29b6f6;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-blue-500:not(.btn-link):not(.btn-flat) {
  background-color: #03a9f4;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-light-blue-600:not(.btn-link):not(.btn-flat) {
  background-color: #039be5;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-light-blue-700:not(.btn-link):not(.btn-flat) {
  background-color: #0288d1;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-light-blue-800:not(.btn-link):not(.btn-flat) {
  background-color: #0277bd;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-light-blue-900:not(.btn-link):not(.btn-flat) {
  background-color: #01579b;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-light-blue-A100:not(.btn-link):not(.btn-flat) {
  background-color: #80d8ff;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-blue-A200:not(.btn-link):not(.btn-flat) {
  background-color: #40c4ff;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-blue-A400:not(.btn-link):not(.btn-flat) {
  background-color: #00b0ff;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-light-blue-A700:not(.btn-link):not(.btn-flat) {
  background-color: #0091ea;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-cyan:not(.btn-link):not(.btn-flat) {
  background-color: #00bcd4;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-cyan-50:not(.btn-link):not(.btn-flat) {
  background-color: #e0f7fa;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-cyan-100:not(.btn-link):not(.btn-flat) {
  background-color: #b2ebf2;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-cyan-200:not(.btn-link):not(.btn-flat) {
  background-color: #80deea;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-cyan-300:not(.btn-link):not(.btn-flat) {
  background-color: #4dd0e1;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-cyan-400:not(.btn-link):not(.btn-flat) {
  background-color: #26c6da;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-cyan-500:not(.btn-link):not(.btn-flat) {
  background-color: #00bcd4;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-cyan-600:not(.btn-link):not(.btn-flat) {
  background-color: #00acc1;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-cyan-700:not(.btn-link):not(.btn-flat) {
  background-color: #0097a7;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-cyan-800:not(.btn-link):not(.btn-flat) {
  background-color: #00838f;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-cyan-900:not(.btn-link):not(.btn-flat) {
  background-color: #006064;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-cyan-A100:not(.btn-link):not(.btn-flat) {
  background-color: #84ffff;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-cyan-A200:not(.btn-link):not(.btn-flat) {
  background-color: #18ffff;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-cyan-A400:not(.btn-link):not(.btn-flat) {
  background-color: #00e5ff;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-cyan-A700:not(.btn-link):not(.btn-flat) {
  background-color: #00b8d4;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-teal:not(.btn-link):not(.btn-flat) {
  background-color: #009688;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-teal-50:not(.btn-link):not(.btn-flat) {
  background-color: #e0f2f1;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-teal-100:not(.btn-link):not(.btn-flat) {
  background-color: #b2dfdb;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-teal-200:not(.btn-link):not(.btn-flat) {
  background-color: #80cbc4;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-teal-300:not(.btn-link):not(.btn-flat) {
  background-color: #4db6ac;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-teal-400:not(.btn-link):not(.btn-flat) {
  background-color: #26a69a;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-teal-500:not(.btn-link):not(.btn-flat) {
  background-color: #009688;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-teal-600:not(.btn-link):not(.btn-flat) {
  background-color: #00897b;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-teal-700:not(.btn-link):not(.btn-flat) {
  background-color: #00796b;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-teal-800:not(.btn-link):not(.btn-flat) {
  background-color: #00695c;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-teal-900:not(.btn-link):not(.btn-flat) {
  background-color: #004d40;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-teal-A100:not(.btn-link):not(.btn-flat) {
  background-color: #a7ffeb;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-teal-A200:not(.btn-link):not(.btn-flat) {
  background-color: #64ffda;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-teal-A400:not(.btn-link):not(.btn-flat) {
  background-color: #1de9b6;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-teal-A700:not(.btn-link):not(.btn-flat) {
  background-color: #00bfa5;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-green:not(.btn-link):not(.btn-flat) {
  background-color: #4caf50;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-green-50:not(.btn-link):not(.btn-flat) {
  background-color: #e8f5e9;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-green-100:not(.btn-link):not(.btn-flat) {
  background-color: #c8e6c9;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-green-200:not(.btn-link):not(.btn-flat) {
  background-color: #a5d6a7;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-green-300:not(.btn-link):not(.btn-flat) {
  background-color: #81c784;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-green-400:not(.btn-link):not(.btn-flat) {
  background-color: #66bb6a;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-green-500:not(.btn-link):not(.btn-flat) {
  background-color: #4caf50;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-green-600:not(.btn-link):not(.btn-flat) {
  background-color: #43a047;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-green-700:not(.btn-link):not(.btn-flat) {
  background-color: #388e3c;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-green-800:not(.btn-link):not(.btn-flat) {
  background-color: #2e7d32;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-green-900:not(.btn-link):not(.btn-flat) {
  background-color: #1b5e20;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-green-A100:not(.btn-link):not(.btn-flat) {
  background-color: #b9f6ca;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-green-A200:not(.btn-link):not(.btn-flat) {
  background-color: #69f0ae;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-green-A400:not(.btn-link):not(.btn-flat) {
  background-color: #00e676;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-green-A700:not(.btn-link):not(.btn-flat) {
  background-color: #00c853;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-green:not(.btn-link):not(.btn-flat) {
  background-color: #8bc34a;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-green-50:not(.btn-link):not(.btn-flat) {
  background-color: #f1f8e9;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-green-100:not(.btn-link):not(.btn-flat) {
  background-color: #dcedc8;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-green-200:not(.btn-link):not(.btn-flat) {
  background-color: #c5e1a5;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-green-300:not(.btn-link):not(.btn-flat) {
  background-color: #aed581;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-green-400:not(.btn-link):not(.btn-flat) {
  background-color: #9ccc65;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-green-500:not(.btn-link):not(.btn-flat) {
  background-color: #8bc34a;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-green-600:not(.btn-link):not(.btn-flat) {
  background-color: #7cb342;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-light-green-700:not(.btn-link):not(.btn-flat) {
  background-color: #689f38;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-light-green-800:not(.btn-link):not(.btn-flat) {
  background-color: #558b2f;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-light-green-900:not(.btn-link):not(.btn-flat) {
  background-color: #33691e;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-light-green-A100:not(.btn-link):not(.btn-flat) {
  background-color: #ccff90;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-green-A200:not(.btn-link):not(.btn-flat) {
  background-color: #b2ff59;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-green-A400:not(.btn-link):not(.btn-flat) {
  background-color: #76ff03;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-light-green-A700:not(.btn-link):not(.btn-flat) {
  background-color: #64dd17;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-lime:not(.btn-link):not(.btn-flat) {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-lime-50:not(.btn-link):not(.btn-flat) {
  background-color: #f9fbe7;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-lime-100:not(.btn-link):not(.btn-flat) {
  background-color: #f0f4c3;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-lime-200:not(.btn-link):not(.btn-flat) {
  background-color: #e6ee9c;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-lime-300:not(.btn-link):not(.btn-flat) {
  background-color: #dce775;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-lime-400:not(.btn-link):not(.btn-flat) {
  background-color: #d4e157;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-lime-500:not(.btn-link):not(.btn-flat) {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-lime-600:not(.btn-link):not(.btn-flat) {
  background-color: #c0ca33;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-lime-700:not(.btn-link):not(.btn-flat) {
  background-color: #afb42b;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-lime-800:not(.btn-link):not(.btn-flat) {
  background-color: #9e9d24;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-lime-900:not(.btn-link):not(.btn-flat) {
  background-color: #827717;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-lime-A100:not(.btn-link):not(.btn-flat) {
  background-color: #f4ff81;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-lime-A200:not(.btn-link):not(.btn-flat) {
  background-color: #eeff41;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-lime-A400:not(.btn-link):not(.btn-flat) {
  background-color: #c6ff00;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-lime-A700:not(.btn-link):not(.btn-flat) {
  background-color: #aeea00;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-yellow:not(.btn-link):not(.btn-flat) {
  background-color: #ffeb3b;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-yellow-50:not(.btn-link):not(.btn-flat) {
  background-color: #fffde7;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-yellow-100:not(.btn-link):not(.btn-flat) {
  background-color: #fff9c4;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-yellow-200:not(.btn-link):not(.btn-flat) {
  background-color: #fff59d;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-yellow-300:not(.btn-link):not(.btn-flat) {
  background-color: #fff176;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-yellow-400:not(.btn-link):not(.btn-flat) {
  background-color: #ffee58;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-yellow-500:not(.btn-link):not(.btn-flat) {
  background-color: #ffeb3b;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-yellow-600:not(.btn-link):not(.btn-flat) {
  background-color: #fdd835;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-yellow-700:not(.btn-link):not(.btn-flat) {
  background-color: #fbc02d;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-yellow-800:not(.btn-link):not(.btn-flat) {
  background-color: #f9a825;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-yellow-900:not(.btn-link):not(.btn-flat) {
  background-color: #f57f17;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-yellow-A100:not(.btn-link):not(.btn-flat) {
  background-color: #ffff8d;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-yellow-A200:not(.btn-link):not(.btn-flat) {
  background-color: #ffff00;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-yellow-A400:not(.btn-link):not(.btn-flat) {
  background-color: #ffea00;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-yellow-A700:not(.btn-link):not(.btn-flat) {
  background-color: #ffd600;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-amber:not(.btn-link):not(.btn-flat) {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-amber-50:not(.btn-link):not(.btn-flat) {
  background-color: #fff8e1;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-amber-100:not(.btn-link):not(.btn-flat) {
  background-color: #ffecb3;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-amber-200:not(.btn-link):not(.btn-flat) {
  background-color: #ffe082;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-amber-300:not(.btn-link):not(.btn-flat) {
  background-color: #ffd54f;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-amber-400:not(.btn-link):not(.btn-flat) {
  background-color: #ffca28;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-amber-500:not(.btn-link):not(.btn-flat) {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-amber-600:not(.btn-link):not(.btn-flat) {
  background-color: #ffb300;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-amber-700:not(.btn-link):not(.btn-flat) {
  background-color: #ffa000;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-amber-800:not(.btn-link):not(.btn-flat) {
  background-color: #ff8f00;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-amber-900:not(.btn-link):not(.btn-flat) {
  background-color: #ff6f00;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-amber-A100:not(.btn-link):not(.btn-flat) {
  background-color: #ffe57f;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-amber-A200:not(.btn-link):not(.btn-flat) {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-amber-A400:not(.btn-link):not(.btn-flat) {
  background-color: #ffc400;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-amber-A700:not(.btn-link):not(.btn-flat) {
  background-color: #ffab00;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-orange:not(.btn-link):not(.btn-flat) {
  background-color: #ff9800;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-orange-50:not(.btn-link):not(.btn-flat) {
  background-color: #fff3e0;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-orange-100:not(.btn-link):not(.btn-flat) {
  background-color: #ffe0b2;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-orange-200:not(.btn-link):not(.btn-flat) {
  background-color: #ffcc80;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-orange-300:not(.btn-link):not(.btn-flat) {
  background-color: #ffb74d;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-orange-400:not(.btn-link):not(.btn-flat) {
  background-color: #ffa726;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-orange-500:not(.btn-link):not(.btn-flat) {
  background-color: #ff9800;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-orange-600:not(.btn-link):not(.btn-flat) {
  background-color: #fb8c00;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-orange-700:not(.btn-link):not(.btn-flat) {
  background-color: #f57c00;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-orange-800:not(.btn-link):not(.btn-flat) {
  background-color: #ef6c00;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-orange-900:not(.btn-link):not(.btn-flat) {
  background-color: #e65100;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-orange-A100:not(.btn-link):not(.btn-flat) {
  background-color: #ffd180;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-orange-A200:not(.btn-link):not(.btn-flat) {
  background-color: #ffab40;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-orange-A400:not(.btn-link):not(.btn-flat) {
  background-color: #ff9100;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-orange-A700:not(.btn-link):not(.btn-flat) {
  background-color: #ff6d00;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-orange:not(.btn-link):not(.btn-flat) {
  background-color: #ff5722;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-orange-50:not(.btn-link):not(.btn-flat) {
  background-color: #fbe9e7;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-deep-orange-100:not(.btn-link):not(.btn-flat) {
  background-color: #ffccbc;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-deep-orange-200:not(.btn-link):not(.btn-flat) {
  background-color: #ffab91;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-deep-orange-300:not(.btn-link):not(.btn-flat) {
  background-color: #ff8a65;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-deep-orange-400:not(.btn-link):not(.btn-flat) {
  background-color: #ff7043;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-orange-500:not(.btn-link):not(.btn-flat) {
  background-color: #ff5722;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-orange-600:not(.btn-link):not(.btn-flat) {
  background-color: #f4511e;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-orange-700:not(.btn-link):not(.btn-flat) {
  background-color: #e64a19;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-orange-800:not(.btn-link):not(.btn-flat) {
  background-color: #d84315;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-orange-900:not(.btn-link):not(.btn-flat) {
  background-color: #bf360c;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-orange-A100:not(.btn-link):not(.btn-flat) {
  background-color: #ff9e80;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-deep-orange-A200:not(.btn-link):not(.btn-flat) {
  background-color: #ff6e40;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-orange-A400:not(.btn-link):not(.btn-flat) {
  background-color: #ff3d00;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-deep-orange-A700:not(.btn-link):not(.btn-flat) {
  background-color: #dd2c00;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-brown:not(.btn-link):not(.btn-flat) {
  background-color: #795548;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-brown-50:not(.btn-link):not(.btn-flat) {
  background-color: #efebe9;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-brown-100:not(.btn-link):not(.btn-flat) {
  background-color: #d7ccc8;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-brown-200:not(.btn-link):not(.btn-flat) {
  background-color: #bcaaa4;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-brown-300:not(.btn-link):not(.btn-flat) {
  background-color: #a1887f;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-brown-400:not(.btn-link):not(.btn-flat) {
  background-color: #8d6e63;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-brown-500:not(.btn-link):not(.btn-flat) {
  background-color: #795548;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-brown-600:not(.btn-link):not(.btn-flat) {
  background-color: #6d4c41;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-brown-700:not(.btn-link):not(.btn-flat) {
  background-color: #5d4037;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-brown-800:not(.btn-link):not(.btn-flat) {
  background-color: #4e342e;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-brown-900:not(.btn-link):not(.btn-flat) {
  background-color: #3e2723;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-brown-A100:not(.btn-link):not(.btn-flat) {
  background-color: #d7ccc8;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-brown-A200:not(.btn-link):not(.btn-flat) {
  background-color: #bcaaa4;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-brown-A400:not(.btn-link):not(.btn-flat) {
  background-color: #8d6e63;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-brown-A700:not(.btn-link):not(.btn-flat) {
  background-color: #5d4037;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-grey:not(.btn-link):not(.btn-flat) {
  background-color: #9e9e9e;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-grey-50:not(.btn-link):not(.btn-flat) {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-grey-100:not(.btn-link):not(.btn-flat) {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-grey-200:not(.btn-link):not(.btn-flat) {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-grey-300:not(.btn-link):not(.btn-flat) {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-grey-400:not(.btn-link):not(.btn-flat) {
  background-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-grey-500:not(.btn-link):not(.btn-flat) {
  background-color: #9e9e9e;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-grey-600:not(.btn-link):not(.btn-flat) {
  background-color: #757575;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-grey-700:not(.btn-link):not(.btn-flat) {
  background-color: #616161;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-grey-800:not(.btn-link):not(.btn-flat) {
  background-color: #424242;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-grey-900:not(.btn-link):not(.btn-flat) {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-grey-A100:not(.btn-link):not(.btn-flat) {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-grey-A200:not(.btn-link):not(.btn-flat) {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-grey-A400:not(.btn-link):not(.btn-flat) {
  background-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-grey-A700:not(.btn-link):not(.btn-flat) {
  background-color: #616161;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-grey:not(.btn-link):not(.btn-flat) {
  background-color: #607d8b;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-grey-50:not(.btn-link):not(.btn-flat) {
  background-color: #eceff1;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-blue-grey-100:not(.btn-link):not(.btn-flat) {
  background-color: #cfd8dc;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-blue-grey-200:not(.btn-link):not(.btn-flat) {
  background-color: #b0bec5;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-blue-grey-300:not(.btn-link):not(.btn-flat) {
  background-color: #90a4ae;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-grey-400:not(.btn-link):not(.btn-flat) {
  background-color: #78909c;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-grey-500:not(.btn-link):not(.btn-flat) {
  background-color: #607d8b;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-grey-600:not(.btn-link):not(.btn-flat) {
  background-color: #546e7a;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-grey-700:not(.btn-link):not(.btn-flat) {
  background-color: #455a64;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-grey-800:not(.btn-link):not(.btn-flat) {
  background-color: #37474f;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-grey-900:not(.btn-link):not(.btn-flat) {
  background-color: #263238;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-grey-A100:not(.btn-link):not(.btn-flat) {
  background-color: #cfd8dc;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-blue-grey-A200:not(.btn-link):not(.btn-flat) {
  background-color: #b0bec5;
  color: rgba(0, 0, 0, 0.84);
}
.btn-material-blue-grey-A400:not(.btn-link):not(.btn-flat) {
  background-color: #78909c;
  color: rgba(255, 255, 255, 0.84);
}
.btn-material-blue-grey-A700:not(.btn-link):not(.btn-flat) {
  background-color: #455a64;
  color: rgba(255, 255, 255, 0.84);
}
.btn:hover:not(.btn-link):not(.btn-flat),
.btn-default:hover:not(.btn-link):not(.btn-flat) {
  background-color: rgba(10, 10, 10, 0);
}
.btn-black:hover:not(.btn-link):not(.btn-flat) {
  background-color: #0a0a0a;
}
.btn-white:hover:not(.btn-link):not(.btn-flat) {
  background-color: #f5f5f5;
}
.btn-inverse:hover:not(.btn-link):not(.btn-flat) {
  background-color: #495bc0;
}
.btn-primary:hover:not(.btn-link):not(.btn-flat) {
  background-color: #00aa9a;
}
.btn-success:hover:not(.btn-link):not(.btn-flat) {
  background-color: #59b75c;
}
.btn-info:hover:not(.btn-link):not(.btn-flat) {
  background-color: #0fb2fc;
}
.btn-warning:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ff6736;
}
.btn-danger:hover:not(.btn-link):not(.btn-flat) {
  background-color: #f55549;
}
.btn-material-red:hover:not(.btn-link):not(.btn-flat) {
  background-color: #f55549;
}
.btn-material-red-50:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ffd7dd;
}
.btn-material-red-100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ffb9c0;
}
.btn-material-red-200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ec8888;
}
.btn-material-red-300:hover:not(.btn-link):not(.btn-flat) {
  background-color: #e88484;
}
.btn-material-red-400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #f16563;
}
.btn-material-red-500:hover:not(.btn-link):not(.btn-flat) {
  background-color: #f55549;
}
.btn-material-red-600:hover:not(.btn-link):not(.btn-flat) {
  background-color: #e74b47;
}
.btn-material-red-700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #d74040;
}
.btn-material-red-800:hover:not(.btn-link):not(.btn-flat) {
  background-color: #d52e2e;
}
.btn-material-red-900:hover:not(.btn-link):not(.btn-flat) {
  background-color: #c91f1f;
}
.btn-material-red-A100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ff776c;
}
.btn-material-red-A200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ff6666;
}
.btn-material-red-A400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ff2b54;
}
.btn-material-red-A700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #e90000;
}
.btn-material-pink:hover:not(.btn-link):not(.btn-flat) {
  background-color: #eb3170;
}
.btn-material-pink-50:hover:not(.btn-link):not(.btn-flat) {
  background-color: #fad2df;
}
.btn-material-pink-100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #f6a9c3;
}
.btn-material-pink-200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #f27ca4;
}
.btn-material-pink-300:hover:not(.btn-link):not(.btn-flat) {
  background-color: #f2759f;
}
.btn-material-pink-400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ee5387;
}
.btn-material-pink-500:hover:not(.btn-link):not(.btn-flat) {
  background-color: #eb3170;
}
.btn-material-pink-600:hover:not(.btn-link):not(.btn-flat) {
  background-color: #e4246a;
}
.btn-material-pink-700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #d41a64;
}
.btn-material-pink-800:hover:not(.btn-link):not(.btn-flat) {
  background-color: #bf1660;
}
.btn-material-pink-900:hover:not(.btn-link):not(.btn-flat) {
  background-color: #9a105a;
}
.btn-material-pink-A100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ff94b8;
}
.btn-material-pink-A200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ff548e;
}
.btn-material-pink-A400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ff0a61;
}
.btn-material-pink-A700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #d8136b;
}
.btn-material-purple:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ab2bc1;
}
.btn-material-purple-50:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ecd6ef;
}
.btn-material-purple-100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #daafe1;
}
.btn-material-purple-200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #d5a2dd;
}
.btn-material-purple-300:hover:not(.btn-link):not(.btn-flat) {
  background-color: #c177cd;
}
.btn-material-purple-400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #b256c1;
}
.btn-material-purple-500:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ab2bc1;
}
.btn-material-purple-600:hover:not(.btn-link):not(.btn-flat) {
  background-color: #9c28bb;
}
.btn-material-purple-700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #8822b3;
}
.btn-material-purple-800:hover:not(.btn-link):not(.btn-flat) {
  background-color: #761eab;
}
.btn-material-purple-900:hover:not(.btn-link):not(.btn-flat) {
  background-color: #53179e;
}
.btn-material-purple-A100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ed94fc;
}
.btn-material-purple-A200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #e354fb;
}
.btn-material-purple-A400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #dc0eff;
}
.btn-material-purple-A700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #b114ff;
}
.btn-material-deep-purple:hover:not(.btn-link):not(.btn-flat) {
  background-color: #7142c3;
}
.btn-material-deep-purple-50:hover:not(.btn-link):not(.btn-flat) {
  background-color: #e2d8f0;
}
.btn-material-deep-purple-100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #c5b5e3;
}
.btn-material-deep-purple-200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #bface0;
}
.btn-material-deep-purple-300:hover:not(.btn-link):not(.btn-flat) {
  background-color: #a184d2;
}
.btn-material-deep-purple-400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #8966c7;
}
.btn-material-deep-purple-500:hover:not(.btn-link):not(.btn-flat) {
  background-color: #7142c3;
}
.btn-material-deep-purple-600:hover:not(.btn-link):not(.btn-flat) {
  background-color: #663ac1;
}
.btn-material-deep-purple-700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #5931b8;
}
.btn-material-deep-purple-800:hover:not(.btn-link):not(.btn-flat) {
  background-color: #4c2bb0;
}
.btn-material-deep-purple-900:hover:not(.btn-link):not(.btn-flat) {
  background-color: #371ea3;
}
.btn-material-deep-purple-A100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #c09cff;
}
.btn-material-deep-purple-A200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #8b61ff;
}
.btn-material-deep-purple-A400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #7333ff;
}
.btn-material-deep-purple-A700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #6b00fe;
}
.btn-material-indigo:hover:not(.btn-link):not(.btn-flat) {
  background-color: #495bc0;
}
.btn-material-indigo-50:hover:not(.btn-link):not(.btn-flat) {
  background-color: #d9ddf0;
}
.btn-material-indigo-100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #b6bce3;
}
.btn-material-indigo-200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #909bd4;
}
.btn-material-indigo-300:hover:not(.btn-link):not(.btn-flat) {
  background-color: #8893d1;
}
.btn-material-indigo-400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #6b78c6;
}
.btn-material-indigo-500:hover:not(.btn-link):not(.btn-flat) {
  background-color: #495bc0;
}
.btn-material-indigo-600:hover:not(.btn-link):not(.btn-flat) {
  background-color: #3e50ba;
}
.btn-material-indigo-700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #3545af;
}
.btn-material-indigo-800:hover:not(.btn-link):not(.btn-flat) {
  background-color: #2c3ba3;
}
.btn-material-indigo-900:hover:not(.btn-link):not(.btn-flat) {
  background-color: #1d288f;
}
.btn-material-indigo-A100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #a0afff;
}
.btn-material-indigo-A200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #677efe;
}
.btn-material-indigo-A400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #516bfe;
}
.btn-material-indigo-A700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #4460fe;
}
.btn-material-blue:hover:not(.btn-link):not(.btn-flat) {
  background-color: #349ff4;
}
.btn-material-blue-50:hover:not(.btn-link):not(.btn-flat) {
  background-color: #d0e9fc;
}
.btn-material-blue-100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #a8d5fa;
}
.btn-material-blue-200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #7dc1f8;
}
.btn-material-blue-300:hover:not(.btn-link):not(.btn-flat) {
  background-color: #51acf5;
}
.btn-material-blue-400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #55aef6;
}
.btn-material-blue-500:hover:not(.btn-link):not(.btn-flat) {
  background-color: #349ff4;
}
.btn-material-blue-600:hover:not(.btn-link):not(.btn-flat) {
  background-color: #3092e7;
}
.btn-material-blue-700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #1b80e4;
}
.btn-material-blue-800:hover:not(.btn-link):not(.btn-flat) {
  background-color: #176fd2;
}
.btn-material-blue-900:hover:not(.btn-link):not(.btn-flat) {
  background-color: #0f4fb4;
}
.btn-material-blue-A100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #6ea4ff;
}
.btn-material-blue-A200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #5897ff;
}
.btn-material-blue-A400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #3d86ff;
}
.btn-material-blue-A700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #3d71ff;
}
.btn-material-light-blue:hover:not(.btn-link):not(.btn-flat) {
  background-color: #0fb2fc;
}
.btn-material-light-blue-50:hover:not(.btn-link):not(.btn-flat) {
  background-color: #cdeefd;
}
.btn-material-light-blue-100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #9fdefb;
}
.btn-material-light-blue-200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #6dcdf9;
}
.btn-material-light-blue-300:hover:not(.btn-link):not(.btn-flat) {
  background-color: #3bbcf6;
}
.btn-material-light-blue-400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #15aff5;
}
.btn-material-light-blue-500:hover:not(.btn-link):not(.btn-flat) {
  background-color: #0fb2fc;
}
.btn-material-light-blue-600:hover:not(.btn-link):not(.btn-flat) {
  background-color: #03a9f9;
}
.btn-material-light-blue-700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #0295e5;
}
.btn-material-light-blue-800:hover:not(.btn-link):not(.btn-flat) {
  background-color: #0284d1;
}
.btn-material-light-blue-900:hover:not(.btn-link):not(.btn-flat) {
  background-color: #0162af;
}
.btn-material-light-blue-A100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #6cd2ff;
}
.btn-material-light-blue-A200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #2cbeff;
}
.btn-material-light-blue-A400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #14b6ff;
}
.btn-material-light-blue-A700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #009efe;
}
.btn-material-cyan:hover:not(.btn-link):not(.btn-flat) {
  background-color: #00aac0;
}
.btn-material-cyan-50:hover:not(.btn-link):not(.btn-flat) {
  background-color: #cef2f7;
}
.btn-material-cyan-100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #a1e6ef;
}
.btn-material-cyan-200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #6ed9e7;
}
.btn-material-cyan-300:hover:not(.btn-link):not(.btn-flat) {
  background-color: #3ccbde;
}
.btn-material-cyan-400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #22b7c9;
}
.btn-material-cyan-500:hover:not(.btn-link):not(.btn-flat) {
  background-color: #00aac0;
}
.btn-material-cyan-600:hover:not(.btn-link):not(.btn-flat) {
  background-color: #00bed5;
}
.btn-material-cyan-700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #00a9bb;
}
.btn-material-cyan-800:hover:not(.btn-link):not(.btn-flat) {
  background-color: #0096a3;
}
.btn-material-cyan-900:hover:not(.btn-link):not(.btn-flat) {
  background-color: #007478;
}
.btn-material-cyan-A100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #70ffff;
}
.btn-material-cyan-A200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #04ffff;
}
.btn-material-cyan-A400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #00d3eb;
}
.btn-material-cyan-A700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #00cae8;
}
.btn-material-teal:hover:not(.btn-link):not(.btn-flat) {
  background-color: #00aa9a;
}
.btn-material-teal-50:hover:not(.btn-link):not(.btn-flat) {
  background-color: #d2eceb;
}
.btn-material-teal-100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #a4d9d4;
}
.btn-material-teal-200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #72c5bd;
}
.btn-material-teal-300:hover:not(.btn-link):not(.btn-flat) {
  background-color: #5bbcb3;
}
.btn-material-teal-400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #2ab7a9;
}
.btn-material-teal-500:hover:not(.btn-link):not(.btn-flat) {
  background-color: #00aa9a;
}
.btn-material-teal-600:hover:not(.btn-link):not(.btn-flat) {
  background-color: #009d8d;
}
.btn-material-teal-700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #008d7d;
}
.btn-material-teal-800:hover:not(.btn-link):not(.btn-flat) {
  background-color: #007d6e;
}
.btn-material-teal-900:hover:not(.btn-link):not(.btn-flat) {
  background-color: #006151;
}
.btn-material-teal-A100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #93ffe6;
}
.btn-material-teal-A200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #50ffd5;
}
.btn-material-teal-A400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #15dcaa;
}
.btn-material-teal-A700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #00d3b7;
}
.btn-material-green:hover:not(.btn-link):not(.btn-flat) {
  background-color: #59b75c;
}
.btn-material-green-50:hover:not(.btn-link):not(.btn-flat) {
  background-color: #daefdb;
}
.btn-material-green-100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #bae0bb;
}
.btn-material-green-200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #97d099;
}
.btn-material-green-300:hover:not(.btn-link):not(.btn-flat) {
  background-color: #73c176;
}
.btn-material-green-400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #74c178;
}
.btn-material-green-500:hover:not(.btn-link):not(.btn-flat) {
  background-color: #59b75c;
}
.btn-material-green-600:hover:not(.btn-link):not(.btn-flat) {
  background-color: #49ae4d;
}
.btn-material-green-700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #3e9d42;
}
.btn-material-green-800:hover:not(.btn-link):not(.btn-flat) {
  background-color: #338c38;
}
.btn-material-green-900:hover:not(.btn-link):not(.btn-flat) {
  background-color: #206e25;
}
.btn-material-green-A100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #a7f4bc;
}
.btn-material-green-A200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #56eea4;
}
.btn-material-green-A400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #00d26c;
}
.btn-material-green-A700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #00b44b;
}
.btn-material-light-green:hover:not(.btn-link):not(.btn-flat) {
  background-color: #81bb3e;
}
.btn-material-light-green-50:hover:not(.btn-link):not(.btn-flat) {
  background-color: #e7f3da;
}
.btn-material-light-green-100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #d2e8b9;
}
.btn-material-light-green-200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #bbdc96;
}
.btn-material-light-green-300:hover:not(.btn-link):not(.btn-flat) {
  background-color: #a4d072;
}
.btn-material-light-green-400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #92c756;
}
.btn-material-light-green-500:hover:not(.btn-link):not(.btn-flat) {
  background-color: #81bb3e;
}
.btn-material-light-green-600:hover:not(.btn-link):not(.btn-flat) {
  background-color: #86bd4c;
}
.btn-material-light-green-700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #72ae3d;
}
.btn-material-light-green-800:hover:not(.btn-link):not(.btn-flat) {
  background-color: #5e9a34;
}
.btn-material-light-green-900:hover:not(.btn-link):not(.btn-flat) {
  background-color: #3b7923;
}
.btn-material-light-green-A100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #c3ff7c;
}
.btn-material-light-green-A200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #a9ff45;
}
.btn-material-light-green-A400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #6cee00;
}
.btn-material-light-green-A700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #5ccb15;
}
.btn-material-lime:hover:not(.btn-link):not(.btn-flat) {
  background-color: #c9d928;
}
.btn-material-lime-50:hover:not(.btn-link):not(.btn-flat) {
  background-color: #f5f8d6;
}
.btn-material-lime-100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ecf1b2;
}
.btn-material-lime-200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #e2eb8b;
}
.btn-material-lime-300:hover:not(.btn-link):not(.btn-flat) {
  background-color: #d8e464;
}
.btn-material-lime-400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #d0de46;
}
.btn-material-lime-500:hover:not(.btn-link):not(.btn-flat) {
  background-color: #c9d928;
}
.btn-material-lime-600:hover:not(.btn-link):not(.btn-flat) {
  background-color: #b1ba2f;
}
.btn-material-lime-700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #9fa427;
}
.btn-material-lime-800:hover:not(.btn-link):not(.btn-flat) {
  background-color: #afae28;
}
.btn-material-lime-900:hover:not(.btn-link):not(.btn-flat) {
  background-color: #93871a;
}
.btn-material-lime-A100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #f2ff6d;
}
.btn-material-lime-A200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ecff2d;
}
.btn-material-lime-A400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #b6eb00;
}
.btn-material-lime-A700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #9fd600;
}
.btn-material-yellow:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ffe927;
}
.btn-material-yellow-50:hover:not(.btn-link):not(.btn-flat) {
  background-color: #fffbd3;
}
.btn-material-yellow-100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #fff7b0;
}
.btn-material-yellow-200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #fff389;
}
.btn-material-yellow-300:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ffef62;
}
.btn-material-yellow-400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ffec44;
}
.btn-material-yellow-500:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ffe927;
}
.btn-material-yellow-600:hover:not(.btn-link):not(.btn-flat) {
  background-color: #fdd421;
}
.btn-material-yellow-700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #fbba19;
}
.btn-material-yellow-800:hover:not(.btn-link):not(.btn-flat) {
  background-color: #f8a011;
}
.btn-material-yellow-900:hover:not(.btn-link):not(.btn-flat) {
  background-color: #f68a2b;
}
.btn-material-yellow-A100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ffff79;
}
.btn-material-yellow-A200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ebeb00;
}
.btn-material-yellow-A400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ebd700;
}
.btn-material-yellow-A700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ebc500;
}
.btn-material-amber:hover:not(.btn-link):not(.btn-flat) {
  background-color: #f2b500;
}
.btn-material-amber-50:hover:not(.btn-link):not(.btn-flat) {
  background-color: #fff3cd;
}
.btn-material-amber-100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ffe79f;
}
.btn-material-amber-200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ffdb6e;
}
.btn-material-amber-300:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ffd03b;
}
.btn-material-amber-400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ffc514;
}
.btn-material-amber-500:hover:not(.btn-link):not(.btn-flat) {
  background-color: #f2b500;
}
.btn-material-amber-600:hover:not(.btn-link):not(.btn-flat) {
  background-color: #eba500;
}
.btn-material-amber-700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #eb9300;
}
.btn-material-amber-800:hover:not(.btn-link):not(.btn-flat) {
  background-color: #eb8400;
}
.btn-material-amber-900:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ff7b14;
}
.btn-material-amber-A100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ffe16b;
}
.btn-material-amber-A200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ffd32c;
}
.btn-material-amber-A400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ebb400;
}
.btn-material-amber-A700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #eb9d00;
}
.btn-material-orange:hover:not(.btn-link):not(.btn-flat) {
  background-color: #eb8c00;
}
.btn-material-orange-50:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ffebcc;
}
.btn-material-orange-100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ffd89e;
}
.btn-material-orange-200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ffc46c;
}
.btn-material-orange-300:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ffaf39;
}
.btn-material-orange-400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ff9f12;
}
.btn-material-orange-500:hover:not(.btn-link):not(.btn-flat) {
  background-color: #eb8c00;
}
.btn-material-orange-600:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ff9510;
}
.btn-material-orange-700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ff860a;
}
.btn-material-orange-800:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ff7604;
}
.btn-material-orange-900:hover:not(.btn-link):not(.btn-flat) {
  background-color: #fa5800;
}
.btn-material-orange-A100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ffca6c;
}
.btn-material-orange-A200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ffa22c;
}
.btn-material-orange-A400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #eb8500;
}
.btn-material-orange-A700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ff7914;
}
.btn-material-deep-orange:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ff6736;
}
.btn-material-deep-orange-50:hover:not(.btn-link):not(.btn-flat) {
  background-color: #f8d9d6;
}
.btn-material-deep-orange-100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ffbca8;
}
.btn-material-deep-orange-200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ff9b7d;
}
.btn-material-deep-orange-300:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ff7b51;
}
.btn-material-deep-orange-400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ff8057;
}
.btn-material-deep-orange-500:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ff6736;
}
.btn-material-deep-orange-600:hover:not(.btn-link):not(.btn-flat) {
  background-color: #f56031;
}
.btn-material-deep-orange-700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #e8582b;
}
.btn-material-deep-orange-800:hover:not(.btn-link):not(.btn-flat) {
  background-color: #e94a19;
}
.btn-material-deep-orange-900:hover:not(.btn-link):not(.btn-flat) {
  background-color: #d23b0d;
}
.btn-material-deep-orange-A100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ff8e6c;
}
.btn-material-deep-orange-A200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ff7d54;
}
.btn-material-deep-orange-A400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ff4d14;
}
.btn-material-deep-orange-A700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #f13000;
}
.btn-material-brown:hover:not(.btn-link):not(.btn-flat) {
  background-color: #865e50;
}
.btn-material-brown-50:hover:not(.btn-link):not(.btn-flat) {
  background-color: #e6e0dd;
}
.btn-material-brown-100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #cec1bc;
}
.btn-material-brown-200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #b39f98;
}
.btn-material-brown-300:hover:not(.btn-link):not(.btn-flat) {
  background-color: #aa938b;
}
.btn-material-brown-400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #98786c;
}
.btn-material-brown-500:hover:not(.btn-link):not(.btn-flat) {
  background-color: #865e50;
}
.btn-material-brown-600:hover:not(.btn-link):not(.btn-flat) {
  background-color: #7a5549;
}
.btn-material-brown-700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #6a493f;
}
.btn-material-brown-800:hover:not(.btn-link):not(.btn-flat) {
  background-color: #5b3d36;
}
.btn-material-brown-900:hover:not(.btn-link):not(.btn-flat) {
  background-color: #4b2f2a;
}
.btn-material-brown-A100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #cec1bc;
}
.btn-material-brown-A200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #b39f98;
}
.btn-material-brown-A400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #98786c;
}
.btn-material-brown-A700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #6a493f;
}
.btn-material-grey:hover:not(.btn-link):not(.btn-flat) {
  background-color: #a8a8a8;
}
.btn-material-grey-50:hover:not(.btn-link):not(.btn-flat) {
  background-color: #f0f0f0;
}
.btn-material-grey-100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ebebeb;
}
.btn-material-grey-200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #e4e4e4;
}
.btn-material-grey-300:hover:not(.btn-link):not(.btn-flat) {
  background-color: #d6d6d6;
}
.btn-material-grey-400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #b3b3b3;
}
.btn-material-grey-500:hover:not(.btn-link):not(.btn-flat) {
  background-color: #a8a8a8;
}
.btn-material-grey-600:hover:not(.btn-link):not(.btn-flat) {
  background-color: #7f7f7f;
}
.btn-material-grey-700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #6b6b6b;
}
.btn-material-grey-800:hover:not(.btn-link):not(.btn-flat) {
  background-color: #4c4c4c;
}
.btn-material-grey-900:hover:not(.btn-link):not(.btn-flat) {
  background-color: #2b2b2b;
}
.btn-material-grey-A100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #ebebeb;
}
.btn-material-grey-A200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #e4e4e4;
}
.btn-material-grey-A400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #b3b3b3;
}
.btn-material-grey-A700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #6b6b6b;
}
.btn-material-blue-grey:hover:not(.btn-link):not(.btn-flat) {
  background-color: #688897;
}
.btn-material-blue-grey-50:hover:not(.btn-link):not(.btn-flat) {
  background-color: #e0e5e8;
}
.btn-material-blue-grey-100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #c3ced3;
}
.btn-material-blue-grey-200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #a4b4bc;
}
.btn-material-blue-grey-300:hover:not(.btn-link):not(.btn-flat) {
  background-color: #9caeb7;
}
.btn-material-blue-grey-400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #849aa5;
}
.btn-material-blue-grey-500:hover:not(.btn-link):not(.btn-flat) {
  background-color: #688897;
}
.btn-material-blue-grey-600:hover:not(.btn-link):not(.btn-flat) {
  background-color: #5c7986;
}
.btn-material-blue-grey-700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #4d6570;
}
.btn-material-blue-grey-800:hover:not(.btn-link):not(.btn-flat) {
  background-color: #3f525b;
}
.btn-material-blue-grey-900:hover:not(.btn-link):not(.btn-flat) {
  background-color: #2e3d44;
}
.btn-material-blue-grey-A100:hover:not(.btn-link):not(.btn-flat) {
  background-color: #c3ced3;
}
.btn-material-blue-grey-A200:hover:not(.btn-link):not(.btn-flat) {
  background-color: #a4b4bc;
}
.btn-material-blue-grey-A400:hover:not(.btn-link):not(.btn-flat) {
  background-color: #849aa5;
}
.btn-material-blue-grey-A700:hover:not(.btn-link):not(.btn-flat) {
  background-color: #4d6570;
}
.btn:active:not(.btn-link):not(.btn-flat),
.btn-default:active:not(.btn-link):not(.btn-flat) {
  background-color: rgba(15, 15, 15, 0);
}
.btn-black:active:not(.btn-link):not(.btn-flat) {
  background-color: #0f0f0f;
}
.btn-white:active:not(.btn-link):not(.btn-flat) {
  background-color: #f0f0f0;
}
.btn-inverse:active:not(.btn-link):not(.btn-flat) {
  background-color: #5062c2;
}
.btn-primary:active:not(.btn-link):not(.btn-flat) {
  background-color: #00b5a4;
}
.btn-success:active:not(.btn-link):not(.btn-flat) {
  background-color: #60ba63;
}
.btn-info:active:not(.btn-link):not(.btn-flat) {
  background-color: #19b6fc;
}
.btn-warning:active:not(.btn-link):not(.btn-flat) {
  background-color: #ff6e41;
}
.btn-danger:active:not(.btn-link):not(.btn-flat) {
  background-color: #f65e53;
}
.btn-material-red:active:not(.btn-link):not(.btn-flat) {
  background-color: #f65e53;
}
.btn-material-red-50:active:not(.btn-link):not(.btn-flat) {
  background-color: #ffccd4;
}
.btn-material-red-100:active:not(.btn-link):not(.btn-flat) {
  background-color: #ffaeb6;
}
.btn-material-red-200:active:not(.btn-link):not(.btn-flat) {
  background-color: #eb8080;
}
.btn-material-red-300:active:not(.btn-link):not(.btn-flat) {
  background-color: #ea8d8d;
}
.btn-material-red-400:active:not(.btn-link):not(.btn-flat) {
  background-color: #f26f6c;
}
.btn-material-red-500:active:not(.btn-link):not(.btn-flat) {
  background-color: #f65e53;
}
.btn-material-red-600:active:not(.btn-link):not(.btn-flat) {
  background-color: #e85450;
}
.btn-material-red-700:active:not(.btn-link):not(.btn-flat) {
  background-color: #d84848;
}
.btn-material-red-800:active:not(.btn-link):not(.btn-flat) {
  background-color: #d63636;
}
.btn-material-red-900:active:not(.btn-link):not(.btn-flat) {
  background-color: #d22020;
}
.btn-material-red-A100:active:not(.btn-link):not(.btn-flat) {
  background-color: #ff6e61;
}
.btn-material-red-A200:active:not(.btn-link):not(.btn-flat) {
  background-color: #ff7171;
}
.btn-material-red-A400:active:not(.btn-link):not(.btn-flat) {
  background-color: #ff365d;
}
.btn-material-red-A700:active:not(.btn-link):not(.btn-flat) {
  background-color: #f40000;
}
.btn-material-pink:active:not(.btn-link):not(.btn-flat) {
  background-color: #ec3a76;
}
.btn-material-pink-50:active:not(.btn-link):not(.btn-flat) {
  background-color: #f9c8d9;
}
.btn-material-pink-100:active:not(.btn-link):not(.btn-flat) {
  background-color: #f59fbd;
}
.btn-material-pink-200:active:not(.btn-link):not(.btn-flat) {
  background-color: #f1739e;
}
.btn-material-pink-300:active:not(.btn-link):not(.btn-flat) {
  background-color: #f37ea5;
}
.btn-material-pink-400:active:not(.btn-link):not(.btn-flat) {
  background-color: #ef5c8d;
}
.btn-material-pink-500:active:not(.btn-link):not(.btn-flat) {
  background-color: #ec3a76;
}
.btn-material-pink-600:active:not(.btn-link):not(.btn-flat) {
  background-color: #e52d70;
}
.btn-material-pink-700:active:not(.btn-link):not(.btn-flat) {
  background-color: #dd1b68;
}
.btn-material-pink-800:active:not(.btn-link):not(.btn-flat) {
  background-color: #c81765;
}
.btn-material-pink-900:active:not(.btn-link):not(.btn-flat) {
  background-color: #a4115f;
}
.btn-material-pink-A100:active:not(.btn-link):not(.btn-flat) {
  background-color: #ff9fbf;
}
.btn-material-pink-A200:active:not(.btn-link):not(.btn-flat) {
  background-color: #ff5f95;
}
.btn-material-pink-A400:active:not(.btn-link):not(.btn-flat) {
  background-color: #ff1568;
}
.btn-material-pink-A700:active:not(.btn-link):not(.btn-flat) {
  background-color: #e11370;
}
.btn-material-purple:active:not(.btn-link):not(.btn-flat) {
  background-color: #b22dc9;
}
.btn-material-purple-50:active:not(.btn-link):not(.btn-flat) {
  background-color: #e9cfec;
}
.btn-material-purple-100:active:not(.btn-link):not(.btn-flat) {
  background-color: #d7a8df;
}
.btn-material-purple-200:active:not(.btn-link):not(.btn-flat) {
  background-color: #d8a9e0;
}
.btn-material-purple-300:active:not(.btn-link):not(.btn-flat) {
  background-color: #c47ed0;
}
.btn-material-purple-400:active:not(.btn-link):not(.btn-flat) {
  background-color: #b55dc4;
}
.btn-material-purple-500:active:not(.btn-link):not(.btn-flat) {
  background-color: #b22dc9;
}
.btn-material-purple-600:active:not(.btn-link):not(.btn-flat) {
  background-color: #a329c3;
}
.btn-material-purple-700:active:not(.btn-link):not(.btn-flat) {
  background-color: #8f24bc;
}
.btn-material-purple-800:active:not(.btn-link):not(.btn-flat) {
  background-color: #7c20b4;
}
.btn-material-purple-900:active:not(.btn-link):not(.btn-flat) {
  background-color: #5818a7;
}
.btn-material-purple-A100:active:not(.btn-link):not(.btn-flat) {
  background-color: #ef9efd;
}
.btn-material-purple-A200:active:not(.btn-link):not(.btn-flat) {
  background-color: #e55efc;
}
.btn-material-purple-A400:active:not(.btn-link):not(.btn-flat) {
  background-color: #de19ff;
}
.btn-material-purple-A700:active:not(.btn-link):not(.btn-flat) {
  background-color: #b41fff;
}
.btn-material-deep-purple:active:not(.btn-link):not(.btn-flat) {
  background-color: #764ac6;
}
.btn-material-deep-purple-50:active:not(.btn-link):not(.btn-flat) {
  background-color: #dcd1ee;
}
.btn-material-deep-purple-100:active:not(.btn-link):not(.btn-flat) {
  background-color: #c0aee1;
}
.btn-material-deep-purple-200:active:not(.btn-link):not(.btn-flat) {
  background-color: #c4b3e3;
}
.btn-material-deep-purple-300:active:not(.btn-link):not(.btn-flat) {
  background-color: #a68bd5;
}
.btn-material-deep-purple-400:active:not(.btn-link):not(.btn-flat) {
  background-color: #8f6dca;
}
.btn-material-deep-purple-500:active:not(.btn-link):not(.btn-flat) {
  background-color: #764ac6;
}
.btn-material-deep-purple-600:active:not(.btn-link):not(.btn-flat) {
  background-color: #6c3fc6;
}
.btn-material-deep-purple-700:active:not(.btn-link):not(.btn-flat) {
  background-color: #5d33c0;
}
.btn-material-deep-purple-800:active:not(.btn-link):not(.btn-flat) {
  background-color: #502db9;
}
.btn-material-deep-purple-900:active:not(.btn-link):not(.btn-flat) {
  background-color: #3a20ac;
}
.btn-material-deep-purple-A100:active:not(.btn-link):not(.btn-flat) {
  background-color: #c7a7ff;
}
.btn-material-deep-purple-A200:active:not(.btn-link):not(.btn-flat) {
  background-color: #936cff;
}
.btn-material-deep-purple-A400:active:not(.btn-link):not(.btn-flat) {
  background-color: #7a3eff;
}
.btn-material-deep-purple-A700:active:not(.btn-link):not(.btn-flat) {
  background-color: #700aff;
}
.btn-material-indigo:active:not(.btn-link):not(.btn-flat) {
  background-color: #5062c2;
}
.btn-material-indigo-50:active:not(.btn-link):not(.btn-flat) {
  background-color: #d2d6ed;
}
.btn-material-indigo-100:active:not(.btn-link):not(.btn-flat) {
  background-color: #afb6e1;
}
.btn-material-indigo-200:active:not(.btn-link):not(.btn-flat) {
  background-color: #8994d1;
}
.btn-material-indigo-300:active:not(.btn-link):not(.btn-flat) {
  background-color: #8f9ad4;
}
.btn-material-indigo-400:active:not(.btn-link):not(.btn-flat) {
  background-color: #727fc9;
}
.btn-material-indigo-500:active:not(.btn-link):not(.btn-flat) {
  background-color: #5062c2;
}
.btn-material-indigo-600:active:not(.btn-link):not(.btn-flat) {
  background-color: #4254c0;
}
.btn-material-indigo-700:active:not(.btn-link):not(.btn-flat) {
  background-color: #3748b7;
}
.btn-material-indigo-800:active:not(.btn-link):not(.btn-flat) {
  background-color: #2f3eab;
}
.btn-material-indigo-900:active:not(.btn-link):not(.btn-flat) {
  background-color: #1f2a97;
}
.btn-material-indigo-A100:active:not(.btn-link):not(.btn-flat) {
  background-color: #abb8ff;
}
.btn-material-indigo-A200:active:not(.btn-link):not(.btn-flat) {
  background-color: #7187fe;
}
.btn-material-indigo-A400:active:not(.btn-link):not(.btn-flat) {
  background-color: #5b74fe;
}
.btn-material-indigo-A700:active:not(.btn-link):not(.btn-flat) {
  background-color: #4e69fe;
}
.btn-material-blue:active:not(.btn-link):not(.btn-flat) {
  background-color: #3ea4f5;
}
.btn-material-blue-50:active:not(.btn-link):not(.btn-flat) {
  background-color: #c6e5fb;
}
.btn-material-blue-100:active:not(.btn-link):not(.btn-flat) {
  background-color: #9ed0f9;
}
.btn-material-blue-200:active:not(.btn-link):not(.btn-flat) {
  background-color: #73bcf7;
}
.btn-material-blue-300:active:not(.btn-link):not(.btn-flat) {
  background-color: #47a7f4;
}
.btn-material-blue-400:active:not(.btn-link):not(.btn-flat) {
  background-color: #5fb3f7;
}
.btn-material-blue-500:active:not(.btn-link):not(.btn-flat) {
  background-color: #3ea4f5;
}
.btn-material-blue-600:active:not(.btn-link):not(.btn-flat) {
  background-color: #3997e8;
}
.btn-material-blue-700:active:not(.btn-link):not(.btn-flat) {
  background-color: #2585e5;
}
.btn-material-blue-800:active:not(.btn-link):not(.btn-flat) {
  background-color: #1874dc;
}
.btn-material-blue-900:active:not(.btn-link):not(.btn-flat) {
  background-color: #0f53bd;
}
.btn-material-blue-A100:active:not(.btn-link):not(.btn-flat) {
  background-color: #639eff;
}
.btn-material-blue-A200:active:not(.btn-link):not(.btn-flat) {
  background-color: #639dff;
}
.btn-material-blue-A400:active:not(.btn-link):not(.btn-flat) {
  background-color: #488cff;
}
.btn-material-blue-A700:active:not(.btn-link):not(.btn-flat) {
  background-color: #4878ff;
}
.btn-material-light-blue:active:not(.btn-link):not(.btn-flat) {
  background-color: #19b6fc;
}
.btn-material-light-blue-50:active:not(.btn-link):not(.btn-flat) {
  background-color: #c3ebfd;
}
.btn-material-light-blue-100:active:not(.btn-link):not(.btn-flat) {
  background-color: #96dbfb;
}
.btn-material-light-blue-200:active:not(.btn-link):not(.btn-flat) {
  background-color: #64caf9;
}
.btn-material-light-blue-300:active:not(.btn-link):not(.btn-flat) {
  background-color: #32b9f6;
}
.btn-material-light-blue-400:active:not(.btn-link):not(.btn-flat) {
  background-color: #0cacf5;
}
.btn-material-light-blue-500:active:not(.btn-link):not(.btn-flat) {
  background-color: #19b6fc;
}
.btn-material-light-blue-600:active:not(.btn-link):not(.btn-flat) {
  background-color: #0badfc;
}
.btn-material-light-blue-700:active:not(.btn-link):not(.btn-flat) {
  background-color: #029cef;
}
.btn-material-light-blue-800:active:not(.btn-link):not(.btn-flat) {
  background-color: #028adb;
}
.btn-material-light-blue-900:active:not(.btn-link):not(.btn-flat) {
  background-color: #0168b9;
}
.btn-material-light-blue-A100:active:not(.btn-link):not(.btn-flat) {
  background-color: #61cfff;
}
.btn-material-light-blue-A200:active:not(.btn-link):not(.btn-flat) {
  background-color: #21bbff;
}
.btn-material-light-blue-A400:active:not(.btn-link):not(.btn-flat) {
  background-color: #1fb9ff;
}
.btn-material-light-blue-A700:active:not(.btn-link):not(.btn-flat) {
  background-color: #0aa2ff;
}
.btn-material-cyan:active:not(.btn-link):not(.btn-flat) {
  background-color: #00a1b5;
}
.btn-material-cyan-50:active:not(.btn-link):not(.btn-flat) {
  background-color: #c6f0f6;
}
.btn-material-cyan-100:active:not(.btn-link):not(.btn-flat) {
  background-color: #98e4ee;
}
.btn-material-cyan-200:active:not(.btn-link):not(.btn-flat) {
  background-color: #66d7e6;
}
.btn-material-cyan-300:active:not(.btn-link):not(.btn-flat) {
  background-color: #33c9dd;
}
.btn-material-cyan-400:active:not(.btn-link):not(.btn-flat) {
  background-color: #21afc1;
}
.btn-material-cyan-500:active:not(.btn-link):not(.btn-flat) {
  background-color: #00a1b5;
}
.btn-material-cyan-600:active:not(.btn-link):not(.btn-flat) {
  background-color: #00c7e0;
}
.btn-material-cyan-700:active:not(.btn-link):not(.btn-flat) {
  background-color: #00b3c6;
}
.btn-material-cyan-800:active:not(.btn-link):not(.btn-flat) {
  background-color: #009fae;
}
.btn-material-cyan-900:active:not(.btn-link):not(.btn-flat) {
  background-color: #007d83;
}
.btn-material-cyan-A100:active:not(.btn-link):not(.btn-flat) {
  background-color: #65ffff;
}
.btn-material-cyan-A200:active:not(.btn-link):not(.btn-flat) {
  background-color: #00f8f8;
}
.btn-material-cyan-A400:active:not(.btn-link):not(.btn-flat) {
  background-color: #00cae0;
}
.btn-material-cyan-A700:active:not(.btn-link):not(.btn-flat) {
  background-color: #00d3f3;
}
.btn-material-teal:active:not(.btn-link):not(.btn-flat) {
  background-color: #00b5a4;
}
.btn-material-teal-50:active:not(.btn-link):not(.btn-flat) {
  background-color: #cae9e7;
}
.btn-material-teal-100:active:not(.btn-link):not(.btn-flat) {
  background-color: #9cd6d1;
}
.btn-material-teal-200:active:not(.btn-link):not(.btn-flat) {
  background-color: #6ac2ba;
}
.btn-material-teal-300:active:not(.btn-link):not(.btn-flat) {
  background-color: #63bfb6;
}
.btn-material-teal-400:active:not(.btn-link):not(.btn-flat) {
  background-color: #2cbfb1;
}
.btn-material-teal-500:active:not(.btn-link):not(.btn-flat) {
  background-color: #00b5a4;
}
.btn-material-teal-600:active:not(.btn-link):not(.btn-flat) {
  background-color: #00a896;
}
.btn-material-teal-700:active:not(.btn-link):not(.btn-flat) {
  background-color: #009886;
}
.btn-material-teal-800:active:not(.btn-link):not(.btn-flat) {
  background-color: #008877;
}
.btn-material-teal-900:active:not(.btn-link):not(.btn-flat) {
  background-color: #006c59;
}
.btn-material-teal-A100:active:not(.btn-link):not(.btn-flat) {
  background-color: #88ffe4;
}
.btn-material-teal-A200:active:not(.btn-link):not(.btn-flat) {
  background-color: #45ffd3;
}
.btn-material-teal-A400:active:not(.btn-link):not(.btn-flat) {
  background-color: #15d3a3;
}
.btn-material-teal-A700:active:not(.btn-link):not(.btn-flat) {
  background-color: #00debf;
}
.btn-material-green:active:not(.btn-link):not(.btn-flat) {
  background-color: #60ba63;
}
.btn-material-green-50:active:not(.btn-link):not(.btn-flat) {
  background-color: #d3ecd5;
}
.btn-material-green-100:active:not(.btn-link):not(.btn-flat) {
  background-color: #b3dcb4;
}
.btn-material-green-200:active:not(.btn-link):not(.btn-flat) {
  background-color: #90cc92;
}
.btn-material-green-300:active:not(.btn-link):not(.btn-flat) {
  background-color: #6cbe6f;
}
.btn-material-green-400:active:not(.btn-link):not(.btn-flat) {
  background-color: #7bc47f;
}
.btn-material-green-500:active:not(.btn-link):not(.btn-flat) {
  background-color: #60ba63;
}
.btn-material-green-600:active:not(.btn-link):not(.btn-flat) {
  background-color: #4db552;
}
.btn-material-green-700:active:not(.btn-link):not(.btn-flat) {
  background-color: #41a445;
}
.btn-material-green-800:active:not(.btn-link):not(.btn-flat) {
  background-color: #36933b;
}
.btn-material-green-900:active:not(.btn-link):not(.btn-flat) {
  background-color: #227628;
}
.btn-material-green-A100:active:not(.btn-link):not(.btn-flat) {
  background-color: #9ef3b5;
}
.btn-material-green-A200:active:not(.btn-link):not(.btn-flat) {
  background-color: #4ded9f;
}
.btn-material-green-A400:active:not(.btn-link):not(.btn-flat) {
  background-color: #00c766;
}
.btn-material-green-A700:active:not(.btn-link):not(.btn-flat) {
  background-color: #00a946;
}
.btn-material-light-green:active:not(.btn-link):not(.btn-flat) {
  background-color: #7cb33b;
}
.btn-material-light-green-50:active:not(.btn-link):not(.btn-flat) {
  background-color: #e2f1d2;
}
.btn-material-light-green-100:active:not(.btn-link):not(.btn-flat) {
  background-color: #cde5b1;
}
.btn-material-light-green-200:active:not(.btn-link):not(.btn-flat) {
  background-color: #b6d98e;
}
.btn-material-light-green-300:active:not(.btn-link):not(.btn-flat) {
  background-color: #9fcd6a;
}
.btn-material-light-green-400:active:not(.btn-link):not(.btn-flat) {
  background-color: #8dc44e;
}
.btn-material-light-green-500:active:not(.btn-link):not(.btn-flat) {
  background-color: #7cb33b;
}
.btn-material-light-green-600:active:not(.btn-link):not(.btn-flat) {
  background-color: #8bc054;
}
.btn-material-light-green-700:active:not(.btn-link):not(.btn-flat) {
  background-color: #77b640;
}
.btn-material-light-green-800:active:not(.btn-link):not(.btn-flat) {
  background-color: #63a237;
}
.btn-material-light-green-900:active:not(.btn-link):not(.btn-flat) {
  background-color: #3f8125;
}
.btn-material-light-green-A100:active:not(.btn-link):not(.btn-flat) {
  background-color: #beff71;
}
.btn-material-light-green-A200:active:not(.btn-link):not(.btn-flat) {
  background-color: #a4ff3a;
}
.btn-material-light-green-A400:active:not(.btn-link):not(.btn-flat) {
  background-color: #68e300;
}
.btn-material-light-green-A700:active:not(.btn-link):not(.btn-flat) {
  background-color: #57c114;
}
.btn-material-lime:active:not(.btn-link):not(.btn-flat) {
  background-color: #c2d125;
}
.btn-material-lime-50:active:not(.btn-link):not(.btn-flat) {
  background-color: #f2f7cd;
}
.btn-material-lime-100:active:not(.btn-link):not(.btn-flat) {
  background-color: #eaefa9;
}
.btn-material-lime-200:active:not(.btn-link):not(.btn-flat) {
  background-color: #dfea82;
}
.btn-material-lime-300:active:not(.btn-link):not(.btn-flat) {
  background-color: #d5e25b;
}
.btn-material-lime-400:active:not(.btn-link):not(.btn-flat) {
  background-color: #cddc3d;
}
.btn-material-lime-500:active:not(.btn-link):not(.btn-flat) {
  background-color: #c2d125;
}
.btn-material-lime-600:active:not(.btn-link):not(.btn-flat) {
  background-color: #a9b22d;
}
.btn-material-lime-700:active:not(.btn-link):not(.btn-flat) {
  background-color: #979b25;
}
.btn-material-lime-800:active:not(.btn-link):not(.btn-flat) {
  background-color: #b7b62a;
}
.btn-material-lime-900:active:not(.btn-link):not(.btn-flat) {
  background-color: #9c8f1c;
}
.btn-material-lime-A100:active:not(.btn-link):not(.btn-flat) {
  background-color: #f1ff62;
}
.btn-material-lime-A200:active:not(.btn-link):not(.btn-flat) {
  background-color: #ebff22;
}
.btn-material-lime-A400:active:not(.btn-link):not(.btn-flat) {
  background-color: #aee000;
}
.btn-material-lime-A700:active:not(.btn-link):not(.btn-flat) {
  background-color: #97cb00;
}
.btn-material-yellow:active:not(.btn-link):not(.btn-flat) {
  background-color: #ffe81c;
}
.btn-material-yellow-50:active:not(.btn-link):not(.btn-flat) {
  background-color: #fffac8;
}
.btn-material-yellow-100:active:not(.btn-link):not(.btn-flat) {
  background-color: #fff6a5;
}
.btn-material-yellow-200:active:not(.btn-link):not(.btn-flat) {
  background-color: #fff27e;
}
.btn-material-yellow-300:active:not(.btn-link):not(.btn-flat) {
  background-color: #ffee57;
}
.btn-material-yellow-400:active:not(.btn-link):not(.btn-flat) {
  background-color: #ffeb39;
}
.btn-material-yellow-500:active:not(.btn-link):not(.btn-flat) {
  background-color: #ffe81c;
}
.btn-material-yellow-600:active:not(.btn-link):not(.btn-flat) {
  background-color: #fdd217;
}
.btn-material-yellow-700:active:not(.btn-link):not(.btn-flat) {
  background-color: #fab70f;
}
.btn-material-yellow-800:active:not(.btn-link):not(.btn-flat) {
  background-color: #f89c07;
}
.btn-material-yellow-900:active:not(.btn-link):not(.btn-flat) {
  background-color: #f68f34;
}
.btn-material-yellow-A100:active:not(.btn-link):not(.btn-flat) {
  background-color: #ffff6e;
}
.btn-material-yellow-A200:active:not(.btn-link):not(.btn-flat) {
  background-color: #e0e000;
}
.btn-material-yellow-A400:active:not(.btn-link):not(.btn-flat) {
  background-color: #e0ce00;
}
.btn-material-yellow-A700:active:not(.btn-link):not(.btn-flat) {
  background-color: #e0bc00;
}
.btn-material-amber:active:not(.btn-link):not(.btn-flat) {
  background-color: #e7ae00;
}
.btn-material-amber-50:active:not(.btn-link):not(.btn-flat) {
  background-color: #fff1c2;
}
.btn-material-amber-100:active:not(.btn-link):not(.btn-flat) {
  background-color: #ffe494;
}
.btn-material-amber-200:active:not(.btn-link):not(.btn-flat) {
  background-color: #ffd863;
}
.btn-material-amber-300:active:not(.btn-link):not(.btn-flat) {
  background-color: #ffce30;
}
.btn-material-amber-400:active:not(.btn-link):not(.btn-flat) {
  background-color: #ffc209;
}
.btn-material-amber-500:active:not(.btn-link):not(.btn-flat) {
  background-color: #e7ae00;
}
.btn-material-amber-600:active:not(.btn-link):not(.btn-flat) {
  background-color: #e09e00;
}
.btn-material-amber-700:active:not(.btn-link):not(.btn-flat) {
  background-color: #e08d00;
}
.btn-material-amber-800:active:not(.btn-link):not(.btn-flat) {
  background-color: #e07e00;
}
.btn-material-amber-900:active:not(.btn-link):not(.btn-flat) {
  background-color: #ff801f;
}
.btn-material-amber-A100:active:not(.btn-link):not(.btn-flat) {
  background-color: #ffdf60;
}
.btn-material-amber-A200:active:not(.btn-link):not(.btn-flat) {
  background-color: #ffd121;
}
.btn-material-amber-A400:active:not(.btn-link):not(.btn-flat) {
  background-color: #e0ac00;
}
.btn-material-amber-A700:active:not(.btn-link):not(.btn-flat) {
  background-color: #e09600;
}
.btn-material-orange:active:not(.btn-link):not(.btn-flat) {
  background-color: #e08600;
}
.btn-material-orange-50:active:not(.btn-link):not(.btn-flat) {
  background-color: #ffe7c1;
}
.btn-material-orange-100:active:not(.btn-link):not(.btn-flat) {
  background-color: #ffd493;
}
.btn-material-orange-200:active:not(.btn-link):not(.btn-flat) {
  background-color: #ffc061;
}
.btn-material-orange-300:active:not(.btn-link):not(.btn-flat) {
  background-color: #ffab2e;
}
.btn-material-orange-400:active:not(.btn-link):not(.btn-flat) {
  background-color: #ff9b07;
}
.btn-material-orange-500:active:not(.btn-link):not(.btn-flat) {
  background-color: #e08600;
}
.btn-material-orange-600:active:not(.btn-link):not(.btn-flat) {
  background-color: #ff9a1b;
}
.btn-material-orange-700:active:not(.btn-link):not(.btn-flat) {
  background-color: #ff8b15;
}
.btn-material-orange-800:active:not(.btn-link):not(.btn-flat) {
  background-color: #ff7b0f;
}
.btn-material-orange-900:active:not(.btn-link):not(.btn-flat) {
  background-color: #ff5d06;
}
.btn-material-orange-A100:active:not(.btn-link):not(.btn-flat) {
  background-color: #ffc661;
}
.btn-material-orange-A200:active:not(.btn-link):not(.btn-flat) {
  background-color: #ff9e21;
}
.btn-material-orange-A400:active:not(.btn-link):not(.btn-flat) {
  background-color: #e08000;
}
.btn-material-orange-A700:active:not(.btn-link):not(.btn-flat) {
  background-color: #ff7f1f;
}
.btn-material-deep-orange:active:not(.btn-link):not(.btn-flat) {
  background-color: #ff6e41;
}
.btn-material-deep-orange-50:active:not(.btn-link):not(.btn-flat) {
  background-color: #f7d1cd;
}
.btn-material-deep-orange-100:active:not(.btn-link):not(.btn-flat) {
  background-color: #ffb59d;
}
.btn-material-deep-orange-200:active:not(.btn-link):not(.btn-flat) {
  background-color: #ff9472;
}
.btn-material-deep-orange-300:active:not(.btn-link):not(.btn-flat) {
  background-color: #ff7346;
}
.btn-material-deep-orange-400:active:not(.btn-link):not(.btn-flat) {
  background-color: #ff8762;
}
.btn-material-deep-orange-500:active:not(.btn-link):not(.btn-flat) {
  background-color: #ff6e41;
}
.btn-material-deep-orange-600:active:not(.btn-link):not(.btn-flat) {
  background-color: #f5683b;
}
.btn-material-deep-orange-700:active:not(.btn-link):not(.btn-flat) {
  background-color: #e96035;
}
.btn-material-deep-orange-800:active:not(.btn-link):not(.btn-flat) {
  background-color: #ea5122;
}
.btn-material-deep-orange-900:active:not(.btn-link):not(.btn-flat) {
  background-color: #dc3e0e;
}
.btn-material-deep-orange-A100:active:not(.btn-link):not(.btn-flat) {
  background-color: #ff8761;
}
.btn-material-deep-orange-A200:active:not(.btn-link):not(.btn-flat) {
  background-color: #ff855f;
}
.btn-material-deep-orange-A400:active:not(.btn-link):not(.btn-flat) {
  background-color: #ff541f;
}
.btn-material-deep-orange-A700:active:not(.btn-link):not(.btn-flat) {
  background-color: #fc3200;
}
.btn-material-brown:active:not(.btn-link):not(.btn-flat) {
  background-color: #8c6253;
}
.btn-material-brown-50:active:not(.btn-link):not(.btn-flat) {
  background-color: #e2dbd7;
}
.btn-material-brown-100:active:not(.btn-link):not(.btn-flat) {
  background-color: #cabcb6;
}
.btn-material-brown-200:active:not(.btn-link):not(.btn-flat) {
  background-color: #af9a92;
}
.btn-material-brown-300:active:not(.btn-link):not(.btn-flat) {
  background-color: #ae9891;
}
.btn-material-brown-400:active:not(.btn-link):not(.btn-flat) {
  background-color: #9c7d72;
}
.btn-material-brown-500:active:not(.btn-link):not(.btn-flat) {
  background-color: #8c6253;
}
.btn-material-brown-600:active:not(.btn-link):not(.btn-flat) {
  background-color: #80594c;
}
.btn-material-brown-700:active:not(.btn-link):not(.btn-flat) {
  background-color: #704d42;
}
.btn-material-brown-800:active:not(.btn-link):not(.btn-flat) {
  background-color: #614139;
}
.btn-material-brown-900:active:not(.btn-link):not(.btn-flat) {
  background-color: #52332e;
}
.btn-material-brown-A100:active:not(.btn-link):not(.btn-flat) {
  background-color: #cabcb6;
}
.btn-material-brown-A200:active:not(.btn-link):not(.btn-flat) {
  background-color: #af9a92;
}
.btn-material-brown-A400:active:not(.btn-link):not(.btn-flat) {
  background-color: #9c7d72;
}
.btn-material-brown-A700:active:not(.btn-link):not(.btn-flat) {
  background-color: #704d42;
}
.btn-material-grey:active:not(.btn-link):not(.btn-flat) {
  background-color: #adadad;
}
.btn-material-grey-50:active:not(.btn-link):not(.btn-flat) {
  background-color: #ebebeb;
}
.btn-material-grey-100:active:not(.btn-link):not(.btn-flat) {
  background-color: #e6e6e6;
}
.btn-material-grey-200:active:not(.btn-link):not(.btn-flat) {
  background-color: #dfdfdf;
}
.btn-material-grey-300:active:not(.btn-link):not(.btn-flat) {
  background-color: #d1d1d1;
}
.btn-material-grey-400:active:not(.btn-link):not(.btn-flat) {
  background-color: #aeaeae;
}
.btn-material-grey-500:active:not(.btn-link):not(.btn-flat) {
  background-color: #adadad;
}
.btn-material-grey-600:active:not(.btn-link):not(.btn-flat) {
  background-color: #848484;
}
.btn-material-grey-700:active:not(.btn-link):not(.btn-flat) {
  background-color: #707070;
}
.btn-material-grey-800:active:not(.btn-link):not(.btn-flat) {
  background-color: #515151;
}
.btn-material-grey-900:active:not(.btn-link):not(.btn-flat) {
  background-color: #303030;
}
.btn-material-grey-A100:active:not(.btn-link):not(.btn-flat) {
  background-color: #e6e6e6;
}
.btn-material-grey-A200:active:not(.btn-link):not(.btn-flat) {
  background-color: #dfdfdf;
}
.btn-material-grey-A400:active:not(.btn-link):not(.btn-flat) {
  background-color: #aeaeae;
}
.btn-material-grey-A700:active:not(.btn-link):not(.btn-flat) {
  background-color: #707070;
}
.btn-material-blue-grey:active:not(.btn-link):not(.btn-flat) {
  background-color: #6e8d9b;
}
.btn-material-blue-grey-50:active:not(.btn-link):not(.btn-flat) {
  background-color: #dae0e4;
}
.btn-material-blue-grey-100:active:not(.btn-link):not(.btn-flat) {
  background-color: #bdcacf;
}
.btn-material-blue-grey-200:active:not(.btn-link):not(.btn-flat) {
  background-color: #9eafb8;
}
.btn-material-blue-grey-300:active:not(.btn-link):not(.btn-flat) {
  background-color: #a2b3bb;
}
.btn-material-blue-grey-400:active:not(.btn-link):not(.btn-flat) {
  background-color: #8a9fa9;
}
.btn-material-blue-grey-500:active:not(.btn-link):not(.btn-flat) {
  background-color: #6e8d9b;
}
.btn-material-blue-grey-600:active:not(.btn-link):not(.btn-flat) {
  background-color: #607e8c;
}
.btn-material-blue-grey-700:active:not(.btn-link):not(.btn-flat) {
  background-color: #516a76;
}
.btn-material-blue-grey-800:active:not(.btn-link):not(.btn-flat) {
  background-color: #445761;
}
.btn-material-blue-grey-900:active:not(.btn-link):not(.btn-flat) {
  background-color: #32424a;
}
.btn-material-blue-grey-A100:active:not(.btn-link):not(.btn-flat) {
  background-color: #bdcacf;
}
.btn-material-blue-grey-A200:active:not(.btn-link):not(.btn-flat) {
  background-color: #9eafb8;
}
.btn-material-blue-grey-A400:active:not(.btn-link):not(.btn-flat) {
  background-color: #8a9fa9;
}
.btn-material-blue-grey-A700:active:not(.btn-link):not(.btn-flat) {
  background-color: #516a76;
}
.btn.active:not(.btn-link):not(.btn-flat),
.btn-default.active:not(.btn-link):not(.btn-flat) {
  background-color: rgba(15, 15, 15, 0);
}
.btn-black.active:not(.btn-link):not(.btn-flat) {
  background-color: #0f0f0f;
}
.btn-white.active:not(.btn-link):not(.btn-flat) {
  background-color: #f0f0f0;
}
.btn-inverse.active:not(.btn-link):not(.btn-flat) {
  background-color: #5062c2;
}
.btn-primary.active:not(.btn-link):not(.btn-flat) {
  background-color: #00b5a4;
}
.btn-success.active:not(.btn-link):not(.btn-flat) {
  background-color: #60ba63;
}
.btn-info.active:not(.btn-link):not(.btn-flat) {
  background-color: #19b6fc;
}
.btn-warning.active:not(.btn-link):not(.btn-flat) {
  background-color: #ff6e41;
}
.btn-danger.active:not(.btn-link):not(.btn-flat) {
  background-color: #f65e53;
}
.btn-material-red.active:not(.btn-link):not(.btn-flat) {
  background-color: #f65e53;
}
.btn-material-red-50.active:not(.btn-link):not(.btn-flat) {
  background-color: #ffccd4;
}
.btn-material-red-100.active:not(.btn-link):not(.btn-flat) {
  background-color: #ffaeb6;
}
.btn-material-red-200.active:not(.btn-link):not(.btn-flat) {
  background-color: #eb8080;
}
.btn-material-red-300.active:not(.btn-link):not(.btn-flat) {
  background-color: #ea8d8d;
}
.btn-material-red-400.active:not(.btn-link):not(.btn-flat) {
  background-color: #f26f6c;
}
.btn-material-red-500.active:not(.btn-link):not(.btn-flat) {
  background-color: #f65e53;
}
.btn-material-red-600.active:not(.btn-link):not(.btn-flat) {
  background-color: #e85450;
}
.btn-material-red-700.active:not(.btn-link):not(.btn-flat) {
  background-color: #d84848;
}
.btn-material-red-800.active:not(.btn-link):not(.btn-flat) {
  background-color: #d63636;
}
.btn-material-red-900.active:not(.btn-link):not(.btn-flat) {
  background-color: #d22020;
}
.btn-material-red-A100.active:not(.btn-link):not(.btn-flat) {
  background-color: #ff6e61;
}
.btn-material-red-A200.active:not(.btn-link):not(.btn-flat) {
  background-color: #ff7171;
}
.btn-material-red-A400.active:not(.btn-link):not(.btn-flat) {
  background-color: #ff365d;
}
.btn-material-red-A700.active:not(.btn-link):not(.btn-flat) {
  background-color: #f40000;
}
.btn-material-pink.active:not(.btn-link):not(.btn-flat) {
  background-color: #ec3a76;
}
.btn-material-pink-50.active:not(.btn-link):not(.btn-flat) {
  background-color: #f9c8d9;
}
.btn-material-pink-100.active:not(.btn-link):not(.btn-flat) {
  background-color: #f59fbd;
}
.btn-material-pink-200.active:not(.btn-link):not(.btn-flat) {
  background-color: #f1739e;
}
.btn-material-pink-300.active:not(.btn-link):not(.btn-flat) {
  background-color: #f37ea5;
}
.btn-material-pink-400.active:not(.btn-link):not(.btn-flat) {
  background-color: #ef5c8d;
}
.btn-material-pink-500.active:not(.btn-link):not(.btn-flat) {
  background-color: #ec3a76;
}
.btn-material-pink-600.active:not(.btn-link):not(.btn-flat) {
  background-color: #e52d70;
}
.btn-material-pink-700.active:not(.btn-link):not(.btn-flat) {
  background-color: #dd1b68;
}
.btn-material-pink-800.active:not(.btn-link):not(.btn-flat) {
  background-color: #c81765;
}
.btn-material-pink-900.active:not(.btn-link):not(.btn-flat) {
  background-color: #a4115f;
}
.btn-material-pink-A100.active:not(.btn-link):not(.btn-flat) {
  background-color: #ff9fbf;
}
.btn-material-pink-A200.active:not(.btn-link):not(.btn-flat) {
  background-color: #ff5f95;
}
.btn-material-pink-A400.active:not(.btn-link):not(.btn-flat) {
  background-color: #ff1568;
}
.btn-material-pink-A700.active:not(.btn-link):not(.btn-flat) {
  background-color: #e11370;
}
.btn-material-purple.active:not(.btn-link):not(.btn-flat) {
  background-color: #b22dc9;
}
.btn-material-purple-50.active:not(.btn-link):not(.btn-flat) {
  background-color: #e9cfec;
}
.btn-material-purple-100.active:not(.btn-link):not(.btn-flat) {
  background-color: #d7a8df;
}
.btn-material-purple-200.active:not(.btn-link):not(.btn-flat) {
  background-color: #d8a9e0;
}
.btn-material-purple-300.active:not(.btn-link):not(.btn-flat) {
  background-color: #c47ed0;
}
.btn-material-purple-400.active:not(.btn-link):not(.btn-flat) {
  background-color: #b55dc4;
}
.btn-material-purple-500.active:not(.btn-link):not(.btn-flat) {
  background-color: #b22dc9;
}
.btn-material-purple-600.active:not(.btn-link):not(.btn-flat) {
  background-color: #a329c3;
}
.btn-material-purple-700.active:not(.btn-link):not(.btn-flat) {
  background-color: #8f24bc;
}
.btn-material-purple-800.active:not(.btn-link):not(.btn-flat) {
  background-color: #7c20b4;
}
.btn-material-purple-900.active:not(.btn-link):not(.btn-flat) {
  background-color: #5818a7;
}
.btn-material-purple-A100.active:not(.btn-link):not(.btn-flat) {
  background-color: #ef9efd;
}
.btn-material-purple-A200.active:not(.btn-link):not(.btn-flat) {
  background-color: #e55efc;
}
.btn-material-purple-A400.active:not(.btn-link):not(.btn-flat) {
  background-color: #de19ff;
}
.btn-material-purple-A700.active:not(.btn-link):not(.btn-flat) {
  background-color: #b41fff;
}
.btn-material-deep-purple.active:not(.btn-link):not(.btn-flat) {
  background-color: #764ac6;
}
.btn-material-deep-purple-50.active:not(.btn-link):not(.btn-flat) {
  background-color: #dcd1ee;
}
.btn-material-deep-purple-100.active:not(.btn-link):not(.btn-flat) {
  background-color: #c0aee1;
}
.btn-material-deep-purple-200.active:not(.btn-link):not(.btn-flat) {
  background-color: #c4b3e3;
}
.btn-material-deep-purple-300.active:not(.btn-link):not(.btn-flat) {
  background-color: #a68bd5;
}
.btn-material-deep-purple-400.active:not(.btn-link):not(.btn-flat) {
  background-color: #8f6dca;
}
.btn-material-deep-purple-500.active:not(.btn-link):not(.btn-flat) {
  background-color: #764ac6;
}
.btn-material-deep-purple-600.active:not(.btn-link):not(.btn-flat) {
  background-color: #6c3fc6;
}
.btn-material-deep-purple-700.active:not(.btn-link):not(.btn-flat) {
  background-color: #5d33c0;
}
.btn-material-deep-purple-800.active:not(.btn-link):not(.btn-flat) {
  background-color: #502db9;
}
.btn-material-deep-purple-900.active:not(.btn-link):not(.btn-flat) {
  background-color: #3a20ac;
}
.btn-material-deep-purple-A100.active:not(.btn-link):not(.btn-flat) {
  background-color: #c7a7ff;
}
.btn-material-deep-purple-A200.active:not(.btn-link):not(.btn-flat) {
  background-color: #936cff;
}
.btn-material-deep-purple-A400.active:not(.btn-link):not(.btn-flat) {
  background-color: #7a3eff;
}
.btn-material-deep-purple-A700.active:not(.btn-link):not(.btn-flat) {
  background-color: #700aff;
}
.btn-material-indigo.active:not(.btn-link):not(.btn-flat) {
  background-color: #5062c2;
}
.btn-material-indigo-50.active:not(.btn-link):not(.btn-flat) {
  background-color: #d2d6ed;
}
.btn-material-indigo-100.active:not(.btn-link):not(.btn-flat) {
  background-color: #afb6e1;
}
.btn-material-indigo-200.active:not(.btn-link):not(.btn-flat) {
  background-color: #8994d1;
}
.btn-material-indigo-300.active:not(.btn-link):not(.btn-flat) {
  background-color: #8f9ad4;
}
.btn-material-indigo-400.active:not(.btn-link):not(.btn-flat) {
  background-color: #727fc9;
}
.btn-material-indigo-500.active:not(.btn-link):not(.btn-flat) {
  background-color: #5062c2;
}
.btn-material-indigo-600.active:not(.btn-link):not(.btn-flat) {
  background-color: #4254c0;
}
.btn-material-indigo-700.active:not(.btn-link):not(.btn-flat) {
  background-color: #3748b7;
}
.btn-material-indigo-800.active:not(.btn-link):not(.btn-flat) {
  background-color: #2f3eab;
}
.btn-material-indigo-900.active:not(.btn-link):not(.btn-flat) {
  background-color: #1f2a97;
}
.btn-material-indigo-A100.active:not(.btn-link):not(.btn-flat) {
  background-color: #abb8ff;
}
.btn-material-indigo-A200.active:not(.btn-link):not(.btn-flat) {
  background-color: #7187fe;
}
.btn-material-indigo-A400.active:not(.btn-link):not(.btn-flat) {
  background-color: #5b74fe;
}
.btn-material-indigo-A700.active:not(.btn-link):not(.btn-flat) {
  background-color: #4e69fe;
}
.btn-material-blue.active:not(.btn-link):not(.btn-flat) {
  background-color: #3ea4f5;
}
.btn-material-blue-50.active:not(.btn-link):not(.btn-flat) {
  background-color: #c6e5fb;
}
.btn-material-blue-100.active:not(.btn-link):not(.btn-flat) {
  background-color: #9ed0f9;
}
.btn-material-blue-200.active:not(.btn-link):not(.btn-flat) {
  background-color: #73bcf7;
}
.btn-material-blue-300.active:not(.btn-link):not(.btn-flat) {
  background-color: #47a7f4;
}
.btn-material-blue-400.active:not(.btn-link):not(.btn-flat) {
  background-color: #5fb3f7;
}
.btn-material-blue-500.active:not(.btn-link):not(.btn-flat) {
  background-color: #3ea4f5;
}
.btn-material-blue-600.active:not(.btn-link):not(.btn-flat) {
  background-color: #3997e8;
}
.btn-material-blue-700.active:not(.btn-link):not(.btn-flat) {
  background-color: #2585e5;
}
.btn-material-blue-800.active:not(.btn-link):not(.btn-flat) {
  background-color: #1874dc;
}
.btn-material-blue-900.active:not(.btn-link):not(.btn-flat) {
  background-color: #0f53bd;
}
.btn-material-blue-A100.active:not(.btn-link):not(.btn-flat) {
  background-color: #639eff;
}
.btn-material-blue-A200.active:not(.btn-link):not(.btn-flat) {
  background-color: #639dff;
}
.btn-material-blue-A400.active:not(.btn-link):not(.btn-flat) {
  background-color: #488cff;
}
.btn-material-blue-A700.active:not(.btn-link):not(.btn-flat) {
  background-color: #4878ff;
}
.btn-material-light-blue.active:not(.btn-link):not(.btn-flat) {
  background-color: #19b6fc;
}
.btn-material-light-blue-50.active:not(.btn-link):not(.btn-flat) {
  background-color: #c3ebfd;
}
.btn-material-light-blue-100.active:not(.btn-link):not(.btn-flat) {
  background-color: #96dbfb;
}
.btn-material-light-blue-200.active:not(.btn-link):not(.btn-flat) {
  background-color: #64caf9;
}
.btn-material-light-blue-300.active:not(.btn-link):not(.btn-flat) {
  background-color: #32b9f6;
}
.btn-material-light-blue-400.active:not(.btn-link):not(.btn-flat) {
  background-color: #0cacf5;
}
.btn-material-light-blue-500.active:not(.btn-link):not(.btn-flat) {
  background-color: #19b6fc;
}
.btn-material-light-blue-600.active:not(.btn-link):not(.btn-flat) {
  background-color: #0badfc;
}
.btn-material-light-blue-700.active:not(.btn-link):not(.btn-flat) {
  background-color: #029cef;
}
.btn-material-light-blue-800.active:not(.btn-link):not(.btn-flat) {
  background-color: #028adb;
}
.btn-material-light-blue-900.active:not(.btn-link):not(.btn-flat) {
  background-color: #0168b9;
}
.btn-material-light-blue-A100.active:not(.btn-link):not(.btn-flat) {
  background-color: #61cfff;
}
.btn-material-light-blue-A200.active:not(.btn-link):not(.btn-flat) {
  background-color: #21bbff;
}
.btn-material-light-blue-A400.active:not(.btn-link):not(.btn-flat) {
  background-color: #1fb9ff;
}
.btn-material-light-blue-A700.active:not(.btn-link):not(.btn-flat) {
  background-color: #0aa2ff;
}
.btn-material-cyan.active:not(.btn-link):not(.btn-flat) {
  background-color: #00a1b5;
}
.btn-material-cyan-50.active:not(.btn-link):not(.btn-flat) {
  background-color: #c6f0f6;
}
.btn-material-cyan-100.active:not(.btn-link):not(.btn-flat) {
  background-color: #98e4ee;
}
.btn-material-cyan-200.active:not(.btn-link):not(.btn-flat) {
  background-color: #66d7e6;
}
.btn-material-cyan-300.active:not(.btn-link):not(.btn-flat) {
  background-color: #33c9dd;
}
.btn-material-cyan-400.active:not(.btn-link):not(.btn-flat) {
  background-color: #21afc1;
}
.btn-material-cyan-500.active:not(.btn-link):not(.btn-flat) {
  background-color: #00a1b5;
}
.btn-material-cyan-600.active:not(.btn-link):not(.btn-flat) {
  background-color: #00c7e0;
}
.btn-material-cyan-700.active:not(.btn-link):not(.btn-flat) {
  background-color: #00b3c6;
}
.btn-material-cyan-800.active:not(.btn-link):not(.btn-flat) {
  background-color: #009fae;
}
.btn-material-cyan-900.active:not(.btn-link):not(.btn-flat) {
  background-color: #007d83;
}
.btn-material-cyan-A100.active:not(.btn-link):not(.btn-flat) {
  background-color: #65ffff;
}
.btn-material-cyan-A200.active:not(.btn-link):not(.btn-flat) {
  background-color: #00f8f8;
}
.btn-material-cyan-A400.active:not(.btn-link):not(.btn-flat) {
  background-color: #00cae0;
}
.btn-material-cyan-A700.active:not(.btn-link):not(.btn-flat) {
  background-color: #00d3f3;
}
.btn-material-teal.active:not(.btn-link):not(.btn-flat) {
  background-color: #00b5a4;
}
.btn-material-teal-50.active:not(.btn-link):not(.btn-flat) {
  background-color: #cae9e7;
}
.btn-material-teal-100.active:not(.btn-link):not(.btn-flat) {
  background-color: #9cd6d1;
}
.btn-material-teal-200.active:not(.btn-link):not(.btn-flat) {
  background-color: #6ac2ba;
}
.btn-material-teal-300.active:not(.btn-link):not(.btn-flat) {
  background-color: #63bfb6;
}
.btn-material-teal-400.active:not(.btn-link):not(.btn-flat) {
  background-color: #2cbfb1;
}
.btn-material-teal-500.active:not(.btn-link):not(.btn-flat) {
  background-color: #00b5a4;
}
.btn-material-teal-600.active:not(.btn-link):not(.btn-flat) {
  background-color: #00a896;
}
.btn-material-teal-700.active:not(.btn-link):not(.btn-flat) {
  background-color: #009886;
}
.btn-material-teal-800.active:not(.btn-link):not(.btn-flat) {
  background-color: #008877;
}
.btn-material-teal-900.active:not(.btn-link):not(.btn-flat) {
  background-color: #006c59;
}
.btn-material-teal-A100.active:not(.btn-link):not(.btn-flat) {
  background-color: #88ffe4;
}
.btn-material-teal-A200.active:not(.btn-link):not(.btn-flat) {
  background-color: #45ffd3;
}
.btn-material-teal-A400.active:not(.btn-link):not(.btn-flat) {
  background-color: #15d3a3;
}
.btn-material-teal-A700.active:not(.btn-link):not(.btn-flat) {
  background-color: #00debf;
}
.btn-material-green.active:not(.btn-link):not(.btn-flat) {
  background-color: #60ba63;
}
.btn-material-green-50.active:not(.btn-link):not(.btn-flat) {
  background-color: #d3ecd5;
}
.btn-material-green-100.active:not(.btn-link):not(.btn-flat) {
  background-color: #b3dcb4;
}
.btn-material-green-200.active:not(.btn-link):not(.btn-flat) {
  background-color: #90cc92;
}
.btn-material-green-300.active:not(.btn-link):not(.btn-flat) {
  background-color: #6cbe6f;
}
.btn-material-green-400.active:not(.btn-link):not(.btn-flat) {
  background-color: #7bc47f;
}
.btn-material-green-500.active:not(.btn-link):not(.btn-flat) {
  background-color: #60ba63;
}
.btn-material-green-600.active:not(.btn-link):not(.btn-flat) {
  background-color: #4db552;
}
.btn-material-green-700.active:not(.btn-link):not(.btn-flat) {
  background-color: #41a445;
}
.btn-material-green-800.active:not(.btn-link):not(.btn-flat) {
  background-color: #36933b;
}
.btn-material-green-900.active:not(.btn-link):not(.btn-flat) {
  background-color: #227628;
}
.btn-material-green-A100.active:not(.btn-link):not(.btn-flat) {
  background-color: #9ef3b5;
}
.btn-material-green-A200.active:not(.btn-link):not(.btn-flat) {
  background-color: #4ded9f;
}
.btn-material-green-A400.active:not(.btn-link):not(.btn-flat) {
  background-color: #00c766;
}
.btn-material-green-A700.active:not(.btn-link):not(.btn-flat) {
  background-color: #00a946;
}
.btn-material-light-green.active:not(.btn-link):not(.btn-flat) {
  background-color: #7cb33b;
}
.btn-material-light-green-50.active:not(.btn-link):not(.btn-flat) {
  background-color: #e2f1d2;
}
.btn-material-light-green-100.active:not(.btn-link):not(.btn-flat) {
  background-color: #cde5b1;
}
.btn-material-light-green-200.active:not(.btn-link):not(.btn-flat) {
  background-color: #b6d98e;
}
.btn-material-light-green-300.active:not(.btn-link):not(.btn-flat) {
  background-color: #9fcd6a;
}
.btn-material-light-green-400.active:not(.btn-link):not(.btn-flat) {
  background-color: #8dc44e;
}
.btn-material-light-green-500.active:not(.btn-link):not(.btn-flat) {
  background-color: #7cb33b;
}
.btn-material-light-green-600.active:not(.btn-link):not(.btn-flat) {
  background-color: #8bc054;
}
.btn-material-light-green-700.active:not(.btn-link):not(.btn-flat) {
  background-color: #77b640;
}
.btn-material-light-green-800.active:not(.btn-link):not(.btn-flat) {
  background-color: #63a237;
}
.btn-material-light-green-900.active:not(.btn-link):not(.btn-flat) {
  background-color: #3f8125;
}
.btn-material-light-green-A100.active:not(.btn-link):not(.btn-flat) {
  background-color: #beff71;
}
.btn-material-light-green-A200.active:not(.btn-link):not(.btn-flat) {
  background-color: #a4ff3a;
}
.btn-material-light-green-A400.active:not(.btn-link):not(.btn-flat) {
  background-color: #68e300;
}
.btn-material-light-green-A700.active:not(.btn-link):not(.btn-flat) {
  background-color: #57c114;
}
.btn-material-lime.active:not(.btn-link):not(.btn-flat) {
  background-color: #c2d125;
}
.btn-material-lime-50.active:not(.btn-link):not(.btn-flat) {
  background-color: #f2f7cd;
}
.btn-material-lime-100.active:not(.btn-link):not(.btn-flat) {
  background-color: #eaefa9;
}
.btn-material-lime-200.active:not(.btn-link):not(.btn-flat) {
  background-color: #dfea82;
}
.btn-material-lime-300.active:not(.btn-link):not(.btn-flat) {
  background-color: #d5e25b;
}
.btn-material-lime-400.active:not(.btn-link):not(.btn-flat) {
  background-color: #cddc3d;
}
.btn-material-lime-500.active:not(.btn-link):not(.btn-flat) {
  background-color: #c2d125;
}
.btn-material-lime-600.active:not(.btn-link):not(.btn-flat) {
  background-color: #a9b22d;
}
.btn-material-lime-700.active:not(.btn-link):not(.btn-flat) {
  background-color: #979b25;
}
.btn-material-lime-800.active:not(.btn-link):not(.btn-flat) {
  background-color: #b7b62a;
}
.btn-material-lime-900.active:not(.btn-link):not(.btn-flat) {
  background-color: #9c8f1c;
}
.btn-material-lime-A100.active:not(.btn-link):not(.btn-flat) {
  background-color: #f1ff62;
}
.btn-material-lime-A200.active:not(.btn-link):not(.btn-flat) {
  background-color: #ebff22;
}
.btn-material-lime-A400.active:not(.btn-link):not(.btn-flat) {
  background-color: #aee000;
}
.btn-material-lime-A700.active:not(.btn-link):not(.btn-flat) {
  background-color: #97cb00;
}
.btn-material-yellow.active:not(.btn-link):not(.btn-flat) {
  background-color: #ffe81c;
}
.btn-material-yellow-50.active:not(.btn-link):not(.btn-flat) {
  background-color: #fffac8;
}
.btn-material-yellow-100.active:not(.btn-link):not(.btn-flat) {
  background-color: #fff6a5;
}
.btn-material-yellow-200.active:not(.btn-link):not(.btn-flat) {
  background-color: #fff27e;
}
.btn-material-yellow-300.active:not(.btn-link):not(.btn-flat) {
  background-color: #ffee57;
}
.btn-material-yellow-400.active:not(.btn-link):not(.btn-flat) {
  background-color: #ffeb39;
}
.btn-material-yellow-500.active:not(.btn-link):not(.btn-flat) {
  background-color: #ffe81c;
}
.btn-material-yellow-600.active:not(.btn-link):not(.btn-flat) {
  background-color: #fdd217;
}
.btn-material-yellow-700.active:not(.btn-link):not(.btn-flat) {
  background-color: #fab70f;
}
.btn-material-yellow-800.active:not(.btn-link):not(.btn-flat) {
  background-color: #f89c07;
}
.btn-material-yellow-900.active:not(.btn-link):not(.btn-flat) {
  background-color: #f68f34;
}
.btn-material-yellow-A100.active:not(.btn-link):not(.btn-flat) {
  background-color: #ffff6e;
}
.btn-material-yellow-A200.active:not(.btn-link):not(.btn-flat) {
  background-color: #e0e000;
}
.btn-material-yellow-A400.active:not(.btn-link):not(.btn-flat) {
  background-color: #e0ce00;
}
.btn-material-yellow-A700.active:not(.btn-link):not(.btn-flat) {
  background-color: #e0bc00;
}
.btn-material-amber.active:not(.btn-link):not(.btn-flat) {
  background-color: #e7ae00;
}
.btn-material-amber-50.active:not(.btn-link):not(.btn-flat) {
  background-color: #fff1c2;
}
.btn-material-amber-100.active:not(.btn-link):not(.btn-flat) {
  background-color: #ffe494;
}
.btn-material-amber-200.active:not(.btn-link):not(.btn-flat) {
  background-color: #ffd863;
}
.btn-material-amber-300.active:not(.btn-link):not(.btn-flat) {
  background-color: #ffce30;
}
.btn-material-amber-400.active:not(.btn-link):not(.btn-flat) {
  background-color: #ffc209;
}
.btn-material-amber-500.active:not(.btn-link):not(.btn-flat) {
  background-color: #e7ae00;
}
.btn-material-amber-600.active:not(.btn-link):not(.btn-flat) {
  background-color: #e09e00;
}
.btn-material-amber-700.active:not(.btn-link):not(.btn-flat) {
  background-color: #e08d00;
}
.btn-material-amber-800.active:not(.btn-link):not(.btn-flat) {
  background-color: #e07e00;
}
.btn-material-amber-900.active:not(.btn-link):not(.btn-flat) {
  background-color: #ff801f;
}
.btn-material-amber-A100.active:not(.btn-link):not(.btn-flat) {
  background-color: #ffdf60;
}
.btn-material-amber-A200.active:not(.btn-link):not(.btn-flat) {
  background-color: #ffd121;
}
.btn-material-amber-A400.active:not(.btn-link):not(.btn-flat) {
  background-color: #e0ac00;
}
.btn-material-amber-A700.active:not(.btn-link):not(.btn-flat) {
  background-color: #e09600;
}
.btn-material-orange.active:not(.btn-link):not(.btn-flat) {
  background-color: #e08600;
}
.btn-material-orange-50.active:not(.btn-link):not(.btn-flat) {
  background-color: #ffe7c1;
}
.btn-material-orange-100.active:not(.btn-link):not(.btn-flat) {
  background-color: #ffd493;
}
.btn-material-orange-200.active:not(.btn-link):not(.btn-flat) {
  background-color: #ffc061;
}
.btn-material-orange-300.active:not(.btn-link):not(.btn-flat) {
  background-color: #ffab2e;
}
.btn-material-orange-400.active:not(.btn-link):not(.btn-flat) {
  background-color: #ff9b07;
}
.btn-material-orange-500.active:not(.btn-link):not(.btn-flat) {
  background-color: #e08600;
}
.btn-material-orange-600.active:not(.btn-link):not(.btn-flat) {
  background-color: #ff9a1b;
}
.btn-material-orange-700.active:not(.btn-link):not(.btn-flat) {
  background-color: #ff8b15;
}
.btn-material-orange-800.active:not(.btn-link):not(.btn-flat) {
  background-color: #ff7b0f;
}
.btn-material-orange-900.active:not(.btn-link):not(.btn-flat) {
  background-color: #ff5d06;
}
.btn-material-orange-A100.active:not(.btn-link):not(.btn-flat) {
  background-color: #ffc661;
}
.btn-material-orange-A200.active:not(.btn-link):not(.btn-flat) {
  background-color: #ff9e21;
}
.btn-material-orange-A400.active:not(.btn-link):not(.btn-flat) {
  background-color: #e08000;
}
.btn-material-orange-A700.active:not(.btn-link):not(.btn-flat) {
  background-color: #ff7f1f;
}
.btn-material-deep-orange.active:not(.btn-link):not(.btn-flat) {
  background-color: #ff6e41;
}
.btn-material-deep-orange-50.active:not(.btn-link):not(.btn-flat) {
  background-color: #f7d1cd;
}
.btn-material-deep-orange-100.active:not(.btn-link):not(.btn-flat) {
  background-color: #ffb59d;
}
.btn-material-deep-orange-200.active:not(.btn-link):not(.btn-flat) {
  background-color: #ff9472;
}
.btn-material-deep-orange-300.active:not(.btn-link):not(.btn-flat) {
  background-color: #ff7346;
}
.btn-material-deep-orange-400.active:not(.btn-link):not(.btn-flat) {
  background-color: #ff8762;
}
.btn-material-deep-orange-500.active:not(.btn-link):not(.btn-flat) {
  background-color: #ff6e41;
}
.btn-material-deep-orange-600.active:not(.btn-link):not(.btn-flat) {
  background-color: #f5683b;
}
.btn-material-deep-orange-700.active:not(.btn-link):not(.btn-flat) {
  background-color: #e96035;
}
.btn-material-deep-orange-800.active:not(.btn-link):not(.btn-flat) {
  background-color: #ea5122;
}
.btn-material-deep-orange-900.active:not(.btn-link):not(.btn-flat) {
  background-color: #dc3e0e;
}
.btn-material-deep-orange-A100.active:not(.btn-link):not(.btn-flat) {
  background-color: #ff8761;
}
.btn-material-deep-orange-A200.active:not(.btn-link):not(.btn-flat) {
  background-color: #ff855f;
}
.btn-material-deep-orange-A400.active:not(.btn-link):not(.btn-flat) {
  background-color: #ff541f;
}
.btn-material-deep-orange-A700.active:not(.btn-link):not(.btn-flat) {
  background-color: #fc3200;
}
.btn-material-brown.active:not(.btn-link):not(.btn-flat) {
  background-color: #8c6253;
}
.btn-material-brown-50.active:not(.btn-link):not(.btn-flat) {
  background-color: #e2dbd7;
}
.btn-material-brown-100.active:not(.btn-link):not(.btn-flat) {
  background-color: #cabcb6;
}
.btn-material-brown-200.active:not(.btn-link):not(.btn-flat) {
  background-color: #af9a92;
}
.btn-material-brown-300.active:not(.btn-link):not(.btn-flat) {
  background-color: #ae9891;
}
.btn-material-brown-400.active:not(.btn-link):not(.btn-flat) {
  background-color: #9c7d72;
}
.btn-material-brown-500.active:not(.btn-link):not(.btn-flat) {
  background-color: #8c6253;
}
.btn-material-brown-600.active:not(.btn-link):not(.btn-flat) {
  background-color: #80594c;
}
.btn-material-brown-700.active:not(.btn-link):not(.btn-flat) {
  background-color: #704d42;
}
.btn-material-brown-800.active:not(.btn-link):not(.btn-flat) {
  background-color: #614139;
}
.btn-material-brown-900.active:not(.btn-link):not(.btn-flat) {
  background-color: #52332e;
}
.btn-material-brown-A100.active:not(.btn-link):not(.btn-flat) {
  background-color: #cabcb6;
}
.btn-material-brown-A200.active:not(.btn-link):not(.btn-flat) {
  background-color: #af9a92;
}
.btn-material-brown-A400.active:not(.btn-link):not(.btn-flat) {
  background-color: #9c7d72;
}
.btn-material-brown-A700.active:not(.btn-link):not(.btn-flat) {
  background-color: #704d42;
}
.btn-material-grey.active:not(.btn-link):not(.btn-flat) {
  background-color: #adadad;
}
.btn-material-grey-50.active:not(.btn-link):not(.btn-flat) {
  background-color: #ebebeb;
}
.btn-material-grey-100.active:not(.btn-link):not(.btn-flat) {
  background-color: #e6e6e6;
}
.btn-material-grey-200.active:not(.btn-link):not(.btn-flat) {
  background-color: #dfdfdf;
}
.btn-material-grey-300.active:not(.btn-link):not(.btn-flat) {
  background-color: #d1d1d1;
}
.btn-material-grey-400.active:not(.btn-link):not(.btn-flat) {
  background-color: #aeaeae;
}
.btn-material-grey-500.active:not(.btn-link):not(.btn-flat) {
  background-color: #adadad;
}
.btn-material-grey-600.active:not(.btn-link):not(.btn-flat) {
  background-color: #848484;
}
.btn-material-grey-700.active:not(.btn-link):not(.btn-flat) {
  background-color: #707070;
}
.btn-material-grey-800.active:not(.btn-link):not(.btn-flat) {
  background-color: #515151;
}
.btn-material-grey-900.active:not(.btn-link):not(.btn-flat) {
  background-color: #303030;
}
.btn-material-grey-A100.active:not(.btn-link):not(.btn-flat) {
  background-color: #e6e6e6;
}
.btn-material-grey-A200.active:not(.btn-link):not(.btn-flat) {
  background-color: #dfdfdf;
}
.btn-material-grey-A400.active:not(.btn-link):not(.btn-flat) {
  background-color: #aeaeae;
}
.btn-material-grey-A700.active:not(.btn-link):not(.btn-flat) {
  background-color: #707070;
}
.btn-material-blue-grey.active:not(.btn-link):not(.btn-flat) {
  background-color: #6e8d9b;
}
.btn-material-blue-grey-50.active:not(.btn-link):not(.btn-flat) {
  background-color: #dae0e4;
}
.btn-material-blue-grey-100.active:not(.btn-link):not(.btn-flat) {
  background-color: #bdcacf;
}
.btn-material-blue-grey-200.active:not(.btn-link):not(.btn-flat) {
  background-color: #9eafb8;
}
.btn-material-blue-grey-300.active:not(.btn-link):not(.btn-flat) {
  background-color: #a2b3bb;
}
.btn-material-blue-grey-400.active:not(.btn-link):not(.btn-flat) {
  background-color: #8a9fa9;
}
.btn-material-blue-grey-500.active:not(.btn-link):not(.btn-flat) {
  background-color: #6e8d9b;
}
.btn-material-blue-grey-600.active:not(.btn-link):not(.btn-flat) {
  background-color: #607e8c;
}
.btn-material-blue-grey-700.active:not(.btn-link):not(.btn-flat) {
  background-color: #516a76;
}
.btn-material-blue-grey-800.active:not(.btn-link):not(.btn-flat) {
  background-color: #445761;
}
.btn-material-blue-grey-900.active:not(.btn-link):not(.btn-flat) {
  background-color: #32424a;
}
.btn-material-blue-grey-A100.active:not(.btn-link):not(.btn-flat) {
  background-color: #bdcacf;
}
.btn-material-blue-grey-A200.active:not(.btn-link):not(.btn-flat) {
  background-color: #9eafb8;
}
.btn-material-blue-grey-A400.active:not(.btn-link):not(.btn-flat) {
  background-color: #8a9fa9;
}
.btn-material-blue-grey-A700.active:not(.btn-link):not(.btn-flat) {
  background-color: #516a76;
}
.btn.btn-flat:hover:not(.btn-link),
.btn-default.btn-flat:hover:not(.btn-link) {
  background-color: rgba(0, 0, 0, 0.2);
}
.btn-black.btn-flat:hover:not(.btn-link) {
  background-color: rgba(0, 0, 0, 0.2);
}
.btn-white.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 255, 255, 0.2);
}
.btn-inverse.btn-flat:hover:not(.btn-link) {
  background-color: rgba(63, 81, 181, 0.2);
}
.btn-primary.btn-flat:hover:not(.btn-link) {
  background-color: rgba(0, 150, 136, 0.2);
}
.btn-success.btn-flat:hover:not(.btn-link) {
  background-color: rgba(76, 175, 80, 0.2);
}
.btn-info.btn-flat:hover:not(.btn-link) {
  background-color: rgba(3, 169, 244, 0.2);
}
.btn-warning.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 87, 34, 0.2);
}
.btn-danger.btn-flat:hover:not(.btn-link) {
  background-color: rgba(244, 67, 54, 0.2);
}
.btn-material-red.btn-flat:hover:not(.btn-link) {
  background-color: rgba(244, 67, 54, 0.2);
}
.btn-material-red-50.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 235, 238, 0.2);
}
.btn-material-red-100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 205, 210, 0.2);
}
.btn-material-red-200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(239, 154, 154, 0.2);
}
.btn-material-red-300.btn-flat:hover:not(.btn-link) {
  background-color: rgba(229, 115, 115, 0.2);
}
.btn-material-red-400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(239, 83, 80, 0.2);
}
.btn-material-red-500.btn-flat:hover:not(.btn-link) {
  background-color: rgba(244, 67, 54, 0.2);
}
.btn-material-red-600.btn-flat:hover:not(.btn-link) {
  background-color: rgba(229, 57, 53, 0.2);
}
.btn-material-red-700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(211, 47, 47, 0.2);
}
.btn-material-red-800.btn-flat:hover:not(.btn-link) {
  background-color: rgba(198, 40, 40, 0.2);
}
.btn-material-red-900.btn-flat:hover:not(.btn-link) {
  background-color: rgba(183, 28, 28, 0.2);
}
.btn-material-red-A100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 138, 128, 0.2);
}
.btn-material-red-A200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 82, 82, 0.2);
}
.btn-material-red-A400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 23, 68, 0.2);
}
.btn-material-red-A700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(213, 0, 0, 0.2);
}
.btn-material-pink.btn-flat:hover:not(.btn-link) {
  background-color: rgba(233, 30, 99, 0.2);
}
.btn-material-pink-50.btn-flat:hover:not(.btn-link) {
  background-color: rgba(252, 228, 236, 0.2);
}
.btn-material-pink-100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(248, 187, 208, 0.2);
}
.btn-material-pink-200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(244, 143, 177, 0.2);
}
.btn-material-pink-300.btn-flat:hover:not(.btn-link) {
  background-color: rgba(240, 98, 146, 0.2);
}
.btn-material-pink-400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(236, 64, 122, 0.2);
}
.btn-material-pink-500.btn-flat:hover:not(.btn-link) {
  background-color: rgba(233, 30, 99, 0.2);
}
.btn-material-pink-600.btn-flat:hover:not(.btn-link) {
  background-color: rgba(216, 27, 96, 0.2);
}
.btn-material-pink-700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(194, 24, 91, 0.2);
}
.btn-material-pink-800.btn-flat:hover:not(.btn-link) {
  background-color: rgba(173, 20, 87, 0.2);
}
.btn-material-pink-900.btn-flat:hover:not(.btn-link) {
  background-color: rgba(136, 14, 79, 0.2);
}
.btn-material-pink-A100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 128, 171, 0.2);
}
.btn-material-pink-A200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 64, 129, 0.2);
}
.btn-material-pink-A400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(245, 0, 87, 0.2);
}
.btn-material-pink-A700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(197, 17, 98, 0.2);
}
.btn-material-purple.btn-flat:hover:not(.btn-link) {
  background-color: rgba(156, 39, 176, 0.2);
}
.btn-material-purple-50.btn-flat:hover:not(.btn-link) {
  background-color: rgba(243, 229, 245, 0.2);
}
.btn-material-purple-100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(225, 190, 231, 0.2);
}
.btn-material-purple-200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(206, 147, 216, 0.2);
}
.btn-material-purple-300.btn-flat:hover:not(.btn-link) {
  background-color: rgba(186, 104, 200, 0.2);
}
.btn-material-purple-400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(171, 71, 188, 0.2);
}
.btn-material-purple-500.btn-flat:hover:not(.btn-link) {
  background-color: rgba(156, 39, 176, 0.2);
}
.btn-material-purple-600.btn-flat:hover:not(.btn-link) {
  background-color: rgba(142, 36, 170, 0.2);
}
.btn-material-purple-700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(123, 31, 162, 0.2);
}
.btn-material-purple-800.btn-flat:hover:not(.btn-link) {
  background-color: rgba(106, 27, 154, 0.2);
}
.btn-material-purple-900.btn-flat:hover:not(.btn-link) {
  background-color: rgba(74, 20, 140, 0.2);
}
.btn-material-purple-A100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(234, 128, 252, 0.2);
}
.btn-material-purple-A200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(224, 64, 251, 0.2);
}
.btn-material-purple-A400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(213, 0, 249, 0.2);
}
.btn-material-purple-A700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(170, 0, 255, 0.2);
}
.btn-material-deep-purple.btn-flat:hover:not(.btn-link) {
  background-color: rgba(103, 58, 183, 0.2);
}
.btn-material-deep-purple-50.btn-flat:hover:not(.btn-link) {
  background-color: rgba(237, 231, 246, 0.2);
}
.btn-material-deep-purple-100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(209, 196, 233, 0.2);
}
.btn-material-deep-purple-200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(179, 157, 219, 0.2);
}
.btn-material-deep-purple-300.btn-flat:hover:not(.btn-link) {
  background-color: rgba(149, 117, 205, 0.2);
}
.btn-material-deep-purple-400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(126, 87, 194, 0.2);
}
.btn-material-deep-purple-500.btn-flat:hover:not(.btn-link) {
  background-color: rgba(103, 58, 183, 0.2);
}
.btn-material-deep-purple-600.btn-flat:hover:not(.btn-link) {
  background-color: rgba(94, 53, 177, 0.2);
}
.btn-material-deep-purple-700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(81, 45, 168, 0.2);
}
.btn-material-deep-purple-800.btn-flat:hover:not(.btn-link) {
  background-color: rgba(69, 39, 160, 0.2);
}
.btn-material-deep-purple-900.btn-flat:hover:not(.btn-link) {
  background-color: rgba(49, 27, 146, 0.2);
}
.btn-material-deep-purple-A100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(179, 136, 255, 0.2);
}
.btn-material-deep-purple-A200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(124, 77, 255, 0.2);
}
.btn-material-deep-purple-A400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(101, 31, 255, 0.2);
}
.btn-material-deep-purple-A700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(98, 0, 234, 0.2);
}
.btn-material-indigo.btn-flat:hover:not(.btn-link) {
  background-color: rgba(63, 81, 181, 0.2);
}
.btn-material-indigo-50.btn-flat:hover:not(.btn-link) {
  background-color: rgba(232, 234, 246, 0.2);
}
.btn-material-indigo-100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(197, 202, 233, 0.2);
}
.btn-material-indigo-200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(159, 168, 218, 0.2);
}
.btn-material-indigo-300.btn-flat:hover:not(.btn-link) {
  background-color: rgba(121, 134, 203, 0.2);
}
.btn-material-indigo-400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(92, 107, 192, 0.2);
}
.btn-material-indigo-500.btn-flat:hover:not(.btn-link) {
  background-color: rgba(63, 81, 181, 0.2);
}
.btn-material-indigo-600.btn-flat:hover:not(.btn-link) {
  background-color: rgba(57, 73, 171, 0.2);
}
.btn-material-indigo-700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(48, 63, 159, 0.2);
}
.btn-material-indigo-800.btn-flat:hover:not(.btn-link) {
  background-color: rgba(40, 53, 147, 0.2);
}
.btn-material-indigo-900.btn-flat:hover:not(.btn-link) {
  background-color: rgba(26, 35, 126, 0.2);
}
.btn-material-indigo-A100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(140, 158, 255, 0.2);
}
.btn-material-indigo-A200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(83, 109, 254, 0.2);
}
.btn-material-indigo-A400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(61, 90, 254, 0.2);
}
.btn-material-indigo-A700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(48, 79, 254, 0.2);
}
.btn-material-blue.btn-flat:hover:not(.btn-link) {
  background-color: rgba(33, 150, 243, 0.2);
}
.btn-material-blue-50.btn-flat:hover:not(.btn-link) {
  background-color: rgba(227, 242, 253, 0.2);
}
.btn-material-blue-100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(187, 222, 251, 0.2);
}
.btn-material-blue-200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(144, 202, 249, 0.2);
}
.btn-material-blue-300.btn-flat:hover:not(.btn-link) {
  background-color: rgba(100, 181, 246, 0.2);
}
.btn-material-blue-400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(66, 165, 245, 0.2);
}
.btn-material-blue-500.btn-flat:hover:not(.btn-link) {
  background-color: rgba(33, 150, 243, 0.2);
}
.btn-material-blue-600.btn-flat:hover:not(.btn-link) {
  background-color: rgba(30, 136, 229, 0.2);
}
.btn-material-blue-700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(25, 118, 210, 0.2);
}
.btn-material-blue-800.btn-flat:hover:not(.btn-link) {
  background-color: rgba(21, 101, 192, 0.2);
}
.btn-material-blue-900.btn-flat:hover:not(.btn-link) {
  background-color: rgba(13, 71, 161, 0.2);
}
.btn-material-blue-A100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(130, 177, 255, 0.2);
}
.btn-material-blue-A200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(68, 138, 255, 0.2);
}
.btn-material-blue-A400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(41, 121, 255, 0.2);
}
.btn-material-blue-A700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(41, 98, 255, 0.2);
}
.btn-material-light-blue.btn-flat:hover:not(.btn-link) {
  background-color: rgba(3, 169, 244, 0.2);
}
.btn-material-light-blue-50.btn-flat:hover:not(.btn-link) {
  background-color: rgba(225, 245, 254, 0.2);
}
.btn-material-light-blue-100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(179, 229, 252, 0.2);
}
.btn-material-light-blue-200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(129, 212, 250, 0.2);
}
.btn-material-light-blue-300.btn-flat:hover:not(.btn-link) {
  background-color: rgba(79, 195, 247, 0.2);
}
.btn-material-light-blue-400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(41, 182, 246, 0.2);
}
.btn-material-light-blue-500.btn-flat:hover:not(.btn-link) {
  background-color: rgba(3, 169, 244, 0.2);
}
.btn-material-light-blue-600.btn-flat:hover:not(.btn-link) {
  background-color: rgba(3, 155, 229, 0.2);
}
.btn-material-light-blue-700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(2, 136, 209, 0.2);
}
.btn-material-light-blue-800.btn-flat:hover:not(.btn-link) {
  background-color: rgba(2, 119, 189, 0.2);
}
.btn-material-light-blue-900.btn-flat:hover:not(.btn-link) {
  background-color: rgba(1, 87, 155, 0.2);
}
.btn-material-light-blue-A100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(128, 216, 255, 0.2);
}
.btn-material-light-blue-A200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(64, 196, 255, 0.2);
}
.btn-material-light-blue-A400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(0, 176, 255, 0.2);
}
.btn-material-light-blue-A700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(0, 145, 234, 0.2);
}
.btn-material-cyan.btn-flat:hover:not(.btn-link) {
  background-color: rgba(0, 188, 212, 0.2);
}
.btn-material-cyan-50.btn-flat:hover:not(.btn-link) {
  background-color: rgba(224, 247, 250, 0.2);
}
.btn-material-cyan-100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(178, 235, 242, 0.2);
}
.btn-material-cyan-200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(128, 222, 234, 0.2);
}
.btn-material-cyan-300.btn-flat:hover:not(.btn-link) {
  background-color: rgba(77, 208, 225, 0.2);
}
.btn-material-cyan-400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(38, 198, 218, 0.2);
}
.btn-material-cyan-500.btn-flat:hover:not(.btn-link) {
  background-color: rgba(0, 188, 212, 0.2);
}
.btn-material-cyan-600.btn-flat:hover:not(.btn-link) {
  background-color: rgba(0, 172, 193, 0.2);
}
.btn-material-cyan-700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(0, 151, 167, 0.2);
}
.btn-material-cyan-800.btn-flat:hover:not(.btn-link) {
  background-color: rgba(0, 131, 143, 0.2);
}
.btn-material-cyan-900.btn-flat:hover:not(.btn-link) {
  background-color: rgba(0, 96, 100, 0.2);
}
.btn-material-cyan-A100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(132, 255, 255, 0.2);
}
.btn-material-cyan-A200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(24, 255, 255, 0.2);
}
.btn-material-cyan-A400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(0, 229, 255, 0.2);
}
.btn-material-cyan-A700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(0, 184, 212, 0.2);
}
.btn-material-teal.btn-flat:hover:not(.btn-link) {
  background-color: rgba(0, 150, 136, 0.2);
}
.btn-material-teal-50.btn-flat:hover:not(.btn-link) {
  background-color: rgba(224, 242, 241, 0.2);
}
.btn-material-teal-100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(178, 223, 219, 0.2);
}
.btn-material-teal-200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(128, 203, 196, 0.2);
}
.btn-material-teal-300.btn-flat:hover:not(.btn-link) {
  background-color: rgba(77, 182, 172, 0.2);
}
.btn-material-teal-400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(38, 166, 154, 0.2);
}
.btn-material-teal-500.btn-flat:hover:not(.btn-link) {
  background-color: rgba(0, 150, 136, 0.2);
}
.btn-material-teal-600.btn-flat:hover:not(.btn-link) {
  background-color: rgba(0, 137, 123, 0.2);
}
.btn-material-teal-700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(0, 121, 107, 0.2);
}
.btn-material-teal-800.btn-flat:hover:not(.btn-link) {
  background-color: rgba(0, 105, 92, 0.2);
}
.btn-material-teal-900.btn-flat:hover:not(.btn-link) {
  background-color: rgba(0, 77, 64, 0.2);
}
.btn-material-teal-A100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(167, 255, 235, 0.2);
}
.btn-material-teal-A200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(100, 255, 218, 0.2);
}
.btn-material-teal-A400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(29, 233, 182, 0.2);
}
.btn-material-teal-A700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(0, 191, 165, 0.2);
}
.btn-material-green.btn-flat:hover:not(.btn-link) {
  background-color: rgba(76, 175, 80, 0.2);
}
.btn-material-green-50.btn-flat:hover:not(.btn-link) {
  background-color: rgba(232, 245, 233, 0.2);
}
.btn-material-green-100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(200, 230, 201, 0.2);
}
.btn-material-green-200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(165, 214, 167, 0.2);
}
.btn-material-green-300.btn-flat:hover:not(.btn-link) {
  background-color: rgba(129, 199, 132, 0.2);
}
.btn-material-green-400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(102, 187, 106, 0.2);
}
.btn-material-green-500.btn-flat:hover:not(.btn-link) {
  background-color: rgba(76, 175, 80, 0.2);
}
.btn-material-green-600.btn-flat:hover:not(.btn-link) {
  background-color: rgba(67, 160, 71, 0.2);
}
.btn-material-green-700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(56, 142, 60, 0.2);
}
.btn-material-green-800.btn-flat:hover:not(.btn-link) {
  background-color: rgba(46, 125, 50, 0.2);
}
.btn-material-green-900.btn-flat:hover:not(.btn-link) {
  background-color: rgba(27, 94, 32, 0.2);
}
.btn-material-green-A100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(185, 246, 202, 0.2);
}
.btn-material-green-A200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(105, 240, 174, 0.2);
}
.btn-material-green-A400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(0, 230, 118, 0.2);
}
.btn-material-green-A700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(0, 200, 83, 0.2);
}
.btn-material-light-green.btn-flat:hover:not(.btn-link) {
  background-color: rgba(139, 195, 74, 0.2);
}
.btn-material-light-green-50.btn-flat:hover:not(.btn-link) {
  background-color: rgba(241, 248, 233, 0.2);
}
.btn-material-light-green-100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(220, 237, 200, 0.2);
}
.btn-material-light-green-200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(197, 225, 165, 0.2);
}
.btn-material-light-green-300.btn-flat:hover:not(.btn-link) {
  background-color: rgba(174, 213, 129, 0.2);
}
.btn-material-light-green-400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(156, 204, 101, 0.2);
}
.btn-material-light-green-500.btn-flat:hover:not(.btn-link) {
  background-color: rgba(139, 195, 74, 0.2);
}
.btn-material-light-green-600.btn-flat:hover:not(.btn-link) {
  background-color: rgba(124, 179, 66, 0.2);
}
.btn-material-light-green-700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(104, 159, 56, 0.2);
}
.btn-material-light-green-800.btn-flat:hover:not(.btn-link) {
  background-color: rgba(85, 139, 47, 0.2);
}
.btn-material-light-green-900.btn-flat:hover:not(.btn-link) {
  background-color: rgba(51, 105, 30, 0.2);
}
.btn-material-light-green-A100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(204, 255, 144, 0.2);
}
.btn-material-light-green-A200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(178, 255, 89, 0.2);
}
.btn-material-light-green-A400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(118, 255, 3, 0.2);
}
.btn-material-light-green-A700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(100, 221, 23, 0.2);
}
.btn-material-lime.btn-flat:hover:not(.btn-link) {
  background-color: rgba(205, 220, 57, 0.2);
}
.btn-material-lime-50.btn-flat:hover:not(.btn-link) {
  background-color: rgba(249, 251, 231, 0.2);
}
.btn-material-lime-100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(240, 244, 195, 0.2);
}
.btn-material-lime-200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(230, 238, 156, 0.2);
}
.btn-material-lime-300.btn-flat:hover:not(.btn-link) {
  background-color: rgba(220, 231, 117, 0.2);
}
.btn-material-lime-400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(212, 225, 87, 0.2);
}
.btn-material-lime-500.btn-flat:hover:not(.btn-link) {
  background-color: rgba(205, 220, 57, 0.2);
}
.btn-material-lime-600.btn-flat:hover:not(.btn-link) {
  background-color: rgba(192, 202, 51, 0.2);
}
.btn-material-lime-700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(175, 180, 43, 0.2);
}
.btn-material-lime-800.btn-flat:hover:not(.btn-link) {
  background-color: rgba(158, 157, 36, 0.2);
}
.btn-material-lime-900.btn-flat:hover:not(.btn-link) {
  background-color: rgba(130, 119, 23, 0.2);
}
.btn-material-lime-A100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(244, 255, 129, 0.2);
}
.btn-material-lime-A200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(238, 255, 65, 0.2);
}
.btn-material-lime-A400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(198, 255, 0, 0.2);
}
.btn-material-lime-A700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(174, 234, 0, 0.2);
}
.btn-material-yellow.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 235, 59, 0.2);
}
.btn-material-yellow-50.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 253, 231, 0.2);
}
.btn-material-yellow-100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 249, 196, 0.2);
}
.btn-material-yellow-200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 245, 157, 0.2);
}
.btn-material-yellow-300.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 241, 118, 0.2);
}
.btn-material-yellow-400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 238, 88, 0.2);
}
.btn-material-yellow-500.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 235, 59, 0.2);
}
.btn-material-yellow-600.btn-flat:hover:not(.btn-link) {
  background-color: rgba(253, 216, 53, 0.2);
}
.btn-material-yellow-700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(251, 192, 45, 0.2);
}
.btn-material-yellow-800.btn-flat:hover:not(.btn-link) {
  background-color: rgba(249, 168, 37, 0.2);
}
.btn-material-yellow-900.btn-flat:hover:not(.btn-link) {
  background-color: rgba(245, 127, 23, 0.2);
}
.btn-material-yellow-A100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 255, 141, 0.2);
}
.btn-material-yellow-A200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 255, 0, 0.2);
}
.btn-material-yellow-A400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 234, 0, 0.2);
}
.btn-material-yellow-A700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 214, 0, 0.2);
}
.btn-material-amber.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 193, 7, 0.2);
}
.btn-material-amber-50.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 248, 225, 0.2);
}
.btn-material-amber-100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 236, 179, 0.2);
}
.btn-material-amber-200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 224, 130, 0.2);
}
.btn-material-amber-300.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 213, 79, 0.2);
}
.btn-material-amber-400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 202, 40, 0.2);
}
.btn-material-amber-500.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 193, 7, 0.2);
}
.btn-material-amber-600.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 179, 0, 0.2);
}
.btn-material-amber-700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 160, 0, 0.2);
}
.btn-material-amber-800.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 143, 0, 0.2);
}
.btn-material-amber-900.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 111, 0, 0.2);
}
.btn-material-amber-A100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 229, 127, 0.2);
}
.btn-material-amber-A200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 215, 64, 0.2);
}
.btn-material-amber-A400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 196, 0, 0.2);
}
.btn-material-amber-A700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 171, 0, 0.2);
}
.btn-material-orange.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 152, 0, 0.2);
}
.btn-material-orange-50.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 243, 224, 0.2);
}
.btn-material-orange-100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 224, 178, 0.2);
}
.btn-material-orange-200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 204, 128, 0.2);
}
.btn-material-orange-300.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 183, 77, 0.2);
}
.btn-material-orange-400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 167, 38, 0.2);
}
.btn-material-orange-500.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 152, 0, 0.2);
}
.btn-material-orange-600.btn-flat:hover:not(.btn-link) {
  background-color: rgba(251, 140, 0, 0.2);
}
.btn-material-orange-700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(245, 124, 0, 0.2);
}
.btn-material-orange-800.btn-flat:hover:not(.btn-link) {
  background-color: rgba(239, 108, 0, 0.2);
}
.btn-material-orange-900.btn-flat:hover:not(.btn-link) {
  background-color: rgba(230, 81, 0, 0.2);
}
.btn-material-orange-A100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 209, 128, 0.2);
}
.btn-material-orange-A200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 171, 64, 0.2);
}
.btn-material-orange-A400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 145, 0, 0.2);
}
.btn-material-orange-A700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 109, 0, 0.2);
}
.btn-material-deep-orange.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 87, 34, 0.2);
}
.btn-material-deep-orange-50.btn-flat:hover:not(.btn-link) {
  background-color: rgba(251, 233, 231, 0.2);
}
.btn-material-deep-orange-100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 204, 188, 0.2);
}
.btn-material-deep-orange-200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 171, 145, 0.2);
}
.btn-material-deep-orange-300.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 138, 101, 0.2);
}
.btn-material-deep-orange-400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 112, 67, 0.2);
}
.btn-material-deep-orange-500.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 87, 34, 0.2);
}
.btn-material-deep-orange-600.btn-flat:hover:not(.btn-link) {
  background-color: rgba(244, 81, 30, 0.2);
}
.btn-material-deep-orange-700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(230, 74, 25, 0.2);
}
.btn-material-deep-orange-800.btn-flat:hover:not(.btn-link) {
  background-color: rgba(216, 67, 21, 0.2);
}
.btn-material-deep-orange-900.btn-flat:hover:not(.btn-link) {
  background-color: rgba(191, 54, 12, 0.2);
}
.btn-material-deep-orange-A100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 158, 128, 0.2);
}
.btn-material-deep-orange-A200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 110, 64, 0.2);
}
.btn-material-deep-orange-A400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(255, 61, 0, 0.2);
}
.btn-material-deep-orange-A700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(221, 44, 0, 0.2);
}
.btn-material-brown.btn-flat:hover:not(.btn-link) {
  background-color: rgba(121, 85, 72, 0.2);
}
.btn-material-brown-50.btn-flat:hover:not(.btn-link) {
  background-color: rgba(239, 235, 233, 0.2);
}
.btn-material-brown-100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(215, 204, 200, 0.2);
}
.btn-material-brown-200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(188, 170, 164, 0.2);
}
.btn-material-brown-300.btn-flat:hover:not(.btn-link) {
  background-color: rgba(161, 136, 127, 0.2);
}
.btn-material-brown-400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(141, 110, 99, 0.2);
}
.btn-material-brown-500.btn-flat:hover:not(.btn-link) {
  background-color: rgba(121, 85, 72, 0.2);
}
.btn-material-brown-600.btn-flat:hover:not(.btn-link) {
  background-color: rgba(109, 76, 65, 0.2);
}
.btn-material-brown-700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(93, 64, 55, 0.2);
}
.btn-material-brown-800.btn-flat:hover:not(.btn-link) {
  background-color: rgba(78, 52, 46, 0.2);
}
.btn-material-brown-900.btn-flat:hover:not(.btn-link) {
  background-color: rgba(62, 39, 35, 0.2);
}
.btn-material-brown-A100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(215, 204, 200, 0.2);
}
.btn-material-brown-A200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(188, 170, 164, 0.2);
}
.btn-material-brown-A400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(141, 110, 99, 0.2);
}
.btn-material-brown-A700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(93, 64, 55, 0.2);
}
.btn-material-grey.btn-flat:hover:not(.btn-link) {
  background-color: rgba(158, 158, 158, 0.2);
}
.btn-material-grey-50.btn-flat:hover:not(.btn-link) {
  background-color: rgba(250, 250, 250, 0.2);
}
.btn-material-grey-100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(245, 245, 245, 0.2);
}
.btn-material-grey-200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(238, 238, 238, 0.2);
}
.btn-material-grey-300.btn-flat:hover:not(.btn-link) {
  background-color: rgba(224, 224, 224, 0.2);
}
.btn-material-grey-400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(189, 189, 189, 0.2);
}
.btn-material-grey-500.btn-flat:hover:not(.btn-link) {
  background-color: rgba(158, 158, 158, 0.2);
}
.btn-material-grey-600.btn-flat:hover:not(.btn-link) {
  background-color: rgba(117, 117, 117, 0.2);
}
.btn-material-grey-700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(97, 97, 97, 0.2);
}
.btn-material-grey-800.btn-flat:hover:not(.btn-link) {
  background-color: rgba(66, 66, 66, 0.2);
}
.btn-material-grey-900.btn-flat:hover:not(.btn-link) {
  background-color: rgba(33, 33, 33, 0.2);
}
.btn-material-grey-A100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(245, 245, 245, 0.2);
}
.btn-material-grey-A200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(238, 238, 238, 0.2);
}
.btn-material-grey-A400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(189, 189, 189, 0.2);
}
.btn-material-grey-A700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(97, 97, 97, 0.2);
}
.btn-material-blue-grey.btn-flat:hover:not(.btn-link) {
  background-color: rgba(96, 125, 139, 0.2);
}
.btn-material-blue-grey-50.btn-flat:hover:not(.btn-link) {
  background-color: rgba(236, 239, 241, 0.2);
}
.btn-material-blue-grey-100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(207, 216, 220, 0.2);
}
.btn-material-blue-grey-200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(176, 190, 197, 0.2);
}
.btn-material-blue-grey-300.btn-flat:hover:not(.btn-link) {
  background-color: rgba(144, 164, 174, 0.2);
}
.btn-material-blue-grey-400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(120, 144, 156, 0.2);
}
.btn-material-blue-grey-500.btn-flat:hover:not(.btn-link) {
  background-color: rgba(96, 125, 139, 0.2);
}
.btn-material-blue-grey-600.btn-flat:hover:not(.btn-link) {
  background-color: rgba(84, 110, 122, 0.2);
}
.btn-material-blue-grey-700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(69, 90, 100, 0.2);
}
.btn-material-blue-grey-800.btn-flat:hover:not(.btn-link) {
  background-color: rgba(55, 71, 79, 0.2);
}
.btn-material-blue-grey-900.btn-flat:hover:not(.btn-link) {
  background-color: rgba(38, 50, 56, 0.2);
}
.btn-material-blue-grey-A100.btn-flat:hover:not(.btn-link) {
  background-color: rgba(207, 216, 220, 0.2);
}
.btn-material-blue-grey-A200.btn-flat:hover:not(.btn-link) {
  background-color: rgba(176, 190, 197, 0.2);
}
.btn-material-blue-grey-A400.btn-flat:hover:not(.btn-link) {
  background-color: rgba(120, 144, 156, 0.2);
}
.btn-material-blue-grey-A700.btn-flat:hover:not(.btn-link) {
  background-color: rgba(69, 90, 100, 0.2);
}
.btn.btn-flat,
.input-group-btn .btn.btn-flat {
  background: none;
  box-shadow: none;
  font-weight: 500;
}
.btn.btn-flat:disabled,
.input-group-btn .btn.btn-flat:disabled {
  color: #a8a8a8 !important;
}
.btn.btn-sm,
.input-group-btn .btn.btn-sm {
  padding: 5px 20px;
}
.btn.btn-xs,
.input-group-btn .btn.btn-xs {
  padding: 4px 15px;
  font-size: 10px;
}
.btn.btn-raised,
.input-group-btn .btn.btn-raised {
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}
.btn.btn-raised:active:not(.btn-link),
.input-group-btn .btn.btn-raised:active:not(.btn-link) {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.btn.btn-fab,
.input-group-btn .btn.btn-fab {
  margin: 0;
  padding: 15px;
  font-size: 26px;
  width: 56px;
  height: 56px;
}
.btn.btn-fab,
.input-group-btn .btn.btn-fab,
.btn.btn-fab:hover,
.input-group-btn .btn.btn-fab:hover,
.btn.btn-fab:active,
.input-group-btn .btn.btn-fab:active,
.btn.btn-fab-default,
.input-group-btn .btn.btn-fab-default,
.btn.btn-fab:hover-default,
.input-group-btn .btn.btn-fab:hover-default,
.btn.btn-fab:active-default,
.input-group-btn .btn.btn-fab:active-default {
  background-color: transparent;
}
.btn.btn-fab-black,
.input-group-btn .btn.btn-fab-black,
.btn.btn-fab:hover-black,
.input-group-btn .btn.btn-fab:hover-black,
.btn.btn-fab:active-black,
.input-group-btn .btn.btn-fab:active-black {
  background-color: #000000;
}
.btn.btn-fab-white,
.input-group-btn .btn.btn-fab-white,
.btn.btn-fab:hover-white,
.input-group-btn .btn.btn-fab:hover-white,
.btn.btn-fab:active-white,
.input-group-btn .btn.btn-fab:active-white {
  background-color: #ffffff;
}
.btn.btn-fab-inverse,
.input-group-btn .btn.btn-fab-inverse,
.btn.btn-fab:hover-inverse,
.input-group-btn .btn.btn-fab:hover-inverse,
.btn.btn-fab:active-inverse,
.input-group-btn .btn.btn-fab:active-inverse {
  background-color: #3f51b5;
}
.btn.btn-fab-primary,
.input-group-btn .btn.btn-fab-primary,
.btn.btn-fab:hover-primary,
.input-group-btn .btn.btn-fab:hover-primary,
.btn.btn-fab:active-primary,
.input-group-btn .btn.btn-fab:active-primary {
  background-color: #009688;
}
.btn.btn-fab-success,
.input-group-btn .btn.btn-fab-success,
.btn.btn-fab:hover-success,
.input-group-btn .btn.btn-fab:hover-success,
.btn.btn-fab:active-success,
.input-group-btn .btn.btn-fab:active-success {
  background-color: #4caf50;
}
.btn.btn-fab-info,
.input-group-btn .btn.btn-fab-info,
.btn.btn-fab:hover-info,
.input-group-btn .btn.btn-fab:hover-info,
.btn.btn-fab:active-info,
.input-group-btn .btn.btn-fab:active-info {
  background-color: #03a9f4;
}
.btn.btn-fab-warning,
.input-group-btn .btn.btn-fab-warning,
.btn.btn-fab:hover-warning,
.input-group-btn .btn.btn-fab:hover-warning,
.btn.btn-fab:active-warning,
.input-group-btn .btn.btn-fab:active-warning {
  background-color: #ff5722;
}
.btn.btn-fab-danger,
.input-group-btn .btn.btn-fab-danger,
.btn.btn-fab:hover-danger,
.input-group-btn .btn.btn-fab:hover-danger,
.btn.btn-fab:active-danger,
.input-group-btn .btn.btn-fab:active-danger {
  background-color: #f44336;
}
.btn.btn-fab-material-red,
.input-group-btn .btn.btn-fab-material-red,
.btn.btn-fab:hover-material-red,
.input-group-btn .btn.btn-fab:hover-material-red,
.btn.btn-fab:active-material-red,
.input-group-btn .btn.btn-fab:active-material-red {
  background-color: #f44336;
}
.btn.btn-fab-material-red-50,
.input-group-btn .btn.btn-fab-material-red-50,
.btn.btn-fab:hover-material-red-50,
.input-group-btn .btn.btn-fab:hover-material-red-50,
.btn.btn-fab:active-material-red-50,
.input-group-btn .btn.btn-fab:active-material-red-50 {
  background-color: #ffebee;
}
.btn.btn-fab-material-red-100,
.input-group-btn .btn.btn-fab-material-red-100,
.btn.btn-fab:hover-material-red-100,
.input-group-btn .btn.btn-fab:hover-material-red-100,
.btn.btn-fab:active-material-red-100,
.input-group-btn .btn.btn-fab:active-material-red-100 {
  background-color: #ffcdd2;
}
.btn.btn-fab-material-red-200,
.input-group-btn .btn.btn-fab-material-red-200,
.btn.btn-fab:hover-material-red-200,
.input-group-btn .btn.btn-fab:hover-material-red-200,
.btn.btn-fab:active-material-red-200,
.input-group-btn .btn.btn-fab:active-material-red-200 {
  background-color: #ef9a9a;
}
.btn.btn-fab-material-red-300,
.input-group-btn .btn.btn-fab-material-red-300,
.btn.btn-fab:hover-material-red-300,
.input-group-btn .btn.btn-fab:hover-material-red-300,
.btn.btn-fab:active-material-red-300,
.input-group-btn .btn.btn-fab:active-material-red-300 {
  background-color: #e57373;
}
.btn.btn-fab-material-red-400,
.input-group-btn .btn.btn-fab-material-red-400,
.btn.btn-fab:hover-material-red-400,
.input-group-btn .btn.btn-fab:hover-material-red-400,
.btn.btn-fab:active-material-red-400,
.input-group-btn .btn.btn-fab:active-material-red-400 {
  background-color: #ef5350;
}
.btn.btn-fab-material-red-500,
.input-group-btn .btn.btn-fab-material-red-500,
.btn.btn-fab:hover-material-red-500,
.input-group-btn .btn.btn-fab:hover-material-red-500,
.btn.btn-fab:active-material-red-500,
.input-group-btn .btn.btn-fab:active-material-red-500 {
  background-color: #f44336;
}
.btn.btn-fab-material-red-600,
.input-group-btn .btn.btn-fab-material-red-600,
.btn.btn-fab:hover-material-red-600,
.input-group-btn .btn.btn-fab:hover-material-red-600,
.btn.btn-fab:active-material-red-600,
.input-group-btn .btn.btn-fab:active-material-red-600 {
  background-color: #e53935;
}
.btn.btn-fab-material-red-700,
.input-group-btn .btn.btn-fab-material-red-700,
.btn.btn-fab:hover-material-red-700,
.input-group-btn .btn.btn-fab:hover-material-red-700,
.btn.btn-fab:active-material-red-700,
.input-group-btn .btn.btn-fab:active-material-red-700 {
  background-color: #d32f2f;
}
.btn.btn-fab-material-red-800,
.input-group-btn .btn.btn-fab-material-red-800,
.btn.btn-fab:hover-material-red-800,
.input-group-btn .btn.btn-fab:hover-material-red-800,
.btn.btn-fab:active-material-red-800,
.input-group-btn .btn.btn-fab:active-material-red-800 {
  background-color: #c62828;
}
.btn.btn-fab-material-red-900,
.input-group-btn .btn.btn-fab-material-red-900,
.btn.btn-fab:hover-material-red-900,
.input-group-btn .btn.btn-fab:hover-material-red-900,
.btn.btn-fab:active-material-red-900,
.input-group-btn .btn.btn-fab:active-material-red-900 {
  background-color: #b71c1c;
}
.btn.btn-fab-material-red-A100,
.input-group-btn .btn.btn-fab-material-red-A100,
.btn.btn-fab:hover-material-red-A100,
.input-group-btn .btn.btn-fab:hover-material-red-A100,
.btn.btn-fab:active-material-red-A100,
.input-group-btn .btn.btn-fab:active-material-red-A100 {
  background-color: #ff8a80;
}
.btn.btn-fab-material-red-A200,
.input-group-btn .btn.btn-fab-material-red-A200,
.btn.btn-fab:hover-material-red-A200,
.input-group-btn .btn.btn-fab:hover-material-red-A200,
.btn.btn-fab:active-material-red-A200,
.input-group-btn .btn.btn-fab:active-material-red-A200 {
  background-color: #ff5252;
}
.btn.btn-fab-material-red-A400,
.input-group-btn .btn.btn-fab-material-red-A400,
.btn.btn-fab:hover-material-red-A400,
.input-group-btn .btn.btn-fab:hover-material-red-A400,
.btn.btn-fab:active-material-red-A400,
.input-group-btn .btn.btn-fab:active-material-red-A400 {
  background-color: #ff1744;
}
.btn.btn-fab-material-red-A700,
.input-group-btn .btn.btn-fab-material-red-A700,
.btn.btn-fab:hover-material-red-A700,
.input-group-btn .btn.btn-fab:hover-material-red-A700,
.btn.btn-fab:active-material-red-A700,
.input-group-btn .btn.btn-fab:active-material-red-A700 {
  background-color: #d50000;
}
.btn.btn-fab-material-pink,
.input-group-btn .btn.btn-fab-material-pink,
.btn.btn-fab:hover-material-pink,
.input-group-btn .btn.btn-fab:hover-material-pink,
.btn.btn-fab:active-material-pink,
.input-group-btn .btn.btn-fab:active-material-pink {
  background-color: #e91e63;
}
.btn.btn-fab-material-pink-50,
.input-group-btn .btn.btn-fab-material-pink-50,
.btn.btn-fab:hover-material-pink-50,
.input-group-btn .btn.btn-fab:hover-material-pink-50,
.btn.btn-fab:active-material-pink-50,
.input-group-btn .btn.btn-fab:active-material-pink-50 {
  background-color: #fce4ec;
}
.btn.btn-fab-material-pink-100,
.input-group-btn .btn.btn-fab-material-pink-100,
.btn.btn-fab:hover-material-pink-100,
.input-group-btn .btn.btn-fab:hover-material-pink-100,
.btn.btn-fab:active-material-pink-100,
.input-group-btn .btn.btn-fab:active-material-pink-100 {
  background-color: #f8bbd0;
}
.btn.btn-fab-material-pink-200,
.input-group-btn .btn.btn-fab-material-pink-200,
.btn.btn-fab:hover-material-pink-200,
.input-group-btn .btn.btn-fab:hover-material-pink-200,
.btn.btn-fab:active-material-pink-200,
.input-group-btn .btn.btn-fab:active-material-pink-200 {
  background-color: #f48fb1;
}
.btn.btn-fab-material-pink-300,
.input-group-btn .btn.btn-fab-material-pink-300,
.btn.btn-fab:hover-material-pink-300,
.input-group-btn .btn.btn-fab:hover-material-pink-300,
.btn.btn-fab:active-material-pink-300,
.input-group-btn .btn.btn-fab:active-material-pink-300 {
  background-color: #f06292;
}
.btn.btn-fab-material-pink-400,
.input-group-btn .btn.btn-fab-material-pink-400,
.btn.btn-fab:hover-material-pink-400,
.input-group-btn .btn.btn-fab:hover-material-pink-400,
.btn.btn-fab:active-material-pink-400,
.input-group-btn .btn.btn-fab:active-material-pink-400 {
  background-color: #ec407a;
}
.btn.btn-fab-material-pink-500,
.input-group-btn .btn.btn-fab-material-pink-500,
.btn.btn-fab:hover-material-pink-500,
.input-group-btn .btn.btn-fab:hover-material-pink-500,
.btn.btn-fab:active-material-pink-500,
.input-group-btn .btn.btn-fab:active-material-pink-500 {
  background-color: #e91e63;
}
.btn.btn-fab-material-pink-600,
.input-group-btn .btn.btn-fab-material-pink-600,
.btn.btn-fab:hover-material-pink-600,
.input-group-btn .btn.btn-fab:hover-material-pink-600,
.btn.btn-fab:active-material-pink-600,
.input-group-btn .btn.btn-fab:active-material-pink-600 {
  background-color: #d81b60;
}
.btn.btn-fab-material-pink-700,
.input-group-btn .btn.btn-fab-material-pink-700,
.btn.btn-fab:hover-material-pink-700,
.input-group-btn .btn.btn-fab:hover-material-pink-700,
.btn.btn-fab:active-material-pink-700,
.input-group-btn .btn.btn-fab:active-material-pink-700 {
  background-color: #c2185b;
}
.btn.btn-fab-material-pink-800,
.input-group-btn .btn.btn-fab-material-pink-800,
.btn.btn-fab:hover-material-pink-800,
.input-group-btn .btn.btn-fab:hover-material-pink-800,
.btn.btn-fab:active-material-pink-800,
.input-group-btn .btn.btn-fab:active-material-pink-800 {
  background-color: #ad1457;
}
.btn.btn-fab-material-pink-900,
.input-group-btn .btn.btn-fab-material-pink-900,
.btn.btn-fab:hover-material-pink-900,
.input-group-btn .btn.btn-fab:hover-material-pink-900,
.btn.btn-fab:active-material-pink-900,
.input-group-btn .btn.btn-fab:active-material-pink-900 {
  background-color: #880e4f;
}
.btn.btn-fab-material-pink-A100,
.input-group-btn .btn.btn-fab-material-pink-A100,
.btn.btn-fab:hover-material-pink-A100,
.input-group-btn .btn.btn-fab:hover-material-pink-A100,
.btn.btn-fab:active-material-pink-A100,
.input-group-btn .btn.btn-fab:active-material-pink-A100 {
  background-color: #ff80ab;
}
.btn.btn-fab-material-pink-A200,
.input-group-btn .btn.btn-fab-material-pink-A200,
.btn.btn-fab:hover-material-pink-A200,
.input-group-btn .btn.btn-fab:hover-material-pink-A200,
.btn.btn-fab:active-material-pink-A200,
.input-group-btn .btn.btn-fab:active-material-pink-A200 {
  background-color: #ff4081;
}
.btn.btn-fab-material-pink-A400,
.input-group-btn .btn.btn-fab-material-pink-A400,
.btn.btn-fab:hover-material-pink-A400,
.input-group-btn .btn.btn-fab:hover-material-pink-A400,
.btn.btn-fab:active-material-pink-A400,
.input-group-btn .btn.btn-fab:active-material-pink-A400 {
  background-color: #f50057;
}
.btn.btn-fab-material-pink-A700,
.input-group-btn .btn.btn-fab-material-pink-A700,
.btn.btn-fab:hover-material-pink-A700,
.input-group-btn .btn.btn-fab:hover-material-pink-A700,
.btn.btn-fab:active-material-pink-A700,
.input-group-btn .btn.btn-fab:active-material-pink-A700 {
  background-color: #c51162;
}
.btn.btn-fab-material-purple,
.input-group-btn .btn.btn-fab-material-purple,
.btn.btn-fab:hover-material-purple,
.input-group-btn .btn.btn-fab:hover-material-purple,
.btn.btn-fab:active-material-purple,
.input-group-btn .btn.btn-fab:active-material-purple {
  background-color: #9c27b0;
}
.btn.btn-fab-material-purple-50,
.input-group-btn .btn.btn-fab-material-purple-50,
.btn.btn-fab:hover-material-purple-50,
.input-group-btn .btn.btn-fab:hover-material-purple-50,
.btn.btn-fab:active-material-purple-50,
.input-group-btn .btn.btn-fab:active-material-purple-50 {
  background-color: #f3e5f5;
}
.btn.btn-fab-material-purple-100,
.input-group-btn .btn.btn-fab-material-purple-100,
.btn.btn-fab:hover-material-purple-100,
.input-group-btn .btn.btn-fab:hover-material-purple-100,
.btn.btn-fab:active-material-purple-100,
.input-group-btn .btn.btn-fab:active-material-purple-100 {
  background-color: #e1bee7;
}
.btn.btn-fab-material-purple-200,
.input-group-btn .btn.btn-fab-material-purple-200,
.btn.btn-fab:hover-material-purple-200,
.input-group-btn .btn.btn-fab:hover-material-purple-200,
.btn.btn-fab:active-material-purple-200,
.input-group-btn .btn.btn-fab:active-material-purple-200 {
  background-color: #ce93d8;
}
.btn.btn-fab-material-purple-300,
.input-group-btn .btn.btn-fab-material-purple-300,
.btn.btn-fab:hover-material-purple-300,
.input-group-btn .btn.btn-fab:hover-material-purple-300,
.btn.btn-fab:active-material-purple-300,
.input-group-btn .btn.btn-fab:active-material-purple-300 {
  background-color: #ba68c8;
}
.btn.btn-fab-material-purple-400,
.input-group-btn .btn.btn-fab-material-purple-400,
.btn.btn-fab:hover-material-purple-400,
.input-group-btn .btn.btn-fab:hover-material-purple-400,
.btn.btn-fab:active-material-purple-400,
.input-group-btn .btn.btn-fab:active-material-purple-400 {
  background-color: #ab47bc;
}
.btn.btn-fab-material-purple-500,
.input-group-btn .btn.btn-fab-material-purple-500,
.btn.btn-fab:hover-material-purple-500,
.input-group-btn .btn.btn-fab:hover-material-purple-500,
.btn.btn-fab:active-material-purple-500,
.input-group-btn .btn.btn-fab:active-material-purple-500 {
  background-color: #9c27b0;
}
.btn.btn-fab-material-purple-600,
.input-group-btn .btn.btn-fab-material-purple-600,
.btn.btn-fab:hover-material-purple-600,
.input-group-btn .btn.btn-fab:hover-material-purple-600,
.btn.btn-fab:active-material-purple-600,
.input-group-btn .btn.btn-fab:active-material-purple-600 {
  background-color: #8e24aa;
}
.btn.btn-fab-material-purple-700,
.input-group-btn .btn.btn-fab-material-purple-700,
.btn.btn-fab:hover-material-purple-700,
.input-group-btn .btn.btn-fab:hover-material-purple-700,
.btn.btn-fab:active-material-purple-700,
.input-group-btn .btn.btn-fab:active-material-purple-700 {
  background-color: #7b1fa2;
}
.btn.btn-fab-material-purple-800,
.input-group-btn .btn.btn-fab-material-purple-800,
.btn.btn-fab:hover-material-purple-800,
.input-group-btn .btn.btn-fab:hover-material-purple-800,
.btn.btn-fab:active-material-purple-800,
.input-group-btn .btn.btn-fab:active-material-purple-800 {
  background-color: #6a1b9a;
}
.btn.btn-fab-material-purple-900,
.input-group-btn .btn.btn-fab-material-purple-900,
.btn.btn-fab:hover-material-purple-900,
.input-group-btn .btn.btn-fab:hover-material-purple-900,
.btn.btn-fab:active-material-purple-900,
.input-group-btn .btn.btn-fab:active-material-purple-900 {
  background-color: #4a148c;
}
.btn.btn-fab-material-purple-A100,
.input-group-btn .btn.btn-fab-material-purple-A100,
.btn.btn-fab:hover-material-purple-A100,
.input-group-btn .btn.btn-fab:hover-material-purple-A100,
.btn.btn-fab:active-material-purple-A100,
.input-group-btn .btn.btn-fab:active-material-purple-A100 {
  background-color: #ea80fc;
}
.btn.btn-fab-material-purple-A200,
.input-group-btn .btn.btn-fab-material-purple-A200,
.btn.btn-fab:hover-material-purple-A200,
.input-group-btn .btn.btn-fab:hover-material-purple-A200,
.btn.btn-fab:active-material-purple-A200,
.input-group-btn .btn.btn-fab:active-material-purple-A200 {
  background-color: #e040fb;
}
.btn.btn-fab-material-purple-A400,
.input-group-btn .btn.btn-fab-material-purple-A400,
.btn.btn-fab:hover-material-purple-A400,
.input-group-btn .btn.btn-fab:hover-material-purple-A400,
.btn.btn-fab:active-material-purple-A400,
.input-group-btn .btn.btn-fab:active-material-purple-A400 {
  background-color: #d500f9;
}
.btn.btn-fab-material-purple-A700,
.input-group-btn .btn.btn-fab-material-purple-A700,
.btn.btn-fab:hover-material-purple-A700,
.input-group-btn .btn.btn-fab:hover-material-purple-A700,
.btn.btn-fab:active-material-purple-A700,
.input-group-btn .btn.btn-fab:active-material-purple-A700 {
  background-color: #aa00ff;
}
.btn.btn-fab-material-deep-purple,
.input-group-btn .btn.btn-fab-material-deep-purple,
.btn.btn-fab:hover-material-deep-purple,
.input-group-btn .btn.btn-fab:hover-material-deep-purple,
.btn.btn-fab:active-material-deep-purple,
.input-group-btn .btn.btn-fab:active-material-deep-purple {
  background-color: #673ab7;
}
.btn.btn-fab-material-deep-purple-50,
.input-group-btn .btn.btn-fab-material-deep-purple-50,
.btn.btn-fab:hover-material-deep-purple-50,
.input-group-btn .btn.btn-fab:hover-material-deep-purple-50,
.btn.btn-fab:active-material-deep-purple-50,
.input-group-btn .btn.btn-fab:active-material-deep-purple-50 {
  background-color: #ede7f6;
}
.btn.btn-fab-material-deep-purple-100,
.input-group-btn .btn.btn-fab-material-deep-purple-100,
.btn.btn-fab:hover-material-deep-purple-100,
.input-group-btn .btn.btn-fab:hover-material-deep-purple-100,
.btn.btn-fab:active-material-deep-purple-100,
.input-group-btn .btn.btn-fab:active-material-deep-purple-100 {
  background-color: #d1c4e9;
}
.btn.btn-fab-material-deep-purple-200,
.input-group-btn .btn.btn-fab-material-deep-purple-200,
.btn.btn-fab:hover-material-deep-purple-200,
.input-group-btn .btn.btn-fab:hover-material-deep-purple-200,
.btn.btn-fab:active-material-deep-purple-200,
.input-group-btn .btn.btn-fab:active-material-deep-purple-200 {
  background-color: #b39ddb;
}
.btn.btn-fab-material-deep-purple-300,
.input-group-btn .btn.btn-fab-material-deep-purple-300,
.btn.btn-fab:hover-material-deep-purple-300,
.input-group-btn .btn.btn-fab:hover-material-deep-purple-300,
.btn.btn-fab:active-material-deep-purple-300,
.input-group-btn .btn.btn-fab:active-material-deep-purple-300 {
  background-color: #9575cd;
}
.btn.btn-fab-material-deep-purple-400,
.input-group-btn .btn.btn-fab-material-deep-purple-400,
.btn.btn-fab:hover-material-deep-purple-400,
.input-group-btn .btn.btn-fab:hover-material-deep-purple-400,
.btn.btn-fab:active-material-deep-purple-400,
.input-group-btn .btn.btn-fab:active-material-deep-purple-400 {
  background-color: #7e57c2;
}
.btn.btn-fab-material-deep-purple-500,
.input-group-btn .btn.btn-fab-material-deep-purple-500,
.btn.btn-fab:hover-material-deep-purple-500,
.input-group-btn .btn.btn-fab:hover-material-deep-purple-500,
.btn.btn-fab:active-material-deep-purple-500,
.input-group-btn .btn.btn-fab:active-material-deep-purple-500 {
  background-color: #673ab7;
}
.btn.btn-fab-material-deep-purple-600,
.input-group-btn .btn.btn-fab-material-deep-purple-600,
.btn.btn-fab:hover-material-deep-purple-600,
.input-group-btn .btn.btn-fab:hover-material-deep-purple-600,
.btn.btn-fab:active-material-deep-purple-600,
.input-group-btn .btn.btn-fab:active-material-deep-purple-600 {
  background-color: #5e35b1;
}
.btn.btn-fab-material-deep-purple-700,
.input-group-btn .btn.btn-fab-material-deep-purple-700,
.btn.btn-fab:hover-material-deep-purple-700,
.input-group-btn .btn.btn-fab:hover-material-deep-purple-700,
.btn.btn-fab:active-material-deep-purple-700,
.input-group-btn .btn.btn-fab:active-material-deep-purple-700 {
  background-color: #512da8;
}
.btn.btn-fab-material-deep-purple-800,
.input-group-btn .btn.btn-fab-material-deep-purple-800,
.btn.btn-fab:hover-material-deep-purple-800,
.input-group-btn .btn.btn-fab:hover-material-deep-purple-800,
.btn.btn-fab:active-material-deep-purple-800,
.input-group-btn .btn.btn-fab:active-material-deep-purple-800 {
  background-color: #4527a0;
}
.btn.btn-fab-material-deep-purple-900,
.input-group-btn .btn.btn-fab-material-deep-purple-900,
.btn.btn-fab:hover-material-deep-purple-900,
.input-group-btn .btn.btn-fab:hover-material-deep-purple-900,
.btn.btn-fab:active-material-deep-purple-900,
.input-group-btn .btn.btn-fab:active-material-deep-purple-900 {
  background-color: #311b92;
}
.btn.btn-fab-material-deep-purple-A100,
.input-group-btn .btn.btn-fab-material-deep-purple-A100,
.btn.btn-fab:hover-material-deep-purple-A100,
.input-group-btn .btn.btn-fab:hover-material-deep-purple-A100,
.btn.btn-fab:active-material-deep-purple-A100,
.input-group-btn .btn.btn-fab:active-material-deep-purple-A100 {
  background-color: #b388ff;
}
.btn.btn-fab-material-deep-purple-A200,
.input-group-btn .btn.btn-fab-material-deep-purple-A200,
.btn.btn-fab:hover-material-deep-purple-A200,
.input-group-btn .btn.btn-fab:hover-material-deep-purple-A200,
.btn.btn-fab:active-material-deep-purple-A200,
.input-group-btn .btn.btn-fab:active-material-deep-purple-A200 {
  background-color: #7c4dff;
}
.btn.btn-fab-material-deep-purple-A400,
.input-group-btn .btn.btn-fab-material-deep-purple-A400,
.btn.btn-fab:hover-material-deep-purple-A400,
.input-group-btn .btn.btn-fab:hover-material-deep-purple-A400,
.btn.btn-fab:active-material-deep-purple-A400,
.input-group-btn .btn.btn-fab:active-material-deep-purple-A400 {
  background-color: #651fff;
}
.btn.btn-fab-material-deep-purple-A700,
.input-group-btn .btn.btn-fab-material-deep-purple-A700,
.btn.btn-fab:hover-material-deep-purple-A700,
.input-group-btn .btn.btn-fab:hover-material-deep-purple-A700,
.btn.btn-fab:active-material-deep-purple-A700,
.input-group-btn .btn.btn-fab:active-material-deep-purple-A700 {
  background-color: #6200ea;
}
.btn.btn-fab-material-indigo,
.input-group-btn .btn.btn-fab-material-indigo,
.btn.btn-fab:hover-material-indigo,
.input-group-btn .btn.btn-fab:hover-material-indigo,
.btn.btn-fab:active-material-indigo,
.input-group-btn .btn.btn-fab:active-material-indigo {
  background-color: #3f51b5;
}
.btn.btn-fab-material-indigo-50,
.input-group-btn .btn.btn-fab-material-indigo-50,
.btn.btn-fab:hover-material-indigo-50,
.input-group-btn .btn.btn-fab:hover-material-indigo-50,
.btn.btn-fab:active-material-indigo-50,
.input-group-btn .btn.btn-fab:active-material-indigo-50 {
  background-color: #e8eaf6;
}
.btn.btn-fab-material-indigo-100,
.input-group-btn .btn.btn-fab-material-indigo-100,
.btn.btn-fab:hover-material-indigo-100,
.input-group-btn .btn.btn-fab:hover-material-indigo-100,
.btn.btn-fab:active-material-indigo-100,
.input-group-btn .btn.btn-fab:active-material-indigo-100 {
  background-color: #c5cae9;
}
.btn.btn-fab-material-indigo-200,
.input-group-btn .btn.btn-fab-material-indigo-200,
.btn.btn-fab:hover-material-indigo-200,
.input-group-btn .btn.btn-fab:hover-material-indigo-200,
.btn.btn-fab:active-material-indigo-200,
.input-group-btn .btn.btn-fab:active-material-indigo-200 {
  background-color: #9fa8da;
}
.btn.btn-fab-material-indigo-300,
.input-group-btn .btn.btn-fab-material-indigo-300,
.btn.btn-fab:hover-material-indigo-300,
.input-group-btn .btn.btn-fab:hover-material-indigo-300,
.btn.btn-fab:active-material-indigo-300,
.input-group-btn .btn.btn-fab:active-material-indigo-300 {
  background-color: #7986cb;
}
.btn.btn-fab-material-indigo-400,
.input-group-btn .btn.btn-fab-material-indigo-400,
.btn.btn-fab:hover-material-indigo-400,
.input-group-btn .btn.btn-fab:hover-material-indigo-400,
.btn.btn-fab:active-material-indigo-400,
.input-group-btn .btn.btn-fab:active-material-indigo-400 {
  background-color: #5c6bc0;
}
.btn.btn-fab-material-indigo-500,
.input-group-btn .btn.btn-fab-material-indigo-500,
.btn.btn-fab:hover-material-indigo-500,
.input-group-btn .btn.btn-fab:hover-material-indigo-500,
.btn.btn-fab:active-material-indigo-500,
.input-group-btn .btn.btn-fab:active-material-indigo-500 {
  background-color: #3f51b5;
}
.btn.btn-fab-material-indigo-600,
.input-group-btn .btn.btn-fab-material-indigo-600,
.btn.btn-fab:hover-material-indigo-600,
.input-group-btn .btn.btn-fab:hover-material-indigo-600,
.btn.btn-fab:active-material-indigo-600,
.input-group-btn .btn.btn-fab:active-material-indigo-600 {
  background-color: #3949ab;
}
.btn.btn-fab-material-indigo-700,
.input-group-btn .btn.btn-fab-material-indigo-700,
.btn.btn-fab:hover-material-indigo-700,
.input-group-btn .btn.btn-fab:hover-material-indigo-700,
.btn.btn-fab:active-material-indigo-700,
.input-group-btn .btn.btn-fab:active-material-indigo-700 {
  background-color: #303f9f;
}
.btn.btn-fab-material-indigo-800,
.input-group-btn .btn.btn-fab-material-indigo-800,
.btn.btn-fab:hover-material-indigo-800,
.input-group-btn .btn.btn-fab:hover-material-indigo-800,
.btn.btn-fab:active-material-indigo-800,
.input-group-btn .btn.btn-fab:active-material-indigo-800 {
  background-color: #283593;
}
.btn.btn-fab-material-indigo-900,
.input-group-btn .btn.btn-fab-material-indigo-900,
.btn.btn-fab:hover-material-indigo-900,
.input-group-btn .btn.btn-fab:hover-material-indigo-900,
.btn.btn-fab:active-material-indigo-900,
.input-group-btn .btn.btn-fab:active-material-indigo-900 {
  background-color: #1a237e;
}
.btn.btn-fab-material-indigo-A100,
.input-group-btn .btn.btn-fab-material-indigo-A100,
.btn.btn-fab:hover-material-indigo-A100,
.input-group-btn .btn.btn-fab:hover-material-indigo-A100,
.btn.btn-fab:active-material-indigo-A100,
.input-group-btn .btn.btn-fab:active-material-indigo-A100 {
  background-color: #8c9eff;
}
.btn.btn-fab-material-indigo-A200,
.input-group-btn .btn.btn-fab-material-indigo-A200,
.btn.btn-fab:hover-material-indigo-A200,
.input-group-btn .btn.btn-fab:hover-material-indigo-A200,
.btn.btn-fab:active-material-indigo-A200,
.input-group-btn .btn.btn-fab:active-material-indigo-A200 {
  background-color: #536dfe;
}
.btn.btn-fab-material-indigo-A400,
.input-group-btn .btn.btn-fab-material-indigo-A400,
.btn.btn-fab:hover-material-indigo-A400,
.input-group-btn .btn.btn-fab:hover-material-indigo-A400,
.btn.btn-fab:active-material-indigo-A400,
.input-group-btn .btn.btn-fab:active-material-indigo-A400 {
  background-color: #3d5afe;
}
.btn.btn-fab-material-indigo-A700,
.input-group-btn .btn.btn-fab-material-indigo-A700,
.btn.btn-fab:hover-material-indigo-A700,
.input-group-btn .btn.btn-fab:hover-material-indigo-A700,
.btn.btn-fab:active-material-indigo-A700,
.input-group-btn .btn.btn-fab:active-material-indigo-A700 {
  background-color: #304ffe;
}
.btn.btn-fab-material-blue,
.input-group-btn .btn.btn-fab-material-blue,
.btn.btn-fab:hover-material-blue,
.input-group-btn .btn.btn-fab:hover-material-blue,
.btn.btn-fab:active-material-blue,
.input-group-btn .btn.btn-fab:active-material-blue {
  background-color: #2196f3;
}
.btn.btn-fab-material-blue-50,
.input-group-btn .btn.btn-fab-material-blue-50,
.btn.btn-fab:hover-material-blue-50,
.input-group-btn .btn.btn-fab:hover-material-blue-50,
.btn.btn-fab:active-material-blue-50,
.input-group-btn .btn.btn-fab:active-material-blue-50 {
  background-color: #e3f2fd;
}
.btn.btn-fab-material-blue-100,
.input-group-btn .btn.btn-fab-material-blue-100,
.btn.btn-fab:hover-material-blue-100,
.input-group-btn .btn.btn-fab:hover-material-blue-100,
.btn.btn-fab:active-material-blue-100,
.input-group-btn .btn.btn-fab:active-material-blue-100 {
  background-color: #bbdefb;
}
.btn.btn-fab-material-blue-200,
.input-group-btn .btn.btn-fab-material-blue-200,
.btn.btn-fab:hover-material-blue-200,
.input-group-btn .btn.btn-fab:hover-material-blue-200,
.btn.btn-fab:active-material-blue-200,
.input-group-btn .btn.btn-fab:active-material-blue-200 {
  background-color: #90caf9;
}
.btn.btn-fab-material-blue-300,
.input-group-btn .btn.btn-fab-material-blue-300,
.btn.btn-fab:hover-material-blue-300,
.input-group-btn .btn.btn-fab:hover-material-blue-300,
.btn.btn-fab:active-material-blue-300,
.input-group-btn .btn.btn-fab:active-material-blue-300 {
  background-color: #64b5f6;
}
.btn.btn-fab-material-blue-400,
.input-group-btn .btn.btn-fab-material-blue-400,
.btn.btn-fab:hover-material-blue-400,
.input-group-btn .btn.btn-fab:hover-material-blue-400,
.btn.btn-fab:active-material-blue-400,
.input-group-btn .btn.btn-fab:active-material-blue-400 {
  background-color: #42a5f5;
}
.btn.btn-fab-material-blue-500,
.input-group-btn .btn.btn-fab-material-blue-500,
.btn.btn-fab:hover-material-blue-500,
.input-group-btn .btn.btn-fab:hover-material-blue-500,
.btn.btn-fab:active-material-blue-500,
.input-group-btn .btn.btn-fab:active-material-blue-500 {
  background-color: #2196f3;
}
.btn.btn-fab-material-blue-600,
.input-group-btn .btn.btn-fab-material-blue-600,
.btn.btn-fab:hover-material-blue-600,
.input-group-btn .btn.btn-fab:hover-material-blue-600,
.btn.btn-fab:active-material-blue-600,
.input-group-btn .btn.btn-fab:active-material-blue-600 {
  background-color: #1e88e5;
}
.btn.btn-fab-material-blue-700,
.input-group-btn .btn.btn-fab-material-blue-700,
.btn.btn-fab:hover-material-blue-700,
.input-group-btn .btn.btn-fab:hover-material-blue-700,
.btn.btn-fab:active-material-blue-700,
.input-group-btn .btn.btn-fab:active-material-blue-700 {
  background-color: #1976d2;
}
.btn.btn-fab-material-blue-800,
.input-group-btn .btn.btn-fab-material-blue-800,
.btn.btn-fab:hover-material-blue-800,
.input-group-btn .btn.btn-fab:hover-material-blue-800,
.btn.btn-fab:active-material-blue-800,
.input-group-btn .btn.btn-fab:active-material-blue-800 {
  background-color: #1565c0;
}
.btn.btn-fab-material-blue-900,
.input-group-btn .btn.btn-fab-material-blue-900,
.btn.btn-fab:hover-material-blue-900,
.input-group-btn .btn.btn-fab:hover-material-blue-900,
.btn.btn-fab:active-material-blue-900,
.input-group-btn .btn.btn-fab:active-material-blue-900 {
  background-color: #0d47a1;
}
.btn.btn-fab-material-blue-A100,
.input-group-btn .btn.btn-fab-material-blue-A100,
.btn.btn-fab:hover-material-blue-A100,
.input-group-btn .btn.btn-fab:hover-material-blue-A100,
.btn.btn-fab:active-material-blue-A100,
.input-group-btn .btn.btn-fab:active-material-blue-A100 {
  background-color: #82b1ff;
}
.btn.btn-fab-material-blue-A200,
.input-group-btn .btn.btn-fab-material-blue-A200,
.btn.btn-fab:hover-material-blue-A200,
.input-group-btn .btn.btn-fab:hover-material-blue-A200,
.btn.btn-fab:active-material-blue-A200,
.input-group-btn .btn.btn-fab:active-material-blue-A200 {
  background-color: #448aff;
}
.btn.btn-fab-material-blue-A400,
.input-group-btn .btn.btn-fab-material-blue-A400,
.btn.btn-fab:hover-material-blue-A400,
.input-group-btn .btn.btn-fab:hover-material-blue-A400,
.btn.btn-fab:active-material-blue-A400,
.input-group-btn .btn.btn-fab:active-material-blue-A400 {
  background-color: #2979ff;
}
.btn.btn-fab-material-blue-A700,
.input-group-btn .btn.btn-fab-material-blue-A700,
.btn.btn-fab:hover-material-blue-A700,
.input-group-btn .btn.btn-fab:hover-material-blue-A700,
.btn.btn-fab:active-material-blue-A700,
.input-group-btn .btn.btn-fab:active-material-blue-A700 {
  background-color: #2962ff;
}
.btn.btn-fab-material-light-blue,
.input-group-btn .btn.btn-fab-material-light-blue,
.btn.btn-fab:hover-material-light-blue,
.input-group-btn .btn.btn-fab:hover-material-light-blue,
.btn.btn-fab:active-material-light-blue,
.input-group-btn .btn.btn-fab:active-material-light-blue {
  background-color: #03a9f4;
}
.btn.btn-fab-material-light-blue-50,
.input-group-btn .btn.btn-fab-material-light-blue-50,
.btn.btn-fab:hover-material-light-blue-50,
.input-group-btn .btn.btn-fab:hover-material-light-blue-50,
.btn.btn-fab:active-material-light-blue-50,
.input-group-btn .btn.btn-fab:active-material-light-blue-50 {
  background-color: #e1f5fe;
}
.btn.btn-fab-material-light-blue-100,
.input-group-btn .btn.btn-fab-material-light-blue-100,
.btn.btn-fab:hover-material-light-blue-100,
.input-group-btn .btn.btn-fab:hover-material-light-blue-100,
.btn.btn-fab:active-material-light-blue-100,
.input-group-btn .btn.btn-fab:active-material-light-blue-100 {
  background-color: #b3e5fc;
}
.btn.btn-fab-material-light-blue-200,
.input-group-btn .btn.btn-fab-material-light-blue-200,
.btn.btn-fab:hover-material-light-blue-200,
.input-group-btn .btn.btn-fab:hover-material-light-blue-200,
.btn.btn-fab:active-material-light-blue-200,
.input-group-btn .btn.btn-fab:active-material-light-blue-200 {
  background-color: #81d4fa;
}
.btn.btn-fab-material-light-blue-300,
.input-group-btn .btn.btn-fab-material-light-blue-300,
.btn.btn-fab:hover-material-light-blue-300,
.input-group-btn .btn.btn-fab:hover-material-light-blue-300,
.btn.btn-fab:active-material-light-blue-300,
.input-group-btn .btn.btn-fab:active-material-light-blue-300 {
  background-color: #4fc3f7;
}
.btn.btn-fab-material-light-blue-400,
.input-group-btn .btn.btn-fab-material-light-blue-400,
.btn.btn-fab:hover-material-light-blue-400,
.input-group-btn .btn.btn-fab:hover-material-light-blue-400,
.btn.btn-fab:active-material-light-blue-400,
.input-group-btn .btn.btn-fab:active-material-light-blue-400 {
  background-color: #29b6f6;
}
.btn.btn-fab-material-light-blue-500,
.input-group-btn .btn.btn-fab-material-light-blue-500,
.btn.btn-fab:hover-material-light-blue-500,
.input-group-btn .btn.btn-fab:hover-material-light-blue-500,
.btn.btn-fab:active-material-light-blue-500,
.input-group-btn .btn.btn-fab:active-material-light-blue-500 {
  background-color: #03a9f4;
}
.btn.btn-fab-material-light-blue-600,
.input-group-btn .btn.btn-fab-material-light-blue-600,
.btn.btn-fab:hover-material-light-blue-600,
.input-group-btn .btn.btn-fab:hover-material-light-blue-600,
.btn.btn-fab:active-material-light-blue-600,
.input-group-btn .btn.btn-fab:active-material-light-blue-600 {
  background-color: #039be5;
}
.btn.btn-fab-material-light-blue-700,
.input-group-btn .btn.btn-fab-material-light-blue-700,
.btn.btn-fab:hover-material-light-blue-700,
.input-group-btn .btn.btn-fab:hover-material-light-blue-700,
.btn.btn-fab:active-material-light-blue-700,
.input-group-btn .btn.btn-fab:active-material-light-blue-700 {
  background-color: #0288d1;
}
.btn.btn-fab-material-light-blue-800,
.input-group-btn .btn.btn-fab-material-light-blue-800,
.btn.btn-fab:hover-material-light-blue-800,
.input-group-btn .btn.btn-fab:hover-material-light-blue-800,
.btn.btn-fab:active-material-light-blue-800,
.input-group-btn .btn.btn-fab:active-material-light-blue-800 {
  background-color: #0277bd;
}
.btn.btn-fab-material-light-blue-900,
.input-group-btn .btn.btn-fab-material-light-blue-900,
.btn.btn-fab:hover-material-light-blue-900,
.input-group-btn .btn.btn-fab:hover-material-light-blue-900,
.btn.btn-fab:active-material-light-blue-900,
.input-group-btn .btn.btn-fab:active-material-light-blue-900 {
  background-color: #01579b;
}
.btn.btn-fab-material-light-blue-A100,
.input-group-btn .btn.btn-fab-material-light-blue-A100,
.btn.btn-fab:hover-material-light-blue-A100,
.input-group-btn .btn.btn-fab:hover-material-light-blue-A100,
.btn.btn-fab:active-material-light-blue-A100,
.input-group-btn .btn.btn-fab:active-material-light-blue-A100 {
  background-color: #80d8ff;
}
.btn.btn-fab-material-light-blue-A200,
.input-group-btn .btn.btn-fab-material-light-blue-A200,
.btn.btn-fab:hover-material-light-blue-A200,
.input-group-btn .btn.btn-fab:hover-material-light-blue-A200,
.btn.btn-fab:active-material-light-blue-A200,
.input-group-btn .btn.btn-fab:active-material-light-blue-A200 {
  background-color: #40c4ff;
}
.btn.btn-fab-material-light-blue-A400,
.input-group-btn .btn.btn-fab-material-light-blue-A400,
.btn.btn-fab:hover-material-light-blue-A400,
.input-group-btn .btn.btn-fab:hover-material-light-blue-A400,
.btn.btn-fab:active-material-light-blue-A400,
.input-group-btn .btn.btn-fab:active-material-light-blue-A400 {
  background-color: #00b0ff;
}
.btn.btn-fab-material-light-blue-A700,
.input-group-btn .btn.btn-fab-material-light-blue-A700,
.btn.btn-fab:hover-material-light-blue-A700,
.input-group-btn .btn.btn-fab:hover-material-light-blue-A700,
.btn.btn-fab:active-material-light-blue-A700,
.input-group-btn .btn.btn-fab:active-material-light-blue-A700 {
  background-color: #0091ea;
}
.btn.btn-fab-material-cyan,
.input-group-btn .btn.btn-fab-material-cyan,
.btn.btn-fab:hover-material-cyan,
.input-group-btn .btn.btn-fab:hover-material-cyan,
.btn.btn-fab:active-material-cyan,
.input-group-btn .btn.btn-fab:active-material-cyan {
  background-color: #00bcd4;
}
.btn.btn-fab-material-cyan-50,
.input-group-btn .btn.btn-fab-material-cyan-50,
.btn.btn-fab:hover-material-cyan-50,
.input-group-btn .btn.btn-fab:hover-material-cyan-50,
.btn.btn-fab:active-material-cyan-50,
.input-group-btn .btn.btn-fab:active-material-cyan-50 {
  background-color: #e0f7fa;
}
.btn.btn-fab-material-cyan-100,
.input-group-btn .btn.btn-fab-material-cyan-100,
.btn.btn-fab:hover-material-cyan-100,
.input-group-btn .btn.btn-fab:hover-material-cyan-100,
.btn.btn-fab:active-material-cyan-100,
.input-group-btn .btn.btn-fab:active-material-cyan-100 {
  background-color: #b2ebf2;
}
.btn.btn-fab-material-cyan-200,
.input-group-btn .btn.btn-fab-material-cyan-200,
.btn.btn-fab:hover-material-cyan-200,
.input-group-btn .btn.btn-fab:hover-material-cyan-200,
.btn.btn-fab:active-material-cyan-200,
.input-group-btn .btn.btn-fab:active-material-cyan-200 {
  background-color: #80deea;
}
.btn.btn-fab-material-cyan-300,
.input-group-btn .btn.btn-fab-material-cyan-300,
.btn.btn-fab:hover-material-cyan-300,
.input-group-btn .btn.btn-fab:hover-material-cyan-300,
.btn.btn-fab:active-material-cyan-300,
.input-group-btn .btn.btn-fab:active-material-cyan-300 {
  background-color: #4dd0e1;
}
.btn.btn-fab-material-cyan-400,
.input-group-btn .btn.btn-fab-material-cyan-400,
.btn.btn-fab:hover-material-cyan-400,
.input-group-btn .btn.btn-fab:hover-material-cyan-400,
.btn.btn-fab:active-material-cyan-400,
.input-group-btn .btn.btn-fab:active-material-cyan-400 {
  background-color: #26c6da;
}
.btn.btn-fab-material-cyan-500,
.input-group-btn .btn.btn-fab-material-cyan-500,
.btn.btn-fab:hover-material-cyan-500,
.input-group-btn .btn.btn-fab:hover-material-cyan-500,
.btn.btn-fab:active-material-cyan-500,
.input-group-btn .btn.btn-fab:active-material-cyan-500 {
  background-color: #00bcd4;
}
.btn.btn-fab-material-cyan-600,
.input-group-btn .btn.btn-fab-material-cyan-600,
.btn.btn-fab:hover-material-cyan-600,
.input-group-btn .btn.btn-fab:hover-material-cyan-600,
.btn.btn-fab:active-material-cyan-600,
.input-group-btn .btn.btn-fab:active-material-cyan-600 {
  background-color: #00acc1;
}
.btn.btn-fab-material-cyan-700,
.input-group-btn .btn.btn-fab-material-cyan-700,
.btn.btn-fab:hover-material-cyan-700,
.input-group-btn .btn.btn-fab:hover-material-cyan-700,
.btn.btn-fab:active-material-cyan-700,
.input-group-btn .btn.btn-fab:active-material-cyan-700 {
  background-color: #0097a7;
}
.btn.btn-fab-material-cyan-800,
.input-group-btn .btn.btn-fab-material-cyan-800,
.btn.btn-fab:hover-material-cyan-800,
.input-group-btn .btn.btn-fab:hover-material-cyan-800,
.btn.btn-fab:active-material-cyan-800,
.input-group-btn .btn.btn-fab:active-material-cyan-800 {
  background-color: #00838f;
}
.btn.btn-fab-material-cyan-900,
.input-group-btn .btn.btn-fab-material-cyan-900,
.btn.btn-fab:hover-material-cyan-900,
.input-group-btn .btn.btn-fab:hover-material-cyan-900,
.btn.btn-fab:active-material-cyan-900,
.input-group-btn .btn.btn-fab:active-material-cyan-900 {
  background-color: #006064;
}
.btn.btn-fab-material-cyan-A100,
.input-group-btn .btn.btn-fab-material-cyan-A100,
.btn.btn-fab:hover-material-cyan-A100,
.input-group-btn .btn.btn-fab:hover-material-cyan-A100,
.btn.btn-fab:active-material-cyan-A100,
.input-group-btn .btn.btn-fab:active-material-cyan-A100 {
  background-color: #84ffff;
}
.btn.btn-fab-material-cyan-A200,
.input-group-btn .btn.btn-fab-material-cyan-A200,
.btn.btn-fab:hover-material-cyan-A200,
.input-group-btn .btn.btn-fab:hover-material-cyan-A200,
.btn.btn-fab:active-material-cyan-A200,
.input-group-btn .btn.btn-fab:active-material-cyan-A200 {
  background-color: #18ffff;
}
.btn.btn-fab-material-cyan-A400,
.input-group-btn .btn.btn-fab-material-cyan-A400,
.btn.btn-fab:hover-material-cyan-A400,
.input-group-btn .btn.btn-fab:hover-material-cyan-A400,
.btn.btn-fab:active-material-cyan-A400,
.input-group-btn .btn.btn-fab:active-material-cyan-A400 {
  background-color: #00e5ff;
}
.btn.btn-fab-material-cyan-A700,
.input-group-btn .btn.btn-fab-material-cyan-A700,
.btn.btn-fab:hover-material-cyan-A700,
.input-group-btn .btn.btn-fab:hover-material-cyan-A700,
.btn.btn-fab:active-material-cyan-A700,
.input-group-btn .btn.btn-fab:active-material-cyan-A700 {
  background-color: #00b8d4;
}
.btn.btn-fab-material-teal,
.input-group-btn .btn.btn-fab-material-teal,
.btn.btn-fab:hover-material-teal,
.input-group-btn .btn.btn-fab:hover-material-teal,
.btn.btn-fab:active-material-teal,
.input-group-btn .btn.btn-fab:active-material-teal {
  background-color: #009688;
}
.btn.btn-fab-material-teal-50,
.input-group-btn .btn.btn-fab-material-teal-50,
.btn.btn-fab:hover-material-teal-50,
.input-group-btn .btn.btn-fab:hover-material-teal-50,
.btn.btn-fab:active-material-teal-50,
.input-group-btn .btn.btn-fab:active-material-teal-50 {
  background-color: #e0f2f1;
}
.btn.btn-fab-material-teal-100,
.input-group-btn .btn.btn-fab-material-teal-100,
.btn.btn-fab:hover-material-teal-100,
.input-group-btn .btn.btn-fab:hover-material-teal-100,
.btn.btn-fab:active-material-teal-100,
.input-group-btn .btn.btn-fab:active-material-teal-100 {
  background-color: #b2dfdb;
}
.btn.btn-fab-material-teal-200,
.input-group-btn .btn.btn-fab-material-teal-200,
.btn.btn-fab:hover-material-teal-200,
.input-group-btn .btn.btn-fab:hover-material-teal-200,
.btn.btn-fab:active-material-teal-200,
.input-group-btn .btn.btn-fab:active-material-teal-200 {
  background-color: #80cbc4;
}
.btn.btn-fab-material-teal-300,
.input-group-btn .btn.btn-fab-material-teal-300,
.btn.btn-fab:hover-material-teal-300,
.input-group-btn .btn.btn-fab:hover-material-teal-300,
.btn.btn-fab:active-material-teal-300,
.input-group-btn .btn.btn-fab:active-material-teal-300 {
  background-color: #4db6ac;
}
.btn.btn-fab-material-teal-400,
.input-group-btn .btn.btn-fab-material-teal-400,
.btn.btn-fab:hover-material-teal-400,
.input-group-btn .btn.btn-fab:hover-material-teal-400,
.btn.btn-fab:active-material-teal-400,
.input-group-btn .btn.btn-fab:active-material-teal-400 {
  background-color: #26a69a;
}
.btn.btn-fab-material-teal-500,
.input-group-btn .btn.btn-fab-material-teal-500,
.btn.btn-fab:hover-material-teal-500,
.input-group-btn .btn.btn-fab:hover-material-teal-500,
.btn.btn-fab:active-material-teal-500,
.input-group-btn .btn.btn-fab:active-material-teal-500 {
  background-color: #009688;
}
.btn.btn-fab-material-teal-600,
.input-group-btn .btn.btn-fab-material-teal-600,
.btn.btn-fab:hover-material-teal-600,
.input-group-btn .btn.btn-fab:hover-material-teal-600,
.btn.btn-fab:active-material-teal-600,
.input-group-btn .btn.btn-fab:active-material-teal-600 {
  background-color: #00897b;
}
.btn.btn-fab-material-teal-700,
.input-group-btn .btn.btn-fab-material-teal-700,
.btn.btn-fab:hover-material-teal-700,
.input-group-btn .btn.btn-fab:hover-material-teal-700,
.btn.btn-fab:active-material-teal-700,
.input-group-btn .btn.btn-fab:active-material-teal-700 {
  background-color: #00796b;
}
.btn.btn-fab-material-teal-800,
.input-group-btn .btn.btn-fab-material-teal-800,
.btn.btn-fab:hover-material-teal-800,
.input-group-btn .btn.btn-fab:hover-material-teal-800,
.btn.btn-fab:active-material-teal-800,
.input-group-btn .btn.btn-fab:active-material-teal-800 {
  background-color: #00695c;
}
.btn.btn-fab-material-teal-900,
.input-group-btn .btn.btn-fab-material-teal-900,
.btn.btn-fab:hover-material-teal-900,
.input-group-btn .btn.btn-fab:hover-material-teal-900,
.btn.btn-fab:active-material-teal-900,
.input-group-btn .btn.btn-fab:active-material-teal-900 {
  background-color: #004d40;
}
.btn.btn-fab-material-teal-A100,
.input-group-btn .btn.btn-fab-material-teal-A100,
.btn.btn-fab:hover-material-teal-A100,
.input-group-btn .btn.btn-fab:hover-material-teal-A100,
.btn.btn-fab:active-material-teal-A100,
.input-group-btn .btn.btn-fab:active-material-teal-A100 {
  background-color: #a7ffeb;
}
.btn.btn-fab-material-teal-A200,
.input-group-btn .btn.btn-fab-material-teal-A200,
.btn.btn-fab:hover-material-teal-A200,
.input-group-btn .btn.btn-fab:hover-material-teal-A200,
.btn.btn-fab:active-material-teal-A200,
.input-group-btn .btn.btn-fab:active-material-teal-A200 {
  background-color: #64ffda;
}
.btn.btn-fab-material-teal-A400,
.input-group-btn .btn.btn-fab-material-teal-A400,
.btn.btn-fab:hover-material-teal-A400,
.input-group-btn .btn.btn-fab:hover-material-teal-A400,
.btn.btn-fab:active-material-teal-A400,
.input-group-btn .btn.btn-fab:active-material-teal-A400 {
  background-color: #1de9b6;
}
.btn.btn-fab-material-teal-A700,
.input-group-btn .btn.btn-fab-material-teal-A700,
.btn.btn-fab:hover-material-teal-A700,
.input-group-btn .btn.btn-fab:hover-material-teal-A700,
.btn.btn-fab:active-material-teal-A700,
.input-group-btn .btn.btn-fab:active-material-teal-A700 {
  background-color: #00bfa5;
}
.btn.btn-fab-material-green,
.input-group-btn .btn.btn-fab-material-green,
.btn.btn-fab:hover-material-green,
.input-group-btn .btn.btn-fab:hover-material-green,
.btn.btn-fab:active-material-green,
.input-group-btn .btn.btn-fab:active-material-green {
  background-color: #4caf50;
}
.btn.btn-fab-material-green-50,
.input-group-btn .btn.btn-fab-material-green-50,
.btn.btn-fab:hover-material-green-50,
.input-group-btn .btn.btn-fab:hover-material-green-50,
.btn.btn-fab:active-material-green-50,
.input-group-btn .btn.btn-fab:active-material-green-50 {
  background-color: #e8f5e9;
}
.btn.btn-fab-material-green-100,
.input-group-btn .btn.btn-fab-material-green-100,
.btn.btn-fab:hover-material-green-100,
.input-group-btn .btn.btn-fab:hover-material-green-100,
.btn.btn-fab:active-material-green-100,
.input-group-btn .btn.btn-fab:active-material-green-100 {
  background-color: #c8e6c9;
}
.btn.btn-fab-material-green-200,
.input-group-btn .btn.btn-fab-material-green-200,
.btn.btn-fab:hover-material-green-200,
.input-group-btn .btn.btn-fab:hover-material-green-200,
.btn.btn-fab:active-material-green-200,
.input-group-btn .btn.btn-fab:active-material-green-200 {
  background-color: #a5d6a7;
}
.btn.btn-fab-material-green-300,
.input-group-btn .btn.btn-fab-material-green-300,
.btn.btn-fab:hover-material-green-300,
.input-group-btn .btn.btn-fab:hover-material-green-300,
.btn.btn-fab:active-material-green-300,
.input-group-btn .btn.btn-fab:active-material-green-300 {
  background-color: #81c784;
}
.btn.btn-fab-material-green-400,
.input-group-btn .btn.btn-fab-material-green-400,
.btn.btn-fab:hover-material-green-400,
.input-group-btn .btn.btn-fab:hover-material-green-400,
.btn.btn-fab:active-material-green-400,
.input-group-btn .btn.btn-fab:active-material-green-400 {
  background-color: #66bb6a;
}
.btn.btn-fab-material-green-500,
.input-group-btn .btn.btn-fab-material-green-500,
.btn.btn-fab:hover-material-green-500,
.input-group-btn .btn.btn-fab:hover-material-green-500,
.btn.btn-fab:active-material-green-500,
.input-group-btn .btn.btn-fab:active-material-green-500 {
  background-color: #4caf50;
}
.btn.btn-fab-material-green-600,
.input-group-btn .btn.btn-fab-material-green-600,
.btn.btn-fab:hover-material-green-600,
.input-group-btn .btn.btn-fab:hover-material-green-600,
.btn.btn-fab:active-material-green-600,
.input-group-btn .btn.btn-fab:active-material-green-600 {
  background-color: #43a047;
}
.btn.btn-fab-material-green-700,
.input-group-btn .btn.btn-fab-material-green-700,
.btn.btn-fab:hover-material-green-700,
.input-group-btn .btn.btn-fab:hover-material-green-700,
.btn.btn-fab:active-material-green-700,
.input-group-btn .btn.btn-fab:active-material-green-700 {
  background-color: #388e3c;
}
.btn.btn-fab-material-green-800,
.input-group-btn .btn.btn-fab-material-green-800,
.btn.btn-fab:hover-material-green-800,
.input-group-btn .btn.btn-fab:hover-material-green-800,
.btn.btn-fab:active-material-green-800,
.input-group-btn .btn.btn-fab:active-material-green-800 {
  background-color: #2e7d32;
}
.btn.btn-fab-material-green-900,
.input-group-btn .btn.btn-fab-material-green-900,
.btn.btn-fab:hover-material-green-900,
.input-group-btn .btn.btn-fab:hover-material-green-900,
.btn.btn-fab:active-material-green-900,
.input-group-btn .btn.btn-fab:active-material-green-900 {
  background-color: #1b5e20;
}
.btn.btn-fab-material-green-A100,
.input-group-btn .btn.btn-fab-material-green-A100,
.btn.btn-fab:hover-material-green-A100,
.input-group-btn .btn.btn-fab:hover-material-green-A100,
.btn.btn-fab:active-material-green-A100,
.input-group-btn .btn.btn-fab:active-material-green-A100 {
  background-color: #b9f6ca;
}
.btn.btn-fab-material-green-A200,
.input-group-btn .btn.btn-fab-material-green-A200,
.btn.btn-fab:hover-material-green-A200,
.input-group-btn .btn.btn-fab:hover-material-green-A200,
.btn.btn-fab:active-material-green-A200,
.input-group-btn .btn.btn-fab:active-material-green-A200 {
  background-color: #69f0ae;
}
.btn.btn-fab-material-green-A400,
.input-group-btn .btn.btn-fab-material-green-A400,
.btn.btn-fab:hover-material-green-A400,
.input-group-btn .btn.btn-fab:hover-material-green-A400,
.btn.btn-fab:active-material-green-A400,
.input-group-btn .btn.btn-fab:active-material-green-A400 {
  background-color: #00e676;
}
.btn.btn-fab-material-green-A700,
.input-group-btn .btn.btn-fab-material-green-A700,
.btn.btn-fab:hover-material-green-A700,
.input-group-btn .btn.btn-fab:hover-material-green-A700,
.btn.btn-fab:active-material-green-A700,
.input-group-btn .btn.btn-fab:active-material-green-A700 {
  background-color: #00c853;
}
.btn.btn-fab-material-light-green,
.input-group-btn .btn.btn-fab-material-light-green,
.btn.btn-fab:hover-material-light-green,
.input-group-btn .btn.btn-fab:hover-material-light-green,
.btn.btn-fab:active-material-light-green,
.input-group-btn .btn.btn-fab:active-material-light-green {
  background-color: #8bc34a;
}
.btn.btn-fab-material-light-green-50,
.input-group-btn .btn.btn-fab-material-light-green-50,
.btn.btn-fab:hover-material-light-green-50,
.input-group-btn .btn.btn-fab:hover-material-light-green-50,
.btn.btn-fab:active-material-light-green-50,
.input-group-btn .btn.btn-fab:active-material-light-green-50 {
  background-color: #f1f8e9;
}
.btn.btn-fab-material-light-green-100,
.input-group-btn .btn.btn-fab-material-light-green-100,
.btn.btn-fab:hover-material-light-green-100,
.input-group-btn .btn.btn-fab:hover-material-light-green-100,
.btn.btn-fab:active-material-light-green-100,
.input-group-btn .btn.btn-fab:active-material-light-green-100 {
  background-color: #dcedc8;
}
.btn.btn-fab-material-light-green-200,
.input-group-btn .btn.btn-fab-material-light-green-200,
.btn.btn-fab:hover-material-light-green-200,
.input-group-btn .btn.btn-fab:hover-material-light-green-200,
.btn.btn-fab:active-material-light-green-200,
.input-group-btn .btn.btn-fab:active-material-light-green-200 {
  background-color: #c5e1a5;
}
.btn.btn-fab-material-light-green-300,
.input-group-btn .btn.btn-fab-material-light-green-300,
.btn.btn-fab:hover-material-light-green-300,
.input-group-btn .btn.btn-fab:hover-material-light-green-300,
.btn.btn-fab:active-material-light-green-300,
.input-group-btn .btn.btn-fab:active-material-light-green-300 {
  background-color: #aed581;
}
.btn.btn-fab-material-light-green-400,
.input-group-btn .btn.btn-fab-material-light-green-400,
.btn.btn-fab:hover-material-light-green-400,
.input-group-btn .btn.btn-fab:hover-material-light-green-400,
.btn.btn-fab:active-material-light-green-400,
.input-group-btn .btn.btn-fab:active-material-light-green-400 {
  background-color: #9ccc65;
}
.btn.btn-fab-material-light-green-500,
.input-group-btn .btn.btn-fab-material-light-green-500,
.btn.btn-fab:hover-material-light-green-500,
.input-group-btn .btn.btn-fab:hover-material-light-green-500,
.btn.btn-fab:active-material-light-green-500,
.input-group-btn .btn.btn-fab:active-material-light-green-500 {
  background-color: #8bc34a;
}
.btn.btn-fab-material-light-green-600,
.input-group-btn .btn.btn-fab-material-light-green-600,
.btn.btn-fab:hover-material-light-green-600,
.input-group-btn .btn.btn-fab:hover-material-light-green-600,
.btn.btn-fab:active-material-light-green-600,
.input-group-btn .btn.btn-fab:active-material-light-green-600 {
  background-color: #7cb342;
}
.btn.btn-fab-material-light-green-700,
.input-group-btn .btn.btn-fab-material-light-green-700,
.btn.btn-fab:hover-material-light-green-700,
.input-group-btn .btn.btn-fab:hover-material-light-green-700,
.btn.btn-fab:active-material-light-green-700,
.input-group-btn .btn.btn-fab:active-material-light-green-700 {
  background-color: #689f38;
}
.btn.btn-fab-material-light-green-800,
.input-group-btn .btn.btn-fab-material-light-green-800,
.btn.btn-fab:hover-material-light-green-800,
.input-group-btn .btn.btn-fab:hover-material-light-green-800,
.btn.btn-fab:active-material-light-green-800,
.input-group-btn .btn.btn-fab:active-material-light-green-800 {
  background-color: #558b2f;
}
.btn.btn-fab-material-light-green-900,
.input-group-btn .btn.btn-fab-material-light-green-900,
.btn.btn-fab:hover-material-light-green-900,
.input-group-btn .btn.btn-fab:hover-material-light-green-900,
.btn.btn-fab:active-material-light-green-900,
.input-group-btn .btn.btn-fab:active-material-light-green-900 {
  background-color: #33691e;
}
.btn.btn-fab-material-light-green-A100,
.input-group-btn .btn.btn-fab-material-light-green-A100,
.btn.btn-fab:hover-material-light-green-A100,
.input-group-btn .btn.btn-fab:hover-material-light-green-A100,
.btn.btn-fab:active-material-light-green-A100,
.input-group-btn .btn.btn-fab:active-material-light-green-A100 {
  background-color: #ccff90;
}
.btn.btn-fab-material-light-green-A200,
.input-group-btn .btn.btn-fab-material-light-green-A200,
.btn.btn-fab:hover-material-light-green-A200,
.input-group-btn .btn.btn-fab:hover-material-light-green-A200,
.btn.btn-fab:active-material-light-green-A200,
.input-group-btn .btn.btn-fab:active-material-light-green-A200 {
  background-color: #b2ff59;
}
.btn.btn-fab-material-light-green-A400,
.input-group-btn .btn.btn-fab-material-light-green-A400,
.btn.btn-fab:hover-material-light-green-A400,
.input-group-btn .btn.btn-fab:hover-material-light-green-A400,
.btn.btn-fab:active-material-light-green-A400,
.input-group-btn .btn.btn-fab:active-material-light-green-A400 {
  background-color: #76ff03;
}
.btn.btn-fab-material-light-green-A700,
.input-group-btn .btn.btn-fab-material-light-green-A700,
.btn.btn-fab:hover-material-light-green-A700,
.input-group-btn .btn.btn-fab:hover-material-light-green-A700,
.btn.btn-fab:active-material-light-green-A700,
.input-group-btn .btn.btn-fab:active-material-light-green-A700 {
  background-color: #64dd17;
}
.btn.btn-fab-material-lime,
.input-group-btn .btn.btn-fab-material-lime,
.btn.btn-fab:hover-material-lime,
.input-group-btn .btn.btn-fab:hover-material-lime,
.btn.btn-fab:active-material-lime,
.input-group-btn .btn.btn-fab:active-material-lime {
  background-color: #cddc39;
}
.btn.btn-fab-material-lime-50,
.input-group-btn .btn.btn-fab-material-lime-50,
.btn.btn-fab:hover-material-lime-50,
.input-group-btn .btn.btn-fab:hover-material-lime-50,
.btn.btn-fab:active-material-lime-50,
.input-group-btn .btn.btn-fab:active-material-lime-50 {
  background-color: #f9fbe7;
}
.btn.btn-fab-material-lime-100,
.input-group-btn .btn.btn-fab-material-lime-100,
.btn.btn-fab:hover-material-lime-100,
.input-group-btn .btn.btn-fab:hover-material-lime-100,
.btn.btn-fab:active-material-lime-100,
.input-group-btn .btn.btn-fab:active-material-lime-100 {
  background-color: #f0f4c3;
}
.btn.btn-fab-material-lime-200,
.input-group-btn .btn.btn-fab-material-lime-200,
.btn.btn-fab:hover-material-lime-200,
.input-group-btn .btn.btn-fab:hover-material-lime-200,
.btn.btn-fab:active-material-lime-200,
.input-group-btn .btn.btn-fab:active-material-lime-200 {
  background-color: #e6ee9c;
}
.btn.btn-fab-material-lime-300,
.input-group-btn .btn.btn-fab-material-lime-300,
.btn.btn-fab:hover-material-lime-300,
.input-group-btn .btn.btn-fab:hover-material-lime-300,
.btn.btn-fab:active-material-lime-300,
.input-group-btn .btn.btn-fab:active-material-lime-300 {
  background-color: #dce775;
}
.btn.btn-fab-material-lime-400,
.input-group-btn .btn.btn-fab-material-lime-400,
.btn.btn-fab:hover-material-lime-400,
.input-group-btn .btn.btn-fab:hover-material-lime-400,
.btn.btn-fab:active-material-lime-400,
.input-group-btn .btn.btn-fab:active-material-lime-400 {
  background-color: #d4e157;
}
.btn.btn-fab-material-lime-500,
.input-group-btn .btn.btn-fab-material-lime-500,
.btn.btn-fab:hover-material-lime-500,
.input-group-btn .btn.btn-fab:hover-material-lime-500,
.btn.btn-fab:active-material-lime-500,
.input-group-btn .btn.btn-fab:active-material-lime-500 {
  background-color: #cddc39;
}
.btn.btn-fab-material-lime-600,
.input-group-btn .btn.btn-fab-material-lime-600,
.btn.btn-fab:hover-material-lime-600,
.input-group-btn .btn.btn-fab:hover-material-lime-600,
.btn.btn-fab:active-material-lime-600,
.input-group-btn .btn.btn-fab:active-material-lime-600 {
  background-color: #c0ca33;
}
.btn.btn-fab-material-lime-700,
.input-group-btn .btn.btn-fab-material-lime-700,
.btn.btn-fab:hover-material-lime-700,
.input-group-btn .btn.btn-fab:hover-material-lime-700,
.btn.btn-fab:active-material-lime-700,
.input-group-btn .btn.btn-fab:active-material-lime-700 {
  background-color: #afb42b;
}
.btn.btn-fab-material-lime-800,
.input-group-btn .btn.btn-fab-material-lime-800,
.btn.btn-fab:hover-material-lime-800,
.input-group-btn .btn.btn-fab:hover-material-lime-800,
.btn.btn-fab:active-material-lime-800,
.input-group-btn .btn.btn-fab:active-material-lime-800 {
  background-color: #9e9d24;
}
.btn.btn-fab-material-lime-900,
.input-group-btn .btn.btn-fab-material-lime-900,
.btn.btn-fab:hover-material-lime-900,
.input-group-btn .btn.btn-fab:hover-material-lime-900,
.btn.btn-fab:active-material-lime-900,
.input-group-btn .btn.btn-fab:active-material-lime-900 {
  background-color: #827717;
}
.btn.btn-fab-material-lime-A100,
.input-group-btn .btn.btn-fab-material-lime-A100,
.btn.btn-fab:hover-material-lime-A100,
.input-group-btn .btn.btn-fab:hover-material-lime-A100,
.btn.btn-fab:active-material-lime-A100,
.input-group-btn .btn.btn-fab:active-material-lime-A100 {
  background-color: #f4ff81;
}
.btn.btn-fab-material-lime-A200,
.input-group-btn .btn.btn-fab-material-lime-A200,
.btn.btn-fab:hover-material-lime-A200,
.input-group-btn .btn.btn-fab:hover-material-lime-A200,
.btn.btn-fab:active-material-lime-A200,
.input-group-btn .btn.btn-fab:active-material-lime-A200 {
  background-color: #eeff41;
}
.btn.btn-fab-material-lime-A400,
.input-group-btn .btn.btn-fab-material-lime-A400,
.btn.btn-fab:hover-material-lime-A400,
.input-group-btn .btn.btn-fab:hover-material-lime-A400,
.btn.btn-fab:active-material-lime-A400,
.input-group-btn .btn.btn-fab:active-material-lime-A400 {
  background-color: #c6ff00;
}
.btn.btn-fab-material-lime-A700,
.input-group-btn .btn.btn-fab-material-lime-A700,
.btn.btn-fab:hover-material-lime-A700,
.input-group-btn .btn.btn-fab:hover-material-lime-A700,
.btn.btn-fab:active-material-lime-A700,
.input-group-btn .btn.btn-fab:active-material-lime-A700 {
  background-color: #aeea00;
}
.btn.btn-fab-material-yellow,
.input-group-btn .btn.btn-fab-material-yellow,
.btn.btn-fab:hover-material-yellow,
.input-group-btn .btn.btn-fab:hover-material-yellow,
.btn.btn-fab:active-material-yellow,
.input-group-btn .btn.btn-fab:active-material-yellow {
  background-color: #ffeb3b;
}
.btn.btn-fab-material-yellow-50,
.input-group-btn .btn.btn-fab-material-yellow-50,
.btn.btn-fab:hover-material-yellow-50,
.input-group-btn .btn.btn-fab:hover-material-yellow-50,
.btn.btn-fab:active-material-yellow-50,
.input-group-btn .btn.btn-fab:active-material-yellow-50 {
  background-color: #fffde7;
}
.btn.btn-fab-material-yellow-100,
.input-group-btn .btn.btn-fab-material-yellow-100,
.btn.btn-fab:hover-material-yellow-100,
.input-group-btn .btn.btn-fab:hover-material-yellow-100,
.btn.btn-fab:active-material-yellow-100,
.input-group-btn .btn.btn-fab:active-material-yellow-100 {
  background-color: #fff9c4;
}
.btn.btn-fab-material-yellow-200,
.input-group-btn .btn.btn-fab-material-yellow-200,
.btn.btn-fab:hover-material-yellow-200,
.input-group-btn .btn.btn-fab:hover-material-yellow-200,
.btn.btn-fab:active-material-yellow-200,
.input-group-btn .btn.btn-fab:active-material-yellow-200 {
  background-color: #fff59d;
}
.btn.btn-fab-material-yellow-300,
.input-group-btn .btn.btn-fab-material-yellow-300,
.btn.btn-fab:hover-material-yellow-300,
.input-group-btn .btn.btn-fab:hover-material-yellow-300,
.btn.btn-fab:active-material-yellow-300,
.input-group-btn .btn.btn-fab:active-material-yellow-300 {
  background-color: #fff176;
}
.btn.btn-fab-material-yellow-400,
.input-group-btn .btn.btn-fab-material-yellow-400,
.btn.btn-fab:hover-material-yellow-400,
.input-group-btn .btn.btn-fab:hover-material-yellow-400,
.btn.btn-fab:active-material-yellow-400,
.input-group-btn .btn.btn-fab:active-material-yellow-400 {
  background-color: #ffee58;
}
.btn.btn-fab-material-yellow-500,
.input-group-btn .btn.btn-fab-material-yellow-500,
.btn.btn-fab:hover-material-yellow-500,
.input-group-btn .btn.btn-fab:hover-material-yellow-500,
.btn.btn-fab:active-material-yellow-500,
.input-group-btn .btn.btn-fab:active-material-yellow-500 {
  background-color: #ffeb3b;
}
.btn.btn-fab-material-yellow-600,
.input-group-btn .btn.btn-fab-material-yellow-600,
.btn.btn-fab:hover-material-yellow-600,
.input-group-btn .btn.btn-fab:hover-material-yellow-600,
.btn.btn-fab:active-material-yellow-600,
.input-group-btn .btn.btn-fab:active-material-yellow-600 {
  background-color: #fdd835;
}
.btn.btn-fab-material-yellow-700,
.input-group-btn .btn.btn-fab-material-yellow-700,
.btn.btn-fab:hover-material-yellow-700,
.input-group-btn .btn.btn-fab:hover-material-yellow-700,
.btn.btn-fab:active-material-yellow-700,
.input-group-btn .btn.btn-fab:active-material-yellow-700 {
  background-color: #fbc02d;
}
.btn.btn-fab-material-yellow-800,
.input-group-btn .btn.btn-fab-material-yellow-800,
.btn.btn-fab:hover-material-yellow-800,
.input-group-btn .btn.btn-fab:hover-material-yellow-800,
.btn.btn-fab:active-material-yellow-800,
.input-group-btn .btn.btn-fab:active-material-yellow-800 {
  background-color: #f9a825;
}
.btn.btn-fab-material-yellow-900,
.input-group-btn .btn.btn-fab-material-yellow-900,
.btn.btn-fab:hover-material-yellow-900,
.input-group-btn .btn.btn-fab:hover-material-yellow-900,
.btn.btn-fab:active-material-yellow-900,
.input-group-btn .btn.btn-fab:active-material-yellow-900 {
  background-color: #f57f17;
}
.btn.btn-fab-material-yellow-A100,
.input-group-btn .btn.btn-fab-material-yellow-A100,
.btn.btn-fab:hover-material-yellow-A100,
.input-group-btn .btn.btn-fab:hover-material-yellow-A100,
.btn.btn-fab:active-material-yellow-A100,
.input-group-btn .btn.btn-fab:active-material-yellow-A100 {
  background-color: #ffff8d;
}
.btn.btn-fab-material-yellow-A200,
.input-group-btn .btn.btn-fab-material-yellow-A200,
.btn.btn-fab:hover-material-yellow-A200,
.input-group-btn .btn.btn-fab:hover-material-yellow-A200,
.btn.btn-fab:active-material-yellow-A200,
.input-group-btn .btn.btn-fab:active-material-yellow-A200 {
  background-color: #ffff00;
}
.btn.btn-fab-material-yellow-A400,
.input-group-btn .btn.btn-fab-material-yellow-A400,
.btn.btn-fab:hover-material-yellow-A400,
.input-group-btn .btn.btn-fab:hover-material-yellow-A400,
.btn.btn-fab:active-material-yellow-A400,
.input-group-btn .btn.btn-fab:active-material-yellow-A400 {
  background-color: #ffea00;
}
.btn.btn-fab-material-yellow-A700,
.input-group-btn .btn.btn-fab-material-yellow-A700,
.btn.btn-fab:hover-material-yellow-A700,
.input-group-btn .btn.btn-fab:hover-material-yellow-A700,
.btn.btn-fab:active-material-yellow-A700,
.input-group-btn .btn.btn-fab:active-material-yellow-A700 {
  background-color: #ffd600;
}
.btn.btn-fab-material-amber,
.input-group-btn .btn.btn-fab-material-amber,
.btn.btn-fab:hover-material-amber,
.input-group-btn .btn.btn-fab:hover-material-amber,
.btn.btn-fab:active-material-amber,
.input-group-btn .btn.btn-fab:active-material-amber {
  background-color: #ffc107;
}
.btn.btn-fab-material-amber-50,
.input-group-btn .btn.btn-fab-material-amber-50,
.btn.btn-fab:hover-material-amber-50,
.input-group-btn .btn.btn-fab:hover-material-amber-50,
.btn.btn-fab:active-material-amber-50,
.input-group-btn .btn.btn-fab:active-material-amber-50 {
  background-color: #fff8e1;
}
.btn.btn-fab-material-amber-100,
.input-group-btn .btn.btn-fab-material-amber-100,
.btn.btn-fab:hover-material-amber-100,
.input-group-btn .btn.btn-fab:hover-material-amber-100,
.btn.btn-fab:active-material-amber-100,
.input-group-btn .btn.btn-fab:active-material-amber-100 {
  background-color: #ffecb3;
}
.btn.btn-fab-material-amber-200,
.input-group-btn .btn.btn-fab-material-amber-200,
.btn.btn-fab:hover-material-amber-200,
.input-group-btn .btn.btn-fab:hover-material-amber-200,
.btn.btn-fab:active-material-amber-200,
.input-group-btn .btn.btn-fab:active-material-amber-200 {
  background-color: #ffe082;
}
.btn.btn-fab-material-amber-300,
.input-group-btn .btn.btn-fab-material-amber-300,
.btn.btn-fab:hover-material-amber-300,
.input-group-btn .btn.btn-fab:hover-material-amber-300,
.btn.btn-fab:active-material-amber-300,
.input-group-btn .btn.btn-fab:active-material-amber-300 {
  background-color: #ffd54f;
}
.btn.btn-fab-material-amber-400,
.input-group-btn .btn.btn-fab-material-amber-400,
.btn.btn-fab:hover-material-amber-400,
.input-group-btn .btn.btn-fab:hover-material-amber-400,
.btn.btn-fab:active-material-amber-400,
.input-group-btn .btn.btn-fab:active-material-amber-400 {
  background-color: #ffca28;
}
.btn.btn-fab-material-amber-500,
.input-group-btn .btn.btn-fab-material-amber-500,
.btn.btn-fab:hover-material-amber-500,
.input-group-btn .btn.btn-fab:hover-material-amber-500,
.btn.btn-fab:active-material-amber-500,
.input-group-btn .btn.btn-fab:active-material-amber-500 {
  background-color: #ffc107;
}
.btn.btn-fab-material-amber-600,
.input-group-btn .btn.btn-fab-material-amber-600,
.btn.btn-fab:hover-material-amber-600,
.input-group-btn .btn.btn-fab:hover-material-amber-600,
.btn.btn-fab:active-material-amber-600,
.input-group-btn .btn.btn-fab:active-material-amber-600 {
  background-color: #ffb300;
}
.btn.btn-fab-material-amber-700,
.input-group-btn .btn.btn-fab-material-amber-700,
.btn.btn-fab:hover-material-amber-700,
.input-group-btn .btn.btn-fab:hover-material-amber-700,
.btn.btn-fab:active-material-amber-700,
.input-group-btn .btn.btn-fab:active-material-amber-700 {
  background-color: #ffa000;
}
.btn.btn-fab-material-amber-800,
.input-group-btn .btn.btn-fab-material-amber-800,
.btn.btn-fab:hover-material-amber-800,
.input-group-btn .btn.btn-fab:hover-material-amber-800,
.btn.btn-fab:active-material-amber-800,
.input-group-btn .btn.btn-fab:active-material-amber-800 {
  background-color: #ff8f00;
}
.btn.btn-fab-material-amber-900,
.input-group-btn .btn.btn-fab-material-amber-900,
.btn.btn-fab:hover-material-amber-900,
.input-group-btn .btn.btn-fab:hover-material-amber-900,
.btn.btn-fab:active-material-amber-900,
.input-group-btn .btn.btn-fab:active-material-amber-900 {
  background-color: #ff6f00;
}
.btn.btn-fab-material-amber-A100,
.input-group-btn .btn.btn-fab-material-amber-A100,
.btn.btn-fab:hover-material-amber-A100,
.input-group-btn .btn.btn-fab:hover-material-amber-A100,
.btn.btn-fab:active-material-amber-A100,
.input-group-btn .btn.btn-fab:active-material-amber-A100 {
  background-color: #ffe57f;
}
.btn.btn-fab-material-amber-A200,
.input-group-btn .btn.btn-fab-material-amber-A200,
.btn.btn-fab:hover-material-amber-A200,
.input-group-btn .btn.btn-fab:hover-material-amber-A200,
.btn.btn-fab:active-material-amber-A200,
.input-group-btn .btn.btn-fab:active-material-amber-A200 {
  background-color: #ffd740;
}
.btn.btn-fab-material-amber-A400,
.input-group-btn .btn.btn-fab-material-amber-A400,
.btn.btn-fab:hover-material-amber-A400,
.input-group-btn .btn.btn-fab:hover-material-amber-A400,
.btn.btn-fab:active-material-amber-A400,
.input-group-btn .btn.btn-fab:active-material-amber-A400 {
  background-color: #ffc400;
}
.btn.btn-fab-material-amber-A700,
.input-group-btn .btn.btn-fab-material-amber-A700,
.btn.btn-fab:hover-material-amber-A700,
.input-group-btn .btn.btn-fab:hover-material-amber-A700,
.btn.btn-fab:active-material-amber-A700,
.input-group-btn .btn.btn-fab:active-material-amber-A700 {
  background-color: #ffab00;
}
.btn.btn-fab-material-orange,
.input-group-btn .btn.btn-fab-material-orange,
.btn.btn-fab:hover-material-orange,
.input-group-btn .btn.btn-fab:hover-material-orange,
.btn.btn-fab:active-material-orange,
.input-group-btn .btn.btn-fab:active-material-orange {
  background-color: #ff9800;
}
.btn.btn-fab-material-orange-50,
.input-group-btn .btn.btn-fab-material-orange-50,
.btn.btn-fab:hover-material-orange-50,
.input-group-btn .btn.btn-fab:hover-material-orange-50,
.btn.btn-fab:active-material-orange-50,
.input-group-btn .btn.btn-fab:active-material-orange-50 {
  background-color: #fff3e0;
}
.btn.btn-fab-material-orange-100,
.input-group-btn .btn.btn-fab-material-orange-100,
.btn.btn-fab:hover-material-orange-100,
.input-group-btn .btn.btn-fab:hover-material-orange-100,
.btn.btn-fab:active-material-orange-100,
.input-group-btn .btn.btn-fab:active-material-orange-100 {
  background-color: #ffe0b2;
}
.btn.btn-fab-material-orange-200,
.input-group-btn .btn.btn-fab-material-orange-200,
.btn.btn-fab:hover-material-orange-200,
.input-group-btn .btn.btn-fab:hover-material-orange-200,
.btn.btn-fab:active-material-orange-200,
.input-group-btn .btn.btn-fab:active-material-orange-200 {
  background-color: #ffcc80;
}
.btn.btn-fab-material-orange-300,
.input-group-btn .btn.btn-fab-material-orange-300,
.btn.btn-fab:hover-material-orange-300,
.input-group-btn .btn.btn-fab:hover-material-orange-300,
.btn.btn-fab:active-material-orange-300,
.input-group-btn .btn.btn-fab:active-material-orange-300 {
  background-color: #ffb74d;
}
.btn.btn-fab-material-orange-400,
.input-group-btn .btn.btn-fab-material-orange-400,
.btn.btn-fab:hover-material-orange-400,
.input-group-btn .btn.btn-fab:hover-material-orange-400,
.btn.btn-fab:active-material-orange-400,
.input-group-btn .btn.btn-fab:active-material-orange-400 {
  background-color: #ffa726;
}
.btn.btn-fab-material-orange-500,
.input-group-btn .btn.btn-fab-material-orange-500,
.btn.btn-fab:hover-material-orange-500,
.input-group-btn .btn.btn-fab:hover-material-orange-500,
.btn.btn-fab:active-material-orange-500,
.input-group-btn .btn.btn-fab:active-material-orange-500 {
  background-color: #ff9800;
}
.btn.btn-fab-material-orange-600,
.input-group-btn .btn.btn-fab-material-orange-600,
.btn.btn-fab:hover-material-orange-600,
.input-group-btn .btn.btn-fab:hover-material-orange-600,
.btn.btn-fab:active-material-orange-600,
.input-group-btn .btn.btn-fab:active-material-orange-600 {
  background-color: #fb8c00;
}
.btn.btn-fab-material-orange-700,
.input-group-btn .btn.btn-fab-material-orange-700,
.btn.btn-fab:hover-material-orange-700,
.input-group-btn .btn.btn-fab:hover-material-orange-700,
.btn.btn-fab:active-material-orange-700,
.input-group-btn .btn.btn-fab:active-material-orange-700 {
  background-color: #f57c00;
}
.btn.btn-fab-material-orange-800,
.input-group-btn .btn.btn-fab-material-orange-800,
.btn.btn-fab:hover-material-orange-800,
.input-group-btn .btn.btn-fab:hover-material-orange-800,
.btn.btn-fab:active-material-orange-800,
.input-group-btn .btn.btn-fab:active-material-orange-800 {
  background-color: #ef6c00;
}
.btn.btn-fab-material-orange-900,
.input-group-btn .btn.btn-fab-material-orange-900,
.btn.btn-fab:hover-material-orange-900,
.input-group-btn .btn.btn-fab:hover-material-orange-900,
.btn.btn-fab:active-material-orange-900,
.input-group-btn .btn.btn-fab:active-material-orange-900 {
  background-color: #e65100;
}
.btn.btn-fab-material-orange-A100,
.input-group-btn .btn.btn-fab-material-orange-A100,
.btn.btn-fab:hover-material-orange-A100,
.input-group-btn .btn.btn-fab:hover-material-orange-A100,
.btn.btn-fab:active-material-orange-A100,
.input-group-btn .btn.btn-fab:active-material-orange-A100 {
  background-color: #ffd180;
}
.btn.btn-fab-material-orange-A200,
.input-group-btn .btn.btn-fab-material-orange-A200,
.btn.btn-fab:hover-material-orange-A200,
.input-group-btn .btn.btn-fab:hover-material-orange-A200,
.btn.btn-fab:active-material-orange-A200,
.input-group-btn .btn.btn-fab:active-material-orange-A200 {
  background-color: #ffab40;
}
.btn.btn-fab-material-orange-A400,
.input-group-btn .btn.btn-fab-material-orange-A400,
.btn.btn-fab:hover-material-orange-A400,
.input-group-btn .btn.btn-fab:hover-material-orange-A400,
.btn.btn-fab:active-material-orange-A400,
.input-group-btn .btn.btn-fab:active-material-orange-A400 {
  background-color: #ff9100;
}
.btn.btn-fab-material-orange-A700,
.input-group-btn .btn.btn-fab-material-orange-A700,
.btn.btn-fab:hover-material-orange-A700,
.input-group-btn .btn.btn-fab:hover-material-orange-A700,
.btn.btn-fab:active-material-orange-A700,
.input-group-btn .btn.btn-fab:active-material-orange-A700 {
  background-color: #ff6d00;
}
.btn.btn-fab-material-deep-orange,
.input-group-btn .btn.btn-fab-material-deep-orange,
.btn.btn-fab:hover-material-deep-orange,
.input-group-btn .btn.btn-fab:hover-material-deep-orange,
.btn.btn-fab:active-material-deep-orange,
.input-group-btn .btn.btn-fab:active-material-deep-orange {
  background-color: #ff5722;
}
.btn.btn-fab-material-deep-orange-50,
.input-group-btn .btn.btn-fab-material-deep-orange-50,
.btn.btn-fab:hover-material-deep-orange-50,
.input-group-btn .btn.btn-fab:hover-material-deep-orange-50,
.btn.btn-fab:active-material-deep-orange-50,
.input-group-btn .btn.btn-fab:active-material-deep-orange-50 {
  background-color: #fbe9e7;
}
.btn.btn-fab-material-deep-orange-100,
.input-group-btn .btn.btn-fab-material-deep-orange-100,
.btn.btn-fab:hover-material-deep-orange-100,
.input-group-btn .btn.btn-fab:hover-material-deep-orange-100,
.btn.btn-fab:active-material-deep-orange-100,
.input-group-btn .btn.btn-fab:active-material-deep-orange-100 {
  background-color: #ffccbc;
}
.btn.btn-fab-material-deep-orange-200,
.input-group-btn .btn.btn-fab-material-deep-orange-200,
.btn.btn-fab:hover-material-deep-orange-200,
.input-group-btn .btn.btn-fab:hover-material-deep-orange-200,
.btn.btn-fab:active-material-deep-orange-200,
.input-group-btn .btn.btn-fab:active-material-deep-orange-200 {
  background-color: #ffab91;
}
.btn.btn-fab-material-deep-orange-300,
.input-group-btn .btn.btn-fab-material-deep-orange-300,
.btn.btn-fab:hover-material-deep-orange-300,
.input-group-btn .btn.btn-fab:hover-material-deep-orange-300,
.btn.btn-fab:active-material-deep-orange-300,
.input-group-btn .btn.btn-fab:active-material-deep-orange-300 {
  background-color: #ff8a65;
}
.btn.btn-fab-material-deep-orange-400,
.input-group-btn .btn.btn-fab-material-deep-orange-400,
.btn.btn-fab:hover-material-deep-orange-400,
.input-group-btn .btn.btn-fab:hover-material-deep-orange-400,
.btn.btn-fab:active-material-deep-orange-400,
.input-group-btn .btn.btn-fab:active-material-deep-orange-400 {
  background-color: #ff7043;
}
.btn.btn-fab-material-deep-orange-500,
.input-group-btn .btn.btn-fab-material-deep-orange-500,
.btn.btn-fab:hover-material-deep-orange-500,
.input-group-btn .btn.btn-fab:hover-material-deep-orange-500,
.btn.btn-fab:active-material-deep-orange-500,
.input-group-btn .btn.btn-fab:active-material-deep-orange-500 {
  background-color: #ff5722;
}
.btn.btn-fab-material-deep-orange-600,
.input-group-btn .btn.btn-fab-material-deep-orange-600,
.btn.btn-fab:hover-material-deep-orange-600,
.input-group-btn .btn.btn-fab:hover-material-deep-orange-600,
.btn.btn-fab:active-material-deep-orange-600,
.input-group-btn .btn.btn-fab:active-material-deep-orange-600 {
  background-color: #f4511e;
}
.btn.btn-fab-material-deep-orange-700,
.input-group-btn .btn.btn-fab-material-deep-orange-700,
.btn.btn-fab:hover-material-deep-orange-700,
.input-group-btn .btn.btn-fab:hover-material-deep-orange-700,
.btn.btn-fab:active-material-deep-orange-700,
.input-group-btn .btn.btn-fab:active-material-deep-orange-700 {
  background-color: #e64a19;
}
.btn.btn-fab-material-deep-orange-800,
.input-group-btn .btn.btn-fab-material-deep-orange-800,
.btn.btn-fab:hover-material-deep-orange-800,
.input-group-btn .btn.btn-fab:hover-material-deep-orange-800,
.btn.btn-fab:active-material-deep-orange-800,
.input-group-btn .btn.btn-fab:active-material-deep-orange-800 {
  background-color: #d84315;
}
.btn.btn-fab-material-deep-orange-900,
.input-group-btn .btn.btn-fab-material-deep-orange-900,
.btn.btn-fab:hover-material-deep-orange-900,
.input-group-btn .btn.btn-fab:hover-material-deep-orange-900,
.btn.btn-fab:active-material-deep-orange-900,
.input-group-btn .btn.btn-fab:active-material-deep-orange-900 {
  background-color: #bf360c;
}
.btn.btn-fab-material-deep-orange-A100,
.input-group-btn .btn.btn-fab-material-deep-orange-A100,
.btn.btn-fab:hover-material-deep-orange-A100,
.input-group-btn .btn.btn-fab:hover-material-deep-orange-A100,
.btn.btn-fab:active-material-deep-orange-A100,
.input-group-btn .btn.btn-fab:active-material-deep-orange-A100 {
  background-color: #ff9e80;
}
.btn.btn-fab-material-deep-orange-A200,
.input-group-btn .btn.btn-fab-material-deep-orange-A200,
.btn.btn-fab:hover-material-deep-orange-A200,
.input-group-btn .btn.btn-fab:hover-material-deep-orange-A200,
.btn.btn-fab:active-material-deep-orange-A200,
.input-group-btn .btn.btn-fab:active-material-deep-orange-A200 {
  background-color: #ff6e40;
}
.btn.btn-fab-material-deep-orange-A400,
.input-group-btn .btn.btn-fab-material-deep-orange-A400,
.btn.btn-fab:hover-material-deep-orange-A400,
.input-group-btn .btn.btn-fab:hover-material-deep-orange-A400,
.btn.btn-fab:active-material-deep-orange-A400,
.input-group-btn .btn.btn-fab:active-material-deep-orange-A400 {
  background-color: #ff3d00;
}
.btn.btn-fab-material-deep-orange-A700,
.input-group-btn .btn.btn-fab-material-deep-orange-A700,
.btn.btn-fab:hover-material-deep-orange-A700,
.input-group-btn .btn.btn-fab:hover-material-deep-orange-A700,
.btn.btn-fab:active-material-deep-orange-A700,
.input-group-btn .btn.btn-fab:active-material-deep-orange-A700 {
  background-color: #dd2c00;
}
.btn.btn-fab-material-brown,
.input-group-btn .btn.btn-fab-material-brown,
.btn.btn-fab:hover-material-brown,
.input-group-btn .btn.btn-fab:hover-material-brown,
.btn.btn-fab:active-material-brown,
.input-group-btn .btn.btn-fab:active-material-brown {
  background-color: #795548;
}
.btn.btn-fab-material-brown-50,
.input-group-btn .btn.btn-fab-material-brown-50,
.btn.btn-fab:hover-material-brown-50,
.input-group-btn .btn.btn-fab:hover-material-brown-50,
.btn.btn-fab:active-material-brown-50,
.input-group-btn .btn.btn-fab:active-material-brown-50 {
  background-color: #efebe9;
}
.btn.btn-fab-material-brown-100,
.input-group-btn .btn.btn-fab-material-brown-100,
.btn.btn-fab:hover-material-brown-100,
.input-group-btn .btn.btn-fab:hover-material-brown-100,
.btn.btn-fab:active-material-brown-100,
.input-group-btn .btn.btn-fab:active-material-brown-100 {
  background-color: #d7ccc8;
}
.btn.btn-fab-material-brown-200,
.input-group-btn .btn.btn-fab-material-brown-200,
.btn.btn-fab:hover-material-brown-200,
.input-group-btn .btn.btn-fab:hover-material-brown-200,
.btn.btn-fab:active-material-brown-200,
.input-group-btn .btn.btn-fab:active-material-brown-200 {
  background-color: #bcaaa4;
}
.btn.btn-fab-material-brown-300,
.input-group-btn .btn.btn-fab-material-brown-300,
.btn.btn-fab:hover-material-brown-300,
.input-group-btn .btn.btn-fab:hover-material-brown-300,
.btn.btn-fab:active-material-brown-300,
.input-group-btn .btn.btn-fab:active-material-brown-300 {
  background-color: #a1887f;
}
.btn.btn-fab-material-brown-400,
.input-group-btn .btn.btn-fab-material-brown-400,
.btn.btn-fab:hover-material-brown-400,
.input-group-btn .btn.btn-fab:hover-material-brown-400,
.btn.btn-fab:active-material-brown-400,
.input-group-btn .btn.btn-fab:active-material-brown-400 {
  background-color: #8d6e63;
}
.btn.btn-fab-material-brown-500,
.input-group-btn .btn.btn-fab-material-brown-500,
.btn.btn-fab:hover-material-brown-500,
.input-group-btn .btn.btn-fab:hover-material-brown-500,
.btn.btn-fab:active-material-brown-500,
.input-group-btn .btn.btn-fab:active-material-brown-500 {
  background-color: #795548;
}
.btn.btn-fab-material-brown-600,
.input-group-btn .btn.btn-fab-material-brown-600,
.btn.btn-fab:hover-material-brown-600,
.input-group-btn .btn.btn-fab:hover-material-brown-600,
.btn.btn-fab:active-material-brown-600,
.input-group-btn .btn.btn-fab:active-material-brown-600 {
  background-color: #6d4c41;
}
.btn.btn-fab-material-brown-700,
.input-group-btn .btn.btn-fab-material-brown-700,
.btn.btn-fab:hover-material-brown-700,
.input-group-btn .btn.btn-fab:hover-material-brown-700,
.btn.btn-fab:active-material-brown-700,
.input-group-btn .btn.btn-fab:active-material-brown-700 {
  background-color: #5d4037;
}
.btn.btn-fab-material-brown-800,
.input-group-btn .btn.btn-fab-material-brown-800,
.btn.btn-fab:hover-material-brown-800,
.input-group-btn .btn.btn-fab:hover-material-brown-800,
.btn.btn-fab:active-material-brown-800,
.input-group-btn .btn.btn-fab:active-material-brown-800 {
  background-color: #4e342e;
}
.btn.btn-fab-material-brown-900,
.input-group-btn .btn.btn-fab-material-brown-900,
.btn.btn-fab:hover-material-brown-900,
.input-group-btn .btn.btn-fab:hover-material-brown-900,
.btn.btn-fab:active-material-brown-900,
.input-group-btn .btn.btn-fab:active-material-brown-900 {
  background-color: #3e2723;
}
.btn.btn-fab-material-brown-A100,
.input-group-btn .btn.btn-fab-material-brown-A100,
.btn.btn-fab:hover-material-brown-A100,
.input-group-btn .btn.btn-fab:hover-material-brown-A100,
.btn.btn-fab:active-material-brown-A100,
.input-group-btn .btn.btn-fab:active-material-brown-A100 {
  background-color: #d7ccc8;
}
.btn.btn-fab-material-brown-A200,
.input-group-btn .btn.btn-fab-material-brown-A200,
.btn.btn-fab:hover-material-brown-A200,
.input-group-btn .btn.btn-fab:hover-material-brown-A200,
.btn.btn-fab:active-material-brown-A200,
.input-group-btn .btn.btn-fab:active-material-brown-A200 {
  background-color: #bcaaa4;
}
.btn.btn-fab-material-brown-A400,
.input-group-btn .btn.btn-fab-material-brown-A400,
.btn.btn-fab:hover-material-brown-A400,
.input-group-btn .btn.btn-fab:hover-material-brown-A400,
.btn.btn-fab:active-material-brown-A400,
.input-group-btn .btn.btn-fab:active-material-brown-A400 {
  background-color: #8d6e63;
}
.btn.btn-fab-material-brown-A700,
.input-group-btn .btn.btn-fab-material-brown-A700,
.btn.btn-fab:hover-material-brown-A700,
.input-group-btn .btn.btn-fab:hover-material-brown-A700,
.btn.btn-fab:active-material-brown-A700,
.input-group-btn .btn.btn-fab:active-material-brown-A700 {
  background-color: #5d4037;
}
.btn.btn-fab-material-grey,
.input-group-btn .btn.btn-fab-material-grey,
.btn.btn-fab:hover-material-grey,
.input-group-btn .btn.btn-fab:hover-material-grey,
.btn.btn-fab:active-material-grey,
.input-group-btn .btn.btn-fab:active-material-grey {
  background-color: #9e9e9e;
}
.btn.btn-fab-material-grey-50,
.input-group-btn .btn.btn-fab-material-grey-50,
.btn.btn-fab:hover-material-grey-50,
.input-group-btn .btn.btn-fab:hover-material-grey-50,
.btn.btn-fab:active-material-grey-50,
.input-group-btn .btn.btn-fab:active-material-grey-50 {
  background-color: #fafafa;
}
.btn.btn-fab-material-grey-100,
.input-group-btn .btn.btn-fab-material-grey-100,
.btn.btn-fab:hover-material-grey-100,
.input-group-btn .btn.btn-fab:hover-material-grey-100,
.btn.btn-fab:active-material-grey-100,
.input-group-btn .btn.btn-fab:active-material-grey-100 {
  background-color: #f5f5f5;
}
.btn.btn-fab-material-grey-200,
.input-group-btn .btn.btn-fab-material-grey-200,
.btn.btn-fab:hover-material-grey-200,
.input-group-btn .btn.btn-fab:hover-material-grey-200,
.btn.btn-fab:active-material-grey-200,
.input-group-btn .btn.btn-fab:active-material-grey-200 {
  background-color: #eeeeee;
}
.btn.btn-fab-material-grey-300,
.input-group-btn .btn.btn-fab-material-grey-300,
.btn.btn-fab:hover-material-grey-300,
.input-group-btn .btn.btn-fab:hover-material-grey-300,
.btn.btn-fab:active-material-grey-300,
.input-group-btn .btn.btn-fab:active-material-grey-300 {
  background-color: #e0e0e0;
}
.btn.btn-fab-material-grey-400,
.input-group-btn .btn.btn-fab-material-grey-400,
.btn.btn-fab:hover-material-grey-400,
.input-group-btn .btn.btn-fab:hover-material-grey-400,
.btn.btn-fab:active-material-grey-400,
.input-group-btn .btn.btn-fab:active-material-grey-400 {
  background-color: #bdbdbd;
}
.btn.btn-fab-material-grey-500,
.input-group-btn .btn.btn-fab-material-grey-500,
.btn.btn-fab:hover-material-grey-500,
.input-group-btn .btn.btn-fab:hover-material-grey-500,
.btn.btn-fab:active-material-grey-500,
.input-group-btn .btn.btn-fab:active-material-grey-500 {
  background-color: #9e9e9e;
}
.btn.btn-fab-material-grey-600,
.input-group-btn .btn.btn-fab-material-grey-600,
.btn.btn-fab:hover-material-grey-600,
.input-group-btn .btn.btn-fab:hover-material-grey-600,
.btn.btn-fab:active-material-grey-600,
.input-group-btn .btn.btn-fab:active-material-grey-600 {
  background-color: #757575;
}
.btn.btn-fab-material-grey-700,
.input-group-btn .btn.btn-fab-material-grey-700,
.btn.btn-fab:hover-material-grey-700,
.input-group-btn .btn.btn-fab:hover-material-grey-700,
.btn.btn-fab:active-material-grey-700,
.input-group-btn .btn.btn-fab:active-material-grey-700 {
  background-color: #616161;
}
.btn.btn-fab-material-grey-800,
.input-group-btn .btn.btn-fab-material-grey-800,
.btn.btn-fab:hover-material-grey-800,
.input-group-btn .btn.btn-fab:hover-material-grey-800,
.btn.btn-fab:active-material-grey-800,
.input-group-btn .btn.btn-fab:active-material-grey-800 {
  background-color: #424242;
}
.btn.btn-fab-material-grey-900,
.input-group-btn .btn.btn-fab-material-grey-900,
.btn.btn-fab:hover-material-grey-900,
.input-group-btn .btn.btn-fab:hover-material-grey-900,
.btn.btn-fab:active-material-grey-900,
.input-group-btn .btn.btn-fab:active-material-grey-900 {
  background-color: #212121;
}
.btn.btn-fab-material-grey-A100,
.input-group-btn .btn.btn-fab-material-grey-A100,
.btn.btn-fab:hover-material-grey-A100,
.input-group-btn .btn.btn-fab:hover-material-grey-A100,
.btn.btn-fab:active-material-grey-A100,
.input-group-btn .btn.btn-fab:active-material-grey-A100 {
  background-color: #f5f5f5;
}
.btn.btn-fab-material-grey-A200,
.input-group-btn .btn.btn-fab-material-grey-A200,
.btn.btn-fab:hover-material-grey-A200,
.input-group-btn .btn.btn-fab:hover-material-grey-A200,
.btn.btn-fab:active-material-grey-A200,
.input-group-btn .btn.btn-fab:active-material-grey-A200 {
  background-color: #eeeeee;
}
.btn.btn-fab-material-grey-A400,
.input-group-btn .btn.btn-fab-material-grey-A400,
.btn.btn-fab:hover-material-grey-A400,
.input-group-btn .btn.btn-fab:hover-material-grey-A400,
.btn.btn-fab:active-material-grey-A400,
.input-group-btn .btn.btn-fab:active-material-grey-A400 {
  background-color: #bdbdbd;
}
.btn.btn-fab-material-grey-A700,
.input-group-btn .btn.btn-fab-material-grey-A700,
.btn.btn-fab:hover-material-grey-A700,
.input-group-btn .btn.btn-fab:hover-material-grey-A700,
.btn.btn-fab:active-material-grey-A700,
.input-group-btn .btn.btn-fab:active-material-grey-A700 {
  background-color: #616161;
}
.btn.btn-fab-material-blue-grey,
.input-group-btn .btn.btn-fab-material-blue-grey,
.btn.btn-fab:hover-material-blue-grey,
.input-group-btn .btn.btn-fab:hover-material-blue-grey,
.btn.btn-fab:active-material-blue-grey,
.input-group-btn .btn.btn-fab:active-material-blue-grey {
  background-color: #607d8b;
}
.btn.btn-fab-material-blue-grey-50,
.input-group-btn .btn.btn-fab-material-blue-grey-50,
.btn.btn-fab:hover-material-blue-grey-50,
.input-group-btn .btn.btn-fab:hover-material-blue-grey-50,
.btn.btn-fab:active-material-blue-grey-50,
.input-group-btn .btn.btn-fab:active-material-blue-grey-50 {
  background-color: #eceff1;
}
.btn.btn-fab-material-blue-grey-100,
.input-group-btn .btn.btn-fab-material-blue-grey-100,
.btn.btn-fab:hover-material-blue-grey-100,
.input-group-btn .btn.btn-fab:hover-material-blue-grey-100,
.btn.btn-fab:active-material-blue-grey-100,
.input-group-btn .btn.btn-fab:active-material-blue-grey-100 {
  background-color: #cfd8dc;
}
.btn.btn-fab-material-blue-grey-200,
.input-group-btn .btn.btn-fab-material-blue-grey-200,
.btn.btn-fab:hover-material-blue-grey-200,
.input-group-btn .btn.btn-fab:hover-material-blue-grey-200,
.btn.btn-fab:active-material-blue-grey-200,
.input-group-btn .btn.btn-fab:active-material-blue-grey-200 {
  background-color: #b0bec5;
}
.btn.btn-fab-material-blue-grey-300,
.input-group-btn .btn.btn-fab-material-blue-grey-300,
.btn.btn-fab:hover-material-blue-grey-300,
.input-group-btn .btn.btn-fab:hover-material-blue-grey-300,
.btn.btn-fab:active-material-blue-grey-300,
.input-group-btn .btn.btn-fab:active-material-blue-grey-300 {
  background-color: #90a4ae;
}
.btn.btn-fab-material-blue-grey-400,
.input-group-btn .btn.btn-fab-material-blue-grey-400,
.btn.btn-fab:hover-material-blue-grey-400,
.input-group-btn .btn.btn-fab:hover-material-blue-grey-400,
.btn.btn-fab:active-material-blue-grey-400,
.input-group-btn .btn.btn-fab:active-material-blue-grey-400 {
  background-color: #78909c;
}
.btn.btn-fab-material-blue-grey-500,
.input-group-btn .btn.btn-fab-material-blue-grey-500,
.btn.btn-fab:hover-material-blue-grey-500,
.input-group-btn .btn.btn-fab:hover-material-blue-grey-500,
.btn.btn-fab:active-material-blue-grey-500,
.input-group-btn .btn.btn-fab:active-material-blue-grey-500 {
  background-color: #607d8b;
}
.btn.btn-fab-material-blue-grey-600,
.input-group-btn .btn.btn-fab-material-blue-grey-600,
.btn.btn-fab:hover-material-blue-grey-600,
.input-group-btn .btn.btn-fab:hover-material-blue-grey-600,
.btn.btn-fab:active-material-blue-grey-600,
.input-group-btn .btn.btn-fab:active-material-blue-grey-600 {
  background-color: #546e7a;
}
.btn.btn-fab-material-blue-grey-700,
.input-group-btn .btn.btn-fab-material-blue-grey-700,
.btn.btn-fab:hover-material-blue-grey-700,
.input-group-btn .btn.btn-fab:hover-material-blue-grey-700,
.btn.btn-fab:active-material-blue-grey-700,
.input-group-btn .btn.btn-fab:active-material-blue-grey-700 {
  background-color: #455a64;
}
.btn.btn-fab-material-blue-grey-800,
.input-group-btn .btn.btn-fab-material-blue-grey-800,
.btn.btn-fab:hover-material-blue-grey-800,
.input-group-btn .btn.btn-fab:hover-material-blue-grey-800,
.btn.btn-fab:active-material-blue-grey-800,
.input-group-btn .btn.btn-fab:active-material-blue-grey-800 {
  background-color: #37474f;
}
.btn.btn-fab-material-blue-grey-900,
.input-group-btn .btn.btn-fab-material-blue-grey-900,
.btn.btn-fab:hover-material-blue-grey-900,
.input-group-btn .btn.btn-fab:hover-material-blue-grey-900,
.btn.btn-fab:active-material-blue-grey-900,
.input-group-btn .btn.btn-fab:active-material-blue-grey-900 {
  background-color: #263238;
}
.btn.btn-fab-material-blue-grey-A100,
.input-group-btn .btn.btn-fab-material-blue-grey-A100,
.btn.btn-fab:hover-material-blue-grey-A100,
.input-group-btn .btn.btn-fab:hover-material-blue-grey-A100,
.btn.btn-fab:active-material-blue-grey-A100,
.input-group-btn .btn.btn-fab:active-material-blue-grey-A100 {
  background-color: #cfd8dc;
}
.btn.btn-fab-material-blue-grey-A200,
.input-group-btn .btn.btn-fab-material-blue-grey-A200,
.btn.btn-fab:hover-material-blue-grey-A200,
.input-group-btn .btn.btn-fab:hover-material-blue-grey-A200,
.btn.btn-fab:active-material-blue-grey-A200,
.input-group-btn .btn.btn-fab:active-material-blue-grey-A200 {
  background-color: #b0bec5;
}
.btn.btn-fab-material-blue-grey-A400,
.input-group-btn .btn.btn-fab-material-blue-grey-A400,
.btn.btn-fab:hover-material-blue-grey-A400,
.input-group-btn .btn.btn-fab:hover-material-blue-grey-A400,
.btn.btn-fab:active-material-blue-grey-A400,
.input-group-btn .btn.btn-fab:active-material-blue-grey-A400 {
  background-color: #78909c;
}
.btn.btn-fab-material-blue-grey-A700,
.input-group-btn .btn.btn-fab-material-blue-grey-A700,
.btn.btn-fab:hover-material-blue-grey-A700,
.input-group-btn .btn.btn-fab:hover-material-blue-grey-A700,
.btn.btn-fab:active-material-blue-grey-A700,
.input-group-btn .btn.btn-fab:active-material-blue-grey-A700 {
  background-color: #455a64;
}
.btn.btn-fab,
.input-group-btn .btn.btn-fab,
.btn.btn-fab:hover,
.input-group-btn .btn.btn-fab:hover {
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-fab:active,
.input-group-btn .btn.btn-fab:active {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.btn.btn-fab,
.input-group-btn .btn.btn-fab,
.btn.btn-fab .ripple-wrapper,
.input-group-btn .btn.btn-fab .ripple-wrapper {
  border-radius: 100%;
}
.btn.btn-fab.btn-fab-mini,
.input-group-btn .btn.btn-fab.btn-fab-mini {
  width: 40px;
  height: 40px;
  padding: 13px 0;
  font-size: 15px;
}
.btn.btn-fab i,
.input-group-btn .btn.btn-fab i {
  position: relative;
  top: -5px;
  margin: 0 auto;
}
.btn-link,
.btn:not([class*="btn-"]),
.btn-default {
  color: rgba(0, 0, 0, 0.84);
}
.btn-link:hover,
.btn:not([class*="btn-"]):hover,
.btn-default:hover {
  color: rgba(0, 0, 0, 0.84);
}
.btn:not([class*="btn-"]):hover,
.btn-default:hover,
.btn-flat:not(.btn-link):hover,
.btn:not([class*="btn-"]).active,
.btn-default.active,
.btn-flat:not(.btn-link).active {
  background-color: rgba(255, 255, 255, 0.5);
}
.open > .dropdown-toggle.btn,
.open > .dropdown-toggle.btn-default {
  background-color: transparent;
}
.open > .dropdown-toggle.btn-black {
  background-color: #000000;
}
.open > .dropdown-toggle.btn-white {
  background-color: #ffffff;
}
.open > .dropdown-toggle.btn-inverse {
  background-color: #3f51b5;
}
.open > .dropdown-toggle.btn-primary {
  background-color: #009688;
}
.open > .dropdown-toggle.btn-success {
  background-color: #4caf50;
}
.open > .dropdown-toggle.btn-info {
  background-color: #03a9f4;
}
.open > .dropdown-toggle.btn-warning {
  background-color: #ff5722;
}
.open > .dropdown-toggle.btn-danger {
  background-color: #f44336;
}
.open > .dropdown-toggle.btn-material-red {
  background-color: #f44336;
}
.open > .dropdown-toggle.btn-material-red-50 {
  background-color: #ffebee;
}
.open > .dropdown-toggle.btn-material-red-100 {
  background-color: #ffcdd2;
}
.open > .dropdown-toggle.btn-material-red-200 {
  background-color: #ef9a9a;
}
.open > .dropdown-toggle.btn-material-red-300 {
  background-color: #e57373;
}
.open > .dropdown-toggle.btn-material-red-400 {
  background-color: #ef5350;
}
.open > .dropdown-toggle.btn-material-red-500 {
  background-color: #f44336;
}
.open > .dropdown-toggle.btn-material-red-600 {
  background-color: #e53935;
}
.open > .dropdown-toggle.btn-material-red-700 {
  background-color: #d32f2f;
}
.open > .dropdown-toggle.btn-material-red-800 {
  background-color: #c62828;
}
.open > .dropdown-toggle.btn-material-red-900 {
  background-color: #b71c1c;
}
.open > .dropdown-toggle.btn-material-red-A100 {
  background-color: #ff8a80;
}
.open > .dropdown-toggle.btn-material-red-A200 {
  background-color: #ff5252;
}
.open > .dropdown-toggle.btn-material-red-A400 {
  background-color: #ff1744;
}
.open > .dropdown-toggle.btn-material-red-A700 {
  background-color: #d50000;
}
.open > .dropdown-toggle.btn-material-pink {
  background-color: #e91e63;
}
.open > .dropdown-toggle.btn-material-pink-50 {
  background-color: #fce4ec;
}
.open > .dropdown-toggle.btn-material-pink-100 {
  background-color: #f8bbd0;
}
.open > .dropdown-toggle.btn-material-pink-200 {
  background-color: #f48fb1;
}
.open > .dropdown-toggle.btn-material-pink-300 {
  background-color: #f06292;
}
.open > .dropdown-toggle.btn-material-pink-400 {
  background-color: #ec407a;
}
.open > .dropdown-toggle.btn-material-pink-500 {
  background-color: #e91e63;
}
.open > .dropdown-toggle.btn-material-pink-600 {
  background-color: #d81b60;
}
.open > .dropdown-toggle.btn-material-pink-700 {
  background-color: #c2185b;
}
.open > .dropdown-toggle.btn-material-pink-800 {
  background-color: #ad1457;
}
.open > .dropdown-toggle.btn-material-pink-900 {
  background-color: #880e4f;
}
.open > .dropdown-toggle.btn-material-pink-A100 {
  background-color: #ff80ab;
}
.open > .dropdown-toggle.btn-material-pink-A200 {
  background-color: #ff4081;
}
.open > .dropdown-toggle.btn-material-pink-A400 {
  background-color: #f50057;
}
.open > .dropdown-toggle.btn-material-pink-A700 {
  background-color: #c51162;
}
.open > .dropdown-toggle.btn-material-purple {
  background-color: #9c27b0;
}
.open > .dropdown-toggle.btn-material-purple-50 {
  background-color: #f3e5f5;
}
.open > .dropdown-toggle.btn-material-purple-100 {
  background-color: #e1bee7;
}
.open > .dropdown-toggle.btn-material-purple-200 {
  background-color: #ce93d8;
}
.open > .dropdown-toggle.btn-material-purple-300 {
  background-color: #ba68c8;
}
.open > .dropdown-toggle.btn-material-purple-400 {
  background-color: #ab47bc;
}
.open > .dropdown-toggle.btn-material-purple-500 {
  background-color: #9c27b0;
}
.open > .dropdown-toggle.btn-material-purple-600 {
  background-color: #8e24aa;
}
.open > .dropdown-toggle.btn-material-purple-700 {
  background-color: #7b1fa2;
}
.open > .dropdown-toggle.btn-material-purple-800 {
  background-color: #6a1b9a;
}
.open > .dropdown-toggle.btn-material-purple-900 {
  background-color: #4a148c;
}
.open > .dropdown-toggle.btn-material-purple-A100 {
  background-color: #ea80fc;
}
.open > .dropdown-toggle.btn-material-purple-A200 {
  background-color: #e040fb;
}
.open > .dropdown-toggle.btn-material-purple-A400 {
  background-color: #d500f9;
}
.open > .dropdown-toggle.btn-material-purple-A700 {
  background-color: #aa00ff;
}
.open > .dropdown-toggle.btn-material-deep-purple {
  background-color: #673ab7;
}
.open > .dropdown-toggle.btn-material-deep-purple-50 {
  background-color: #ede7f6;
}
.open > .dropdown-toggle.btn-material-deep-purple-100 {
  background-color: #d1c4e9;
}
.open > .dropdown-toggle.btn-material-deep-purple-200 {
  background-color: #b39ddb;
}
.open > .dropdown-toggle.btn-material-deep-purple-300 {
  background-color: #9575cd;
}
.open > .dropdown-toggle.btn-material-deep-purple-400 {
  background-color: #7e57c2;
}
.open > .dropdown-toggle.btn-material-deep-purple-500 {
  background-color: #673ab7;
}
.open > .dropdown-toggle.btn-material-deep-purple-600 {
  background-color: #5e35b1;
}
.open > .dropdown-toggle.btn-material-deep-purple-700 {
  background-color: #512da8;
}
.open > .dropdown-toggle.btn-material-deep-purple-800 {
  background-color: #4527a0;
}
.open > .dropdown-toggle.btn-material-deep-purple-900 {
  background-color: #311b92;
}
.open > .dropdown-toggle.btn-material-deep-purple-A100 {
  background-color: #b388ff;
}
.open > .dropdown-toggle.btn-material-deep-purple-A200 {
  background-color: #7c4dff;
}
.open > .dropdown-toggle.btn-material-deep-purple-A400 {
  background-color: #651fff;
}
.open > .dropdown-toggle.btn-material-deep-purple-A700 {
  background-color: #6200ea;
}
.open > .dropdown-toggle.btn-material-indigo {
  background-color: #3f51b5;
}
.open > .dropdown-toggle.btn-material-indigo-50 {
  background-color: #e8eaf6;
}
.open > .dropdown-toggle.btn-material-indigo-100 {
  background-color: #c5cae9;
}
.open > .dropdown-toggle.btn-material-indigo-200 {
  background-color: #9fa8da;
}
.open > .dropdown-toggle.btn-material-indigo-300 {
  background-color: #7986cb;
}
.open > .dropdown-toggle.btn-material-indigo-400 {
  background-color: #5c6bc0;
}
.open > .dropdown-toggle.btn-material-indigo-500 {
  background-color: #3f51b5;
}
.open > .dropdown-toggle.btn-material-indigo-600 {
  background-color: #3949ab;
}
.open > .dropdown-toggle.btn-material-indigo-700 {
  background-color: #303f9f;
}
.open > .dropdown-toggle.btn-material-indigo-800 {
  background-color: #283593;
}
.open > .dropdown-toggle.btn-material-indigo-900 {
  background-color: #1a237e;
}
.open > .dropdown-toggle.btn-material-indigo-A100 {
  background-color: #8c9eff;
}
.open > .dropdown-toggle.btn-material-indigo-A200 {
  background-color: #536dfe;
}
.open > .dropdown-toggle.btn-material-indigo-A400 {
  background-color: #3d5afe;
}
.open > .dropdown-toggle.btn-material-indigo-A700 {
  background-color: #304ffe;
}
.open > .dropdown-toggle.btn-material-blue {
  background-color: #2196f3;
}
.open > .dropdown-toggle.btn-material-blue-50 {
  background-color: #e3f2fd;
}
.open > .dropdown-toggle.btn-material-blue-100 {
  background-color: #bbdefb;
}
.open > .dropdown-toggle.btn-material-blue-200 {
  background-color: #90caf9;
}
.open > .dropdown-toggle.btn-material-blue-300 {
  background-color: #64b5f6;
}
.open > .dropdown-toggle.btn-material-blue-400 {
  background-color: #42a5f5;
}
.open > .dropdown-toggle.btn-material-blue-500 {
  background-color: #2196f3;
}
.open > .dropdown-toggle.btn-material-blue-600 {
  background-color: #1e88e5;
}
.open > .dropdown-toggle.btn-material-blue-700 {
  background-color: #1976d2;
}
.open > .dropdown-toggle.btn-material-blue-800 {
  background-color: #1565c0;
}
.open > .dropdown-toggle.btn-material-blue-900 {
  background-color: #0d47a1;
}
.open > .dropdown-toggle.btn-material-blue-A100 {
  background-color: #82b1ff;
}
.open > .dropdown-toggle.btn-material-blue-A200 {
  background-color: #448aff;
}
.open > .dropdown-toggle.btn-material-blue-A400 {
  background-color: #2979ff;
}
.open > .dropdown-toggle.btn-material-blue-A700 {
  background-color: #2962ff;
}
.open > .dropdown-toggle.btn-material-light-blue {
  background-color: #03a9f4;
}
.open > .dropdown-toggle.btn-material-light-blue-50 {
  background-color: #e1f5fe;
}
.open > .dropdown-toggle.btn-material-light-blue-100 {
  background-color: #b3e5fc;
}
.open > .dropdown-toggle.btn-material-light-blue-200 {
  background-color: #81d4fa;
}
.open > .dropdown-toggle.btn-material-light-blue-300 {
  background-color: #4fc3f7;
}
.open > .dropdown-toggle.btn-material-light-blue-400 {
  background-color: #29b6f6;
}
.open > .dropdown-toggle.btn-material-light-blue-500 {
  background-color: #03a9f4;
}
.open > .dropdown-toggle.btn-material-light-blue-600 {
  background-color: #039be5;
}
.open > .dropdown-toggle.btn-material-light-blue-700 {
  background-color: #0288d1;
}
.open > .dropdown-toggle.btn-material-light-blue-800 {
  background-color: #0277bd;
}
.open > .dropdown-toggle.btn-material-light-blue-900 {
  background-color: #01579b;
}
.open > .dropdown-toggle.btn-material-light-blue-A100 {
  background-color: #80d8ff;
}
.open > .dropdown-toggle.btn-material-light-blue-A200 {
  background-color: #40c4ff;
}
.open > .dropdown-toggle.btn-material-light-blue-A400 {
  background-color: #00b0ff;
}
.open > .dropdown-toggle.btn-material-light-blue-A700 {
  background-color: #0091ea;
}
.open > .dropdown-toggle.btn-material-cyan {
  background-color: #00bcd4;
}
.open > .dropdown-toggle.btn-material-cyan-50 {
  background-color: #e0f7fa;
}
.open > .dropdown-toggle.btn-material-cyan-100 {
  background-color: #b2ebf2;
}
.open > .dropdown-toggle.btn-material-cyan-200 {
  background-color: #80deea;
}
.open > .dropdown-toggle.btn-material-cyan-300 {
  background-color: #4dd0e1;
}
.open > .dropdown-toggle.btn-material-cyan-400 {
  background-color: #26c6da;
}
.open > .dropdown-toggle.btn-material-cyan-500 {
  background-color: #00bcd4;
}
.open > .dropdown-toggle.btn-material-cyan-600 {
  background-color: #00acc1;
}
.open > .dropdown-toggle.btn-material-cyan-700 {
  background-color: #0097a7;
}
.open > .dropdown-toggle.btn-material-cyan-800 {
  background-color: #00838f;
}
.open > .dropdown-toggle.btn-material-cyan-900 {
  background-color: #006064;
}
.open > .dropdown-toggle.btn-material-cyan-A100 {
  background-color: #84ffff;
}
.open > .dropdown-toggle.btn-material-cyan-A200 {
  background-color: #18ffff;
}
.open > .dropdown-toggle.btn-material-cyan-A400 {
  background-color: #00e5ff;
}
.open > .dropdown-toggle.btn-material-cyan-A700 {
  background-color: #00b8d4;
}
.open > .dropdown-toggle.btn-material-teal {
  background-color: #009688;
}
.open > .dropdown-toggle.btn-material-teal-50 {
  background-color: #e0f2f1;
}
.open > .dropdown-toggle.btn-material-teal-100 {
  background-color: #b2dfdb;
}
.open > .dropdown-toggle.btn-material-teal-200 {
  background-color: #80cbc4;
}
.open > .dropdown-toggle.btn-material-teal-300 {
  background-color: #4db6ac;
}
.open > .dropdown-toggle.btn-material-teal-400 {
  background-color: #26a69a;
}
.open > .dropdown-toggle.btn-material-teal-500 {
  background-color: #009688;
}
.open > .dropdown-toggle.btn-material-teal-600 {
  background-color: #00897b;
}
.open > .dropdown-toggle.btn-material-teal-700 {
  background-color: #00796b;
}
.open > .dropdown-toggle.btn-material-teal-800 {
  background-color: #00695c;
}
.open > .dropdown-toggle.btn-material-teal-900 {
  background-color: #004d40;
}
.open > .dropdown-toggle.btn-material-teal-A100 {
  background-color: #a7ffeb;
}
.open > .dropdown-toggle.btn-material-teal-A200 {
  background-color: #64ffda;
}
.open > .dropdown-toggle.btn-material-teal-A400 {
  background-color: #1de9b6;
}
.open > .dropdown-toggle.btn-material-teal-A700 {
  background-color: #00bfa5;
}
.open > .dropdown-toggle.btn-material-green {
  background-color: #4caf50;
}
.open > .dropdown-toggle.btn-material-green-50 {
  background-color: #e8f5e9;
}
.open > .dropdown-toggle.btn-material-green-100 {
  background-color: #c8e6c9;
}
.open > .dropdown-toggle.btn-material-green-200 {
  background-color: #a5d6a7;
}
.open > .dropdown-toggle.btn-material-green-300 {
  background-color: #81c784;
}
.open > .dropdown-toggle.btn-material-green-400 {
  background-color: #66bb6a;
}
.open > .dropdown-toggle.btn-material-green-500 {
  background-color: #4caf50;
}
.open > .dropdown-toggle.btn-material-green-600 {
  background-color: #43a047;
}
.open > .dropdown-toggle.btn-material-green-700 {
  background-color: #388e3c;
}
.open > .dropdown-toggle.btn-material-green-800 {
  background-color: #2e7d32;
}
.open > .dropdown-toggle.btn-material-green-900 {
  background-color: #1b5e20;
}
.open > .dropdown-toggle.btn-material-green-A100 {
  background-color: #b9f6ca;
}
.open > .dropdown-toggle.btn-material-green-A200 {
  background-color: #69f0ae;
}
.open > .dropdown-toggle.btn-material-green-A400 {
  background-color: #00e676;
}
.open > .dropdown-toggle.btn-material-green-A700 {
  background-color: #00c853;
}
.open > .dropdown-toggle.btn-material-light-green {
  background-color: #8bc34a;
}
.open > .dropdown-toggle.btn-material-light-green-50 {
  background-color: #f1f8e9;
}
.open > .dropdown-toggle.btn-material-light-green-100 {
  background-color: #dcedc8;
}
.open > .dropdown-toggle.btn-material-light-green-200 {
  background-color: #c5e1a5;
}
.open > .dropdown-toggle.btn-material-light-green-300 {
  background-color: #aed581;
}
.open > .dropdown-toggle.btn-material-light-green-400 {
  background-color: #9ccc65;
}
.open > .dropdown-toggle.btn-material-light-green-500 {
  background-color: #8bc34a;
}
.open > .dropdown-toggle.btn-material-light-green-600 {
  background-color: #7cb342;
}
.open > .dropdown-toggle.btn-material-light-green-700 {
  background-color: #689f38;
}
.open > .dropdown-toggle.btn-material-light-green-800 {
  background-color: #558b2f;
}
.open > .dropdown-toggle.btn-material-light-green-900 {
  background-color: #33691e;
}
.open > .dropdown-toggle.btn-material-light-green-A100 {
  background-color: #ccff90;
}
.open > .dropdown-toggle.btn-material-light-green-A200 {
  background-color: #b2ff59;
}
.open > .dropdown-toggle.btn-material-light-green-A400 {
  background-color: #76ff03;
}
.open > .dropdown-toggle.btn-material-light-green-A700 {
  background-color: #64dd17;
}
.open > .dropdown-toggle.btn-material-lime {
  background-color: #cddc39;
}
.open > .dropdown-toggle.btn-material-lime-50 {
  background-color: #f9fbe7;
}
.open > .dropdown-toggle.btn-material-lime-100 {
  background-color: #f0f4c3;
}
.open > .dropdown-toggle.btn-material-lime-200 {
  background-color: #e6ee9c;
}
.open > .dropdown-toggle.btn-material-lime-300 {
  background-color: #dce775;
}
.open > .dropdown-toggle.btn-material-lime-400 {
  background-color: #d4e157;
}
.open > .dropdown-toggle.btn-material-lime-500 {
  background-color: #cddc39;
}
.open > .dropdown-toggle.btn-material-lime-600 {
  background-color: #c0ca33;
}
.open > .dropdown-toggle.btn-material-lime-700 {
  background-color: #afb42b;
}
.open > .dropdown-toggle.btn-material-lime-800 {
  background-color: #9e9d24;
}
.open > .dropdown-toggle.btn-material-lime-900 {
  background-color: #827717;
}
.open > .dropdown-toggle.btn-material-lime-A100 {
  background-color: #f4ff81;
}
.open > .dropdown-toggle.btn-material-lime-A200 {
  background-color: #eeff41;
}
.open > .dropdown-toggle.btn-material-lime-A400 {
  background-color: #c6ff00;
}
.open > .dropdown-toggle.btn-material-lime-A700 {
  background-color: #aeea00;
}
.open > .dropdown-toggle.btn-material-yellow {
  background-color: #ffeb3b;
}
.open > .dropdown-toggle.btn-material-yellow-50 {
  background-color: #fffde7;
}
.open > .dropdown-toggle.btn-material-yellow-100 {
  background-color: #fff9c4;
}
.open > .dropdown-toggle.btn-material-yellow-200 {
  background-color: #fff59d;
}
.open > .dropdown-toggle.btn-material-yellow-300 {
  background-color: #fff176;
}
.open > .dropdown-toggle.btn-material-yellow-400 {
  background-color: #ffee58;
}
.open > .dropdown-toggle.btn-material-yellow-500 {
  background-color: #ffeb3b;
}
.open > .dropdown-toggle.btn-material-yellow-600 {
  background-color: #fdd835;
}
.open > .dropdown-toggle.btn-material-yellow-700 {
  background-color: #fbc02d;
}
.open > .dropdown-toggle.btn-material-yellow-800 {
  background-color: #f9a825;
}
.open > .dropdown-toggle.btn-material-yellow-900 {
  background-color: #f57f17;
}
.open > .dropdown-toggle.btn-material-yellow-A100 {
  background-color: #ffff8d;
}
.open > .dropdown-toggle.btn-material-yellow-A200 {
  background-color: #ffff00;
}
.open > .dropdown-toggle.btn-material-yellow-A400 {
  background-color: #ffea00;
}
.open > .dropdown-toggle.btn-material-yellow-A700 {
  background-color: #ffd600;
}
.open > .dropdown-toggle.btn-material-amber {
  background-color: #ffc107;
}
.open > .dropdown-toggle.btn-material-amber-50 {
  background-color: #fff8e1;
}
.open > .dropdown-toggle.btn-material-amber-100 {
  background-color: #ffecb3;
}
.open > .dropdown-toggle.btn-material-amber-200 {
  background-color: #ffe082;
}
.open > .dropdown-toggle.btn-material-amber-300 {
  background-color: #ffd54f;
}
.open > .dropdown-toggle.btn-material-amber-400 {
  background-color: #ffca28;
}
.open > .dropdown-toggle.btn-material-amber-500 {
  background-color: #ffc107;
}
.open > .dropdown-toggle.btn-material-amber-600 {
  background-color: #ffb300;
}
.open > .dropdown-toggle.btn-material-amber-700 {
  background-color: #ffa000;
}
.open > .dropdown-toggle.btn-material-amber-800 {
  background-color: #ff8f00;
}
.open > .dropdown-toggle.btn-material-amber-900 {
  background-color: #ff6f00;
}
.open > .dropdown-toggle.btn-material-amber-A100 {
  background-color: #ffe57f;
}
.open > .dropdown-toggle.btn-material-amber-A200 {
  background-color: #ffd740;
}
.open > .dropdown-toggle.btn-material-amber-A400 {
  background-color: #ffc400;
}
.open > .dropdown-toggle.btn-material-amber-A700 {
  background-color: #ffab00;
}
.open > .dropdown-toggle.btn-material-orange {
  background-color: #ff9800;
}
.open > .dropdown-toggle.btn-material-orange-50 {
  background-color: #fff3e0;
}
.open > .dropdown-toggle.btn-material-orange-100 {
  background-color: #ffe0b2;
}
.open > .dropdown-toggle.btn-material-orange-200 {
  background-color: #ffcc80;
}
.open > .dropdown-toggle.btn-material-orange-300 {
  background-color: #ffb74d;
}
.open > .dropdown-toggle.btn-material-orange-400 {
  background-color: #ffa726;
}
.open > .dropdown-toggle.btn-material-orange-500 {
  background-color: #ff9800;
}
.open > .dropdown-toggle.btn-material-orange-600 {
  background-color: #fb8c00;
}
.open > .dropdown-toggle.btn-material-orange-700 {
  background-color: #f57c00;
}
.open > .dropdown-toggle.btn-material-orange-800 {
  background-color: #ef6c00;
}
.open > .dropdown-toggle.btn-material-orange-900 {
  background-color: #e65100;
}
.open > .dropdown-toggle.btn-material-orange-A100 {
  background-color: #ffd180;
}
.open > .dropdown-toggle.btn-material-orange-A200 {
  background-color: #ffab40;
}
.open > .dropdown-toggle.btn-material-orange-A400 {
  background-color: #ff9100;
}
.open > .dropdown-toggle.btn-material-orange-A700 {
  background-color: #ff6d00;
}
.open > .dropdown-toggle.btn-material-deep-orange {
  background-color: #ff5722;
}
.open > .dropdown-toggle.btn-material-deep-orange-50 {
  background-color: #fbe9e7;
}
.open > .dropdown-toggle.btn-material-deep-orange-100 {
  background-color: #ffccbc;
}
.open > .dropdown-toggle.btn-material-deep-orange-200 {
  background-color: #ffab91;
}
.open > .dropdown-toggle.btn-material-deep-orange-300 {
  background-color: #ff8a65;
}
.open > .dropdown-toggle.btn-material-deep-orange-400 {
  background-color: #ff7043;
}
.open > .dropdown-toggle.btn-material-deep-orange-500 {
  background-color: #ff5722;
}
.open > .dropdown-toggle.btn-material-deep-orange-600 {
  background-color: #f4511e;
}
.open > .dropdown-toggle.btn-material-deep-orange-700 {
  background-color: #e64a19;
}
.open > .dropdown-toggle.btn-material-deep-orange-800 {
  background-color: #d84315;
}
.open > .dropdown-toggle.btn-material-deep-orange-900 {
  background-color: #bf360c;
}
.open > .dropdown-toggle.btn-material-deep-orange-A100 {
  background-color: #ff9e80;
}
.open > .dropdown-toggle.btn-material-deep-orange-A200 {
  background-color: #ff6e40;
}
.open > .dropdown-toggle.btn-material-deep-orange-A400 {
  background-color: #ff3d00;
}
.open > .dropdown-toggle.btn-material-deep-orange-A700 {
  background-color: #dd2c00;
}
.open > .dropdown-toggle.btn-material-brown {
  background-color: #795548;
}
.open > .dropdown-toggle.btn-material-brown-50 {
  background-color: #efebe9;
}
.open > .dropdown-toggle.btn-material-brown-100 {
  background-color: #d7ccc8;
}
.open > .dropdown-toggle.btn-material-brown-200 {
  background-color: #bcaaa4;
}
.open > .dropdown-toggle.btn-material-brown-300 {
  background-color: #a1887f;
}
.open > .dropdown-toggle.btn-material-brown-400 {
  background-color: #8d6e63;
}
.open > .dropdown-toggle.btn-material-brown-500 {
  background-color: #795548;
}
.open > .dropdown-toggle.btn-material-brown-600 {
  background-color: #6d4c41;
}
.open > .dropdown-toggle.btn-material-brown-700 {
  background-color: #5d4037;
}
.open > .dropdown-toggle.btn-material-brown-800 {
  background-color: #4e342e;
}
.open > .dropdown-toggle.btn-material-brown-900 {
  background-color: #3e2723;
}
.open > .dropdown-toggle.btn-material-brown-A100 {
  background-color: #d7ccc8;
}
.open > .dropdown-toggle.btn-material-brown-A200 {
  background-color: #bcaaa4;
}
.open > .dropdown-toggle.btn-material-brown-A400 {
  background-color: #8d6e63;
}
.open > .dropdown-toggle.btn-material-brown-A700 {
  background-color: #5d4037;
}
.open > .dropdown-toggle.btn-material-grey {
  background-color: #9e9e9e;
}
.open > .dropdown-toggle.btn-material-grey-50 {
  background-color: #fafafa;
}
.open > .dropdown-toggle.btn-material-grey-100 {
  background-color: #f5f5f5;
}
.open > .dropdown-toggle.btn-material-grey-200 {
  background-color: #eeeeee;
}
.open > .dropdown-toggle.btn-material-grey-300 {
  background-color: #e0e0e0;
}
.open > .dropdown-toggle.btn-material-grey-400 {
  background-color: #bdbdbd;
}
.open > .dropdown-toggle.btn-material-grey-500 {
  background-color: #9e9e9e;
}
.open > .dropdown-toggle.btn-material-grey-600 {
  background-color: #757575;
}
.open > .dropdown-toggle.btn-material-grey-700 {
  background-color: #616161;
}
.open > .dropdown-toggle.btn-material-grey-800 {
  background-color: #424242;
}
.open > .dropdown-toggle.btn-material-grey-900 {
  background-color: #212121;
}
.open > .dropdown-toggle.btn-material-grey-A100 {
  background-color: #f5f5f5;
}
.open > .dropdown-toggle.btn-material-grey-A200 {
  background-color: #eeeeee;
}
.open > .dropdown-toggle.btn-material-grey-A400 {
  background-color: #bdbdbd;
}
.open > .dropdown-toggle.btn-material-grey-A700 {
  background-color: #616161;
}
.open > .dropdown-toggle.btn-material-blue-grey {
  background-color: #607d8b;
}
.open > .dropdown-toggle.btn-material-blue-grey-50 {
  background-color: #eceff1;
}
.open > .dropdown-toggle.btn-material-blue-grey-100 {
  background-color: #cfd8dc;
}
.open > .dropdown-toggle.btn-material-blue-grey-200 {
  background-color: #b0bec5;
}
.open > .dropdown-toggle.btn-material-blue-grey-300 {
  background-color: #90a4ae;
}
.open > .dropdown-toggle.btn-material-blue-grey-400 {
  background-color: #78909c;
}
.open > .dropdown-toggle.btn-material-blue-grey-500 {
  background-color: #607d8b;
}
.open > .dropdown-toggle.btn-material-blue-grey-600 {
  background-color: #546e7a;
}
.open > .dropdown-toggle.btn-material-blue-grey-700 {
  background-color: #455a64;
}
.open > .dropdown-toggle.btn-material-blue-grey-800 {
  background-color: #37474f;
}
.open > .dropdown-toggle.btn-material-blue-grey-900 {
  background-color: #263238;
}
.open > .dropdown-toggle.btn-material-blue-grey-A100 {
  background-color: #cfd8dc;
}
.open > .dropdown-toggle.btn-material-blue-grey-A200 {
  background-color: #b0bec5;
}
.open > .dropdown-toggle.btn-material-blue-grey-A400 {
  background-color: #78909c;
}
.open > .dropdown-toggle.btn-material-blue-grey-A700 {
  background-color: #455a64;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-left: 0;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  border-radius: 2px;
  margin: 10px 1px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}
.btn-group:active:not(.btn-link),
.btn-group-vertical:active:not(.btn-link) {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.btn-group.open .dropdown-toggle,
.btn-group-vertical.open .dropdown-toggle {
  box-shadow: none;
}
.btn-group.btn-group-raised,
.btn-group-vertical.btn-group-raised {
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}
.btn-group.btn-group-raised:active:not(.btn-link),
.btn-group-vertical.btn-group-raised:active:not(.btn-link) {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.btn-group .btn,
.btn-group-vertical .btn,
.btn-group .btn:active,
.btn-group-vertical .btn:active,
.btn-group .btn-group,
.btn-group-vertical .btn-group {
  box-shadow: none !important;
  margin: 0;
}
.btn-group-flat {
  box-shadow: none !important;
}
.form-horizontal .checkbox {
  padding-top: 20px;
}
.form-group .checkbox label {
  font-size: 16px;
  line-height: 1.42857143;
  font-weight: normal;
}
.form-group.form-group-sm .checkbox label {
  font-size: 11px;
  line-height: 1.5;
  font-weight: normal;
}
.form-group.form-group-lg .checkbox label {
  font-size: 18px;
  line-height: 1.3333333;
  font-weight: normal;
}
.form-group .checkbox label {
  cursor: pointer;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.54);
}
.form-group .checkbox input[type=checkbox] {
  opacity: 0;
  position: absolute;
  margin: 0;
  z-index: -1;
  width: 0;
  height: 0;
  overflow: hidden;
  left: 0;
  pointer-events: none;
}
.form-group .checkbox .checkbox-material {
  vertical-align: middle;
  position: relative;
  top: 3px;
}
.form-group .checkbox .checkbox-material:before {
  display: block;
  position: absolute;
  left: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.84);
  height: 20px;
  width: 20px;
  border-radius: 100%;
  z-index: 1;
  opacity: 0;
  margin: 0;
  transform: scale3d(2.3, 2.3, 1);
}
.form-group .checkbox .checkbox-material .check {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(0, 0, 0, 0.54);
  border-radius: 2px;
  overflow: hidden;
  z-index: 1;
}
.form-group .checkbox .checkbox-material .check:before {
  position: absolute;
  content: "";
  transform: rotate(45deg);
  display: block;
  margin-top: -4px;
  margin-left: 6px;
  width: 0;
  height: 0;
  box-shadow: 0 0 0 0,
          0 0 0 0,
          0 0 0 0,
          0 0 0 0,
          0 0 0 0,
          0 0 0 0,
          0 0 0 0 inset;
  animation: checkbox-off 0.3s forwards;
}
.form-group .checkbox input[type=checkbox]:focus + .checkbox-material .check:after {
  opacity: 0.2;
}
.form-group .checkbox input[type=checkbox]:checked + .checkbox-material .check:before {
  box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  animation: checkbox-on 0.3s forwards;
}
.form-group .checkbox input[type=checkbox]:not(:checked) + .checkbox-material:before {
  animation: rippleOff 500ms;
}
.form-group .checkbox input[type=checkbox]:checked + .checkbox-material:before {
  animation: rippleOn 500ms;
}
.form-group .checkbox input[type=checkbox]:not(:checked) + .checkbox-material .check:after {
  animation: rippleOff 500ms forwards;
}
.form-group .checkbox input[type=checkbox]:checked + .checkbox-material .check:after {
  animation: rippleOn 500ms forwards;
}
fieldset[disabled] .form-group .checkbox,
fieldset[disabled] .form-group .checkbox input[type=checkbox],
.form-group .checkbox input[type=checkbox][disabled]:not(:checked) ~ .checkbox-material .check:before,
.form-group .checkbox input[type=checkbox][disabled]:not(:checked) ~ .checkbox-material .check,
.form-group .checkbox input[type=checkbox][disabled] + .circle {
  opacity: 0.5;
}
.form-group .checkbox input[type=checkbox][disabled] + .checkbox-material .check:after {
  background-color: rgba(0, 0, 0, 0.84);
  transform: rotate(-45deg);
}
.form-group .checkbox input[type=checkbox]:checked + .checkbox-material .check:after,
.form-group .checkbox-default input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #4caf50;
}
.form-group .checkbox-black input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #000000;
}
.form-group .checkbox-white input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ffffff;
}
.form-group .checkbox-inverse input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #3f51b5;
}
.form-group .checkbox-primary input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #009688;
}
.form-group .checkbox-success input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #4caf50;
}
.form-group .checkbox-info input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #03a9f4;
}
.form-group .checkbox-warning input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ff5722;
}
.form-group .checkbox-danger input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #f44336;
}
.form-group .checkbox-material-red input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #f44336;
}
.form-group .checkbox-material-red-50 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ffebee;
}
.form-group .checkbox-material-red-100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ffcdd2;
}
.form-group .checkbox-material-red-200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ef9a9a;
}
.form-group .checkbox-material-red-300 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #e57373;
}
.form-group .checkbox-material-red-400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ef5350;
}
.form-group .checkbox-material-red-500 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #f44336;
}
.form-group .checkbox-material-red-600 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #e53935;
}
.form-group .checkbox-material-red-700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #d32f2f;
}
.form-group .checkbox-material-red-800 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #c62828;
}
.form-group .checkbox-material-red-900 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #b71c1c;
}
.form-group .checkbox-material-red-A100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ff8a80;
}
.form-group .checkbox-material-red-A200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ff5252;
}
.form-group .checkbox-material-red-A400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ff1744;
}
.form-group .checkbox-material-red-A700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #d50000;
}
.form-group .checkbox-material-pink input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #e91e63;
}
.form-group .checkbox-material-pink-50 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #fce4ec;
}
.form-group .checkbox-material-pink-100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #f8bbd0;
}
.form-group .checkbox-material-pink-200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #f48fb1;
}
.form-group .checkbox-material-pink-300 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #f06292;
}
.form-group .checkbox-material-pink-400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ec407a;
}
.form-group .checkbox-material-pink-500 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #e91e63;
}
.form-group .checkbox-material-pink-600 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #d81b60;
}
.form-group .checkbox-material-pink-700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #c2185b;
}
.form-group .checkbox-material-pink-800 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ad1457;
}
.form-group .checkbox-material-pink-900 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #880e4f;
}
.form-group .checkbox-material-pink-A100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ff80ab;
}
.form-group .checkbox-material-pink-A200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ff4081;
}
.form-group .checkbox-material-pink-A400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #f50057;
}
.form-group .checkbox-material-pink-A700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #c51162;
}
.form-group .checkbox-material-purple input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #9c27b0;
}
.form-group .checkbox-material-purple-50 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #f3e5f5;
}
.form-group .checkbox-material-purple-100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #e1bee7;
}
.form-group .checkbox-material-purple-200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ce93d8;
}
.form-group .checkbox-material-purple-300 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ba68c8;
}
.form-group .checkbox-material-purple-400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ab47bc;
}
.form-group .checkbox-material-purple-500 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #9c27b0;
}
.form-group .checkbox-material-purple-600 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #8e24aa;
}
.form-group .checkbox-material-purple-700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #7b1fa2;
}
.form-group .checkbox-material-purple-800 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #6a1b9a;
}
.form-group .checkbox-material-purple-900 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #4a148c;
}
.form-group .checkbox-material-purple-A100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ea80fc;
}
.form-group .checkbox-material-purple-A200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #e040fb;
}
.form-group .checkbox-material-purple-A400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #d500f9;
}
.form-group .checkbox-material-purple-A700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #aa00ff;
}
.form-group .checkbox-material-deep-purple input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #673ab7;
}
.form-group .checkbox-material-deep-purple-50 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ede7f6;
}
.form-group .checkbox-material-deep-purple-100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #d1c4e9;
}
.form-group .checkbox-material-deep-purple-200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #b39ddb;
}
.form-group .checkbox-material-deep-purple-300 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #9575cd;
}
.form-group .checkbox-material-deep-purple-400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #7e57c2;
}
.form-group .checkbox-material-deep-purple-500 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #673ab7;
}
.form-group .checkbox-material-deep-purple-600 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #5e35b1;
}
.form-group .checkbox-material-deep-purple-700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #512da8;
}
.form-group .checkbox-material-deep-purple-800 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #4527a0;
}
.form-group .checkbox-material-deep-purple-900 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #311b92;
}
.form-group .checkbox-material-deep-purple-A100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #b388ff;
}
.form-group .checkbox-material-deep-purple-A200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #7c4dff;
}
.form-group .checkbox-material-deep-purple-A400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #651fff;
}
.form-group .checkbox-material-deep-purple-A700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #6200ea;
}
.form-group .checkbox-material-indigo input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #3f51b5;
}
.form-group .checkbox-material-indigo-50 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #e8eaf6;
}
.form-group .checkbox-material-indigo-100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #c5cae9;
}
.form-group .checkbox-material-indigo-200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #9fa8da;
}
.form-group .checkbox-material-indigo-300 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #7986cb;
}
.form-group .checkbox-material-indigo-400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #5c6bc0;
}
.form-group .checkbox-material-indigo-500 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #3f51b5;
}
.form-group .checkbox-material-indigo-600 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #3949ab;
}
.form-group .checkbox-material-indigo-700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #303f9f;
}
.form-group .checkbox-material-indigo-800 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #283593;
}
.form-group .checkbox-material-indigo-900 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #1a237e;
}
.form-group .checkbox-material-indigo-A100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #8c9eff;
}
.form-group .checkbox-material-indigo-A200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #536dfe;
}
.form-group .checkbox-material-indigo-A400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #3d5afe;
}
.form-group .checkbox-material-indigo-A700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #304ffe;
}
.form-group .checkbox-material-blue input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #2196f3;
}
.form-group .checkbox-material-blue-50 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #e3f2fd;
}
.form-group .checkbox-material-blue-100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #bbdefb;
}
.form-group .checkbox-material-blue-200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #90caf9;
}
.form-group .checkbox-material-blue-300 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #64b5f6;
}
.form-group .checkbox-material-blue-400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #42a5f5;
}
.form-group .checkbox-material-blue-500 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #2196f3;
}
.form-group .checkbox-material-blue-600 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #1e88e5;
}
.form-group .checkbox-material-blue-700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #1976d2;
}
.form-group .checkbox-material-blue-800 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #1565c0;
}
.form-group .checkbox-material-blue-900 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #0d47a1;
}
.form-group .checkbox-material-blue-A100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #82b1ff;
}
.form-group .checkbox-material-blue-A200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #448aff;
}
.form-group .checkbox-material-blue-A400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #2979ff;
}
.form-group .checkbox-material-blue-A700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #2962ff;
}
.form-group .checkbox-material-light-blue input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #03a9f4;
}
.form-group .checkbox-material-light-blue-50 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #e1f5fe;
}
.form-group .checkbox-material-light-blue-100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #b3e5fc;
}
.form-group .checkbox-material-light-blue-200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #81d4fa;
}
.form-group .checkbox-material-light-blue-300 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #4fc3f7;
}
.form-group .checkbox-material-light-blue-400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #29b6f6;
}
.form-group .checkbox-material-light-blue-500 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #03a9f4;
}
.form-group .checkbox-material-light-blue-600 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #039be5;
}
.form-group .checkbox-material-light-blue-700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #0288d1;
}
.form-group .checkbox-material-light-blue-800 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #0277bd;
}
.form-group .checkbox-material-light-blue-900 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #01579b;
}
.form-group .checkbox-material-light-blue-A100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #80d8ff;
}
.form-group .checkbox-material-light-blue-A200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #40c4ff;
}
.form-group .checkbox-material-light-blue-A400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #00b0ff;
}
.form-group .checkbox-material-light-blue-A700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #0091ea;
}
.form-group .checkbox-material-cyan input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #00bcd4;
}
.form-group .checkbox-material-cyan-50 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #e0f7fa;
}
.form-group .checkbox-material-cyan-100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #b2ebf2;
}
.form-group .checkbox-material-cyan-200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #80deea;
}
.form-group .checkbox-material-cyan-300 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #4dd0e1;
}
.form-group .checkbox-material-cyan-400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #26c6da;
}
.form-group .checkbox-material-cyan-500 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #00bcd4;
}
.form-group .checkbox-material-cyan-600 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #00acc1;
}
.form-group .checkbox-material-cyan-700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #0097a7;
}
.form-group .checkbox-material-cyan-800 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #00838f;
}
.form-group .checkbox-material-cyan-900 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #006064;
}
.form-group .checkbox-material-cyan-A100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #84ffff;
}
.form-group .checkbox-material-cyan-A200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #18ffff;
}
.form-group .checkbox-material-cyan-A400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #00e5ff;
}
.form-group .checkbox-material-cyan-A700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #00b8d4;
}
.form-group .checkbox-material-teal input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #009688;
}
.form-group .checkbox-material-teal-50 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #e0f2f1;
}
.form-group .checkbox-material-teal-100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #b2dfdb;
}
.form-group .checkbox-material-teal-200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #80cbc4;
}
.form-group .checkbox-material-teal-300 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #4db6ac;
}
.form-group .checkbox-material-teal-400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #26a69a;
}
.form-group .checkbox-material-teal-500 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #009688;
}
.form-group .checkbox-material-teal-600 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #00897b;
}
.form-group .checkbox-material-teal-700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #00796b;
}
.form-group .checkbox-material-teal-800 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #00695c;
}
.form-group .checkbox-material-teal-900 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #004d40;
}
.form-group .checkbox-material-teal-A100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #a7ffeb;
}
.form-group .checkbox-material-teal-A200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #64ffda;
}
.form-group .checkbox-material-teal-A400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #1de9b6;
}
.form-group .checkbox-material-teal-A700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #00bfa5;
}
.form-group .checkbox-material-green input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #4caf50;
}
.form-group .checkbox-material-green-50 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #e8f5e9;
}
.form-group .checkbox-material-green-100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #c8e6c9;
}
.form-group .checkbox-material-green-200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #a5d6a7;
}
.form-group .checkbox-material-green-300 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #81c784;
}
.form-group .checkbox-material-green-400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #66bb6a;
}
.form-group .checkbox-material-green-500 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #4caf50;
}
.form-group .checkbox-material-green-600 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #43a047;
}
.form-group .checkbox-material-green-700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #388e3c;
}
.form-group .checkbox-material-green-800 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #2e7d32;
}
.form-group .checkbox-material-green-900 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #1b5e20;
}
.form-group .checkbox-material-green-A100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #b9f6ca;
}
.form-group .checkbox-material-green-A200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #69f0ae;
}
.form-group .checkbox-material-green-A400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #00e676;
}
.form-group .checkbox-material-green-A700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #00c853;
}
.form-group .checkbox-material-light-green input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #8bc34a;
}
.form-group .checkbox-material-light-green-50 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #f1f8e9;
}
.form-group .checkbox-material-light-green-100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #dcedc8;
}
.form-group .checkbox-material-light-green-200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #c5e1a5;
}
.form-group .checkbox-material-light-green-300 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #aed581;
}
.form-group .checkbox-material-light-green-400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #9ccc65;
}
.form-group .checkbox-material-light-green-500 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #8bc34a;
}
.form-group .checkbox-material-light-green-600 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #7cb342;
}
.form-group .checkbox-material-light-green-700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #689f38;
}
.form-group .checkbox-material-light-green-800 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #558b2f;
}
.form-group .checkbox-material-light-green-900 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #33691e;
}
.form-group .checkbox-material-light-green-A100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ccff90;
}
.form-group .checkbox-material-light-green-A200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #b2ff59;
}
.form-group .checkbox-material-light-green-A400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #76ff03;
}
.form-group .checkbox-material-light-green-A700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #64dd17;
}
.form-group .checkbox-material-lime input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #cddc39;
}
.form-group .checkbox-material-lime-50 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #f9fbe7;
}
.form-group .checkbox-material-lime-100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #f0f4c3;
}
.form-group .checkbox-material-lime-200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #e6ee9c;
}
.form-group .checkbox-material-lime-300 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #dce775;
}
.form-group .checkbox-material-lime-400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #d4e157;
}
.form-group .checkbox-material-lime-500 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #cddc39;
}
.form-group .checkbox-material-lime-600 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #c0ca33;
}
.form-group .checkbox-material-lime-700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #afb42b;
}
.form-group .checkbox-material-lime-800 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #9e9d24;
}
.form-group .checkbox-material-lime-900 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #827717;
}
.form-group .checkbox-material-lime-A100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #f4ff81;
}
.form-group .checkbox-material-lime-A200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #eeff41;
}
.form-group .checkbox-material-lime-A400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #c6ff00;
}
.form-group .checkbox-material-lime-A700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #aeea00;
}
.form-group .checkbox-material-yellow input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ffeb3b;
}
.form-group .checkbox-material-yellow-50 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #fffde7;
}
.form-group .checkbox-material-yellow-100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #fff9c4;
}
.form-group .checkbox-material-yellow-200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #fff59d;
}
.form-group .checkbox-material-yellow-300 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #fff176;
}
.form-group .checkbox-material-yellow-400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ffee58;
}
.form-group .checkbox-material-yellow-500 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ffeb3b;
}
.form-group .checkbox-material-yellow-600 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #fdd835;
}
.form-group .checkbox-material-yellow-700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #fbc02d;
}
.form-group .checkbox-material-yellow-800 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #f9a825;
}
.form-group .checkbox-material-yellow-900 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #f57f17;
}
.form-group .checkbox-material-yellow-A100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ffff8d;
}
.form-group .checkbox-material-yellow-A200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ffff00;
}
.form-group .checkbox-material-yellow-A400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ffea00;
}
.form-group .checkbox-material-yellow-A700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ffd600;
}
.form-group .checkbox-material-amber input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ffc107;
}
.form-group .checkbox-material-amber-50 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #fff8e1;
}
.form-group .checkbox-material-amber-100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ffecb3;
}
.form-group .checkbox-material-amber-200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ffe082;
}
.form-group .checkbox-material-amber-300 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ffd54f;
}
.form-group .checkbox-material-amber-400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ffca28;
}
.form-group .checkbox-material-amber-500 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ffc107;
}
.form-group .checkbox-material-amber-600 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ffb300;
}
.form-group .checkbox-material-amber-700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ffa000;
}
.form-group .checkbox-material-amber-800 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ff8f00;
}
.form-group .checkbox-material-amber-900 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ff6f00;
}
.form-group .checkbox-material-amber-A100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ffe57f;
}
.form-group .checkbox-material-amber-A200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ffd740;
}
.form-group .checkbox-material-amber-A400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ffc400;
}
.form-group .checkbox-material-amber-A700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ffab00;
}
.form-group .checkbox-material-orange input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ff9800;
}
.form-group .checkbox-material-orange-50 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #fff3e0;
}
.form-group .checkbox-material-orange-100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ffe0b2;
}
.form-group .checkbox-material-orange-200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ffcc80;
}
.form-group .checkbox-material-orange-300 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ffb74d;
}
.form-group .checkbox-material-orange-400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ffa726;
}
.form-group .checkbox-material-orange-500 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ff9800;
}
.form-group .checkbox-material-orange-600 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #fb8c00;
}
.form-group .checkbox-material-orange-700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #f57c00;
}
.form-group .checkbox-material-orange-800 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ef6c00;
}
.form-group .checkbox-material-orange-900 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #e65100;
}
.form-group .checkbox-material-orange-A100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ffd180;
}
.form-group .checkbox-material-orange-A200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ffab40;
}
.form-group .checkbox-material-orange-A400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ff9100;
}
.form-group .checkbox-material-orange-A700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ff6d00;
}
.form-group .checkbox-material-deep-orange input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ff5722;
}
.form-group .checkbox-material-deep-orange-50 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #fbe9e7;
}
.form-group .checkbox-material-deep-orange-100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ffccbc;
}
.form-group .checkbox-material-deep-orange-200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ffab91;
}
.form-group .checkbox-material-deep-orange-300 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ff8a65;
}
.form-group .checkbox-material-deep-orange-400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ff7043;
}
.form-group .checkbox-material-deep-orange-500 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ff5722;
}
.form-group .checkbox-material-deep-orange-600 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #f4511e;
}
.form-group .checkbox-material-deep-orange-700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #e64a19;
}
.form-group .checkbox-material-deep-orange-800 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #d84315;
}
.form-group .checkbox-material-deep-orange-900 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #bf360c;
}
.form-group .checkbox-material-deep-orange-A100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ff9e80;
}
.form-group .checkbox-material-deep-orange-A200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ff6e40;
}
.form-group .checkbox-material-deep-orange-A400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #ff3d00;
}
.form-group .checkbox-material-deep-orange-A700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #dd2c00;
}
.form-group .checkbox-material-brown input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #795548;
}
.form-group .checkbox-material-brown-50 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #efebe9;
}
.form-group .checkbox-material-brown-100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #d7ccc8;
}
.form-group .checkbox-material-brown-200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #bcaaa4;
}
.form-group .checkbox-material-brown-300 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #a1887f;
}
.form-group .checkbox-material-brown-400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #8d6e63;
}
.form-group .checkbox-material-brown-500 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #795548;
}
.form-group .checkbox-material-brown-600 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #6d4c41;
}
.form-group .checkbox-material-brown-700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #5d4037;
}
.form-group .checkbox-material-brown-800 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #4e342e;
}
.form-group .checkbox-material-brown-900 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #3e2723;
}
.form-group .checkbox-material-brown-A100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #d7ccc8;
}
.form-group .checkbox-material-brown-A200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #bcaaa4;
}
.form-group .checkbox-material-brown-A400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #8d6e63;
}
.form-group .checkbox-material-brown-A700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #5d4037;
}
.form-group .checkbox-material-grey input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #9e9e9e;
}
.form-group .checkbox-material-grey-50 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #fafafa;
}
.form-group .checkbox-material-grey-100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #f5f5f5;
}
.form-group .checkbox-material-grey-200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #eeeeee;
}
.form-group .checkbox-material-grey-300 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #e0e0e0;
}
.form-group .checkbox-material-grey-400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #bdbdbd;
}
.form-group .checkbox-material-grey-500 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #9e9e9e;
}
.form-group .checkbox-material-grey-600 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #757575;
}
.form-group .checkbox-material-grey-700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #616161;
}
.form-group .checkbox-material-grey-800 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #424242;
}
.form-group .checkbox-material-grey-900 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #212121;
}
.form-group .checkbox-material-grey-A100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #f5f5f5;
}
.form-group .checkbox-material-grey-A200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #eeeeee;
}
.form-group .checkbox-material-grey-A400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #bdbdbd;
}
.form-group .checkbox-material-grey-A700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #616161;
}
.form-group .checkbox-material-blue-grey input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #607d8b;
}
.form-group .checkbox-material-blue-grey-50 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #eceff1;
}
.form-group .checkbox-material-blue-grey-100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #cfd8dc;
}
.form-group .checkbox-material-blue-grey-200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #b0bec5;
}
.form-group .checkbox-material-blue-grey-300 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #90a4ae;
}
.form-group .checkbox-material-blue-grey-400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #78909c;
}
.form-group .checkbox-material-blue-grey-500 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #607d8b;
}
.form-group .checkbox-material-blue-grey-600 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #546e7a;
}
.form-group .checkbox-material-blue-grey-700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #455a64;
}
.form-group .checkbox-material-blue-grey-800 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #37474f;
}
.form-group .checkbox-material-blue-grey-900 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #263238;
}
.form-group .checkbox-material-blue-grey-A100 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #cfd8dc;
}
.form-group .checkbox-material-blue-grey-A200 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #b0bec5;
}
.form-group .checkbox-material-blue-grey-A400 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #78909c;
}
.form-group .checkbox-material-blue-grey-A700 input[type=checkbox]:checked + .checkbox-material .check:after {
  background-color: #455a64;
}
.form-group .checkbox input[type=checkbox]:checked + .checkbox-material .check:before,
.form-group .checkbox-default input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #4caf50;
}
.form-group .checkbox-black input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #000000;
}
.form-group .checkbox-white input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ffffff;
}
.form-group .checkbox-inverse input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #3f51b5;
}
.form-group .checkbox-primary input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #009688;
}
.form-group .checkbox-success input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #4caf50;
}
.form-group .checkbox-info input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #03a9f4;
}
.form-group .checkbox-warning input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ff5722;
}
.form-group .checkbox-danger input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #f44336;
}
.form-group .checkbox-material-red input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #f44336;
}
.form-group .checkbox-material-red-50 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ffebee;
}
.form-group .checkbox-material-red-100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ffcdd2;
}
.form-group .checkbox-material-red-200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ef9a9a;
}
.form-group .checkbox-material-red-300 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #e57373;
}
.form-group .checkbox-material-red-400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ef5350;
}
.form-group .checkbox-material-red-500 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #f44336;
}
.form-group .checkbox-material-red-600 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #e53935;
}
.form-group .checkbox-material-red-700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #d32f2f;
}
.form-group .checkbox-material-red-800 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #c62828;
}
.form-group .checkbox-material-red-900 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #b71c1c;
}
.form-group .checkbox-material-red-A100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ff8a80;
}
.form-group .checkbox-material-red-A200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ff5252;
}
.form-group .checkbox-material-red-A400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ff1744;
}
.form-group .checkbox-material-red-A700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #d50000;
}
.form-group .checkbox-material-pink input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #e91e63;
}
.form-group .checkbox-material-pink-50 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #fce4ec;
}
.form-group .checkbox-material-pink-100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #f8bbd0;
}
.form-group .checkbox-material-pink-200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #f48fb1;
}
.form-group .checkbox-material-pink-300 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #f06292;
}
.form-group .checkbox-material-pink-400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ec407a;
}
.form-group .checkbox-material-pink-500 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #e91e63;
}
.form-group .checkbox-material-pink-600 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #d81b60;
}
.form-group .checkbox-material-pink-700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #c2185b;
}
.form-group .checkbox-material-pink-800 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ad1457;
}
.form-group .checkbox-material-pink-900 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #880e4f;
}
.form-group .checkbox-material-pink-A100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ff80ab;
}
.form-group .checkbox-material-pink-A200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ff4081;
}
.form-group .checkbox-material-pink-A400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #f50057;
}
.form-group .checkbox-material-pink-A700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #c51162;
}
.form-group .checkbox-material-purple input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #9c27b0;
}
.form-group .checkbox-material-purple-50 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #f3e5f5;
}
.form-group .checkbox-material-purple-100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #e1bee7;
}
.form-group .checkbox-material-purple-200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ce93d8;
}
.form-group .checkbox-material-purple-300 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ba68c8;
}
.form-group .checkbox-material-purple-400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ab47bc;
}
.form-group .checkbox-material-purple-500 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #9c27b0;
}
.form-group .checkbox-material-purple-600 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #8e24aa;
}
.form-group .checkbox-material-purple-700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #7b1fa2;
}
.form-group .checkbox-material-purple-800 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #6a1b9a;
}
.form-group .checkbox-material-purple-900 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #4a148c;
}
.form-group .checkbox-material-purple-A100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ea80fc;
}
.form-group .checkbox-material-purple-A200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #e040fb;
}
.form-group .checkbox-material-purple-A400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #d500f9;
}
.form-group .checkbox-material-purple-A700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #aa00ff;
}
.form-group .checkbox-material-deep-purple input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #673ab7;
}
.form-group .checkbox-material-deep-purple-50 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ede7f6;
}
.form-group .checkbox-material-deep-purple-100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #d1c4e9;
}
.form-group .checkbox-material-deep-purple-200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #b39ddb;
}
.form-group .checkbox-material-deep-purple-300 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #9575cd;
}
.form-group .checkbox-material-deep-purple-400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #7e57c2;
}
.form-group .checkbox-material-deep-purple-500 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #673ab7;
}
.form-group .checkbox-material-deep-purple-600 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #5e35b1;
}
.form-group .checkbox-material-deep-purple-700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #512da8;
}
.form-group .checkbox-material-deep-purple-800 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #4527a0;
}
.form-group .checkbox-material-deep-purple-900 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #311b92;
}
.form-group .checkbox-material-deep-purple-A100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #b388ff;
}
.form-group .checkbox-material-deep-purple-A200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #7c4dff;
}
.form-group .checkbox-material-deep-purple-A400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #651fff;
}
.form-group .checkbox-material-deep-purple-A700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #6200ea;
}
.form-group .checkbox-material-indigo input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #3f51b5;
}
.form-group .checkbox-material-indigo-50 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #e8eaf6;
}
.form-group .checkbox-material-indigo-100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #c5cae9;
}
.form-group .checkbox-material-indigo-200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #9fa8da;
}
.form-group .checkbox-material-indigo-300 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #7986cb;
}
.form-group .checkbox-material-indigo-400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #5c6bc0;
}
.form-group .checkbox-material-indigo-500 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #3f51b5;
}
.form-group .checkbox-material-indigo-600 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #3949ab;
}
.form-group .checkbox-material-indigo-700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #303f9f;
}
.form-group .checkbox-material-indigo-800 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #283593;
}
.form-group .checkbox-material-indigo-900 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #1a237e;
}
.form-group .checkbox-material-indigo-A100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #8c9eff;
}
.form-group .checkbox-material-indigo-A200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #536dfe;
}
.form-group .checkbox-material-indigo-A400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #3d5afe;
}
.form-group .checkbox-material-indigo-A700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #304ffe;
}
.form-group .checkbox-material-blue input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #2196f3;
}
.form-group .checkbox-material-blue-50 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #e3f2fd;
}
.form-group .checkbox-material-blue-100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #bbdefb;
}
.form-group .checkbox-material-blue-200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #90caf9;
}
.form-group .checkbox-material-blue-300 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #64b5f6;
}
.form-group .checkbox-material-blue-400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #42a5f5;
}
.form-group .checkbox-material-blue-500 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #2196f3;
}
.form-group .checkbox-material-blue-600 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #1e88e5;
}
.form-group .checkbox-material-blue-700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #1976d2;
}
.form-group .checkbox-material-blue-800 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #1565c0;
}
.form-group .checkbox-material-blue-900 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #0d47a1;
}
.form-group .checkbox-material-blue-A100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #82b1ff;
}
.form-group .checkbox-material-blue-A200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #448aff;
}
.form-group .checkbox-material-blue-A400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #2979ff;
}
.form-group .checkbox-material-blue-A700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #2962ff;
}
.form-group .checkbox-material-light-blue input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #03a9f4;
}
.form-group .checkbox-material-light-blue-50 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #e1f5fe;
}
.form-group .checkbox-material-light-blue-100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #b3e5fc;
}
.form-group .checkbox-material-light-blue-200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #81d4fa;
}
.form-group .checkbox-material-light-blue-300 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #4fc3f7;
}
.form-group .checkbox-material-light-blue-400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #29b6f6;
}
.form-group .checkbox-material-light-blue-500 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #03a9f4;
}
.form-group .checkbox-material-light-blue-600 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #039be5;
}
.form-group .checkbox-material-light-blue-700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #0288d1;
}
.form-group .checkbox-material-light-blue-800 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #0277bd;
}
.form-group .checkbox-material-light-blue-900 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #01579b;
}
.form-group .checkbox-material-light-blue-A100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #80d8ff;
}
.form-group .checkbox-material-light-blue-A200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #40c4ff;
}
.form-group .checkbox-material-light-blue-A400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #00b0ff;
}
.form-group .checkbox-material-light-blue-A700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #0091ea;
}
.form-group .checkbox-material-cyan input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #00bcd4;
}
.form-group .checkbox-material-cyan-50 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #e0f7fa;
}
.form-group .checkbox-material-cyan-100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #b2ebf2;
}
.form-group .checkbox-material-cyan-200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #80deea;
}
.form-group .checkbox-material-cyan-300 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #4dd0e1;
}
.form-group .checkbox-material-cyan-400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #26c6da;
}
.form-group .checkbox-material-cyan-500 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #00bcd4;
}
.form-group .checkbox-material-cyan-600 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #00acc1;
}
.form-group .checkbox-material-cyan-700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #0097a7;
}
.form-group .checkbox-material-cyan-800 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #00838f;
}
.form-group .checkbox-material-cyan-900 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #006064;
}
.form-group .checkbox-material-cyan-A100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #84ffff;
}
.form-group .checkbox-material-cyan-A200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #18ffff;
}
.form-group .checkbox-material-cyan-A400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #00e5ff;
}
.form-group .checkbox-material-cyan-A700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #00b8d4;
}
.form-group .checkbox-material-teal input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #009688;
}
.form-group .checkbox-material-teal-50 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #e0f2f1;
}
.form-group .checkbox-material-teal-100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #b2dfdb;
}
.form-group .checkbox-material-teal-200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #80cbc4;
}
.form-group .checkbox-material-teal-300 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #4db6ac;
}
.form-group .checkbox-material-teal-400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #26a69a;
}
.form-group .checkbox-material-teal-500 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #009688;
}
.form-group .checkbox-material-teal-600 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #00897b;
}
.form-group .checkbox-material-teal-700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #00796b;
}
.form-group .checkbox-material-teal-800 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #00695c;
}
.form-group .checkbox-material-teal-900 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #004d40;
}
.form-group .checkbox-material-teal-A100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #a7ffeb;
}
.form-group .checkbox-material-teal-A200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #64ffda;
}
.form-group .checkbox-material-teal-A400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #1de9b6;
}
.form-group .checkbox-material-teal-A700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #00bfa5;
}
.form-group .checkbox-material-green input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #4caf50;
}
.form-group .checkbox-material-green-50 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #e8f5e9;
}
.form-group .checkbox-material-green-100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #c8e6c9;
}
.form-group .checkbox-material-green-200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #a5d6a7;
}
.form-group .checkbox-material-green-300 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #81c784;
}
.form-group .checkbox-material-green-400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #66bb6a;
}
.form-group .checkbox-material-green-500 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #4caf50;
}
.form-group .checkbox-material-green-600 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #43a047;
}
.form-group .checkbox-material-green-700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #388e3c;
}
.form-group .checkbox-material-green-800 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #2e7d32;
}
.form-group .checkbox-material-green-900 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #1b5e20;
}
.form-group .checkbox-material-green-A100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #b9f6ca;
}
.form-group .checkbox-material-green-A200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #69f0ae;
}
.form-group .checkbox-material-green-A400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #00e676;
}
.form-group .checkbox-material-green-A700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #00c853;
}
.form-group .checkbox-material-light-green input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #8bc34a;
}
.form-group .checkbox-material-light-green-50 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #f1f8e9;
}
.form-group .checkbox-material-light-green-100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #dcedc8;
}
.form-group .checkbox-material-light-green-200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #c5e1a5;
}
.form-group .checkbox-material-light-green-300 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #aed581;
}
.form-group .checkbox-material-light-green-400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #9ccc65;
}
.form-group .checkbox-material-light-green-500 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #8bc34a;
}
.form-group .checkbox-material-light-green-600 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #7cb342;
}
.form-group .checkbox-material-light-green-700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #689f38;
}
.form-group .checkbox-material-light-green-800 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #558b2f;
}
.form-group .checkbox-material-light-green-900 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #33691e;
}
.form-group .checkbox-material-light-green-A100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ccff90;
}
.form-group .checkbox-material-light-green-A200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #b2ff59;
}
.form-group .checkbox-material-light-green-A400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #76ff03;
}
.form-group .checkbox-material-light-green-A700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #64dd17;
}
.form-group .checkbox-material-lime input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #cddc39;
}
.form-group .checkbox-material-lime-50 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #f9fbe7;
}
.form-group .checkbox-material-lime-100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #f0f4c3;
}
.form-group .checkbox-material-lime-200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #e6ee9c;
}
.form-group .checkbox-material-lime-300 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #dce775;
}
.form-group .checkbox-material-lime-400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #d4e157;
}
.form-group .checkbox-material-lime-500 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #cddc39;
}
.form-group .checkbox-material-lime-600 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #c0ca33;
}
.form-group .checkbox-material-lime-700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #afb42b;
}
.form-group .checkbox-material-lime-800 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #9e9d24;
}
.form-group .checkbox-material-lime-900 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #827717;
}
.form-group .checkbox-material-lime-A100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #f4ff81;
}
.form-group .checkbox-material-lime-A200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #eeff41;
}
.form-group .checkbox-material-lime-A400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #c6ff00;
}
.form-group .checkbox-material-lime-A700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #aeea00;
}
.form-group .checkbox-material-yellow input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ffeb3b;
}
.form-group .checkbox-material-yellow-50 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #fffde7;
}
.form-group .checkbox-material-yellow-100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #fff9c4;
}
.form-group .checkbox-material-yellow-200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #fff59d;
}
.form-group .checkbox-material-yellow-300 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #fff176;
}
.form-group .checkbox-material-yellow-400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ffee58;
}
.form-group .checkbox-material-yellow-500 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ffeb3b;
}
.form-group .checkbox-material-yellow-600 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #fdd835;
}
.form-group .checkbox-material-yellow-700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #fbc02d;
}
.form-group .checkbox-material-yellow-800 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #f9a825;
}
.form-group .checkbox-material-yellow-900 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #f57f17;
}
.form-group .checkbox-material-yellow-A100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ffff8d;
}
.form-group .checkbox-material-yellow-A200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ffff00;
}
.form-group .checkbox-material-yellow-A400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ffea00;
}
.form-group .checkbox-material-yellow-A700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ffd600;
}
.form-group .checkbox-material-amber input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ffc107;
}
.form-group .checkbox-material-amber-50 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #fff8e1;
}
.form-group .checkbox-material-amber-100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ffecb3;
}
.form-group .checkbox-material-amber-200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ffe082;
}
.form-group .checkbox-material-amber-300 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ffd54f;
}
.form-group .checkbox-material-amber-400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ffca28;
}
.form-group .checkbox-material-amber-500 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ffc107;
}
.form-group .checkbox-material-amber-600 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ffb300;
}
.form-group .checkbox-material-amber-700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ffa000;
}
.form-group .checkbox-material-amber-800 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ff8f00;
}
.form-group .checkbox-material-amber-900 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ff6f00;
}
.form-group .checkbox-material-amber-A100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ffe57f;
}
.form-group .checkbox-material-amber-A200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ffd740;
}
.form-group .checkbox-material-amber-A400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ffc400;
}
.form-group .checkbox-material-amber-A700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ffab00;
}
.form-group .checkbox-material-orange input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ff9800;
}
.form-group .checkbox-material-orange-50 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #fff3e0;
}
.form-group .checkbox-material-orange-100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ffe0b2;
}
.form-group .checkbox-material-orange-200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ffcc80;
}
.form-group .checkbox-material-orange-300 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ffb74d;
}
.form-group .checkbox-material-orange-400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ffa726;
}
.form-group .checkbox-material-orange-500 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ff9800;
}
.form-group .checkbox-material-orange-600 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #fb8c00;
}
.form-group .checkbox-material-orange-700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #f57c00;
}
.form-group .checkbox-material-orange-800 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ef6c00;
}
.form-group .checkbox-material-orange-900 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #e65100;
}
.form-group .checkbox-material-orange-A100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ffd180;
}
.form-group .checkbox-material-orange-A200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ffab40;
}
.form-group .checkbox-material-orange-A400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ff9100;
}
.form-group .checkbox-material-orange-A700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ff6d00;
}
.form-group .checkbox-material-deep-orange input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ff5722;
}
.form-group .checkbox-material-deep-orange-50 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #fbe9e7;
}
.form-group .checkbox-material-deep-orange-100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ffccbc;
}
.form-group .checkbox-material-deep-orange-200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ffab91;
}
.form-group .checkbox-material-deep-orange-300 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ff8a65;
}
.form-group .checkbox-material-deep-orange-400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ff7043;
}
.form-group .checkbox-material-deep-orange-500 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ff5722;
}
.form-group .checkbox-material-deep-orange-600 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #f4511e;
}
.form-group .checkbox-material-deep-orange-700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #e64a19;
}
.form-group .checkbox-material-deep-orange-800 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #d84315;
}
.form-group .checkbox-material-deep-orange-900 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #bf360c;
}
.form-group .checkbox-material-deep-orange-A100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ff9e80;
}
.form-group .checkbox-material-deep-orange-A200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ff6e40;
}
.form-group .checkbox-material-deep-orange-A400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #ff3d00;
}
.form-group .checkbox-material-deep-orange-A700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #dd2c00;
}
.form-group .checkbox-material-brown input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #795548;
}
.form-group .checkbox-material-brown-50 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #efebe9;
}
.form-group .checkbox-material-brown-100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #d7ccc8;
}
.form-group .checkbox-material-brown-200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #bcaaa4;
}
.form-group .checkbox-material-brown-300 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #a1887f;
}
.form-group .checkbox-material-brown-400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #8d6e63;
}
.form-group .checkbox-material-brown-500 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #795548;
}
.form-group .checkbox-material-brown-600 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #6d4c41;
}
.form-group .checkbox-material-brown-700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #5d4037;
}
.form-group .checkbox-material-brown-800 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #4e342e;
}
.form-group .checkbox-material-brown-900 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #3e2723;
}
.form-group .checkbox-material-brown-A100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #d7ccc8;
}
.form-group .checkbox-material-brown-A200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #bcaaa4;
}
.form-group .checkbox-material-brown-A400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #8d6e63;
}
.form-group .checkbox-material-brown-A700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #5d4037;
}
.form-group .checkbox-material-grey input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #9e9e9e;
}
.form-group .checkbox-material-grey-50 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #fafafa;
}
.form-group .checkbox-material-grey-100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #f5f5f5;
}
.form-group .checkbox-material-grey-200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #eeeeee;
}
.form-group .checkbox-material-grey-300 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #e0e0e0;
}
.form-group .checkbox-material-grey-400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #bdbdbd;
}
.form-group .checkbox-material-grey-500 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #9e9e9e;
}
.form-group .checkbox-material-grey-600 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #757575;
}
.form-group .checkbox-material-grey-700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #616161;
}
.form-group .checkbox-material-grey-800 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #424242;
}
.form-group .checkbox-material-grey-900 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #212121;
}
.form-group .checkbox-material-grey-A100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #f5f5f5;
}
.form-group .checkbox-material-grey-A200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #eeeeee;
}
.form-group .checkbox-material-grey-A400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #bdbdbd;
}
.form-group .checkbox-material-grey-A700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #616161;
}
.form-group .checkbox-material-blue-grey input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #607d8b;
}
.form-group .checkbox-material-blue-grey-50 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #eceff1;
}
.form-group .checkbox-material-blue-grey-100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #cfd8dc;
}
.form-group .checkbox-material-blue-grey-200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #b0bec5;
}
.form-group .checkbox-material-blue-grey-300 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #90a4ae;
}
.form-group .checkbox-material-blue-grey-400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #78909c;
}
.form-group .checkbox-material-blue-grey-500 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #607d8b;
}
.form-group .checkbox-material-blue-grey-600 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #546e7a;
}
.form-group .checkbox-material-blue-grey-700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #455a64;
}
.form-group .checkbox-material-blue-grey-800 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #37474f;
}
.form-group .checkbox-material-blue-grey-900 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #263238;
}
.form-group .checkbox-material-blue-grey-A100 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #cfd8dc;
}
.form-group .checkbox-material-blue-grey-A200 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #b0bec5;
}
.form-group .checkbox-material-blue-grey-A400 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #78909c;
}
.form-group .checkbox-material-blue-grey-A700 input[type=checkbox]:checked + .checkbox-material .check:before {
  color: #455a64;
}
.form-group .checkbox input[type=checkbox]:checked + .checkbox-material .check,
.form-group .checkbox-default input[type=checkbox]:checked + .checkbox-material .check {
  color: #4caf50;
}
.form-group .checkbox-black input[type=checkbox]:checked + .checkbox-material .check {
  color: #000000;
}
.form-group .checkbox-white input[type=checkbox]:checked + .checkbox-material .check {
  color: #ffffff;
}
.form-group .checkbox-inverse input[type=checkbox]:checked + .checkbox-material .check {
  color: #3f51b5;
}
.form-group .checkbox-primary input[type=checkbox]:checked + .checkbox-material .check {
  color: #009688;
}
.form-group .checkbox-success input[type=checkbox]:checked + .checkbox-material .check {
  color: #4caf50;
}
.form-group .checkbox-info input[type=checkbox]:checked + .checkbox-material .check {
  color: #03a9f4;
}
.form-group .checkbox-warning input[type=checkbox]:checked + .checkbox-material .check {
  color: #ff5722;
}
.form-group .checkbox-danger input[type=checkbox]:checked + .checkbox-material .check {
  color: #f44336;
}
.form-group .checkbox-material-red input[type=checkbox]:checked + .checkbox-material .check {
  color: #f44336;
}
.form-group .checkbox-material-red-50 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ffebee;
}
.form-group .checkbox-material-red-100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ffcdd2;
}
.form-group .checkbox-material-red-200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ef9a9a;
}
.form-group .checkbox-material-red-300 input[type=checkbox]:checked + .checkbox-material .check {
  color: #e57373;
}
.form-group .checkbox-material-red-400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ef5350;
}
.form-group .checkbox-material-red-500 input[type=checkbox]:checked + .checkbox-material .check {
  color: #f44336;
}
.form-group .checkbox-material-red-600 input[type=checkbox]:checked + .checkbox-material .check {
  color: #e53935;
}
.form-group .checkbox-material-red-700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #d32f2f;
}
.form-group .checkbox-material-red-800 input[type=checkbox]:checked + .checkbox-material .check {
  color: #c62828;
}
.form-group .checkbox-material-red-900 input[type=checkbox]:checked + .checkbox-material .check {
  color: #b71c1c;
}
.form-group .checkbox-material-red-A100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ff8a80;
}
.form-group .checkbox-material-red-A200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ff5252;
}
.form-group .checkbox-material-red-A400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ff1744;
}
.form-group .checkbox-material-red-A700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #d50000;
}
.form-group .checkbox-material-pink input[type=checkbox]:checked + .checkbox-material .check {
  color: #e91e63;
}
.form-group .checkbox-material-pink-50 input[type=checkbox]:checked + .checkbox-material .check {
  color: #fce4ec;
}
.form-group .checkbox-material-pink-100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #f8bbd0;
}
.form-group .checkbox-material-pink-200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #f48fb1;
}
.form-group .checkbox-material-pink-300 input[type=checkbox]:checked + .checkbox-material .check {
  color: #f06292;
}
.form-group .checkbox-material-pink-400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ec407a;
}
.form-group .checkbox-material-pink-500 input[type=checkbox]:checked + .checkbox-material .check {
  color: #e91e63;
}
.form-group .checkbox-material-pink-600 input[type=checkbox]:checked + .checkbox-material .check {
  color: #d81b60;
}
.form-group .checkbox-material-pink-700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #c2185b;
}
.form-group .checkbox-material-pink-800 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ad1457;
}
.form-group .checkbox-material-pink-900 input[type=checkbox]:checked + .checkbox-material .check {
  color: #880e4f;
}
.form-group .checkbox-material-pink-A100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ff80ab;
}
.form-group .checkbox-material-pink-A200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ff4081;
}
.form-group .checkbox-material-pink-A400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #f50057;
}
.form-group .checkbox-material-pink-A700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #c51162;
}
.form-group .checkbox-material-purple input[type=checkbox]:checked + .checkbox-material .check {
  color: #9c27b0;
}
.form-group .checkbox-material-purple-50 input[type=checkbox]:checked + .checkbox-material .check {
  color: #f3e5f5;
}
.form-group .checkbox-material-purple-100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #e1bee7;
}
.form-group .checkbox-material-purple-200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ce93d8;
}
.form-group .checkbox-material-purple-300 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ba68c8;
}
.form-group .checkbox-material-purple-400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ab47bc;
}
.form-group .checkbox-material-purple-500 input[type=checkbox]:checked + .checkbox-material .check {
  color: #9c27b0;
}
.form-group .checkbox-material-purple-600 input[type=checkbox]:checked + .checkbox-material .check {
  color: #8e24aa;
}
.form-group .checkbox-material-purple-700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #7b1fa2;
}
.form-group .checkbox-material-purple-800 input[type=checkbox]:checked + .checkbox-material .check {
  color: #6a1b9a;
}
.form-group .checkbox-material-purple-900 input[type=checkbox]:checked + .checkbox-material .check {
  color: #4a148c;
}
.form-group .checkbox-material-purple-A100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ea80fc;
}
.form-group .checkbox-material-purple-A200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #e040fb;
}
.form-group .checkbox-material-purple-A400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #d500f9;
}
.form-group .checkbox-material-purple-A700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #aa00ff;
}
.form-group .checkbox-material-deep-purple input[type=checkbox]:checked + .checkbox-material .check {
  color: #673ab7;
}
.form-group .checkbox-material-deep-purple-50 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ede7f6;
}
.form-group .checkbox-material-deep-purple-100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #d1c4e9;
}
.form-group .checkbox-material-deep-purple-200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #b39ddb;
}
.form-group .checkbox-material-deep-purple-300 input[type=checkbox]:checked + .checkbox-material .check {
  color: #9575cd;
}
.form-group .checkbox-material-deep-purple-400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #7e57c2;
}
.form-group .checkbox-material-deep-purple-500 input[type=checkbox]:checked + .checkbox-material .check {
  color: #673ab7;
}
.form-group .checkbox-material-deep-purple-600 input[type=checkbox]:checked + .checkbox-material .check {
  color: #5e35b1;
}
.form-group .checkbox-material-deep-purple-700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #512da8;
}
.form-group .checkbox-material-deep-purple-800 input[type=checkbox]:checked + .checkbox-material .check {
  color: #4527a0;
}
.form-group .checkbox-material-deep-purple-900 input[type=checkbox]:checked + .checkbox-material .check {
  color: #311b92;
}
.form-group .checkbox-material-deep-purple-A100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #b388ff;
}
.form-group .checkbox-material-deep-purple-A200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #7c4dff;
}
.form-group .checkbox-material-deep-purple-A400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #651fff;
}
.form-group .checkbox-material-deep-purple-A700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #6200ea;
}
.form-group .checkbox-material-indigo input[type=checkbox]:checked + .checkbox-material .check {
  color: #3f51b5;
}
.form-group .checkbox-material-indigo-50 input[type=checkbox]:checked + .checkbox-material .check {
  color: #e8eaf6;
}
.form-group .checkbox-material-indigo-100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #c5cae9;
}
.form-group .checkbox-material-indigo-200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #9fa8da;
}
.form-group .checkbox-material-indigo-300 input[type=checkbox]:checked + .checkbox-material .check {
  color: #7986cb;
}
.form-group .checkbox-material-indigo-400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #5c6bc0;
}
.form-group .checkbox-material-indigo-500 input[type=checkbox]:checked + .checkbox-material .check {
  color: #3f51b5;
}
.form-group .checkbox-material-indigo-600 input[type=checkbox]:checked + .checkbox-material .check {
  color: #3949ab;
}
.form-group .checkbox-material-indigo-700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #303f9f;
}
.form-group .checkbox-material-indigo-800 input[type=checkbox]:checked + .checkbox-material .check {
  color: #283593;
}
.form-group .checkbox-material-indigo-900 input[type=checkbox]:checked + .checkbox-material .check {
  color: #1a237e;
}
.form-group .checkbox-material-indigo-A100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #8c9eff;
}
.form-group .checkbox-material-indigo-A200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #536dfe;
}
.form-group .checkbox-material-indigo-A400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #3d5afe;
}
.form-group .checkbox-material-indigo-A700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #304ffe;
}
.form-group .checkbox-material-blue input[type=checkbox]:checked + .checkbox-material .check {
  color: #2196f3;
}
.form-group .checkbox-material-blue-50 input[type=checkbox]:checked + .checkbox-material .check {
  color: #e3f2fd;
}
.form-group .checkbox-material-blue-100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #bbdefb;
}
.form-group .checkbox-material-blue-200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #90caf9;
}
.form-group .checkbox-material-blue-300 input[type=checkbox]:checked + .checkbox-material .check {
  color: #64b5f6;
}
.form-group .checkbox-material-blue-400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #42a5f5;
}
.form-group .checkbox-material-blue-500 input[type=checkbox]:checked + .checkbox-material .check {
  color: #2196f3;
}
.form-group .checkbox-material-blue-600 input[type=checkbox]:checked + .checkbox-material .check {
  color: #1e88e5;
}
.form-group .checkbox-material-blue-700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #1976d2;
}
.form-group .checkbox-material-blue-800 input[type=checkbox]:checked + .checkbox-material .check {
  color: #1565c0;
}
.form-group .checkbox-material-blue-900 input[type=checkbox]:checked + .checkbox-material .check {
  color: #0d47a1;
}
.form-group .checkbox-material-blue-A100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #82b1ff;
}
.form-group .checkbox-material-blue-A200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #448aff;
}
.form-group .checkbox-material-blue-A400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #2979ff;
}
.form-group .checkbox-material-blue-A700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #2962ff;
}
.form-group .checkbox-material-light-blue input[type=checkbox]:checked + .checkbox-material .check {
  color: #03a9f4;
}
.form-group .checkbox-material-light-blue-50 input[type=checkbox]:checked + .checkbox-material .check {
  color: #e1f5fe;
}
.form-group .checkbox-material-light-blue-100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #b3e5fc;
}
.form-group .checkbox-material-light-blue-200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #81d4fa;
}
.form-group .checkbox-material-light-blue-300 input[type=checkbox]:checked + .checkbox-material .check {
  color: #4fc3f7;
}
.form-group .checkbox-material-light-blue-400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #29b6f6;
}
.form-group .checkbox-material-light-blue-500 input[type=checkbox]:checked + .checkbox-material .check {
  color: #03a9f4;
}
.form-group .checkbox-material-light-blue-600 input[type=checkbox]:checked + .checkbox-material .check {
  color: #039be5;
}
.form-group .checkbox-material-light-blue-700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #0288d1;
}
.form-group .checkbox-material-light-blue-800 input[type=checkbox]:checked + .checkbox-material .check {
  color: #0277bd;
}
.form-group .checkbox-material-light-blue-900 input[type=checkbox]:checked + .checkbox-material .check {
  color: #01579b;
}
.form-group .checkbox-material-light-blue-A100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #80d8ff;
}
.form-group .checkbox-material-light-blue-A200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #40c4ff;
}
.form-group .checkbox-material-light-blue-A400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #00b0ff;
}
.form-group .checkbox-material-light-blue-A700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #0091ea;
}
.form-group .checkbox-material-cyan input[type=checkbox]:checked + .checkbox-material .check {
  color: #00bcd4;
}
.form-group .checkbox-material-cyan-50 input[type=checkbox]:checked + .checkbox-material .check {
  color: #e0f7fa;
}
.form-group .checkbox-material-cyan-100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #b2ebf2;
}
.form-group .checkbox-material-cyan-200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #80deea;
}
.form-group .checkbox-material-cyan-300 input[type=checkbox]:checked + .checkbox-material .check {
  color: #4dd0e1;
}
.form-group .checkbox-material-cyan-400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #26c6da;
}
.form-group .checkbox-material-cyan-500 input[type=checkbox]:checked + .checkbox-material .check {
  color: #00bcd4;
}
.form-group .checkbox-material-cyan-600 input[type=checkbox]:checked + .checkbox-material .check {
  color: #00acc1;
}
.form-group .checkbox-material-cyan-700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #0097a7;
}
.form-group .checkbox-material-cyan-800 input[type=checkbox]:checked + .checkbox-material .check {
  color: #00838f;
}
.form-group .checkbox-material-cyan-900 input[type=checkbox]:checked + .checkbox-material .check {
  color: #006064;
}
.form-group .checkbox-material-cyan-A100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #84ffff;
}
.form-group .checkbox-material-cyan-A200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #18ffff;
}
.form-group .checkbox-material-cyan-A400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #00e5ff;
}
.form-group .checkbox-material-cyan-A700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #00b8d4;
}
.form-group .checkbox-material-teal input[type=checkbox]:checked + .checkbox-material .check {
  color: #009688;
}
.form-group .checkbox-material-teal-50 input[type=checkbox]:checked + .checkbox-material .check {
  color: #e0f2f1;
}
.form-group .checkbox-material-teal-100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #b2dfdb;
}
.form-group .checkbox-material-teal-200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #80cbc4;
}
.form-group .checkbox-material-teal-300 input[type=checkbox]:checked + .checkbox-material .check {
  color: #4db6ac;
}
.form-group .checkbox-material-teal-400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #26a69a;
}
.form-group .checkbox-material-teal-500 input[type=checkbox]:checked + .checkbox-material .check {
  color: #009688;
}
.form-group .checkbox-material-teal-600 input[type=checkbox]:checked + .checkbox-material .check {
  color: #00897b;
}
.form-group .checkbox-material-teal-700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #00796b;
}
.form-group .checkbox-material-teal-800 input[type=checkbox]:checked + .checkbox-material .check {
  color: #00695c;
}
.form-group .checkbox-material-teal-900 input[type=checkbox]:checked + .checkbox-material .check {
  color: #004d40;
}
.form-group .checkbox-material-teal-A100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #a7ffeb;
}
.form-group .checkbox-material-teal-A200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #64ffda;
}
.form-group .checkbox-material-teal-A400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #1de9b6;
}
.form-group .checkbox-material-teal-A700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #00bfa5;
}
.form-group .checkbox-material-green input[type=checkbox]:checked + .checkbox-material .check {
  color: #4caf50;
}
.form-group .checkbox-material-green-50 input[type=checkbox]:checked + .checkbox-material .check {
  color: #e8f5e9;
}
.form-group .checkbox-material-green-100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #c8e6c9;
}
.form-group .checkbox-material-green-200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #a5d6a7;
}
.form-group .checkbox-material-green-300 input[type=checkbox]:checked + .checkbox-material .check {
  color: #81c784;
}
.form-group .checkbox-material-green-400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #66bb6a;
}
.form-group .checkbox-material-green-500 input[type=checkbox]:checked + .checkbox-material .check {
  color: #4caf50;
}
.form-group .checkbox-material-green-600 input[type=checkbox]:checked + .checkbox-material .check {
  color: #43a047;
}
.form-group .checkbox-material-green-700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #388e3c;
}
.form-group .checkbox-material-green-800 input[type=checkbox]:checked + .checkbox-material .check {
  color: #2e7d32;
}
.form-group .checkbox-material-green-900 input[type=checkbox]:checked + .checkbox-material .check {
  color: #1b5e20;
}
.form-group .checkbox-material-green-A100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #b9f6ca;
}
.form-group .checkbox-material-green-A200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #69f0ae;
}
.form-group .checkbox-material-green-A400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #00e676;
}
.form-group .checkbox-material-green-A700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #00c853;
}
.form-group .checkbox-material-light-green input[type=checkbox]:checked + .checkbox-material .check {
  color: #8bc34a;
}
.form-group .checkbox-material-light-green-50 input[type=checkbox]:checked + .checkbox-material .check {
  color: #f1f8e9;
}
.form-group .checkbox-material-light-green-100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #dcedc8;
}
.form-group .checkbox-material-light-green-200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #c5e1a5;
}
.form-group .checkbox-material-light-green-300 input[type=checkbox]:checked + .checkbox-material .check {
  color: #aed581;
}
.form-group .checkbox-material-light-green-400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #9ccc65;
}
.form-group .checkbox-material-light-green-500 input[type=checkbox]:checked + .checkbox-material .check {
  color: #8bc34a;
}
.form-group .checkbox-material-light-green-600 input[type=checkbox]:checked + .checkbox-material .check {
  color: #7cb342;
}
.form-group .checkbox-material-light-green-700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #689f38;
}
.form-group .checkbox-material-light-green-800 input[type=checkbox]:checked + .checkbox-material .check {
  color: #558b2f;
}
.form-group .checkbox-material-light-green-900 input[type=checkbox]:checked + .checkbox-material .check {
  color: #33691e;
}
.form-group .checkbox-material-light-green-A100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ccff90;
}
.form-group .checkbox-material-light-green-A200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #b2ff59;
}
.form-group .checkbox-material-light-green-A400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #76ff03;
}
.form-group .checkbox-material-light-green-A700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #64dd17;
}
.form-group .checkbox-material-lime input[type=checkbox]:checked + .checkbox-material .check {
  color: #cddc39;
}
.form-group .checkbox-material-lime-50 input[type=checkbox]:checked + .checkbox-material .check {
  color: #f9fbe7;
}
.form-group .checkbox-material-lime-100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #f0f4c3;
}
.form-group .checkbox-material-lime-200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #e6ee9c;
}
.form-group .checkbox-material-lime-300 input[type=checkbox]:checked + .checkbox-material .check {
  color: #dce775;
}
.form-group .checkbox-material-lime-400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #d4e157;
}
.form-group .checkbox-material-lime-500 input[type=checkbox]:checked + .checkbox-material .check {
  color: #cddc39;
}
.form-group .checkbox-material-lime-600 input[type=checkbox]:checked + .checkbox-material .check {
  color: #c0ca33;
}
.form-group .checkbox-material-lime-700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #afb42b;
}
.form-group .checkbox-material-lime-800 input[type=checkbox]:checked + .checkbox-material .check {
  color: #9e9d24;
}
.form-group .checkbox-material-lime-900 input[type=checkbox]:checked + .checkbox-material .check {
  color: #827717;
}
.form-group .checkbox-material-lime-A100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #f4ff81;
}
.form-group .checkbox-material-lime-A200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #eeff41;
}
.form-group .checkbox-material-lime-A400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #c6ff00;
}
.form-group .checkbox-material-lime-A700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #aeea00;
}
.form-group .checkbox-material-yellow input[type=checkbox]:checked + .checkbox-material .check {
  color: #ffeb3b;
}
.form-group .checkbox-material-yellow-50 input[type=checkbox]:checked + .checkbox-material .check {
  color: #fffde7;
}
.form-group .checkbox-material-yellow-100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #fff9c4;
}
.form-group .checkbox-material-yellow-200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #fff59d;
}
.form-group .checkbox-material-yellow-300 input[type=checkbox]:checked + .checkbox-material .check {
  color: #fff176;
}
.form-group .checkbox-material-yellow-400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ffee58;
}
.form-group .checkbox-material-yellow-500 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ffeb3b;
}
.form-group .checkbox-material-yellow-600 input[type=checkbox]:checked + .checkbox-material .check {
  color: #fdd835;
}
.form-group .checkbox-material-yellow-700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #fbc02d;
}
.form-group .checkbox-material-yellow-800 input[type=checkbox]:checked + .checkbox-material .check {
  color: #f9a825;
}
.form-group .checkbox-material-yellow-900 input[type=checkbox]:checked + .checkbox-material .check {
  color: #f57f17;
}
.form-group .checkbox-material-yellow-A100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ffff8d;
}
.form-group .checkbox-material-yellow-A200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ffff00;
}
.form-group .checkbox-material-yellow-A400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ffea00;
}
.form-group .checkbox-material-yellow-A700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ffd600;
}
.form-group .checkbox-material-amber input[type=checkbox]:checked + .checkbox-material .check {
  color: #ffc107;
}
.form-group .checkbox-material-amber-50 input[type=checkbox]:checked + .checkbox-material .check {
  color: #fff8e1;
}
.form-group .checkbox-material-amber-100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ffecb3;
}
.form-group .checkbox-material-amber-200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ffe082;
}
.form-group .checkbox-material-amber-300 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ffd54f;
}
.form-group .checkbox-material-amber-400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ffca28;
}
.form-group .checkbox-material-amber-500 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ffc107;
}
.form-group .checkbox-material-amber-600 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ffb300;
}
.form-group .checkbox-material-amber-700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ffa000;
}
.form-group .checkbox-material-amber-800 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ff8f00;
}
.form-group .checkbox-material-amber-900 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ff6f00;
}
.form-group .checkbox-material-amber-A100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ffe57f;
}
.form-group .checkbox-material-amber-A200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ffd740;
}
.form-group .checkbox-material-amber-A400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ffc400;
}
.form-group .checkbox-material-amber-A700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ffab00;
}
.form-group .checkbox-material-orange input[type=checkbox]:checked + .checkbox-material .check {
  color: #ff9800;
}
.form-group .checkbox-material-orange-50 input[type=checkbox]:checked + .checkbox-material .check {
  color: #fff3e0;
}
.form-group .checkbox-material-orange-100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ffe0b2;
}
.form-group .checkbox-material-orange-200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ffcc80;
}
.form-group .checkbox-material-orange-300 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ffb74d;
}
.form-group .checkbox-material-orange-400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ffa726;
}
.form-group .checkbox-material-orange-500 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ff9800;
}
.form-group .checkbox-material-orange-600 input[type=checkbox]:checked + .checkbox-material .check {
  color: #fb8c00;
}
.form-group .checkbox-material-orange-700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #f57c00;
}
.form-group .checkbox-material-orange-800 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ef6c00;
}
.form-group .checkbox-material-orange-900 input[type=checkbox]:checked + .checkbox-material .check {
  color: #e65100;
}
.form-group .checkbox-material-orange-A100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ffd180;
}
.form-group .checkbox-material-orange-A200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ffab40;
}
.form-group .checkbox-material-orange-A400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ff9100;
}
.form-group .checkbox-material-orange-A700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ff6d00;
}
.form-group .checkbox-material-deep-orange input[type=checkbox]:checked + .checkbox-material .check {
  color: #ff5722;
}
.form-group .checkbox-material-deep-orange-50 input[type=checkbox]:checked + .checkbox-material .check {
  color: #fbe9e7;
}
.form-group .checkbox-material-deep-orange-100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ffccbc;
}
.form-group .checkbox-material-deep-orange-200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ffab91;
}
.form-group .checkbox-material-deep-orange-300 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ff8a65;
}
.form-group .checkbox-material-deep-orange-400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ff7043;
}
.form-group .checkbox-material-deep-orange-500 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ff5722;
}
.form-group .checkbox-material-deep-orange-600 input[type=checkbox]:checked + .checkbox-material .check {
  color: #f4511e;
}
.form-group .checkbox-material-deep-orange-700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #e64a19;
}
.form-group .checkbox-material-deep-orange-800 input[type=checkbox]:checked + .checkbox-material .check {
  color: #d84315;
}
.form-group .checkbox-material-deep-orange-900 input[type=checkbox]:checked + .checkbox-material .check {
  color: #bf360c;
}
.form-group .checkbox-material-deep-orange-A100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ff9e80;
}
.form-group .checkbox-material-deep-orange-A200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ff6e40;
}
.form-group .checkbox-material-deep-orange-A400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #ff3d00;
}
.form-group .checkbox-material-deep-orange-A700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #dd2c00;
}
.form-group .checkbox-material-brown input[type=checkbox]:checked + .checkbox-material .check {
  color: #795548;
}
.form-group .checkbox-material-brown-50 input[type=checkbox]:checked + .checkbox-material .check {
  color: #efebe9;
}
.form-group .checkbox-material-brown-100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #d7ccc8;
}
.form-group .checkbox-material-brown-200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #bcaaa4;
}
.form-group .checkbox-material-brown-300 input[type=checkbox]:checked + .checkbox-material .check {
  color: #a1887f;
}
.form-group .checkbox-material-brown-400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #8d6e63;
}
.form-group .checkbox-material-brown-500 input[type=checkbox]:checked + .checkbox-material .check {
  color: #795548;
}
.form-group .checkbox-material-brown-600 input[type=checkbox]:checked + .checkbox-material .check {
  color: #6d4c41;
}
.form-group .checkbox-material-brown-700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #5d4037;
}
.form-group .checkbox-material-brown-800 input[type=checkbox]:checked + .checkbox-material .check {
  color: #4e342e;
}
.form-group .checkbox-material-brown-900 input[type=checkbox]:checked + .checkbox-material .check {
  color: #3e2723;
}
.form-group .checkbox-material-brown-A100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #d7ccc8;
}
.form-group .checkbox-material-brown-A200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #bcaaa4;
}
.form-group .checkbox-material-brown-A400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #8d6e63;
}
.form-group .checkbox-material-brown-A700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #5d4037;
}
.form-group .checkbox-material-grey input[type=checkbox]:checked + .checkbox-material .check {
  color: #9e9e9e;
}
.form-group .checkbox-material-grey-50 input[type=checkbox]:checked + .checkbox-material .check {
  color: #fafafa;
}
.form-group .checkbox-material-grey-100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #f5f5f5;
}
.form-group .checkbox-material-grey-200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #eeeeee;
}
.form-group .checkbox-material-grey-300 input[type=checkbox]:checked + .checkbox-material .check {
  color: #e0e0e0;
}
.form-group .checkbox-material-grey-400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #bdbdbd;
}
.form-group .checkbox-material-grey-500 input[type=checkbox]:checked + .checkbox-material .check {
  color: #9e9e9e;
}
.form-group .checkbox-material-grey-600 input[type=checkbox]:checked + .checkbox-material .check {
  color: #757575;
}
.form-group .checkbox-material-grey-700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #616161;
}
.form-group .checkbox-material-grey-800 input[type=checkbox]:checked + .checkbox-material .check {
  color: #424242;
}
.form-group .checkbox-material-grey-900 input[type=checkbox]:checked + .checkbox-material .check {
  color: #212121;
}
.form-group .checkbox-material-grey-A100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #f5f5f5;
}
.form-group .checkbox-material-grey-A200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #eeeeee;
}
.form-group .checkbox-material-grey-A400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #bdbdbd;
}
.form-group .checkbox-material-grey-A700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #616161;
}
.form-group .checkbox-material-blue-grey input[type=checkbox]:checked + .checkbox-material .check {
  color: #607d8b;
}
.form-group .checkbox-material-blue-grey-50 input[type=checkbox]:checked + .checkbox-material .check {
  color: #eceff1;
}
.form-group .checkbox-material-blue-grey-100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #cfd8dc;
}
.form-group .checkbox-material-blue-grey-200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #b0bec5;
}
.form-group .checkbox-material-blue-grey-300 input[type=checkbox]:checked + .checkbox-material .check {
  color: #90a4ae;
}
.form-group .checkbox-material-blue-grey-400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #78909c;
}
.form-group .checkbox-material-blue-grey-500 input[type=checkbox]:checked + .checkbox-material .check {
  color: #607d8b;
}
.form-group .checkbox-material-blue-grey-600 input[type=checkbox]:checked + .checkbox-material .check {
  color: #546e7a;
}
.form-group .checkbox-material-blue-grey-700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #455a64;
}
.form-group .checkbox-material-blue-grey-800 input[type=checkbox]:checked + .checkbox-material .check {
  color: #37474f;
}
.form-group .checkbox-material-blue-grey-900 input[type=checkbox]:checked + .checkbox-material .check {
  color: #263238;
}
.form-group .checkbox-material-blue-grey-A100 input[type=checkbox]:checked + .checkbox-material .check {
  color: #cfd8dc;
}
.form-group .checkbox-material-blue-grey-A200 input[type=checkbox]:checked + .checkbox-material .check {
  color: #b0bec5;
}
.form-group .checkbox-material-blue-grey-A400 input[type=checkbox]:checked + .checkbox-material .check {
  color: #78909c;
}
.form-group .checkbox-material-blue-grey-A700 input[type=checkbox]:checked + .checkbox-material .check {
  color: #455a64;
}
.form-group .checkbox input[type=checkbox]:checked + .checkbox-material .check,
.form-group .checkbox-default input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #4caf50;
}
.form-group .checkbox-black input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #000000;
}
.form-group .checkbox-white input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ffffff;
}
.form-group .checkbox-inverse input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #3f51b5;
}
.form-group .checkbox-primary input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #009688;
}
.form-group .checkbox-success input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #4caf50;
}
.form-group .checkbox-info input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #03a9f4;
}
.form-group .checkbox-warning input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ff5722;
}
.form-group .checkbox-danger input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #f44336;
}
.form-group .checkbox-material-red input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #f44336;
}
.form-group .checkbox-material-red-50 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ffebee;
}
.form-group .checkbox-material-red-100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ffcdd2;
}
.form-group .checkbox-material-red-200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ef9a9a;
}
.form-group .checkbox-material-red-300 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #e57373;
}
.form-group .checkbox-material-red-400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ef5350;
}
.form-group .checkbox-material-red-500 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #f44336;
}
.form-group .checkbox-material-red-600 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #e53935;
}
.form-group .checkbox-material-red-700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #d32f2f;
}
.form-group .checkbox-material-red-800 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #c62828;
}
.form-group .checkbox-material-red-900 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #b71c1c;
}
.form-group .checkbox-material-red-A100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ff8a80;
}
.form-group .checkbox-material-red-A200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ff5252;
}
.form-group .checkbox-material-red-A400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ff1744;
}
.form-group .checkbox-material-red-A700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #d50000;
}
.form-group .checkbox-material-pink input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #e91e63;
}
.form-group .checkbox-material-pink-50 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #fce4ec;
}
.form-group .checkbox-material-pink-100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #f8bbd0;
}
.form-group .checkbox-material-pink-200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #f48fb1;
}
.form-group .checkbox-material-pink-300 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #f06292;
}
.form-group .checkbox-material-pink-400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ec407a;
}
.form-group .checkbox-material-pink-500 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #e91e63;
}
.form-group .checkbox-material-pink-600 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #d81b60;
}
.form-group .checkbox-material-pink-700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #c2185b;
}
.form-group .checkbox-material-pink-800 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ad1457;
}
.form-group .checkbox-material-pink-900 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #880e4f;
}
.form-group .checkbox-material-pink-A100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ff80ab;
}
.form-group .checkbox-material-pink-A200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ff4081;
}
.form-group .checkbox-material-pink-A400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #f50057;
}
.form-group .checkbox-material-pink-A700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #c51162;
}
.form-group .checkbox-material-purple input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #9c27b0;
}
.form-group .checkbox-material-purple-50 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #f3e5f5;
}
.form-group .checkbox-material-purple-100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #e1bee7;
}
.form-group .checkbox-material-purple-200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ce93d8;
}
.form-group .checkbox-material-purple-300 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ba68c8;
}
.form-group .checkbox-material-purple-400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ab47bc;
}
.form-group .checkbox-material-purple-500 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #9c27b0;
}
.form-group .checkbox-material-purple-600 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #8e24aa;
}
.form-group .checkbox-material-purple-700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #7b1fa2;
}
.form-group .checkbox-material-purple-800 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #6a1b9a;
}
.form-group .checkbox-material-purple-900 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #4a148c;
}
.form-group .checkbox-material-purple-A100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ea80fc;
}
.form-group .checkbox-material-purple-A200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #e040fb;
}
.form-group .checkbox-material-purple-A400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #d500f9;
}
.form-group .checkbox-material-purple-A700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #aa00ff;
}
.form-group .checkbox-material-deep-purple input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #673ab7;
}
.form-group .checkbox-material-deep-purple-50 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ede7f6;
}
.form-group .checkbox-material-deep-purple-100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #d1c4e9;
}
.form-group .checkbox-material-deep-purple-200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #b39ddb;
}
.form-group .checkbox-material-deep-purple-300 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #9575cd;
}
.form-group .checkbox-material-deep-purple-400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #7e57c2;
}
.form-group .checkbox-material-deep-purple-500 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #673ab7;
}
.form-group .checkbox-material-deep-purple-600 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #5e35b1;
}
.form-group .checkbox-material-deep-purple-700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #512da8;
}
.form-group .checkbox-material-deep-purple-800 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #4527a0;
}
.form-group .checkbox-material-deep-purple-900 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #311b92;
}
.form-group .checkbox-material-deep-purple-A100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #b388ff;
}
.form-group .checkbox-material-deep-purple-A200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #7c4dff;
}
.form-group .checkbox-material-deep-purple-A400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #651fff;
}
.form-group .checkbox-material-deep-purple-A700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #6200ea;
}
.form-group .checkbox-material-indigo input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #3f51b5;
}
.form-group .checkbox-material-indigo-50 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #e8eaf6;
}
.form-group .checkbox-material-indigo-100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #c5cae9;
}
.form-group .checkbox-material-indigo-200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #9fa8da;
}
.form-group .checkbox-material-indigo-300 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #7986cb;
}
.form-group .checkbox-material-indigo-400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #5c6bc0;
}
.form-group .checkbox-material-indigo-500 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #3f51b5;
}
.form-group .checkbox-material-indigo-600 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #3949ab;
}
.form-group .checkbox-material-indigo-700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #303f9f;
}
.form-group .checkbox-material-indigo-800 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #283593;
}
.form-group .checkbox-material-indigo-900 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #1a237e;
}
.form-group .checkbox-material-indigo-A100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #8c9eff;
}
.form-group .checkbox-material-indigo-A200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #536dfe;
}
.form-group .checkbox-material-indigo-A400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #3d5afe;
}
.form-group .checkbox-material-indigo-A700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #304ffe;
}
.form-group .checkbox-material-blue input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #2196f3;
}
.form-group .checkbox-material-blue-50 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #e3f2fd;
}
.form-group .checkbox-material-blue-100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #bbdefb;
}
.form-group .checkbox-material-blue-200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #90caf9;
}
.form-group .checkbox-material-blue-300 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #64b5f6;
}
.form-group .checkbox-material-blue-400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #42a5f5;
}
.form-group .checkbox-material-blue-500 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #2196f3;
}
.form-group .checkbox-material-blue-600 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #1e88e5;
}
.form-group .checkbox-material-blue-700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #1976d2;
}
.form-group .checkbox-material-blue-800 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #1565c0;
}
.form-group .checkbox-material-blue-900 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #0d47a1;
}
.form-group .checkbox-material-blue-A100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #82b1ff;
}
.form-group .checkbox-material-blue-A200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #448aff;
}
.form-group .checkbox-material-blue-A400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #2979ff;
}
.form-group .checkbox-material-blue-A700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #2962ff;
}
.form-group .checkbox-material-light-blue input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #03a9f4;
}
.form-group .checkbox-material-light-blue-50 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #e1f5fe;
}
.form-group .checkbox-material-light-blue-100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #b3e5fc;
}
.form-group .checkbox-material-light-blue-200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #81d4fa;
}
.form-group .checkbox-material-light-blue-300 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #4fc3f7;
}
.form-group .checkbox-material-light-blue-400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #29b6f6;
}
.form-group .checkbox-material-light-blue-500 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #03a9f4;
}
.form-group .checkbox-material-light-blue-600 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #039be5;
}
.form-group .checkbox-material-light-blue-700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #0288d1;
}
.form-group .checkbox-material-light-blue-800 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #0277bd;
}
.form-group .checkbox-material-light-blue-900 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #01579b;
}
.form-group .checkbox-material-light-blue-A100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #80d8ff;
}
.form-group .checkbox-material-light-blue-A200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #40c4ff;
}
.form-group .checkbox-material-light-blue-A400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #00b0ff;
}
.form-group .checkbox-material-light-blue-A700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #0091ea;
}
.form-group .checkbox-material-cyan input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #00bcd4;
}
.form-group .checkbox-material-cyan-50 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #e0f7fa;
}
.form-group .checkbox-material-cyan-100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #b2ebf2;
}
.form-group .checkbox-material-cyan-200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #80deea;
}
.form-group .checkbox-material-cyan-300 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #4dd0e1;
}
.form-group .checkbox-material-cyan-400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #26c6da;
}
.form-group .checkbox-material-cyan-500 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #00bcd4;
}
.form-group .checkbox-material-cyan-600 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #00acc1;
}
.form-group .checkbox-material-cyan-700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #0097a7;
}
.form-group .checkbox-material-cyan-800 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #00838f;
}
.form-group .checkbox-material-cyan-900 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #006064;
}
.form-group .checkbox-material-cyan-A100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #84ffff;
}
.form-group .checkbox-material-cyan-A200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #18ffff;
}
.form-group .checkbox-material-cyan-A400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #00e5ff;
}
.form-group .checkbox-material-cyan-A700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #00b8d4;
}
.form-group .checkbox-material-teal input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #009688;
}
.form-group .checkbox-material-teal-50 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #e0f2f1;
}
.form-group .checkbox-material-teal-100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #b2dfdb;
}
.form-group .checkbox-material-teal-200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #80cbc4;
}
.form-group .checkbox-material-teal-300 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #4db6ac;
}
.form-group .checkbox-material-teal-400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #26a69a;
}
.form-group .checkbox-material-teal-500 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #009688;
}
.form-group .checkbox-material-teal-600 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #00897b;
}
.form-group .checkbox-material-teal-700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #00796b;
}
.form-group .checkbox-material-teal-800 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #00695c;
}
.form-group .checkbox-material-teal-900 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #004d40;
}
.form-group .checkbox-material-teal-A100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #a7ffeb;
}
.form-group .checkbox-material-teal-A200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #64ffda;
}
.form-group .checkbox-material-teal-A400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #1de9b6;
}
.form-group .checkbox-material-teal-A700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #00bfa5;
}
.form-group .checkbox-material-green input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #4caf50;
}
.form-group .checkbox-material-green-50 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #e8f5e9;
}
.form-group .checkbox-material-green-100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #c8e6c9;
}
.form-group .checkbox-material-green-200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #a5d6a7;
}
.form-group .checkbox-material-green-300 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #81c784;
}
.form-group .checkbox-material-green-400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #66bb6a;
}
.form-group .checkbox-material-green-500 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #4caf50;
}
.form-group .checkbox-material-green-600 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #43a047;
}
.form-group .checkbox-material-green-700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #388e3c;
}
.form-group .checkbox-material-green-800 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #2e7d32;
}
.form-group .checkbox-material-green-900 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #1b5e20;
}
.form-group .checkbox-material-green-A100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #b9f6ca;
}
.form-group .checkbox-material-green-A200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #69f0ae;
}
.form-group .checkbox-material-green-A400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #00e676;
}
.form-group .checkbox-material-green-A700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #00c853;
}
.form-group .checkbox-material-light-green input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #8bc34a;
}
.form-group .checkbox-material-light-green-50 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #f1f8e9;
}
.form-group .checkbox-material-light-green-100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #dcedc8;
}
.form-group .checkbox-material-light-green-200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #c5e1a5;
}
.form-group .checkbox-material-light-green-300 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #aed581;
}
.form-group .checkbox-material-light-green-400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #9ccc65;
}
.form-group .checkbox-material-light-green-500 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #8bc34a;
}
.form-group .checkbox-material-light-green-600 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #7cb342;
}
.form-group .checkbox-material-light-green-700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #689f38;
}
.form-group .checkbox-material-light-green-800 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #558b2f;
}
.form-group .checkbox-material-light-green-900 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #33691e;
}
.form-group .checkbox-material-light-green-A100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ccff90;
}
.form-group .checkbox-material-light-green-A200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #b2ff59;
}
.form-group .checkbox-material-light-green-A400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #76ff03;
}
.form-group .checkbox-material-light-green-A700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #64dd17;
}
.form-group .checkbox-material-lime input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #cddc39;
}
.form-group .checkbox-material-lime-50 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #f9fbe7;
}
.form-group .checkbox-material-lime-100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #f0f4c3;
}
.form-group .checkbox-material-lime-200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #e6ee9c;
}
.form-group .checkbox-material-lime-300 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #dce775;
}
.form-group .checkbox-material-lime-400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #d4e157;
}
.form-group .checkbox-material-lime-500 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #cddc39;
}
.form-group .checkbox-material-lime-600 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #c0ca33;
}
.form-group .checkbox-material-lime-700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #afb42b;
}
.form-group .checkbox-material-lime-800 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #9e9d24;
}
.form-group .checkbox-material-lime-900 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #827717;
}
.form-group .checkbox-material-lime-A100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #f4ff81;
}
.form-group .checkbox-material-lime-A200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #eeff41;
}
.form-group .checkbox-material-lime-A400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #c6ff00;
}
.form-group .checkbox-material-lime-A700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #aeea00;
}
.form-group .checkbox-material-yellow input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ffeb3b;
}
.form-group .checkbox-material-yellow-50 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #fffde7;
}
.form-group .checkbox-material-yellow-100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #fff9c4;
}
.form-group .checkbox-material-yellow-200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #fff59d;
}
.form-group .checkbox-material-yellow-300 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #fff176;
}
.form-group .checkbox-material-yellow-400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ffee58;
}
.form-group .checkbox-material-yellow-500 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ffeb3b;
}
.form-group .checkbox-material-yellow-600 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #fdd835;
}
.form-group .checkbox-material-yellow-700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #fbc02d;
}
.form-group .checkbox-material-yellow-800 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #f9a825;
}
.form-group .checkbox-material-yellow-900 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #f57f17;
}
.form-group .checkbox-material-yellow-A100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ffff8d;
}
.form-group .checkbox-material-yellow-A200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ffff00;
}
.form-group .checkbox-material-yellow-A400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ffea00;
}
.form-group .checkbox-material-yellow-A700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ffd600;
}
.form-group .checkbox-material-amber input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ffc107;
}
.form-group .checkbox-material-amber-50 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #fff8e1;
}
.form-group .checkbox-material-amber-100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ffecb3;
}
.form-group .checkbox-material-amber-200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ffe082;
}
.form-group .checkbox-material-amber-300 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ffd54f;
}
.form-group .checkbox-material-amber-400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ffca28;
}
.form-group .checkbox-material-amber-500 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ffc107;
}
.form-group .checkbox-material-amber-600 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ffb300;
}
.form-group .checkbox-material-amber-700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ffa000;
}
.form-group .checkbox-material-amber-800 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ff8f00;
}
.form-group .checkbox-material-amber-900 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ff6f00;
}
.form-group .checkbox-material-amber-A100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ffe57f;
}
.form-group .checkbox-material-amber-A200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ffd740;
}
.form-group .checkbox-material-amber-A400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ffc400;
}
.form-group .checkbox-material-amber-A700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ffab00;
}
.form-group .checkbox-material-orange input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ff9800;
}
.form-group .checkbox-material-orange-50 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #fff3e0;
}
.form-group .checkbox-material-orange-100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ffe0b2;
}
.form-group .checkbox-material-orange-200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ffcc80;
}
.form-group .checkbox-material-orange-300 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ffb74d;
}
.form-group .checkbox-material-orange-400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ffa726;
}
.form-group .checkbox-material-orange-500 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ff9800;
}
.form-group .checkbox-material-orange-600 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #fb8c00;
}
.form-group .checkbox-material-orange-700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #f57c00;
}
.form-group .checkbox-material-orange-800 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ef6c00;
}
.form-group .checkbox-material-orange-900 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #e65100;
}
.form-group .checkbox-material-orange-A100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ffd180;
}
.form-group .checkbox-material-orange-A200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ffab40;
}
.form-group .checkbox-material-orange-A400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ff9100;
}
.form-group .checkbox-material-orange-A700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ff6d00;
}
.form-group .checkbox-material-deep-orange input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ff5722;
}
.form-group .checkbox-material-deep-orange-50 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #fbe9e7;
}
.form-group .checkbox-material-deep-orange-100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ffccbc;
}
.form-group .checkbox-material-deep-orange-200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ffab91;
}
.form-group .checkbox-material-deep-orange-300 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ff8a65;
}
.form-group .checkbox-material-deep-orange-400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ff7043;
}
.form-group .checkbox-material-deep-orange-500 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ff5722;
}
.form-group .checkbox-material-deep-orange-600 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #f4511e;
}
.form-group .checkbox-material-deep-orange-700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #e64a19;
}
.form-group .checkbox-material-deep-orange-800 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #d84315;
}
.form-group .checkbox-material-deep-orange-900 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #bf360c;
}
.form-group .checkbox-material-deep-orange-A100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ff9e80;
}
.form-group .checkbox-material-deep-orange-A200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ff6e40;
}
.form-group .checkbox-material-deep-orange-A400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #ff3d00;
}
.form-group .checkbox-material-deep-orange-A700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #dd2c00;
}
.form-group .checkbox-material-brown input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #795548;
}
.form-group .checkbox-material-brown-50 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #efebe9;
}
.form-group .checkbox-material-brown-100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #d7ccc8;
}
.form-group .checkbox-material-brown-200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #bcaaa4;
}
.form-group .checkbox-material-brown-300 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #a1887f;
}
.form-group .checkbox-material-brown-400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #8d6e63;
}
.form-group .checkbox-material-brown-500 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #795548;
}
.form-group .checkbox-material-brown-600 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #6d4c41;
}
.form-group .checkbox-material-brown-700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #5d4037;
}
.form-group .checkbox-material-brown-800 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #4e342e;
}
.form-group .checkbox-material-brown-900 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #3e2723;
}
.form-group .checkbox-material-brown-A100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #d7ccc8;
}
.form-group .checkbox-material-brown-A200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #bcaaa4;
}
.form-group .checkbox-material-brown-A400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #8d6e63;
}
.form-group .checkbox-material-brown-A700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #5d4037;
}
.form-group .checkbox-material-grey input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #9e9e9e;
}
.form-group .checkbox-material-grey-50 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #fafafa;
}
.form-group .checkbox-material-grey-100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #f5f5f5;
}
.form-group .checkbox-material-grey-200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #eeeeee;
}
.form-group .checkbox-material-grey-300 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #e0e0e0;
}
.form-group .checkbox-material-grey-400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #bdbdbd;
}
.form-group .checkbox-material-grey-500 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #9e9e9e;
}
.form-group .checkbox-material-grey-600 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #757575;
}
.form-group .checkbox-material-grey-700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #616161;
}
.form-group .checkbox-material-grey-800 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #424242;
}
.form-group .checkbox-material-grey-900 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #212121;
}
.form-group .checkbox-material-grey-A100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #f5f5f5;
}
.form-group .checkbox-material-grey-A200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #eeeeee;
}
.form-group .checkbox-material-grey-A400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #bdbdbd;
}
.form-group .checkbox-material-grey-A700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #616161;
}
.form-group .checkbox-material-blue-grey input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #607d8b;
}
.form-group .checkbox-material-blue-grey-50 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #eceff1;
}
.form-group .checkbox-material-blue-grey-100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #cfd8dc;
}
.form-group .checkbox-material-blue-grey-200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #b0bec5;
}
.form-group .checkbox-material-blue-grey-300 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #90a4ae;
}
.form-group .checkbox-material-blue-grey-400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #78909c;
}
.form-group .checkbox-material-blue-grey-500 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #607d8b;
}
.form-group .checkbox-material-blue-grey-600 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #546e7a;
}
.form-group .checkbox-material-blue-grey-700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #455a64;
}
.form-group .checkbox-material-blue-grey-800 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #37474f;
}
.form-group .checkbox-material-blue-grey-900 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #263238;
}
.form-group .checkbox-material-blue-grey-A100 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #cfd8dc;
}
.form-group .checkbox-material-blue-grey-A200 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #b0bec5;
}
.form-group .checkbox-material-blue-grey-A400 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #78909c;
}
.form-group .checkbox-material-blue-grey-A700 input[type=checkbox]:checked + .checkbox-material .check {
  border-color: #455a64;
}
@keyframes checkbox-on {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px;
  }
  50% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px;
  }
  100% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  }
}
@keyframes checkbox-off {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }
  25% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }
  50% {
    transform: rotate(45deg);
    margin-top: -4px;
    margin-left: 6px;
    width: 0px;
    height: 0px;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset;
  }
  51% {
    transform: rotate(0deg);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 0,
      0 0 0 0,
      0 0 0 0,
      0 0 0 0,
      0 0 0 0,
      0 0 0 0,
      0px 0px 0 10px inset;
  }
  100% {
    transform: rotate(0deg);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 0,
      0 0 0 0,
      0 0 0 0,
      0 0 0 0,
      0 0 0 0,
      0 0 0 0,
      0px 0px 0 0px inset;
  }
}
@keyframes rippleOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@keyframes rippleOff {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
.form-group .togglebutton label {
  font-size: 16px;
  line-height: 1.42857143;
  font-weight: normal;
}
.form-group.form-group-sm .togglebutton label {
  font-size: 11px;
  line-height: 1.5;
  font-weight: normal;
}
.form-group.form-group-lg .togglebutton label {
  font-size: 18px;
  line-height: 1.3333333;
  font-weight: normal;
}
.form-group .togglebutton {
  vertical-align: middle;
}
.form-group .togglebutton,
.form-group .togglebutton label,
.form-group .togglebutton input,
.form-group .togglebutton .toggle {
  user-select: none;
}
.form-group .togglebutton label {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.54);
}
.form-group .togglebutton label input[type=checkbox] {
  opacity: 0;
  width: 0;
  height: 0;
}
.form-group .togglebutton label .toggle,
.form-group .togglebutton label input[type=checkbox][disabled] + .toggle {
  content: "";
  display: inline-block;
  width: 30px;
  height: 15px;
  background-color: rgba(80, 80, 80, 0.7);
  border-radius: 15px;
  margin-right: 10px;
  transition: background 0.3s ease;
  vertical-align: middle;
}
.form-group .togglebutton label .toggle:after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #F1F1F1;
  border-radius: 20px;
  position: relative;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
  left: -5px;
  top: -2px;
  transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease;
}
.form-group .togglebutton label input[type=checkbox][disabled] + .toggle:after,
.form-group .togglebutton label input[type=checkbox][disabled]:checked + .toggle:after {
  background-color: #BDBDBD;
}
.form-group .togglebutton label input[type=checkbox] + .toggle:active:after,
.form-group .togglebutton label input[type=checkbox][disabled] + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.1);
}
.form-group .togglebutton label input[type=checkbox]:checked + .toggle:after {
  left: 15px;
}
.form-group .togglebutton label input[type=checkbox]:checked + .toggle,
.form-group .togglebutton-default label input[type=checkbox]:checked + .toggle {
  background-color: rgba(0, 150, 136, 0.5);
}
.form-group .togglebutton-black label input[type=checkbox]:checked + .toggle {
  background-color: rgba(0, 0, 0, 0.5);
}
.form-group .togglebutton-white label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 255, 255, 0.5);
}
.form-group .togglebutton-inverse label input[type=checkbox]:checked + .toggle {
  background-color: rgba(63, 81, 181, 0.5);
}
.form-group .togglebutton-primary label input[type=checkbox]:checked + .toggle {
  background-color: rgba(0, 150, 136, 0.5);
}
.form-group .togglebutton-success label input[type=checkbox]:checked + .toggle {
  background-color: rgba(76, 175, 80, 0.5);
}
.form-group .togglebutton-info label input[type=checkbox]:checked + .toggle {
  background-color: rgba(3, 169, 244, 0.5);
}
.form-group .togglebutton-warning label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 87, 34, 0.5);
}
.form-group .togglebutton-danger label input[type=checkbox]:checked + .toggle {
  background-color: rgba(244, 67, 54, 0.5);
}
.form-group .togglebutton-material-red label input[type=checkbox]:checked + .toggle {
  background-color: rgba(244, 67, 54, 0.5);
}
.form-group .togglebutton-material-red-50 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 235, 238, 0.5);
}
.form-group .togglebutton-material-red-100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 205, 210, 0.5);
}
.form-group .togglebutton-material-red-200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(239, 154, 154, 0.5);
}
.form-group .togglebutton-material-red-300 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(229, 115, 115, 0.5);
}
.form-group .togglebutton-material-red-400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(239, 83, 80, 0.5);
}
.form-group .togglebutton-material-red-500 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(244, 67, 54, 0.5);
}
.form-group .togglebutton-material-red-600 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(229, 57, 53, 0.5);
}
.form-group .togglebutton-material-red-700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(211, 47, 47, 0.5);
}
.form-group .togglebutton-material-red-800 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(198, 40, 40, 0.5);
}
.form-group .togglebutton-material-red-900 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(183, 28, 28, 0.5);
}
.form-group .togglebutton-material-red-A100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 138, 128, 0.5);
}
.form-group .togglebutton-material-red-A200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 82, 82, 0.5);
}
.form-group .togglebutton-material-red-A400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 23, 68, 0.5);
}
.form-group .togglebutton-material-red-A700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(213, 0, 0, 0.5);
}
.form-group .togglebutton-material-pink label input[type=checkbox]:checked + .toggle {
  background-color: rgba(233, 30, 99, 0.5);
}
.form-group .togglebutton-material-pink-50 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(252, 228, 236, 0.5);
}
.form-group .togglebutton-material-pink-100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(248, 187, 208, 0.5);
}
.form-group .togglebutton-material-pink-200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(244, 143, 177, 0.5);
}
.form-group .togglebutton-material-pink-300 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(240, 98, 146, 0.5);
}
.form-group .togglebutton-material-pink-400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(236, 64, 122, 0.5);
}
.form-group .togglebutton-material-pink-500 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(233, 30, 99, 0.5);
}
.form-group .togglebutton-material-pink-600 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(216, 27, 96, 0.5);
}
.form-group .togglebutton-material-pink-700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(194, 24, 91, 0.5);
}
.form-group .togglebutton-material-pink-800 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(173, 20, 87, 0.5);
}
.form-group .togglebutton-material-pink-900 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(136, 14, 79, 0.5);
}
.form-group .togglebutton-material-pink-A100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 128, 171, 0.5);
}
.form-group .togglebutton-material-pink-A200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 64, 129, 0.5);
}
.form-group .togglebutton-material-pink-A400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(245, 0, 87, 0.5);
}
.form-group .togglebutton-material-pink-A700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(197, 17, 98, 0.5);
}
.form-group .togglebutton-material-purple label input[type=checkbox]:checked + .toggle {
  background-color: rgba(156, 39, 176, 0.5);
}
.form-group .togglebutton-material-purple-50 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(243, 229, 245, 0.5);
}
.form-group .togglebutton-material-purple-100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(225, 190, 231, 0.5);
}
.form-group .togglebutton-material-purple-200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(206, 147, 216, 0.5);
}
.form-group .togglebutton-material-purple-300 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(186, 104, 200, 0.5);
}
.form-group .togglebutton-material-purple-400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(171, 71, 188, 0.5);
}
.form-group .togglebutton-material-purple-500 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(156, 39, 176, 0.5);
}
.form-group .togglebutton-material-purple-600 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(142, 36, 170, 0.5);
}
.form-group .togglebutton-material-purple-700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(123, 31, 162, 0.5);
}
.form-group .togglebutton-material-purple-800 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(106, 27, 154, 0.5);
}
.form-group .togglebutton-material-purple-900 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(74, 20, 140, 0.5);
}
.form-group .togglebutton-material-purple-A100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(234, 128, 252, 0.5);
}
.form-group .togglebutton-material-purple-A200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(224, 64, 251, 0.5);
}
.form-group .togglebutton-material-purple-A400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(213, 0, 249, 0.5);
}
.form-group .togglebutton-material-purple-A700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(170, 0, 255, 0.5);
}
.form-group .togglebutton-material-deep-purple label input[type=checkbox]:checked + .toggle {
  background-color: rgba(103, 58, 183, 0.5);
}
.form-group .togglebutton-material-deep-purple-50 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(237, 231, 246, 0.5);
}
.form-group .togglebutton-material-deep-purple-100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(209, 196, 233, 0.5);
}
.form-group .togglebutton-material-deep-purple-200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(179, 157, 219, 0.5);
}
.form-group .togglebutton-material-deep-purple-300 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(149, 117, 205, 0.5);
}
.form-group .togglebutton-material-deep-purple-400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(126, 87, 194, 0.5);
}
.form-group .togglebutton-material-deep-purple-500 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(103, 58, 183, 0.5);
}
.form-group .togglebutton-material-deep-purple-600 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(94, 53, 177, 0.5);
}
.form-group .togglebutton-material-deep-purple-700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(81, 45, 168, 0.5);
}
.form-group .togglebutton-material-deep-purple-800 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(69, 39, 160, 0.5);
}
.form-group .togglebutton-material-deep-purple-900 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(49, 27, 146, 0.5);
}
.form-group .togglebutton-material-deep-purple-A100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(179, 136, 255, 0.5);
}
.form-group .togglebutton-material-deep-purple-A200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(124, 77, 255, 0.5);
}
.form-group .togglebutton-material-deep-purple-A400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(101, 31, 255, 0.5);
}
.form-group .togglebutton-material-deep-purple-A700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(98, 0, 234, 0.5);
}
.form-group .togglebutton-material-indigo label input[type=checkbox]:checked + .toggle {
  background-color: rgba(63, 81, 181, 0.5);
}
.form-group .togglebutton-material-indigo-50 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(232, 234, 246, 0.5);
}
.form-group .togglebutton-material-indigo-100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(197, 202, 233, 0.5);
}
.form-group .togglebutton-material-indigo-200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(159, 168, 218, 0.5);
}
.form-group .togglebutton-material-indigo-300 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(121, 134, 203, 0.5);
}
.form-group .togglebutton-material-indigo-400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(92, 107, 192, 0.5);
}
.form-group .togglebutton-material-indigo-500 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(63, 81, 181, 0.5);
}
.form-group .togglebutton-material-indigo-600 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(57, 73, 171, 0.5);
}
.form-group .togglebutton-material-indigo-700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(48, 63, 159, 0.5);
}
.form-group .togglebutton-material-indigo-800 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(40, 53, 147, 0.5);
}
.form-group .togglebutton-material-indigo-900 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(26, 35, 126, 0.5);
}
.form-group .togglebutton-material-indigo-A100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(140, 158, 255, 0.5);
}
.form-group .togglebutton-material-indigo-A200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(83, 109, 254, 0.5);
}
.form-group .togglebutton-material-indigo-A400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(61, 90, 254, 0.5);
}
.form-group .togglebutton-material-indigo-A700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(48, 79, 254, 0.5);
}
.form-group .togglebutton-material-blue label input[type=checkbox]:checked + .toggle {
  background-color: rgba(33, 150, 243, 0.5);
}
.form-group .togglebutton-material-blue-50 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(227, 242, 253, 0.5);
}
.form-group .togglebutton-material-blue-100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(187, 222, 251, 0.5);
}
.form-group .togglebutton-material-blue-200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(144, 202, 249, 0.5);
}
.form-group .togglebutton-material-blue-300 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(100, 181, 246, 0.5);
}
.form-group .togglebutton-material-blue-400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(66, 165, 245, 0.5);
}
.form-group .togglebutton-material-blue-500 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(33, 150, 243, 0.5);
}
.form-group .togglebutton-material-blue-600 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(30, 136, 229, 0.5);
}
.form-group .togglebutton-material-blue-700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(25, 118, 210, 0.5);
}
.form-group .togglebutton-material-blue-800 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(21, 101, 192, 0.5);
}
.form-group .togglebutton-material-blue-900 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(13, 71, 161, 0.5);
}
.form-group .togglebutton-material-blue-A100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(130, 177, 255, 0.5);
}
.form-group .togglebutton-material-blue-A200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(68, 138, 255, 0.5);
}
.form-group .togglebutton-material-blue-A400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(41, 121, 255, 0.5);
}
.form-group .togglebutton-material-blue-A700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(41, 98, 255, 0.5);
}
.form-group .togglebutton-material-light-blue label input[type=checkbox]:checked + .toggle {
  background-color: rgba(3, 169, 244, 0.5);
}
.form-group .togglebutton-material-light-blue-50 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(225, 245, 254, 0.5);
}
.form-group .togglebutton-material-light-blue-100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(179, 229, 252, 0.5);
}
.form-group .togglebutton-material-light-blue-200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(129, 212, 250, 0.5);
}
.form-group .togglebutton-material-light-blue-300 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(79, 195, 247, 0.5);
}
.form-group .togglebutton-material-light-blue-400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(41, 182, 246, 0.5);
}
.form-group .togglebutton-material-light-blue-500 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(3, 169, 244, 0.5);
}
.form-group .togglebutton-material-light-blue-600 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(3, 155, 229, 0.5);
}
.form-group .togglebutton-material-light-blue-700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(2, 136, 209, 0.5);
}
.form-group .togglebutton-material-light-blue-800 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(2, 119, 189, 0.5);
}
.form-group .togglebutton-material-light-blue-900 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(1, 87, 155, 0.5);
}
.form-group .togglebutton-material-light-blue-A100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(128, 216, 255, 0.5);
}
.form-group .togglebutton-material-light-blue-A200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(64, 196, 255, 0.5);
}
.form-group .togglebutton-material-light-blue-A400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(0, 176, 255, 0.5);
}
.form-group .togglebutton-material-light-blue-A700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(0, 145, 234, 0.5);
}
.form-group .togglebutton-material-cyan label input[type=checkbox]:checked + .toggle {
  background-color: rgba(0, 188, 212, 0.5);
}
.form-group .togglebutton-material-cyan-50 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(224, 247, 250, 0.5);
}
.form-group .togglebutton-material-cyan-100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(178, 235, 242, 0.5);
}
.form-group .togglebutton-material-cyan-200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(128, 222, 234, 0.5);
}
.form-group .togglebutton-material-cyan-300 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(77, 208, 225, 0.5);
}
.form-group .togglebutton-material-cyan-400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(38, 198, 218, 0.5);
}
.form-group .togglebutton-material-cyan-500 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(0, 188, 212, 0.5);
}
.form-group .togglebutton-material-cyan-600 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(0, 172, 193, 0.5);
}
.form-group .togglebutton-material-cyan-700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(0, 151, 167, 0.5);
}
.form-group .togglebutton-material-cyan-800 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(0, 131, 143, 0.5);
}
.form-group .togglebutton-material-cyan-900 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(0, 96, 100, 0.5);
}
.form-group .togglebutton-material-cyan-A100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(132, 255, 255, 0.5);
}
.form-group .togglebutton-material-cyan-A200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(24, 255, 255, 0.5);
}
.form-group .togglebutton-material-cyan-A400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(0, 229, 255, 0.5);
}
.form-group .togglebutton-material-cyan-A700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(0, 184, 212, 0.5);
}
.form-group .togglebutton-material-teal label input[type=checkbox]:checked + .toggle {
  background-color: rgba(0, 150, 136, 0.5);
}
.form-group .togglebutton-material-teal-50 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(224, 242, 241, 0.5);
}
.form-group .togglebutton-material-teal-100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(178, 223, 219, 0.5);
}
.form-group .togglebutton-material-teal-200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(128, 203, 196, 0.5);
}
.form-group .togglebutton-material-teal-300 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(77, 182, 172, 0.5);
}
.form-group .togglebutton-material-teal-400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(38, 166, 154, 0.5);
}
.form-group .togglebutton-material-teal-500 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(0, 150, 136, 0.5);
}
.form-group .togglebutton-material-teal-600 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(0, 137, 123, 0.5);
}
.form-group .togglebutton-material-teal-700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(0, 121, 107, 0.5);
}
.form-group .togglebutton-material-teal-800 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(0, 105, 92, 0.5);
}
.form-group .togglebutton-material-teal-900 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(0, 77, 64, 0.5);
}
.form-group .togglebutton-material-teal-A100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(167, 255, 235, 0.5);
}
.form-group .togglebutton-material-teal-A200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(100, 255, 218, 0.5);
}
.form-group .togglebutton-material-teal-A400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(29, 233, 182, 0.5);
}
.form-group .togglebutton-material-teal-A700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(0, 191, 165, 0.5);
}
.form-group .togglebutton-material-green label input[type=checkbox]:checked + .toggle {
  background-color: rgba(76, 175, 80, 0.5);
}
.form-group .togglebutton-material-green-50 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(232, 245, 233, 0.5);
}
.form-group .togglebutton-material-green-100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(200, 230, 201, 0.5);
}
.form-group .togglebutton-material-green-200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(165, 214, 167, 0.5);
}
.form-group .togglebutton-material-green-300 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(129, 199, 132, 0.5);
}
.form-group .togglebutton-material-green-400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(102, 187, 106, 0.5);
}
.form-group .togglebutton-material-green-500 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(76, 175, 80, 0.5);
}
.form-group .togglebutton-material-green-600 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(67, 160, 71, 0.5);
}
.form-group .togglebutton-material-green-700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(56, 142, 60, 0.5);
}
.form-group .togglebutton-material-green-800 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(46, 125, 50, 0.5);
}
.form-group .togglebutton-material-green-900 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(27, 94, 32, 0.5);
}
.form-group .togglebutton-material-green-A100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(185, 246, 202, 0.5);
}
.form-group .togglebutton-material-green-A200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(105, 240, 174, 0.5);
}
.form-group .togglebutton-material-green-A400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(0, 230, 118, 0.5);
}
.form-group .togglebutton-material-green-A700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(0, 200, 83, 0.5);
}
.form-group .togglebutton-material-light-green label input[type=checkbox]:checked + .toggle {
  background-color: rgba(139, 195, 74, 0.5);
}
.form-group .togglebutton-material-light-green-50 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(241, 248, 233, 0.5);
}
.form-group .togglebutton-material-light-green-100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(220, 237, 200, 0.5);
}
.form-group .togglebutton-material-light-green-200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(197, 225, 165, 0.5);
}
.form-group .togglebutton-material-light-green-300 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(174, 213, 129, 0.5);
}
.form-group .togglebutton-material-light-green-400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(156, 204, 101, 0.5);
}
.form-group .togglebutton-material-light-green-500 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(139, 195, 74, 0.5);
}
.form-group .togglebutton-material-light-green-600 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(124, 179, 66, 0.5);
}
.form-group .togglebutton-material-light-green-700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(104, 159, 56, 0.5);
}
.form-group .togglebutton-material-light-green-800 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(85, 139, 47, 0.5);
}
.form-group .togglebutton-material-light-green-900 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(51, 105, 30, 0.5);
}
.form-group .togglebutton-material-light-green-A100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(204, 255, 144, 0.5);
}
.form-group .togglebutton-material-light-green-A200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(178, 255, 89, 0.5);
}
.form-group .togglebutton-material-light-green-A400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(118, 255, 3, 0.5);
}
.form-group .togglebutton-material-light-green-A700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(100, 221, 23, 0.5);
}
.form-group .togglebutton-material-lime label input[type=checkbox]:checked + .toggle {
  background-color: rgba(205, 220, 57, 0.5);
}
.form-group .togglebutton-material-lime-50 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(249, 251, 231, 0.5);
}
.form-group .togglebutton-material-lime-100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(240, 244, 195, 0.5);
}
.form-group .togglebutton-material-lime-200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(230, 238, 156, 0.5);
}
.form-group .togglebutton-material-lime-300 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(220, 231, 117, 0.5);
}
.form-group .togglebutton-material-lime-400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(212, 225, 87, 0.5);
}
.form-group .togglebutton-material-lime-500 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(205, 220, 57, 0.5);
}
.form-group .togglebutton-material-lime-600 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(192, 202, 51, 0.5);
}
.form-group .togglebutton-material-lime-700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(175, 180, 43, 0.5);
}
.form-group .togglebutton-material-lime-800 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(158, 157, 36, 0.5);
}
.form-group .togglebutton-material-lime-900 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(130, 119, 23, 0.5);
}
.form-group .togglebutton-material-lime-A100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(244, 255, 129, 0.5);
}
.form-group .togglebutton-material-lime-A200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(238, 255, 65, 0.5);
}
.form-group .togglebutton-material-lime-A400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(198, 255, 0, 0.5);
}
.form-group .togglebutton-material-lime-A700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(174, 234, 0, 0.5);
}
.form-group .togglebutton-material-yellow label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 235, 59, 0.5);
}
.form-group .togglebutton-material-yellow-50 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 253, 231, 0.5);
}
.form-group .togglebutton-material-yellow-100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 249, 196, 0.5);
}
.form-group .togglebutton-material-yellow-200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 245, 157, 0.5);
}
.form-group .togglebutton-material-yellow-300 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 241, 118, 0.5);
}
.form-group .togglebutton-material-yellow-400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 238, 88, 0.5);
}
.form-group .togglebutton-material-yellow-500 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 235, 59, 0.5);
}
.form-group .togglebutton-material-yellow-600 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(253, 216, 53, 0.5);
}
.form-group .togglebutton-material-yellow-700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(251, 192, 45, 0.5);
}
.form-group .togglebutton-material-yellow-800 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(249, 168, 37, 0.5);
}
.form-group .togglebutton-material-yellow-900 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(245, 127, 23, 0.5);
}
.form-group .togglebutton-material-yellow-A100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 255, 141, 0.5);
}
.form-group .togglebutton-material-yellow-A200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 255, 0, 0.5);
}
.form-group .togglebutton-material-yellow-A400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 234, 0, 0.5);
}
.form-group .togglebutton-material-yellow-A700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 214, 0, 0.5);
}
.form-group .togglebutton-material-amber label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 193, 7, 0.5);
}
.form-group .togglebutton-material-amber-50 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 248, 225, 0.5);
}
.form-group .togglebutton-material-amber-100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 236, 179, 0.5);
}
.form-group .togglebutton-material-amber-200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 224, 130, 0.5);
}
.form-group .togglebutton-material-amber-300 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 213, 79, 0.5);
}
.form-group .togglebutton-material-amber-400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 202, 40, 0.5);
}
.form-group .togglebutton-material-amber-500 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 193, 7, 0.5);
}
.form-group .togglebutton-material-amber-600 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 179, 0, 0.5);
}
.form-group .togglebutton-material-amber-700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 160, 0, 0.5);
}
.form-group .togglebutton-material-amber-800 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 143, 0, 0.5);
}
.form-group .togglebutton-material-amber-900 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 111, 0, 0.5);
}
.form-group .togglebutton-material-amber-A100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 229, 127, 0.5);
}
.form-group .togglebutton-material-amber-A200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 215, 64, 0.5);
}
.form-group .togglebutton-material-amber-A400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 196, 0, 0.5);
}
.form-group .togglebutton-material-amber-A700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 171, 0, 0.5);
}
.form-group .togglebutton-material-orange label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 152, 0, 0.5);
}
.form-group .togglebutton-material-orange-50 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 243, 224, 0.5);
}
.form-group .togglebutton-material-orange-100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 224, 178, 0.5);
}
.form-group .togglebutton-material-orange-200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 204, 128, 0.5);
}
.form-group .togglebutton-material-orange-300 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 183, 77, 0.5);
}
.form-group .togglebutton-material-orange-400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 167, 38, 0.5);
}
.form-group .togglebutton-material-orange-500 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 152, 0, 0.5);
}
.form-group .togglebutton-material-orange-600 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(251, 140, 0, 0.5);
}
.form-group .togglebutton-material-orange-700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(245, 124, 0, 0.5);
}
.form-group .togglebutton-material-orange-800 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(239, 108, 0, 0.5);
}
.form-group .togglebutton-material-orange-900 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(230, 81, 0, 0.5);
}
.form-group .togglebutton-material-orange-A100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 209, 128, 0.5);
}
.form-group .togglebutton-material-orange-A200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 171, 64, 0.5);
}
.form-group .togglebutton-material-orange-A400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 145, 0, 0.5);
}
.form-group .togglebutton-material-orange-A700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 109, 0, 0.5);
}
.form-group .togglebutton-material-deep-orange label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 87, 34, 0.5);
}
.form-group .togglebutton-material-deep-orange-50 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(251, 233, 231, 0.5);
}
.form-group .togglebutton-material-deep-orange-100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 204, 188, 0.5);
}
.form-group .togglebutton-material-deep-orange-200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 171, 145, 0.5);
}
.form-group .togglebutton-material-deep-orange-300 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 138, 101, 0.5);
}
.form-group .togglebutton-material-deep-orange-400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 112, 67, 0.5);
}
.form-group .togglebutton-material-deep-orange-500 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 87, 34, 0.5);
}
.form-group .togglebutton-material-deep-orange-600 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(244, 81, 30, 0.5);
}
.form-group .togglebutton-material-deep-orange-700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(230, 74, 25, 0.5);
}
.form-group .togglebutton-material-deep-orange-800 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(216, 67, 21, 0.5);
}
.form-group .togglebutton-material-deep-orange-900 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(191, 54, 12, 0.5);
}
.form-group .togglebutton-material-deep-orange-A100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 158, 128, 0.5);
}
.form-group .togglebutton-material-deep-orange-A200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 110, 64, 0.5);
}
.form-group .togglebutton-material-deep-orange-A400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(255, 61, 0, 0.5);
}
.form-group .togglebutton-material-deep-orange-A700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(221, 44, 0, 0.5);
}
.form-group .togglebutton-material-brown label input[type=checkbox]:checked + .toggle {
  background-color: rgba(121, 85, 72, 0.5);
}
.form-group .togglebutton-material-brown-50 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(239, 235, 233, 0.5);
}
.form-group .togglebutton-material-brown-100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(215, 204, 200, 0.5);
}
.form-group .togglebutton-material-brown-200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(188, 170, 164, 0.5);
}
.form-group .togglebutton-material-brown-300 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(161, 136, 127, 0.5);
}
.form-group .togglebutton-material-brown-400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(141, 110, 99, 0.5);
}
.form-group .togglebutton-material-brown-500 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(121, 85, 72, 0.5);
}
.form-group .togglebutton-material-brown-600 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(109, 76, 65, 0.5);
}
.form-group .togglebutton-material-brown-700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(93, 64, 55, 0.5);
}
.form-group .togglebutton-material-brown-800 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(78, 52, 46, 0.5);
}
.form-group .togglebutton-material-brown-900 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(62, 39, 35, 0.5);
}
.form-group .togglebutton-material-brown-A100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(215, 204, 200, 0.5);
}
.form-group .togglebutton-material-brown-A200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(188, 170, 164, 0.5);
}
.form-group .togglebutton-material-brown-A400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(141, 110, 99, 0.5);
}
.form-group .togglebutton-material-brown-A700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(93, 64, 55, 0.5);
}
.form-group .togglebutton-material-grey label input[type=checkbox]:checked + .toggle {
  background-color: rgba(158, 158, 158, 0.5);
}
.form-group .togglebutton-material-grey-50 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(250, 250, 250, 0.5);
}
.form-group .togglebutton-material-grey-100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(245, 245, 245, 0.5);
}
.form-group .togglebutton-material-grey-200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(238, 238, 238, 0.5);
}
.form-group .togglebutton-material-grey-300 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(224, 224, 224, 0.5);
}
.form-group .togglebutton-material-grey-400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(189, 189, 189, 0.5);
}
.form-group .togglebutton-material-grey-500 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(158, 158, 158, 0.5);
}
.form-group .togglebutton-material-grey-600 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(117, 117, 117, 0.5);
}
.form-group .togglebutton-material-grey-700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(97, 97, 97, 0.5);
}
.form-group .togglebutton-material-grey-800 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(66, 66, 66, 0.5);
}
.form-group .togglebutton-material-grey-900 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(33, 33, 33, 0.5);
}
.form-group .togglebutton-material-grey-A100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(245, 245, 245, 0.5);
}
.form-group .togglebutton-material-grey-A200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(238, 238, 238, 0.5);
}
.form-group .togglebutton-material-grey-A400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(189, 189, 189, 0.5);
}
.form-group .togglebutton-material-grey-A700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(97, 97, 97, 0.5);
}
.form-group .togglebutton-material-blue-grey label input[type=checkbox]:checked + .toggle {
  background-color: rgba(96, 125, 139, 0.5);
}
.form-group .togglebutton-material-blue-grey-50 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(236, 239, 241, 0.5);
}
.form-group .togglebutton-material-blue-grey-100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(207, 216, 220, 0.5);
}
.form-group .togglebutton-material-blue-grey-200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(176, 190, 197, 0.5);
}
.form-group .togglebutton-material-blue-grey-300 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(144, 164, 174, 0.5);
}
.form-group .togglebutton-material-blue-grey-400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(120, 144, 156, 0.5);
}
.form-group .togglebutton-material-blue-grey-500 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(96, 125, 139, 0.5);
}
.form-group .togglebutton-material-blue-grey-600 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(84, 110, 122, 0.5);
}
.form-group .togglebutton-material-blue-grey-700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(69, 90, 100, 0.5);
}
.form-group .togglebutton-material-blue-grey-800 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(55, 71, 79, 0.5);
}
.form-group .togglebutton-material-blue-grey-900 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(38, 50, 56, 0.5);
}
.form-group .togglebutton-material-blue-grey-A100 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(207, 216, 220, 0.5);
}
.form-group .togglebutton-material-blue-grey-A200 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(176, 190, 197, 0.5);
}
.form-group .togglebutton-material-blue-grey-A400 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(120, 144, 156, 0.5);
}
.form-group .togglebutton-material-blue-grey-A700 label input[type=checkbox]:checked + .toggle {
  background-color: rgba(69, 90, 100, 0.5);
}
.form-group .togglebutton label input[type=checkbox]:checked + .toggle:after,
.form-group .togglebutton-default label input[type=checkbox]:checked + .toggle:after {
  background-color: #009688;
}
.form-group .togglebutton-black label input[type=checkbox]:checked + .toggle:after {
  background-color: #000000;
}
.form-group .togglebutton-white label input[type=checkbox]:checked + .toggle:after {
  background-color: #ffffff;
}
.form-group .togglebutton-inverse label input[type=checkbox]:checked + .toggle:after {
  background-color: #3f51b5;
}
.form-group .togglebutton-primary label input[type=checkbox]:checked + .toggle:after {
  background-color: #009688;
}
.form-group .togglebutton-success label input[type=checkbox]:checked + .toggle:after {
  background-color: #4caf50;
}
.form-group .togglebutton-info label input[type=checkbox]:checked + .toggle:after {
  background-color: #03a9f4;
}
.form-group .togglebutton-warning label input[type=checkbox]:checked + .toggle:after {
  background-color: #ff5722;
}
.form-group .togglebutton-danger label input[type=checkbox]:checked + .toggle:after {
  background-color: #f44336;
}
.form-group .togglebutton-material-red label input[type=checkbox]:checked + .toggle:after {
  background-color: #f44336;
}
.form-group .togglebutton-material-red-50 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ffebee;
}
.form-group .togglebutton-material-red-100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ffcdd2;
}
.form-group .togglebutton-material-red-200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ef9a9a;
}
.form-group .togglebutton-material-red-300 label input[type=checkbox]:checked + .toggle:after {
  background-color: #e57373;
}
.form-group .togglebutton-material-red-400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ef5350;
}
.form-group .togglebutton-material-red-500 label input[type=checkbox]:checked + .toggle:after {
  background-color: #f44336;
}
.form-group .togglebutton-material-red-600 label input[type=checkbox]:checked + .toggle:after {
  background-color: #e53935;
}
.form-group .togglebutton-material-red-700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #d32f2f;
}
.form-group .togglebutton-material-red-800 label input[type=checkbox]:checked + .toggle:after {
  background-color: #c62828;
}
.form-group .togglebutton-material-red-900 label input[type=checkbox]:checked + .toggle:after {
  background-color: #b71c1c;
}
.form-group .togglebutton-material-red-A100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ff8a80;
}
.form-group .togglebutton-material-red-A200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ff5252;
}
.form-group .togglebutton-material-red-A400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ff1744;
}
.form-group .togglebutton-material-red-A700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #d50000;
}
.form-group .togglebutton-material-pink label input[type=checkbox]:checked + .toggle:after {
  background-color: #e91e63;
}
.form-group .togglebutton-material-pink-50 label input[type=checkbox]:checked + .toggle:after {
  background-color: #fce4ec;
}
.form-group .togglebutton-material-pink-100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #f8bbd0;
}
.form-group .togglebutton-material-pink-200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #f48fb1;
}
.form-group .togglebutton-material-pink-300 label input[type=checkbox]:checked + .toggle:after {
  background-color: #f06292;
}
.form-group .togglebutton-material-pink-400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ec407a;
}
.form-group .togglebutton-material-pink-500 label input[type=checkbox]:checked + .toggle:after {
  background-color: #e91e63;
}
.form-group .togglebutton-material-pink-600 label input[type=checkbox]:checked + .toggle:after {
  background-color: #d81b60;
}
.form-group .togglebutton-material-pink-700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #c2185b;
}
.form-group .togglebutton-material-pink-800 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ad1457;
}
.form-group .togglebutton-material-pink-900 label input[type=checkbox]:checked + .toggle:after {
  background-color: #880e4f;
}
.form-group .togglebutton-material-pink-A100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ff80ab;
}
.form-group .togglebutton-material-pink-A200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ff4081;
}
.form-group .togglebutton-material-pink-A400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #f50057;
}
.form-group .togglebutton-material-pink-A700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #c51162;
}
.form-group .togglebutton-material-purple label input[type=checkbox]:checked + .toggle:after {
  background-color: #9c27b0;
}
.form-group .togglebutton-material-purple-50 label input[type=checkbox]:checked + .toggle:after {
  background-color: #f3e5f5;
}
.form-group .togglebutton-material-purple-100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #e1bee7;
}
.form-group .togglebutton-material-purple-200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ce93d8;
}
.form-group .togglebutton-material-purple-300 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ba68c8;
}
.form-group .togglebutton-material-purple-400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ab47bc;
}
.form-group .togglebutton-material-purple-500 label input[type=checkbox]:checked + .toggle:after {
  background-color: #9c27b0;
}
.form-group .togglebutton-material-purple-600 label input[type=checkbox]:checked + .toggle:after {
  background-color: #8e24aa;
}
.form-group .togglebutton-material-purple-700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #7b1fa2;
}
.form-group .togglebutton-material-purple-800 label input[type=checkbox]:checked + .toggle:after {
  background-color: #6a1b9a;
}
.form-group .togglebutton-material-purple-900 label input[type=checkbox]:checked + .toggle:after {
  background-color: #4a148c;
}
.form-group .togglebutton-material-purple-A100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ea80fc;
}
.form-group .togglebutton-material-purple-A200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #e040fb;
}
.form-group .togglebutton-material-purple-A400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #d500f9;
}
.form-group .togglebutton-material-purple-A700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #aa00ff;
}
.form-group .togglebutton-material-deep-purple label input[type=checkbox]:checked + .toggle:after {
  background-color: #673ab7;
}
.form-group .togglebutton-material-deep-purple-50 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ede7f6;
}
.form-group .togglebutton-material-deep-purple-100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #d1c4e9;
}
.form-group .togglebutton-material-deep-purple-200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #b39ddb;
}
.form-group .togglebutton-material-deep-purple-300 label input[type=checkbox]:checked + .toggle:after {
  background-color: #9575cd;
}
.form-group .togglebutton-material-deep-purple-400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #7e57c2;
}
.form-group .togglebutton-material-deep-purple-500 label input[type=checkbox]:checked + .toggle:after {
  background-color: #673ab7;
}
.form-group .togglebutton-material-deep-purple-600 label input[type=checkbox]:checked + .toggle:after {
  background-color: #5e35b1;
}
.form-group .togglebutton-material-deep-purple-700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #512da8;
}
.form-group .togglebutton-material-deep-purple-800 label input[type=checkbox]:checked + .toggle:after {
  background-color: #4527a0;
}
.form-group .togglebutton-material-deep-purple-900 label input[type=checkbox]:checked + .toggle:after {
  background-color: #311b92;
}
.form-group .togglebutton-material-deep-purple-A100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #b388ff;
}
.form-group .togglebutton-material-deep-purple-A200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #7c4dff;
}
.form-group .togglebutton-material-deep-purple-A400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #651fff;
}
.form-group .togglebutton-material-deep-purple-A700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #6200ea;
}
.form-group .togglebutton-material-indigo label input[type=checkbox]:checked + .toggle:after {
  background-color: #3f51b5;
}
.form-group .togglebutton-material-indigo-50 label input[type=checkbox]:checked + .toggle:after {
  background-color: #e8eaf6;
}
.form-group .togglebutton-material-indigo-100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #c5cae9;
}
.form-group .togglebutton-material-indigo-200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #9fa8da;
}
.form-group .togglebutton-material-indigo-300 label input[type=checkbox]:checked + .toggle:after {
  background-color: #7986cb;
}
.form-group .togglebutton-material-indigo-400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #5c6bc0;
}
.form-group .togglebutton-material-indigo-500 label input[type=checkbox]:checked + .toggle:after {
  background-color: #3f51b5;
}
.form-group .togglebutton-material-indigo-600 label input[type=checkbox]:checked + .toggle:after {
  background-color: #3949ab;
}
.form-group .togglebutton-material-indigo-700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #303f9f;
}
.form-group .togglebutton-material-indigo-800 label input[type=checkbox]:checked + .toggle:after {
  background-color: #283593;
}
.form-group .togglebutton-material-indigo-900 label input[type=checkbox]:checked + .toggle:after {
  background-color: #1a237e;
}
.form-group .togglebutton-material-indigo-A100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #8c9eff;
}
.form-group .togglebutton-material-indigo-A200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #536dfe;
}
.form-group .togglebutton-material-indigo-A400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #3d5afe;
}
.form-group .togglebutton-material-indigo-A700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #304ffe;
}
.form-group .togglebutton-material-blue label input[type=checkbox]:checked + .toggle:after {
  background-color: #2196f3;
}
.form-group .togglebutton-material-blue-50 label input[type=checkbox]:checked + .toggle:after {
  background-color: #e3f2fd;
}
.form-group .togglebutton-material-blue-100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #bbdefb;
}
.form-group .togglebutton-material-blue-200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #90caf9;
}
.form-group .togglebutton-material-blue-300 label input[type=checkbox]:checked + .toggle:after {
  background-color: #64b5f6;
}
.form-group .togglebutton-material-blue-400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #42a5f5;
}
.form-group .togglebutton-material-blue-500 label input[type=checkbox]:checked + .toggle:after {
  background-color: #2196f3;
}
.form-group .togglebutton-material-blue-600 label input[type=checkbox]:checked + .toggle:after {
  background-color: #1e88e5;
}
.form-group .togglebutton-material-blue-700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #1976d2;
}
.form-group .togglebutton-material-blue-800 label input[type=checkbox]:checked + .toggle:after {
  background-color: #1565c0;
}
.form-group .togglebutton-material-blue-900 label input[type=checkbox]:checked + .toggle:after {
  background-color: #0d47a1;
}
.form-group .togglebutton-material-blue-A100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #82b1ff;
}
.form-group .togglebutton-material-blue-A200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #448aff;
}
.form-group .togglebutton-material-blue-A400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #2979ff;
}
.form-group .togglebutton-material-blue-A700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #2962ff;
}
.form-group .togglebutton-material-light-blue label input[type=checkbox]:checked + .toggle:after {
  background-color: #03a9f4;
}
.form-group .togglebutton-material-light-blue-50 label input[type=checkbox]:checked + .toggle:after {
  background-color: #e1f5fe;
}
.form-group .togglebutton-material-light-blue-100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #b3e5fc;
}
.form-group .togglebutton-material-light-blue-200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #81d4fa;
}
.form-group .togglebutton-material-light-blue-300 label input[type=checkbox]:checked + .toggle:after {
  background-color: #4fc3f7;
}
.form-group .togglebutton-material-light-blue-400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #29b6f6;
}
.form-group .togglebutton-material-light-blue-500 label input[type=checkbox]:checked + .toggle:after {
  background-color: #03a9f4;
}
.form-group .togglebutton-material-light-blue-600 label input[type=checkbox]:checked + .toggle:after {
  background-color: #039be5;
}
.form-group .togglebutton-material-light-blue-700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #0288d1;
}
.form-group .togglebutton-material-light-blue-800 label input[type=checkbox]:checked + .toggle:after {
  background-color: #0277bd;
}
.form-group .togglebutton-material-light-blue-900 label input[type=checkbox]:checked + .toggle:after {
  background-color: #01579b;
}
.form-group .togglebutton-material-light-blue-A100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #80d8ff;
}
.form-group .togglebutton-material-light-blue-A200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #40c4ff;
}
.form-group .togglebutton-material-light-blue-A400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #00b0ff;
}
.form-group .togglebutton-material-light-blue-A700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #0091ea;
}
.form-group .togglebutton-material-cyan label input[type=checkbox]:checked + .toggle:after {
  background-color: #00bcd4;
}
.form-group .togglebutton-material-cyan-50 label input[type=checkbox]:checked + .toggle:after {
  background-color: #e0f7fa;
}
.form-group .togglebutton-material-cyan-100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #b2ebf2;
}
.form-group .togglebutton-material-cyan-200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #80deea;
}
.form-group .togglebutton-material-cyan-300 label input[type=checkbox]:checked + .toggle:after {
  background-color: #4dd0e1;
}
.form-group .togglebutton-material-cyan-400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #26c6da;
}
.form-group .togglebutton-material-cyan-500 label input[type=checkbox]:checked + .toggle:after {
  background-color: #00bcd4;
}
.form-group .togglebutton-material-cyan-600 label input[type=checkbox]:checked + .toggle:after {
  background-color: #00acc1;
}
.form-group .togglebutton-material-cyan-700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #0097a7;
}
.form-group .togglebutton-material-cyan-800 label input[type=checkbox]:checked + .toggle:after {
  background-color: #00838f;
}
.form-group .togglebutton-material-cyan-900 label input[type=checkbox]:checked + .toggle:after {
  background-color: #006064;
}
.form-group .togglebutton-material-cyan-A100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #84ffff;
}
.form-group .togglebutton-material-cyan-A200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #18ffff;
}
.form-group .togglebutton-material-cyan-A400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #00e5ff;
}
.form-group .togglebutton-material-cyan-A700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #00b8d4;
}
.form-group .togglebutton-material-teal label input[type=checkbox]:checked + .toggle:after {
  background-color: #009688;
}
.form-group .togglebutton-material-teal-50 label input[type=checkbox]:checked + .toggle:after {
  background-color: #e0f2f1;
}
.form-group .togglebutton-material-teal-100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #b2dfdb;
}
.form-group .togglebutton-material-teal-200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #80cbc4;
}
.form-group .togglebutton-material-teal-300 label input[type=checkbox]:checked + .toggle:after {
  background-color: #4db6ac;
}
.form-group .togglebutton-material-teal-400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #26a69a;
}
.form-group .togglebutton-material-teal-500 label input[type=checkbox]:checked + .toggle:after {
  background-color: #009688;
}
.form-group .togglebutton-material-teal-600 label input[type=checkbox]:checked + .toggle:after {
  background-color: #00897b;
}
.form-group .togglebutton-material-teal-700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #00796b;
}
.form-group .togglebutton-material-teal-800 label input[type=checkbox]:checked + .toggle:after {
  background-color: #00695c;
}
.form-group .togglebutton-material-teal-900 label input[type=checkbox]:checked + .toggle:after {
  background-color: #004d40;
}
.form-group .togglebutton-material-teal-A100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #a7ffeb;
}
.form-group .togglebutton-material-teal-A200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #64ffda;
}
.form-group .togglebutton-material-teal-A400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #1de9b6;
}
.form-group .togglebutton-material-teal-A700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #00bfa5;
}
.form-group .togglebutton-material-green label input[type=checkbox]:checked + .toggle:after {
  background-color: #4caf50;
}
.form-group .togglebutton-material-green-50 label input[type=checkbox]:checked + .toggle:after {
  background-color: #e8f5e9;
}
.form-group .togglebutton-material-green-100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #c8e6c9;
}
.form-group .togglebutton-material-green-200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #a5d6a7;
}
.form-group .togglebutton-material-green-300 label input[type=checkbox]:checked + .toggle:after {
  background-color: #81c784;
}
.form-group .togglebutton-material-green-400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #66bb6a;
}
.form-group .togglebutton-material-green-500 label input[type=checkbox]:checked + .toggle:after {
  background-color: #4caf50;
}
.form-group .togglebutton-material-green-600 label input[type=checkbox]:checked + .toggle:after {
  background-color: #43a047;
}
.form-group .togglebutton-material-green-700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #388e3c;
}
.form-group .togglebutton-material-green-800 label input[type=checkbox]:checked + .toggle:after {
  background-color: #2e7d32;
}
.form-group .togglebutton-material-green-900 label input[type=checkbox]:checked + .toggle:after {
  background-color: #1b5e20;
}
.form-group .togglebutton-material-green-A100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #b9f6ca;
}
.form-group .togglebutton-material-green-A200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #69f0ae;
}
.form-group .togglebutton-material-green-A400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #00e676;
}
.form-group .togglebutton-material-green-A700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #00c853;
}
.form-group .togglebutton-material-light-green label input[type=checkbox]:checked + .toggle:after {
  background-color: #8bc34a;
}
.form-group .togglebutton-material-light-green-50 label input[type=checkbox]:checked + .toggle:after {
  background-color: #f1f8e9;
}
.form-group .togglebutton-material-light-green-100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #dcedc8;
}
.form-group .togglebutton-material-light-green-200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #c5e1a5;
}
.form-group .togglebutton-material-light-green-300 label input[type=checkbox]:checked + .toggle:after {
  background-color: #aed581;
}
.form-group .togglebutton-material-light-green-400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #9ccc65;
}
.form-group .togglebutton-material-light-green-500 label input[type=checkbox]:checked + .toggle:after {
  background-color: #8bc34a;
}
.form-group .togglebutton-material-light-green-600 label input[type=checkbox]:checked + .toggle:after {
  background-color: #7cb342;
}
.form-group .togglebutton-material-light-green-700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #689f38;
}
.form-group .togglebutton-material-light-green-800 label input[type=checkbox]:checked + .toggle:after {
  background-color: #558b2f;
}
.form-group .togglebutton-material-light-green-900 label input[type=checkbox]:checked + .toggle:after {
  background-color: #33691e;
}
.form-group .togglebutton-material-light-green-A100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ccff90;
}
.form-group .togglebutton-material-light-green-A200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #b2ff59;
}
.form-group .togglebutton-material-light-green-A400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #76ff03;
}
.form-group .togglebutton-material-light-green-A700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #64dd17;
}
.form-group .togglebutton-material-lime label input[type=checkbox]:checked + .toggle:after {
  background-color: #cddc39;
}
.form-group .togglebutton-material-lime-50 label input[type=checkbox]:checked + .toggle:after {
  background-color: #f9fbe7;
}
.form-group .togglebutton-material-lime-100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #f0f4c3;
}
.form-group .togglebutton-material-lime-200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #e6ee9c;
}
.form-group .togglebutton-material-lime-300 label input[type=checkbox]:checked + .toggle:after {
  background-color: #dce775;
}
.form-group .togglebutton-material-lime-400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #d4e157;
}
.form-group .togglebutton-material-lime-500 label input[type=checkbox]:checked + .toggle:after {
  background-color: #cddc39;
}
.form-group .togglebutton-material-lime-600 label input[type=checkbox]:checked + .toggle:after {
  background-color: #c0ca33;
}
.form-group .togglebutton-material-lime-700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #afb42b;
}
.form-group .togglebutton-material-lime-800 label input[type=checkbox]:checked + .toggle:after {
  background-color: #9e9d24;
}
.form-group .togglebutton-material-lime-900 label input[type=checkbox]:checked + .toggle:after {
  background-color: #827717;
}
.form-group .togglebutton-material-lime-A100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #f4ff81;
}
.form-group .togglebutton-material-lime-A200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #eeff41;
}
.form-group .togglebutton-material-lime-A400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #c6ff00;
}
.form-group .togglebutton-material-lime-A700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #aeea00;
}
.form-group .togglebutton-material-yellow label input[type=checkbox]:checked + .toggle:after {
  background-color: #ffeb3b;
}
.form-group .togglebutton-material-yellow-50 label input[type=checkbox]:checked + .toggle:after {
  background-color: #fffde7;
}
.form-group .togglebutton-material-yellow-100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #fff9c4;
}
.form-group .togglebutton-material-yellow-200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #fff59d;
}
.form-group .togglebutton-material-yellow-300 label input[type=checkbox]:checked + .toggle:after {
  background-color: #fff176;
}
.form-group .togglebutton-material-yellow-400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ffee58;
}
.form-group .togglebutton-material-yellow-500 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ffeb3b;
}
.form-group .togglebutton-material-yellow-600 label input[type=checkbox]:checked + .toggle:after {
  background-color: #fdd835;
}
.form-group .togglebutton-material-yellow-700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #fbc02d;
}
.form-group .togglebutton-material-yellow-800 label input[type=checkbox]:checked + .toggle:after {
  background-color: #f9a825;
}
.form-group .togglebutton-material-yellow-900 label input[type=checkbox]:checked + .toggle:after {
  background-color: #f57f17;
}
.form-group .togglebutton-material-yellow-A100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ffff8d;
}
.form-group .togglebutton-material-yellow-A200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ffff00;
}
.form-group .togglebutton-material-yellow-A400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ffea00;
}
.form-group .togglebutton-material-yellow-A700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ffd600;
}
.form-group .togglebutton-material-amber label input[type=checkbox]:checked + .toggle:after {
  background-color: #ffc107;
}
.form-group .togglebutton-material-amber-50 label input[type=checkbox]:checked + .toggle:after {
  background-color: #fff8e1;
}
.form-group .togglebutton-material-amber-100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ffecb3;
}
.form-group .togglebutton-material-amber-200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ffe082;
}
.form-group .togglebutton-material-amber-300 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ffd54f;
}
.form-group .togglebutton-material-amber-400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ffca28;
}
.form-group .togglebutton-material-amber-500 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ffc107;
}
.form-group .togglebutton-material-amber-600 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ffb300;
}
.form-group .togglebutton-material-amber-700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ffa000;
}
.form-group .togglebutton-material-amber-800 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ff8f00;
}
.form-group .togglebutton-material-amber-900 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ff6f00;
}
.form-group .togglebutton-material-amber-A100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ffe57f;
}
.form-group .togglebutton-material-amber-A200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ffd740;
}
.form-group .togglebutton-material-amber-A400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ffc400;
}
.form-group .togglebutton-material-amber-A700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ffab00;
}
.form-group .togglebutton-material-orange label input[type=checkbox]:checked + .toggle:after {
  background-color: #ff9800;
}
.form-group .togglebutton-material-orange-50 label input[type=checkbox]:checked + .toggle:after {
  background-color: #fff3e0;
}
.form-group .togglebutton-material-orange-100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ffe0b2;
}
.form-group .togglebutton-material-orange-200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ffcc80;
}
.form-group .togglebutton-material-orange-300 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ffb74d;
}
.form-group .togglebutton-material-orange-400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ffa726;
}
.form-group .togglebutton-material-orange-500 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ff9800;
}
.form-group .togglebutton-material-orange-600 label input[type=checkbox]:checked + .toggle:after {
  background-color: #fb8c00;
}
.form-group .togglebutton-material-orange-700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #f57c00;
}
.form-group .togglebutton-material-orange-800 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ef6c00;
}
.form-group .togglebutton-material-orange-900 label input[type=checkbox]:checked + .toggle:after {
  background-color: #e65100;
}
.form-group .togglebutton-material-orange-A100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ffd180;
}
.form-group .togglebutton-material-orange-A200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ffab40;
}
.form-group .togglebutton-material-orange-A400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ff9100;
}
.form-group .togglebutton-material-orange-A700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ff6d00;
}
.form-group .togglebutton-material-deep-orange label input[type=checkbox]:checked + .toggle:after {
  background-color: #ff5722;
}
.form-group .togglebutton-material-deep-orange-50 label input[type=checkbox]:checked + .toggle:after {
  background-color: #fbe9e7;
}
.form-group .togglebutton-material-deep-orange-100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ffccbc;
}
.form-group .togglebutton-material-deep-orange-200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ffab91;
}
.form-group .togglebutton-material-deep-orange-300 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ff8a65;
}
.form-group .togglebutton-material-deep-orange-400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ff7043;
}
.form-group .togglebutton-material-deep-orange-500 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ff5722;
}
.form-group .togglebutton-material-deep-orange-600 label input[type=checkbox]:checked + .toggle:after {
  background-color: #f4511e;
}
.form-group .togglebutton-material-deep-orange-700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #e64a19;
}
.form-group .togglebutton-material-deep-orange-800 label input[type=checkbox]:checked + .toggle:after {
  background-color: #d84315;
}
.form-group .togglebutton-material-deep-orange-900 label input[type=checkbox]:checked + .toggle:after {
  background-color: #bf360c;
}
.form-group .togglebutton-material-deep-orange-A100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ff9e80;
}
.form-group .togglebutton-material-deep-orange-A200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ff6e40;
}
.form-group .togglebutton-material-deep-orange-A400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #ff3d00;
}
.form-group .togglebutton-material-deep-orange-A700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #dd2c00;
}
.form-group .togglebutton-material-brown label input[type=checkbox]:checked + .toggle:after {
  background-color: #795548;
}
.form-group .togglebutton-material-brown-50 label input[type=checkbox]:checked + .toggle:after {
  background-color: #efebe9;
}
.form-group .togglebutton-material-brown-100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #d7ccc8;
}
.form-group .togglebutton-material-brown-200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #bcaaa4;
}
.form-group .togglebutton-material-brown-300 label input[type=checkbox]:checked + .toggle:after {
  background-color: #a1887f;
}
.form-group .togglebutton-material-brown-400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #8d6e63;
}
.form-group .togglebutton-material-brown-500 label input[type=checkbox]:checked + .toggle:after {
  background-color: #795548;
}
.form-group .togglebutton-material-brown-600 label input[type=checkbox]:checked + .toggle:after {
  background-color: #6d4c41;
}
.form-group .togglebutton-material-brown-700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #5d4037;
}
.form-group .togglebutton-material-brown-800 label input[type=checkbox]:checked + .toggle:after {
  background-color: #4e342e;
}
.form-group .togglebutton-material-brown-900 label input[type=checkbox]:checked + .toggle:after {
  background-color: #3e2723;
}
.form-group .togglebutton-material-brown-A100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #d7ccc8;
}
.form-group .togglebutton-material-brown-A200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #bcaaa4;
}
.form-group .togglebutton-material-brown-A400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #8d6e63;
}
.form-group .togglebutton-material-brown-A700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #5d4037;
}
.form-group .togglebutton-material-grey label input[type=checkbox]:checked + .toggle:after {
  background-color: #9e9e9e;
}
.form-group .togglebutton-material-grey-50 label input[type=checkbox]:checked + .toggle:after {
  background-color: #fafafa;
}
.form-group .togglebutton-material-grey-100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #f5f5f5;
}
.form-group .togglebutton-material-grey-200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #eeeeee;
}
.form-group .togglebutton-material-grey-300 label input[type=checkbox]:checked + .toggle:after {
  background-color: #e0e0e0;
}
.form-group .togglebutton-material-grey-400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #bdbdbd;
}
.form-group .togglebutton-material-grey-500 label input[type=checkbox]:checked + .toggle:after {
  background-color: #9e9e9e;
}
.form-group .togglebutton-material-grey-600 label input[type=checkbox]:checked + .toggle:after {
  background-color: #757575;
}
.form-group .togglebutton-material-grey-700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #616161;
}
.form-group .togglebutton-material-grey-800 label input[type=checkbox]:checked + .toggle:after {
  background-color: #424242;
}
.form-group .togglebutton-material-grey-900 label input[type=checkbox]:checked + .toggle:after {
  background-color: #212121;
}
.form-group .togglebutton-material-grey-A100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #f5f5f5;
}
.form-group .togglebutton-material-grey-A200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #eeeeee;
}
.form-group .togglebutton-material-grey-A400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #bdbdbd;
}
.form-group .togglebutton-material-grey-A700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #616161;
}
.form-group .togglebutton-material-blue-grey label input[type=checkbox]:checked + .toggle:after {
  background-color: #607d8b;
}
.form-group .togglebutton-material-blue-grey-50 label input[type=checkbox]:checked + .toggle:after {
  background-color: #eceff1;
}
.form-group .togglebutton-material-blue-grey-100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #cfd8dc;
}
.form-group .togglebutton-material-blue-grey-200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #b0bec5;
}
.form-group .togglebutton-material-blue-grey-300 label input[type=checkbox]:checked + .toggle:after {
  background-color: #90a4ae;
}
.form-group .togglebutton-material-blue-grey-400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #78909c;
}
.form-group .togglebutton-material-blue-grey-500 label input[type=checkbox]:checked + .toggle:after {
  background-color: #607d8b;
}
.form-group .togglebutton-material-blue-grey-600 label input[type=checkbox]:checked + .toggle:after {
  background-color: #546e7a;
}
.form-group .togglebutton-material-blue-grey-700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #455a64;
}
.form-group .togglebutton-material-blue-grey-800 label input[type=checkbox]:checked + .toggle:after {
  background-color: #37474f;
}
.form-group .togglebutton-material-blue-grey-900 label input[type=checkbox]:checked + .toggle:after {
  background-color: #263238;
}
.form-group .togglebutton-material-blue-grey-A100 label input[type=checkbox]:checked + .toggle:after {
  background-color: #cfd8dc;
}
.form-group .togglebutton-material-blue-grey-A200 label input[type=checkbox]:checked + .toggle:after {
  background-color: #b0bec5;
}
.form-group .togglebutton-material-blue-grey-A400 label input[type=checkbox]:checked + .toggle:after {
  background-color: #78909c;
}
.form-group .togglebutton-material-blue-grey-A700 label input[type=checkbox]:checked + .toggle:after {
  background-color: #455a64;
}
.form-group .togglebutton label input[type=checkbox]:checked + .toggle:active:after,
.form-group .togglebutton-default label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 150, 136, 0.1);
}
.form-group .togglebutton-black label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.1);
}
.form-group .togglebutton-white label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 255, 255, 0.1);
}
.form-group .togglebutton-inverse label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(63, 81, 181, 0.1);
}
.form-group .togglebutton-primary label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 150, 136, 0.1);
}
.form-group .togglebutton-success label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(76, 175, 80, 0.1);
}
.form-group .togglebutton-info label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(3, 169, 244, 0.1);
}
.form-group .togglebutton-warning label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 87, 34, 0.1);
}
.form-group .togglebutton-danger label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(244, 67, 54, 0.1);
}
.form-group .togglebutton-material-red label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(244, 67, 54, 0.1);
}
.form-group .togglebutton-material-red-50 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 235, 238, 0.1);
}
.form-group .togglebutton-material-red-100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 205, 210, 0.1);
}
.form-group .togglebutton-material-red-200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(239, 154, 154, 0.1);
}
.form-group .togglebutton-material-red-300 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(229, 115, 115, 0.1);
}
.form-group .togglebutton-material-red-400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(239, 83, 80, 0.1);
}
.form-group .togglebutton-material-red-500 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(244, 67, 54, 0.1);
}
.form-group .togglebutton-material-red-600 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(229, 57, 53, 0.1);
}
.form-group .togglebutton-material-red-700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(211, 47, 47, 0.1);
}
.form-group .togglebutton-material-red-800 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(198, 40, 40, 0.1);
}
.form-group .togglebutton-material-red-900 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(183, 28, 28, 0.1);
}
.form-group .togglebutton-material-red-A100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 138, 128, 0.1);
}
.form-group .togglebutton-material-red-A200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 82, 82, 0.1);
}
.form-group .togglebutton-material-red-A400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 23, 68, 0.1);
}
.form-group .togglebutton-material-red-A700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(213, 0, 0, 0.1);
}
.form-group .togglebutton-material-pink label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(233, 30, 99, 0.1);
}
.form-group .togglebutton-material-pink-50 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(252, 228, 236, 0.1);
}
.form-group .togglebutton-material-pink-100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(248, 187, 208, 0.1);
}
.form-group .togglebutton-material-pink-200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(244, 143, 177, 0.1);
}
.form-group .togglebutton-material-pink-300 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(240, 98, 146, 0.1);
}
.form-group .togglebutton-material-pink-400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(236, 64, 122, 0.1);
}
.form-group .togglebutton-material-pink-500 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(233, 30, 99, 0.1);
}
.form-group .togglebutton-material-pink-600 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(216, 27, 96, 0.1);
}
.form-group .togglebutton-material-pink-700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(194, 24, 91, 0.1);
}
.form-group .togglebutton-material-pink-800 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(173, 20, 87, 0.1);
}
.form-group .togglebutton-material-pink-900 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(136, 14, 79, 0.1);
}
.form-group .togglebutton-material-pink-A100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 128, 171, 0.1);
}
.form-group .togglebutton-material-pink-A200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 64, 129, 0.1);
}
.form-group .togglebutton-material-pink-A400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(245, 0, 87, 0.1);
}
.form-group .togglebutton-material-pink-A700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(197, 17, 98, 0.1);
}
.form-group .togglebutton-material-purple label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(156, 39, 176, 0.1);
}
.form-group .togglebutton-material-purple-50 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(243, 229, 245, 0.1);
}
.form-group .togglebutton-material-purple-100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(225, 190, 231, 0.1);
}
.form-group .togglebutton-material-purple-200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(206, 147, 216, 0.1);
}
.form-group .togglebutton-material-purple-300 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(186, 104, 200, 0.1);
}
.form-group .togglebutton-material-purple-400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(171, 71, 188, 0.1);
}
.form-group .togglebutton-material-purple-500 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(156, 39, 176, 0.1);
}
.form-group .togglebutton-material-purple-600 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(142, 36, 170, 0.1);
}
.form-group .togglebutton-material-purple-700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(123, 31, 162, 0.1);
}
.form-group .togglebutton-material-purple-800 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(106, 27, 154, 0.1);
}
.form-group .togglebutton-material-purple-900 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(74, 20, 140, 0.1);
}
.form-group .togglebutton-material-purple-A100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(234, 128, 252, 0.1);
}
.form-group .togglebutton-material-purple-A200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(224, 64, 251, 0.1);
}
.form-group .togglebutton-material-purple-A400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(213, 0, 249, 0.1);
}
.form-group .togglebutton-material-purple-A700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(170, 0, 255, 0.1);
}
.form-group .togglebutton-material-deep-purple label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(103, 58, 183, 0.1);
}
.form-group .togglebutton-material-deep-purple-50 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(237, 231, 246, 0.1);
}
.form-group .togglebutton-material-deep-purple-100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(209, 196, 233, 0.1);
}
.form-group .togglebutton-material-deep-purple-200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(179, 157, 219, 0.1);
}
.form-group .togglebutton-material-deep-purple-300 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(149, 117, 205, 0.1);
}
.form-group .togglebutton-material-deep-purple-400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(126, 87, 194, 0.1);
}
.form-group .togglebutton-material-deep-purple-500 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(103, 58, 183, 0.1);
}
.form-group .togglebutton-material-deep-purple-600 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(94, 53, 177, 0.1);
}
.form-group .togglebutton-material-deep-purple-700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(81, 45, 168, 0.1);
}
.form-group .togglebutton-material-deep-purple-800 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(69, 39, 160, 0.1);
}
.form-group .togglebutton-material-deep-purple-900 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(49, 27, 146, 0.1);
}
.form-group .togglebutton-material-deep-purple-A100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(179, 136, 255, 0.1);
}
.form-group .togglebutton-material-deep-purple-A200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(124, 77, 255, 0.1);
}
.form-group .togglebutton-material-deep-purple-A400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(101, 31, 255, 0.1);
}
.form-group .togglebutton-material-deep-purple-A700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(98, 0, 234, 0.1);
}
.form-group .togglebutton-material-indigo label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(63, 81, 181, 0.1);
}
.form-group .togglebutton-material-indigo-50 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(232, 234, 246, 0.1);
}
.form-group .togglebutton-material-indigo-100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(197, 202, 233, 0.1);
}
.form-group .togglebutton-material-indigo-200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(159, 168, 218, 0.1);
}
.form-group .togglebutton-material-indigo-300 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(121, 134, 203, 0.1);
}
.form-group .togglebutton-material-indigo-400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(92, 107, 192, 0.1);
}
.form-group .togglebutton-material-indigo-500 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(63, 81, 181, 0.1);
}
.form-group .togglebutton-material-indigo-600 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(57, 73, 171, 0.1);
}
.form-group .togglebutton-material-indigo-700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(48, 63, 159, 0.1);
}
.form-group .togglebutton-material-indigo-800 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(40, 53, 147, 0.1);
}
.form-group .togglebutton-material-indigo-900 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(26, 35, 126, 0.1);
}
.form-group .togglebutton-material-indigo-A100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(140, 158, 255, 0.1);
}
.form-group .togglebutton-material-indigo-A200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(83, 109, 254, 0.1);
}
.form-group .togglebutton-material-indigo-A400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(61, 90, 254, 0.1);
}
.form-group .togglebutton-material-indigo-A700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(48, 79, 254, 0.1);
}
.form-group .togglebutton-material-blue label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(33, 150, 243, 0.1);
}
.form-group .togglebutton-material-blue-50 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(227, 242, 253, 0.1);
}
.form-group .togglebutton-material-blue-100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(187, 222, 251, 0.1);
}
.form-group .togglebutton-material-blue-200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(144, 202, 249, 0.1);
}
.form-group .togglebutton-material-blue-300 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(100, 181, 246, 0.1);
}
.form-group .togglebutton-material-blue-400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(66, 165, 245, 0.1);
}
.form-group .togglebutton-material-blue-500 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(33, 150, 243, 0.1);
}
.form-group .togglebutton-material-blue-600 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(30, 136, 229, 0.1);
}
.form-group .togglebutton-material-blue-700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(25, 118, 210, 0.1);
}
.form-group .togglebutton-material-blue-800 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(21, 101, 192, 0.1);
}
.form-group .togglebutton-material-blue-900 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(13, 71, 161, 0.1);
}
.form-group .togglebutton-material-blue-A100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(130, 177, 255, 0.1);
}
.form-group .togglebutton-material-blue-A200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(68, 138, 255, 0.1);
}
.form-group .togglebutton-material-blue-A400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(41, 121, 255, 0.1);
}
.form-group .togglebutton-material-blue-A700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(41, 98, 255, 0.1);
}
.form-group .togglebutton-material-light-blue label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(3, 169, 244, 0.1);
}
.form-group .togglebutton-material-light-blue-50 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(225, 245, 254, 0.1);
}
.form-group .togglebutton-material-light-blue-100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(179, 229, 252, 0.1);
}
.form-group .togglebutton-material-light-blue-200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(129, 212, 250, 0.1);
}
.form-group .togglebutton-material-light-blue-300 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(79, 195, 247, 0.1);
}
.form-group .togglebutton-material-light-blue-400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(41, 182, 246, 0.1);
}
.form-group .togglebutton-material-light-blue-500 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(3, 169, 244, 0.1);
}
.form-group .togglebutton-material-light-blue-600 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(3, 155, 229, 0.1);
}
.form-group .togglebutton-material-light-blue-700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(2, 136, 209, 0.1);
}
.form-group .togglebutton-material-light-blue-800 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(2, 119, 189, 0.1);
}
.form-group .togglebutton-material-light-blue-900 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(1, 87, 155, 0.1);
}
.form-group .togglebutton-material-light-blue-A100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(128, 216, 255, 0.1);
}
.form-group .togglebutton-material-light-blue-A200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(64, 196, 255, 0.1);
}
.form-group .togglebutton-material-light-blue-A400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 176, 255, 0.1);
}
.form-group .togglebutton-material-light-blue-A700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 145, 234, 0.1);
}
.form-group .togglebutton-material-cyan label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 188, 212, 0.1);
}
.form-group .togglebutton-material-cyan-50 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(224, 247, 250, 0.1);
}
.form-group .togglebutton-material-cyan-100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(178, 235, 242, 0.1);
}
.form-group .togglebutton-material-cyan-200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(128, 222, 234, 0.1);
}
.form-group .togglebutton-material-cyan-300 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(77, 208, 225, 0.1);
}
.form-group .togglebutton-material-cyan-400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(38, 198, 218, 0.1);
}
.form-group .togglebutton-material-cyan-500 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 188, 212, 0.1);
}
.form-group .togglebutton-material-cyan-600 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 172, 193, 0.1);
}
.form-group .togglebutton-material-cyan-700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 151, 167, 0.1);
}
.form-group .togglebutton-material-cyan-800 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 131, 143, 0.1);
}
.form-group .togglebutton-material-cyan-900 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 96, 100, 0.1);
}
.form-group .togglebutton-material-cyan-A100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(132, 255, 255, 0.1);
}
.form-group .togglebutton-material-cyan-A200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(24, 255, 255, 0.1);
}
.form-group .togglebutton-material-cyan-A400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 229, 255, 0.1);
}
.form-group .togglebutton-material-cyan-A700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 184, 212, 0.1);
}
.form-group .togglebutton-material-teal label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 150, 136, 0.1);
}
.form-group .togglebutton-material-teal-50 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(224, 242, 241, 0.1);
}
.form-group .togglebutton-material-teal-100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(178, 223, 219, 0.1);
}
.form-group .togglebutton-material-teal-200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(128, 203, 196, 0.1);
}
.form-group .togglebutton-material-teal-300 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(77, 182, 172, 0.1);
}
.form-group .togglebutton-material-teal-400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(38, 166, 154, 0.1);
}
.form-group .togglebutton-material-teal-500 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 150, 136, 0.1);
}
.form-group .togglebutton-material-teal-600 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 137, 123, 0.1);
}
.form-group .togglebutton-material-teal-700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 121, 107, 0.1);
}
.form-group .togglebutton-material-teal-800 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 105, 92, 0.1);
}
.form-group .togglebutton-material-teal-900 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 77, 64, 0.1);
}
.form-group .togglebutton-material-teal-A100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(167, 255, 235, 0.1);
}
.form-group .togglebutton-material-teal-A200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(100, 255, 218, 0.1);
}
.form-group .togglebutton-material-teal-A400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(29, 233, 182, 0.1);
}
.form-group .togglebutton-material-teal-A700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 191, 165, 0.1);
}
.form-group .togglebutton-material-green label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(76, 175, 80, 0.1);
}
.form-group .togglebutton-material-green-50 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(232, 245, 233, 0.1);
}
.form-group .togglebutton-material-green-100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(200, 230, 201, 0.1);
}
.form-group .togglebutton-material-green-200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(165, 214, 167, 0.1);
}
.form-group .togglebutton-material-green-300 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(129, 199, 132, 0.1);
}
.form-group .togglebutton-material-green-400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(102, 187, 106, 0.1);
}
.form-group .togglebutton-material-green-500 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(76, 175, 80, 0.1);
}
.form-group .togglebutton-material-green-600 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(67, 160, 71, 0.1);
}
.form-group .togglebutton-material-green-700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(56, 142, 60, 0.1);
}
.form-group .togglebutton-material-green-800 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(46, 125, 50, 0.1);
}
.form-group .togglebutton-material-green-900 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(27, 94, 32, 0.1);
}
.form-group .togglebutton-material-green-A100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(185, 246, 202, 0.1);
}
.form-group .togglebutton-material-green-A200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(105, 240, 174, 0.1);
}
.form-group .togglebutton-material-green-A400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 230, 118, 0.1);
}
.form-group .togglebutton-material-green-A700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 200, 83, 0.1);
}
.form-group .togglebutton-material-light-green label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(139, 195, 74, 0.1);
}
.form-group .togglebutton-material-light-green-50 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(241, 248, 233, 0.1);
}
.form-group .togglebutton-material-light-green-100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(220, 237, 200, 0.1);
}
.form-group .togglebutton-material-light-green-200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(197, 225, 165, 0.1);
}
.form-group .togglebutton-material-light-green-300 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(174, 213, 129, 0.1);
}
.form-group .togglebutton-material-light-green-400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(156, 204, 101, 0.1);
}
.form-group .togglebutton-material-light-green-500 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(139, 195, 74, 0.1);
}
.form-group .togglebutton-material-light-green-600 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(124, 179, 66, 0.1);
}
.form-group .togglebutton-material-light-green-700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(104, 159, 56, 0.1);
}
.form-group .togglebutton-material-light-green-800 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(85, 139, 47, 0.1);
}
.form-group .togglebutton-material-light-green-900 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(51, 105, 30, 0.1);
}
.form-group .togglebutton-material-light-green-A100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(204, 255, 144, 0.1);
}
.form-group .togglebutton-material-light-green-A200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(178, 255, 89, 0.1);
}
.form-group .togglebutton-material-light-green-A400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(118, 255, 3, 0.1);
}
.form-group .togglebutton-material-light-green-A700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(100, 221, 23, 0.1);
}
.form-group .togglebutton-material-lime label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(205, 220, 57, 0.1);
}
.form-group .togglebutton-material-lime-50 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(249, 251, 231, 0.1);
}
.form-group .togglebutton-material-lime-100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(240, 244, 195, 0.1);
}
.form-group .togglebutton-material-lime-200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(230, 238, 156, 0.1);
}
.form-group .togglebutton-material-lime-300 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(220, 231, 117, 0.1);
}
.form-group .togglebutton-material-lime-400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(212, 225, 87, 0.1);
}
.form-group .togglebutton-material-lime-500 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(205, 220, 57, 0.1);
}
.form-group .togglebutton-material-lime-600 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(192, 202, 51, 0.1);
}
.form-group .togglebutton-material-lime-700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(175, 180, 43, 0.1);
}
.form-group .togglebutton-material-lime-800 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(158, 157, 36, 0.1);
}
.form-group .togglebutton-material-lime-900 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(130, 119, 23, 0.1);
}
.form-group .togglebutton-material-lime-A100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(244, 255, 129, 0.1);
}
.form-group .togglebutton-material-lime-A200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(238, 255, 65, 0.1);
}
.form-group .togglebutton-material-lime-A400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(198, 255, 0, 0.1);
}
.form-group .togglebutton-material-lime-A700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(174, 234, 0, 0.1);
}
.form-group .togglebutton-material-yellow label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 235, 59, 0.1);
}
.form-group .togglebutton-material-yellow-50 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 253, 231, 0.1);
}
.form-group .togglebutton-material-yellow-100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 249, 196, 0.1);
}
.form-group .togglebutton-material-yellow-200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 245, 157, 0.1);
}
.form-group .togglebutton-material-yellow-300 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 241, 118, 0.1);
}
.form-group .togglebutton-material-yellow-400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 238, 88, 0.1);
}
.form-group .togglebutton-material-yellow-500 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 235, 59, 0.1);
}
.form-group .togglebutton-material-yellow-600 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(253, 216, 53, 0.1);
}
.form-group .togglebutton-material-yellow-700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(251, 192, 45, 0.1);
}
.form-group .togglebutton-material-yellow-800 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(249, 168, 37, 0.1);
}
.form-group .togglebutton-material-yellow-900 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(245, 127, 23, 0.1);
}
.form-group .togglebutton-material-yellow-A100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 255, 141, 0.1);
}
.form-group .togglebutton-material-yellow-A200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 255, 0, 0.1);
}
.form-group .togglebutton-material-yellow-A400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 234, 0, 0.1);
}
.form-group .togglebutton-material-yellow-A700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 214, 0, 0.1);
}
.form-group .togglebutton-material-amber label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 193, 7, 0.1);
}
.form-group .togglebutton-material-amber-50 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 248, 225, 0.1);
}
.form-group .togglebutton-material-amber-100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 236, 179, 0.1);
}
.form-group .togglebutton-material-amber-200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 224, 130, 0.1);
}
.form-group .togglebutton-material-amber-300 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 213, 79, 0.1);
}
.form-group .togglebutton-material-amber-400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 202, 40, 0.1);
}
.form-group .togglebutton-material-amber-500 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 193, 7, 0.1);
}
.form-group .togglebutton-material-amber-600 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 179, 0, 0.1);
}
.form-group .togglebutton-material-amber-700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 160, 0, 0.1);
}
.form-group .togglebutton-material-amber-800 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 143, 0, 0.1);
}
.form-group .togglebutton-material-amber-900 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 111, 0, 0.1);
}
.form-group .togglebutton-material-amber-A100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 229, 127, 0.1);
}
.form-group .togglebutton-material-amber-A200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 215, 64, 0.1);
}
.form-group .togglebutton-material-amber-A400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 196, 0, 0.1);
}
.form-group .togglebutton-material-amber-A700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 171, 0, 0.1);
}
.form-group .togglebutton-material-orange label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 152, 0, 0.1);
}
.form-group .togglebutton-material-orange-50 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 243, 224, 0.1);
}
.form-group .togglebutton-material-orange-100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 224, 178, 0.1);
}
.form-group .togglebutton-material-orange-200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 204, 128, 0.1);
}
.form-group .togglebutton-material-orange-300 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 183, 77, 0.1);
}
.form-group .togglebutton-material-orange-400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 167, 38, 0.1);
}
.form-group .togglebutton-material-orange-500 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 152, 0, 0.1);
}
.form-group .togglebutton-material-orange-600 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(251, 140, 0, 0.1);
}
.form-group .togglebutton-material-orange-700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(245, 124, 0, 0.1);
}
.form-group .togglebutton-material-orange-800 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(239, 108, 0, 0.1);
}
.form-group .togglebutton-material-orange-900 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(230, 81, 0, 0.1);
}
.form-group .togglebutton-material-orange-A100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 209, 128, 0.1);
}
.form-group .togglebutton-material-orange-A200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 171, 64, 0.1);
}
.form-group .togglebutton-material-orange-A400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 145, 0, 0.1);
}
.form-group .togglebutton-material-orange-A700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 109, 0, 0.1);
}
.form-group .togglebutton-material-deep-orange label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 87, 34, 0.1);
}
.form-group .togglebutton-material-deep-orange-50 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(251, 233, 231, 0.1);
}
.form-group .togglebutton-material-deep-orange-100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 204, 188, 0.1);
}
.form-group .togglebutton-material-deep-orange-200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 171, 145, 0.1);
}
.form-group .togglebutton-material-deep-orange-300 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 138, 101, 0.1);
}
.form-group .togglebutton-material-deep-orange-400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 112, 67, 0.1);
}
.form-group .togglebutton-material-deep-orange-500 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 87, 34, 0.1);
}
.form-group .togglebutton-material-deep-orange-600 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(244, 81, 30, 0.1);
}
.form-group .togglebutton-material-deep-orange-700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(230, 74, 25, 0.1);
}
.form-group .togglebutton-material-deep-orange-800 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(216, 67, 21, 0.1);
}
.form-group .togglebutton-material-deep-orange-900 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(191, 54, 12, 0.1);
}
.form-group .togglebutton-material-deep-orange-A100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 158, 128, 0.1);
}
.form-group .togglebutton-material-deep-orange-A200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 110, 64, 0.1);
}
.form-group .togglebutton-material-deep-orange-A400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 61, 0, 0.1);
}
.form-group .togglebutton-material-deep-orange-A700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(221, 44, 0, 0.1);
}
.form-group .togglebutton-material-brown label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(121, 85, 72, 0.1);
}
.form-group .togglebutton-material-brown-50 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(239, 235, 233, 0.1);
}
.form-group .togglebutton-material-brown-100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(215, 204, 200, 0.1);
}
.form-group .togglebutton-material-brown-200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(188, 170, 164, 0.1);
}
.form-group .togglebutton-material-brown-300 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(161, 136, 127, 0.1);
}
.form-group .togglebutton-material-brown-400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(141, 110, 99, 0.1);
}
.form-group .togglebutton-material-brown-500 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(121, 85, 72, 0.1);
}
.form-group .togglebutton-material-brown-600 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(109, 76, 65, 0.1);
}
.form-group .togglebutton-material-brown-700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(93, 64, 55, 0.1);
}
.form-group .togglebutton-material-brown-800 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(78, 52, 46, 0.1);
}
.form-group .togglebutton-material-brown-900 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(62, 39, 35, 0.1);
}
.form-group .togglebutton-material-brown-A100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(215, 204, 200, 0.1);
}
.form-group .togglebutton-material-brown-A200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(188, 170, 164, 0.1);
}
.form-group .togglebutton-material-brown-A400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(141, 110, 99, 0.1);
}
.form-group .togglebutton-material-brown-A700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(93, 64, 55, 0.1);
}
.form-group .togglebutton-material-grey label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(158, 158, 158, 0.1);
}
.form-group .togglebutton-material-grey-50 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(250, 250, 250, 0.1);
}
.form-group .togglebutton-material-grey-100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(245, 245, 245, 0.1);
}
.form-group .togglebutton-material-grey-200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(238, 238, 238, 0.1);
}
.form-group .togglebutton-material-grey-300 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(224, 224, 224, 0.1);
}
.form-group .togglebutton-material-grey-400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(189, 189, 189, 0.1);
}
.form-group .togglebutton-material-grey-500 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(158, 158, 158, 0.1);
}
.form-group .togglebutton-material-grey-600 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(117, 117, 117, 0.1);
}
.form-group .togglebutton-material-grey-700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(97, 97, 97, 0.1);
}
.form-group .togglebutton-material-grey-800 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(66, 66, 66, 0.1);
}
.form-group .togglebutton-material-grey-900 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(33, 33, 33, 0.1);
}
.form-group .togglebutton-material-grey-A100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(245, 245, 245, 0.1);
}
.form-group .togglebutton-material-grey-A200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(238, 238, 238, 0.1);
}
.form-group .togglebutton-material-grey-A400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(189, 189, 189, 0.1);
}
.form-group .togglebutton-material-grey-A700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(97, 97, 97, 0.1);
}
.form-group .togglebutton-material-blue-grey label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(96, 125, 139, 0.1);
}
.form-group .togglebutton-material-blue-grey-50 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(236, 239, 241, 0.1);
}
.form-group .togglebutton-material-blue-grey-100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(207, 216, 220, 0.1);
}
.form-group .togglebutton-material-blue-grey-200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(176, 190, 197, 0.1);
}
.form-group .togglebutton-material-blue-grey-300 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(144, 164, 174, 0.1);
}
.form-group .togglebutton-material-blue-grey-400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(120, 144, 156, 0.1);
}
.form-group .togglebutton-material-blue-grey-500 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(96, 125, 139, 0.1);
}
.form-group .togglebutton-material-blue-grey-600 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(84, 110, 122, 0.1);
}
.form-group .togglebutton-material-blue-grey-700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(69, 90, 100, 0.1);
}
.form-group .togglebutton-material-blue-grey-800 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(55, 71, 79, 0.1);
}
.form-group .togglebutton-material-blue-grey-900 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(38, 50, 56, 0.1);
}
.form-group .togglebutton-material-blue-grey-A100 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(207, 216, 220, 0.1);
}
.form-group .togglebutton-material-blue-grey-A200 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(176, 190, 197, 0.1);
}
.form-group .togglebutton-material-blue-grey-A400 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(120, 144, 156, 0.1);
}
.form-group .togglebutton-material-blue-grey-A700 label input[type=checkbox]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(69, 90, 100, 0.1);
}
.form-horizontal .radio {
  margin-bottom: 10px;
}
.form-group .radio label {
  font-size: 16px;
  line-height: 1.42857143;
  font-weight: normal;
}
.form-group.form-group-sm .radio label {
  font-size: 11px;
  line-height: 1.5;
  font-weight: normal;
}
.form-group.form-group-lg .radio label {
  font-size: 18px;
  line-height: 1.3333333;
  font-weight: normal;
}
.radio label {
  cursor: pointer;
  padding-left: 45px;
  position: relative;
  color: rgba(0, 0, 0, 0.54);
}
.radio label span {
  display: block;
  position: absolute;
  left: 10px;
  top: 2px;
  transition-duration: 0.2s;
}
.radio label .circle {
  border: 2px solid rgba(0, 0, 0, 0.54);
  height: 15px;
  width: 15px;
  border-radius: 100%;
}
.radio label .check {
  height: 15px;
  width: 15px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.84);
  transform: scale3d(0, 0, 0);
}
.radio label .check:after {
  display: block;
  position: absolute;
  content: "";
  background-color: rgba(0, 0, 0, 0.84);
  left: -18px;
  top: -18px;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  z-index: 1;
  opacity: 0;
  margin: 0;
  transform: scale3d(1.5, 1.5, 1);
}
.radio label input[type=radio]:not(:checked) ~ .check:after {
  animation: rippleOff 500ms;
}
.radio label input[type=radio]:checked ~ .check:after {
  animation: rippleOn 500ms;
}
.radio input[type=radio]:checked ~ .check,
.radio-default input[type=radio]:checked ~ .check {
  background-color: rgba(0, 0, 0, 0.84);
}
.radio-black input[type=radio]:checked ~ .check {
  background-color: #000000;
}
.radio-white input[type=radio]:checked ~ .check {
  background-color: #ffffff;
}
.radio-inverse input[type=radio]:checked ~ .check {
  background-color: #3f51b5;
}
.radio-primary input[type=radio]:checked ~ .check {
  background-color: #009688;
}
.radio-success input[type=radio]:checked ~ .check {
  background-color: #4caf50;
}
.radio-info input[type=radio]:checked ~ .check {
  background-color: #03a9f4;
}
.radio-warning input[type=radio]:checked ~ .check {
  background-color: #ff5722;
}
.radio-danger input[type=radio]:checked ~ .check {
  background-color: #f44336;
}
.radio-material-red input[type=radio]:checked ~ .check {
  background-color: #f44336;
}
.radio-material-red-50 input[type=radio]:checked ~ .check {
  background-color: #ffebee;
}
.radio-material-red-100 input[type=radio]:checked ~ .check {
  background-color: #ffcdd2;
}
.radio-material-red-200 input[type=radio]:checked ~ .check {
  background-color: #ef9a9a;
}
.radio-material-red-300 input[type=radio]:checked ~ .check {
  background-color: #e57373;
}
.radio-material-red-400 input[type=radio]:checked ~ .check {
  background-color: #ef5350;
}
.radio-material-red-500 input[type=radio]:checked ~ .check {
  background-color: #f44336;
}
.radio-material-red-600 input[type=radio]:checked ~ .check {
  background-color: #e53935;
}
.radio-material-red-700 input[type=radio]:checked ~ .check {
  background-color: #d32f2f;
}
.radio-material-red-800 input[type=radio]:checked ~ .check {
  background-color: #c62828;
}
.radio-material-red-900 input[type=radio]:checked ~ .check {
  background-color: #b71c1c;
}
.radio-material-red-A100 input[type=radio]:checked ~ .check {
  background-color: #ff8a80;
}
.radio-material-red-A200 input[type=radio]:checked ~ .check {
  background-color: #ff5252;
}
.radio-material-red-A400 input[type=radio]:checked ~ .check {
  background-color: #ff1744;
}
.radio-material-red-A700 input[type=radio]:checked ~ .check {
  background-color: #d50000;
}
.radio-material-pink input[type=radio]:checked ~ .check {
  background-color: #e91e63;
}
.radio-material-pink-50 input[type=radio]:checked ~ .check {
  background-color: #fce4ec;
}
.radio-material-pink-100 input[type=radio]:checked ~ .check {
  background-color: #f8bbd0;
}
.radio-material-pink-200 input[type=radio]:checked ~ .check {
  background-color: #f48fb1;
}
.radio-material-pink-300 input[type=radio]:checked ~ .check {
  background-color: #f06292;
}
.radio-material-pink-400 input[type=radio]:checked ~ .check {
  background-color: #ec407a;
}
.radio-material-pink-500 input[type=radio]:checked ~ .check {
  background-color: #e91e63;
}
.radio-material-pink-600 input[type=radio]:checked ~ .check {
  background-color: #d81b60;
}
.radio-material-pink-700 input[type=radio]:checked ~ .check {
  background-color: #c2185b;
}
.radio-material-pink-800 input[type=radio]:checked ~ .check {
  background-color: #ad1457;
}
.radio-material-pink-900 input[type=radio]:checked ~ .check {
  background-color: #880e4f;
}
.radio-material-pink-A100 input[type=radio]:checked ~ .check {
  background-color: #ff80ab;
}
.radio-material-pink-A200 input[type=radio]:checked ~ .check {
  background-color: #ff4081;
}
.radio-material-pink-A400 input[type=radio]:checked ~ .check {
  background-color: #f50057;
}
.radio-material-pink-A700 input[type=radio]:checked ~ .check {
  background-color: #c51162;
}
.radio-material-purple input[type=radio]:checked ~ .check {
  background-color: #9c27b0;
}
.radio-material-purple-50 input[type=radio]:checked ~ .check {
  background-color: #f3e5f5;
}
.radio-material-purple-100 input[type=radio]:checked ~ .check {
  background-color: #e1bee7;
}
.radio-material-purple-200 input[type=radio]:checked ~ .check {
  background-color: #ce93d8;
}
.radio-material-purple-300 input[type=radio]:checked ~ .check {
  background-color: #ba68c8;
}
.radio-material-purple-400 input[type=radio]:checked ~ .check {
  background-color: #ab47bc;
}
.radio-material-purple-500 input[type=radio]:checked ~ .check {
  background-color: #9c27b0;
}
.radio-material-purple-600 input[type=radio]:checked ~ .check {
  background-color: #8e24aa;
}
.radio-material-purple-700 input[type=radio]:checked ~ .check {
  background-color: #7b1fa2;
}
.radio-material-purple-800 input[type=radio]:checked ~ .check {
  background-color: #6a1b9a;
}
.radio-material-purple-900 input[type=radio]:checked ~ .check {
  background-color: #4a148c;
}
.radio-material-purple-A100 input[type=radio]:checked ~ .check {
  background-color: #ea80fc;
}
.radio-material-purple-A200 input[type=radio]:checked ~ .check {
  background-color: #e040fb;
}
.radio-material-purple-A400 input[type=radio]:checked ~ .check {
  background-color: #d500f9;
}
.radio-material-purple-A700 input[type=radio]:checked ~ .check {
  background-color: #aa00ff;
}
.radio-material-deep-purple input[type=radio]:checked ~ .check {
  background-color: #673ab7;
}
.radio-material-deep-purple-50 input[type=radio]:checked ~ .check {
  background-color: #ede7f6;
}
.radio-material-deep-purple-100 input[type=radio]:checked ~ .check {
  background-color: #d1c4e9;
}
.radio-material-deep-purple-200 input[type=radio]:checked ~ .check {
  background-color: #b39ddb;
}
.radio-material-deep-purple-300 input[type=radio]:checked ~ .check {
  background-color: #9575cd;
}
.radio-material-deep-purple-400 input[type=radio]:checked ~ .check {
  background-color: #7e57c2;
}
.radio-material-deep-purple-500 input[type=radio]:checked ~ .check {
  background-color: #673ab7;
}
.radio-material-deep-purple-600 input[type=radio]:checked ~ .check {
  background-color: #5e35b1;
}
.radio-material-deep-purple-700 input[type=radio]:checked ~ .check {
  background-color: #512da8;
}
.radio-material-deep-purple-800 input[type=radio]:checked ~ .check {
  background-color: #4527a0;
}
.radio-material-deep-purple-900 input[type=radio]:checked ~ .check {
  background-color: #311b92;
}
.radio-material-deep-purple-A100 input[type=radio]:checked ~ .check {
  background-color: #b388ff;
}
.radio-material-deep-purple-A200 input[type=radio]:checked ~ .check {
  background-color: #7c4dff;
}
.radio-material-deep-purple-A400 input[type=radio]:checked ~ .check {
  background-color: #651fff;
}
.radio-material-deep-purple-A700 input[type=radio]:checked ~ .check {
  background-color: #6200ea;
}
.radio-material-indigo input[type=radio]:checked ~ .check {
  background-color: #3f51b5;
}
.radio-material-indigo-50 input[type=radio]:checked ~ .check {
  background-color: #e8eaf6;
}
.radio-material-indigo-100 input[type=radio]:checked ~ .check {
  background-color: #c5cae9;
}
.radio-material-indigo-200 input[type=radio]:checked ~ .check {
  background-color: #9fa8da;
}
.radio-material-indigo-300 input[type=radio]:checked ~ .check {
  background-color: #7986cb;
}
.radio-material-indigo-400 input[type=radio]:checked ~ .check {
  background-color: #5c6bc0;
}
.radio-material-indigo-500 input[type=radio]:checked ~ .check {
  background-color: #3f51b5;
}
.radio-material-indigo-600 input[type=radio]:checked ~ .check {
  background-color: #3949ab;
}
.radio-material-indigo-700 input[type=radio]:checked ~ .check {
  background-color: #303f9f;
}
.radio-material-indigo-800 input[type=radio]:checked ~ .check {
  background-color: #283593;
}
.radio-material-indigo-900 input[type=radio]:checked ~ .check {
  background-color: #1a237e;
}
.radio-material-indigo-A100 input[type=radio]:checked ~ .check {
  background-color: #8c9eff;
}
.radio-material-indigo-A200 input[type=radio]:checked ~ .check {
  background-color: #536dfe;
}
.radio-material-indigo-A400 input[type=radio]:checked ~ .check {
  background-color: #3d5afe;
}
.radio-material-indigo-A700 input[type=radio]:checked ~ .check {
  background-color: #304ffe;
}
.radio-material-blue input[type=radio]:checked ~ .check {
  background-color: #2196f3;
}
.radio-material-blue-50 input[type=radio]:checked ~ .check {
  background-color: #e3f2fd;
}
.radio-material-blue-100 input[type=radio]:checked ~ .check {
  background-color: #bbdefb;
}
.radio-material-blue-200 input[type=radio]:checked ~ .check {
  background-color: #90caf9;
}
.radio-material-blue-300 input[type=radio]:checked ~ .check {
  background-color: #64b5f6;
}
.radio-material-blue-400 input[type=radio]:checked ~ .check {
  background-color: #42a5f5;
}
.radio-material-blue-500 input[type=radio]:checked ~ .check {
  background-color: #2196f3;
}
.radio-material-blue-600 input[type=radio]:checked ~ .check {
  background-color: #1e88e5;
}
.radio-material-blue-700 input[type=radio]:checked ~ .check {
  background-color: #1976d2;
}
.radio-material-blue-800 input[type=radio]:checked ~ .check {
  background-color: #1565c0;
}
.radio-material-blue-900 input[type=radio]:checked ~ .check {
  background-color: #0d47a1;
}
.radio-material-blue-A100 input[type=radio]:checked ~ .check {
  background-color: #82b1ff;
}
.radio-material-blue-A200 input[type=radio]:checked ~ .check {
  background-color: #448aff;
}
.radio-material-blue-A400 input[type=radio]:checked ~ .check {
  background-color: #2979ff;
}
.radio-material-blue-A700 input[type=radio]:checked ~ .check {
  background-color: #2962ff;
}
.radio-material-light-blue input[type=radio]:checked ~ .check {
  background-color: #03a9f4;
}
.radio-material-light-blue-50 input[type=radio]:checked ~ .check {
  background-color: #e1f5fe;
}
.radio-material-light-blue-100 input[type=radio]:checked ~ .check {
  background-color: #b3e5fc;
}
.radio-material-light-blue-200 input[type=radio]:checked ~ .check {
  background-color: #81d4fa;
}
.radio-material-light-blue-300 input[type=radio]:checked ~ .check {
  background-color: #4fc3f7;
}
.radio-material-light-blue-400 input[type=radio]:checked ~ .check {
  background-color: #29b6f6;
}
.radio-material-light-blue-500 input[type=radio]:checked ~ .check {
  background-color: #03a9f4;
}
.radio-material-light-blue-600 input[type=radio]:checked ~ .check {
  background-color: #039be5;
}
.radio-material-light-blue-700 input[type=radio]:checked ~ .check {
  background-color: #0288d1;
}
.radio-material-light-blue-800 input[type=radio]:checked ~ .check {
  background-color: #0277bd;
}
.radio-material-light-blue-900 input[type=radio]:checked ~ .check {
  background-color: #01579b;
}
.radio-material-light-blue-A100 input[type=radio]:checked ~ .check {
  background-color: #80d8ff;
}
.radio-material-light-blue-A200 input[type=radio]:checked ~ .check {
  background-color: #40c4ff;
}
.radio-material-light-blue-A400 input[type=radio]:checked ~ .check {
  background-color: #00b0ff;
}
.radio-material-light-blue-A700 input[type=radio]:checked ~ .check {
  background-color: #0091ea;
}
.radio-material-cyan input[type=radio]:checked ~ .check {
  background-color: #00bcd4;
}
.radio-material-cyan-50 input[type=radio]:checked ~ .check {
  background-color: #e0f7fa;
}
.radio-material-cyan-100 input[type=radio]:checked ~ .check {
  background-color: #b2ebf2;
}
.radio-material-cyan-200 input[type=radio]:checked ~ .check {
  background-color: #80deea;
}
.radio-material-cyan-300 input[type=radio]:checked ~ .check {
  background-color: #4dd0e1;
}
.radio-material-cyan-400 input[type=radio]:checked ~ .check {
  background-color: #26c6da;
}
.radio-material-cyan-500 input[type=radio]:checked ~ .check {
  background-color: #00bcd4;
}
.radio-material-cyan-600 input[type=radio]:checked ~ .check {
  background-color: #00acc1;
}
.radio-material-cyan-700 input[type=radio]:checked ~ .check {
  background-color: #0097a7;
}
.radio-material-cyan-800 input[type=radio]:checked ~ .check {
  background-color: #00838f;
}
.radio-material-cyan-900 input[type=radio]:checked ~ .check {
  background-color: #006064;
}
.radio-material-cyan-A100 input[type=radio]:checked ~ .check {
  background-color: #84ffff;
}
.radio-material-cyan-A200 input[type=radio]:checked ~ .check {
  background-color: #18ffff;
}
.radio-material-cyan-A400 input[type=radio]:checked ~ .check {
  background-color: #00e5ff;
}
.radio-material-cyan-A700 input[type=radio]:checked ~ .check {
  background-color: #00b8d4;
}
.radio-material-teal input[type=radio]:checked ~ .check {
  background-color: #009688;
}
.radio-material-teal-50 input[type=radio]:checked ~ .check {
  background-color: #e0f2f1;
}
.radio-material-teal-100 input[type=radio]:checked ~ .check {
  background-color: #b2dfdb;
}
.radio-material-teal-200 input[type=radio]:checked ~ .check {
  background-color: #80cbc4;
}
.radio-material-teal-300 input[type=radio]:checked ~ .check {
  background-color: #4db6ac;
}
.radio-material-teal-400 input[type=radio]:checked ~ .check {
  background-color: #26a69a;
}
.radio-material-teal-500 input[type=radio]:checked ~ .check {
  background-color: #009688;
}
.radio-material-teal-600 input[type=radio]:checked ~ .check {
  background-color: #00897b;
}
.radio-material-teal-700 input[type=radio]:checked ~ .check {
  background-color: #00796b;
}
.radio-material-teal-800 input[type=radio]:checked ~ .check {
  background-color: #00695c;
}
.radio-material-teal-900 input[type=radio]:checked ~ .check {
  background-color: #004d40;
}
.radio-material-teal-A100 input[type=radio]:checked ~ .check {
  background-color: #a7ffeb;
}
.radio-material-teal-A200 input[type=radio]:checked ~ .check {
  background-color: #64ffda;
}
.radio-material-teal-A400 input[type=radio]:checked ~ .check {
  background-color: #1de9b6;
}
.radio-material-teal-A700 input[type=radio]:checked ~ .check {
  background-color: #00bfa5;
}
.radio-material-green input[type=radio]:checked ~ .check {
  background-color: #4caf50;
}
.radio-material-green-50 input[type=radio]:checked ~ .check {
  background-color: #e8f5e9;
}
.radio-material-green-100 input[type=radio]:checked ~ .check {
  background-color: #c8e6c9;
}
.radio-material-green-200 input[type=radio]:checked ~ .check {
  background-color: #a5d6a7;
}
.radio-material-green-300 input[type=radio]:checked ~ .check {
  background-color: #81c784;
}
.radio-material-green-400 input[type=radio]:checked ~ .check {
  background-color: #66bb6a;
}
.radio-material-green-500 input[type=radio]:checked ~ .check {
  background-color: #4caf50;
}
.radio-material-green-600 input[type=radio]:checked ~ .check {
  background-color: #43a047;
}
.radio-material-green-700 input[type=radio]:checked ~ .check {
  background-color: #388e3c;
}
.radio-material-green-800 input[type=radio]:checked ~ .check {
  background-color: #2e7d32;
}
.radio-material-green-900 input[type=radio]:checked ~ .check {
  background-color: #1b5e20;
}
.radio-material-green-A100 input[type=radio]:checked ~ .check {
  background-color: #b9f6ca;
}
.radio-material-green-A200 input[type=radio]:checked ~ .check {
  background-color: #69f0ae;
}
.radio-material-green-A400 input[type=radio]:checked ~ .check {
  background-color: #00e676;
}
.radio-material-green-A700 input[type=radio]:checked ~ .check {
  background-color: #00c853;
}
.radio-material-light-green input[type=radio]:checked ~ .check {
  background-color: #8bc34a;
}
.radio-material-light-green-50 input[type=radio]:checked ~ .check {
  background-color: #f1f8e9;
}
.radio-material-light-green-100 input[type=radio]:checked ~ .check {
  background-color: #dcedc8;
}
.radio-material-light-green-200 input[type=radio]:checked ~ .check {
  background-color: #c5e1a5;
}
.radio-material-light-green-300 input[type=radio]:checked ~ .check {
  background-color: #aed581;
}
.radio-material-light-green-400 input[type=radio]:checked ~ .check {
  background-color: #9ccc65;
}
.radio-material-light-green-500 input[type=radio]:checked ~ .check {
  background-color: #8bc34a;
}
.radio-material-light-green-600 input[type=radio]:checked ~ .check {
  background-color: #7cb342;
}
.radio-material-light-green-700 input[type=radio]:checked ~ .check {
  background-color: #689f38;
}
.radio-material-light-green-800 input[type=radio]:checked ~ .check {
  background-color: #558b2f;
}
.radio-material-light-green-900 input[type=radio]:checked ~ .check {
  background-color: #33691e;
}
.radio-material-light-green-A100 input[type=radio]:checked ~ .check {
  background-color: #ccff90;
}
.radio-material-light-green-A200 input[type=radio]:checked ~ .check {
  background-color: #b2ff59;
}
.radio-material-light-green-A400 input[type=radio]:checked ~ .check {
  background-color: #76ff03;
}
.radio-material-light-green-A700 input[type=radio]:checked ~ .check {
  background-color: #64dd17;
}
.radio-material-lime input[type=radio]:checked ~ .check {
  background-color: #cddc39;
}
.radio-material-lime-50 input[type=radio]:checked ~ .check {
  background-color: #f9fbe7;
}
.radio-material-lime-100 input[type=radio]:checked ~ .check {
  background-color: #f0f4c3;
}
.radio-material-lime-200 input[type=radio]:checked ~ .check {
  background-color: #e6ee9c;
}
.radio-material-lime-300 input[type=radio]:checked ~ .check {
  background-color: #dce775;
}
.radio-material-lime-400 input[type=radio]:checked ~ .check {
  background-color: #d4e157;
}
.radio-material-lime-500 input[type=radio]:checked ~ .check {
  background-color: #cddc39;
}
.radio-material-lime-600 input[type=radio]:checked ~ .check {
  background-color: #c0ca33;
}
.radio-material-lime-700 input[type=radio]:checked ~ .check {
  background-color: #afb42b;
}
.radio-material-lime-800 input[type=radio]:checked ~ .check {
  background-color: #9e9d24;
}
.radio-material-lime-900 input[type=radio]:checked ~ .check {
  background-color: #827717;
}
.radio-material-lime-A100 input[type=radio]:checked ~ .check {
  background-color: #f4ff81;
}
.radio-material-lime-A200 input[type=radio]:checked ~ .check {
  background-color: #eeff41;
}
.radio-material-lime-A400 input[type=radio]:checked ~ .check {
  background-color: #c6ff00;
}
.radio-material-lime-A700 input[type=radio]:checked ~ .check {
  background-color: #aeea00;
}
.radio-material-yellow input[type=radio]:checked ~ .check {
  background-color: #ffeb3b;
}
.radio-material-yellow-50 input[type=radio]:checked ~ .check {
  background-color: #fffde7;
}
.radio-material-yellow-100 input[type=radio]:checked ~ .check {
  background-color: #fff9c4;
}
.radio-material-yellow-200 input[type=radio]:checked ~ .check {
  background-color: #fff59d;
}
.radio-material-yellow-300 input[type=radio]:checked ~ .check {
  background-color: #fff176;
}
.radio-material-yellow-400 input[type=radio]:checked ~ .check {
  background-color: #ffee58;
}
.radio-material-yellow-500 input[type=radio]:checked ~ .check {
  background-color: #ffeb3b;
}
.radio-material-yellow-600 input[type=radio]:checked ~ .check {
  background-color: #fdd835;
}
.radio-material-yellow-700 input[type=radio]:checked ~ .check {
  background-color: #fbc02d;
}
.radio-material-yellow-800 input[type=radio]:checked ~ .check {
  background-color: #f9a825;
}
.radio-material-yellow-900 input[type=radio]:checked ~ .check {
  background-color: #f57f17;
}
.radio-material-yellow-A100 input[type=radio]:checked ~ .check {
  background-color: #ffff8d;
}
.radio-material-yellow-A200 input[type=radio]:checked ~ .check {
  background-color: #ffff00;
}
.radio-material-yellow-A400 input[type=radio]:checked ~ .check {
  background-color: #ffea00;
}
.radio-material-yellow-A700 input[type=radio]:checked ~ .check {
  background-color: #ffd600;
}
.radio-material-amber input[type=radio]:checked ~ .check {
  background-color: #ffc107;
}
.radio-material-amber-50 input[type=radio]:checked ~ .check {
  background-color: #fff8e1;
}
.radio-material-amber-100 input[type=radio]:checked ~ .check {
  background-color: #ffecb3;
}
.radio-material-amber-200 input[type=radio]:checked ~ .check {
  background-color: #ffe082;
}
.radio-material-amber-300 input[type=radio]:checked ~ .check {
  background-color: #ffd54f;
}
.radio-material-amber-400 input[type=radio]:checked ~ .check {
  background-color: #ffca28;
}
.radio-material-amber-500 input[type=radio]:checked ~ .check {
  background-color: #ffc107;
}
.radio-material-amber-600 input[type=radio]:checked ~ .check {
  background-color: #ffb300;
}
.radio-material-amber-700 input[type=radio]:checked ~ .check {
  background-color: #ffa000;
}
.radio-material-amber-800 input[type=radio]:checked ~ .check {
  background-color: #ff8f00;
}
.radio-material-amber-900 input[type=radio]:checked ~ .check {
  background-color: #ff6f00;
}
.radio-material-amber-A100 input[type=radio]:checked ~ .check {
  background-color: #ffe57f;
}
.radio-material-amber-A200 input[type=radio]:checked ~ .check {
  background-color: #ffd740;
}
.radio-material-amber-A400 input[type=radio]:checked ~ .check {
  background-color: #ffc400;
}
.radio-material-amber-A700 input[type=radio]:checked ~ .check {
  background-color: #ffab00;
}
.radio-material-orange input[type=radio]:checked ~ .check {
  background-color: #ff9800;
}
.radio-material-orange-50 input[type=radio]:checked ~ .check {
  background-color: #fff3e0;
}
.radio-material-orange-100 input[type=radio]:checked ~ .check {
  background-color: #ffe0b2;
}
.radio-material-orange-200 input[type=radio]:checked ~ .check {
  background-color: #ffcc80;
}
.radio-material-orange-300 input[type=radio]:checked ~ .check {
  background-color: #ffb74d;
}
.radio-material-orange-400 input[type=radio]:checked ~ .check {
  background-color: #ffa726;
}
.radio-material-orange-500 input[type=radio]:checked ~ .check {
  background-color: #ff9800;
}
.radio-material-orange-600 input[type=radio]:checked ~ .check {
  background-color: #fb8c00;
}
.radio-material-orange-700 input[type=radio]:checked ~ .check {
  background-color: #f57c00;
}
.radio-material-orange-800 input[type=radio]:checked ~ .check {
  background-color: #ef6c00;
}
.radio-material-orange-900 input[type=radio]:checked ~ .check {
  background-color: #e65100;
}
.radio-material-orange-A100 input[type=radio]:checked ~ .check {
  background-color: #ffd180;
}
.radio-material-orange-A200 input[type=radio]:checked ~ .check {
  background-color: #ffab40;
}
.radio-material-orange-A400 input[type=radio]:checked ~ .check {
  background-color: #ff9100;
}
.radio-material-orange-A700 input[type=radio]:checked ~ .check {
  background-color: #ff6d00;
}
.radio-material-deep-orange input[type=radio]:checked ~ .check {
  background-color: #ff5722;
}
.radio-material-deep-orange-50 input[type=radio]:checked ~ .check {
  background-color: #fbe9e7;
}
.radio-material-deep-orange-100 input[type=radio]:checked ~ .check {
  background-color: #ffccbc;
}
.radio-material-deep-orange-200 input[type=radio]:checked ~ .check {
  background-color: #ffab91;
}
.radio-material-deep-orange-300 input[type=radio]:checked ~ .check {
  background-color: #ff8a65;
}
.radio-material-deep-orange-400 input[type=radio]:checked ~ .check {
  background-color: #ff7043;
}
.radio-material-deep-orange-500 input[type=radio]:checked ~ .check {
  background-color: #ff5722;
}
.radio-material-deep-orange-600 input[type=radio]:checked ~ .check {
  background-color: #f4511e;
}
.radio-material-deep-orange-700 input[type=radio]:checked ~ .check {
  background-color: #e64a19;
}
.radio-material-deep-orange-800 input[type=radio]:checked ~ .check {
  background-color: #d84315;
}
.radio-material-deep-orange-900 input[type=radio]:checked ~ .check {
  background-color: #bf360c;
}
.radio-material-deep-orange-A100 input[type=radio]:checked ~ .check {
  background-color: #ff9e80;
}
.radio-material-deep-orange-A200 input[type=radio]:checked ~ .check {
  background-color: #ff6e40;
}
.radio-material-deep-orange-A400 input[type=radio]:checked ~ .check {
  background-color: #ff3d00;
}
.radio-material-deep-orange-A700 input[type=radio]:checked ~ .check {
  background-color: #dd2c00;
}
.radio-material-brown input[type=radio]:checked ~ .check {
  background-color: #795548;
}
.radio-material-brown-50 input[type=radio]:checked ~ .check {
  background-color: #efebe9;
}
.radio-material-brown-100 input[type=radio]:checked ~ .check {
  background-color: #d7ccc8;
}
.radio-material-brown-200 input[type=radio]:checked ~ .check {
  background-color: #bcaaa4;
}
.radio-material-brown-300 input[type=radio]:checked ~ .check {
  background-color: #a1887f;
}
.radio-material-brown-400 input[type=radio]:checked ~ .check {
  background-color: #8d6e63;
}
.radio-material-brown-500 input[type=radio]:checked ~ .check {
  background-color: #795548;
}
.radio-material-brown-600 input[type=radio]:checked ~ .check {
  background-color: #6d4c41;
}
.radio-material-brown-700 input[type=radio]:checked ~ .check {
  background-color: #5d4037;
}
.radio-material-brown-800 input[type=radio]:checked ~ .check {
  background-color: #4e342e;
}
.radio-material-brown-900 input[type=radio]:checked ~ .check {
  background-color: #3e2723;
}
.radio-material-brown-A100 input[type=radio]:checked ~ .check {
  background-color: #d7ccc8;
}
.radio-material-brown-A200 input[type=radio]:checked ~ .check {
  background-color: #bcaaa4;
}
.radio-material-brown-A400 input[type=radio]:checked ~ .check {
  background-color: #8d6e63;
}
.radio-material-brown-A700 input[type=radio]:checked ~ .check {
  background-color: #5d4037;
}
.radio-material-grey input[type=radio]:checked ~ .check {
  background-color: #9e9e9e;
}
.radio-material-grey-50 input[type=radio]:checked ~ .check {
  background-color: #fafafa;
}
.radio-material-grey-100 input[type=radio]:checked ~ .check {
  background-color: #f5f5f5;
}
.radio-material-grey-200 input[type=radio]:checked ~ .check {
  background-color: #eeeeee;
}
.radio-material-grey-300 input[type=radio]:checked ~ .check {
  background-color: #e0e0e0;
}
.radio-material-grey-400 input[type=radio]:checked ~ .check {
  background-color: #bdbdbd;
}
.radio-material-grey-500 input[type=radio]:checked ~ .check {
  background-color: #9e9e9e;
}
.radio-material-grey-600 input[type=radio]:checked ~ .check {
  background-color: #757575;
}
.radio-material-grey-700 input[type=radio]:checked ~ .check {
  background-color: #616161;
}
.radio-material-grey-800 input[type=radio]:checked ~ .check {
  background-color: #424242;
}
.radio-material-grey-900 input[type=radio]:checked ~ .check {
  background-color: #212121;
}
.radio-material-grey-A100 input[type=radio]:checked ~ .check {
  background-color: #f5f5f5;
}
.radio-material-grey-A200 input[type=radio]:checked ~ .check {
  background-color: #eeeeee;
}
.radio-material-grey-A400 input[type=radio]:checked ~ .check {
  background-color: #bdbdbd;
}
.radio-material-grey-A700 input[type=radio]:checked ~ .check {
  background-color: #616161;
}
.radio-material-blue-grey input[type=radio]:checked ~ .check {
  background-color: #607d8b;
}
.radio-material-blue-grey-50 input[type=radio]:checked ~ .check {
  background-color: #eceff1;
}
.radio-material-blue-grey-100 input[type=radio]:checked ~ .check {
  background-color: #cfd8dc;
}
.radio-material-blue-grey-200 input[type=radio]:checked ~ .check {
  background-color: #b0bec5;
}
.radio-material-blue-grey-300 input[type=radio]:checked ~ .check {
  background-color: #90a4ae;
}
.radio-material-blue-grey-400 input[type=radio]:checked ~ .check {
  background-color: #78909c;
}
.radio-material-blue-grey-500 input[type=radio]:checked ~ .check {
  background-color: #607d8b;
}
.radio-material-blue-grey-600 input[type=radio]:checked ~ .check {
  background-color: #546e7a;
}
.radio-material-blue-grey-700 input[type=radio]:checked ~ .check {
  background-color: #455a64;
}
.radio-material-blue-grey-800 input[type=radio]:checked ~ .check {
  background-color: #37474f;
}
.radio-material-blue-grey-900 input[type=radio]:checked ~ .check {
  background-color: #263238;
}
.radio-material-blue-grey-A100 input[type=radio]:checked ~ .check {
  background-color: #cfd8dc;
}
.radio-material-blue-grey-A200 input[type=radio]:checked ~ .check {
  background-color: #b0bec5;
}
.radio-material-blue-grey-A400 input[type=radio]:checked ~ .check {
  background-color: #78909c;
}
.radio-material-blue-grey-A700 input[type=radio]:checked ~ .check {
  background-color: #455a64;
}
.radio input[type=radio]:checked ~ .circle,
.radio-default input[type=radio]:checked ~ .circle {
  border-color: rgba(0, 0, 0, 0.84);
}
.radio-black input[type=radio]:checked ~ .circle {
  border-color: #000000;
}
.radio-white input[type=radio]:checked ~ .circle {
  border-color: #ffffff;
}
.radio-inverse input[type=radio]:checked ~ .circle {
  border-color: #3f51b5;
}
.radio-primary input[type=radio]:checked ~ .circle {
  border-color: #009688;
}
.radio-success input[type=radio]:checked ~ .circle {
  border-color: #4caf50;
}
.radio-info input[type=radio]:checked ~ .circle {
  border-color: #03a9f4;
}
.radio-warning input[type=radio]:checked ~ .circle {
  border-color: #ff5722;
}
.radio-danger input[type=radio]:checked ~ .circle {
  border-color: #f44336;
}
.radio-material-red input[type=radio]:checked ~ .circle {
  border-color: #f44336;
}
.radio-material-red-50 input[type=radio]:checked ~ .circle {
  border-color: #ffebee;
}
.radio-material-red-100 input[type=radio]:checked ~ .circle {
  border-color: #ffcdd2;
}
.radio-material-red-200 input[type=radio]:checked ~ .circle {
  border-color: #ef9a9a;
}
.radio-material-red-300 input[type=radio]:checked ~ .circle {
  border-color: #e57373;
}
.radio-material-red-400 input[type=radio]:checked ~ .circle {
  border-color: #ef5350;
}
.radio-material-red-500 input[type=radio]:checked ~ .circle {
  border-color: #f44336;
}
.radio-material-red-600 input[type=radio]:checked ~ .circle {
  border-color: #e53935;
}
.radio-material-red-700 input[type=radio]:checked ~ .circle {
  border-color: #d32f2f;
}
.radio-material-red-800 input[type=radio]:checked ~ .circle {
  border-color: #c62828;
}
.radio-material-red-900 input[type=radio]:checked ~ .circle {
  border-color: #b71c1c;
}
.radio-material-red-A100 input[type=radio]:checked ~ .circle {
  border-color: #ff8a80;
}
.radio-material-red-A200 input[type=radio]:checked ~ .circle {
  border-color: #ff5252;
}
.radio-material-red-A400 input[type=radio]:checked ~ .circle {
  border-color: #ff1744;
}
.radio-material-red-A700 input[type=radio]:checked ~ .circle {
  border-color: #d50000;
}
.radio-material-pink input[type=radio]:checked ~ .circle {
  border-color: #e91e63;
}
.radio-material-pink-50 input[type=radio]:checked ~ .circle {
  border-color: #fce4ec;
}
.radio-material-pink-100 input[type=radio]:checked ~ .circle {
  border-color: #f8bbd0;
}
.radio-material-pink-200 input[type=radio]:checked ~ .circle {
  border-color: #f48fb1;
}
.radio-material-pink-300 input[type=radio]:checked ~ .circle {
  border-color: #f06292;
}
.radio-material-pink-400 input[type=radio]:checked ~ .circle {
  border-color: #ec407a;
}
.radio-material-pink-500 input[type=radio]:checked ~ .circle {
  border-color: #e91e63;
}
.radio-material-pink-600 input[type=radio]:checked ~ .circle {
  border-color: #d81b60;
}
.radio-material-pink-700 input[type=radio]:checked ~ .circle {
  border-color: #c2185b;
}
.radio-material-pink-800 input[type=radio]:checked ~ .circle {
  border-color: #ad1457;
}
.radio-material-pink-900 input[type=radio]:checked ~ .circle {
  border-color: #880e4f;
}
.radio-material-pink-A100 input[type=radio]:checked ~ .circle {
  border-color: #ff80ab;
}
.radio-material-pink-A200 input[type=radio]:checked ~ .circle {
  border-color: #ff4081;
}
.radio-material-pink-A400 input[type=radio]:checked ~ .circle {
  border-color: #f50057;
}
.radio-material-pink-A700 input[type=radio]:checked ~ .circle {
  border-color: #c51162;
}
.radio-material-purple input[type=radio]:checked ~ .circle {
  border-color: #9c27b0;
}
.radio-material-purple-50 input[type=radio]:checked ~ .circle {
  border-color: #f3e5f5;
}
.radio-material-purple-100 input[type=radio]:checked ~ .circle {
  border-color: #e1bee7;
}
.radio-material-purple-200 input[type=radio]:checked ~ .circle {
  border-color: #ce93d8;
}
.radio-material-purple-300 input[type=radio]:checked ~ .circle {
  border-color: #ba68c8;
}
.radio-material-purple-400 input[type=radio]:checked ~ .circle {
  border-color: #ab47bc;
}
.radio-material-purple-500 input[type=radio]:checked ~ .circle {
  border-color: #9c27b0;
}
.radio-material-purple-600 input[type=radio]:checked ~ .circle {
  border-color: #8e24aa;
}
.radio-material-purple-700 input[type=radio]:checked ~ .circle {
  border-color: #7b1fa2;
}
.radio-material-purple-800 input[type=radio]:checked ~ .circle {
  border-color: #6a1b9a;
}
.radio-material-purple-900 input[type=radio]:checked ~ .circle {
  border-color: #4a148c;
}
.radio-material-purple-A100 input[type=radio]:checked ~ .circle {
  border-color: #ea80fc;
}
.radio-material-purple-A200 input[type=radio]:checked ~ .circle {
  border-color: #e040fb;
}
.radio-material-purple-A400 input[type=radio]:checked ~ .circle {
  border-color: #d500f9;
}
.radio-material-purple-A700 input[type=radio]:checked ~ .circle {
  border-color: #aa00ff;
}
.radio-material-deep-purple input[type=radio]:checked ~ .circle {
  border-color: #673ab7;
}
.radio-material-deep-purple-50 input[type=radio]:checked ~ .circle {
  border-color: #ede7f6;
}
.radio-material-deep-purple-100 input[type=radio]:checked ~ .circle {
  border-color: #d1c4e9;
}
.radio-material-deep-purple-200 input[type=radio]:checked ~ .circle {
  border-color: #b39ddb;
}
.radio-material-deep-purple-300 input[type=radio]:checked ~ .circle {
  border-color: #9575cd;
}
.radio-material-deep-purple-400 input[type=radio]:checked ~ .circle {
  border-color: #7e57c2;
}
.radio-material-deep-purple-500 input[type=radio]:checked ~ .circle {
  border-color: #673ab7;
}
.radio-material-deep-purple-600 input[type=radio]:checked ~ .circle {
  border-color: #5e35b1;
}
.radio-material-deep-purple-700 input[type=radio]:checked ~ .circle {
  border-color: #512da8;
}
.radio-material-deep-purple-800 input[type=radio]:checked ~ .circle {
  border-color: #4527a0;
}
.radio-material-deep-purple-900 input[type=radio]:checked ~ .circle {
  border-color: #311b92;
}
.radio-material-deep-purple-A100 input[type=radio]:checked ~ .circle {
  border-color: #b388ff;
}
.radio-material-deep-purple-A200 input[type=radio]:checked ~ .circle {
  border-color: #7c4dff;
}
.radio-material-deep-purple-A400 input[type=radio]:checked ~ .circle {
  border-color: #651fff;
}
.radio-material-deep-purple-A700 input[type=radio]:checked ~ .circle {
  border-color: #6200ea;
}
.radio-material-indigo input[type=radio]:checked ~ .circle {
  border-color: #3f51b5;
}
.radio-material-indigo-50 input[type=radio]:checked ~ .circle {
  border-color: #e8eaf6;
}
.radio-material-indigo-100 input[type=radio]:checked ~ .circle {
  border-color: #c5cae9;
}
.radio-material-indigo-200 input[type=radio]:checked ~ .circle {
  border-color: #9fa8da;
}
.radio-material-indigo-300 input[type=radio]:checked ~ .circle {
  border-color: #7986cb;
}
.radio-material-indigo-400 input[type=radio]:checked ~ .circle {
  border-color: #5c6bc0;
}
.radio-material-indigo-500 input[type=radio]:checked ~ .circle {
  border-color: #3f51b5;
}
.radio-material-indigo-600 input[type=radio]:checked ~ .circle {
  border-color: #3949ab;
}
.radio-material-indigo-700 input[type=radio]:checked ~ .circle {
  border-color: #303f9f;
}
.radio-material-indigo-800 input[type=radio]:checked ~ .circle {
  border-color: #283593;
}
.radio-material-indigo-900 input[type=radio]:checked ~ .circle {
  border-color: #1a237e;
}
.radio-material-indigo-A100 input[type=radio]:checked ~ .circle {
  border-color: #8c9eff;
}
.radio-material-indigo-A200 input[type=radio]:checked ~ .circle {
  border-color: #536dfe;
}
.radio-material-indigo-A400 input[type=radio]:checked ~ .circle {
  border-color: #3d5afe;
}
.radio-material-indigo-A700 input[type=radio]:checked ~ .circle {
  border-color: #304ffe;
}
.radio-material-blue input[type=radio]:checked ~ .circle {
  border-color: #2196f3;
}
.radio-material-blue-50 input[type=radio]:checked ~ .circle {
  border-color: #e3f2fd;
}
.radio-material-blue-100 input[type=radio]:checked ~ .circle {
  border-color: #bbdefb;
}
.radio-material-blue-200 input[type=radio]:checked ~ .circle {
  border-color: #90caf9;
}
.radio-material-blue-300 input[type=radio]:checked ~ .circle {
  border-color: #64b5f6;
}
.radio-material-blue-400 input[type=radio]:checked ~ .circle {
  border-color: #42a5f5;
}
.radio-material-blue-500 input[type=radio]:checked ~ .circle {
  border-color: #2196f3;
}
.radio-material-blue-600 input[type=radio]:checked ~ .circle {
  border-color: #1e88e5;
}
.radio-material-blue-700 input[type=radio]:checked ~ .circle {
  border-color: #1976d2;
}
.radio-material-blue-800 input[type=radio]:checked ~ .circle {
  border-color: #1565c0;
}
.radio-material-blue-900 input[type=radio]:checked ~ .circle {
  border-color: #0d47a1;
}
.radio-material-blue-A100 input[type=radio]:checked ~ .circle {
  border-color: #82b1ff;
}
.radio-material-blue-A200 input[type=radio]:checked ~ .circle {
  border-color: #448aff;
}
.radio-material-blue-A400 input[type=radio]:checked ~ .circle {
  border-color: #2979ff;
}
.radio-material-blue-A700 input[type=radio]:checked ~ .circle {
  border-color: #2962ff;
}
.radio-material-light-blue input[type=radio]:checked ~ .circle {
  border-color: #03a9f4;
}
.radio-material-light-blue-50 input[type=radio]:checked ~ .circle {
  border-color: #e1f5fe;
}
.radio-material-light-blue-100 input[type=radio]:checked ~ .circle {
  border-color: #b3e5fc;
}
.radio-material-light-blue-200 input[type=radio]:checked ~ .circle {
  border-color: #81d4fa;
}
.radio-material-light-blue-300 input[type=radio]:checked ~ .circle {
  border-color: #4fc3f7;
}
.radio-material-light-blue-400 input[type=radio]:checked ~ .circle {
  border-color: #29b6f6;
}
.radio-material-light-blue-500 input[type=radio]:checked ~ .circle {
  border-color: #03a9f4;
}
.radio-material-light-blue-600 input[type=radio]:checked ~ .circle {
  border-color: #039be5;
}
.radio-material-light-blue-700 input[type=radio]:checked ~ .circle {
  border-color: #0288d1;
}
.radio-material-light-blue-800 input[type=radio]:checked ~ .circle {
  border-color: #0277bd;
}
.radio-material-light-blue-900 input[type=radio]:checked ~ .circle {
  border-color: #01579b;
}
.radio-material-light-blue-A100 input[type=radio]:checked ~ .circle {
  border-color: #80d8ff;
}
.radio-material-light-blue-A200 input[type=radio]:checked ~ .circle {
  border-color: #40c4ff;
}
.radio-material-light-blue-A400 input[type=radio]:checked ~ .circle {
  border-color: #00b0ff;
}
.radio-material-light-blue-A700 input[type=radio]:checked ~ .circle {
  border-color: #0091ea;
}
.radio-material-cyan input[type=radio]:checked ~ .circle {
  border-color: #00bcd4;
}
.radio-material-cyan-50 input[type=radio]:checked ~ .circle {
  border-color: #e0f7fa;
}
.radio-material-cyan-100 input[type=radio]:checked ~ .circle {
  border-color: #b2ebf2;
}
.radio-material-cyan-200 input[type=radio]:checked ~ .circle {
  border-color: #80deea;
}
.radio-material-cyan-300 input[type=radio]:checked ~ .circle {
  border-color: #4dd0e1;
}
.radio-material-cyan-400 input[type=radio]:checked ~ .circle {
  border-color: #26c6da;
}
.radio-material-cyan-500 input[type=radio]:checked ~ .circle {
  border-color: #00bcd4;
}
.radio-material-cyan-600 input[type=radio]:checked ~ .circle {
  border-color: #00acc1;
}
.radio-material-cyan-700 input[type=radio]:checked ~ .circle {
  border-color: #0097a7;
}
.radio-material-cyan-800 input[type=radio]:checked ~ .circle {
  border-color: #00838f;
}
.radio-material-cyan-900 input[type=radio]:checked ~ .circle {
  border-color: #006064;
}
.radio-material-cyan-A100 input[type=radio]:checked ~ .circle {
  border-color: #84ffff;
}
.radio-material-cyan-A200 input[type=radio]:checked ~ .circle {
  border-color: #18ffff;
}
.radio-material-cyan-A400 input[type=radio]:checked ~ .circle {
  border-color: #00e5ff;
}
.radio-material-cyan-A700 input[type=radio]:checked ~ .circle {
  border-color: #00b8d4;
}
.radio-material-teal input[type=radio]:checked ~ .circle {
  border-color: #009688;
}
.radio-material-teal-50 input[type=radio]:checked ~ .circle {
  border-color: #e0f2f1;
}
.radio-material-teal-100 input[type=radio]:checked ~ .circle {
  border-color: #b2dfdb;
}
.radio-material-teal-200 input[type=radio]:checked ~ .circle {
  border-color: #80cbc4;
}
.radio-material-teal-300 input[type=radio]:checked ~ .circle {
  border-color: #4db6ac;
}
.radio-material-teal-400 input[type=radio]:checked ~ .circle {
  border-color: #26a69a;
}
.radio-material-teal-500 input[type=radio]:checked ~ .circle {
  border-color: #009688;
}
.radio-material-teal-600 input[type=radio]:checked ~ .circle {
  border-color: #00897b;
}
.radio-material-teal-700 input[type=radio]:checked ~ .circle {
  border-color: #00796b;
}
.radio-material-teal-800 input[type=radio]:checked ~ .circle {
  border-color: #00695c;
}
.radio-material-teal-900 input[type=radio]:checked ~ .circle {
  border-color: #004d40;
}
.radio-material-teal-A100 input[type=radio]:checked ~ .circle {
  border-color: #a7ffeb;
}
.radio-material-teal-A200 input[type=radio]:checked ~ .circle {
  border-color: #64ffda;
}
.radio-material-teal-A400 input[type=radio]:checked ~ .circle {
  border-color: #1de9b6;
}
.radio-material-teal-A700 input[type=radio]:checked ~ .circle {
  border-color: #00bfa5;
}
.radio-material-green input[type=radio]:checked ~ .circle {
  border-color: #4caf50;
}
.radio-material-green-50 input[type=radio]:checked ~ .circle {
  border-color: #e8f5e9;
}
.radio-material-green-100 input[type=radio]:checked ~ .circle {
  border-color: #c8e6c9;
}
.radio-material-green-200 input[type=radio]:checked ~ .circle {
  border-color: #a5d6a7;
}
.radio-material-green-300 input[type=radio]:checked ~ .circle {
  border-color: #81c784;
}
.radio-material-green-400 input[type=radio]:checked ~ .circle {
  border-color: #66bb6a;
}
.radio-material-green-500 input[type=radio]:checked ~ .circle {
  border-color: #4caf50;
}
.radio-material-green-600 input[type=radio]:checked ~ .circle {
  border-color: #43a047;
}
.radio-material-green-700 input[type=radio]:checked ~ .circle {
  border-color: #388e3c;
}
.radio-material-green-800 input[type=radio]:checked ~ .circle {
  border-color: #2e7d32;
}
.radio-material-green-900 input[type=radio]:checked ~ .circle {
  border-color: #1b5e20;
}
.radio-material-green-A100 input[type=radio]:checked ~ .circle {
  border-color: #b9f6ca;
}
.radio-material-green-A200 input[type=radio]:checked ~ .circle {
  border-color: #69f0ae;
}
.radio-material-green-A400 input[type=radio]:checked ~ .circle {
  border-color: #00e676;
}
.radio-material-green-A700 input[type=radio]:checked ~ .circle {
  border-color: #00c853;
}
.radio-material-light-green input[type=radio]:checked ~ .circle {
  border-color: #8bc34a;
}
.radio-material-light-green-50 input[type=radio]:checked ~ .circle {
  border-color: #f1f8e9;
}
.radio-material-light-green-100 input[type=radio]:checked ~ .circle {
  border-color: #dcedc8;
}
.radio-material-light-green-200 input[type=radio]:checked ~ .circle {
  border-color: #c5e1a5;
}
.radio-material-light-green-300 input[type=radio]:checked ~ .circle {
  border-color: #aed581;
}
.radio-material-light-green-400 input[type=radio]:checked ~ .circle {
  border-color: #9ccc65;
}
.radio-material-light-green-500 input[type=radio]:checked ~ .circle {
  border-color: #8bc34a;
}
.radio-material-light-green-600 input[type=radio]:checked ~ .circle {
  border-color: #7cb342;
}
.radio-material-light-green-700 input[type=radio]:checked ~ .circle {
  border-color: #689f38;
}
.radio-material-light-green-800 input[type=radio]:checked ~ .circle {
  border-color: #558b2f;
}
.radio-material-light-green-900 input[type=radio]:checked ~ .circle {
  border-color: #33691e;
}
.radio-material-light-green-A100 input[type=radio]:checked ~ .circle {
  border-color: #ccff90;
}
.radio-material-light-green-A200 input[type=radio]:checked ~ .circle {
  border-color: #b2ff59;
}
.radio-material-light-green-A400 input[type=radio]:checked ~ .circle {
  border-color: #76ff03;
}
.radio-material-light-green-A700 input[type=radio]:checked ~ .circle {
  border-color: #64dd17;
}
.radio-material-lime input[type=radio]:checked ~ .circle {
  border-color: #cddc39;
}
.radio-material-lime-50 input[type=radio]:checked ~ .circle {
  border-color: #f9fbe7;
}
.radio-material-lime-100 input[type=radio]:checked ~ .circle {
  border-color: #f0f4c3;
}
.radio-material-lime-200 input[type=radio]:checked ~ .circle {
  border-color: #e6ee9c;
}
.radio-material-lime-300 input[type=radio]:checked ~ .circle {
  border-color: #dce775;
}
.radio-material-lime-400 input[type=radio]:checked ~ .circle {
  border-color: #d4e157;
}
.radio-material-lime-500 input[type=radio]:checked ~ .circle {
  border-color: #cddc39;
}
.radio-material-lime-600 input[type=radio]:checked ~ .circle {
  border-color: #c0ca33;
}
.radio-material-lime-700 input[type=radio]:checked ~ .circle {
  border-color: #afb42b;
}
.radio-material-lime-800 input[type=radio]:checked ~ .circle {
  border-color: #9e9d24;
}
.radio-material-lime-900 input[type=radio]:checked ~ .circle {
  border-color: #827717;
}
.radio-material-lime-A100 input[type=radio]:checked ~ .circle {
  border-color: #f4ff81;
}
.radio-material-lime-A200 input[type=radio]:checked ~ .circle {
  border-color: #eeff41;
}
.radio-material-lime-A400 input[type=radio]:checked ~ .circle {
  border-color: #c6ff00;
}
.radio-material-lime-A700 input[type=radio]:checked ~ .circle {
  border-color: #aeea00;
}
.radio-material-yellow input[type=radio]:checked ~ .circle {
  border-color: #ffeb3b;
}
.radio-material-yellow-50 input[type=radio]:checked ~ .circle {
  border-color: #fffde7;
}
.radio-material-yellow-100 input[type=radio]:checked ~ .circle {
  border-color: #fff9c4;
}
.radio-material-yellow-200 input[type=radio]:checked ~ .circle {
  border-color: #fff59d;
}
.radio-material-yellow-300 input[type=radio]:checked ~ .circle {
  border-color: #fff176;
}
.radio-material-yellow-400 input[type=radio]:checked ~ .circle {
  border-color: #ffee58;
}
.radio-material-yellow-500 input[type=radio]:checked ~ .circle {
  border-color: #ffeb3b;
}
.radio-material-yellow-600 input[type=radio]:checked ~ .circle {
  border-color: #fdd835;
}
.radio-material-yellow-700 input[type=radio]:checked ~ .circle {
  border-color: #fbc02d;
}
.radio-material-yellow-800 input[type=radio]:checked ~ .circle {
  border-color: #f9a825;
}
.radio-material-yellow-900 input[type=radio]:checked ~ .circle {
  border-color: #f57f17;
}
.radio-material-yellow-A100 input[type=radio]:checked ~ .circle {
  border-color: #ffff8d;
}
.radio-material-yellow-A200 input[type=radio]:checked ~ .circle {
  border-color: #ffff00;
}
.radio-material-yellow-A400 input[type=radio]:checked ~ .circle {
  border-color: #ffea00;
}
.radio-material-yellow-A700 input[type=radio]:checked ~ .circle {
  border-color: #ffd600;
}
.radio-material-amber input[type=radio]:checked ~ .circle {
  border-color: #ffc107;
}
.radio-material-amber-50 input[type=radio]:checked ~ .circle {
  border-color: #fff8e1;
}
.radio-material-amber-100 input[type=radio]:checked ~ .circle {
  border-color: #ffecb3;
}
.radio-material-amber-200 input[type=radio]:checked ~ .circle {
  border-color: #ffe082;
}
.radio-material-amber-300 input[type=radio]:checked ~ .circle {
  border-color: #ffd54f;
}
.radio-material-amber-400 input[type=radio]:checked ~ .circle {
  border-color: #ffca28;
}
.radio-material-amber-500 input[type=radio]:checked ~ .circle {
  border-color: #ffc107;
}
.radio-material-amber-600 input[type=radio]:checked ~ .circle {
  border-color: #ffb300;
}
.radio-material-amber-700 input[type=radio]:checked ~ .circle {
  border-color: #ffa000;
}
.radio-material-amber-800 input[type=radio]:checked ~ .circle {
  border-color: #ff8f00;
}
.radio-material-amber-900 input[type=radio]:checked ~ .circle {
  border-color: #ff6f00;
}
.radio-material-amber-A100 input[type=radio]:checked ~ .circle {
  border-color: #ffe57f;
}
.radio-material-amber-A200 input[type=radio]:checked ~ .circle {
  border-color: #ffd740;
}
.radio-material-amber-A400 input[type=radio]:checked ~ .circle {
  border-color: #ffc400;
}
.radio-material-amber-A700 input[type=radio]:checked ~ .circle {
  border-color: #ffab00;
}
.radio-material-orange input[type=radio]:checked ~ .circle {
  border-color: #ff9800;
}
.radio-material-orange-50 input[type=radio]:checked ~ .circle {
  border-color: #fff3e0;
}
.radio-material-orange-100 input[type=radio]:checked ~ .circle {
  border-color: #ffe0b2;
}
.radio-material-orange-200 input[type=radio]:checked ~ .circle {
  border-color: #ffcc80;
}
.radio-material-orange-300 input[type=radio]:checked ~ .circle {
  border-color: #ffb74d;
}
.radio-material-orange-400 input[type=radio]:checked ~ .circle {
  border-color: #ffa726;
}
.radio-material-orange-500 input[type=radio]:checked ~ .circle {
  border-color: #ff9800;
}
.radio-material-orange-600 input[type=radio]:checked ~ .circle {
  border-color: #fb8c00;
}
.radio-material-orange-700 input[type=radio]:checked ~ .circle {
  border-color: #f57c00;
}
.radio-material-orange-800 input[type=radio]:checked ~ .circle {
  border-color: #ef6c00;
}
.radio-material-orange-900 input[type=radio]:checked ~ .circle {
  border-color: #e65100;
}
.radio-material-orange-A100 input[type=radio]:checked ~ .circle {
  border-color: #ffd180;
}
.radio-material-orange-A200 input[type=radio]:checked ~ .circle {
  border-color: #ffab40;
}
.radio-material-orange-A400 input[type=radio]:checked ~ .circle {
  border-color: #ff9100;
}
.radio-material-orange-A700 input[type=radio]:checked ~ .circle {
  border-color: #ff6d00;
}
.radio-material-deep-orange input[type=radio]:checked ~ .circle {
  border-color: #ff5722;
}
.radio-material-deep-orange-50 input[type=radio]:checked ~ .circle {
  border-color: #fbe9e7;
}
.radio-material-deep-orange-100 input[type=radio]:checked ~ .circle {
  border-color: #ffccbc;
}
.radio-material-deep-orange-200 input[type=radio]:checked ~ .circle {
  border-color: #ffab91;
}
.radio-material-deep-orange-300 input[type=radio]:checked ~ .circle {
  border-color: #ff8a65;
}
.radio-material-deep-orange-400 input[type=radio]:checked ~ .circle {
  border-color: #ff7043;
}
.radio-material-deep-orange-500 input[type=radio]:checked ~ .circle {
  border-color: #ff5722;
}
.radio-material-deep-orange-600 input[type=radio]:checked ~ .circle {
  border-color: #f4511e;
}
.radio-material-deep-orange-700 input[type=radio]:checked ~ .circle {
  border-color: #e64a19;
}
.radio-material-deep-orange-800 input[type=radio]:checked ~ .circle {
  border-color: #d84315;
}
.radio-material-deep-orange-900 input[type=radio]:checked ~ .circle {
  border-color: #bf360c;
}
.radio-material-deep-orange-A100 input[type=radio]:checked ~ .circle {
  border-color: #ff9e80;
}
.radio-material-deep-orange-A200 input[type=radio]:checked ~ .circle {
  border-color: #ff6e40;
}
.radio-material-deep-orange-A400 input[type=radio]:checked ~ .circle {
  border-color: #ff3d00;
}
.radio-material-deep-orange-A700 input[type=radio]:checked ~ .circle {
  border-color: #dd2c00;
}
.radio-material-brown input[type=radio]:checked ~ .circle {
  border-color: #795548;
}
.radio-material-brown-50 input[type=radio]:checked ~ .circle {
  border-color: #efebe9;
}
.radio-material-brown-100 input[type=radio]:checked ~ .circle {
  border-color: #d7ccc8;
}
.radio-material-brown-200 input[type=radio]:checked ~ .circle {
  border-color: #bcaaa4;
}
.radio-material-brown-300 input[type=radio]:checked ~ .circle {
  border-color: #a1887f;
}
.radio-material-brown-400 input[type=radio]:checked ~ .circle {
  border-color: #8d6e63;
}
.radio-material-brown-500 input[type=radio]:checked ~ .circle {
  border-color: #795548;
}
.radio-material-brown-600 input[type=radio]:checked ~ .circle {
  border-color: #6d4c41;
}
.radio-material-brown-700 input[type=radio]:checked ~ .circle {
  border-color: #5d4037;
}
.radio-material-brown-800 input[type=radio]:checked ~ .circle {
  border-color: #4e342e;
}
.radio-material-brown-900 input[type=radio]:checked ~ .circle {
  border-color: #3e2723;
}
.radio-material-brown-A100 input[type=radio]:checked ~ .circle {
  border-color: #d7ccc8;
}
.radio-material-brown-A200 input[type=radio]:checked ~ .circle {
  border-color: #bcaaa4;
}
.radio-material-brown-A400 input[type=radio]:checked ~ .circle {
  border-color: #8d6e63;
}
.radio-material-brown-A700 input[type=radio]:checked ~ .circle {
  border-color: #5d4037;
}
.radio-material-grey input[type=radio]:checked ~ .circle {
  border-color: #9e9e9e;
}
.radio-material-grey-50 input[type=radio]:checked ~ .circle {
  border-color: #fafafa;
}
.radio-material-grey-100 input[type=radio]:checked ~ .circle {
  border-color: #f5f5f5;
}
.radio-material-grey-200 input[type=radio]:checked ~ .circle {
  border-color: #eeeeee;
}
.radio-material-grey-300 input[type=radio]:checked ~ .circle {
  border-color: #e0e0e0;
}
.radio-material-grey-400 input[type=radio]:checked ~ .circle {
  border-color: #bdbdbd;
}
.radio-material-grey-500 input[type=radio]:checked ~ .circle {
  border-color: #9e9e9e;
}
.radio-material-grey-600 input[type=radio]:checked ~ .circle {
  border-color: #757575;
}
.radio-material-grey-700 input[type=radio]:checked ~ .circle {
  border-color: #616161;
}
.radio-material-grey-800 input[type=radio]:checked ~ .circle {
  border-color: #424242;
}
.radio-material-grey-900 input[type=radio]:checked ~ .circle {
  border-color: #212121;
}
.radio-material-grey-A100 input[type=radio]:checked ~ .circle {
  border-color: #f5f5f5;
}
.radio-material-grey-A200 input[type=radio]:checked ~ .circle {
  border-color: #eeeeee;
}
.radio-material-grey-A400 input[type=radio]:checked ~ .circle {
  border-color: #bdbdbd;
}
.radio-material-grey-A700 input[type=radio]:checked ~ .circle {
  border-color: #616161;
}
.radio-material-blue-grey input[type=radio]:checked ~ .circle {
  border-color: #607d8b;
}
.radio-material-blue-grey-50 input[type=radio]:checked ~ .circle {
  border-color: #eceff1;
}
.radio-material-blue-grey-100 input[type=radio]:checked ~ .circle {
  border-color: #cfd8dc;
}
.radio-material-blue-grey-200 input[type=radio]:checked ~ .circle {
  border-color: #b0bec5;
}
.radio-material-blue-grey-300 input[type=radio]:checked ~ .circle {
  border-color: #90a4ae;
}
.radio-material-blue-grey-400 input[type=radio]:checked ~ .circle {
  border-color: #78909c;
}
.radio-material-blue-grey-500 input[type=radio]:checked ~ .circle {
  border-color: #607d8b;
}
.radio-material-blue-grey-600 input[type=radio]:checked ~ .circle {
  border-color: #546e7a;
}
.radio-material-blue-grey-700 input[type=radio]:checked ~ .circle {
  border-color: #455a64;
}
.radio-material-blue-grey-800 input[type=radio]:checked ~ .circle {
  border-color: #37474f;
}
.radio-material-blue-grey-900 input[type=radio]:checked ~ .circle {
  border-color: #263238;
}
.radio-material-blue-grey-A100 input[type=radio]:checked ~ .circle {
  border-color: #cfd8dc;
}
.radio-material-blue-grey-A200 input[type=radio]:checked ~ .circle {
  border-color: #b0bec5;
}
.radio-material-blue-grey-A400 input[type=radio]:checked ~ .circle {
  border-color: #78909c;
}
.radio-material-blue-grey-A700 input[type=radio]:checked ~ .circle {
  border-color: #455a64;
}
.radio input[type=radio][disabled] ~ .check,
.radio input[type=radio][disabled] ~ .circle {
  opacity: 0.5;
}
.radio input[type=radio] {
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden;
}
.radio input[type=radio]:checked ~ .check {
  transform: scale3d(0.55, 0.55, 1);
}
.radio input[type=radio][disabled] ~ .circle {
  border-color: rgba(0, 0, 0, 0.84);
}
.radio input[type=radio][disabled] ~ .check {
  background-color: rgba(0, 0, 0, 0.84);
}
@keyframes rippleOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@keyframes rippleOff {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
legend {
  margin-bottom: 22px;
  font-size: 24px;
}
output {
  padding-top: 8px;
  font-size: 16px;
  line-height: 1.42857143;
}
.form-control {
  height: 38px;
  padding: 7px 0px;
  font-size: 16px;
  line-height: 1.42857143;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"].form-control,
  input[type="time"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control {
    line-height: 38px;
  }
  input[type="date"].input-sm,
  input[type="time"].input-sm,
  input[type="datetime-local"].input-sm,
  input[type="month"].input-sm,
  .input-group-sm input[type="date"],
  .input-group-sm input[type="time"],
  .input-group-sm input[type="datetime-local"],
  .input-group-sm input[type="month"] {
    line-height: 24px;
  }
  input[type="date"].input-lg,
  input[type="time"].input-lg,
  input[type="datetime-local"].input-lg,
  input[type="month"].input-lg,
  .input-group-lg input[type="date"],
  .input-group-lg input[type="time"],
  .input-group-lg input[type="datetime-local"],
  .input-group-lg input[type="month"] {
    line-height: 44px;
  }
}
.radio label,
.checkbox label {
  min-height: 22px;
}
.form-control-static {
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 38px;
}
.input-sm '.input-sm' {
  height: 24px;
  padding: 3px 0px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 3px;
}
.input-sm select'.input-sm' {
  height: 24px;
  line-height: 24px;
}
.input-sm textarea'.input-sm',
.input-sm select[multiple]'.input-sm' {
  height: auto;
}
.form-group-sm .form-control {
  height: 24px;
  padding: 3px 0px;
  font-size: 11px;
  line-height: 1.5;
}
.form-group-sm select.form-control {
  height: 24px;
  line-height: 24px;
}
.form-group-sm textarea.form-control,
.form-group-sm select[multiple].form-control {
  height: auto;
}
.form-group-sm .form-control-static {
  height: 24px;
  min-height: 33px;
  padding: 4px 0px;
  font-size: 11px;
  line-height: 1.5;
}
.input-lg '.input-lg' {
  height: 44px;
  padding: 9px 0px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}
.input-lg select'.input-lg' {
  height: 44px;
  line-height: 44px;
}
.input-lg textarea'.input-lg',
.input-lg select[multiple]'.input-lg' {
  height: auto;
}
.form-group-lg .form-control {
  height: 44px;
  padding: 9px 0px;
  font-size: 18px;
  line-height: 1.3333333;
}
.form-group-lg select.form-control {
  height: 44px;
  line-height: 44px;
}
.form-group-lg textarea.form-control,
.form-group-lg select[multiple].form-control {
  height: auto;
}
.form-group-lg .form-control-static {
  height: 44px;
  min-height: 40px;
  padding: 10px 0px;
  font-size: 18px;
  line-height: 1.3333333;
}
.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  padding-top: 8px;
}
.form-horizontal .radio,
.form-horizontal .checkbox {
  min-height: 30px;
}
@media (min-width: 768px) {
  .form-horizontal .control-label {
    padding-top: 8px;
  }
}
@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 12.9999997px;
    font-size: 18px;
  }
}
@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 4px;
    font-size: 11px;
  }
}
.form-group {
  position: relative;
  margin: 28px 0 0 0;
  padding-bottom: 7px;
}
.form-group .form-control {
  border: 0;
  background-image: linear-gradient(#009688, #009688), linear-gradient(#D2D2D2, #D2D2D2);
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-color: rgba(0, 0, 0, 0);
  transition: background 0s ease-out;
  float: none;
  box-shadow: none;
  border-radius: 0;
}
.form-group .form-control::-moz-placeholder {
  color: #BDBDBD;
  font-weight: normal;
}
.form-group .form-control:-ms-input-placeholder {
  color: #BDBDBD;
  font-weight: normal;
}
.form-group .form-control::-webkit-input-placeholder {
  color: #BDBDBD;
  font-weight: normal;
}
.form-group .form-control[readonly],
.form-group .form-control[disabled],
fieldset[disabled] .form-group .form-control {
  background-color: rgba(0, 0, 0, 0);
}
.form-group .form-control[disabled],
fieldset[disabled] .form-group .form-control {
  background-image: none;
  border-bottom: 1px dotted #D2D2D2;
}
.form-group.is-focused .form-control {
  outline: none;
  background-image: linear-gradient(#009688, #009688), linear-gradient(#D2D2D2, #D2D2D2);
  background-size: 100% 2px, 100% 1px;
  box-shadow: none;
  transition-duration: 0.3s;
}
.form-group.is-focused .form-control .material-input:after {
  background-color: #009688;
}
.form-group.label-static label.control-label,
.form-group.label-placeholder label.control-label,
.form-group.label-floating label.control-label {
  position: absolute;
  pointer-events: none;
  transition: 0.3s ease all;
}
.form-group label.control-label {
  color: #BDBDBD;
  font-weight: normal;
  margin: 16px 0 0 0;
}
.form-group.label-placeholder:not(.is-empty) label.control-label {
  display: none;
}
.form-group label.control-label,
.form-group-default label.control-label {
  color: #BDBDBD;
}
.form-group-black label.control-label {
  color: #000000;
}
.form-group-white label.control-label {
  color: #ffffff;
}
.form-group-inverse label.control-label {
  color: #3f51b5;
}
.form-group-primary label.control-label {
  color: #009688;
}
.form-group-success label.control-label {
  color: #4caf50;
}
.form-group-info label.control-label {
  color: #03a9f4;
}
.form-group-warning label.control-label {
  color: #ff5722;
}
.form-group-danger label.control-label {
  color: #f44336;
}
.form-group-material-red label.control-label {
  color: #f44336;
}
.form-group-material-red-50 label.control-label {
  color: #ffebee;
}
.form-group-material-red-100 label.control-label {
  color: #ffcdd2;
}
.form-group-material-red-200 label.control-label {
  color: #ef9a9a;
}
.form-group-material-red-300 label.control-label {
  color: #e57373;
}
.form-group-material-red-400 label.control-label {
  color: #ef5350;
}
.form-group-material-red-500 label.control-label {
  color: #f44336;
}
.form-group-material-red-600 label.control-label {
  color: #e53935;
}
.form-group-material-red-700 label.control-label {
  color: #d32f2f;
}
.form-group-material-red-800 label.control-label {
  color: #c62828;
}
.form-group-material-red-900 label.control-label {
  color: #b71c1c;
}
.form-group-material-red-A100 label.control-label {
  color: #ff8a80;
}
.form-group-material-red-A200 label.control-label {
  color: #ff5252;
}
.form-group-material-red-A400 label.control-label {
  color: #ff1744;
}
.form-group-material-red-A700 label.control-label {
  color: #d50000;
}
.form-group-material-pink label.control-label {
  color: #e91e63;
}
.form-group-material-pink-50 label.control-label {
  color: #fce4ec;
}
.form-group-material-pink-100 label.control-label {
  color: #f8bbd0;
}
.form-group-material-pink-200 label.control-label {
  color: #f48fb1;
}
.form-group-material-pink-300 label.control-label {
  color: #f06292;
}
.form-group-material-pink-400 label.control-label {
  color: #ec407a;
}
.form-group-material-pink-500 label.control-label {
  color: #e91e63;
}
.form-group-material-pink-600 label.control-label {
  color: #d81b60;
}
.form-group-material-pink-700 label.control-label {
  color: #c2185b;
}
.form-group-material-pink-800 label.control-label {
  color: #ad1457;
}
.form-group-material-pink-900 label.control-label {
  color: #880e4f;
}
.form-group-material-pink-A100 label.control-label {
  color: #ff80ab;
}
.form-group-material-pink-A200 label.control-label {
  color: #ff4081;
}
.form-group-material-pink-A400 label.control-label {
  color: #f50057;
}
.form-group-material-pink-A700 label.control-label {
  color: #c51162;
}
.form-group-material-purple label.control-label {
  color: #9c27b0;
}
.form-group-material-purple-50 label.control-label {
  color: #f3e5f5;
}
.form-group-material-purple-100 label.control-label {
  color: #e1bee7;
}
.form-group-material-purple-200 label.control-label {
  color: #ce93d8;
}
.form-group-material-purple-300 label.control-label {
  color: #ba68c8;
}
.form-group-material-purple-400 label.control-label {
  color: #ab47bc;
}
.form-group-material-purple-500 label.control-label {
  color: #9c27b0;
}
.form-group-material-purple-600 label.control-label {
  color: #8e24aa;
}
.form-group-material-purple-700 label.control-label {
  color: #7b1fa2;
}
.form-group-material-purple-800 label.control-label {
  color: #6a1b9a;
}
.form-group-material-purple-900 label.control-label {
  color: #4a148c;
}
.form-group-material-purple-A100 label.control-label {
  color: #ea80fc;
}
.form-group-material-purple-A200 label.control-label {
  color: #e040fb;
}
.form-group-material-purple-A400 label.control-label {
  color: #d500f9;
}
.form-group-material-purple-A700 label.control-label {
  color: #aa00ff;
}
.form-group-material-deep-purple label.control-label {
  color: #673ab7;
}
.form-group-material-deep-purple-50 label.control-label {
  color: #ede7f6;
}
.form-group-material-deep-purple-100 label.control-label {
  color: #d1c4e9;
}
.form-group-material-deep-purple-200 label.control-label {
  color: #b39ddb;
}
.form-group-material-deep-purple-300 label.control-label {
  color: #9575cd;
}
.form-group-material-deep-purple-400 label.control-label {
  color: #7e57c2;
}
.form-group-material-deep-purple-500 label.control-label {
  color: #673ab7;
}
.form-group-material-deep-purple-600 label.control-label {
  color: #5e35b1;
}
.form-group-material-deep-purple-700 label.control-label {
  color: #512da8;
}
.form-group-material-deep-purple-800 label.control-label {
  color: #4527a0;
}
.form-group-material-deep-purple-900 label.control-label {
  color: #311b92;
}
.form-group-material-deep-purple-A100 label.control-label {
  color: #b388ff;
}
.form-group-material-deep-purple-A200 label.control-label {
  color: #7c4dff;
}
.form-group-material-deep-purple-A400 label.control-label {
  color: #651fff;
}
.form-group-material-deep-purple-A700 label.control-label {
  color: #6200ea;
}
.form-group-material-indigo label.control-label {
  color: #3f51b5;
}
.form-group-material-indigo-50 label.control-label {
  color: #e8eaf6;
}
.form-group-material-indigo-100 label.control-label {
  color: #c5cae9;
}
.form-group-material-indigo-200 label.control-label {
  color: #9fa8da;
}
.form-group-material-indigo-300 label.control-label {
  color: #7986cb;
}
.form-group-material-indigo-400 label.control-label {
  color: #5c6bc0;
}
.form-group-material-indigo-500 label.control-label {
  color: #3f51b5;
}
.form-group-material-indigo-600 label.control-label {
  color: #3949ab;
}
.form-group-material-indigo-700 label.control-label {
  color: #303f9f;
}
.form-group-material-indigo-800 label.control-label {
  color: #283593;
}
.form-group-material-indigo-900 label.control-label {
  color: #1a237e;
}
.form-group-material-indigo-A100 label.control-label {
  color: #8c9eff;
}
.form-group-material-indigo-A200 label.control-label {
  color: #536dfe;
}
.form-group-material-indigo-A400 label.control-label {
  color: #3d5afe;
}
.form-group-material-indigo-A700 label.control-label {
  color: #304ffe;
}
.form-group-material-blue label.control-label {
  color: #2196f3;
}
.form-group-material-blue-50 label.control-label {
  color: #e3f2fd;
}
.form-group-material-blue-100 label.control-label {
  color: #bbdefb;
}
.form-group-material-blue-200 label.control-label {
  color: #90caf9;
}
.form-group-material-blue-300 label.control-label {
  color: #64b5f6;
}
.form-group-material-blue-400 label.control-label {
  color: #42a5f5;
}
.form-group-material-blue-500 label.control-label {
  color: #2196f3;
}
.form-group-material-blue-600 label.control-label {
  color: #1e88e5;
}
.form-group-material-blue-700 label.control-label {
  color: #1976d2;
}
.form-group-material-blue-800 label.control-label {
  color: #1565c0;
}
.form-group-material-blue-900 label.control-label {
  color: #0d47a1;
}
.form-group-material-blue-A100 label.control-label {
  color: #82b1ff;
}
.form-group-material-blue-A200 label.control-label {
  color: #448aff;
}
.form-group-material-blue-A400 label.control-label {
  color: #2979ff;
}
.form-group-material-blue-A700 label.control-label {
  color: #2962ff;
}
.form-group-material-light-blue label.control-label {
  color: #03a9f4;
}
.form-group-material-light-blue-50 label.control-label {
  color: #e1f5fe;
}
.form-group-material-light-blue-100 label.control-label {
  color: #b3e5fc;
}
.form-group-material-light-blue-200 label.control-label {
  color: #81d4fa;
}
.form-group-material-light-blue-300 label.control-label {
  color: #4fc3f7;
}
.form-group-material-light-blue-400 label.control-label {
  color: #29b6f6;
}
.form-group-material-light-blue-500 label.control-label {
  color: #03a9f4;
}
.form-group-material-light-blue-600 label.control-label {
  color: #039be5;
}
.form-group-material-light-blue-700 label.control-label {
  color: #0288d1;
}
.form-group-material-light-blue-800 label.control-label {
  color: #0277bd;
}
.form-group-material-light-blue-900 label.control-label {
  color: #01579b;
}
.form-group-material-light-blue-A100 label.control-label {
  color: #80d8ff;
}
.form-group-material-light-blue-A200 label.control-label {
  color: #40c4ff;
}
.form-group-material-light-blue-A400 label.control-label {
  color: #00b0ff;
}
.form-group-material-light-blue-A700 label.control-label {
  color: #0091ea;
}
.form-group-material-cyan label.control-label {
  color: #00bcd4;
}
.form-group-material-cyan-50 label.control-label {
  color: #e0f7fa;
}
.form-group-material-cyan-100 label.control-label {
  color: #b2ebf2;
}
.form-group-material-cyan-200 label.control-label {
  color: #80deea;
}
.form-group-material-cyan-300 label.control-label {
  color: #4dd0e1;
}
.form-group-material-cyan-400 label.control-label {
  color: #26c6da;
}
.form-group-material-cyan-500 label.control-label {
  color: #00bcd4;
}
.form-group-material-cyan-600 label.control-label {
  color: #00acc1;
}
.form-group-material-cyan-700 label.control-label {
  color: #0097a7;
}
.form-group-material-cyan-800 label.control-label {
  color: #00838f;
}
.form-group-material-cyan-900 label.control-label {
  color: #006064;
}
.form-group-material-cyan-A100 label.control-label {
  color: #84ffff;
}
.form-group-material-cyan-A200 label.control-label {
  color: #18ffff;
}
.form-group-material-cyan-A400 label.control-label {
  color: #00e5ff;
}
.form-group-material-cyan-A700 label.control-label {
  color: #00b8d4;
}
.form-group-material-teal label.control-label {
  color: #009688;
}
.form-group-material-teal-50 label.control-label {
  color: #e0f2f1;
}
.form-group-material-teal-100 label.control-label {
  color: #b2dfdb;
}
.form-group-material-teal-200 label.control-label {
  color: #80cbc4;
}
.form-group-material-teal-300 label.control-label {
  color: #4db6ac;
}
.form-group-material-teal-400 label.control-label {
  color: #26a69a;
}
.form-group-material-teal-500 label.control-label {
  color: #009688;
}
.form-group-material-teal-600 label.control-label {
  color: #00897b;
}
.form-group-material-teal-700 label.control-label {
  color: #00796b;
}
.form-group-material-teal-800 label.control-label {
  color: #00695c;
}
.form-group-material-teal-900 label.control-label {
  color: #004d40;
}
.form-group-material-teal-A100 label.control-label {
  color: #a7ffeb;
}
.form-group-material-teal-A200 label.control-label {
  color: #64ffda;
}
.form-group-material-teal-A400 label.control-label {
  color: #1de9b6;
}
.form-group-material-teal-A700 label.control-label {
  color: #00bfa5;
}
.form-group-material-green label.control-label {
  color: #4caf50;
}
.form-group-material-green-50 label.control-label {
  color: #e8f5e9;
}
.form-group-material-green-100 label.control-label {
  color: #c8e6c9;
}
.form-group-material-green-200 label.control-label {
  color: #a5d6a7;
}
.form-group-material-green-300 label.control-label {
  color: #81c784;
}
.form-group-material-green-400 label.control-label {
  color: #66bb6a;
}
.form-group-material-green-500 label.control-label {
  color: #4caf50;
}
.form-group-material-green-600 label.control-label {
  color: #43a047;
}
.form-group-material-green-700 label.control-label {
  color: #388e3c;
}
.form-group-material-green-800 label.control-label {
  color: #2e7d32;
}
.form-group-material-green-900 label.control-label {
  color: #1b5e20;
}
.form-group-material-green-A100 label.control-label {
  color: #b9f6ca;
}
.form-group-material-green-A200 label.control-label {
  color: #69f0ae;
}
.form-group-material-green-A400 label.control-label {
  color: #00e676;
}
.form-group-material-green-A700 label.control-label {
  color: #00c853;
}
.form-group-material-light-green label.control-label {
  color: #8bc34a;
}
.form-group-material-light-green-50 label.control-label {
  color: #f1f8e9;
}
.form-group-material-light-green-100 label.control-label {
  color: #dcedc8;
}
.form-group-material-light-green-200 label.control-label {
  color: #c5e1a5;
}
.form-group-material-light-green-300 label.control-label {
  color: #aed581;
}
.form-group-material-light-green-400 label.control-label {
  color: #9ccc65;
}
.form-group-material-light-green-500 label.control-label {
  color: #8bc34a;
}
.form-group-material-light-green-600 label.control-label {
  color: #7cb342;
}
.form-group-material-light-green-700 label.control-label {
  color: #689f38;
}
.form-group-material-light-green-800 label.control-label {
  color: #558b2f;
}
.form-group-material-light-green-900 label.control-label {
  color: #33691e;
}
.form-group-material-light-green-A100 label.control-label {
  color: #ccff90;
}
.form-group-material-light-green-A200 label.control-label {
  color: #b2ff59;
}
.form-group-material-light-green-A400 label.control-label {
  color: #76ff03;
}
.form-group-material-light-green-A700 label.control-label {
  color: #64dd17;
}
.form-group-material-lime label.control-label {
  color: #cddc39;
}
.form-group-material-lime-50 label.control-label {
  color: #f9fbe7;
}
.form-group-material-lime-100 label.control-label {
  color: #f0f4c3;
}
.form-group-material-lime-200 label.control-label {
  color: #e6ee9c;
}
.form-group-material-lime-300 label.control-label {
  color: #dce775;
}
.form-group-material-lime-400 label.control-label {
  color: #d4e157;
}
.form-group-material-lime-500 label.control-label {
  color: #cddc39;
}
.form-group-material-lime-600 label.control-label {
  color: #c0ca33;
}
.form-group-material-lime-700 label.control-label {
  color: #afb42b;
}
.form-group-material-lime-800 label.control-label {
  color: #9e9d24;
}
.form-group-material-lime-900 label.control-label {
  color: #827717;
}
.form-group-material-lime-A100 label.control-label {
  color: #f4ff81;
}
.form-group-material-lime-A200 label.control-label {
  color: #eeff41;
}
.form-group-material-lime-A400 label.control-label {
  color: #c6ff00;
}
.form-group-material-lime-A700 label.control-label {
  color: #aeea00;
}
.form-group-material-yellow label.control-label {
  color: #ffeb3b;
}
.form-group-material-yellow-50 label.control-label {
  color: #fffde7;
}
.form-group-material-yellow-100 label.control-label {
  color: #fff9c4;
}
.form-group-material-yellow-200 label.control-label {
  color: #fff59d;
}
.form-group-material-yellow-300 label.control-label {
  color: #fff176;
}
.form-group-material-yellow-400 label.control-label {
  color: #ffee58;
}
.form-group-material-yellow-500 label.control-label {
  color: #ffeb3b;
}
.form-group-material-yellow-600 label.control-label {
  color: #fdd835;
}
.form-group-material-yellow-700 label.control-label {
  color: #fbc02d;
}
.form-group-material-yellow-800 label.control-label {
  color: #f9a825;
}
.form-group-material-yellow-900 label.control-label {
  color: #f57f17;
}
.form-group-material-yellow-A100 label.control-label {
  color: #ffff8d;
}
.form-group-material-yellow-A200 label.control-label {
  color: #ffff00;
}
.form-group-material-yellow-A400 label.control-label {
  color: #ffea00;
}
.form-group-material-yellow-A700 label.control-label {
  color: #ffd600;
}
.form-group-material-amber label.control-label {
  color: #ffc107;
}
.form-group-material-amber-50 label.control-label {
  color: #fff8e1;
}
.form-group-material-amber-100 label.control-label {
  color: #ffecb3;
}
.form-group-material-amber-200 label.control-label {
  color: #ffe082;
}
.form-group-material-amber-300 label.control-label {
  color: #ffd54f;
}
.form-group-material-amber-400 label.control-label {
  color: #ffca28;
}
.form-group-material-amber-500 label.control-label {
  color: #ffc107;
}
.form-group-material-amber-600 label.control-label {
  color: #ffb300;
}
.form-group-material-amber-700 label.control-label {
  color: #ffa000;
}
.form-group-material-amber-800 label.control-label {
  color: #ff8f00;
}
.form-group-material-amber-900 label.control-label {
  color: #ff6f00;
}
.form-group-material-amber-A100 label.control-label {
  color: #ffe57f;
}
.form-group-material-amber-A200 label.control-label {
  color: #ffd740;
}
.form-group-material-amber-A400 label.control-label {
  color: #ffc400;
}
.form-group-material-amber-A700 label.control-label {
  color: #ffab00;
}
.form-group-material-orange label.control-label {
  color: #ff9800;
}
.form-group-material-orange-50 label.control-label {
  color: #fff3e0;
}
.form-group-material-orange-100 label.control-label {
  color: #ffe0b2;
}
.form-group-material-orange-200 label.control-label {
  color: #ffcc80;
}
.form-group-material-orange-300 label.control-label {
  color: #ffb74d;
}
.form-group-material-orange-400 label.control-label {
  color: #ffa726;
}
.form-group-material-orange-500 label.control-label {
  color: #ff9800;
}
.form-group-material-orange-600 label.control-label {
  color: #fb8c00;
}
.form-group-material-orange-700 label.control-label {
  color: #f57c00;
}
.form-group-material-orange-800 label.control-label {
  color: #ef6c00;
}
.form-group-material-orange-900 label.control-label {
  color: #e65100;
}
.form-group-material-orange-A100 label.control-label {
  color: #ffd180;
}
.form-group-material-orange-A200 label.control-label {
  color: #ffab40;
}
.form-group-material-orange-A400 label.control-label {
  color: #ff9100;
}
.form-group-material-orange-A700 label.control-label {
  color: #ff6d00;
}
.form-group-material-deep-orange label.control-label {
  color: #ff5722;
}
.form-group-material-deep-orange-50 label.control-label {
  color: #fbe9e7;
}
.form-group-material-deep-orange-100 label.control-label {
  color: #ffccbc;
}
.form-group-material-deep-orange-200 label.control-label {
  color: #ffab91;
}
.form-group-material-deep-orange-300 label.control-label {
  color: #ff8a65;
}
.form-group-material-deep-orange-400 label.control-label {
  color: #ff7043;
}
.form-group-material-deep-orange-500 label.control-label {
  color: #ff5722;
}
.form-group-material-deep-orange-600 label.control-label {
  color: #f4511e;
}
.form-group-material-deep-orange-700 label.control-label {
  color: #e64a19;
}
.form-group-material-deep-orange-800 label.control-label {
  color: #d84315;
}
.form-group-material-deep-orange-900 label.control-label {
  color: #bf360c;
}
.form-group-material-deep-orange-A100 label.control-label {
  color: #ff9e80;
}
.form-group-material-deep-orange-A200 label.control-label {
  color: #ff6e40;
}
.form-group-material-deep-orange-A400 label.control-label {
  color: #ff3d00;
}
.form-group-material-deep-orange-A700 label.control-label {
  color: #dd2c00;
}
.form-group-material-brown label.control-label {
  color: #795548;
}
.form-group-material-brown-50 label.control-label {
  color: #efebe9;
}
.form-group-material-brown-100 label.control-label {
  color: #d7ccc8;
}
.form-group-material-brown-200 label.control-label {
  color: #bcaaa4;
}
.form-group-material-brown-300 label.control-label {
  color: #a1887f;
}
.form-group-material-brown-400 label.control-label {
  color: #8d6e63;
}
.form-group-material-brown-500 label.control-label {
  color: #795548;
}
.form-group-material-brown-600 label.control-label {
  color: #6d4c41;
}
.form-group-material-brown-700 label.control-label {
  color: #5d4037;
}
.form-group-material-brown-800 label.control-label {
  color: #4e342e;
}
.form-group-material-brown-900 label.control-label {
  color: #3e2723;
}
.form-group-material-brown-A100 label.control-label {
  color: #d7ccc8;
}
.form-group-material-brown-A200 label.control-label {
  color: #bcaaa4;
}
.form-group-material-brown-A400 label.control-label {
  color: #8d6e63;
}
.form-group-material-brown-A700 label.control-label {
  color: #5d4037;
}
.form-group-material-grey label.control-label {
  color: #9e9e9e;
}
.form-group-material-grey-50 label.control-label {
  color: #fafafa;
}
.form-group-material-grey-100 label.control-label {
  color: #f5f5f5;
}
.form-group-material-grey-200 label.control-label {
  color: #eeeeee;
}
.form-group-material-grey-300 label.control-label {
  color: #e0e0e0;
}
.form-group-material-grey-400 label.control-label {
  color: #bdbdbd;
}
.form-group-material-grey-500 label.control-label {
  color: #9e9e9e;
}
.form-group-material-grey-600 label.control-label {
  color: #757575;
}
.form-group-material-grey-700 label.control-label {
  color: #616161;
}
.form-group-material-grey-800 label.control-label {
  color: #424242;
}
.form-group-material-grey-900 label.control-label {
  color: #212121;
}
.form-group-material-grey-A100 label.control-label {
  color: #f5f5f5;
}
.form-group-material-grey-A200 label.control-label {
  color: #eeeeee;
}
.form-group-material-grey-A400 label.control-label {
  color: #bdbdbd;
}
.form-group-material-grey-A700 label.control-label {
  color: #616161;
}
.form-group-material-blue-grey label.control-label {
  color: #607d8b;
}
.form-group-material-blue-grey-50 label.control-label {
  color: #eceff1;
}
.form-group-material-blue-grey-100 label.control-label {
  color: #cfd8dc;
}
.form-group-material-blue-grey-200 label.control-label {
  color: #b0bec5;
}
.form-group-material-blue-grey-300 label.control-label {
  color: #90a4ae;
}
.form-group-material-blue-grey-400 label.control-label {
  color: #78909c;
}
.form-group-material-blue-grey-500 label.control-label {
  color: #607d8b;
}
.form-group-material-blue-grey-600 label.control-label {
  color: #546e7a;
}
.form-group-material-blue-grey-700 label.control-label {
  color: #455a64;
}
.form-group-material-blue-grey-800 label.control-label {
  color: #37474f;
}
.form-group-material-blue-grey-900 label.control-label {
  color: #263238;
}
.form-group-material-blue-grey-A100 label.control-label {
  color: #cfd8dc;
}
.form-group-material-blue-grey-A200 label.control-label {
  color: #b0bec5;
}
.form-group-material-blue-grey-A400 label.control-label {
  color: #78909c;
}
.form-group-material-blue-grey-A700 label.control-label {
  color: #455a64;
}
.form-group.is-focused label.control-label,
.form-group-default.is-focused label.control-label {
  color: #009688;
}
.form-group-black.is-focused label.control-label {
  color: #000000;
}
.form-group-white.is-focused label.control-label {
  color: #ffffff;
}
.form-group-inverse.is-focused label.control-label {
  color: #3f51b5;
}
.form-group-primary.is-focused label.control-label {
  color: #009688;
}
.form-group-success.is-focused label.control-label {
  color: #4caf50;
}
.form-group-info.is-focused label.control-label {
  color: #03a9f4;
}
.form-group-warning.is-focused label.control-label {
  color: #ff5722;
}
.form-group-danger.is-focused label.control-label {
  color: #f44336;
}
.form-group-material-red.is-focused label.control-label {
  color: #f44336;
}
.form-group-material-red-50.is-focused label.control-label {
  color: #ffebee;
}
.form-group-material-red-100.is-focused label.control-label {
  color: #ffcdd2;
}
.form-group-material-red-200.is-focused label.control-label {
  color: #ef9a9a;
}
.form-group-material-red-300.is-focused label.control-label {
  color: #e57373;
}
.form-group-material-red-400.is-focused label.control-label {
  color: #ef5350;
}
.form-group-material-red-500.is-focused label.control-label {
  color: #f44336;
}
.form-group-material-red-600.is-focused label.control-label {
  color: #e53935;
}
.form-group-material-red-700.is-focused label.control-label {
  color: #d32f2f;
}
.form-group-material-red-800.is-focused label.control-label {
  color: #c62828;
}
.form-group-material-red-900.is-focused label.control-label {
  color: #b71c1c;
}
.form-group-material-red-A100.is-focused label.control-label {
  color: #ff8a80;
}
.form-group-material-red-A200.is-focused label.control-label {
  color: #ff5252;
}
.form-group-material-red-A400.is-focused label.control-label {
  color: #ff1744;
}
.form-group-material-red-A700.is-focused label.control-label {
  color: #d50000;
}
.form-group-material-pink.is-focused label.control-label {
  color: #e91e63;
}
.form-group-material-pink-50.is-focused label.control-label {
  color: #fce4ec;
}
.form-group-material-pink-100.is-focused label.control-label {
  color: #f8bbd0;
}
.form-group-material-pink-200.is-focused label.control-label {
  color: #f48fb1;
}
.form-group-material-pink-300.is-focused label.control-label {
  color: #f06292;
}
.form-group-material-pink-400.is-focused label.control-label {
  color: #ec407a;
}
.form-group-material-pink-500.is-focused label.control-label {
  color: #e91e63;
}
.form-group-material-pink-600.is-focused label.control-label {
  color: #d81b60;
}
.form-group-material-pink-700.is-focused label.control-label {
  color: #c2185b;
}
.form-group-material-pink-800.is-focused label.control-label {
  color: #ad1457;
}
.form-group-material-pink-900.is-focused label.control-label {
  color: #880e4f;
}
.form-group-material-pink-A100.is-focused label.control-label {
  color: #ff80ab;
}
.form-group-material-pink-A200.is-focused label.control-label {
  color: #ff4081;
}
.form-group-material-pink-A400.is-focused label.control-label {
  color: #f50057;
}
.form-group-material-pink-A700.is-focused label.control-label {
  color: #c51162;
}
.form-group-material-purple.is-focused label.control-label {
  color: #9c27b0;
}
.form-group-material-purple-50.is-focused label.control-label {
  color: #f3e5f5;
}
.form-group-material-purple-100.is-focused label.control-label {
  color: #e1bee7;
}
.form-group-material-purple-200.is-focused label.control-label {
  color: #ce93d8;
}
.form-group-material-purple-300.is-focused label.control-label {
  color: #ba68c8;
}
.form-group-material-purple-400.is-focused label.control-label {
  color: #ab47bc;
}
.form-group-material-purple-500.is-focused label.control-label {
  color: #9c27b0;
}
.form-group-material-purple-600.is-focused label.control-label {
  color: #8e24aa;
}
.form-group-material-purple-700.is-focused label.control-label {
  color: #7b1fa2;
}
.form-group-material-purple-800.is-focused label.control-label {
  color: #6a1b9a;
}
.form-group-material-purple-900.is-focused label.control-label {
  color: #4a148c;
}
.form-group-material-purple-A100.is-focused label.control-label {
  color: #ea80fc;
}
.form-group-material-purple-A200.is-focused label.control-label {
  color: #e040fb;
}
.form-group-material-purple-A400.is-focused label.control-label {
  color: #d500f9;
}
.form-group-material-purple-A700.is-focused label.control-label {
  color: #aa00ff;
}
.form-group-material-deep-purple.is-focused label.control-label {
  color: #673ab7;
}
.form-group-material-deep-purple-50.is-focused label.control-label {
  color: #ede7f6;
}
.form-group-material-deep-purple-100.is-focused label.control-label {
  color: #d1c4e9;
}
.form-group-material-deep-purple-200.is-focused label.control-label {
  color: #b39ddb;
}
.form-group-material-deep-purple-300.is-focused label.control-label {
  color: #9575cd;
}
.form-group-material-deep-purple-400.is-focused label.control-label {
  color: #7e57c2;
}
.form-group-material-deep-purple-500.is-focused label.control-label {
  color: #673ab7;
}
.form-group-material-deep-purple-600.is-focused label.control-label {
  color: #5e35b1;
}
.form-group-material-deep-purple-700.is-focused label.control-label {
  color: #512da8;
}
.form-group-material-deep-purple-800.is-focused label.control-label {
  color: #4527a0;
}
.form-group-material-deep-purple-900.is-focused label.control-label {
  color: #311b92;
}
.form-group-material-deep-purple-A100.is-focused label.control-label {
  color: #b388ff;
}
.form-group-material-deep-purple-A200.is-focused label.control-label {
  color: #7c4dff;
}
.form-group-material-deep-purple-A400.is-focused label.control-label {
  color: #651fff;
}
.form-group-material-deep-purple-A700.is-focused label.control-label {
  color: #6200ea;
}
.form-group-material-indigo.is-focused label.control-label {
  color: #3f51b5;
}
.form-group-material-indigo-50.is-focused label.control-label {
  color: #e8eaf6;
}
.form-group-material-indigo-100.is-focused label.control-label {
  color: #c5cae9;
}
.form-group-material-indigo-200.is-focused label.control-label {
  color: #9fa8da;
}
.form-group-material-indigo-300.is-focused label.control-label {
  color: #7986cb;
}
.form-group-material-indigo-400.is-focused label.control-label {
  color: #5c6bc0;
}
.form-group-material-indigo-500.is-focused label.control-label {
  color: #3f51b5;
}
.form-group-material-indigo-600.is-focused label.control-label {
  color: #3949ab;
}
.form-group-material-indigo-700.is-focused label.control-label {
  color: #303f9f;
}
.form-group-material-indigo-800.is-focused label.control-label {
  color: #283593;
}
.form-group-material-indigo-900.is-focused label.control-label {
  color: #1a237e;
}
.form-group-material-indigo-A100.is-focused label.control-label {
  color: #8c9eff;
}
.form-group-material-indigo-A200.is-focused label.control-label {
  color: #536dfe;
}
.form-group-material-indigo-A400.is-focused label.control-label {
  color: #3d5afe;
}
.form-group-material-indigo-A700.is-focused label.control-label {
  color: #304ffe;
}
.form-group-material-blue.is-focused label.control-label {
  color: #2196f3;
}
.form-group-material-blue-50.is-focused label.control-label {
  color: #e3f2fd;
}
.form-group-material-blue-100.is-focused label.control-label {
  color: #bbdefb;
}
.form-group-material-blue-200.is-focused label.control-label {
  color: #90caf9;
}
.form-group-material-blue-300.is-focused label.control-label {
  color: #64b5f6;
}
.form-group-material-blue-400.is-focused label.control-label {
  color: #42a5f5;
}
.form-group-material-blue-500.is-focused label.control-label {
  color: #2196f3;
}
.form-group-material-blue-600.is-focused label.control-label {
  color: #1e88e5;
}
.form-group-material-blue-700.is-focused label.control-label {
  color: #1976d2;
}
.form-group-material-blue-800.is-focused label.control-label {
  color: #1565c0;
}
.form-group-material-blue-900.is-focused label.control-label {
  color: #0d47a1;
}
.form-group-material-blue-A100.is-focused label.control-label {
  color: #82b1ff;
}
.form-group-material-blue-A200.is-focused label.control-label {
  color: #448aff;
}
.form-group-material-blue-A400.is-focused label.control-label {
  color: #2979ff;
}
.form-group-material-blue-A700.is-focused label.control-label {
  color: #2962ff;
}
.form-group-material-light-blue.is-focused label.control-label {
  color: #03a9f4;
}
.form-group-material-light-blue-50.is-focused label.control-label {
  color: #e1f5fe;
}
.form-group-material-light-blue-100.is-focused label.control-label {
  color: #b3e5fc;
}
.form-group-material-light-blue-200.is-focused label.control-label {
  color: #81d4fa;
}
.form-group-material-light-blue-300.is-focused label.control-label {
  color: #4fc3f7;
}
.form-group-material-light-blue-400.is-focused label.control-label {
  color: #29b6f6;
}
.form-group-material-light-blue-500.is-focused label.control-label {
  color: #03a9f4;
}
.form-group-material-light-blue-600.is-focused label.control-label {
  color: #039be5;
}
.form-group-material-light-blue-700.is-focused label.control-label {
  color: #0288d1;
}
.form-group-material-light-blue-800.is-focused label.control-label {
  color: #0277bd;
}
.form-group-material-light-blue-900.is-focused label.control-label {
  color: #01579b;
}
.form-group-material-light-blue-A100.is-focused label.control-label {
  color: #80d8ff;
}
.form-group-material-light-blue-A200.is-focused label.control-label {
  color: #40c4ff;
}
.form-group-material-light-blue-A400.is-focused label.control-label {
  color: #00b0ff;
}
.form-group-material-light-blue-A700.is-focused label.control-label {
  color: #0091ea;
}
.form-group-material-cyan.is-focused label.control-label {
  color: #00bcd4;
}
.form-group-material-cyan-50.is-focused label.control-label {
  color: #e0f7fa;
}
.form-group-material-cyan-100.is-focused label.control-label {
  color: #b2ebf2;
}
.form-group-material-cyan-200.is-focused label.control-label {
  color: #80deea;
}
.form-group-material-cyan-300.is-focused label.control-label {
  color: #4dd0e1;
}
.form-group-material-cyan-400.is-focused label.control-label {
  color: #26c6da;
}
.form-group-material-cyan-500.is-focused label.control-label {
  color: #00bcd4;
}
.form-group-material-cyan-600.is-focused label.control-label {
  color: #00acc1;
}
.form-group-material-cyan-700.is-focused label.control-label {
  color: #0097a7;
}
.form-group-material-cyan-800.is-focused label.control-label {
  color: #00838f;
}
.form-group-material-cyan-900.is-focused label.control-label {
  color: #006064;
}
.form-group-material-cyan-A100.is-focused label.control-label {
  color: #84ffff;
}
.form-group-material-cyan-A200.is-focused label.control-label {
  color: #18ffff;
}
.form-group-material-cyan-A400.is-focused label.control-label {
  color: #00e5ff;
}
.form-group-material-cyan-A700.is-focused label.control-label {
  color: #00b8d4;
}
.form-group-material-teal.is-focused label.control-label {
  color: #009688;
}
.form-group-material-teal-50.is-focused label.control-label {
  color: #e0f2f1;
}
.form-group-material-teal-100.is-focused label.control-label {
  color: #b2dfdb;
}
.form-group-material-teal-200.is-focused label.control-label {
  color: #80cbc4;
}
.form-group-material-teal-300.is-focused label.control-label {
  color: #4db6ac;
}
.form-group-material-teal-400.is-focused label.control-label {
  color: #26a69a;
}
.form-group-material-teal-500.is-focused label.control-label {
  color: #009688;
}
.form-group-material-teal-600.is-focused label.control-label {
  color: #00897b;
}
.form-group-material-teal-700.is-focused label.control-label {
  color: #00796b;
}
.form-group-material-teal-800.is-focused label.control-label {
  color: #00695c;
}
.form-group-material-teal-900.is-focused label.control-label {
  color: #004d40;
}
.form-group-material-teal-A100.is-focused label.control-label {
  color: #a7ffeb;
}
.form-group-material-teal-A200.is-focused label.control-label {
  color: #64ffda;
}
.form-group-material-teal-A400.is-focused label.control-label {
  color: #1de9b6;
}
.form-group-material-teal-A700.is-focused label.control-label {
  color: #00bfa5;
}
.form-group-material-green.is-focused label.control-label {
  color: #4caf50;
}
.form-group-material-green-50.is-focused label.control-label {
  color: #e8f5e9;
}
.form-group-material-green-100.is-focused label.control-label {
  color: #c8e6c9;
}
.form-group-material-green-200.is-focused label.control-label {
  color: #a5d6a7;
}
.form-group-material-green-300.is-focused label.control-label {
  color: #81c784;
}
.form-group-material-green-400.is-focused label.control-label {
  color: #66bb6a;
}
.form-group-material-green-500.is-focused label.control-label {
  color: #4caf50;
}
.form-group-material-green-600.is-focused label.control-label {
  color: #43a047;
}
.form-group-material-green-700.is-focused label.control-label {
  color: #388e3c;
}
.form-group-material-green-800.is-focused label.control-label {
  color: #2e7d32;
}
.form-group-material-green-900.is-focused label.control-label {
  color: #1b5e20;
}
.form-group-material-green-A100.is-focused label.control-label {
  color: #b9f6ca;
}
.form-group-material-green-A200.is-focused label.control-label {
  color: #69f0ae;
}
.form-group-material-green-A400.is-focused label.control-label {
  color: #00e676;
}
.form-group-material-green-A700.is-focused label.control-label {
  color: #00c853;
}
.form-group-material-light-green.is-focused label.control-label {
  color: #8bc34a;
}
.form-group-material-light-green-50.is-focused label.control-label {
  color: #f1f8e9;
}
.form-group-material-light-green-100.is-focused label.control-label {
  color: #dcedc8;
}
.form-group-material-light-green-200.is-focused label.control-label {
  color: #c5e1a5;
}
.form-group-material-light-green-300.is-focused label.control-label {
  color: #aed581;
}
.form-group-material-light-green-400.is-focused label.control-label {
  color: #9ccc65;
}
.form-group-material-light-green-500.is-focused label.control-label {
  color: #8bc34a;
}
.form-group-material-light-green-600.is-focused label.control-label {
  color: #7cb342;
}
.form-group-material-light-green-700.is-focused label.control-label {
  color: #689f38;
}
.form-group-material-light-green-800.is-focused label.control-label {
  color: #558b2f;
}
.form-group-material-light-green-900.is-focused label.control-label {
  color: #33691e;
}
.form-group-material-light-green-A100.is-focused label.control-label {
  color: #ccff90;
}
.form-group-material-light-green-A200.is-focused label.control-label {
  color: #b2ff59;
}
.form-group-material-light-green-A400.is-focused label.control-label {
  color: #76ff03;
}
.form-group-material-light-green-A700.is-focused label.control-label {
  color: #64dd17;
}
.form-group-material-lime.is-focused label.control-label {
  color: #cddc39;
}
.form-group-material-lime-50.is-focused label.control-label {
  color: #f9fbe7;
}
.form-group-material-lime-100.is-focused label.control-label {
  color: #f0f4c3;
}
.form-group-material-lime-200.is-focused label.control-label {
  color: #e6ee9c;
}
.form-group-material-lime-300.is-focused label.control-label {
  color: #dce775;
}
.form-group-material-lime-400.is-focused label.control-label {
  color: #d4e157;
}
.form-group-material-lime-500.is-focused label.control-label {
  color: #cddc39;
}
.form-group-material-lime-600.is-focused label.control-label {
  color: #c0ca33;
}
.form-group-material-lime-700.is-focused label.control-label {
  color: #afb42b;
}
.form-group-material-lime-800.is-focused label.control-label {
  color: #9e9d24;
}
.form-group-material-lime-900.is-focused label.control-label {
  color: #827717;
}
.form-group-material-lime-A100.is-focused label.control-label {
  color: #f4ff81;
}
.form-group-material-lime-A200.is-focused label.control-label {
  color: #eeff41;
}
.form-group-material-lime-A400.is-focused label.control-label {
  color: #c6ff00;
}
.form-group-material-lime-A700.is-focused label.control-label {
  color: #aeea00;
}
.form-group-material-yellow.is-focused label.control-label {
  color: #ffeb3b;
}
.form-group-material-yellow-50.is-focused label.control-label {
  color: #fffde7;
}
.form-group-material-yellow-100.is-focused label.control-label {
  color: #fff9c4;
}
.form-group-material-yellow-200.is-focused label.control-label {
  color: #fff59d;
}
.form-group-material-yellow-300.is-focused label.control-label {
  color: #fff176;
}
.form-group-material-yellow-400.is-focused label.control-label {
  color: #ffee58;
}
.form-group-material-yellow-500.is-focused label.control-label {
  color: #ffeb3b;
}
.form-group-material-yellow-600.is-focused label.control-label {
  color: #fdd835;
}
.form-group-material-yellow-700.is-focused label.control-label {
  color: #fbc02d;
}
.form-group-material-yellow-800.is-focused label.control-label {
  color: #f9a825;
}
.form-group-material-yellow-900.is-focused label.control-label {
  color: #f57f17;
}
.form-group-material-yellow-A100.is-focused label.control-label {
  color: #ffff8d;
}
.form-group-material-yellow-A200.is-focused label.control-label {
  color: #ffff00;
}
.form-group-material-yellow-A400.is-focused label.control-label {
  color: #ffea00;
}
.form-group-material-yellow-A700.is-focused label.control-label {
  color: #ffd600;
}
.form-group-material-amber.is-focused label.control-label {
  color: #ffc107;
}
.form-group-material-amber-50.is-focused label.control-label {
  color: #fff8e1;
}
.form-group-material-amber-100.is-focused label.control-label {
  color: #ffecb3;
}
.form-group-material-amber-200.is-focused label.control-label {
  color: #ffe082;
}
.form-group-material-amber-300.is-focused label.control-label {
  color: #ffd54f;
}
.form-group-material-amber-400.is-focused label.control-label {
  color: #ffca28;
}
.form-group-material-amber-500.is-focused label.control-label {
  color: #ffc107;
}
.form-group-material-amber-600.is-focused label.control-label {
  color: #ffb300;
}
.form-group-material-amber-700.is-focused label.control-label {
  color: #ffa000;
}
.form-group-material-amber-800.is-focused label.control-label {
  color: #ff8f00;
}
.form-group-material-amber-900.is-focused label.control-label {
  color: #ff6f00;
}
.form-group-material-amber-A100.is-focused label.control-label {
  color: #ffe57f;
}
.form-group-material-amber-A200.is-focused label.control-label {
  color: #ffd740;
}
.form-group-material-amber-A400.is-focused label.control-label {
  color: #ffc400;
}
.form-group-material-amber-A700.is-focused label.control-label {
  color: #ffab00;
}
.form-group-material-orange.is-focused label.control-label {
  color: #ff9800;
}
.form-group-material-orange-50.is-focused label.control-label {
  color: #fff3e0;
}
.form-group-material-orange-100.is-focused label.control-label {
  color: #ffe0b2;
}
.form-group-material-orange-200.is-focused label.control-label {
  color: #ffcc80;
}
.form-group-material-orange-300.is-focused label.control-label {
  color: #ffb74d;
}
.form-group-material-orange-400.is-focused label.control-label {
  color: #ffa726;
}
.form-group-material-orange-500.is-focused label.control-label {
  color: #ff9800;
}
.form-group-material-orange-600.is-focused label.control-label {
  color: #fb8c00;
}
.form-group-material-orange-700.is-focused label.control-label {
  color: #f57c00;
}
.form-group-material-orange-800.is-focused label.control-label {
  color: #ef6c00;
}
.form-group-material-orange-900.is-focused label.control-label {
  color: #e65100;
}
.form-group-material-orange-A100.is-focused label.control-label {
  color: #ffd180;
}
.form-group-material-orange-A200.is-focused label.control-label {
  color: #ffab40;
}
.form-group-material-orange-A400.is-focused label.control-label {
  color: #ff9100;
}
.form-group-material-orange-A700.is-focused label.control-label {
  color: #ff6d00;
}
.form-group-material-deep-orange.is-focused label.control-label {
  color: #ff5722;
}
.form-group-material-deep-orange-50.is-focused label.control-label {
  color: #fbe9e7;
}
.form-group-material-deep-orange-100.is-focused label.control-label {
  color: #ffccbc;
}
.form-group-material-deep-orange-200.is-focused label.control-label {
  color: #ffab91;
}
.form-group-material-deep-orange-300.is-focused label.control-label {
  color: #ff8a65;
}
.form-group-material-deep-orange-400.is-focused label.control-label {
  color: #ff7043;
}
.form-group-material-deep-orange-500.is-focused label.control-label {
  color: #ff5722;
}
.form-group-material-deep-orange-600.is-focused label.control-label {
  color: #f4511e;
}
.form-group-material-deep-orange-700.is-focused label.control-label {
  color: #e64a19;
}
.form-group-material-deep-orange-800.is-focused label.control-label {
  color: #d84315;
}
.form-group-material-deep-orange-900.is-focused label.control-label {
  color: #bf360c;
}
.form-group-material-deep-orange-A100.is-focused label.control-label {
  color: #ff9e80;
}
.form-group-material-deep-orange-A200.is-focused label.control-label {
  color: #ff6e40;
}
.form-group-material-deep-orange-A400.is-focused label.control-label {
  color: #ff3d00;
}
.form-group-material-deep-orange-A700.is-focused label.control-label {
  color: #dd2c00;
}
.form-group-material-brown.is-focused label.control-label {
  color: #795548;
}
.form-group-material-brown-50.is-focused label.control-label {
  color: #efebe9;
}
.form-group-material-brown-100.is-focused label.control-label {
  color: #d7ccc8;
}
.form-group-material-brown-200.is-focused label.control-label {
  color: #bcaaa4;
}
.form-group-material-brown-300.is-focused label.control-label {
  color: #a1887f;
}
.form-group-material-brown-400.is-focused label.control-label {
  color: #8d6e63;
}
.form-group-material-brown-500.is-focused label.control-label {
  color: #795548;
}
.form-group-material-brown-600.is-focused label.control-label {
  color: #6d4c41;
}
.form-group-material-brown-700.is-focused label.control-label {
  color: #5d4037;
}
.form-group-material-brown-800.is-focused label.control-label {
  color: #4e342e;
}
.form-group-material-brown-900.is-focused label.control-label {
  color: #3e2723;
}
.form-group-material-brown-A100.is-focused label.control-label {
  color: #d7ccc8;
}
.form-group-material-brown-A200.is-focused label.control-label {
  color: #bcaaa4;
}
.form-group-material-brown-A400.is-focused label.control-label {
  color: #8d6e63;
}
.form-group-material-brown-A700.is-focused label.control-label {
  color: #5d4037;
}
.form-group-material-grey.is-focused label.control-label {
  color: #9e9e9e;
}
.form-group-material-grey-50.is-focused label.control-label {
  color: #fafafa;
}
.form-group-material-grey-100.is-focused label.control-label {
  color: #f5f5f5;
}
.form-group-material-grey-200.is-focused label.control-label {
  color: #eeeeee;
}
.form-group-material-grey-300.is-focused label.control-label {
  color: #e0e0e0;
}
.form-group-material-grey-400.is-focused label.control-label {
  color: #bdbdbd;
}
.form-group-material-grey-500.is-focused label.control-label {
  color: #9e9e9e;
}
.form-group-material-grey-600.is-focused label.control-label {
  color: #757575;
}
.form-group-material-grey-700.is-focused label.control-label {
  color: #616161;
}
.form-group-material-grey-800.is-focused label.control-label {
  color: #424242;
}
.form-group-material-grey-900.is-focused label.control-label {
  color: #212121;
}
.form-group-material-grey-A100.is-focused label.control-label {
  color: #f5f5f5;
}
.form-group-material-grey-A200.is-focused label.control-label {
  color: #eeeeee;
}
.form-group-material-grey-A400.is-focused label.control-label {
  color: #bdbdbd;
}
.form-group-material-grey-A700.is-focused label.control-label {
  color: #616161;
}
.form-group-material-blue-grey.is-focused label.control-label {
  color: #607d8b;
}
.form-group-material-blue-grey-50.is-focused label.control-label {
  color: #eceff1;
}
.form-group-material-blue-grey-100.is-focused label.control-label {
  color: #cfd8dc;
}
.form-group-material-blue-grey-200.is-focused label.control-label {
  color: #b0bec5;
}
.form-group-material-blue-grey-300.is-focused label.control-label {
  color: #90a4ae;
}
.form-group-material-blue-grey-400.is-focused label.control-label {
  color: #78909c;
}
.form-group-material-blue-grey-500.is-focused label.control-label {
  color: #607d8b;
}
.form-group-material-blue-grey-600.is-focused label.control-label {
  color: #546e7a;
}
.form-group-material-blue-grey-700.is-focused label.control-label {
  color: #455a64;
}
.form-group-material-blue-grey-800.is-focused label.control-label {
  color: #37474f;
}
.form-group-material-blue-grey-900.is-focused label.control-label {
  color: #263238;
}
.form-group-material-blue-grey-A100.is-focused label.control-label {
  color: #cfd8dc;
}
.form-group-material-blue-grey-A200.is-focused label.control-label {
  color: #b0bec5;
}
.form-group-material-blue-grey-A400.is-focused label.control-label {
  color: #78909c;
}
.form-group-material-blue-grey-A700.is-focused label.control-label {
  color: #455a64;
}
.form-group.is-focused.label-placeholder label.control-label,
.form-group-default.is-focused.label-placeholder label.control-label {
  color: #BDBDBD;
}
.form-group-black.is-focused.label-placeholder label.control-label {
  color: #000000;
}
.form-group-white.is-focused.label-placeholder label.control-label {
  color: #ffffff;
}
.form-group-inverse.is-focused.label-placeholder label.control-label {
  color: #3f51b5;
}
.form-group-primary.is-focused.label-placeholder label.control-label {
  color: #009688;
}
.form-group-success.is-focused.label-placeholder label.control-label {
  color: #4caf50;
}
.form-group-info.is-focused.label-placeholder label.control-label {
  color: #03a9f4;
}
.form-group-warning.is-focused.label-placeholder label.control-label {
  color: #ff5722;
}
.form-group-danger.is-focused.label-placeholder label.control-label {
  color: #f44336;
}
.form-group-material-red.is-focused.label-placeholder label.control-label {
  color: #f44336;
}
.form-group-material-red-50.is-focused.label-placeholder label.control-label {
  color: #ffebee;
}
.form-group-material-red-100.is-focused.label-placeholder label.control-label {
  color: #ffcdd2;
}
.form-group-material-red-200.is-focused.label-placeholder label.control-label {
  color: #ef9a9a;
}
.form-group-material-red-300.is-focused.label-placeholder label.control-label {
  color: #e57373;
}
.form-group-material-red-400.is-focused.label-placeholder label.control-label {
  color: #ef5350;
}
.form-group-material-red-500.is-focused.label-placeholder label.control-label {
  color: #f44336;
}
.form-group-material-red-600.is-focused.label-placeholder label.control-label {
  color: #e53935;
}
.form-group-material-red-700.is-focused.label-placeholder label.control-label {
  color: #d32f2f;
}
.form-group-material-red-800.is-focused.label-placeholder label.control-label {
  color: #c62828;
}
.form-group-material-red-900.is-focused.label-placeholder label.control-label {
  color: #b71c1c;
}
.form-group-material-red-A100.is-focused.label-placeholder label.control-label {
  color: #ff8a80;
}
.form-group-material-red-A200.is-focused.label-placeholder label.control-label {
  color: #ff5252;
}
.form-group-material-red-A400.is-focused.label-placeholder label.control-label {
  color: #ff1744;
}
.form-group-material-red-A700.is-focused.label-placeholder label.control-label {
  color: #d50000;
}
.form-group-material-pink.is-focused.label-placeholder label.control-label {
  color: #e91e63;
}
.form-group-material-pink-50.is-focused.label-placeholder label.control-label {
  color: #fce4ec;
}
.form-group-material-pink-100.is-focused.label-placeholder label.control-label {
  color: #f8bbd0;
}
.form-group-material-pink-200.is-focused.label-placeholder label.control-label {
  color: #f48fb1;
}
.form-group-material-pink-300.is-focused.label-placeholder label.control-label {
  color: #f06292;
}
.form-group-material-pink-400.is-focused.label-placeholder label.control-label {
  color: #ec407a;
}
.form-group-material-pink-500.is-focused.label-placeholder label.control-label {
  color: #e91e63;
}
.form-group-material-pink-600.is-focused.label-placeholder label.control-label {
  color: #d81b60;
}
.form-group-material-pink-700.is-focused.label-placeholder label.control-label {
  color: #c2185b;
}
.form-group-material-pink-800.is-focused.label-placeholder label.control-label {
  color: #ad1457;
}
.form-group-material-pink-900.is-focused.label-placeholder label.control-label {
  color: #880e4f;
}
.form-group-material-pink-A100.is-focused.label-placeholder label.control-label {
  color: #ff80ab;
}
.form-group-material-pink-A200.is-focused.label-placeholder label.control-label {
  color: #ff4081;
}
.form-group-material-pink-A400.is-focused.label-placeholder label.control-label {
  color: #f50057;
}
.form-group-material-pink-A700.is-focused.label-placeholder label.control-label {
  color: #c51162;
}
.form-group-material-purple.is-focused.label-placeholder label.control-label {
  color: #9c27b0;
}
.form-group-material-purple-50.is-focused.label-placeholder label.control-label {
  color: #f3e5f5;
}
.form-group-material-purple-100.is-focused.label-placeholder label.control-label {
  color: #e1bee7;
}
.form-group-material-purple-200.is-focused.label-placeholder label.control-label {
  color: #ce93d8;
}
.form-group-material-purple-300.is-focused.label-placeholder label.control-label {
  color: #ba68c8;
}
.form-group-material-purple-400.is-focused.label-placeholder label.control-label {
  color: #ab47bc;
}
.form-group-material-purple-500.is-focused.label-placeholder label.control-label {
  color: #9c27b0;
}
.form-group-material-purple-600.is-focused.label-placeholder label.control-label {
  color: #8e24aa;
}
.form-group-material-purple-700.is-focused.label-placeholder label.control-label {
  color: #7b1fa2;
}
.form-group-material-purple-800.is-focused.label-placeholder label.control-label {
  color: #6a1b9a;
}
.form-group-material-purple-900.is-focused.label-placeholder label.control-label {
  color: #4a148c;
}
.form-group-material-purple-A100.is-focused.label-placeholder label.control-label {
  color: #ea80fc;
}
.form-group-material-purple-A200.is-focused.label-placeholder label.control-label {
  color: #e040fb;
}
.form-group-material-purple-A400.is-focused.label-placeholder label.control-label {
  color: #d500f9;
}
.form-group-material-purple-A700.is-focused.label-placeholder label.control-label {
  color: #aa00ff;
}
.form-group-material-deep-purple.is-focused.label-placeholder label.control-label {
  color: #673ab7;
}
.form-group-material-deep-purple-50.is-focused.label-placeholder label.control-label {
  color: #ede7f6;
}
.form-group-material-deep-purple-100.is-focused.label-placeholder label.control-label {
  color: #d1c4e9;
}
.form-group-material-deep-purple-200.is-focused.label-placeholder label.control-label {
  color: #b39ddb;
}
.form-group-material-deep-purple-300.is-focused.label-placeholder label.control-label {
  color: #9575cd;
}
.form-group-material-deep-purple-400.is-focused.label-placeholder label.control-label {
  color: #7e57c2;
}
.form-group-material-deep-purple-500.is-focused.label-placeholder label.control-label {
  color: #673ab7;
}
.form-group-material-deep-purple-600.is-focused.label-placeholder label.control-label {
  color: #5e35b1;
}
.form-group-material-deep-purple-700.is-focused.label-placeholder label.control-label {
  color: #512da8;
}
.form-group-material-deep-purple-800.is-focused.label-placeholder label.control-label {
  color: #4527a0;
}
.form-group-material-deep-purple-900.is-focused.label-placeholder label.control-label {
  color: #311b92;
}
.form-group-material-deep-purple-A100.is-focused.label-placeholder label.control-label {
  color: #b388ff;
}
.form-group-material-deep-purple-A200.is-focused.label-placeholder label.control-label {
  color: #7c4dff;
}
.form-group-material-deep-purple-A400.is-focused.label-placeholder label.control-label {
  color: #651fff;
}
.form-group-material-deep-purple-A700.is-focused.label-placeholder label.control-label {
  color: #6200ea;
}
.form-group-material-indigo.is-focused.label-placeholder label.control-label {
  color: #3f51b5;
}
.form-group-material-indigo-50.is-focused.label-placeholder label.control-label {
  color: #e8eaf6;
}
.form-group-material-indigo-100.is-focused.label-placeholder label.control-label {
  color: #c5cae9;
}
.form-group-material-indigo-200.is-focused.label-placeholder label.control-label {
  color: #9fa8da;
}
.form-group-material-indigo-300.is-focused.label-placeholder label.control-label {
  color: #7986cb;
}
.form-group-material-indigo-400.is-focused.label-placeholder label.control-label {
  color: #5c6bc0;
}
.form-group-material-indigo-500.is-focused.label-placeholder label.control-label {
  color: #3f51b5;
}
.form-group-material-indigo-600.is-focused.label-placeholder label.control-label {
  color: #3949ab;
}
.form-group-material-indigo-700.is-focused.label-placeholder label.control-label {
  color: #303f9f;
}
.form-group-material-indigo-800.is-focused.label-placeholder label.control-label {
  color: #283593;
}
.form-group-material-indigo-900.is-focused.label-placeholder label.control-label {
  color: #1a237e;
}
.form-group-material-indigo-A100.is-focused.label-placeholder label.control-label {
  color: #8c9eff;
}
.form-group-material-indigo-A200.is-focused.label-placeholder label.control-label {
  color: #536dfe;
}
.form-group-material-indigo-A400.is-focused.label-placeholder label.control-label {
  color: #3d5afe;
}
.form-group-material-indigo-A700.is-focused.label-placeholder label.control-label {
  color: #304ffe;
}
.form-group-material-blue.is-focused.label-placeholder label.control-label {
  color: #2196f3;
}
.form-group-material-blue-50.is-focused.label-placeholder label.control-label {
  color: #e3f2fd;
}
.form-group-material-blue-100.is-focused.label-placeholder label.control-label {
  color: #bbdefb;
}
.form-group-material-blue-200.is-focused.label-placeholder label.control-label {
  color: #90caf9;
}
.form-group-material-blue-300.is-focused.label-placeholder label.control-label {
  color: #64b5f6;
}
.form-group-material-blue-400.is-focused.label-placeholder label.control-label {
  color: #42a5f5;
}
.form-group-material-blue-500.is-focused.label-placeholder label.control-label {
  color: #2196f3;
}
.form-group-material-blue-600.is-focused.label-placeholder label.control-label {
  color: #1e88e5;
}
.form-group-material-blue-700.is-focused.label-placeholder label.control-label {
  color: #1976d2;
}
.form-group-material-blue-800.is-focused.label-placeholder label.control-label {
  color: #1565c0;
}
.form-group-material-blue-900.is-focused.label-placeholder label.control-label {
  color: #0d47a1;
}
.form-group-material-blue-A100.is-focused.label-placeholder label.control-label {
  color: #82b1ff;
}
.form-group-material-blue-A200.is-focused.label-placeholder label.control-label {
  color: #448aff;
}
.form-group-material-blue-A400.is-focused.label-placeholder label.control-label {
  color: #2979ff;
}
.form-group-material-blue-A700.is-focused.label-placeholder label.control-label {
  color: #2962ff;
}
.form-group-material-light-blue.is-focused.label-placeholder label.control-label {
  color: #03a9f4;
}
.form-group-material-light-blue-50.is-focused.label-placeholder label.control-label {
  color: #e1f5fe;
}
.form-group-material-light-blue-100.is-focused.label-placeholder label.control-label {
  color: #b3e5fc;
}
.form-group-material-light-blue-200.is-focused.label-placeholder label.control-label {
  color: #81d4fa;
}
.form-group-material-light-blue-300.is-focused.label-placeholder label.control-label {
  color: #4fc3f7;
}
.form-group-material-light-blue-400.is-focused.label-placeholder label.control-label {
  color: #29b6f6;
}
.form-group-material-light-blue-500.is-focused.label-placeholder label.control-label {
  color: #03a9f4;
}
.form-group-material-light-blue-600.is-focused.label-placeholder label.control-label {
  color: #039be5;
}
.form-group-material-light-blue-700.is-focused.label-placeholder label.control-label {
  color: #0288d1;
}
.form-group-material-light-blue-800.is-focused.label-placeholder label.control-label {
  color: #0277bd;
}
.form-group-material-light-blue-900.is-focused.label-placeholder label.control-label {
  color: #01579b;
}
.form-group-material-light-blue-A100.is-focused.label-placeholder label.control-label {
  color: #80d8ff;
}
.form-group-material-light-blue-A200.is-focused.label-placeholder label.control-label {
  color: #40c4ff;
}
.form-group-material-light-blue-A400.is-focused.label-placeholder label.control-label {
  color: #00b0ff;
}
.form-group-material-light-blue-A700.is-focused.label-placeholder label.control-label {
  color: #0091ea;
}
.form-group-material-cyan.is-focused.label-placeholder label.control-label {
  color: #00bcd4;
}
.form-group-material-cyan-50.is-focused.label-placeholder label.control-label {
  color: #e0f7fa;
}
.form-group-material-cyan-100.is-focused.label-placeholder label.control-label {
  color: #b2ebf2;
}
.form-group-material-cyan-200.is-focused.label-placeholder label.control-label {
  color: #80deea;
}
.form-group-material-cyan-300.is-focused.label-placeholder label.control-label {
  color: #4dd0e1;
}
.form-group-material-cyan-400.is-focused.label-placeholder label.control-label {
  color: #26c6da;
}
.form-group-material-cyan-500.is-focused.label-placeholder label.control-label {
  color: #00bcd4;
}
.form-group-material-cyan-600.is-focused.label-placeholder label.control-label {
  color: #00acc1;
}
.form-group-material-cyan-700.is-focused.label-placeholder label.control-label {
  color: #0097a7;
}
.form-group-material-cyan-800.is-focused.label-placeholder label.control-label {
  color: #00838f;
}
.form-group-material-cyan-900.is-focused.label-placeholder label.control-label {
  color: #006064;
}
.form-group-material-cyan-A100.is-focused.label-placeholder label.control-label {
  color: #84ffff;
}
.form-group-material-cyan-A200.is-focused.label-placeholder label.control-label {
  color: #18ffff;
}
.form-group-material-cyan-A400.is-focused.label-placeholder label.control-label {
  color: #00e5ff;
}
.form-group-material-cyan-A700.is-focused.label-placeholder label.control-label {
  color: #00b8d4;
}
.form-group-material-teal.is-focused.label-placeholder label.control-label {
  color: #009688;
}
.form-group-material-teal-50.is-focused.label-placeholder label.control-label {
  color: #e0f2f1;
}
.form-group-material-teal-100.is-focused.label-placeholder label.control-label {
  color: #b2dfdb;
}
.form-group-material-teal-200.is-focused.label-placeholder label.control-label {
  color: #80cbc4;
}
.form-group-material-teal-300.is-focused.label-placeholder label.control-label {
  color: #4db6ac;
}
.form-group-material-teal-400.is-focused.label-placeholder label.control-label {
  color: #26a69a;
}
.form-group-material-teal-500.is-focused.label-placeholder label.control-label {
  color: #009688;
}
.form-group-material-teal-600.is-focused.label-placeholder label.control-label {
  color: #00897b;
}
.form-group-material-teal-700.is-focused.label-placeholder label.control-label {
  color: #00796b;
}
.form-group-material-teal-800.is-focused.label-placeholder label.control-label {
  color: #00695c;
}
.form-group-material-teal-900.is-focused.label-placeholder label.control-label {
  color: #004d40;
}
.form-group-material-teal-A100.is-focused.label-placeholder label.control-label {
  color: #a7ffeb;
}
.form-group-material-teal-A200.is-focused.label-placeholder label.control-label {
  color: #64ffda;
}
.form-group-material-teal-A400.is-focused.label-placeholder label.control-label {
  color: #1de9b6;
}
.form-group-material-teal-A700.is-focused.label-placeholder label.control-label {
  color: #00bfa5;
}
.form-group-material-green.is-focused.label-placeholder label.control-label {
  color: #4caf50;
}
.form-group-material-green-50.is-focused.label-placeholder label.control-label {
  color: #e8f5e9;
}
.form-group-material-green-100.is-focused.label-placeholder label.control-label {
  color: #c8e6c9;
}
.form-group-material-green-200.is-focused.label-placeholder label.control-label {
  color: #a5d6a7;
}
.form-group-material-green-300.is-focused.label-placeholder label.control-label {
  color: #81c784;
}
.form-group-material-green-400.is-focused.label-placeholder label.control-label {
  color: #66bb6a;
}
.form-group-material-green-500.is-focused.label-placeholder label.control-label {
  color: #4caf50;
}
.form-group-material-green-600.is-focused.label-placeholder label.control-label {
  color: #43a047;
}
.form-group-material-green-700.is-focused.label-placeholder label.control-label {
  color: #388e3c;
}
.form-group-material-green-800.is-focused.label-placeholder label.control-label {
  color: #2e7d32;
}
.form-group-material-green-900.is-focused.label-placeholder label.control-label {
  color: #1b5e20;
}
.form-group-material-green-A100.is-focused.label-placeholder label.control-label {
  color: #b9f6ca;
}
.form-group-material-green-A200.is-focused.label-placeholder label.control-label {
  color: #69f0ae;
}
.form-group-material-green-A400.is-focused.label-placeholder label.control-label {
  color: #00e676;
}
.form-group-material-green-A700.is-focused.label-placeholder label.control-label {
  color: #00c853;
}
.form-group-material-light-green.is-focused.label-placeholder label.control-label {
  color: #8bc34a;
}
.form-group-material-light-green-50.is-focused.label-placeholder label.control-label {
  color: #f1f8e9;
}
.form-group-material-light-green-100.is-focused.label-placeholder label.control-label {
  color: #dcedc8;
}
.form-group-material-light-green-200.is-focused.label-placeholder label.control-label {
  color: #c5e1a5;
}
.form-group-material-light-green-300.is-focused.label-placeholder label.control-label {
  color: #aed581;
}
.form-group-material-light-green-400.is-focused.label-placeholder label.control-label {
  color: #9ccc65;
}
.form-group-material-light-green-500.is-focused.label-placeholder label.control-label {
  color: #8bc34a;
}
.form-group-material-light-green-600.is-focused.label-placeholder label.control-label {
  color: #7cb342;
}
.form-group-material-light-green-700.is-focused.label-placeholder label.control-label {
  color: #689f38;
}
.form-group-material-light-green-800.is-focused.label-placeholder label.control-label {
  color: #558b2f;
}
.form-group-material-light-green-900.is-focused.label-placeholder label.control-label {
  color: #33691e;
}
.form-group-material-light-green-A100.is-focused.label-placeholder label.control-label {
  color: #ccff90;
}
.form-group-material-light-green-A200.is-focused.label-placeholder label.control-label {
  color: #b2ff59;
}
.form-group-material-light-green-A400.is-focused.label-placeholder label.control-label {
  color: #76ff03;
}
.form-group-material-light-green-A700.is-focused.label-placeholder label.control-label {
  color: #64dd17;
}
.form-group-material-lime.is-focused.label-placeholder label.control-label {
  color: #cddc39;
}
.form-group-material-lime-50.is-focused.label-placeholder label.control-label {
  color: #f9fbe7;
}
.form-group-material-lime-100.is-focused.label-placeholder label.control-label {
  color: #f0f4c3;
}
.form-group-material-lime-200.is-focused.label-placeholder label.control-label {
  color: #e6ee9c;
}
.form-group-material-lime-300.is-focused.label-placeholder label.control-label {
  color: #dce775;
}
.form-group-material-lime-400.is-focused.label-placeholder label.control-label {
  color: #d4e157;
}
.form-group-material-lime-500.is-focused.label-placeholder label.control-label {
  color: #cddc39;
}
.form-group-material-lime-600.is-focused.label-placeholder label.control-label {
  color: #c0ca33;
}
.form-group-material-lime-700.is-focused.label-placeholder label.control-label {
  color: #afb42b;
}
.form-group-material-lime-800.is-focused.label-placeholder label.control-label {
  color: #9e9d24;
}
.form-group-material-lime-900.is-focused.label-placeholder label.control-label {
  color: #827717;
}
.form-group-material-lime-A100.is-focused.label-placeholder label.control-label {
  color: #f4ff81;
}
.form-group-material-lime-A200.is-focused.label-placeholder label.control-label {
  color: #eeff41;
}
.form-group-material-lime-A400.is-focused.label-placeholder label.control-label {
  color: #c6ff00;
}
.form-group-material-lime-A700.is-focused.label-placeholder label.control-label {
  color: #aeea00;
}
.form-group-material-yellow.is-focused.label-placeholder label.control-label {
  color: #ffeb3b;
}
.form-group-material-yellow-50.is-focused.label-placeholder label.control-label {
  color: #fffde7;
}
.form-group-material-yellow-100.is-focused.label-placeholder label.control-label {
  color: #fff9c4;
}
.form-group-material-yellow-200.is-focused.label-placeholder label.control-label {
  color: #fff59d;
}
.form-group-material-yellow-300.is-focused.label-placeholder label.control-label {
  color: #fff176;
}
.form-group-material-yellow-400.is-focused.label-placeholder label.control-label {
  color: #ffee58;
}
.form-group-material-yellow-500.is-focused.label-placeholder label.control-label {
  color: #ffeb3b;
}
.form-group-material-yellow-600.is-focused.label-placeholder label.control-label {
  color: #fdd835;
}
.form-group-material-yellow-700.is-focused.label-placeholder label.control-label {
  color: #fbc02d;
}
.form-group-material-yellow-800.is-focused.label-placeholder label.control-label {
  color: #f9a825;
}
.form-group-material-yellow-900.is-focused.label-placeholder label.control-label {
  color: #f57f17;
}
.form-group-material-yellow-A100.is-focused.label-placeholder label.control-label {
  color: #ffff8d;
}
.form-group-material-yellow-A200.is-focused.label-placeholder label.control-label {
  color: #ffff00;
}
.form-group-material-yellow-A400.is-focused.label-placeholder label.control-label {
  color: #ffea00;
}
.form-group-material-yellow-A700.is-focused.label-placeholder label.control-label {
  color: #ffd600;
}
.form-group-material-amber.is-focused.label-placeholder label.control-label {
  color: #ffc107;
}
.form-group-material-amber-50.is-focused.label-placeholder label.control-label {
  color: #fff8e1;
}
.form-group-material-amber-100.is-focused.label-placeholder label.control-label {
  color: #ffecb3;
}
.form-group-material-amber-200.is-focused.label-placeholder label.control-label {
  color: #ffe082;
}
.form-group-material-amber-300.is-focused.label-placeholder label.control-label {
  color: #ffd54f;
}
.form-group-material-amber-400.is-focused.label-placeholder label.control-label {
  color: #ffca28;
}
.form-group-material-amber-500.is-focused.label-placeholder label.control-label {
  color: #ffc107;
}
.form-group-material-amber-600.is-focused.label-placeholder label.control-label {
  color: #ffb300;
}
.form-group-material-amber-700.is-focused.label-placeholder label.control-label {
  color: #ffa000;
}
.form-group-material-amber-800.is-focused.label-placeholder label.control-label {
  color: #ff8f00;
}
.form-group-material-amber-900.is-focused.label-placeholder label.control-label {
  color: #ff6f00;
}
.form-group-material-amber-A100.is-focused.label-placeholder label.control-label {
  color: #ffe57f;
}
.form-group-material-amber-A200.is-focused.label-placeholder label.control-label {
  color: #ffd740;
}
.form-group-material-amber-A400.is-focused.label-placeholder label.control-label {
  color: #ffc400;
}
.form-group-material-amber-A700.is-focused.label-placeholder label.control-label {
  color: #ffab00;
}
.form-group-material-orange.is-focused.label-placeholder label.control-label {
  color: #ff9800;
}
.form-group-material-orange-50.is-focused.label-placeholder label.control-label {
  color: #fff3e0;
}
.form-group-material-orange-100.is-focused.label-placeholder label.control-label {
  color: #ffe0b2;
}
.form-group-material-orange-200.is-focused.label-placeholder label.control-label {
  color: #ffcc80;
}
.form-group-material-orange-300.is-focused.label-placeholder label.control-label {
  color: #ffb74d;
}
.form-group-material-orange-400.is-focused.label-placeholder label.control-label {
  color: #ffa726;
}
.form-group-material-orange-500.is-focused.label-placeholder label.control-label {
  color: #ff9800;
}
.form-group-material-orange-600.is-focused.label-placeholder label.control-label {
  color: #fb8c00;
}
.form-group-material-orange-700.is-focused.label-placeholder label.control-label {
  color: #f57c00;
}
.form-group-material-orange-800.is-focused.label-placeholder label.control-label {
  color: #ef6c00;
}
.form-group-material-orange-900.is-focused.label-placeholder label.control-label {
  color: #e65100;
}
.form-group-material-orange-A100.is-focused.label-placeholder label.control-label {
  color: #ffd180;
}
.form-group-material-orange-A200.is-focused.label-placeholder label.control-label {
  color: #ffab40;
}
.form-group-material-orange-A400.is-focused.label-placeholder label.control-label {
  color: #ff9100;
}
.form-group-material-orange-A700.is-focused.label-placeholder label.control-label {
  color: #ff6d00;
}
.form-group-material-deep-orange.is-focused.label-placeholder label.control-label {
  color: #ff5722;
}
.form-group-material-deep-orange-50.is-focused.label-placeholder label.control-label {
  color: #fbe9e7;
}
.form-group-material-deep-orange-100.is-focused.label-placeholder label.control-label {
  color: #ffccbc;
}
.form-group-material-deep-orange-200.is-focused.label-placeholder label.control-label {
  color: #ffab91;
}
.form-group-material-deep-orange-300.is-focused.label-placeholder label.control-label {
  color: #ff8a65;
}
.form-group-material-deep-orange-400.is-focused.label-placeholder label.control-label {
  color: #ff7043;
}
.form-group-material-deep-orange-500.is-focused.label-placeholder label.control-label {
  color: #ff5722;
}
.form-group-material-deep-orange-600.is-focused.label-placeholder label.control-label {
  color: #f4511e;
}
.form-group-material-deep-orange-700.is-focused.label-placeholder label.control-label {
  color: #e64a19;
}
.form-group-material-deep-orange-800.is-focused.label-placeholder label.control-label {
  color: #d84315;
}
.form-group-material-deep-orange-900.is-focused.label-placeholder label.control-label {
  color: #bf360c;
}
.form-group-material-deep-orange-A100.is-focused.label-placeholder label.control-label {
  color: #ff9e80;
}
.form-group-material-deep-orange-A200.is-focused.label-placeholder label.control-label {
  color: #ff6e40;
}
.form-group-material-deep-orange-A400.is-focused.label-placeholder label.control-label {
  color: #ff3d00;
}
.form-group-material-deep-orange-A700.is-focused.label-placeholder label.control-label {
  color: #dd2c00;
}
.form-group-material-brown.is-focused.label-placeholder label.control-label {
  color: #795548;
}
.form-group-material-brown-50.is-focused.label-placeholder label.control-label {
  color: #efebe9;
}
.form-group-material-brown-100.is-focused.label-placeholder label.control-label {
  color: #d7ccc8;
}
.form-group-material-brown-200.is-focused.label-placeholder label.control-label {
  color: #bcaaa4;
}
.form-group-material-brown-300.is-focused.label-placeholder label.control-label {
  color: #a1887f;
}
.form-group-material-brown-400.is-focused.label-placeholder label.control-label {
  color: #8d6e63;
}
.form-group-material-brown-500.is-focused.label-placeholder label.control-label {
  color: #795548;
}
.form-group-material-brown-600.is-focused.label-placeholder label.control-label {
  color: #6d4c41;
}
.form-group-material-brown-700.is-focused.label-placeholder label.control-label {
  color: #5d4037;
}
.form-group-material-brown-800.is-focused.label-placeholder label.control-label {
  color: #4e342e;
}
.form-group-material-brown-900.is-focused.label-placeholder label.control-label {
  color: #3e2723;
}
.form-group-material-brown-A100.is-focused.label-placeholder label.control-label {
  color: #d7ccc8;
}
.form-group-material-brown-A200.is-focused.label-placeholder label.control-label {
  color: #bcaaa4;
}
.form-group-material-brown-A400.is-focused.label-placeholder label.control-label {
  color: #8d6e63;
}
.form-group-material-brown-A700.is-focused.label-placeholder label.control-label {
  color: #5d4037;
}
.form-group-material-grey.is-focused.label-placeholder label.control-label {
  color: #9e9e9e;
}
.form-group-material-grey-50.is-focused.label-placeholder label.control-label {
  color: #fafafa;
}
.form-group-material-grey-100.is-focused.label-placeholder label.control-label {
  color: #f5f5f5;
}
.form-group-material-grey-200.is-focused.label-placeholder label.control-label {
  color: #eeeeee;
}
.form-group-material-grey-300.is-focused.label-placeholder label.control-label {
  color: #e0e0e0;
}
.form-group-material-grey-400.is-focused.label-placeholder label.control-label {
  color: #bdbdbd;
}
.form-group-material-grey-500.is-focused.label-placeholder label.control-label {
  color: #9e9e9e;
}
.form-group-material-grey-600.is-focused.label-placeholder label.control-label {
  color: #757575;
}
.form-group-material-grey-700.is-focused.label-placeholder label.control-label {
  color: #616161;
}
.form-group-material-grey-800.is-focused.label-placeholder label.control-label {
  color: #424242;
}
.form-group-material-grey-900.is-focused.label-placeholder label.control-label {
  color: #212121;
}
.form-group-material-grey-A100.is-focused.label-placeholder label.control-label {
  color: #f5f5f5;
}
.form-group-material-grey-A200.is-focused.label-placeholder label.control-label {
  color: #eeeeee;
}
.form-group-material-grey-A400.is-focused.label-placeholder label.control-label {
  color: #bdbdbd;
}
.form-group-material-grey-A700.is-focused.label-placeholder label.control-label {
  color: #616161;
}
.form-group-material-blue-grey.is-focused.label-placeholder label.control-label {
  color: #607d8b;
}
.form-group-material-blue-grey-50.is-focused.label-placeholder label.control-label {
  color: #eceff1;
}
.form-group-material-blue-grey-100.is-focused.label-placeholder label.control-label {
  color: #cfd8dc;
}
.form-group-material-blue-grey-200.is-focused.label-placeholder label.control-label {
  color: #b0bec5;
}
.form-group-material-blue-grey-300.is-focused.label-placeholder label.control-label {
  color: #90a4ae;
}
.form-group-material-blue-grey-400.is-focused.label-placeholder label.control-label {
  color: #78909c;
}
.form-group-material-blue-grey-500.is-focused.label-placeholder label.control-label {
  color: #607d8b;
}
.form-group-material-blue-grey-600.is-focused.label-placeholder label.control-label {
  color: #546e7a;
}
.form-group-material-blue-grey-700.is-focused.label-placeholder label.control-label {
  color: #455a64;
}
.form-group-material-blue-grey-800.is-focused.label-placeholder label.control-label {
  color: #37474f;
}
.form-group-material-blue-grey-900.is-focused.label-placeholder label.control-label {
  color: #263238;
}
.form-group-material-blue-grey-A100.is-focused.label-placeholder label.control-label {
  color: #cfd8dc;
}
.form-group-material-blue-grey-A200.is-focused.label-placeholder label.control-label {
  color: #b0bec5;
}
.form-group-material-blue-grey-A400.is-focused.label-placeholder label.control-label {
  color: #78909c;
}
.form-group-material-blue-grey-A700.is-focused.label-placeholder label.control-label {
  color: #455a64;
}
.form-group .form-control {
  margin-bottom: 7px;
}
.form-group .form-control::-moz-placeholder {
  font-size: 16px;
}
.form-group .form-control:-ms-input-placeholder {
  font-size: 16px;
}
.form-group .form-control::-webkit-input-placeholder {
  font-size: 16px;
}
.form-group .help-block {
  margin-top: 0px;
  font-size: 12px;
}
.form-group label.control-label {
  font-size: 12px;
  line-height: 1.07142857;
}
.form-group.label-floating label.control-label,
.form-group.label-placeholder label.control-label {
  top: -7px;
  font-size: 16px;
  line-height: 1.42857143;
}
.form-group.label-static label.control-label,
.form-group.label-floating.is-focused label.control-label,
.form-group.label-floating:not(.is-empty) label.control-label {
  top: -30px;
  left: 0;
  font-size: 12px;
  line-height: 1.07142857;
}
.form-group.form-group-sm {
  margin: 21px 0 0 0;
  padding-bottom: 3px;
}
.form-group.form-group-sm .form-control {
  margin-bottom: 3px;
}
.form-group.form-group-sm .form-control::-moz-placeholder {
  font-size: 11px;
}
.form-group.form-group-sm .form-control:-ms-input-placeholder {
  font-size: 11px;
}
.form-group.form-group-sm .form-control::-webkit-input-placeholder {
  font-size: 11px;
}
.form-group.form-group-sm .help-block {
  margin-top: 0px;
  font-size: 9px;
}
.form-group.form-group-sm label.control-label {
  font-size: 9px;
  line-height: 1.125;
}
.form-group.form-group-sm.label-floating label.control-label,
.form-group.form-group-sm.label-placeholder label.control-label {
  top: -11px;
  font-size: 11px;
  line-height: 1.5;
}
.form-group.form-group-sm.label-static label.control-label,
.form-group.form-group-sm.label-floating.is-focused label.control-label,
.form-group.form-group-sm.label-floating:not(.is-empty) label.control-label {
  top: -25px;
  left: 0;
  font-size: 9px;
  line-height: 1.125;
}
.form-group.form-group-lg {
  margin: 30px 0 0 0;
  padding-bottom: 9px;
}
.form-group.form-group-lg .form-control {
  margin-bottom: 9px;
}
.form-group.form-group-lg .form-control::-moz-placeholder {
  font-size: 18px;
}
.form-group.form-group-lg .form-control:-ms-input-placeholder {
  font-size: 18px;
}
.form-group.form-group-lg .form-control::-webkit-input-placeholder {
  font-size: 18px;
}
.form-group.form-group-lg .help-block {
  margin-top: 0px;
  font-size: 14px;
}
.form-group.form-group-lg label.control-label {
  font-size: 14px;
  line-height: 0.99999998;
}
.form-group.form-group-lg.label-floating label.control-label,
.form-group.form-group-lg.label-placeholder label.control-label {
  top: -5px;
  font-size: 18px;
  line-height: 1.3333333;
}
.form-group.form-group-lg.label-static label.control-label,
.form-group.form-group-lg.label-floating.is-focused label.control-label,
.form-group.form-group-lg.label-floating:not(.is-empty) label.control-label {
  top: -32px;
  left: 0;
  font-size: 14px;
  line-height: 0.99999998;
}
.form-group .help-block {
  position: absolute;
  display: none;
}
.form-group.is-focused .help-block {
  display: block;
}
.form-group.is-focused .form-control,
.form-group-default.is-focused .form-control {
  background-image: linear-gradient(#009688, #009688), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-black.is-focused .form-control {
  background-image: linear-gradient(#000000, #000000), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-white.is-focused .form-control {
  background-image: linear-gradient(#ffffff, #ffffff), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-inverse.is-focused .form-control {
  background-image: linear-gradient(#3f51b5, #3f51b5), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-primary.is-focused .form-control {
  background-image: linear-gradient(#009688, #009688), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-success.is-focused .form-control {
  background-image: linear-gradient(#4caf50, #4caf50), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-info.is-focused .form-control {
  background-image: linear-gradient(#03a9f4, #03a9f4), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-warning.is-focused .form-control {
  background-image: linear-gradient(#ff5722, #ff5722), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-danger.is-focused .form-control {
  background-image: linear-gradient(#f44336, #f44336), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-red.is-focused .form-control {
  background-image: linear-gradient(#f44336, #f44336), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-red-50.is-focused .form-control {
  background-image: linear-gradient(#ffebee, #ffebee), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-red-100.is-focused .form-control {
  background-image: linear-gradient(#ffcdd2, #ffcdd2), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-red-200.is-focused .form-control {
  background-image: linear-gradient(#ef9a9a, #ef9a9a), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-red-300.is-focused .form-control {
  background-image: linear-gradient(#e57373, #e57373), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-red-400.is-focused .form-control {
  background-image: linear-gradient(#ef5350, #ef5350), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-red-500.is-focused .form-control {
  background-image: linear-gradient(#f44336, #f44336), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-red-600.is-focused .form-control {
  background-image: linear-gradient(#e53935, #e53935), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-red-700.is-focused .form-control {
  background-image: linear-gradient(#d32f2f, #d32f2f), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-red-800.is-focused .form-control {
  background-image: linear-gradient(#c62828, #c62828), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-red-900.is-focused .form-control {
  background-image: linear-gradient(#b71c1c, #b71c1c), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-red-A100.is-focused .form-control {
  background-image: linear-gradient(#ff8a80, #ff8a80), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-red-A200.is-focused .form-control {
  background-image: linear-gradient(#ff5252, #ff5252), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-red-A400.is-focused .form-control {
  background-image: linear-gradient(#ff1744, #ff1744), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-red-A700.is-focused .form-control {
  background-image: linear-gradient(#d50000, #d50000), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-pink.is-focused .form-control {
  background-image: linear-gradient(#e91e63, #e91e63), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-pink-50.is-focused .form-control {
  background-image: linear-gradient(#fce4ec, #fce4ec), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-pink-100.is-focused .form-control {
  background-image: linear-gradient(#f8bbd0, #f8bbd0), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-pink-200.is-focused .form-control {
  background-image: linear-gradient(#f48fb1, #f48fb1), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-pink-300.is-focused .form-control {
  background-image: linear-gradient(#f06292, #f06292), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-pink-400.is-focused .form-control {
  background-image: linear-gradient(#ec407a, #ec407a), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-pink-500.is-focused .form-control {
  background-image: linear-gradient(#e91e63, #e91e63), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-pink-600.is-focused .form-control {
  background-image: linear-gradient(#d81b60, #d81b60), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-pink-700.is-focused .form-control {
  background-image: linear-gradient(#c2185b, #c2185b), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-pink-800.is-focused .form-control {
  background-image: linear-gradient(#ad1457, #ad1457), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-pink-900.is-focused .form-control {
  background-image: linear-gradient(#880e4f, #880e4f), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-pink-A100.is-focused .form-control {
  background-image: linear-gradient(#ff80ab, #ff80ab), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-pink-A200.is-focused .form-control {
  background-image: linear-gradient(#ff4081, #ff4081), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-pink-A400.is-focused .form-control {
  background-image: linear-gradient(#f50057, #f50057), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-pink-A700.is-focused .form-control {
  background-image: linear-gradient(#c51162, #c51162), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-purple.is-focused .form-control {
  background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-purple-50.is-focused .form-control {
  background-image: linear-gradient(#f3e5f5, #f3e5f5), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-purple-100.is-focused .form-control {
  background-image: linear-gradient(#e1bee7, #e1bee7), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-purple-200.is-focused .form-control {
  background-image: linear-gradient(#ce93d8, #ce93d8), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-purple-300.is-focused .form-control {
  background-image: linear-gradient(#ba68c8, #ba68c8), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-purple-400.is-focused .form-control {
  background-image: linear-gradient(#ab47bc, #ab47bc), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-purple-500.is-focused .form-control {
  background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-purple-600.is-focused .form-control {
  background-image: linear-gradient(#8e24aa, #8e24aa), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-purple-700.is-focused .form-control {
  background-image: linear-gradient(#7b1fa2, #7b1fa2), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-purple-800.is-focused .form-control {
  background-image: linear-gradient(#6a1b9a, #6a1b9a), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-purple-900.is-focused .form-control {
  background-image: linear-gradient(#4a148c, #4a148c), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-purple-A100.is-focused .form-control {
  background-image: linear-gradient(#ea80fc, #ea80fc), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-purple-A200.is-focused .form-control {
  background-image: linear-gradient(#e040fb, #e040fb), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-purple-A400.is-focused .form-control {
  background-image: linear-gradient(#d500f9, #d500f9), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-purple-A700.is-focused .form-control {
  background-image: linear-gradient(#aa00ff, #aa00ff), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-deep-purple.is-focused .form-control {
  background-image: linear-gradient(#673ab7, #673ab7), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-deep-purple-50.is-focused .form-control {
  background-image: linear-gradient(#ede7f6, #ede7f6), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-deep-purple-100.is-focused .form-control {
  background-image: linear-gradient(#d1c4e9, #d1c4e9), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-deep-purple-200.is-focused .form-control {
  background-image: linear-gradient(#b39ddb, #b39ddb), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-deep-purple-300.is-focused .form-control {
  background-image: linear-gradient(#9575cd, #9575cd), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-deep-purple-400.is-focused .form-control {
  background-image: linear-gradient(#7e57c2, #7e57c2), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-deep-purple-500.is-focused .form-control {
  background-image: linear-gradient(#673ab7, #673ab7), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-deep-purple-600.is-focused .form-control {
  background-image: linear-gradient(#5e35b1, #5e35b1), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-deep-purple-700.is-focused .form-control {
  background-image: linear-gradient(#512da8, #512da8), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-deep-purple-800.is-focused .form-control {
  background-image: linear-gradient(#4527a0, #4527a0), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-deep-purple-900.is-focused .form-control {
  background-image: linear-gradient(#311b92, #311b92), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-deep-purple-A100.is-focused .form-control {
  background-image: linear-gradient(#b388ff, #b388ff), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-deep-purple-A200.is-focused .form-control {
  background-image: linear-gradient(#7c4dff, #7c4dff), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-deep-purple-A400.is-focused .form-control {
  background-image: linear-gradient(#651fff, #651fff), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-deep-purple-A700.is-focused .form-control {
  background-image: linear-gradient(#6200ea, #6200ea), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-indigo.is-focused .form-control {
  background-image: linear-gradient(#3f51b5, #3f51b5), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-indigo-50.is-focused .form-control {
  background-image: linear-gradient(#e8eaf6, #e8eaf6), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-indigo-100.is-focused .form-control {
  background-image: linear-gradient(#c5cae9, #c5cae9), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-indigo-200.is-focused .form-control {
  background-image: linear-gradient(#9fa8da, #9fa8da), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-indigo-300.is-focused .form-control {
  background-image: linear-gradient(#7986cb, #7986cb), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-indigo-400.is-focused .form-control {
  background-image: linear-gradient(#5c6bc0, #5c6bc0), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-indigo-500.is-focused .form-control {
  background-image: linear-gradient(#3f51b5, #3f51b5), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-indigo-600.is-focused .form-control {
  background-image: linear-gradient(#3949ab, #3949ab), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-indigo-700.is-focused .form-control {
  background-image: linear-gradient(#303f9f, #303f9f), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-indigo-800.is-focused .form-control {
  background-image: linear-gradient(#283593, #283593), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-indigo-900.is-focused .form-control {
  background-image: linear-gradient(#1a237e, #1a237e), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-indigo-A100.is-focused .form-control {
  background-image: linear-gradient(#8c9eff, #8c9eff), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-indigo-A200.is-focused .form-control {
  background-image: linear-gradient(#536dfe, #536dfe), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-indigo-A400.is-focused .form-control {
  background-image: linear-gradient(#3d5afe, #3d5afe), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-indigo-A700.is-focused .form-control {
  background-image: linear-gradient(#304ffe, #304ffe), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-blue.is-focused .form-control {
  background-image: linear-gradient(#2196f3, #2196f3), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-blue-50.is-focused .form-control {
  background-image: linear-gradient(#e3f2fd, #e3f2fd), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-blue-100.is-focused .form-control {
  background-image: linear-gradient(#bbdefb, #bbdefb), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-blue-200.is-focused .form-control {
  background-image: linear-gradient(#90caf9, #90caf9), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-blue-300.is-focused .form-control {
  background-image: linear-gradient(#64b5f6, #64b5f6), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-blue-400.is-focused .form-control {
  background-image: linear-gradient(#42a5f5, #42a5f5), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-blue-500.is-focused .form-control {
  background-image: linear-gradient(#2196f3, #2196f3), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-blue-600.is-focused .form-control {
  background-image: linear-gradient(#1e88e5, #1e88e5), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-blue-700.is-focused .form-control {
  background-image: linear-gradient(#1976d2, #1976d2), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-blue-800.is-focused .form-control {
  background-image: linear-gradient(#1565c0, #1565c0), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-blue-900.is-focused .form-control {
  background-image: linear-gradient(#0d47a1, #0d47a1), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-blue-A100.is-focused .form-control {
  background-image: linear-gradient(#82b1ff, #82b1ff), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-blue-A200.is-focused .form-control {
  background-image: linear-gradient(#448aff, #448aff), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-blue-A400.is-focused .form-control {
  background-image: linear-gradient(#2979ff, #2979ff), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-blue-A700.is-focused .form-control {
  background-image: linear-gradient(#2962ff, #2962ff), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-light-blue.is-focused .form-control {
  background-image: linear-gradient(#03a9f4, #03a9f4), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-light-blue-50.is-focused .form-control {
  background-image: linear-gradient(#e1f5fe, #e1f5fe), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-light-blue-100.is-focused .form-control {
  background-image: linear-gradient(#b3e5fc, #b3e5fc), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-light-blue-200.is-focused .form-control {
  background-image: linear-gradient(#81d4fa, #81d4fa), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-light-blue-300.is-focused .form-control {
  background-image: linear-gradient(#4fc3f7, #4fc3f7), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-light-blue-400.is-focused .form-control {
  background-image: linear-gradient(#29b6f6, #29b6f6), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-light-blue-500.is-focused .form-control {
  background-image: linear-gradient(#03a9f4, #03a9f4), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-light-blue-600.is-focused .form-control {
  background-image: linear-gradient(#039be5, #039be5), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-light-blue-700.is-focused .form-control {
  background-image: linear-gradient(#0288d1, #0288d1), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-light-blue-800.is-focused .form-control {
  background-image: linear-gradient(#0277bd, #0277bd), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-light-blue-900.is-focused .form-control {
  background-image: linear-gradient(#01579b, #01579b), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-light-blue-A100.is-focused .form-control {
  background-image: linear-gradient(#80d8ff, #80d8ff), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-light-blue-A200.is-focused .form-control {
  background-image: linear-gradient(#40c4ff, #40c4ff), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-light-blue-A400.is-focused .form-control {
  background-image: linear-gradient(#00b0ff, #00b0ff), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-light-blue-A700.is-focused .form-control {
  background-image: linear-gradient(#0091ea, #0091ea), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-cyan.is-focused .form-control {
  background-image: linear-gradient(#00bcd4, #00bcd4), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-cyan-50.is-focused .form-control {
  background-image: linear-gradient(#e0f7fa, #e0f7fa), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-cyan-100.is-focused .form-control {
  background-image: linear-gradient(#b2ebf2, #b2ebf2), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-cyan-200.is-focused .form-control {
  background-image: linear-gradient(#80deea, #80deea), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-cyan-300.is-focused .form-control {
  background-image: linear-gradient(#4dd0e1, #4dd0e1), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-cyan-400.is-focused .form-control {
  background-image: linear-gradient(#26c6da, #26c6da), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-cyan-500.is-focused .form-control {
  background-image: linear-gradient(#00bcd4, #00bcd4), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-cyan-600.is-focused .form-control {
  background-image: linear-gradient(#00acc1, #00acc1), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-cyan-700.is-focused .form-control {
  background-image: linear-gradient(#0097a7, #0097a7), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-cyan-800.is-focused .form-control {
  background-image: linear-gradient(#00838f, #00838f), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-cyan-900.is-focused .form-control {
  background-image: linear-gradient(#006064, #006064), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-cyan-A100.is-focused .form-control {
  background-image: linear-gradient(#84ffff, #84ffff), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-cyan-A200.is-focused .form-control {
  background-image: linear-gradient(#18ffff, #18ffff), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-cyan-A400.is-focused .form-control {
  background-image: linear-gradient(#00e5ff, #00e5ff), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-cyan-A700.is-focused .form-control {
  background-image: linear-gradient(#00b8d4, #00b8d4), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-teal.is-focused .form-control {
  background-image: linear-gradient(#009688, #009688), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-teal-50.is-focused .form-control {
  background-image: linear-gradient(#e0f2f1, #e0f2f1), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-teal-100.is-focused .form-control {
  background-image: linear-gradient(#b2dfdb, #b2dfdb), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-teal-200.is-focused .form-control {
  background-image: linear-gradient(#80cbc4, #80cbc4), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-teal-300.is-focused .form-control {
  background-image: linear-gradient(#4db6ac, #4db6ac), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-teal-400.is-focused .form-control {
  background-image: linear-gradient(#26a69a, #26a69a), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-teal-500.is-focused .form-control {
  background-image: linear-gradient(#009688, #009688), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-teal-600.is-focused .form-control {
  background-image: linear-gradient(#00897b, #00897b), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-teal-700.is-focused .form-control {
  background-image: linear-gradient(#00796b, #00796b), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-teal-800.is-focused .form-control {
  background-image: linear-gradient(#00695c, #00695c), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-teal-900.is-focused .form-control {
  background-image: linear-gradient(#004d40, #004d40), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-teal-A100.is-focused .form-control {
  background-image: linear-gradient(#a7ffeb, #a7ffeb), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-teal-A200.is-focused .form-control {
  background-image: linear-gradient(#64ffda, #64ffda), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-teal-A400.is-focused .form-control {
  background-image: linear-gradient(#1de9b6, #1de9b6), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-teal-A700.is-focused .form-control {
  background-image: linear-gradient(#00bfa5, #00bfa5), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-green.is-focused .form-control {
  background-image: linear-gradient(#4caf50, #4caf50), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-green-50.is-focused .form-control {
  background-image: linear-gradient(#e8f5e9, #e8f5e9), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-green-100.is-focused .form-control {
  background-image: linear-gradient(#c8e6c9, #c8e6c9), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-green-200.is-focused .form-control {
  background-image: linear-gradient(#a5d6a7, #a5d6a7), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-green-300.is-focused .form-control {
  background-image: linear-gradient(#81c784, #81c784), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-green-400.is-focused .form-control {
  background-image: linear-gradient(#66bb6a, #66bb6a), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-green-500.is-focused .form-control {
  background-image: linear-gradient(#4caf50, #4caf50), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-green-600.is-focused .form-control {
  background-image: linear-gradient(#43a047, #43a047), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-green-700.is-focused .form-control {
  background-image: linear-gradient(#388e3c, #388e3c), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-green-800.is-focused .form-control {
  background-image: linear-gradient(#2e7d32, #2e7d32), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-green-900.is-focused .form-control {
  background-image: linear-gradient(#1b5e20, #1b5e20), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-green-A100.is-focused .form-control {
  background-image: linear-gradient(#b9f6ca, #b9f6ca), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-green-A200.is-focused .form-control {
  background-image: linear-gradient(#69f0ae, #69f0ae), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-green-A400.is-focused .form-control {
  background-image: linear-gradient(#00e676, #00e676), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-green-A700.is-focused .form-control {
  background-image: linear-gradient(#00c853, #00c853), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-light-green.is-focused .form-control {
  background-image: linear-gradient(#8bc34a, #8bc34a), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-light-green-50.is-focused .form-control {
  background-image: linear-gradient(#f1f8e9, #f1f8e9), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-light-green-100.is-focused .form-control {
  background-image: linear-gradient(#dcedc8, #dcedc8), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-light-green-200.is-focused .form-control {
  background-image: linear-gradient(#c5e1a5, #c5e1a5), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-light-green-300.is-focused .form-control {
  background-image: linear-gradient(#aed581, #aed581), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-light-green-400.is-focused .form-control {
  background-image: linear-gradient(#9ccc65, #9ccc65), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-light-green-500.is-focused .form-control {
  background-image: linear-gradient(#8bc34a, #8bc34a), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-light-green-600.is-focused .form-control {
  background-image: linear-gradient(#7cb342, #7cb342), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-light-green-700.is-focused .form-control {
  background-image: linear-gradient(#689f38, #689f38), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-light-green-800.is-focused .form-control {
  background-image: linear-gradient(#558b2f, #558b2f), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-light-green-900.is-focused .form-control {
  background-image: linear-gradient(#33691e, #33691e), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-light-green-A100.is-focused .form-control {
  background-image: linear-gradient(#ccff90, #ccff90), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-light-green-A200.is-focused .form-control {
  background-image: linear-gradient(#b2ff59, #b2ff59), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-light-green-A400.is-focused .form-control {
  background-image: linear-gradient(#76ff03, #76ff03), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-light-green-A700.is-focused .form-control {
  background-image: linear-gradient(#64dd17, #64dd17), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-lime.is-focused .form-control {
  background-image: linear-gradient(#cddc39, #cddc39), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-lime-50.is-focused .form-control {
  background-image: linear-gradient(#f9fbe7, #f9fbe7), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-lime-100.is-focused .form-control {
  background-image: linear-gradient(#f0f4c3, #f0f4c3), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-lime-200.is-focused .form-control {
  background-image: linear-gradient(#e6ee9c, #e6ee9c), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-lime-300.is-focused .form-control {
  background-image: linear-gradient(#dce775, #dce775), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-lime-400.is-focused .form-control {
  background-image: linear-gradient(#d4e157, #d4e157), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-lime-500.is-focused .form-control {
  background-image: linear-gradient(#cddc39, #cddc39), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-lime-600.is-focused .form-control {
  background-image: linear-gradient(#c0ca33, #c0ca33), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-lime-700.is-focused .form-control {
  background-image: linear-gradient(#afb42b, #afb42b), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-lime-800.is-focused .form-control {
  background-image: linear-gradient(#9e9d24, #9e9d24), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-lime-900.is-focused .form-control {
  background-image: linear-gradient(#827717, #827717), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-lime-A100.is-focused .form-control {
  background-image: linear-gradient(#f4ff81, #f4ff81), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-lime-A200.is-focused .form-control {
  background-image: linear-gradient(#eeff41, #eeff41), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-lime-A400.is-focused .form-control {
  background-image: linear-gradient(#c6ff00, #c6ff00), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-lime-A700.is-focused .form-control {
  background-image: linear-gradient(#aeea00, #aeea00), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-yellow.is-focused .form-control {
  background-image: linear-gradient(#ffeb3b, #ffeb3b), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-yellow-50.is-focused .form-control {
  background-image: linear-gradient(#fffde7, #fffde7), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-yellow-100.is-focused .form-control {
  background-image: linear-gradient(#fff9c4, #fff9c4), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-yellow-200.is-focused .form-control {
  background-image: linear-gradient(#fff59d, #fff59d), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-yellow-300.is-focused .form-control {
  background-image: linear-gradient(#fff176, #fff176), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-yellow-400.is-focused .form-control {
  background-image: linear-gradient(#ffee58, #ffee58), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-yellow-500.is-focused .form-control {
  background-image: linear-gradient(#ffeb3b, #ffeb3b), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-yellow-600.is-focused .form-control {
  background-image: linear-gradient(#fdd835, #fdd835), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-yellow-700.is-focused .form-control {
  background-image: linear-gradient(#fbc02d, #fbc02d), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-yellow-800.is-focused .form-control {
  background-image: linear-gradient(#f9a825, #f9a825), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-yellow-900.is-focused .form-control {
  background-image: linear-gradient(#f57f17, #f57f17), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-yellow-A100.is-focused .form-control {
  background-image: linear-gradient(#ffff8d, #ffff8d), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-yellow-A200.is-focused .form-control {
  background-image: linear-gradient(#ffff00, #ffff00), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-yellow-A400.is-focused .form-control {
  background-image: linear-gradient(#ffea00, #ffea00), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-yellow-A700.is-focused .form-control {
  background-image: linear-gradient(#ffd600, #ffd600), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-amber.is-focused .form-control {
  background-image: linear-gradient(#ffc107, #ffc107), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-amber-50.is-focused .form-control {
  background-image: linear-gradient(#fff8e1, #fff8e1), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-amber-100.is-focused .form-control {
  background-image: linear-gradient(#ffecb3, #ffecb3), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-amber-200.is-focused .form-control {
  background-image: linear-gradient(#ffe082, #ffe082), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-amber-300.is-focused .form-control {
  background-image: linear-gradient(#ffd54f, #ffd54f), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-amber-400.is-focused .form-control {
  background-image: linear-gradient(#ffca28, #ffca28), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-amber-500.is-focused .form-control {
  background-image: linear-gradient(#ffc107, #ffc107), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-amber-600.is-focused .form-control {
  background-image: linear-gradient(#ffb300, #ffb300), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-amber-700.is-focused .form-control {
  background-image: linear-gradient(#ffa000, #ffa000), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-amber-800.is-focused .form-control {
  background-image: linear-gradient(#ff8f00, #ff8f00), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-amber-900.is-focused .form-control {
  background-image: linear-gradient(#ff6f00, #ff6f00), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-amber-A100.is-focused .form-control {
  background-image: linear-gradient(#ffe57f, #ffe57f), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-amber-A200.is-focused .form-control {
  background-image: linear-gradient(#ffd740, #ffd740), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-amber-A400.is-focused .form-control {
  background-image: linear-gradient(#ffc400, #ffc400), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-amber-A700.is-focused .form-control {
  background-image: linear-gradient(#ffab00, #ffab00), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-orange.is-focused .form-control {
  background-image: linear-gradient(#ff9800, #ff9800), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-orange-50.is-focused .form-control {
  background-image: linear-gradient(#fff3e0, #fff3e0), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-orange-100.is-focused .form-control {
  background-image: linear-gradient(#ffe0b2, #ffe0b2), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-orange-200.is-focused .form-control {
  background-image: linear-gradient(#ffcc80, #ffcc80), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-orange-300.is-focused .form-control {
  background-image: linear-gradient(#ffb74d, #ffb74d), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-orange-400.is-focused .form-control {
  background-image: linear-gradient(#ffa726, #ffa726), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-orange-500.is-focused .form-control {
  background-image: linear-gradient(#ff9800, #ff9800), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-orange-600.is-focused .form-control {
  background-image: linear-gradient(#fb8c00, #fb8c00), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-orange-700.is-focused .form-control {
  background-image: linear-gradient(#f57c00, #f57c00), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-orange-800.is-focused .form-control {
  background-image: linear-gradient(#ef6c00, #ef6c00), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-orange-900.is-focused .form-control {
  background-image: linear-gradient(#e65100, #e65100), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-orange-A100.is-focused .form-control {
  background-image: linear-gradient(#ffd180, #ffd180), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-orange-A200.is-focused .form-control {
  background-image: linear-gradient(#ffab40, #ffab40), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-orange-A400.is-focused .form-control {
  background-image: linear-gradient(#ff9100, #ff9100), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-orange-A700.is-focused .form-control {
  background-image: linear-gradient(#ff6d00, #ff6d00), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-deep-orange.is-focused .form-control {
  background-image: linear-gradient(#ff5722, #ff5722), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-deep-orange-50.is-focused .form-control {
  background-image: linear-gradient(#fbe9e7, #fbe9e7), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-deep-orange-100.is-focused .form-control {
  background-image: linear-gradient(#ffccbc, #ffccbc), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-deep-orange-200.is-focused .form-control {
  background-image: linear-gradient(#ffab91, #ffab91), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-deep-orange-300.is-focused .form-control {
  background-image: linear-gradient(#ff8a65, #ff8a65), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-deep-orange-400.is-focused .form-control {
  background-image: linear-gradient(#ff7043, #ff7043), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-deep-orange-500.is-focused .form-control {
  background-image: linear-gradient(#ff5722, #ff5722), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-deep-orange-600.is-focused .form-control {
  background-image: linear-gradient(#f4511e, #f4511e), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-deep-orange-700.is-focused .form-control {
  background-image: linear-gradient(#e64a19, #e64a19), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-deep-orange-800.is-focused .form-control {
  background-image: linear-gradient(#d84315, #d84315), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-deep-orange-900.is-focused .form-control {
  background-image: linear-gradient(#bf360c, #bf360c), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-deep-orange-A100.is-focused .form-control {
  background-image: linear-gradient(#ff9e80, #ff9e80), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-deep-orange-A200.is-focused .form-control {
  background-image: linear-gradient(#ff6e40, #ff6e40), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-deep-orange-A400.is-focused .form-control {
  background-image: linear-gradient(#ff3d00, #ff3d00), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-deep-orange-A700.is-focused .form-control {
  background-image: linear-gradient(#dd2c00, #dd2c00), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-brown.is-focused .form-control {
  background-image: linear-gradient(#795548, #795548), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-brown-50.is-focused .form-control {
  background-image: linear-gradient(#efebe9, #efebe9), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-brown-100.is-focused .form-control {
  background-image: linear-gradient(#d7ccc8, #d7ccc8), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-brown-200.is-focused .form-control {
  background-image: linear-gradient(#bcaaa4, #bcaaa4), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-brown-300.is-focused .form-control {
  background-image: linear-gradient(#a1887f, #a1887f), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-brown-400.is-focused .form-control {
  background-image: linear-gradient(#8d6e63, #8d6e63), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-brown-500.is-focused .form-control {
  background-image: linear-gradient(#795548, #795548), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-brown-600.is-focused .form-control {
  background-image: linear-gradient(#6d4c41, #6d4c41), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-brown-700.is-focused .form-control {
  background-image: linear-gradient(#5d4037, #5d4037), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-brown-800.is-focused .form-control {
  background-image: linear-gradient(#4e342e, #4e342e), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-brown-900.is-focused .form-control {
  background-image: linear-gradient(#3e2723, #3e2723), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-brown-A100.is-focused .form-control {
  background-image: linear-gradient(#d7ccc8, #d7ccc8), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-brown-A200.is-focused .form-control {
  background-image: linear-gradient(#bcaaa4, #bcaaa4), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-brown-A400.is-focused .form-control {
  background-image: linear-gradient(#8d6e63, #8d6e63), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-brown-A700.is-focused .form-control {
  background-image: linear-gradient(#5d4037, #5d4037), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-grey.is-focused .form-control {
  background-image: linear-gradient(#9e9e9e, #9e9e9e), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-grey-50.is-focused .form-control {
  background-image: linear-gradient(#fafafa, #fafafa), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-grey-100.is-focused .form-control {
  background-image: linear-gradient(#f5f5f5, #f5f5f5), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-grey-200.is-focused .form-control {
  background-image: linear-gradient(#eeeeee, #eeeeee), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-grey-300.is-focused .form-control {
  background-image: linear-gradient(#e0e0e0, #e0e0e0), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-grey-400.is-focused .form-control {
  background-image: linear-gradient(#bdbdbd, #bdbdbd), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-grey-500.is-focused .form-control {
  background-image: linear-gradient(#9e9e9e, #9e9e9e), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-grey-600.is-focused .form-control {
  background-image: linear-gradient(#757575, #757575), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-grey-700.is-focused .form-control {
  background-image: linear-gradient(#616161, #616161), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-grey-800.is-focused .form-control {
  background-image: linear-gradient(#424242, #424242), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-grey-900.is-focused .form-control {
  background-image: linear-gradient(#212121, #212121), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-grey-A100.is-focused .form-control {
  background-image: linear-gradient(#f5f5f5, #f5f5f5), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-grey-A200.is-focused .form-control {
  background-image: linear-gradient(#eeeeee, #eeeeee), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-grey-A400.is-focused .form-control {
  background-image: linear-gradient(#bdbdbd, #bdbdbd), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-grey-A700.is-focused .form-control {
  background-image: linear-gradient(#616161, #616161), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-blue-grey.is-focused .form-control {
  background-image: linear-gradient(#607d8b, #607d8b), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-blue-grey-50.is-focused .form-control {
  background-image: linear-gradient(#eceff1, #eceff1), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-blue-grey-100.is-focused .form-control {
  background-image: linear-gradient(#cfd8dc, #cfd8dc), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-blue-grey-200.is-focused .form-control {
  background-image: linear-gradient(#b0bec5, #b0bec5), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-blue-grey-300.is-focused .form-control {
  background-image: linear-gradient(#90a4ae, #90a4ae), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-blue-grey-400.is-focused .form-control {
  background-image: linear-gradient(#78909c, #78909c), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-blue-grey-500.is-focused .form-control {
  background-image: linear-gradient(#607d8b, #607d8b), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-blue-grey-600.is-focused .form-control {
  background-image: linear-gradient(#546e7a, #546e7a), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-blue-grey-700.is-focused .form-control {
  background-image: linear-gradient(#455a64, #455a64), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-blue-grey-800.is-focused .form-control {
  background-image: linear-gradient(#37474f, #37474f), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-blue-grey-900.is-focused .form-control {
  background-image: linear-gradient(#263238, #263238), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-blue-grey-A100.is-focused .form-control {
  background-image: linear-gradient(#cfd8dc, #cfd8dc), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-blue-grey-A200.is-focused .form-control {
  background-image: linear-gradient(#b0bec5, #b0bec5), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-blue-grey-A400.is-focused .form-control {
  background-image: linear-gradient(#78909c, #78909c), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group-material-blue-grey-A700.is-focused .form-control {
  background-image: linear-gradient(#455a64, #455a64), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group.has-warning .form-control {
  box-shadow: none;
}
.form-group.has-warning.is-focused .form-control {
  background-image: linear-gradient(#ff5722, #ff5722), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group.has-warning label.control-label,
.form-group.has-warning .help-block {
  color: #ff5722;
}
.form-group.has-error .form-control {
  box-shadow: none;
}
.form-group.has-error.is-focused .form-control {
  background-image: linear-gradient(#f44336, #f44336), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group.has-error label.control-label,
.form-group.has-error .help-block {
  color: #f44336;
}
.form-group.has-success .form-control {
  box-shadow: none;
}
.form-group.has-success.is-focused .form-control {
  background-image: linear-gradient(#4caf50, #4caf50), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group.has-success label.control-label,
.form-group.has-success .help-block {
  color: #4caf50;
}
.form-group.has-info .form-control {
  box-shadow: none;
}
.form-group.has-info.is-focused .form-control {
  background-image: linear-gradient(#03a9f4, #03a9f4), linear-gradient(#D2D2D2, #D2D2D2);
}
.form-group.has-info label.control-label,
.form-group.has-info .help-block {
  color: #03a9f4;
}
.form-group textarea {
  resize: none;
}
.form-group textarea ~ .form-control-highlight {
  margin-top: -11px;
}
.form-group select {
  appearance: none;
}
.form-group select ~ .material-input:after {
  display: none;
}
select.form-control {
  border: 0;
  box-shadow: none;
  border-radius: 0;
}
.form-group.is-focused select.form-control {
  box-shadow: none;
  border-color: #D2D2D2;
}
select.form-control[multiple],
.form-group.is-focused select.form-control[multiple] {
  height: 85px;
}
.input-group-btn .btn {
  margin: 0 0 7px 0;
}
.form-group.form-group-sm .input-group-btn .btn {
  margin: 0 0 3px 0;
}
.form-group.form-group-lg .input-group-btn .btn {
  margin: 0 0 9px 0;
}
.input-group .input-group-btn {
  padding: 0 12px;
}
.input-group .input-group-addon {
  border: 0;
  background: transparent;
}
.form-group input[type=file] {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
legend {
  border-bottom: 0;
}
.list-group {
  border-radius: 0;
}
.list-group .list-group-item {
  background-color: transparent;
  overflow: hidden;
  border: 0;
  border-radius: 0;
  padding: 0 16px;
}
.list-group .list-group-item.baseline {
  border-bottom: 1px solid #cecece;
}
.list-group .list-group-item.baseline:last-child {
  border-bottom: none;
}
.list-group .list-group-item .row-picture,
.list-group .list-group-item .row-action-primary {
  float: left;
  display: inline-block;
  padding-right: 16px;
}
.list-group .list-group-item .row-picture img,
.list-group .list-group-item .row-action-primary img,
.list-group .list-group-item .row-picture i,
.list-group .list-group-item .row-action-primary i,
.list-group .list-group-item .row-picture label,
.list-group .list-group-item .row-action-primary label {
  display: block;
  width: 56px;
  height: 56px;
}
.list-group .list-group-item .row-picture img,
.list-group .list-group-item .row-action-primary img {
  background: rgba(0, 0, 0, 0.1);
  padding: 1px;
}
.list-group .list-group-item .row-picture img.circle,
.list-group .list-group-item .row-action-primary img.circle {
  border-radius: 100%;
}
.list-group .list-group-item .row-picture i,
.list-group .list-group-item .row-action-primary i {
  background: rgba(0, 0, 0, 0.25);
  border-radius: 100%;
  text-align: center;
  line-height: 56px;
  font-size: 20px;
  color: white;
}
.list-group .list-group-item .row-picture label,
.list-group .list-group-item .row-action-primary label {
  margin-left: 7px;
  margin-right: -7px;
  margin-top: 5px;
  margin-bottom: -5px;
}
.list-group .list-group-item .row-picture label .checkbox-material,
.list-group .list-group-item .row-action-primary label .checkbox-material {
  left: -10px;
}
.list-group .list-group-item .row-content {
  display: inline-block;
  width: calc(100% - 92px);
  min-height: 66px;
}
.list-group .list-group-item .row-content .action-secondary {
  position: absolute;
  right: 16px;
  top: 16px;
}
.list-group .list-group-item .row-content .action-secondary i {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.list-group .list-group-item .row-content .action-secondary ~ * {
  max-width: calc(100% - 30px);
}
.list-group .list-group-item .row-content .least-content {
  position: absolute;
  right: 16px;
  top: 0px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
}
.list-group .list-group-item .list-group-item-heading {
  color: rgba(0, 0, 0, 0.77);
  font-size: 20px;
  line-height: 29px;
}
.list-group .list-group-item.active:hover,
.list-group .list-group-item.active:focus {
  background: rgba(0, 0, 0, 0.15);
  outline: 10px solid rgba(0, 0, 0, 0.15);
}
.list-group .list-group-item.active .list-group-item-heading,
.list-group .list-group-item.active .list-group-item-text {
  color: rgba(0, 0, 0, 0.84);
}
.list-group .list-group-separator {
  clear: both;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
}
.list-group .list-group-separator:before {
  content: "";
  width: calc(100% - 90px);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  float: right;
}
.navbar {
  background-color: #009688;
  border: 0;
  border-radius: 0;
}
.navbar .navbar-brand {
  position: relative;
  height: 60px;
  line-height: 30px;
  color: inherit;
}
.navbar .navbar-brand:hover,
.navbar .navbar-brand:focus {
  color: inherit;
  background-color: transparent;
}
.navbar .navbar-text {
  color: inherit;
  margin-top: 20px;
  margin-bottom: 20px;
}
.navbar .navbar-nav > li > a {
  color: inherit;
  padding-top: 20px;
  padding-bottom: 20px;
}
.navbar .navbar-nav > li > a:hover,
.navbar .navbar-nav > li > a:focus {
  color: inherit;
  background-color: transparent;
}
.navbar .navbar-nav > .active > a,
.navbar .navbar-nav > .active > a:hover,
.navbar .navbar-nav > .active > a:focus {
  color: inherit;
  background-color: rgba(255, 255, 255, 0.1);
}
.navbar .navbar-nav > .disabled > a,
.navbar .navbar-nav > .disabled > a:hover,
.navbar .navbar-nav > .disabled > a:focus {
  color: inherit;
  background-color: transparent;
  opacity: 0.9;
}
.navbar .navbar-toggle {
  border: 0;
}
.navbar .navbar-toggle:hover,
.navbar .navbar-toggle:focus {
  background-color: transparent;
}
.navbar .navbar-toggle .icon-bar {
  background-color: inherit;
  border: 1px solid;
}
.navbar .navbar-default .navbar-toggle,
.navbar .navbar-inverse .navbar-toggle {
  border-color: transparent;
}
.navbar .navbar-collapse,
.navbar .navbar-form {
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar .navbar-nav > .open > a,
.navbar .navbar-nav > .open > a:hover,
.navbar .navbar-nav > .open > a:focus {
  background-color: transparent;
  color: inherit;
}
@media (max-width: 767px) {
  .navbar .navbar-nav .navbar-text {
    color: inherit;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .navbar .navbar-nav .open .dropdown-menu > .dropdown-header {
    border: 0;
    color: inherit;
  }
  .navbar .navbar-nav .open .dropdown-menu .divider {
    border-bottom: 1px solid;
    opacity: 0.08;
  }
  .navbar .navbar-nav .open .dropdown-menu > li > a {
    color: inherit;
  }
  .navbar .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar .navbar-nav .open .dropdown-menu > li > a:focus {
    color: inherit;
    background-color: transparent;
  }
  .navbar .navbar-nav .open .dropdown-menu > .active > a,
  .navbar .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: inherit;
    background-color: transparent;
  }
  .navbar .navbar-nav .open .dropdown-menu > .disabled > a,
  .navbar .navbar-nav .open .dropdown-menu > .disabled > a:hover,
  .navbar .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: inherit;
    background-color: transparent;
  }
}
.navbar .navbar-link {
  color: inherit;
}
.navbar .navbar-link:hover {
  color: inherit;
}
.navbar .btn-link {
  color: inherit;
}
.navbar .btn-link:hover,
.navbar .btn-link:focus {
  color: inherit;
}
.navbar .btn-link[disabled]:hover,
fieldset[disabled] .navbar .btn-link:hover,
.navbar .btn-link[disabled]:focus,
fieldset[disabled] .navbar .btn-link:focus {
  color: inherit;
}
.navbar .navbar-form {
  margin-top: 16px;
}
.navbar .navbar-form .form-group {
  margin: 0;
  padding: 0;
}
.navbar .navbar-form .form-group .material-input:before,
.navbar .navbar-form .form-group.is-focused .material-input:after {
  background-color: inherit;
}
.navbar .navbar-form .form-group .form-control,
.navbar .navbar-form .form-control {
  border-color: inherit;
  color: inherit;
  padding: 0;
  margin: 0;
  height: 28px;
  font-size: 14px;
  line-height: 1.42857143;
}
.navbar.navbar,
.navbar-default.navbar {
  background-color: #009688;
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-default.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar.navbar .navbar-form input.form-control::placeholder,
.navbar-default.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar .dropdown-menu,
.navbar-default.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar.navbar .dropdown-menu li > a,
.navbar-default.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar.navbar .dropdown-menu li > a:hover,
.navbar-default.navbar .dropdown-menu li > a:hover,
.navbar.navbar .dropdown-menu li > a:focus,
.navbar-default.navbar .dropdown-menu li > a:focus {
  color: #009688;
  background-color: #eeeeee;
}
.navbar.navbar .dropdown-menu .active > a,
.navbar-default.navbar .dropdown-menu .active > a {
  background-color: #009688;
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar .dropdown-menu .active > a:hover,
.navbar-default.navbar .dropdown-menu .active > a:hover,
.navbar.navbar .dropdown-menu .active > a:focus,
.navbar-default.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-black.navbar {
  background-color: #000000;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-black.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-black.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-black.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-black.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-black.navbar .dropdown-menu li > a:hover,
.navbar-black.navbar .dropdown-menu li > a:focus {
  color: #000000;
  background-color: #eeeeee;
}
.navbar-black.navbar .dropdown-menu .active > a {
  background-color: #000000;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-black.navbar .dropdown-menu .active > a:hover,
.navbar-black.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-white.navbar {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-white.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-white.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-white.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-white.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-white.navbar .dropdown-menu li > a:hover,
.navbar-white.navbar .dropdown-menu li > a:focus {
  color: #ffffff;
  background-color: #eeeeee;
}
.navbar-white.navbar .dropdown-menu .active > a {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-white.navbar .dropdown-menu .active > a:hover,
.navbar-white.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-inverse.navbar {
  background-color: #3f51b5;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-inverse.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-inverse.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-inverse.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-inverse.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-inverse.navbar .dropdown-menu li > a:hover,
.navbar-inverse.navbar .dropdown-menu li > a:focus {
  color: #3f51b5;
  background-color: #eeeeee;
}
.navbar-inverse.navbar .dropdown-menu .active > a {
  background-color: #3f51b5;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-inverse.navbar .dropdown-menu .active > a:hover,
.navbar-inverse.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-primary.navbar {
  background-color: #009688;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-primary.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-primary.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-primary.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-primary.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-primary.navbar .dropdown-menu li > a:hover,
.navbar-primary.navbar .dropdown-menu li > a:focus {
  color: #009688;
  background-color: #eeeeee;
}
.navbar-primary.navbar .dropdown-menu .active > a {
  background-color: #009688;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-primary.navbar .dropdown-menu .active > a:hover,
.navbar-primary.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-success.navbar {
  background-color: #4caf50;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-success.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-success.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-success.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-success.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-success.navbar .dropdown-menu li > a:hover,
.navbar-success.navbar .dropdown-menu li > a:focus {
  color: #4caf50;
  background-color: #eeeeee;
}
.navbar-success.navbar .dropdown-menu .active > a {
  background-color: #4caf50;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-success.navbar .dropdown-menu .active > a:hover,
.navbar-success.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-info.navbar {
  background-color: #03a9f4;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-info.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-info.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-info.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-info.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-info.navbar .dropdown-menu li > a:hover,
.navbar-info.navbar .dropdown-menu li > a:focus {
  color: #03a9f4;
  background-color: #eeeeee;
}
.navbar-info.navbar .dropdown-menu .active > a {
  background-color: #03a9f4;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-info.navbar .dropdown-menu .active > a:hover,
.navbar-info.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-warning.navbar {
  background-color: #ff5722;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-warning.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-warning.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-warning.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-warning.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-warning.navbar .dropdown-menu li > a:hover,
.navbar-warning.navbar .dropdown-menu li > a:focus {
  color: #ff5722;
  background-color: #eeeeee;
}
.navbar-warning.navbar .dropdown-menu .active > a {
  background-color: #ff5722;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-warning.navbar .dropdown-menu .active > a:hover,
.navbar-warning.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-danger.navbar {
  background-color: #f44336;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-danger.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-danger.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-danger.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-danger.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-danger.navbar .dropdown-menu li > a:hover,
.navbar-danger.navbar .dropdown-menu li > a:focus {
  color: #f44336;
  background-color: #eeeeee;
}
.navbar-danger.navbar .dropdown-menu .active > a {
  background-color: #f44336;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-danger.navbar .dropdown-menu .active > a:hover,
.navbar-danger.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red.navbar {
  background-color: #f44336;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-red.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-red.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-red.navbar .dropdown-menu li > a:hover,
.navbar-material-red.navbar .dropdown-menu li > a:focus {
  color: #f44336;
  background-color: #eeeeee;
}
.navbar-material-red.navbar .dropdown-menu .active > a {
  background-color: #f44336;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red.navbar .dropdown-menu .active > a:hover,
.navbar-material-red.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-50.navbar {
  background-color: #ffebee;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-red-50.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-red-50.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-red-50.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-red-50.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-red-50.navbar .dropdown-menu li > a:hover,
.navbar-material-red-50.navbar .dropdown-menu li > a:focus {
  color: #ffebee;
  background-color: #eeeeee;
}
.navbar-material-red-50.navbar .dropdown-menu .active > a {
  background-color: #ffebee;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-red-50.navbar .dropdown-menu .active > a:hover,
.navbar-material-red-50.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-red-100.navbar {
  background-color: #ffcdd2;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-red-100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-red-100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-red-100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-red-100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-red-100.navbar .dropdown-menu li > a:hover,
.navbar-material-red-100.navbar .dropdown-menu li > a:focus {
  color: #ffcdd2;
  background-color: #eeeeee;
}
.navbar-material-red-100.navbar .dropdown-menu .active > a {
  background-color: #ffcdd2;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-red-100.navbar .dropdown-menu .active > a:hover,
.navbar-material-red-100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-red-200.navbar {
  background-color: #ef9a9a;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-red-200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-red-200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-red-200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-red-200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-red-200.navbar .dropdown-menu li > a:hover,
.navbar-material-red-200.navbar .dropdown-menu li > a:focus {
  color: #ef9a9a;
  background-color: #eeeeee;
}
.navbar-material-red-200.navbar .dropdown-menu .active > a {
  background-color: #ef9a9a;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-red-200.navbar .dropdown-menu .active > a:hover,
.navbar-material-red-200.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-red-300.navbar {
  background-color: #e57373;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-300.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-red-300.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-300.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-red-300.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-red-300.navbar .dropdown-menu li > a:hover,
.navbar-material-red-300.navbar .dropdown-menu li > a:focus {
  color: #e57373;
  background-color: #eeeeee;
}
.navbar-material-red-300.navbar .dropdown-menu .active > a {
  background-color: #e57373;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-300.navbar .dropdown-menu .active > a:hover,
.navbar-material-red-300.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-400.navbar {
  background-color: #ef5350;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-red-400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-red-400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-red-400.navbar .dropdown-menu li > a:hover,
.navbar-material-red-400.navbar .dropdown-menu li > a:focus {
  color: #ef5350;
  background-color: #eeeeee;
}
.navbar-material-red-400.navbar .dropdown-menu .active > a {
  background-color: #ef5350;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-400.navbar .dropdown-menu .active > a:hover,
.navbar-material-red-400.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-500.navbar {
  background-color: #f44336;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-500.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-red-500.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-500.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-red-500.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-red-500.navbar .dropdown-menu li > a:hover,
.navbar-material-red-500.navbar .dropdown-menu li > a:focus {
  color: #f44336;
  background-color: #eeeeee;
}
.navbar-material-red-500.navbar .dropdown-menu .active > a {
  background-color: #f44336;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-500.navbar .dropdown-menu .active > a:hover,
.navbar-material-red-500.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-600.navbar {
  background-color: #e53935;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-600.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-red-600.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-600.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-red-600.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-red-600.navbar .dropdown-menu li > a:hover,
.navbar-material-red-600.navbar .dropdown-menu li > a:focus {
  color: #e53935;
  background-color: #eeeeee;
}
.navbar-material-red-600.navbar .dropdown-menu .active > a {
  background-color: #e53935;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-600.navbar .dropdown-menu .active > a:hover,
.navbar-material-red-600.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-700.navbar {
  background-color: #d32f2f;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-red-700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-red-700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-red-700.navbar .dropdown-menu li > a:hover,
.navbar-material-red-700.navbar .dropdown-menu li > a:focus {
  color: #d32f2f;
  background-color: #eeeeee;
}
.navbar-material-red-700.navbar .dropdown-menu .active > a {
  background-color: #d32f2f;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-700.navbar .dropdown-menu .active > a:hover,
.navbar-material-red-700.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-800.navbar {
  background-color: #c62828;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-800.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-red-800.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-800.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-red-800.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-red-800.navbar .dropdown-menu li > a:hover,
.navbar-material-red-800.navbar .dropdown-menu li > a:focus {
  color: #c62828;
  background-color: #eeeeee;
}
.navbar-material-red-800.navbar .dropdown-menu .active > a {
  background-color: #c62828;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-800.navbar .dropdown-menu .active > a:hover,
.navbar-material-red-800.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-900.navbar {
  background-color: #b71c1c;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-900.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-red-900.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-900.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-red-900.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-red-900.navbar .dropdown-menu li > a:hover,
.navbar-material-red-900.navbar .dropdown-menu li > a:focus {
  color: #b71c1c;
  background-color: #eeeeee;
}
.navbar-material-red-900.navbar .dropdown-menu .active > a {
  background-color: #b71c1c;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-900.navbar .dropdown-menu .active > a:hover,
.navbar-material-red-900.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-A100.navbar {
  background-color: #ff8a80;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-red-A100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-red-A100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-red-A100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-red-A100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-red-A100.navbar .dropdown-menu li > a:hover,
.navbar-material-red-A100.navbar .dropdown-menu li > a:focus {
  color: #ff8a80;
  background-color: #eeeeee;
}
.navbar-material-red-A100.navbar .dropdown-menu .active > a {
  background-color: #ff8a80;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-red-A100.navbar .dropdown-menu .active > a:hover,
.navbar-material-red-A100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-red-A200.navbar {
  background-color: #ff5252;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-A200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-red-A200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-A200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-red-A200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-red-A200.navbar .dropdown-menu li > a:hover,
.navbar-material-red-A200.navbar .dropdown-menu li > a:focus {
  color: #ff5252;
  background-color: #eeeeee;
}
.navbar-material-red-A200.navbar .dropdown-menu .active > a {
  background-color: #ff5252;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-A200.navbar .dropdown-menu .active > a:hover,
.navbar-material-red-A200.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-A400.navbar {
  background-color: #ff1744;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-A400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-red-A400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-A400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-red-A400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-red-A400.navbar .dropdown-menu li > a:hover,
.navbar-material-red-A400.navbar .dropdown-menu li > a:focus {
  color: #ff1744;
  background-color: #eeeeee;
}
.navbar-material-red-A400.navbar .dropdown-menu .active > a {
  background-color: #ff1744;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-A400.navbar .dropdown-menu .active > a:hover,
.navbar-material-red-A400.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-A700.navbar {
  background-color: #d50000;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-A700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-red-A700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-A700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-red-A700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-red-A700.navbar .dropdown-menu li > a:hover,
.navbar-material-red-A700.navbar .dropdown-menu li > a:focus {
  color: #d50000;
  background-color: #eeeeee;
}
.navbar-material-red-A700.navbar .dropdown-menu .active > a {
  background-color: #d50000;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-red-A700.navbar .dropdown-menu .active > a:hover,
.navbar-material-red-A700.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink.navbar {
  background-color: #e91e63;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-pink.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-pink.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-pink.navbar .dropdown-menu li > a:hover,
.navbar-material-pink.navbar .dropdown-menu li > a:focus {
  color: #e91e63;
  background-color: #eeeeee;
}
.navbar-material-pink.navbar .dropdown-menu .active > a {
  background-color: #e91e63;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink.navbar .dropdown-menu .active > a:hover,
.navbar-material-pink.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-50.navbar {
  background-color: #fce4ec;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-pink-50.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-pink-50.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-pink-50.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-pink-50.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-pink-50.navbar .dropdown-menu li > a:hover,
.navbar-material-pink-50.navbar .dropdown-menu li > a:focus {
  color: #fce4ec;
  background-color: #eeeeee;
}
.navbar-material-pink-50.navbar .dropdown-menu .active > a {
  background-color: #fce4ec;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-pink-50.navbar .dropdown-menu .active > a:hover,
.navbar-material-pink-50.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-pink-100.navbar {
  background-color: #f8bbd0;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-pink-100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-pink-100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-pink-100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-pink-100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-pink-100.navbar .dropdown-menu li > a:hover,
.navbar-material-pink-100.navbar .dropdown-menu li > a:focus {
  color: #f8bbd0;
  background-color: #eeeeee;
}
.navbar-material-pink-100.navbar .dropdown-menu .active > a {
  background-color: #f8bbd0;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-pink-100.navbar .dropdown-menu .active > a:hover,
.navbar-material-pink-100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-pink-200.navbar {
  background-color: #f48fb1;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-pink-200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-pink-200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-pink-200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-pink-200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-pink-200.navbar .dropdown-menu li > a:hover,
.navbar-material-pink-200.navbar .dropdown-menu li > a:focus {
  color: #f48fb1;
  background-color: #eeeeee;
}
.navbar-material-pink-200.navbar .dropdown-menu .active > a {
  background-color: #f48fb1;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-pink-200.navbar .dropdown-menu .active > a:hover,
.navbar-material-pink-200.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-pink-300.navbar {
  background-color: #f06292;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-300.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-pink-300.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-300.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-pink-300.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-pink-300.navbar .dropdown-menu li > a:hover,
.navbar-material-pink-300.navbar .dropdown-menu li > a:focus {
  color: #f06292;
  background-color: #eeeeee;
}
.navbar-material-pink-300.navbar .dropdown-menu .active > a {
  background-color: #f06292;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-300.navbar .dropdown-menu .active > a:hover,
.navbar-material-pink-300.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-400.navbar {
  background-color: #ec407a;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-pink-400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-pink-400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-pink-400.navbar .dropdown-menu li > a:hover,
.navbar-material-pink-400.navbar .dropdown-menu li > a:focus {
  color: #ec407a;
  background-color: #eeeeee;
}
.navbar-material-pink-400.navbar .dropdown-menu .active > a {
  background-color: #ec407a;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-400.navbar .dropdown-menu .active > a:hover,
.navbar-material-pink-400.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-500.navbar {
  background-color: #e91e63;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-500.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-pink-500.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-500.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-pink-500.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-pink-500.navbar .dropdown-menu li > a:hover,
.navbar-material-pink-500.navbar .dropdown-menu li > a:focus {
  color: #e91e63;
  background-color: #eeeeee;
}
.navbar-material-pink-500.navbar .dropdown-menu .active > a {
  background-color: #e91e63;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-500.navbar .dropdown-menu .active > a:hover,
.navbar-material-pink-500.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-600.navbar {
  background-color: #d81b60;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-600.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-pink-600.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-600.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-pink-600.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-pink-600.navbar .dropdown-menu li > a:hover,
.navbar-material-pink-600.navbar .dropdown-menu li > a:focus {
  color: #d81b60;
  background-color: #eeeeee;
}
.navbar-material-pink-600.navbar .dropdown-menu .active > a {
  background-color: #d81b60;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-600.navbar .dropdown-menu .active > a:hover,
.navbar-material-pink-600.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-700.navbar {
  background-color: #c2185b;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-pink-700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-pink-700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-pink-700.navbar .dropdown-menu li > a:hover,
.navbar-material-pink-700.navbar .dropdown-menu li > a:focus {
  color: #c2185b;
  background-color: #eeeeee;
}
.navbar-material-pink-700.navbar .dropdown-menu .active > a {
  background-color: #c2185b;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-700.navbar .dropdown-menu .active > a:hover,
.navbar-material-pink-700.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-800.navbar {
  background-color: #ad1457;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-800.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-pink-800.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-800.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-pink-800.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-pink-800.navbar .dropdown-menu li > a:hover,
.navbar-material-pink-800.navbar .dropdown-menu li > a:focus {
  color: #ad1457;
  background-color: #eeeeee;
}
.navbar-material-pink-800.navbar .dropdown-menu .active > a {
  background-color: #ad1457;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-800.navbar .dropdown-menu .active > a:hover,
.navbar-material-pink-800.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-900.navbar {
  background-color: #880e4f;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-900.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-pink-900.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-900.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-pink-900.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-pink-900.navbar .dropdown-menu li > a:hover,
.navbar-material-pink-900.navbar .dropdown-menu li > a:focus {
  color: #880e4f;
  background-color: #eeeeee;
}
.navbar-material-pink-900.navbar .dropdown-menu .active > a {
  background-color: #880e4f;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-900.navbar .dropdown-menu .active > a:hover,
.navbar-material-pink-900.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-A100.navbar {
  background-color: #ff80ab;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-A100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-pink-A100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-A100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-pink-A100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-pink-A100.navbar .dropdown-menu li > a:hover,
.navbar-material-pink-A100.navbar .dropdown-menu li > a:focus {
  color: #ff80ab;
  background-color: #eeeeee;
}
.navbar-material-pink-A100.navbar .dropdown-menu .active > a {
  background-color: #ff80ab;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-A100.navbar .dropdown-menu .active > a:hover,
.navbar-material-pink-A100.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-A200.navbar {
  background-color: #ff4081;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-A200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-pink-A200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-A200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-pink-A200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-pink-A200.navbar .dropdown-menu li > a:hover,
.navbar-material-pink-A200.navbar .dropdown-menu li > a:focus {
  color: #ff4081;
  background-color: #eeeeee;
}
.navbar-material-pink-A200.navbar .dropdown-menu .active > a {
  background-color: #ff4081;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-A200.navbar .dropdown-menu .active > a:hover,
.navbar-material-pink-A200.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-A400.navbar {
  background-color: #f50057;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-A400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-pink-A400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-A400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-pink-A400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-pink-A400.navbar .dropdown-menu li > a:hover,
.navbar-material-pink-A400.navbar .dropdown-menu li > a:focus {
  color: #f50057;
  background-color: #eeeeee;
}
.navbar-material-pink-A400.navbar .dropdown-menu .active > a {
  background-color: #f50057;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-A400.navbar .dropdown-menu .active > a:hover,
.navbar-material-pink-A400.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-A700.navbar {
  background-color: #c51162;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-A700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-pink-A700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-A700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-pink-A700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-pink-A700.navbar .dropdown-menu li > a:hover,
.navbar-material-pink-A700.navbar .dropdown-menu li > a:focus {
  color: #c51162;
  background-color: #eeeeee;
}
.navbar-material-pink-A700.navbar .dropdown-menu .active > a {
  background-color: #c51162;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-pink-A700.navbar .dropdown-menu .active > a:hover,
.navbar-material-pink-A700.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple.navbar {
  background-color: #9c27b0;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-purple.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-purple.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-purple.navbar .dropdown-menu li > a:hover,
.navbar-material-purple.navbar .dropdown-menu li > a:focus {
  color: #9c27b0;
  background-color: #eeeeee;
}
.navbar-material-purple.navbar .dropdown-menu .active > a {
  background-color: #9c27b0;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple.navbar .dropdown-menu .active > a:hover,
.navbar-material-purple.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-50.navbar {
  background-color: #f3e5f5;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-purple-50.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-purple-50.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-purple-50.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-purple-50.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-purple-50.navbar .dropdown-menu li > a:hover,
.navbar-material-purple-50.navbar .dropdown-menu li > a:focus {
  color: #f3e5f5;
  background-color: #eeeeee;
}
.navbar-material-purple-50.navbar .dropdown-menu .active > a {
  background-color: #f3e5f5;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-purple-50.navbar .dropdown-menu .active > a:hover,
.navbar-material-purple-50.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-purple-100.navbar {
  background-color: #e1bee7;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-purple-100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-purple-100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-purple-100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-purple-100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-purple-100.navbar .dropdown-menu li > a:hover,
.navbar-material-purple-100.navbar .dropdown-menu li > a:focus {
  color: #e1bee7;
  background-color: #eeeeee;
}
.navbar-material-purple-100.navbar .dropdown-menu .active > a {
  background-color: #e1bee7;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-purple-100.navbar .dropdown-menu .active > a:hover,
.navbar-material-purple-100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-purple-200.navbar {
  background-color: #ce93d8;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-purple-200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-purple-200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-purple-200.navbar .dropdown-menu li > a:hover,
.navbar-material-purple-200.navbar .dropdown-menu li > a:focus {
  color: #ce93d8;
  background-color: #eeeeee;
}
.navbar-material-purple-200.navbar .dropdown-menu .active > a {
  background-color: #ce93d8;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-200.navbar .dropdown-menu .active > a:hover,
.navbar-material-purple-200.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-300.navbar {
  background-color: #ba68c8;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-300.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-purple-300.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-300.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-purple-300.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-purple-300.navbar .dropdown-menu li > a:hover,
.navbar-material-purple-300.navbar .dropdown-menu li > a:focus {
  color: #ba68c8;
  background-color: #eeeeee;
}
.navbar-material-purple-300.navbar .dropdown-menu .active > a {
  background-color: #ba68c8;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-300.navbar .dropdown-menu .active > a:hover,
.navbar-material-purple-300.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-400.navbar {
  background-color: #ab47bc;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-purple-400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-purple-400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-purple-400.navbar .dropdown-menu li > a:hover,
.navbar-material-purple-400.navbar .dropdown-menu li > a:focus {
  color: #ab47bc;
  background-color: #eeeeee;
}
.navbar-material-purple-400.navbar .dropdown-menu .active > a {
  background-color: #ab47bc;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-400.navbar .dropdown-menu .active > a:hover,
.navbar-material-purple-400.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-500.navbar {
  background-color: #9c27b0;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-500.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-purple-500.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-500.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-purple-500.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-purple-500.navbar .dropdown-menu li > a:hover,
.navbar-material-purple-500.navbar .dropdown-menu li > a:focus {
  color: #9c27b0;
  background-color: #eeeeee;
}
.navbar-material-purple-500.navbar .dropdown-menu .active > a {
  background-color: #9c27b0;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-500.navbar .dropdown-menu .active > a:hover,
.navbar-material-purple-500.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-600.navbar {
  background-color: #8e24aa;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-600.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-purple-600.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-600.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-purple-600.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-purple-600.navbar .dropdown-menu li > a:hover,
.navbar-material-purple-600.navbar .dropdown-menu li > a:focus {
  color: #8e24aa;
  background-color: #eeeeee;
}
.navbar-material-purple-600.navbar .dropdown-menu .active > a {
  background-color: #8e24aa;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-600.navbar .dropdown-menu .active > a:hover,
.navbar-material-purple-600.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-700.navbar {
  background-color: #7b1fa2;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-purple-700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-purple-700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-purple-700.navbar .dropdown-menu li > a:hover,
.navbar-material-purple-700.navbar .dropdown-menu li > a:focus {
  color: #7b1fa2;
  background-color: #eeeeee;
}
.navbar-material-purple-700.navbar .dropdown-menu .active > a {
  background-color: #7b1fa2;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-700.navbar .dropdown-menu .active > a:hover,
.navbar-material-purple-700.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-800.navbar {
  background-color: #6a1b9a;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-800.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-purple-800.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-800.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-purple-800.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-purple-800.navbar .dropdown-menu li > a:hover,
.navbar-material-purple-800.navbar .dropdown-menu li > a:focus {
  color: #6a1b9a;
  background-color: #eeeeee;
}
.navbar-material-purple-800.navbar .dropdown-menu .active > a {
  background-color: #6a1b9a;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-800.navbar .dropdown-menu .active > a:hover,
.navbar-material-purple-800.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-900.navbar {
  background-color: #4a148c;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-900.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-purple-900.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-900.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-purple-900.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-purple-900.navbar .dropdown-menu li > a:hover,
.navbar-material-purple-900.navbar .dropdown-menu li > a:focus {
  color: #4a148c;
  background-color: #eeeeee;
}
.navbar-material-purple-900.navbar .dropdown-menu .active > a {
  background-color: #4a148c;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-900.navbar .dropdown-menu .active > a:hover,
.navbar-material-purple-900.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-A100.navbar {
  background-color: #ea80fc;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-A100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-purple-A100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-A100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-purple-A100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-purple-A100.navbar .dropdown-menu li > a:hover,
.navbar-material-purple-A100.navbar .dropdown-menu li > a:focus {
  color: #ea80fc;
  background-color: #eeeeee;
}
.navbar-material-purple-A100.navbar .dropdown-menu .active > a {
  background-color: #ea80fc;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-A100.navbar .dropdown-menu .active > a:hover,
.navbar-material-purple-A100.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-A200.navbar {
  background-color: #e040fb;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-A200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-purple-A200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-A200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-purple-A200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-purple-A200.navbar .dropdown-menu li > a:hover,
.navbar-material-purple-A200.navbar .dropdown-menu li > a:focus {
  color: #e040fb;
  background-color: #eeeeee;
}
.navbar-material-purple-A200.navbar .dropdown-menu .active > a {
  background-color: #e040fb;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-A200.navbar .dropdown-menu .active > a:hover,
.navbar-material-purple-A200.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-A400.navbar {
  background-color: #d500f9;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-A400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-purple-A400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-A400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-purple-A400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-purple-A400.navbar .dropdown-menu li > a:hover,
.navbar-material-purple-A400.navbar .dropdown-menu li > a:focus {
  color: #d500f9;
  background-color: #eeeeee;
}
.navbar-material-purple-A400.navbar .dropdown-menu .active > a {
  background-color: #d500f9;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-A400.navbar .dropdown-menu .active > a:hover,
.navbar-material-purple-A400.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-A700.navbar {
  background-color: #aa00ff;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-A700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-purple-A700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-A700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-purple-A700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-purple-A700.navbar .dropdown-menu li > a:hover,
.navbar-material-purple-A700.navbar .dropdown-menu li > a:focus {
  color: #aa00ff;
  background-color: #eeeeee;
}
.navbar-material-purple-A700.navbar .dropdown-menu .active > a {
  background-color: #aa00ff;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-purple-A700.navbar .dropdown-menu .active > a:hover,
.navbar-material-purple-A700.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple.navbar {
  background-color: #673ab7;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-deep-purple.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-deep-purple.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-deep-purple.navbar .dropdown-menu li > a:hover,
.navbar-material-deep-purple.navbar .dropdown-menu li > a:focus {
  color: #673ab7;
  background-color: #eeeeee;
}
.navbar-material-deep-purple.navbar .dropdown-menu .active > a {
  background-color: #673ab7;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple.navbar .dropdown-menu .active > a:hover,
.navbar-material-deep-purple.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-50.navbar {
  background-color: #ede7f6;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-purple-50.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-deep-purple-50.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-purple-50.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-deep-purple-50.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-deep-purple-50.navbar .dropdown-menu li > a:hover,
.navbar-material-deep-purple-50.navbar .dropdown-menu li > a:focus {
  color: #ede7f6;
  background-color: #eeeeee;
}
.navbar-material-deep-purple-50.navbar .dropdown-menu .active > a {
  background-color: #ede7f6;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-purple-50.navbar .dropdown-menu .active > a:hover,
.navbar-material-deep-purple-50.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-purple-100.navbar {
  background-color: #d1c4e9;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-purple-100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-deep-purple-100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-purple-100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-deep-purple-100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-deep-purple-100.navbar .dropdown-menu li > a:hover,
.navbar-material-deep-purple-100.navbar .dropdown-menu li > a:focus {
  color: #d1c4e9;
  background-color: #eeeeee;
}
.navbar-material-deep-purple-100.navbar .dropdown-menu .active > a {
  background-color: #d1c4e9;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-purple-100.navbar .dropdown-menu .active > a:hover,
.navbar-material-deep-purple-100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-purple-200.navbar {
  background-color: #b39ddb;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-deep-purple-200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-deep-purple-200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-deep-purple-200.navbar .dropdown-menu li > a:hover,
.navbar-material-deep-purple-200.navbar .dropdown-menu li > a:focus {
  color: #b39ddb;
  background-color: #eeeeee;
}
.navbar-material-deep-purple-200.navbar .dropdown-menu .active > a {
  background-color: #b39ddb;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-200.navbar .dropdown-menu .active > a:hover,
.navbar-material-deep-purple-200.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-300.navbar {
  background-color: #9575cd;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-300.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-deep-purple-300.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-300.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-deep-purple-300.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-deep-purple-300.navbar .dropdown-menu li > a:hover,
.navbar-material-deep-purple-300.navbar .dropdown-menu li > a:focus {
  color: #9575cd;
  background-color: #eeeeee;
}
.navbar-material-deep-purple-300.navbar .dropdown-menu .active > a {
  background-color: #9575cd;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-300.navbar .dropdown-menu .active > a:hover,
.navbar-material-deep-purple-300.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-400.navbar {
  background-color: #7e57c2;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-deep-purple-400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-deep-purple-400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-deep-purple-400.navbar .dropdown-menu li > a:hover,
.navbar-material-deep-purple-400.navbar .dropdown-menu li > a:focus {
  color: #7e57c2;
  background-color: #eeeeee;
}
.navbar-material-deep-purple-400.navbar .dropdown-menu .active > a {
  background-color: #7e57c2;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-400.navbar .dropdown-menu .active > a:hover,
.navbar-material-deep-purple-400.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-500.navbar {
  background-color: #673ab7;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-500.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-deep-purple-500.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-500.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-deep-purple-500.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-deep-purple-500.navbar .dropdown-menu li > a:hover,
.navbar-material-deep-purple-500.navbar .dropdown-menu li > a:focus {
  color: #673ab7;
  background-color: #eeeeee;
}
.navbar-material-deep-purple-500.navbar .dropdown-menu .active > a {
  background-color: #673ab7;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-500.navbar .dropdown-menu .active > a:hover,
.navbar-material-deep-purple-500.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-600.navbar {
  background-color: #5e35b1;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-600.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-deep-purple-600.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-600.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-deep-purple-600.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-deep-purple-600.navbar .dropdown-menu li > a:hover,
.navbar-material-deep-purple-600.navbar .dropdown-menu li > a:focus {
  color: #5e35b1;
  background-color: #eeeeee;
}
.navbar-material-deep-purple-600.navbar .dropdown-menu .active > a {
  background-color: #5e35b1;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-600.navbar .dropdown-menu .active > a:hover,
.navbar-material-deep-purple-600.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-700.navbar {
  background-color: #512da8;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-deep-purple-700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-deep-purple-700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-deep-purple-700.navbar .dropdown-menu li > a:hover,
.navbar-material-deep-purple-700.navbar .dropdown-menu li > a:focus {
  color: #512da8;
  background-color: #eeeeee;
}
.navbar-material-deep-purple-700.navbar .dropdown-menu .active > a {
  background-color: #512da8;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-700.navbar .dropdown-menu .active > a:hover,
.navbar-material-deep-purple-700.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-800.navbar {
  background-color: #4527a0;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-800.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-deep-purple-800.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-800.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-deep-purple-800.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-deep-purple-800.navbar .dropdown-menu li > a:hover,
.navbar-material-deep-purple-800.navbar .dropdown-menu li > a:focus {
  color: #4527a0;
  background-color: #eeeeee;
}
.navbar-material-deep-purple-800.navbar .dropdown-menu .active > a {
  background-color: #4527a0;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-800.navbar .dropdown-menu .active > a:hover,
.navbar-material-deep-purple-800.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-900.navbar {
  background-color: #311b92;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-900.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-deep-purple-900.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-900.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-deep-purple-900.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-deep-purple-900.navbar .dropdown-menu li > a:hover,
.navbar-material-deep-purple-900.navbar .dropdown-menu li > a:focus {
  color: #311b92;
  background-color: #eeeeee;
}
.navbar-material-deep-purple-900.navbar .dropdown-menu .active > a {
  background-color: #311b92;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-900.navbar .dropdown-menu .active > a:hover,
.navbar-material-deep-purple-900.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-A100.navbar {
  background-color: #b388ff;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-A100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-deep-purple-A100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-A100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-deep-purple-A100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-deep-purple-A100.navbar .dropdown-menu li > a:hover,
.navbar-material-deep-purple-A100.navbar .dropdown-menu li > a:focus {
  color: #b388ff;
  background-color: #eeeeee;
}
.navbar-material-deep-purple-A100.navbar .dropdown-menu .active > a {
  background-color: #b388ff;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-A100.navbar .dropdown-menu .active > a:hover,
.navbar-material-deep-purple-A100.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-A200.navbar {
  background-color: #7c4dff;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-A200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-deep-purple-A200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-A200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-deep-purple-A200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-deep-purple-A200.navbar .dropdown-menu li > a:hover,
.navbar-material-deep-purple-A200.navbar .dropdown-menu li > a:focus {
  color: #7c4dff;
  background-color: #eeeeee;
}
.navbar-material-deep-purple-A200.navbar .dropdown-menu .active > a {
  background-color: #7c4dff;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-A200.navbar .dropdown-menu .active > a:hover,
.navbar-material-deep-purple-A200.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-A400.navbar {
  background-color: #651fff;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-A400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-deep-purple-A400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-A400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-deep-purple-A400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-deep-purple-A400.navbar .dropdown-menu li > a:hover,
.navbar-material-deep-purple-A400.navbar .dropdown-menu li > a:focus {
  color: #651fff;
  background-color: #eeeeee;
}
.navbar-material-deep-purple-A400.navbar .dropdown-menu .active > a {
  background-color: #651fff;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-A400.navbar .dropdown-menu .active > a:hover,
.navbar-material-deep-purple-A400.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-A700.navbar {
  background-color: #6200ea;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-A700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-deep-purple-A700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-A700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-deep-purple-A700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-deep-purple-A700.navbar .dropdown-menu li > a:hover,
.navbar-material-deep-purple-A700.navbar .dropdown-menu li > a:focus {
  color: #6200ea;
  background-color: #eeeeee;
}
.navbar-material-deep-purple-A700.navbar .dropdown-menu .active > a {
  background-color: #6200ea;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-purple-A700.navbar .dropdown-menu .active > a:hover,
.navbar-material-deep-purple-A700.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo.navbar {
  background-color: #3f51b5;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-indigo.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-indigo.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-indigo.navbar .dropdown-menu li > a:hover,
.navbar-material-indigo.navbar .dropdown-menu li > a:focus {
  color: #3f51b5;
  background-color: #eeeeee;
}
.navbar-material-indigo.navbar .dropdown-menu .active > a {
  background-color: #3f51b5;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo.navbar .dropdown-menu .active > a:hover,
.navbar-material-indigo.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-50.navbar {
  background-color: #e8eaf6;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-indigo-50.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-indigo-50.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-indigo-50.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-indigo-50.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-indigo-50.navbar .dropdown-menu li > a:hover,
.navbar-material-indigo-50.navbar .dropdown-menu li > a:focus {
  color: #e8eaf6;
  background-color: #eeeeee;
}
.navbar-material-indigo-50.navbar .dropdown-menu .active > a {
  background-color: #e8eaf6;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-indigo-50.navbar .dropdown-menu .active > a:hover,
.navbar-material-indigo-50.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-indigo-100.navbar {
  background-color: #c5cae9;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-indigo-100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-indigo-100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-indigo-100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-indigo-100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-indigo-100.navbar .dropdown-menu li > a:hover,
.navbar-material-indigo-100.navbar .dropdown-menu li > a:focus {
  color: #c5cae9;
  background-color: #eeeeee;
}
.navbar-material-indigo-100.navbar .dropdown-menu .active > a {
  background-color: #c5cae9;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-indigo-100.navbar .dropdown-menu .active > a:hover,
.navbar-material-indigo-100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-indigo-200.navbar {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-indigo-200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-indigo-200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-indigo-200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-indigo-200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-indigo-200.navbar .dropdown-menu li > a:hover,
.navbar-material-indigo-200.navbar .dropdown-menu li > a:focus {
  color: #9fa8da;
  background-color: #eeeeee;
}
.navbar-material-indigo-200.navbar .dropdown-menu .active > a {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-indigo-200.navbar .dropdown-menu .active > a:hover,
.navbar-material-indigo-200.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-indigo-300.navbar {
  background-color: #7986cb;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-300.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-indigo-300.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-300.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-indigo-300.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-indigo-300.navbar .dropdown-menu li > a:hover,
.navbar-material-indigo-300.navbar .dropdown-menu li > a:focus {
  color: #7986cb;
  background-color: #eeeeee;
}
.navbar-material-indigo-300.navbar .dropdown-menu .active > a {
  background-color: #7986cb;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-300.navbar .dropdown-menu .active > a:hover,
.navbar-material-indigo-300.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-400.navbar {
  background-color: #5c6bc0;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-indigo-400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-indigo-400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-indigo-400.navbar .dropdown-menu li > a:hover,
.navbar-material-indigo-400.navbar .dropdown-menu li > a:focus {
  color: #5c6bc0;
  background-color: #eeeeee;
}
.navbar-material-indigo-400.navbar .dropdown-menu .active > a {
  background-color: #5c6bc0;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-400.navbar .dropdown-menu .active > a:hover,
.navbar-material-indigo-400.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-500.navbar {
  background-color: #3f51b5;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-500.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-indigo-500.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-500.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-indigo-500.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-indigo-500.navbar .dropdown-menu li > a:hover,
.navbar-material-indigo-500.navbar .dropdown-menu li > a:focus {
  color: #3f51b5;
  background-color: #eeeeee;
}
.navbar-material-indigo-500.navbar .dropdown-menu .active > a {
  background-color: #3f51b5;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-500.navbar .dropdown-menu .active > a:hover,
.navbar-material-indigo-500.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-600.navbar {
  background-color: #3949ab;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-600.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-indigo-600.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-600.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-indigo-600.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-indigo-600.navbar .dropdown-menu li > a:hover,
.navbar-material-indigo-600.navbar .dropdown-menu li > a:focus {
  color: #3949ab;
  background-color: #eeeeee;
}
.navbar-material-indigo-600.navbar .dropdown-menu .active > a {
  background-color: #3949ab;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-600.navbar .dropdown-menu .active > a:hover,
.navbar-material-indigo-600.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-700.navbar {
  background-color: #303f9f;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-indigo-700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-indigo-700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-indigo-700.navbar .dropdown-menu li > a:hover,
.navbar-material-indigo-700.navbar .dropdown-menu li > a:focus {
  color: #303f9f;
  background-color: #eeeeee;
}
.navbar-material-indigo-700.navbar .dropdown-menu .active > a {
  background-color: #303f9f;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-700.navbar .dropdown-menu .active > a:hover,
.navbar-material-indigo-700.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-800.navbar {
  background-color: #283593;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-800.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-indigo-800.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-800.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-indigo-800.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-indigo-800.navbar .dropdown-menu li > a:hover,
.navbar-material-indigo-800.navbar .dropdown-menu li > a:focus {
  color: #283593;
  background-color: #eeeeee;
}
.navbar-material-indigo-800.navbar .dropdown-menu .active > a {
  background-color: #283593;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-800.navbar .dropdown-menu .active > a:hover,
.navbar-material-indigo-800.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-900.navbar {
  background-color: #1a237e;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-900.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-indigo-900.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-900.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-indigo-900.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-indigo-900.navbar .dropdown-menu li > a:hover,
.navbar-material-indigo-900.navbar .dropdown-menu li > a:focus {
  color: #1a237e;
  background-color: #eeeeee;
}
.navbar-material-indigo-900.navbar .dropdown-menu .active > a {
  background-color: #1a237e;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-900.navbar .dropdown-menu .active > a:hover,
.navbar-material-indigo-900.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-A100.navbar {
  background-color: #8c9eff;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-A100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-indigo-A100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-A100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-indigo-A100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-indigo-A100.navbar .dropdown-menu li > a:hover,
.navbar-material-indigo-A100.navbar .dropdown-menu li > a:focus {
  color: #8c9eff;
  background-color: #eeeeee;
}
.navbar-material-indigo-A100.navbar .dropdown-menu .active > a {
  background-color: #8c9eff;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-A100.navbar .dropdown-menu .active > a:hover,
.navbar-material-indigo-A100.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-A200.navbar {
  background-color: #536dfe;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-A200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-indigo-A200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-A200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-indigo-A200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-indigo-A200.navbar .dropdown-menu li > a:hover,
.navbar-material-indigo-A200.navbar .dropdown-menu li > a:focus {
  color: #536dfe;
  background-color: #eeeeee;
}
.navbar-material-indigo-A200.navbar .dropdown-menu .active > a {
  background-color: #536dfe;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-A200.navbar .dropdown-menu .active > a:hover,
.navbar-material-indigo-A200.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-A400.navbar {
  background-color: #3d5afe;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-A400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-indigo-A400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-A400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-indigo-A400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-indigo-A400.navbar .dropdown-menu li > a:hover,
.navbar-material-indigo-A400.navbar .dropdown-menu li > a:focus {
  color: #3d5afe;
  background-color: #eeeeee;
}
.navbar-material-indigo-A400.navbar .dropdown-menu .active > a {
  background-color: #3d5afe;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-A400.navbar .dropdown-menu .active > a:hover,
.navbar-material-indigo-A400.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-A700.navbar {
  background-color: #304ffe;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-A700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-indigo-A700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-A700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-indigo-A700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-indigo-A700.navbar .dropdown-menu li > a:hover,
.navbar-material-indigo-A700.navbar .dropdown-menu li > a:focus {
  color: #304ffe;
  background-color: #eeeeee;
}
.navbar-material-indigo-A700.navbar .dropdown-menu .active > a {
  background-color: #304ffe;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-indigo-A700.navbar .dropdown-menu .active > a:hover,
.navbar-material-indigo-A700.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue.navbar {
  background-color: #2196f3;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-blue.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-blue.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-blue.navbar .dropdown-menu li > a:hover,
.navbar-material-blue.navbar .dropdown-menu li > a:focus {
  color: #2196f3;
  background-color: #eeeeee;
}
.navbar-material-blue.navbar .dropdown-menu .active > a {
  background-color: #2196f3;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue.navbar .dropdown-menu .active > a:hover,
.navbar-material-blue.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-50.navbar {
  background-color: #e3f2fd;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-50.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-blue-50.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-50.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-blue-50.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-blue-50.navbar .dropdown-menu li > a:hover,
.navbar-material-blue-50.navbar .dropdown-menu li > a:focus {
  color: #e3f2fd;
  background-color: #eeeeee;
}
.navbar-material-blue-50.navbar .dropdown-menu .active > a {
  background-color: #e3f2fd;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-50.navbar .dropdown-menu .active > a:hover,
.navbar-material-blue-50.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-100.navbar {
  background-color: #bbdefb;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-blue-100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-blue-100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-blue-100.navbar .dropdown-menu li > a:hover,
.navbar-material-blue-100.navbar .dropdown-menu li > a:focus {
  color: #bbdefb;
  background-color: #eeeeee;
}
.navbar-material-blue-100.navbar .dropdown-menu .active > a {
  background-color: #bbdefb;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-100.navbar .dropdown-menu .active > a:hover,
.navbar-material-blue-100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-200.navbar {
  background-color: #90caf9;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-blue-200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-blue-200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-blue-200.navbar .dropdown-menu li > a:hover,
.navbar-material-blue-200.navbar .dropdown-menu li > a:focus {
  color: #90caf9;
  background-color: #eeeeee;
}
.navbar-material-blue-200.navbar .dropdown-menu .active > a {
  background-color: #90caf9;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-200.navbar .dropdown-menu .active > a:hover,
.navbar-material-blue-200.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-300.navbar {
  background-color: #64b5f6;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-300.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-blue-300.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-300.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-blue-300.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-blue-300.navbar .dropdown-menu li > a:hover,
.navbar-material-blue-300.navbar .dropdown-menu li > a:focus {
  color: #64b5f6;
  background-color: #eeeeee;
}
.navbar-material-blue-300.navbar .dropdown-menu .active > a {
  background-color: #64b5f6;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-300.navbar .dropdown-menu .active > a:hover,
.navbar-material-blue-300.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-400.navbar {
  background-color: #42a5f5;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-blue-400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-blue-400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-blue-400.navbar .dropdown-menu li > a:hover,
.navbar-material-blue-400.navbar .dropdown-menu li > a:focus {
  color: #42a5f5;
  background-color: #eeeeee;
}
.navbar-material-blue-400.navbar .dropdown-menu .active > a {
  background-color: #42a5f5;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-400.navbar .dropdown-menu .active > a:hover,
.navbar-material-blue-400.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-500.navbar {
  background-color: #2196f3;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-500.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-blue-500.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-500.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-blue-500.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-blue-500.navbar .dropdown-menu li > a:hover,
.navbar-material-blue-500.navbar .dropdown-menu li > a:focus {
  color: #2196f3;
  background-color: #eeeeee;
}
.navbar-material-blue-500.navbar .dropdown-menu .active > a {
  background-color: #2196f3;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-500.navbar .dropdown-menu .active > a:hover,
.navbar-material-blue-500.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-600.navbar {
  background-color: #1e88e5;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-600.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-blue-600.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-600.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-blue-600.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-blue-600.navbar .dropdown-menu li > a:hover,
.navbar-material-blue-600.navbar .dropdown-menu li > a:focus {
  color: #1e88e5;
  background-color: #eeeeee;
}
.navbar-material-blue-600.navbar .dropdown-menu .active > a {
  background-color: #1e88e5;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-600.navbar .dropdown-menu .active > a:hover,
.navbar-material-blue-600.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-700.navbar {
  background-color: #1976d2;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-blue-700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-blue-700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-blue-700.navbar .dropdown-menu li > a:hover,
.navbar-material-blue-700.navbar .dropdown-menu li > a:focus {
  color: #1976d2;
  background-color: #eeeeee;
}
.navbar-material-blue-700.navbar .dropdown-menu .active > a {
  background-color: #1976d2;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-700.navbar .dropdown-menu .active > a:hover,
.navbar-material-blue-700.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-800.navbar {
  background-color: #1565c0;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-800.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-blue-800.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-800.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-blue-800.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-blue-800.navbar .dropdown-menu li > a:hover,
.navbar-material-blue-800.navbar .dropdown-menu li > a:focus {
  color: #1565c0;
  background-color: #eeeeee;
}
.navbar-material-blue-800.navbar .dropdown-menu .active > a {
  background-color: #1565c0;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-800.navbar .dropdown-menu .active > a:hover,
.navbar-material-blue-800.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-900.navbar {
  background-color: #0d47a1;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-900.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-blue-900.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-900.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-blue-900.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-blue-900.navbar .dropdown-menu li > a:hover,
.navbar-material-blue-900.navbar .dropdown-menu li > a:focus {
  color: #0d47a1;
  background-color: #eeeeee;
}
.navbar-material-blue-900.navbar .dropdown-menu .active > a {
  background-color: #0d47a1;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-900.navbar .dropdown-menu .active > a:hover,
.navbar-material-blue-900.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-A100.navbar {
  background-color: #82b1ff;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-A100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-blue-A100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-A100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-blue-A100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-blue-A100.navbar .dropdown-menu li > a:hover,
.navbar-material-blue-A100.navbar .dropdown-menu li > a:focus {
  color: #82b1ff;
  background-color: #eeeeee;
}
.navbar-material-blue-A100.navbar .dropdown-menu .active > a {
  background-color: #82b1ff;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-A100.navbar .dropdown-menu .active > a:hover,
.navbar-material-blue-A100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-A200.navbar {
  background-color: #448aff;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-A200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-blue-A200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-A200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-blue-A200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-blue-A200.navbar .dropdown-menu li > a:hover,
.navbar-material-blue-A200.navbar .dropdown-menu li > a:focus {
  color: #448aff;
  background-color: #eeeeee;
}
.navbar-material-blue-A200.navbar .dropdown-menu .active > a {
  background-color: #448aff;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-A200.navbar .dropdown-menu .active > a:hover,
.navbar-material-blue-A200.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-A400.navbar {
  background-color: #2979ff;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-A400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-blue-A400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-A400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-blue-A400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-blue-A400.navbar .dropdown-menu li > a:hover,
.navbar-material-blue-A400.navbar .dropdown-menu li > a:focus {
  color: #2979ff;
  background-color: #eeeeee;
}
.navbar-material-blue-A400.navbar .dropdown-menu .active > a {
  background-color: #2979ff;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-A400.navbar .dropdown-menu .active > a:hover,
.navbar-material-blue-A400.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-A700.navbar {
  background-color: #2962ff;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-A700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-blue-A700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-A700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-blue-A700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-blue-A700.navbar .dropdown-menu li > a:hover,
.navbar-material-blue-A700.navbar .dropdown-menu li > a:focus {
  color: #2962ff;
  background-color: #eeeeee;
}
.navbar-material-blue-A700.navbar .dropdown-menu .active > a {
  background-color: #2962ff;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-A700.navbar .dropdown-menu .active > a:hover,
.navbar-material-blue-A700.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue.navbar {
  background-color: #03a9f4;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-light-blue.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-light-blue.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-light-blue.navbar .dropdown-menu li > a:hover,
.navbar-material-light-blue.navbar .dropdown-menu li > a:focus {
  color: #03a9f4;
  background-color: #eeeeee;
}
.navbar-material-light-blue.navbar .dropdown-menu .active > a {
  background-color: #03a9f4;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue.navbar .dropdown-menu .active > a:hover,
.navbar-material-light-blue.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-50.navbar {
  background-color: #e1f5fe;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-50.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-light-blue-50.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-50.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-light-blue-50.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-light-blue-50.navbar .dropdown-menu li > a:hover,
.navbar-material-light-blue-50.navbar .dropdown-menu li > a:focus {
  color: #e1f5fe;
  background-color: #eeeeee;
}
.navbar-material-light-blue-50.navbar .dropdown-menu .active > a {
  background-color: #e1f5fe;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-50.navbar .dropdown-menu .active > a:hover,
.navbar-material-light-blue-50.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-100.navbar {
  background-color: #b3e5fc;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-light-blue-100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-light-blue-100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-light-blue-100.navbar .dropdown-menu li > a:hover,
.navbar-material-light-blue-100.navbar .dropdown-menu li > a:focus {
  color: #b3e5fc;
  background-color: #eeeeee;
}
.navbar-material-light-blue-100.navbar .dropdown-menu .active > a {
  background-color: #b3e5fc;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-100.navbar .dropdown-menu .active > a:hover,
.navbar-material-light-blue-100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-200.navbar {
  background-color: #81d4fa;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-light-blue-200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-light-blue-200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-light-blue-200.navbar .dropdown-menu li > a:hover,
.navbar-material-light-blue-200.navbar .dropdown-menu li > a:focus {
  color: #81d4fa;
  background-color: #eeeeee;
}
.navbar-material-light-blue-200.navbar .dropdown-menu .active > a {
  background-color: #81d4fa;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-200.navbar .dropdown-menu .active > a:hover,
.navbar-material-light-blue-200.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-300.navbar {
  background-color: #4fc3f7;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-300.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-light-blue-300.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-300.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-light-blue-300.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-light-blue-300.navbar .dropdown-menu li > a:hover,
.navbar-material-light-blue-300.navbar .dropdown-menu li > a:focus {
  color: #4fc3f7;
  background-color: #eeeeee;
}
.navbar-material-light-blue-300.navbar .dropdown-menu .active > a {
  background-color: #4fc3f7;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-300.navbar .dropdown-menu .active > a:hover,
.navbar-material-light-blue-300.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-400.navbar {
  background-color: #29b6f6;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-light-blue-400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-light-blue-400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-light-blue-400.navbar .dropdown-menu li > a:hover,
.navbar-material-light-blue-400.navbar .dropdown-menu li > a:focus {
  color: #29b6f6;
  background-color: #eeeeee;
}
.navbar-material-light-blue-400.navbar .dropdown-menu .active > a {
  background-color: #29b6f6;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-400.navbar .dropdown-menu .active > a:hover,
.navbar-material-light-blue-400.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-500.navbar {
  background-color: #03a9f4;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-500.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-light-blue-500.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-500.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-light-blue-500.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-light-blue-500.navbar .dropdown-menu li > a:hover,
.navbar-material-light-blue-500.navbar .dropdown-menu li > a:focus {
  color: #03a9f4;
  background-color: #eeeeee;
}
.navbar-material-light-blue-500.navbar .dropdown-menu .active > a {
  background-color: #03a9f4;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-500.navbar .dropdown-menu .active > a:hover,
.navbar-material-light-blue-500.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-600.navbar {
  background-color: #039be5;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-600.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-light-blue-600.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-600.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-light-blue-600.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-light-blue-600.navbar .dropdown-menu li > a:hover,
.navbar-material-light-blue-600.navbar .dropdown-menu li > a:focus {
  color: #039be5;
  background-color: #eeeeee;
}
.navbar-material-light-blue-600.navbar .dropdown-menu .active > a {
  background-color: #039be5;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-600.navbar .dropdown-menu .active > a:hover,
.navbar-material-light-blue-600.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-700.navbar {
  background-color: #0288d1;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-light-blue-700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-light-blue-700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-light-blue-700.navbar .dropdown-menu li > a:hover,
.navbar-material-light-blue-700.navbar .dropdown-menu li > a:focus {
  color: #0288d1;
  background-color: #eeeeee;
}
.navbar-material-light-blue-700.navbar .dropdown-menu .active > a {
  background-color: #0288d1;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-700.navbar .dropdown-menu .active > a:hover,
.navbar-material-light-blue-700.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-800.navbar {
  background-color: #0277bd;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-800.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-light-blue-800.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-800.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-light-blue-800.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-light-blue-800.navbar .dropdown-menu li > a:hover,
.navbar-material-light-blue-800.navbar .dropdown-menu li > a:focus {
  color: #0277bd;
  background-color: #eeeeee;
}
.navbar-material-light-blue-800.navbar .dropdown-menu .active > a {
  background-color: #0277bd;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-800.navbar .dropdown-menu .active > a:hover,
.navbar-material-light-blue-800.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-900.navbar {
  background-color: #01579b;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-900.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-light-blue-900.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-900.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-light-blue-900.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-light-blue-900.navbar .dropdown-menu li > a:hover,
.navbar-material-light-blue-900.navbar .dropdown-menu li > a:focus {
  color: #01579b;
  background-color: #eeeeee;
}
.navbar-material-light-blue-900.navbar .dropdown-menu .active > a {
  background-color: #01579b;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-900.navbar .dropdown-menu .active > a:hover,
.navbar-material-light-blue-900.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-A100.navbar {
  background-color: #80d8ff;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-A100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-light-blue-A100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-A100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-light-blue-A100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-light-blue-A100.navbar .dropdown-menu li > a:hover,
.navbar-material-light-blue-A100.navbar .dropdown-menu li > a:focus {
  color: #80d8ff;
  background-color: #eeeeee;
}
.navbar-material-light-blue-A100.navbar .dropdown-menu .active > a {
  background-color: #80d8ff;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-A100.navbar .dropdown-menu .active > a:hover,
.navbar-material-light-blue-A100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-A200.navbar {
  background-color: #40c4ff;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-A200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-light-blue-A200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-A200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-light-blue-A200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-light-blue-A200.navbar .dropdown-menu li > a:hover,
.navbar-material-light-blue-A200.navbar .dropdown-menu li > a:focus {
  color: #40c4ff;
  background-color: #eeeeee;
}
.navbar-material-light-blue-A200.navbar .dropdown-menu .active > a {
  background-color: #40c4ff;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-A200.navbar .dropdown-menu .active > a:hover,
.navbar-material-light-blue-A200.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-blue-A400.navbar {
  background-color: #00b0ff;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-A400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-light-blue-A400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-A400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-light-blue-A400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-light-blue-A400.navbar .dropdown-menu li > a:hover,
.navbar-material-light-blue-A400.navbar .dropdown-menu li > a:focus {
  color: #00b0ff;
  background-color: #eeeeee;
}
.navbar-material-light-blue-A400.navbar .dropdown-menu .active > a {
  background-color: #00b0ff;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-A400.navbar .dropdown-menu .active > a:hover,
.navbar-material-light-blue-A400.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-A700.navbar {
  background-color: #0091ea;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-A700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-light-blue-A700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-A700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-light-blue-A700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-light-blue-A700.navbar .dropdown-menu li > a:hover,
.navbar-material-light-blue-A700.navbar .dropdown-menu li > a:focus {
  color: #0091ea;
  background-color: #eeeeee;
}
.navbar-material-light-blue-A700.navbar .dropdown-menu .active > a {
  background-color: #0091ea;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-blue-A700.navbar .dropdown-menu .active > a:hover,
.navbar-material-light-blue-A700.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-cyan.navbar {
  background-color: #00bcd4;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-cyan.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-cyan.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-cyan.navbar .dropdown-menu li > a:hover,
.navbar-material-cyan.navbar .dropdown-menu li > a:focus {
  color: #00bcd4;
  background-color: #eeeeee;
}
.navbar-material-cyan.navbar .dropdown-menu .active > a {
  background-color: #00bcd4;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan.navbar .dropdown-menu .active > a:hover,
.navbar-material-cyan.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-50.navbar {
  background-color: #e0f7fa;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-50.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-cyan-50.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-50.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-cyan-50.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-cyan-50.navbar .dropdown-menu li > a:hover,
.navbar-material-cyan-50.navbar .dropdown-menu li > a:focus {
  color: #e0f7fa;
  background-color: #eeeeee;
}
.navbar-material-cyan-50.navbar .dropdown-menu .active > a {
  background-color: #e0f7fa;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-50.navbar .dropdown-menu .active > a:hover,
.navbar-material-cyan-50.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-100.navbar {
  background-color: #b2ebf2;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-cyan-100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-cyan-100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-cyan-100.navbar .dropdown-menu li > a:hover,
.navbar-material-cyan-100.navbar .dropdown-menu li > a:focus {
  color: #b2ebf2;
  background-color: #eeeeee;
}
.navbar-material-cyan-100.navbar .dropdown-menu .active > a {
  background-color: #b2ebf2;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-100.navbar .dropdown-menu .active > a:hover,
.navbar-material-cyan-100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-200.navbar {
  background-color: #80deea;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-cyan-200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-cyan-200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-cyan-200.navbar .dropdown-menu li > a:hover,
.navbar-material-cyan-200.navbar .dropdown-menu li > a:focus {
  color: #80deea;
  background-color: #eeeeee;
}
.navbar-material-cyan-200.navbar .dropdown-menu .active > a {
  background-color: #80deea;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-200.navbar .dropdown-menu .active > a:hover,
.navbar-material-cyan-200.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-300.navbar {
  background-color: #4dd0e1;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-300.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-cyan-300.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-300.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-cyan-300.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-cyan-300.navbar .dropdown-menu li > a:hover,
.navbar-material-cyan-300.navbar .dropdown-menu li > a:focus {
  color: #4dd0e1;
  background-color: #eeeeee;
}
.navbar-material-cyan-300.navbar .dropdown-menu .active > a {
  background-color: #4dd0e1;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-300.navbar .dropdown-menu .active > a:hover,
.navbar-material-cyan-300.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-400.navbar {
  background-color: #26c6da;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-cyan-400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-cyan-400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-cyan-400.navbar .dropdown-menu li > a:hover,
.navbar-material-cyan-400.navbar .dropdown-menu li > a:focus {
  color: #26c6da;
  background-color: #eeeeee;
}
.navbar-material-cyan-400.navbar .dropdown-menu .active > a {
  background-color: #26c6da;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-400.navbar .dropdown-menu .active > a:hover,
.navbar-material-cyan-400.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-500.navbar {
  background-color: #00bcd4;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-500.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-cyan-500.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-500.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-cyan-500.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-cyan-500.navbar .dropdown-menu li > a:hover,
.navbar-material-cyan-500.navbar .dropdown-menu li > a:focus {
  color: #00bcd4;
  background-color: #eeeeee;
}
.navbar-material-cyan-500.navbar .dropdown-menu .active > a {
  background-color: #00bcd4;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-500.navbar .dropdown-menu .active > a:hover,
.navbar-material-cyan-500.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-600.navbar {
  background-color: #00acc1;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-cyan-600.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-cyan-600.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-cyan-600.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-cyan-600.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-cyan-600.navbar .dropdown-menu li > a:hover,
.navbar-material-cyan-600.navbar .dropdown-menu li > a:focus {
  color: #00acc1;
  background-color: #eeeeee;
}
.navbar-material-cyan-600.navbar .dropdown-menu .active > a {
  background-color: #00acc1;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-cyan-600.navbar .dropdown-menu .active > a:hover,
.navbar-material-cyan-600.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-cyan-700.navbar {
  background-color: #0097a7;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-cyan-700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-cyan-700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-cyan-700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-cyan-700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-cyan-700.navbar .dropdown-menu li > a:hover,
.navbar-material-cyan-700.navbar .dropdown-menu li > a:focus {
  color: #0097a7;
  background-color: #eeeeee;
}
.navbar-material-cyan-700.navbar .dropdown-menu .active > a {
  background-color: #0097a7;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-cyan-700.navbar .dropdown-menu .active > a:hover,
.navbar-material-cyan-700.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-cyan-800.navbar {
  background-color: #00838f;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-cyan-800.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-cyan-800.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-cyan-800.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-cyan-800.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-cyan-800.navbar .dropdown-menu li > a:hover,
.navbar-material-cyan-800.navbar .dropdown-menu li > a:focus {
  color: #00838f;
  background-color: #eeeeee;
}
.navbar-material-cyan-800.navbar .dropdown-menu .active > a {
  background-color: #00838f;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-cyan-800.navbar .dropdown-menu .active > a:hover,
.navbar-material-cyan-800.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-cyan-900.navbar {
  background-color: #006064;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-cyan-900.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-cyan-900.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-cyan-900.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-cyan-900.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-cyan-900.navbar .dropdown-menu li > a:hover,
.navbar-material-cyan-900.navbar .dropdown-menu li > a:focus {
  color: #006064;
  background-color: #eeeeee;
}
.navbar-material-cyan-900.navbar .dropdown-menu .active > a {
  background-color: #006064;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-cyan-900.navbar .dropdown-menu .active > a:hover,
.navbar-material-cyan-900.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-cyan-A100.navbar {
  background-color: #84ffff;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-A100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-cyan-A100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-A100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-cyan-A100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-cyan-A100.navbar .dropdown-menu li > a:hover,
.navbar-material-cyan-A100.navbar .dropdown-menu li > a:focus {
  color: #84ffff;
  background-color: #eeeeee;
}
.navbar-material-cyan-A100.navbar .dropdown-menu .active > a {
  background-color: #84ffff;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-A100.navbar .dropdown-menu .active > a:hover,
.navbar-material-cyan-A100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-A200.navbar {
  background-color: #18ffff;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-A200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-cyan-A200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-A200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-cyan-A200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-cyan-A200.navbar .dropdown-menu li > a:hover,
.navbar-material-cyan-A200.navbar .dropdown-menu li > a:focus {
  color: #18ffff;
  background-color: #eeeeee;
}
.navbar-material-cyan-A200.navbar .dropdown-menu .active > a {
  background-color: #18ffff;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-A200.navbar .dropdown-menu .active > a:hover,
.navbar-material-cyan-A200.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-A400.navbar {
  background-color: #00e5ff;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-A400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-cyan-A400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-A400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-cyan-A400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-cyan-A400.navbar .dropdown-menu li > a:hover,
.navbar-material-cyan-A400.navbar .dropdown-menu li > a:focus {
  color: #00e5ff;
  background-color: #eeeeee;
}
.navbar-material-cyan-A400.navbar .dropdown-menu .active > a {
  background-color: #00e5ff;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-A400.navbar .dropdown-menu .active > a:hover,
.navbar-material-cyan-A400.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-cyan-A700.navbar {
  background-color: #00b8d4;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-cyan-A700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-cyan-A700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-cyan-A700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-cyan-A700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-cyan-A700.navbar .dropdown-menu li > a:hover,
.navbar-material-cyan-A700.navbar .dropdown-menu li > a:focus {
  color: #00b8d4;
  background-color: #eeeeee;
}
.navbar-material-cyan-A700.navbar .dropdown-menu .active > a {
  background-color: #00b8d4;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-cyan-A700.navbar .dropdown-menu .active > a:hover,
.navbar-material-cyan-A700.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal.navbar {
  background-color: #009688;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-teal.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-teal.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-teal.navbar .dropdown-menu li > a:hover,
.navbar-material-teal.navbar .dropdown-menu li > a:focus {
  color: #009688;
  background-color: #eeeeee;
}
.navbar-material-teal.navbar .dropdown-menu .active > a {
  background-color: #009688;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal.navbar .dropdown-menu .active > a:hover,
.navbar-material-teal.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-50.navbar {
  background-color: #e0f2f1;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-50.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-teal-50.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-50.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-teal-50.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-teal-50.navbar .dropdown-menu li > a:hover,
.navbar-material-teal-50.navbar .dropdown-menu li > a:focus {
  color: #e0f2f1;
  background-color: #eeeeee;
}
.navbar-material-teal-50.navbar .dropdown-menu .active > a {
  background-color: #e0f2f1;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-50.navbar .dropdown-menu .active > a:hover,
.navbar-material-teal-50.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-100.navbar {
  background-color: #b2dfdb;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-teal-100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-teal-100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-teal-100.navbar .dropdown-menu li > a:hover,
.navbar-material-teal-100.navbar .dropdown-menu li > a:focus {
  color: #b2dfdb;
  background-color: #eeeeee;
}
.navbar-material-teal-100.navbar .dropdown-menu .active > a {
  background-color: #b2dfdb;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-100.navbar .dropdown-menu .active > a:hover,
.navbar-material-teal-100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-200.navbar {
  background-color: #80cbc4;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-teal-200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-teal-200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-teal-200.navbar .dropdown-menu li > a:hover,
.navbar-material-teal-200.navbar .dropdown-menu li > a:focus {
  color: #80cbc4;
  background-color: #eeeeee;
}
.navbar-material-teal-200.navbar .dropdown-menu .active > a {
  background-color: #80cbc4;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-200.navbar .dropdown-menu .active > a:hover,
.navbar-material-teal-200.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-300.navbar {
  background-color: #4db6ac;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-300.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-teal-300.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-300.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-teal-300.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-teal-300.navbar .dropdown-menu li > a:hover,
.navbar-material-teal-300.navbar .dropdown-menu li > a:focus {
  color: #4db6ac;
  background-color: #eeeeee;
}
.navbar-material-teal-300.navbar .dropdown-menu .active > a {
  background-color: #4db6ac;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-300.navbar .dropdown-menu .active > a:hover,
.navbar-material-teal-300.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-400.navbar {
  background-color: #26a69a;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-teal-400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-teal-400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-teal-400.navbar .dropdown-menu li > a:hover,
.navbar-material-teal-400.navbar .dropdown-menu li > a:focus {
  color: #26a69a;
  background-color: #eeeeee;
}
.navbar-material-teal-400.navbar .dropdown-menu .active > a {
  background-color: #26a69a;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-400.navbar .dropdown-menu .active > a:hover,
.navbar-material-teal-400.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-500.navbar {
  background-color: #009688;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-500.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-teal-500.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-500.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-teal-500.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-teal-500.navbar .dropdown-menu li > a:hover,
.navbar-material-teal-500.navbar .dropdown-menu li > a:focus {
  color: #009688;
  background-color: #eeeeee;
}
.navbar-material-teal-500.navbar .dropdown-menu .active > a {
  background-color: #009688;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-500.navbar .dropdown-menu .active > a:hover,
.navbar-material-teal-500.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-600.navbar {
  background-color: #00897b;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-600.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-teal-600.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-600.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-teal-600.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-teal-600.navbar .dropdown-menu li > a:hover,
.navbar-material-teal-600.navbar .dropdown-menu li > a:focus {
  color: #00897b;
  background-color: #eeeeee;
}
.navbar-material-teal-600.navbar .dropdown-menu .active > a {
  background-color: #00897b;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-600.navbar .dropdown-menu .active > a:hover,
.navbar-material-teal-600.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-700.navbar {
  background-color: #00796b;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-teal-700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-teal-700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-teal-700.navbar .dropdown-menu li > a:hover,
.navbar-material-teal-700.navbar .dropdown-menu li > a:focus {
  color: #00796b;
  background-color: #eeeeee;
}
.navbar-material-teal-700.navbar .dropdown-menu .active > a {
  background-color: #00796b;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-700.navbar .dropdown-menu .active > a:hover,
.navbar-material-teal-700.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-800.navbar {
  background-color: #00695c;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-800.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-teal-800.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-800.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-teal-800.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-teal-800.navbar .dropdown-menu li > a:hover,
.navbar-material-teal-800.navbar .dropdown-menu li > a:focus {
  color: #00695c;
  background-color: #eeeeee;
}
.navbar-material-teal-800.navbar .dropdown-menu .active > a {
  background-color: #00695c;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-800.navbar .dropdown-menu .active > a:hover,
.navbar-material-teal-800.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-900.navbar {
  background-color: #004d40;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-900.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-teal-900.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-900.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-teal-900.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-teal-900.navbar .dropdown-menu li > a:hover,
.navbar-material-teal-900.navbar .dropdown-menu li > a:focus {
  color: #004d40;
  background-color: #eeeeee;
}
.navbar-material-teal-900.navbar .dropdown-menu .active > a {
  background-color: #004d40;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-900.navbar .dropdown-menu .active > a:hover,
.navbar-material-teal-900.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-A100.navbar {
  background-color: #a7ffeb;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-A100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-teal-A100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-A100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-teal-A100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-teal-A100.navbar .dropdown-menu li > a:hover,
.navbar-material-teal-A100.navbar .dropdown-menu li > a:focus {
  color: #a7ffeb;
  background-color: #eeeeee;
}
.navbar-material-teal-A100.navbar .dropdown-menu .active > a {
  background-color: #a7ffeb;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-A100.navbar .dropdown-menu .active > a:hover,
.navbar-material-teal-A100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-A200.navbar {
  background-color: #64ffda;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-A200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-teal-A200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-A200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-teal-A200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-teal-A200.navbar .dropdown-menu li > a:hover,
.navbar-material-teal-A200.navbar .dropdown-menu li > a:focus {
  color: #64ffda;
  background-color: #eeeeee;
}
.navbar-material-teal-A200.navbar .dropdown-menu .active > a {
  background-color: #64ffda;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-A200.navbar .dropdown-menu .active > a:hover,
.navbar-material-teal-A200.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-A400.navbar {
  background-color: #1de9b6;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-A400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-teal-A400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-A400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-teal-A400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-teal-A400.navbar .dropdown-menu li > a:hover,
.navbar-material-teal-A400.navbar .dropdown-menu li > a:focus {
  color: #1de9b6;
  background-color: #eeeeee;
}
.navbar-material-teal-A400.navbar .dropdown-menu .active > a {
  background-color: #1de9b6;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-A400.navbar .dropdown-menu .active > a:hover,
.navbar-material-teal-A400.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-teal-A700.navbar {
  background-color: #00bfa5;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-A700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-teal-A700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-A700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-teal-A700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-teal-A700.navbar .dropdown-menu li > a:hover,
.navbar-material-teal-A700.navbar .dropdown-menu li > a:focus {
  color: #00bfa5;
  background-color: #eeeeee;
}
.navbar-material-teal-A700.navbar .dropdown-menu .active > a {
  background-color: #00bfa5;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-teal-A700.navbar .dropdown-menu .active > a:hover,
.navbar-material-teal-A700.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green.navbar {
  background-color: #4caf50;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-green.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-green.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-green.navbar .dropdown-menu li > a:hover,
.navbar-material-green.navbar .dropdown-menu li > a:focus {
  color: #4caf50;
  background-color: #eeeeee;
}
.navbar-material-green.navbar .dropdown-menu .active > a {
  background-color: #4caf50;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green.navbar .dropdown-menu .active > a:hover,
.navbar-material-green.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-50.navbar {
  background-color: #e8f5e9;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-50.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-green-50.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-50.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-green-50.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-green-50.navbar .dropdown-menu li > a:hover,
.navbar-material-green-50.navbar .dropdown-menu li > a:focus {
  color: #e8f5e9;
  background-color: #eeeeee;
}
.navbar-material-green-50.navbar .dropdown-menu .active > a {
  background-color: #e8f5e9;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-50.navbar .dropdown-menu .active > a:hover,
.navbar-material-green-50.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-100.navbar {
  background-color: #c8e6c9;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-green-100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-green-100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-green-100.navbar .dropdown-menu li > a:hover,
.navbar-material-green-100.navbar .dropdown-menu li > a:focus {
  color: #c8e6c9;
  background-color: #eeeeee;
}
.navbar-material-green-100.navbar .dropdown-menu .active > a {
  background-color: #c8e6c9;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-100.navbar .dropdown-menu .active > a:hover,
.navbar-material-green-100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-200.navbar {
  background-color: #a5d6a7;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-green-200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-green-200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-green-200.navbar .dropdown-menu li > a:hover,
.navbar-material-green-200.navbar .dropdown-menu li > a:focus {
  color: #a5d6a7;
  background-color: #eeeeee;
}
.navbar-material-green-200.navbar .dropdown-menu .active > a {
  background-color: #a5d6a7;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-200.navbar .dropdown-menu .active > a:hover,
.navbar-material-green-200.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-300.navbar {
  background-color: #81c784;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-300.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-green-300.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-300.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-green-300.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-green-300.navbar .dropdown-menu li > a:hover,
.navbar-material-green-300.navbar .dropdown-menu li > a:focus {
  color: #81c784;
  background-color: #eeeeee;
}
.navbar-material-green-300.navbar .dropdown-menu .active > a {
  background-color: #81c784;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-300.navbar .dropdown-menu .active > a:hover,
.navbar-material-green-300.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-400.navbar {
  background-color: #66bb6a;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-green-400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-green-400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-green-400.navbar .dropdown-menu li > a:hover,
.navbar-material-green-400.navbar .dropdown-menu li > a:focus {
  color: #66bb6a;
  background-color: #eeeeee;
}
.navbar-material-green-400.navbar .dropdown-menu .active > a {
  background-color: #66bb6a;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-400.navbar .dropdown-menu .active > a:hover,
.navbar-material-green-400.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-500.navbar {
  background-color: #4caf50;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-500.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-green-500.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-500.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-green-500.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-green-500.navbar .dropdown-menu li > a:hover,
.navbar-material-green-500.navbar .dropdown-menu li > a:focus {
  color: #4caf50;
  background-color: #eeeeee;
}
.navbar-material-green-500.navbar .dropdown-menu .active > a {
  background-color: #4caf50;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-500.navbar .dropdown-menu .active > a:hover,
.navbar-material-green-500.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-600.navbar {
  background-color: #43a047;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-600.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-green-600.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-600.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-green-600.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-green-600.navbar .dropdown-menu li > a:hover,
.navbar-material-green-600.navbar .dropdown-menu li > a:focus {
  color: #43a047;
  background-color: #eeeeee;
}
.navbar-material-green-600.navbar .dropdown-menu .active > a {
  background-color: #43a047;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-600.navbar .dropdown-menu .active > a:hover,
.navbar-material-green-600.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-700.navbar {
  background-color: #388e3c;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-green-700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-green-700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-green-700.navbar .dropdown-menu li > a:hover,
.navbar-material-green-700.navbar .dropdown-menu li > a:focus {
  color: #388e3c;
  background-color: #eeeeee;
}
.navbar-material-green-700.navbar .dropdown-menu .active > a {
  background-color: #388e3c;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-700.navbar .dropdown-menu .active > a:hover,
.navbar-material-green-700.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-800.navbar {
  background-color: #2e7d32;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-800.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-green-800.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-800.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-green-800.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-green-800.navbar .dropdown-menu li > a:hover,
.navbar-material-green-800.navbar .dropdown-menu li > a:focus {
  color: #2e7d32;
  background-color: #eeeeee;
}
.navbar-material-green-800.navbar .dropdown-menu .active > a {
  background-color: #2e7d32;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-800.navbar .dropdown-menu .active > a:hover,
.navbar-material-green-800.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-900.navbar {
  background-color: #1b5e20;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-900.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-green-900.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-900.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-green-900.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-green-900.navbar .dropdown-menu li > a:hover,
.navbar-material-green-900.navbar .dropdown-menu li > a:focus {
  color: #1b5e20;
  background-color: #eeeeee;
}
.navbar-material-green-900.navbar .dropdown-menu .active > a {
  background-color: #1b5e20;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-900.navbar .dropdown-menu .active > a:hover,
.navbar-material-green-900.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-green-A100.navbar {
  background-color: #b9f6ca;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-A100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-green-A100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-A100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-green-A100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-green-A100.navbar .dropdown-menu li > a:hover,
.navbar-material-green-A100.navbar .dropdown-menu li > a:focus {
  color: #b9f6ca;
  background-color: #eeeeee;
}
.navbar-material-green-A100.navbar .dropdown-menu .active > a {
  background-color: #b9f6ca;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-A100.navbar .dropdown-menu .active > a:hover,
.navbar-material-green-A100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-A200.navbar {
  background-color: #69f0ae;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-A200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-green-A200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-A200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-green-A200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-green-A200.navbar .dropdown-menu li > a:hover,
.navbar-material-green-A200.navbar .dropdown-menu li > a:focus {
  color: #69f0ae;
  background-color: #eeeeee;
}
.navbar-material-green-A200.navbar .dropdown-menu .active > a {
  background-color: #69f0ae;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-A200.navbar .dropdown-menu .active > a:hover,
.navbar-material-green-A200.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-A400.navbar {
  background-color: #00e676;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-A400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-green-A400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-A400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-green-A400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-green-A400.navbar .dropdown-menu li > a:hover,
.navbar-material-green-A400.navbar .dropdown-menu li > a:focus {
  color: #00e676;
  background-color: #eeeeee;
}
.navbar-material-green-A400.navbar .dropdown-menu .active > a {
  background-color: #00e676;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-A400.navbar .dropdown-menu .active > a:hover,
.navbar-material-green-A400.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-A700.navbar {
  background-color: #00c853;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-A700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-green-A700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-A700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-green-A700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-green-A700.navbar .dropdown-menu li > a:hover,
.navbar-material-green-A700.navbar .dropdown-menu li > a:focus {
  color: #00c853;
  background-color: #eeeeee;
}
.navbar-material-green-A700.navbar .dropdown-menu .active > a {
  background-color: #00c853;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-green-A700.navbar .dropdown-menu .active > a:hover,
.navbar-material-green-A700.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green.navbar {
  background-color: #8bc34a;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-light-green.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-light-green.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-light-green.navbar .dropdown-menu li > a:hover,
.navbar-material-light-green.navbar .dropdown-menu li > a:focus {
  color: #8bc34a;
  background-color: #eeeeee;
}
.navbar-material-light-green.navbar .dropdown-menu .active > a {
  background-color: #8bc34a;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green.navbar .dropdown-menu .active > a:hover,
.navbar-material-light-green.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-50.navbar {
  background-color: #f1f8e9;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-50.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-light-green-50.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-50.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-light-green-50.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-light-green-50.navbar .dropdown-menu li > a:hover,
.navbar-material-light-green-50.navbar .dropdown-menu li > a:focus {
  color: #f1f8e9;
  background-color: #eeeeee;
}
.navbar-material-light-green-50.navbar .dropdown-menu .active > a {
  background-color: #f1f8e9;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-50.navbar .dropdown-menu .active > a:hover,
.navbar-material-light-green-50.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-100.navbar {
  background-color: #dcedc8;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-light-green-100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-light-green-100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-light-green-100.navbar .dropdown-menu li > a:hover,
.navbar-material-light-green-100.navbar .dropdown-menu li > a:focus {
  color: #dcedc8;
  background-color: #eeeeee;
}
.navbar-material-light-green-100.navbar .dropdown-menu .active > a {
  background-color: #dcedc8;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-100.navbar .dropdown-menu .active > a:hover,
.navbar-material-light-green-100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-200.navbar {
  background-color: #c5e1a5;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-light-green-200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-light-green-200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-light-green-200.navbar .dropdown-menu li > a:hover,
.navbar-material-light-green-200.navbar .dropdown-menu li > a:focus {
  color: #c5e1a5;
  background-color: #eeeeee;
}
.navbar-material-light-green-200.navbar .dropdown-menu .active > a {
  background-color: #c5e1a5;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-200.navbar .dropdown-menu .active > a:hover,
.navbar-material-light-green-200.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-300.navbar {
  background-color: #aed581;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-300.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-light-green-300.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-300.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-light-green-300.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-light-green-300.navbar .dropdown-menu li > a:hover,
.navbar-material-light-green-300.navbar .dropdown-menu li > a:focus {
  color: #aed581;
  background-color: #eeeeee;
}
.navbar-material-light-green-300.navbar .dropdown-menu .active > a {
  background-color: #aed581;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-300.navbar .dropdown-menu .active > a:hover,
.navbar-material-light-green-300.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-400.navbar {
  background-color: #9ccc65;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-light-green-400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-light-green-400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-light-green-400.navbar .dropdown-menu li > a:hover,
.navbar-material-light-green-400.navbar .dropdown-menu li > a:focus {
  color: #9ccc65;
  background-color: #eeeeee;
}
.navbar-material-light-green-400.navbar .dropdown-menu .active > a {
  background-color: #9ccc65;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-400.navbar .dropdown-menu .active > a:hover,
.navbar-material-light-green-400.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-500.navbar {
  background-color: #8bc34a;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-500.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-light-green-500.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-500.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-light-green-500.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-light-green-500.navbar .dropdown-menu li > a:hover,
.navbar-material-light-green-500.navbar .dropdown-menu li > a:focus {
  color: #8bc34a;
  background-color: #eeeeee;
}
.navbar-material-light-green-500.navbar .dropdown-menu .active > a {
  background-color: #8bc34a;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-500.navbar .dropdown-menu .active > a:hover,
.navbar-material-light-green-500.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-600.navbar {
  background-color: #7cb342;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-green-600.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-light-green-600.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-green-600.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-light-green-600.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-light-green-600.navbar .dropdown-menu li > a:hover,
.navbar-material-light-green-600.navbar .dropdown-menu li > a:focus {
  color: #7cb342;
  background-color: #eeeeee;
}
.navbar-material-light-green-600.navbar .dropdown-menu .active > a {
  background-color: #7cb342;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-green-600.navbar .dropdown-menu .active > a:hover,
.navbar-material-light-green-600.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-green-700.navbar {
  background-color: #689f38;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-green-700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-light-green-700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-green-700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-light-green-700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-light-green-700.navbar .dropdown-menu li > a:hover,
.navbar-material-light-green-700.navbar .dropdown-menu li > a:focus {
  color: #689f38;
  background-color: #eeeeee;
}
.navbar-material-light-green-700.navbar .dropdown-menu .active > a {
  background-color: #689f38;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-green-700.navbar .dropdown-menu .active > a:hover,
.navbar-material-light-green-700.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-green-800.navbar {
  background-color: #558b2f;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-green-800.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-light-green-800.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-green-800.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-light-green-800.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-light-green-800.navbar .dropdown-menu li > a:hover,
.navbar-material-light-green-800.navbar .dropdown-menu li > a:focus {
  color: #558b2f;
  background-color: #eeeeee;
}
.navbar-material-light-green-800.navbar .dropdown-menu .active > a {
  background-color: #558b2f;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-green-800.navbar .dropdown-menu .active > a:hover,
.navbar-material-light-green-800.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-green-900.navbar {
  background-color: #33691e;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-green-900.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-light-green-900.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-green-900.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-light-green-900.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-light-green-900.navbar .dropdown-menu li > a:hover,
.navbar-material-light-green-900.navbar .dropdown-menu li > a:focus {
  color: #33691e;
  background-color: #eeeeee;
}
.navbar-material-light-green-900.navbar .dropdown-menu .active > a {
  background-color: #33691e;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-green-900.navbar .dropdown-menu .active > a:hover,
.navbar-material-light-green-900.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-light-green-A100.navbar {
  background-color: #ccff90;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-A100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-light-green-A100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-A100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-light-green-A100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-light-green-A100.navbar .dropdown-menu li > a:hover,
.navbar-material-light-green-A100.navbar .dropdown-menu li > a:focus {
  color: #ccff90;
  background-color: #eeeeee;
}
.navbar-material-light-green-A100.navbar .dropdown-menu .active > a {
  background-color: #ccff90;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-A100.navbar .dropdown-menu .active > a:hover,
.navbar-material-light-green-A100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-A200.navbar {
  background-color: #b2ff59;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-A200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-light-green-A200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-A200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-light-green-A200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-light-green-A200.navbar .dropdown-menu li > a:hover,
.navbar-material-light-green-A200.navbar .dropdown-menu li > a:focus {
  color: #b2ff59;
  background-color: #eeeeee;
}
.navbar-material-light-green-A200.navbar .dropdown-menu .active > a {
  background-color: #b2ff59;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-A200.navbar .dropdown-menu .active > a:hover,
.navbar-material-light-green-A200.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-A400.navbar {
  background-color: #76ff03;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-A400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-light-green-A400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-A400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-light-green-A400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-light-green-A400.navbar .dropdown-menu li > a:hover,
.navbar-material-light-green-A400.navbar .dropdown-menu li > a:focus {
  color: #76ff03;
  background-color: #eeeeee;
}
.navbar-material-light-green-A400.navbar .dropdown-menu .active > a {
  background-color: #76ff03;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-A400.navbar .dropdown-menu .active > a:hover,
.navbar-material-light-green-A400.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-A700.navbar {
  background-color: #64dd17;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-A700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-light-green-A700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-A700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-light-green-A700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-light-green-A700.navbar .dropdown-menu li > a:hover,
.navbar-material-light-green-A700.navbar .dropdown-menu li > a:focus {
  color: #64dd17;
  background-color: #eeeeee;
}
.navbar-material-light-green-A700.navbar .dropdown-menu .active > a {
  background-color: #64dd17;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-light-green-A700.navbar .dropdown-menu .active > a:hover,
.navbar-material-light-green-A700.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime.navbar {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-lime.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-lime.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-lime.navbar .dropdown-menu li > a:hover,
.navbar-material-lime.navbar .dropdown-menu li > a:focus {
  color: #cddc39;
  background-color: #eeeeee;
}
.navbar-material-lime.navbar .dropdown-menu .active > a {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime.navbar .dropdown-menu .active > a:hover,
.navbar-material-lime.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-50.navbar {
  background-color: #f9fbe7;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-50.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-lime-50.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-50.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-lime-50.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-lime-50.navbar .dropdown-menu li > a:hover,
.navbar-material-lime-50.navbar .dropdown-menu li > a:focus {
  color: #f9fbe7;
  background-color: #eeeeee;
}
.navbar-material-lime-50.navbar .dropdown-menu .active > a {
  background-color: #f9fbe7;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-50.navbar .dropdown-menu .active > a:hover,
.navbar-material-lime-50.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-100.navbar {
  background-color: #f0f4c3;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-lime-100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-lime-100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-lime-100.navbar .dropdown-menu li > a:hover,
.navbar-material-lime-100.navbar .dropdown-menu li > a:focus {
  color: #f0f4c3;
  background-color: #eeeeee;
}
.navbar-material-lime-100.navbar .dropdown-menu .active > a {
  background-color: #f0f4c3;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-100.navbar .dropdown-menu .active > a:hover,
.navbar-material-lime-100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-200.navbar {
  background-color: #e6ee9c;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-lime-200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-lime-200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-lime-200.navbar .dropdown-menu li > a:hover,
.navbar-material-lime-200.navbar .dropdown-menu li > a:focus {
  color: #e6ee9c;
  background-color: #eeeeee;
}
.navbar-material-lime-200.navbar .dropdown-menu .active > a {
  background-color: #e6ee9c;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-200.navbar .dropdown-menu .active > a:hover,
.navbar-material-lime-200.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-300.navbar {
  background-color: #dce775;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-300.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-lime-300.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-300.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-lime-300.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-lime-300.navbar .dropdown-menu li > a:hover,
.navbar-material-lime-300.navbar .dropdown-menu li > a:focus {
  color: #dce775;
  background-color: #eeeeee;
}
.navbar-material-lime-300.navbar .dropdown-menu .active > a {
  background-color: #dce775;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-300.navbar .dropdown-menu .active > a:hover,
.navbar-material-lime-300.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-400.navbar {
  background-color: #d4e157;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-lime-400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-lime-400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-lime-400.navbar .dropdown-menu li > a:hover,
.navbar-material-lime-400.navbar .dropdown-menu li > a:focus {
  color: #d4e157;
  background-color: #eeeeee;
}
.navbar-material-lime-400.navbar .dropdown-menu .active > a {
  background-color: #d4e157;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-400.navbar .dropdown-menu .active > a:hover,
.navbar-material-lime-400.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-500.navbar {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-500.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-lime-500.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-500.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-lime-500.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-lime-500.navbar .dropdown-menu li > a:hover,
.navbar-material-lime-500.navbar .dropdown-menu li > a:focus {
  color: #cddc39;
  background-color: #eeeeee;
}
.navbar-material-lime-500.navbar .dropdown-menu .active > a {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-500.navbar .dropdown-menu .active > a:hover,
.navbar-material-lime-500.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-600.navbar {
  background-color: #c0ca33;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-600.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-lime-600.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-600.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-lime-600.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-lime-600.navbar .dropdown-menu li > a:hover,
.navbar-material-lime-600.navbar .dropdown-menu li > a:focus {
  color: #c0ca33;
  background-color: #eeeeee;
}
.navbar-material-lime-600.navbar .dropdown-menu .active > a {
  background-color: #c0ca33;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-600.navbar .dropdown-menu .active > a:hover,
.navbar-material-lime-600.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-700.navbar {
  background-color: #afb42b;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-lime-700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-lime-700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-lime-700.navbar .dropdown-menu li > a:hover,
.navbar-material-lime-700.navbar .dropdown-menu li > a:focus {
  color: #afb42b;
  background-color: #eeeeee;
}
.navbar-material-lime-700.navbar .dropdown-menu .active > a {
  background-color: #afb42b;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-700.navbar .dropdown-menu .active > a:hover,
.navbar-material-lime-700.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-800.navbar {
  background-color: #9e9d24;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-lime-800.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-lime-800.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-lime-800.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-lime-800.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-lime-800.navbar .dropdown-menu li > a:hover,
.navbar-material-lime-800.navbar .dropdown-menu li > a:focus {
  color: #9e9d24;
  background-color: #eeeeee;
}
.navbar-material-lime-800.navbar .dropdown-menu .active > a {
  background-color: #9e9d24;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-lime-800.navbar .dropdown-menu .active > a:hover,
.navbar-material-lime-800.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-lime-900.navbar {
  background-color: #827717;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-lime-900.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-lime-900.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-lime-900.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-lime-900.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-lime-900.navbar .dropdown-menu li > a:hover,
.navbar-material-lime-900.navbar .dropdown-menu li > a:focus {
  color: #827717;
  background-color: #eeeeee;
}
.navbar-material-lime-900.navbar .dropdown-menu .active > a {
  background-color: #827717;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-lime-900.navbar .dropdown-menu .active > a:hover,
.navbar-material-lime-900.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-lime-A100.navbar {
  background-color: #f4ff81;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-A100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-lime-A100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-A100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-lime-A100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-lime-A100.navbar .dropdown-menu li > a:hover,
.navbar-material-lime-A100.navbar .dropdown-menu li > a:focus {
  color: #f4ff81;
  background-color: #eeeeee;
}
.navbar-material-lime-A100.navbar .dropdown-menu .active > a {
  background-color: #f4ff81;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-A100.navbar .dropdown-menu .active > a:hover,
.navbar-material-lime-A100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-A200.navbar {
  background-color: #eeff41;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-A200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-lime-A200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-A200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-lime-A200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-lime-A200.navbar .dropdown-menu li > a:hover,
.navbar-material-lime-A200.navbar .dropdown-menu li > a:focus {
  color: #eeff41;
  background-color: #eeeeee;
}
.navbar-material-lime-A200.navbar .dropdown-menu .active > a {
  background-color: #eeff41;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-A200.navbar .dropdown-menu .active > a:hover,
.navbar-material-lime-A200.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-A400.navbar {
  background-color: #c6ff00;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-A400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-lime-A400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-A400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-lime-A400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-lime-A400.navbar .dropdown-menu li > a:hover,
.navbar-material-lime-A400.navbar .dropdown-menu li > a:focus {
  color: #c6ff00;
  background-color: #eeeeee;
}
.navbar-material-lime-A400.navbar .dropdown-menu .active > a {
  background-color: #c6ff00;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-A400.navbar .dropdown-menu .active > a:hover,
.navbar-material-lime-A400.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-A700.navbar {
  background-color: #aeea00;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-A700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-lime-A700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-A700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-lime-A700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-lime-A700.navbar .dropdown-menu li > a:hover,
.navbar-material-lime-A700.navbar .dropdown-menu li > a:focus {
  color: #aeea00;
  background-color: #eeeeee;
}
.navbar-material-lime-A700.navbar .dropdown-menu .active > a {
  background-color: #aeea00;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-lime-A700.navbar .dropdown-menu .active > a:hover,
.navbar-material-lime-A700.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow.navbar {
  background-color: #ffeb3b;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-yellow.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-yellow.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-yellow.navbar .dropdown-menu li > a:hover,
.navbar-material-yellow.navbar .dropdown-menu li > a:focus {
  color: #ffeb3b;
  background-color: #eeeeee;
}
.navbar-material-yellow.navbar .dropdown-menu .active > a {
  background-color: #ffeb3b;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow.navbar .dropdown-menu .active > a:hover,
.navbar-material-yellow.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-50.navbar {
  background-color: #fffde7;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-50.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-yellow-50.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-50.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-yellow-50.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-yellow-50.navbar .dropdown-menu li > a:hover,
.navbar-material-yellow-50.navbar .dropdown-menu li > a:focus {
  color: #fffde7;
  background-color: #eeeeee;
}
.navbar-material-yellow-50.navbar .dropdown-menu .active > a {
  background-color: #fffde7;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-50.navbar .dropdown-menu .active > a:hover,
.navbar-material-yellow-50.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-100.navbar {
  background-color: #fff9c4;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-yellow-100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-yellow-100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-yellow-100.navbar .dropdown-menu li > a:hover,
.navbar-material-yellow-100.navbar .dropdown-menu li > a:focus {
  color: #fff9c4;
  background-color: #eeeeee;
}
.navbar-material-yellow-100.navbar .dropdown-menu .active > a {
  background-color: #fff9c4;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-100.navbar .dropdown-menu .active > a:hover,
.navbar-material-yellow-100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-200.navbar {
  background-color: #fff59d;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-yellow-200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-yellow-200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-yellow-200.navbar .dropdown-menu li > a:hover,
.navbar-material-yellow-200.navbar .dropdown-menu li > a:focus {
  color: #fff59d;
  background-color: #eeeeee;
}
.navbar-material-yellow-200.navbar .dropdown-menu .active > a {
  background-color: #fff59d;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-200.navbar .dropdown-menu .active > a:hover,
.navbar-material-yellow-200.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-300.navbar {
  background-color: #fff176;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-300.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-yellow-300.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-300.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-yellow-300.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-yellow-300.navbar .dropdown-menu li > a:hover,
.navbar-material-yellow-300.navbar .dropdown-menu li > a:focus {
  color: #fff176;
  background-color: #eeeeee;
}
.navbar-material-yellow-300.navbar .dropdown-menu .active > a {
  background-color: #fff176;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-300.navbar .dropdown-menu .active > a:hover,
.navbar-material-yellow-300.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-400.navbar {
  background-color: #ffee58;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-yellow-400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-yellow-400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-yellow-400.navbar .dropdown-menu li > a:hover,
.navbar-material-yellow-400.navbar .dropdown-menu li > a:focus {
  color: #ffee58;
  background-color: #eeeeee;
}
.navbar-material-yellow-400.navbar .dropdown-menu .active > a {
  background-color: #ffee58;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-400.navbar .dropdown-menu .active > a:hover,
.navbar-material-yellow-400.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-500.navbar {
  background-color: #ffeb3b;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-500.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-yellow-500.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-500.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-yellow-500.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-yellow-500.navbar .dropdown-menu li > a:hover,
.navbar-material-yellow-500.navbar .dropdown-menu li > a:focus {
  color: #ffeb3b;
  background-color: #eeeeee;
}
.navbar-material-yellow-500.navbar .dropdown-menu .active > a {
  background-color: #ffeb3b;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-500.navbar .dropdown-menu .active > a:hover,
.navbar-material-yellow-500.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-600.navbar {
  background-color: #fdd835;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-600.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-yellow-600.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-600.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-yellow-600.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-yellow-600.navbar .dropdown-menu li > a:hover,
.navbar-material-yellow-600.navbar .dropdown-menu li > a:focus {
  color: #fdd835;
  background-color: #eeeeee;
}
.navbar-material-yellow-600.navbar .dropdown-menu .active > a {
  background-color: #fdd835;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-600.navbar .dropdown-menu .active > a:hover,
.navbar-material-yellow-600.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-700.navbar {
  background-color: #fbc02d;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-yellow-700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-yellow-700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-yellow-700.navbar .dropdown-menu li > a:hover,
.navbar-material-yellow-700.navbar .dropdown-menu li > a:focus {
  color: #fbc02d;
  background-color: #eeeeee;
}
.navbar-material-yellow-700.navbar .dropdown-menu .active > a {
  background-color: #fbc02d;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-700.navbar .dropdown-menu .active > a:hover,
.navbar-material-yellow-700.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-800.navbar {
  background-color: #f9a825;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-800.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-yellow-800.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-800.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-yellow-800.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-yellow-800.navbar .dropdown-menu li > a:hover,
.navbar-material-yellow-800.navbar .dropdown-menu li > a:focus {
  color: #f9a825;
  background-color: #eeeeee;
}
.navbar-material-yellow-800.navbar .dropdown-menu .active > a {
  background-color: #f9a825;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-800.navbar .dropdown-menu .active > a:hover,
.navbar-material-yellow-800.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-900.navbar {
  background-color: #f57f17;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-yellow-900.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-yellow-900.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-yellow-900.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-yellow-900.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-yellow-900.navbar .dropdown-menu li > a:hover,
.navbar-material-yellow-900.navbar .dropdown-menu li > a:focus {
  color: #f57f17;
  background-color: #eeeeee;
}
.navbar-material-yellow-900.navbar .dropdown-menu .active > a {
  background-color: #f57f17;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-yellow-900.navbar .dropdown-menu .active > a:hover,
.navbar-material-yellow-900.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-yellow-A100.navbar {
  background-color: #ffff8d;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-A100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-yellow-A100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-A100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-yellow-A100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-yellow-A100.navbar .dropdown-menu li > a:hover,
.navbar-material-yellow-A100.navbar .dropdown-menu li > a:focus {
  color: #ffff8d;
  background-color: #eeeeee;
}
.navbar-material-yellow-A100.navbar .dropdown-menu .active > a {
  background-color: #ffff8d;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-A100.navbar .dropdown-menu .active > a:hover,
.navbar-material-yellow-A100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-A200.navbar {
  background-color: #ffff00;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-A200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-yellow-A200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-A200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-yellow-A200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-yellow-A200.navbar .dropdown-menu li > a:hover,
.navbar-material-yellow-A200.navbar .dropdown-menu li > a:focus {
  color: #ffff00;
  background-color: #eeeeee;
}
.navbar-material-yellow-A200.navbar .dropdown-menu .active > a {
  background-color: #ffff00;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-A200.navbar .dropdown-menu .active > a:hover,
.navbar-material-yellow-A200.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-A400.navbar {
  background-color: #ffea00;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-A400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-yellow-A400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-A400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-yellow-A400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-yellow-A400.navbar .dropdown-menu li > a:hover,
.navbar-material-yellow-A400.navbar .dropdown-menu li > a:focus {
  color: #ffea00;
  background-color: #eeeeee;
}
.navbar-material-yellow-A400.navbar .dropdown-menu .active > a {
  background-color: #ffea00;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-A400.navbar .dropdown-menu .active > a:hover,
.navbar-material-yellow-A400.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-A700.navbar {
  background-color: #ffd600;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-A700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-yellow-A700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-A700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-yellow-A700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-yellow-A700.navbar .dropdown-menu li > a:hover,
.navbar-material-yellow-A700.navbar .dropdown-menu li > a:focus {
  color: #ffd600;
  background-color: #eeeeee;
}
.navbar-material-yellow-A700.navbar .dropdown-menu .active > a {
  background-color: #ffd600;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-yellow-A700.navbar .dropdown-menu .active > a:hover,
.navbar-material-yellow-A700.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber.navbar {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-amber.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-amber.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-amber.navbar .dropdown-menu li > a:hover,
.navbar-material-amber.navbar .dropdown-menu li > a:focus {
  color: #ffc107;
  background-color: #eeeeee;
}
.navbar-material-amber.navbar .dropdown-menu .active > a {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber.navbar .dropdown-menu .active > a:hover,
.navbar-material-amber.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-50.navbar {
  background-color: #fff8e1;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-50.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-amber-50.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-50.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-amber-50.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-amber-50.navbar .dropdown-menu li > a:hover,
.navbar-material-amber-50.navbar .dropdown-menu li > a:focus {
  color: #fff8e1;
  background-color: #eeeeee;
}
.navbar-material-amber-50.navbar .dropdown-menu .active > a {
  background-color: #fff8e1;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-50.navbar .dropdown-menu .active > a:hover,
.navbar-material-amber-50.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-100.navbar {
  background-color: #ffecb3;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-amber-100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-amber-100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-amber-100.navbar .dropdown-menu li > a:hover,
.navbar-material-amber-100.navbar .dropdown-menu li > a:focus {
  color: #ffecb3;
  background-color: #eeeeee;
}
.navbar-material-amber-100.navbar .dropdown-menu .active > a {
  background-color: #ffecb3;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-100.navbar .dropdown-menu .active > a:hover,
.navbar-material-amber-100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-200.navbar {
  background-color: #ffe082;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-amber-200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-amber-200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-amber-200.navbar .dropdown-menu li > a:hover,
.navbar-material-amber-200.navbar .dropdown-menu li > a:focus {
  color: #ffe082;
  background-color: #eeeeee;
}
.navbar-material-amber-200.navbar .dropdown-menu .active > a {
  background-color: #ffe082;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-200.navbar .dropdown-menu .active > a:hover,
.navbar-material-amber-200.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-300.navbar {
  background-color: #ffd54f;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-300.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-amber-300.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-300.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-amber-300.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-amber-300.navbar .dropdown-menu li > a:hover,
.navbar-material-amber-300.navbar .dropdown-menu li > a:focus {
  color: #ffd54f;
  background-color: #eeeeee;
}
.navbar-material-amber-300.navbar .dropdown-menu .active > a {
  background-color: #ffd54f;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-300.navbar .dropdown-menu .active > a:hover,
.navbar-material-amber-300.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-400.navbar {
  background-color: #ffca28;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-amber-400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-amber-400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-amber-400.navbar .dropdown-menu li > a:hover,
.navbar-material-amber-400.navbar .dropdown-menu li > a:focus {
  color: #ffca28;
  background-color: #eeeeee;
}
.navbar-material-amber-400.navbar .dropdown-menu .active > a {
  background-color: #ffca28;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-400.navbar .dropdown-menu .active > a:hover,
.navbar-material-amber-400.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-500.navbar {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-500.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-amber-500.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-500.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-amber-500.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-amber-500.navbar .dropdown-menu li > a:hover,
.navbar-material-amber-500.navbar .dropdown-menu li > a:focus {
  color: #ffc107;
  background-color: #eeeeee;
}
.navbar-material-amber-500.navbar .dropdown-menu .active > a {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-500.navbar .dropdown-menu .active > a:hover,
.navbar-material-amber-500.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-600.navbar {
  background-color: #ffb300;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-600.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-amber-600.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-600.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-amber-600.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-amber-600.navbar .dropdown-menu li > a:hover,
.navbar-material-amber-600.navbar .dropdown-menu li > a:focus {
  color: #ffb300;
  background-color: #eeeeee;
}
.navbar-material-amber-600.navbar .dropdown-menu .active > a {
  background-color: #ffb300;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-600.navbar .dropdown-menu .active > a:hover,
.navbar-material-amber-600.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-700.navbar {
  background-color: #ffa000;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-amber-700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-amber-700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-amber-700.navbar .dropdown-menu li > a:hover,
.navbar-material-amber-700.navbar .dropdown-menu li > a:focus {
  color: #ffa000;
  background-color: #eeeeee;
}
.navbar-material-amber-700.navbar .dropdown-menu .active > a {
  background-color: #ffa000;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-700.navbar .dropdown-menu .active > a:hover,
.navbar-material-amber-700.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-800.navbar {
  background-color: #ff8f00;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-800.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-amber-800.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-800.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-amber-800.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-amber-800.navbar .dropdown-menu li > a:hover,
.navbar-material-amber-800.navbar .dropdown-menu li > a:focus {
  color: #ff8f00;
  background-color: #eeeeee;
}
.navbar-material-amber-800.navbar .dropdown-menu .active > a {
  background-color: #ff8f00;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-800.navbar .dropdown-menu .active > a:hover,
.navbar-material-amber-800.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-900.navbar {
  background-color: #ff6f00;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-amber-900.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-amber-900.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-amber-900.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-amber-900.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-amber-900.navbar .dropdown-menu li > a:hover,
.navbar-material-amber-900.navbar .dropdown-menu li > a:focus {
  color: #ff6f00;
  background-color: #eeeeee;
}
.navbar-material-amber-900.navbar .dropdown-menu .active > a {
  background-color: #ff6f00;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-amber-900.navbar .dropdown-menu .active > a:hover,
.navbar-material-amber-900.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-amber-A100.navbar {
  background-color: #ffe57f;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-A100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-amber-A100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-A100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-amber-A100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-amber-A100.navbar .dropdown-menu li > a:hover,
.navbar-material-amber-A100.navbar .dropdown-menu li > a:focus {
  color: #ffe57f;
  background-color: #eeeeee;
}
.navbar-material-amber-A100.navbar .dropdown-menu .active > a {
  background-color: #ffe57f;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-A100.navbar .dropdown-menu .active > a:hover,
.navbar-material-amber-A100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-A200.navbar {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-A200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-amber-A200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-A200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-amber-A200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-amber-A200.navbar .dropdown-menu li > a:hover,
.navbar-material-amber-A200.navbar .dropdown-menu li > a:focus {
  color: #ffd740;
  background-color: #eeeeee;
}
.navbar-material-amber-A200.navbar .dropdown-menu .active > a {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-A200.navbar .dropdown-menu .active > a:hover,
.navbar-material-amber-A200.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-A400.navbar {
  background-color: #ffc400;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-A400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-amber-A400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-A400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-amber-A400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-amber-A400.navbar .dropdown-menu li > a:hover,
.navbar-material-amber-A400.navbar .dropdown-menu li > a:focus {
  color: #ffc400;
  background-color: #eeeeee;
}
.navbar-material-amber-A400.navbar .dropdown-menu .active > a {
  background-color: #ffc400;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-A400.navbar .dropdown-menu .active > a:hover,
.navbar-material-amber-A400.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-A700.navbar {
  background-color: #ffab00;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-A700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-amber-A700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-A700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-amber-A700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-amber-A700.navbar .dropdown-menu li > a:hover,
.navbar-material-amber-A700.navbar .dropdown-menu li > a:focus {
  color: #ffab00;
  background-color: #eeeeee;
}
.navbar-material-amber-A700.navbar .dropdown-menu .active > a {
  background-color: #ffab00;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-amber-A700.navbar .dropdown-menu .active > a:hover,
.navbar-material-amber-A700.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange.navbar {
  background-color: #ff9800;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-orange.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-orange.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-orange.navbar .dropdown-menu li > a:hover,
.navbar-material-orange.navbar .dropdown-menu li > a:focus {
  color: #ff9800;
  background-color: #eeeeee;
}
.navbar-material-orange.navbar .dropdown-menu .active > a {
  background-color: #ff9800;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange.navbar .dropdown-menu .active > a:hover,
.navbar-material-orange.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-50.navbar {
  background-color: #fff3e0;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-50.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-orange-50.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-50.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-orange-50.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-orange-50.navbar .dropdown-menu li > a:hover,
.navbar-material-orange-50.navbar .dropdown-menu li > a:focus {
  color: #fff3e0;
  background-color: #eeeeee;
}
.navbar-material-orange-50.navbar .dropdown-menu .active > a {
  background-color: #fff3e0;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-50.navbar .dropdown-menu .active > a:hover,
.navbar-material-orange-50.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-100.navbar {
  background-color: #ffe0b2;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-orange-100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-orange-100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-orange-100.navbar .dropdown-menu li > a:hover,
.navbar-material-orange-100.navbar .dropdown-menu li > a:focus {
  color: #ffe0b2;
  background-color: #eeeeee;
}
.navbar-material-orange-100.navbar .dropdown-menu .active > a {
  background-color: #ffe0b2;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-100.navbar .dropdown-menu .active > a:hover,
.navbar-material-orange-100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-200.navbar {
  background-color: #ffcc80;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-orange-200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-orange-200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-orange-200.navbar .dropdown-menu li > a:hover,
.navbar-material-orange-200.navbar .dropdown-menu li > a:focus {
  color: #ffcc80;
  background-color: #eeeeee;
}
.navbar-material-orange-200.navbar .dropdown-menu .active > a {
  background-color: #ffcc80;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-200.navbar .dropdown-menu .active > a:hover,
.navbar-material-orange-200.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-300.navbar {
  background-color: #ffb74d;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-300.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-orange-300.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-300.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-orange-300.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-orange-300.navbar .dropdown-menu li > a:hover,
.navbar-material-orange-300.navbar .dropdown-menu li > a:focus {
  color: #ffb74d;
  background-color: #eeeeee;
}
.navbar-material-orange-300.navbar .dropdown-menu .active > a {
  background-color: #ffb74d;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-300.navbar .dropdown-menu .active > a:hover,
.navbar-material-orange-300.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-400.navbar {
  background-color: #ffa726;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-orange-400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-orange-400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-orange-400.navbar .dropdown-menu li > a:hover,
.navbar-material-orange-400.navbar .dropdown-menu li > a:focus {
  color: #ffa726;
  background-color: #eeeeee;
}
.navbar-material-orange-400.navbar .dropdown-menu .active > a {
  background-color: #ffa726;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-400.navbar .dropdown-menu .active > a:hover,
.navbar-material-orange-400.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-500.navbar {
  background-color: #ff9800;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-500.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-orange-500.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-500.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-orange-500.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-orange-500.navbar .dropdown-menu li > a:hover,
.navbar-material-orange-500.navbar .dropdown-menu li > a:focus {
  color: #ff9800;
  background-color: #eeeeee;
}
.navbar-material-orange-500.navbar .dropdown-menu .active > a {
  background-color: #ff9800;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-500.navbar .dropdown-menu .active > a:hover,
.navbar-material-orange-500.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-600.navbar {
  background-color: #fb8c00;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-orange-600.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-orange-600.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-orange-600.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-orange-600.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-orange-600.navbar .dropdown-menu li > a:hover,
.navbar-material-orange-600.navbar .dropdown-menu li > a:focus {
  color: #fb8c00;
  background-color: #eeeeee;
}
.navbar-material-orange-600.navbar .dropdown-menu .active > a {
  background-color: #fb8c00;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-orange-600.navbar .dropdown-menu .active > a:hover,
.navbar-material-orange-600.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-orange-700.navbar {
  background-color: #f57c00;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-orange-700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-orange-700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-orange-700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-orange-700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-orange-700.navbar .dropdown-menu li > a:hover,
.navbar-material-orange-700.navbar .dropdown-menu li > a:focus {
  color: #f57c00;
  background-color: #eeeeee;
}
.navbar-material-orange-700.navbar .dropdown-menu .active > a {
  background-color: #f57c00;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-orange-700.navbar .dropdown-menu .active > a:hover,
.navbar-material-orange-700.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-orange-800.navbar {
  background-color: #ef6c00;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-orange-800.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-orange-800.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-orange-800.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-orange-800.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-orange-800.navbar .dropdown-menu li > a:hover,
.navbar-material-orange-800.navbar .dropdown-menu li > a:focus {
  color: #ef6c00;
  background-color: #eeeeee;
}
.navbar-material-orange-800.navbar .dropdown-menu .active > a {
  background-color: #ef6c00;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-orange-800.navbar .dropdown-menu .active > a:hover,
.navbar-material-orange-800.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-orange-900.navbar {
  background-color: #e65100;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-orange-900.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-orange-900.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-orange-900.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-orange-900.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-orange-900.navbar .dropdown-menu li > a:hover,
.navbar-material-orange-900.navbar .dropdown-menu li > a:focus {
  color: #e65100;
  background-color: #eeeeee;
}
.navbar-material-orange-900.navbar .dropdown-menu .active > a {
  background-color: #e65100;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-orange-900.navbar .dropdown-menu .active > a:hover,
.navbar-material-orange-900.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-orange-A100.navbar {
  background-color: #ffd180;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-A100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-orange-A100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-A100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-orange-A100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-orange-A100.navbar .dropdown-menu li > a:hover,
.navbar-material-orange-A100.navbar .dropdown-menu li > a:focus {
  color: #ffd180;
  background-color: #eeeeee;
}
.navbar-material-orange-A100.navbar .dropdown-menu .active > a {
  background-color: #ffd180;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-A100.navbar .dropdown-menu .active > a:hover,
.navbar-material-orange-A100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-A200.navbar {
  background-color: #ffab40;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-A200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-orange-A200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-A200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-orange-A200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-orange-A200.navbar .dropdown-menu li > a:hover,
.navbar-material-orange-A200.navbar .dropdown-menu li > a:focus {
  color: #ffab40;
  background-color: #eeeeee;
}
.navbar-material-orange-A200.navbar .dropdown-menu .active > a {
  background-color: #ffab40;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-A200.navbar .dropdown-menu .active > a:hover,
.navbar-material-orange-A200.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-A400.navbar {
  background-color: #ff9100;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-A400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-orange-A400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-A400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-orange-A400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-orange-A400.navbar .dropdown-menu li > a:hover,
.navbar-material-orange-A400.navbar .dropdown-menu li > a:focus {
  color: #ff9100;
  background-color: #eeeeee;
}
.navbar-material-orange-A400.navbar .dropdown-menu .active > a {
  background-color: #ff9100;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-A400.navbar .dropdown-menu .active > a:hover,
.navbar-material-orange-A400.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-orange-A700.navbar {
  background-color: #ff6d00;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-orange-A700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-orange-A700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-orange-A700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-orange-A700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-orange-A700.navbar .dropdown-menu li > a:hover,
.navbar-material-orange-A700.navbar .dropdown-menu li > a:focus {
  color: #ff6d00;
  background-color: #eeeeee;
}
.navbar-material-orange-A700.navbar .dropdown-menu .active > a {
  background-color: #ff6d00;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-orange-A700.navbar .dropdown-menu .active > a:hover,
.navbar-material-orange-A700.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange.navbar {
  background-color: #ff5722;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-deep-orange.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-deep-orange.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-deep-orange.navbar .dropdown-menu li > a:hover,
.navbar-material-deep-orange.navbar .dropdown-menu li > a:focus {
  color: #ff5722;
  background-color: #eeeeee;
}
.navbar-material-deep-orange.navbar .dropdown-menu .active > a {
  background-color: #ff5722;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange.navbar .dropdown-menu .active > a:hover,
.navbar-material-deep-orange.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-50.navbar {
  background-color: #fbe9e7;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-orange-50.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-deep-orange-50.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-orange-50.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-deep-orange-50.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-deep-orange-50.navbar .dropdown-menu li > a:hover,
.navbar-material-deep-orange-50.navbar .dropdown-menu li > a:focus {
  color: #fbe9e7;
  background-color: #eeeeee;
}
.navbar-material-deep-orange-50.navbar .dropdown-menu .active > a {
  background-color: #fbe9e7;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-orange-50.navbar .dropdown-menu .active > a:hover,
.navbar-material-deep-orange-50.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-orange-100.navbar {
  background-color: #ffccbc;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-orange-100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-deep-orange-100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-orange-100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-deep-orange-100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-deep-orange-100.navbar .dropdown-menu li > a:hover,
.navbar-material-deep-orange-100.navbar .dropdown-menu li > a:focus {
  color: #ffccbc;
  background-color: #eeeeee;
}
.navbar-material-deep-orange-100.navbar .dropdown-menu .active > a {
  background-color: #ffccbc;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-orange-100.navbar .dropdown-menu .active > a:hover,
.navbar-material-deep-orange-100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-orange-200.navbar {
  background-color: #ffab91;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-orange-200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-deep-orange-200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-orange-200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-deep-orange-200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-deep-orange-200.navbar .dropdown-menu li > a:hover,
.navbar-material-deep-orange-200.navbar .dropdown-menu li > a:focus {
  color: #ffab91;
  background-color: #eeeeee;
}
.navbar-material-deep-orange-200.navbar .dropdown-menu .active > a {
  background-color: #ffab91;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-orange-200.navbar .dropdown-menu .active > a:hover,
.navbar-material-deep-orange-200.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-orange-300.navbar {
  background-color: #ff8a65;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-orange-300.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-deep-orange-300.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-orange-300.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-deep-orange-300.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-deep-orange-300.navbar .dropdown-menu li > a:hover,
.navbar-material-deep-orange-300.navbar .dropdown-menu li > a:focus {
  color: #ff8a65;
  background-color: #eeeeee;
}
.navbar-material-deep-orange-300.navbar .dropdown-menu .active > a {
  background-color: #ff8a65;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-orange-300.navbar .dropdown-menu .active > a:hover,
.navbar-material-deep-orange-300.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-orange-400.navbar {
  background-color: #ff7043;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-deep-orange-400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-deep-orange-400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-deep-orange-400.navbar .dropdown-menu li > a:hover,
.navbar-material-deep-orange-400.navbar .dropdown-menu li > a:focus {
  color: #ff7043;
  background-color: #eeeeee;
}
.navbar-material-deep-orange-400.navbar .dropdown-menu .active > a {
  background-color: #ff7043;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-400.navbar .dropdown-menu .active > a:hover,
.navbar-material-deep-orange-400.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-500.navbar {
  background-color: #ff5722;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-500.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-deep-orange-500.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-500.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-deep-orange-500.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-deep-orange-500.navbar .dropdown-menu li > a:hover,
.navbar-material-deep-orange-500.navbar .dropdown-menu li > a:focus {
  color: #ff5722;
  background-color: #eeeeee;
}
.navbar-material-deep-orange-500.navbar .dropdown-menu .active > a {
  background-color: #ff5722;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-500.navbar .dropdown-menu .active > a:hover,
.navbar-material-deep-orange-500.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-600.navbar {
  background-color: #f4511e;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-600.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-deep-orange-600.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-600.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-deep-orange-600.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-deep-orange-600.navbar .dropdown-menu li > a:hover,
.navbar-material-deep-orange-600.navbar .dropdown-menu li > a:focus {
  color: #f4511e;
  background-color: #eeeeee;
}
.navbar-material-deep-orange-600.navbar .dropdown-menu .active > a {
  background-color: #f4511e;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-600.navbar .dropdown-menu .active > a:hover,
.navbar-material-deep-orange-600.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-700.navbar {
  background-color: #e64a19;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-deep-orange-700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-deep-orange-700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-deep-orange-700.navbar .dropdown-menu li > a:hover,
.navbar-material-deep-orange-700.navbar .dropdown-menu li > a:focus {
  color: #e64a19;
  background-color: #eeeeee;
}
.navbar-material-deep-orange-700.navbar .dropdown-menu .active > a {
  background-color: #e64a19;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-700.navbar .dropdown-menu .active > a:hover,
.navbar-material-deep-orange-700.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-800.navbar {
  background-color: #d84315;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-800.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-deep-orange-800.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-800.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-deep-orange-800.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-deep-orange-800.navbar .dropdown-menu li > a:hover,
.navbar-material-deep-orange-800.navbar .dropdown-menu li > a:focus {
  color: #d84315;
  background-color: #eeeeee;
}
.navbar-material-deep-orange-800.navbar .dropdown-menu .active > a {
  background-color: #d84315;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-800.navbar .dropdown-menu .active > a:hover,
.navbar-material-deep-orange-800.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-900.navbar {
  background-color: #bf360c;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-900.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-deep-orange-900.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-900.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-deep-orange-900.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-deep-orange-900.navbar .dropdown-menu li > a:hover,
.navbar-material-deep-orange-900.navbar .dropdown-menu li > a:focus {
  color: #bf360c;
  background-color: #eeeeee;
}
.navbar-material-deep-orange-900.navbar .dropdown-menu .active > a {
  background-color: #bf360c;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-900.navbar .dropdown-menu .active > a:hover,
.navbar-material-deep-orange-900.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-A100.navbar {
  background-color: #ff9e80;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-orange-A100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-deep-orange-A100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-orange-A100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-deep-orange-A100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-deep-orange-A100.navbar .dropdown-menu li > a:hover,
.navbar-material-deep-orange-A100.navbar .dropdown-menu li > a:focus {
  color: #ff9e80;
  background-color: #eeeeee;
}
.navbar-material-deep-orange-A100.navbar .dropdown-menu .active > a {
  background-color: #ff9e80;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-orange-A100.navbar .dropdown-menu .active > a:hover,
.navbar-material-deep-orange-A100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-deep-orange-A200.navbar {
  background-color: #ff6e40;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-A200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-deep-orange-A200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-A200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-deep-orange-A200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-deep-orange-A200.navbar .dropdown-menu li > a:hover,
.navbar-material-deep-orange-A200.navbar .dropdown-menu li > a:focus {
  color: #ff6e40;
  background-color: #eeeeee;
}
.navbar-material-deep-orange-A200.navbar .dropdown-menu .active > a {
  background-color: #ff6e40;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-A200.navbar .dropdown-menu .active > a:hover,
.navbar-material-deep-orange-A200.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-A400.navbar {
  background-color: #ff3d00;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-A400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-deep-orange-A400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-A400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-deep-orange-A400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-deep-orange-A400.navbar .dropdown-menu li > a:hover,
.navbar-material-deep-orange-A400.navbar .dropdown-menu li > a:focus {
  color: #ff3d00;
  background-color: #eeeeee;
}
.navbar-material-deep-orange-A400.navbar .dropdown-menu .active > a {
  background-color: #ff3d00;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-A400.navbar .dropdown-menu .active > a:hover,
.navbar-material-deep-orange-A400.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-A700.navbar {
  background-color: #dd2c00;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-A700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-deep-orange-A700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-A700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-deep-orange-A700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-deep-orange-A700.navbar .dropdown-menu li > a:hover,
.navbar-material-deep-orange-A700.navbar .dropdown-menu li > a:focus {
  color: #dd2c00;
  background-color: #eeeeee;
}
.navbar-material-deep-orange-A700.navbar .dropdown-menu .active > a {
  background-color: #dd2c00;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-deep-orange-A700.navbar .dropdown-menu .active > a:hover,
.navbar-material-deep-orange-A700.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown.navbar {
  background-color: #795548;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-brown.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-brown.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-brown.navbar .dropdown-menu li > a:hover,
.navbar-material-brown.navbar .dropdown-menu li > a:focus {
  color: #795548;
  background-color: #eeeeee;
}
.navbar-material-brown.navbar .dropdown-menu .active > a {
  background-color: #795548;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown.navbar .dropdown-menu .active > a:hover,
.navbar-material-brown.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-50.navbar {
  background-color: #efebe9;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-brown-50.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-brown-50.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-brown-50.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-brown-50.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-brown-50.navbar .dropdown-menu li > a:hover,
.navbar-material-brown-50.navbar .dropdown-menu li > a:focus {
  color: #efebe9;
  background-color: #eeeeee;
}
.navbar-material-brown-50.navbar .dropdown-menu .active > a {
  background-color: #efebe9;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-brown-50.navbar .dropdown-menu .active > a:hover,
.navbar-material-brown-50.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-brown-100.navbar {
  background-color: #d7ccc8;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-brown-100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-brown-100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-brown-100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-brown-100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-brown-100.navbar .dropdown-menu li > a:hover,
.navbar-material-brown-100.navbar .dropdown-menu li > a:focus {
  color: #d7ccc8;
  background-color: #eeeeee;
}
.navbar-material-brown-100.navbar .dropdown-menu .active > a {
  background-color: #d7ccc8;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-brown-100.navbar .dropdown-menu .active > a:hover,
.navbar-material-brown-100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-brown-200.navbar {
  background-color: #bcaaa4;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-brown-200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-brown-200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-brown-200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-brown-200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-brown-200.navbar .dropdown-menu li > a:hover,
.navbar-material-brown-200.navbar .dropdown-menu li > a:focus {
  color: #bcaaa4;
  background-color: #eeeeee;
}
.navbar-material-brown-200.navbar .dropdown-menu .active > a {
  background-color: #bcaaa4;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-brown-200.navbar .dropdown-menu .active > a:hover,
.navbar-material-brown-200.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-brown-300.navbar {
  background-color: #a1887f;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-300.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-brown-300.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-300.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-brown-300.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-brown-300.navbar .dropdown-menu li > a:hover,
.navbar-material-brown-300.navbar .dropdown-menu li > a:focus {
  color: #a1887f;
  background-color: #eeeeee;
}
.navbar-material-brown-300.navbar .dropdown-menu .active > a {
  background-color: #a1887f;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-300.navbar .dropdown-menu .active > a:hover,
.navbar-material-brown-300.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-400.navbar {
  background-color: #8d6e63;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-brown-400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-brown-400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-brown-400.navbar .dropdown-menu li > a:hover,
.navbar-material-brown-400.navbar .dropdown-menu li > a:focus {
  color: #8d6e63;
  background-color: #eeeeee;
}
.navbar-material-brown-400.navbar .dropdown-menu .active > a {
  background-color: #8d6e63;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-400.navbar .dropdown-menu .active > a:hover,
.navbar-material-brown-400.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-500.navbar {
  background-color: #795548;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-500.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-brown-500.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-500.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-brown-500.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-brown-500.navbar .dropdown-menu li > a:hover,
.navbar-material-brown-500.navbar .dropdown-menu li > a:focus {
  color: #795548;
  background-color: #eeeeee;
}
.navbar-material-brown-500.navbar .dropdown-menu .active > a {
  background-color: #795548;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-500.navbar .dropdown-menu .active > a:hover,
.navbar-material-brown-500.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-600.navbar {
  background-color: #6d4c41;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-600.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-brown-600.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-600.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-brown-600.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-brown-600.navbar .dropdown-menu li > a:hover,
.navbar-material-brown-600.navbar .dropdown-menu li > a:focus {
  color: #6d4c41;
  background-color: #eeeeee;
}
.navbar-material-brown-600.navbar .dropdown-menu .active > a {
  background-color: #6d4c41;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-600.navbar .dropdown-menu .active > a:hover,
.navbar-material-brown-600.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-700.navbar {
  background-color: #5d4037;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-brown-700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-brown-700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-brown-700.navbar .dropdown-menu li > a:hover,
.navbar-material-brown-700.navbar .dropdown-menu li > a:focus {
  color: #5d4037;
  background-color: #eeeeee;
}
.navbar-material-brown-700.navbar .dropdown-menu .active > a {
  background-color: #5d4037;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-700.navbar .dropdown-menu .active > a:hover,
.navbar-material-brown-700.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-800.navbar {
  background-color: #4e342e;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-800.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-brown-800.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-800.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-brown-800.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-brown-800.navbar .dropdown-menu li > a:hover,
.navbar-material-brown-800.navbar .dropdown-menu li > a:focus {
  color: #4e342e;
  background-color: #eeeeee;
}
.navbar-material-brown-800.navbar .dropdown-menu .active > a {
  background-color: #4e342e;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-800.navbar .dropdown-menu .active > a:hover,
.navbar-material-brown-800.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-900.navbar {
  background-color: #3e2723;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-900.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-brown-900.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-900.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-brown-900.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-brown-900.navbar .dropdown-menu li > a:hover,
.navbar-material-brown-900.navbar .dropdown-menu li > a:focus {
  color: #3e2723;
  background-color: #eeeeee;
}
.navbar-material-brown-900.navbar .dropdown-menu .active > a {
  background-color: #3e2723;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-900.navbar .dropdown-menu .active > a:hover,
.navbar-material-brown-900.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-A100.navbar {
  background-color: #d7ccc8;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-brown-A100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-brown-A100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-brown-A100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-brown-A100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-brown-A100.navbar .dropdown-menu li > a:hover,
.navbar-material-brown-A100.navbar .dropdown-menu li > a:focus {
  color: #d7ccc8;
  background-color: #eeeeee;
}
.navbar-material-brown-A100.navbar .dropdown-menu .active > a {
  background-color: #d7ccc8;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-brown-A100.navbar .dropdown-menu .active > a:hover,
.navbar-material-brown-A100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-brown-A200.navbar {
  background-color: #bcaaa4;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-brown-A200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-brown-A200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-brown-A200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-brown-A200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-brown-A200.navbar .dropdown-menu li > a:hover,
.navbar-material-brown-A200.navbar .dropdown-menu li > a:focus {
  color: #bcaaa4;
  background-color: #eeeeee;
}
.navbar-material-brown-A200.navbar .dropdown-menu .active > a {
  background-color: #bcaaa4;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-brown-A200.navbar .dropdown-menu .active > a:hover,
.navbar-material-brown-A200.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-brown-A400.navbar {
  background-color: #8d6e63;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-A400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-brown-A400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-A400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-brown-A400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-brown-A400.navbar .dropdown-menu li > a:hover,
.navbar-material-brown-A400.navbar .dropdown-menu li > a:focus {
  color: #8d6e63;
  background-color: #eeeeee;
}
.navbar-material-brown-A400.navbar .dropdown-menu .active > a {
  background-color: #8d6e63;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-A400.navbar .dropdown-menu .active > a:hover,
.navbar-material-brown-A400.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-A700.navbar {
  background-color: #5d4037;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-A700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-brown-A700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-A700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-brown-A700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-brown-A700.navbar .dropdown-menu li > a:hover,
.navbar-material-brown-A700.navbar .dropdown-menu li > a:focus {
  color: #5d4037;
  background-color: #eeeeee;
}
.navbar-material-brown-A700.navbar .dropdown-menu .active > a {
  background-color: #5d4037;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-brown-A700.navbar .dropdown-menu .active > a:hover,
.navbar-material-brown-A700.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey.navbar {
  background-color: #9e9e9e;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-grey.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-grey.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-grey.navbar .dropdown-menu li > a:hover,
.navbar-material-grey.navbar .dropdown-menu li > a:focus {
  color: #9e9e9e;
  background-color: #eeeeee;
}
.navbar-material-grey.navbar .dropdown-menu .active > a {
  background-color: #9e9e9e;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey.navbar .dropdown-menu .active > a:hover,
.navbar-material-grey.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-50.navbar {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-50.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-grey-50.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-50.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-grey-50.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-grey-50.navbar .dropdown-menu li > a:hover,
.navbar-material-grey-50.navbar .dropdown-menu li > a:focus {
  color: #fafafa;
  background-color: #eeeeee;
}
.navbar-material-grey-50.navbar .dropdown-menu .active > a {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-50.navbar .dropdown-menu .active > a:hover,
.navbar-material-grey-50.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-100.navbar {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-grey-100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-grey-100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-grey-100.navbar .dropdown-menu li > a:hover,
.navbar-material-grey-100.navbar .dropdown-menu li > a:focus {
  color: #f5f5f5;
  background-color: #eeeeee;
}
.navbar-material-grey-100.navbar .dropdown-menu .active > a {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-100.navbar .dropdown-menu .active > a:hover,
.navbar-material-grey-100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-200.navbar {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-grey-200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-grey-200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-grey-200.navbar .dropdown-menu li > a:hover,
.navbar-material-grey-200.navbar .dropdown-menu li > a:focus {
  color: #eeeeee;
  background-color: #eeeeee;
}
.navbar-material-grey-200.navbar .dropdown-menu .active > a {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-200.navbar .dropdown-menu .active > a:hover,
.navbar-material-grey-200.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-300.navbar {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-300.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-grey-300.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-300.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-grey-300.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-grey-300.navbar .dropdown-menu li > a:hover,
.navbar-material-grey-300.navbar .dropdown-menu li > a:focus {
  color: #e0e0e0;
  background-color: #eeeeee;
}
.navbar-material-grey-300.navbar .dropdown-menu .active > a {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-300.navbar .dropdown-menu .active > a:hover,
.navbar-material-grey-300.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-400.navbar {
  background-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-grey-400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-grey-400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-grey-400.navbar .dropdown-menu li > a:hover,
.navbar-material-grey-400.navbar .dropdown-menu li > a:focus {
  color: #bdbdbd;
  background-color: #eeeeee;
}
.navbar-material-grey-400.navbar .dropdown-menu .active > a {
  background-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-400.navbar .dropdown-menu .active > a:hover,
.navbar-material-grey-400.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-500.navbar {
  background-color: #9e9e9e;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-500.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-grey-500.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-500.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-grey-500.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-grey-500.navbar .dropdown-menu li > a:hover,
.navbar-material-grey-500.navbar .dropdown-menu li > a:focus {
  color: #9e9e9e;
  background-color: #eeeeee;
}
.navbar-material-grey-500.navbar .dropdown-menu .active > a {
  background-color: #9e9e9e;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-500.navbar .dropdown-menu .active > a:hover,
.navbar-material-grey-500.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-600.navbar {
  background-color: #757575;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-600.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-grey-600.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-600.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-grey-600.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-grey-600.navbar .dropdown-menu li > a:hover,
.navbar-material-grey-600.navbar .dropdown-menu li > a:focus {
  color: #757575;
  background-color: #eeeeee;
}
.navbar-material-grey-600.navbar .dropdown-menu .active > a {
  background-color: #757575;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-600.navbar .dropdown-menu .active > a:hover,
.navbar-material-grey-600.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-700.navbar {
  background-color: #616161;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-grey-700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-grey-700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-grey-700.navbar .dropdown-menu li > a:hover,
.navbar-material-grey-700.navbar .dropdown-menu li > a:focus {
  color: #616161;
  background-color: #eeeeee;
}
.navbar-material-grey-700.navbar .dropdown-menu .active > a {
  background-color: #616161;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-700.navbar .dropdown-menu .active > a:hover,
.navbar-material-grey-700.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-800.navbar {
  background-color: #424242;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-800.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-grey-800.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-800.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-grey-800.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-grey-800.navbar .dropdown-menu li > a:hover,
.navbar-material-grey-800.navbar .dropdown-menu li > a:focus {
  color: #424242;
  background-color: #eeeeee;
}
.navbar-material-grey-800.navbar .dropdown-menu .active > a {
  background-color: #424242;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-800.navbar .dropdown-menu .active > a:hover,
.navbar-material-grey-800.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-900.navbar {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-900.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-grey-900.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-900.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-grey-900.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-grey-900.navbar .dropdown-menu li > a:hover,
.navbar-material-grey-900.navbar .dropdown-menu li > a:focus {
  color: #212121;
  background-color: #eeeeee;
}
.navbar-material-grey-900.navbar .dropdown-menu .active > a {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-900.navbar .dropdown-menu .active > a:hover,
.navbar-material-grey-900.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-A100.navbar {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-A100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-grey-A100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-A100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-grey-A100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-grey-A100.navbar .dropdown-menu li > a:hover,
.navbar-material-grey-A100.navbar .dropdown-menu li > a:focus {
  color: #f5f5f5;
  background-color: #eeeeee;
}
.navbar-material-grey-A100.navbar .dropdown-menu .active > a {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-A100.navbar .dropdown-menu .active > a:hover,
.navbar-material-grey-A100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-A200.navbar {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-A200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-grey-A200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-A200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-grey-A200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-grey-A200.navbar .dropdown-menu li > a:hover,
.navbar-material-grey-A200.navbar .dropdown-menu li > a:focus {
  color: #eeeeee;
  background-color: #eeeeee;
}
.navbar-material-grey-A200.navbar .dropdown-menu .active > a {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-A200.navbar .dropdown-menu .active > a:hover,
.navbar-material-grey-A200.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-A400.navbar {
  background-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-A400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-grey-A400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-A400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-grey-A400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-grey-A400.navbar .dropdown-menu li > a:hover,
.navbar-material-grey-A400.navbar .dropdown-menu li > a:focus {
  color: #bdbdbd;
  background-color: #eeeeee;
}
.navbar-material-grey-A400.navbar .dropdown-menu .active > a {
  background-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-A400.navbar .dropdown-menu .active > a:hover,
.navbar-material-grey-A400.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-grey-A700.navbar {
  background-color: #616161;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-A700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-grey-A700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-A700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-grey-A700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-grey-A700.navbar .dropdown-menu li > a:hover,
.navbar-material-grey-A700.navbar .dropdown-menu li > a:focus {
  color: #616161;
  background-color: #eeeeee;
}
.navbar-material-grey-A700.navbar .dropdown-menu .active > a {
  background-color: #616161;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-grey-A700.navbar .dropdown-menu .active > a:hover,
.navbar-material-grey-A700.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey.navbar {
  background-color: #607d8b;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-blue-grey.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-blue-grey.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-blue-grey.navbar .dropdown-menu li > a:hover,
.navbar-material-blue-grey.navbar .dropdown-menu li > a:focus {
  color: #607d8b;
  background-color: #eeeeee;
}
.navbar-material-blue-grey.navbar .dropdown-menu .active > a {
  background-color: #607d8b;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey.navbar .dropdown-menu .active > a:hover,
.navbar-material-blue-grey.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-50.navbar {
  background-color: #eceff1;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-grey-50.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-blue-grey-50.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-grey-50.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-blue-grey-50.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-blue-grey-50.navbar .dropdown-menu li > a:hover,
.navbar-material-blue-grey-50.navbar .dropdown-menu li > a:focus {
  color: #eceff1;
  background-color: #eeeeee;
}
.navbar-material-blue-grey-50.navbar .dropdown-menu .active > a {
  background-color: #eceff1;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-grey-50.navbar .dropdown-menu .active > a:hover,
.navbar-material-blue-grey-50.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-grey-100.navbar {
  background-color: #cfd8dc;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-grey-100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-blue-grey-100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-grey-100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-blue-grey-100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-blue-grey-100.navbar .dropdown-menu li > a:hover,
.navbar-material-blue-grey-100.navbar .dropdown-menu li > a:focus {
  color: #cfd8dc;
  background-color: #eeeeee;
}
.navbar-material-blue-grey-100.navbar .dropdown-menu .active > a {
  background-color: #cfd8dc;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-grey-100.navbar .dropdown-menu .active > a:hover,
.navbar-material-blue-grey-100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-grey-200.navbar {
  background-color: #b0bec5;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-grey-200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-blue-grey-200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-grey-200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-blue-grey-200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-blue-grey-200.navbar .dropdown-menu li > a:hover,
.navbar-material-blue-grey-200.navbar .dropdown-menu li > a:focus {
  color: #b0bec5;
  background-color: #eeeeee;
}
.navbar-material-blue-grey-200.navbar .dropdown-menu .active > a {
  background-color: #b0bec5;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-grey-200.navbar .dropdown-menu .active > a:hover,
.navbar-material-blue-grey-200.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-grey-300.navbar {
  background-color: #90a4ae;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-300.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-blue-grey-300.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-300.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-blue-grey-300.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-blue-grey-300.navbar .dropdown-menu li > a:hover,
.navbar-material-blue-grey-300.navbar .dropdown-menu li > a:focus {
  color: #90a4ae;
  background-color: #eeeeee;
}
.navbar-material-blue-grey-300.navbar .dropdown-menu .active > a {
  background-color: #90a4ae;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-300.navbar .dropdown-menu .active > a:hover,
.navbar-material-blue-grey-300.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-400.navbar {
  background-color: #78909c;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-blue-grey-400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-blue-grey-400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-blue-grey-400.navbar .dropdown-menu li > a:hover,
.navbar-material-blue-grey-400.navbar .dropdown-menu li > a:focus {
  color: #78909c;
  background-color: #eeeeee;
}
.navbar-material-blue-grey-400.navbar .dropdown-menu .active > a {
  background-color: #78909c;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-400.navbar .dropdown-menu .active > a:hover,
.navbar-material-blue-grey-400.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-500.navbar {
  background-color: #607d8b;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-500.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-blue-grey-500.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-500.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-blue-grey-500.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-blue-grey-500.navbar .dropdown-menu li > a:hover,
.navbar-material-blue-grey-500.navbar .dropdown-menu li > a:focus {
  color: #607d8b;
  background-color: #eeeeee;
}
.navbar-material-blue-grey-500.navbar .dropdown-menu .active > a {
  background-color: #607d8b;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-500.navbar .dropdown-menu .active > a:hover,
.navbar-material-blue-grey-500.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-600.navbar {
  background-color: #546e7a;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-600.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-blue-grey-600.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-600.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-blue-grey-600.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-blue-grey-600.navbar .dropdown-menu li > a:hover,
.navbar-material-blue-grey-600.navbar .dropdown-menu li > a:focus {
  color: #546e7a;
  background-color: #eeeeee;
}
.navbar-material-blue-grey-600.navbar .dropdown-menu .active > a {
  background-color: #546e7a;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-600.navbar .dropdown-menu .active > a:hover,
.navbar-material-blue-grey-600.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-700.navbar {
  background-color: #455a64;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-blue-grey-700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-blue-grey-700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-blue-grey-700.navbar .dropdown-menu li > a:hover,
.navbar-material-blue-grey-700.navbar .dropdown-menu li > a:focus {
  color: #455a64;
  background-color: #eeeeee;
}
.navbar-material-blue-grey-700.navbar .dropdown-menu .active > a {
  background-color: #455a64;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-700.navbar .dropdown-menu .active > a:hover,
.navbar-material-blue-grey-700.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-800.navbar {
  background-color: #37474f;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-800.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-blue-grey-800.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-800.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-blue-grey-800.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-blue-grey-800.navbar .dropdown-menu li > a:hover,
.navbar-material-blue-grey-800.navbar .dropdown-menu li > a:focus {
  color: #37474f;
  background-color: #eeeeee;
}
.navbar-material-blue-grey-800.navbar .dropdown-menu .active > a {
  background-color: #37474f;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-800.navbar .dropdown-menu .active > a:hover,
.navbar-material-blue-grey-800.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-900.navbar {
  background-color: #263238;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-900.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-blue-grey-900.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-900.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-blue-grey-900.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-blue-grey-900.navbar .dropdown-menu li > a:hover,
.navbar-material-blue-grey-900.navbar .dropdown-menu li > a:focus {
  color: #263238;
  background-color: #eeeeee;
}
.navbar-material-blue-grey-900.navbar .dropdown-menu .active > a {
  background-color: #263238;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-900.navbar .dropdown-menu .active > a:hover,
.navbar-material-blue-grey-900.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-A100.navbar {
  background-color: #cfd8dc;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-grey-A100.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-blue-grey-A100.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-grey-A100.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-blue-grey-A100.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-blue-grey-A100.navbar .dropdown-menu li > a:hover,
.navbar-material-blue-grey-A100.navbar .dropdown-menu li > a:focus {
  color: #cfd8dc;
  background-color: #eeeeee;
}
.navbar-material-blue-grey-A100.navbar .dropdown-menu .active > a {
  background-color: #cfd8dc;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-grey-A100.navbar .dropdown-menu .active > a:hover,
.navbar-material-blue-grey-A100.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-grey-A200.navbar {
  background-color: #b0bec5;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-grey-A200.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-blue-grey-A200.navbar .navbar-form input.form-control::placeholder {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-grey-A200.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-blue-grey-A200.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-blue-grey-A200.navbar .dropdown-menu li > a:hover,
.navbar-material-blue-grey-A200.navbar .dropdown-menu li > a:focus {
  color: #b0bec5;
  background-color: #eeeeee;
}
.navbar-material-blue-grey-A200.navbar .dropdown-menu .active > a {
  background-color: #b0bec5;
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-grey-A200.navbar .dropdown-menu .active > a:hover,
.navbar-material-blue-grey-A200.navbar .dropdown-menu .active > a:focus {
  color: rgba(0, 0, 0, 0.84);
}
.navbar-material-blue-grey-A400.navbar {
  background-color: #78909c;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-A400.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-blue-grey-A400.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-A400.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-blue-grey-A400.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-blue-grey-A400.navbar .dropdown-menu li > a:hover,
.navbar-material-blue-grey-A400.navbar .dropdown-menu li > a:focus {
  color: #78909c;
  background-color: #eeeeee;
}
.navbar-material-blue-grey-A400.navbar .dropdown-menu .active > a {
  background-color: #78909c;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-A400.navbar .dropdown-menu .active > a:hover,
.navbar-material-blue-grey-A400.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-A700.navbar {
  background-color: #455a64;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-A700.navbar .navbar-form .form-group input.form-control::placeholder,
.navbar-material-blue-grey-A700.navbar .navbar-form input.form-control::placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-A700.navbar .dropdown-menu {
  border-radius: 2px;
}
.navbar-material-blue-grey-A700.navbar .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar-material-blue-grey-A700.navbar .dropdown-menu li > a:hover,
.navbar-material-blue-grey-A700.navbar .dropdown-menu li > a:focus {
  color: #455a64;
  background-color: #eeeeee;
}
.navbar-material-blue-grey-A700.navbar .dropdown-menu .active > a {
  background-color: #455a64;
  color: rgba(255, 255, 255, 0.84);
}
.navbar-material-blue-grey-A700.navbar .dropdown-menu .active > a:hover,
.navbar-material-blue-grey-A700.navbar .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-inverse {
  background-color: #3f51b5;
}
@media (max-width: 1199px) {
  .navbar .navbar-brand {
    height: 50px;
    padding: 10px 15px;
  }
  .navbar .navbar-form {
    margin-top: 10px;
  }
  .navbar .navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.dropdown-menu {
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.dropdown-menu .divider {
  background-color: rgba(229, 229, 229, 0.12);
}
.dropdown-menu li {
  overflow: hidden;
  position: relative;
}
.dropdown-menu li a:hover {
  background-color: transparent;
  color: #009688;
}
.dropdown-menu li a:hover,
.dropdown-menu-default li a:hover {
  color: #009688;
}
.dropdown-menu-black li a:hover {
  color: #000000;
}
.dropdown-menu-white li a:hover {
  color: #ffffff;
}
.dropdown-menu-inverse li a:hover {
  color: #3f51b5;
}
.dropdown-menu-primary li a:hover {
  color: #009688;
}
.dropdown-menu-success li a:hover {
  color: #4caf50;
}
.dropdown-menu-info li a:hover {
  color: #03a9f4;
}
.dropdown-menu-warning li a:hover {
  color: #ff5722;
}
.dropdown-menu-danger li a:hover {
  color: #f44336;
}
.dropdown-menu-material-red li a:hover {
  color: #f44336;
}
.dropdown-menu-material-red-50 li a:hover {
  color: #ffebee;
}
.dropdown-menu-material-red-100 li a:hover {
  color: #ffcdd2;
}
.dropdown-menu-material-red-200 li a:hover {
  color: #ef9a9a;
}
.dropdown-menu-material-red-300 li a:hover {
  color: #e57373;
}
.dropdown-menu-material-red-400 li a:hover {
  color: #ef5350;
}
.dropdown-menu-material-red-500 li a:hover {
  color: #f44336;
}
.dropdown-menu-material-red-600 li a:hover {
  color: #e53935;
}
.dropdown-menu-material-red-700 li a:hover {
  color: #d32f2f;
}
.dropdown-menu-material-red-800 li a:hover {
  color: #c62828;
}
.dropdown-menu-material-red-900 li a:hover {
  color: #b71c1c;
}
.dropdown-menu-material-red-A100 li a:hover {
  color: #ff8a80;
}
.dropdown-menu-material-red-A200 li a:hover {
  color: #ff5252;
}
.dropdown-menu-material-red-A400 li a:hover {
  color: #ff1744;
}
.dropdown-menu-material-red-A700 li a:hover {
  color: #d50000;
}
.dropdown-menu-material-pink li a:hover {
  color: #e91e63;
}
.dropdown-menu-material-pink-50 li a:hover {
  color: #fce4ec;
}
.dropdown-menu-material-pink-100 li a:hover {
  color: #f8bbd0;
}
.dropdown-menu-material-pink-200 li a:hover {
  color: #f48fb1;
}
.dropdown-menu-material-pink-300 li a:hover {
  color: #f06292;
}
.dropdown-menu-material-pink-400 li a:hover {
  color: #ec407a;
}
.dropdown-menu-material-pink-500 li a:hover {
  color: #e91e63;
}
.dropdown-menu-material-pink-600 li a:hover {
  color: #d81b60;
}
.dropdown-menu-material-pink-700 li a:hover {
  color: #c2185b;
}
.dropdown-menu-material-pink-800 li a:hover {
  color: #ad1457;
}
.dropdown-menu-material-pink-900 li a:hover {
  color: #880e4f;
}
.dropdown-menu-material-pink-A100 li a:hover {
  color: #ff80ab;
}
.dropdown-menu-material-pink-A200 li a:hover {
  color: #ff4081;
}
.dropdown-menu-material-pink-A400 li a:hover {
  color: #f50057;
}
.dropdown-menu-material-pink-A700 li a:hover {
  color: #c51162;
}
.dropdown-menu-material-purple li a:hover {
  color: #9c27b0;
}
.dropdown-menu-material-purple-50 li a:hover {
  color: #f3e5f5;
}
.dropdown-menu-material-purple-100 li a:hover {
  color: #e1bee7;
}
.dropdown-menu-material-purple-200 li a:hover {
  color: #ce93d8;
}
.dropdown-menu-material-purple-300 li a:hover {
  color: #ba68c8;
}
.dropdown-menu-material-purple-400 li a:hover {
  color: #ab47bc;
}
.dropdown-menu-material-purple-500 li a:hover {
  color: #9c27b0;
}
.dropdown-menu-material-purple-600 li a:hover {
  color: #8e24aa;
}
.dropdown-menu-material-purple-700 li a:hover {
  color: #7b1fa2;
}
.dropdown-menu-material-purple-800 li a:hover {
  color: #6a1b9a;
}
.dropdown-menu-material-purple-900 li a:hover {
  color: #4a148c;
}
.dropdown-menu-material-purple-A100 li a:hover {
  color: #ea80fc;
}
.dropdown-menu-material-purple-A200 li a:hover {
  color: #e040fb;
}
.dropdown-menu-material-purple-A400 li a:hover {
  color: #d500f9;
}
.dropdown-menu-material-purple-A700 li a:hover {
  color: #aa00ff;
}
.dropdown-menu-material-deep-purple li a:hover {
  color: #673ab7;
}
.dropdown-menu-material-deep-purple-50 li a:hover {
  color: #ede7f6;
}
.dropdown-menu-material-deep-purple-100 li a:hover {
  color: #d1c4e9;
}
.dropdown-menu-material-deep-purple-200 li a:hover {
  color: #b39ddb;
}
.dropdown-menu-material-deep-purple-300 li a:hover {
  color: #9575cd;
}
.dropdown-menu-material-deep-purple-400 li a:hover {
  color: #7e57c2;
}
.dropdown-menu-material-deep-purple-500 li a:hover {
  color: #673ab7;
}
.dropdown-menu-material-deep-purple-600 li a:hover {
  color: #5e35b1;
}
.dropdown-menu-material-deep-purple-700 li a:hover {
  color: #512da8;
}
.dropdown-menu-material-deep-purple-800 li a:hover {
  color: #4527a0;
}
.dropdown-menu-material-deep-purple-900 li a:hover {
  color: #311b92;
}
.dropdown-menu-material-deep-purple-A100 li a:hover {
  color: #b388ff;
}
.dropdown-menu-material-deep-purple-A200 li a:hover {
  color: #7c4dff;
}
.dropdown-menu-material-deep-purple-A400 li a:hover {
  color: #651fff;
}
.dropdown-menu-material-deep-purple-A700 li a:hover {
  color: #6200ea;
}
.dropdown-menu-material-indigo li a:hover {
  color: #3f51b5;
}
.dropdown-menu-material-indigo-50 li a:hover {
  color: #e8eaf6;
}
.dropdown-menu-material-indigo-100 li a:hover {
  color: #c5cae9;
}
.dropdown-menu-material-indigo-200 li a:hover {
  color: #9fa8da;
}
.dropdown-menu-material-indigo-300 li a:hover {
  color: #7986cb;
}
.dropdown-menu-material-indigo-400 li a:hover {
  color: #5c6bc0;
}
.dropdown-menu-material-indigo-500 li a:hover {
  color: #3f51b5;
}
.dropdown-menu-material-indigo-600 li a:hover {
  color: #3949ab;
}
.dropdown-menu-material-indigo-700 li a:hover {
  color: #303f9f;
}
.dropdown-menu-material-indigo-800 li a:hover {
  color: #283593;
}
.dropdown-menu-material-indigo-900 li a:hover {
  color: #1a237e;
}
.dropdown-menu-material-indigo-A100 li a:hover {
  color: #8c9eff;
}
.dropdown-menu-material-indigo-A200 li a:hover {
  color: #536dfe;
}
.dropdown-menu-material-indigo-A400 li a:hover {
  color: #3d5afe;
}
.dropdown-menu-material-indigo-A700 li a:hover {
  color: #304ffe;
}
.dropdown-menu-material-blue li a:hover {
  color: #2196f3;
}
.dropdown-menu-material-blue-50 li a:hover {
  color: #e3f2fd;
}
.dropdown-menu-material-blue-100 li a:hover {
  color: #bbdefb;
}
.dropdown-menu-material-blue-200 li a:hover {
  color: #90caf9;
}
.dropdown-menu-material-blue-300 li a:hover {
  color: #64b5f6;
}
.dropdown-menu-material-blue-400 li a:hover {
  color: #42a5f5;
}
.dropdown-menu-material-blue-500 li a:hover {
  color: #2196f3;
}
.dropdown-menu-material-blue-600 li a:hover {
  color: #1e88e5;
}
.dropdown-menu-material-blue-700 li a:hover {
  color: #1976d2;
}
.dropdown-menu-material-blue-800 li a:hover {
  color: #1565c0;
}
.dropdown-menu-material-blue-900 li a:hover {
  color: #0d47a1;
}
.dropdown-menu-material-blue-A100 li a:hover {
  color: #82b1ff;
}
.dropdown-menu-material-blue-A200 li a:hover {
  color: #448aff;
}
.dropdown-menu-material-blue-A400 li a:hover {
  color: #2979ff;
}
.dropdown-menu-material-blue-A700 li a:hover {
  color: #2962ff;
}
.dropdown-menu-material-light-blue li a:hover {
  color: #03a9f4;
}
.dropdown-menu-material-light-blue-50 li a:hover {
  color: #e1f5fe;
}
.dropdown-menu-material-light-blue-100 li a:hover {
  color: #b3e5fc;
}
.dropdown-menu-material-light-blue-200 li a:hover {
  color: #81d4fa;
}
.dropdown-menu-material-light-blue-300 li a:hover {
  color: #4fc3f7;
}
.dropdown-menu-material-light-blue-400 li a:hover {
  color: #29b6f6;
}
.dropdown-menu-material-light-blue-500 li a:hover {
  color: #03a9f4;
}
.dropdown-menu-material-light-blue-600 li a:hover {
  color: #039be5;
}
.dropdown-menu-material-light-blue-700 li a:hover {
  color: #0288d1;
}
.dropdown-menu-material-light-blue-800 li a:hover {
  color: #0277bd;
}
.dropdown-menu-material-light-blue-900 li a:hover {
  color: #01579b;
}
.dropdown-menu-material-light-blue-A100 li a:hover {
  color: #80d8ff;
}
.dropdown-menu-material-light-blue-A200 li a:hover {
  color: #40c4ff;
}
.dropdown-menu-material-light-blue-A400 li a:hover {
  color: #00b0ff;
}
.dropdown-menu-material-light-blue-A700 li a:hover {
  color: #0091ea;
}
.dropdown-menu-material-cyan li a:hover {
  color: #00bcd4;
}
.dropdown-menu-material-cyan-50 li a:hover {
  color: #e0f7fa;
}
.dropdown-menu-material-cyan-100 li a:hover {
  color: #b2ebf2;
}
.dropdown-menu-material-cyan-200 li a:hover {
  color: #80deea;
}
.dropdown-menu-material-cyan-300 li a:hover {
  color: #4dd0e1;
}
.dropdown-menu-material-cyan-400 li a:hover {
  color: #26c6da;
}
.dropdown-menu-material-cyan-500 li a:hover {
  color: #00bcd4;
}
.dropdown-menu-material-cyan-600 li a:hover {
  color: #00acc1;
}
.dropdown-menu-material-cyan-700 li a:hover {
  color: #0097a7;
}
.dropdown-menu-material-cyan-800 li a:hover {
  color: #00838f;
}
.dropdown-menu-material-cyan-900 li a:hover {
  color: #006064;
}
.dropdown-menu-material-cyan-A100 li a:hover {
  color: #84ffff;
}
.dropdown-menu-material-cyan-A200 li a:hover {
  color: #18ffff;
}
.dropdown-menu-material-cyan-A400 li a:hover {
  color: #00e5ff;
}
.dropdown-menu-material-cyan-A700 li a:hover {
  color: #00b8d4;
}
.dropdown-menu-material-teal li a:hover {
  color: #009688;
}
.dropdown-menu-material-teal-50 li a:hover {
  color: #e0f2f1;
}
.dropdown-menu-material-teal-100 li a:hover {
  color: #b2dfdb;
}
.dropdown-menu-material-teal-200 li a:hover {
  color: #80cbc4;
}
.dropdown-menu-material-teal-300 li a:hover {
  color: #4db6ac;
}
.dropdown-menu-material-teal-400 li a:hover {
  color: #26a69a;
}
.dropdown-menu-material-teal-500 li a:hover {
  color: #009688;
}
.dropdown-menu-material-teal-600 li a:hover {
  color: #00897b;
}
.dropdown-menu-material-teal-700 li a:hover {
  color: #00796b;
}
.dropdown-menu-material-teal-800 li a:hover {
  color: #00695c;
}
.dropdown-menu-material-teal-900 li a:hover {
  color: #004d40;
}
.dropdown-menu-material-teal-A100 li a:hover {
  color: #a7ffeb;
}
.dropdown-menu-material-teal-A200 li a:hover {
  color: #64ffda;
}
.dropdown-menu-material-teal-A400 li a:hover {
  color: #1de9b6;
}
.dropdown-menu-material-teal-A700 li a:hover {
  color: #00bfa5;
}
.dropdown-menu-material-green li a:hover {
  color: #4caf50;
}
.dropdown-menu-material-green-50 li a:hover {
  color: #e8f5e9;
}
.dropdown-menu-material-green-100 li a:hover {
  color: #c8e6c9;
}
.dropdown-menu-material-green-200 li a:hover {
  color: #a5d6a7;
}
.dropdown-menu-material-green-300 li a:hover {
  color: #81c784;
}
.dropdown-menu-material-green-400 li a:hover {
  color: #66bb6a;
}
.dropdown-menu-material-green-500 li a:hover {
  color: #4caf50;
}
.dropdown-menu-material-green-600 li a:hover {
  color: #43a047;
}
.dropdown-menu-material-green-700 li a:hover {
  color: #388e3c;
}
.dropdown-menu-material-green-800 li a:hover {
  color: #2e7d32;
}
.dropdown-menu-material-green-900 li a:hover {
  color: #1b5e20;
}
.dropdown-menu-material-green-A100 li a:hover {
  color: #b9f6ca;
}
.dropdown-menu-material-green-A200 li a:hover {
  color: #69f0ae;
}
.dropdown-menu-material-green-A400 li a:hover {
  color: #00e676;
}
.dropdown-menu-material-green-A700 li a:hover {
  color: #00c853;
}
.dropdown-menu-material-light-green li a:hover {
  color: #8bc34a;
}
.dropdown-menu-material-light-green-50 li a:hover {
  color: #f1f8e9;
}
.dropdown-menu-material-light-green-100 li a:hover {
  color: #dcedc8;
}
.dropdown-menu-material-light-green-200 li a:hover {
  color: #c5e1a5;
}
.dropdown-menu-material-light-green-300 li a:hover {
  color: #aed581;
}
.dropdown-menu-material-light-green-400 li a:hover {
  color: #9ccc65;
}
.dropdown-menu-material-light-green-500 li a:hover {
  color: #8bc34a;
}
.dropdown-menu-material-light-green-600 li a:hover {
  color: #7cb342;
}
.dropdown-menu-material-light-green-700 li a:hover {
  color: #689f38;
}
.dropdown-menu-material-light-green-800 li a:hover {
  color: #558b2f;
}
.dropdown-menu-material-light-green-900 li a:hover {
  color: #33691e;
}
.dropdown-menu-material-light-green-A100 li a:hover {
  color: #ccff90;
}
.dropdown-menu-material-light-green-A200 li a:hover {
  color: #b2ff59;
}
.dropdown-menu-material-light-green-A400 li a:hover {
  color: #76ff03;
}
.dropdown-menu-material-light-green-A700 li a:hover {
  color: #64dd17;
}
.dropdown-menu-material-lime li a:hover {
  color: #cddc39;
}
.dropdown-menu-material-lime-50 li a:hover {
  color: #f9fbe7;
}
.dropdown-menu-material-lime-100 li a:hover {
  color: #f0f4c3;
}
.dropdown-menu-material-lime-200 li a:hover {
  color: #e6ee9c;
}
.dropdown-menu-material-lime-300 li a:hover {
  color: #dce775;
}
.dropdown-menu-material-lime-400 li a:hover {
  color: #d4e157;
}
.dropdown-menu-material-lime-500 li a:hover {
  color: #cddc39;
}
.dropdown-menu-material-lime-600 li a:hover {
  color: #c0ca33;
}
.dropdown-menu-material-lime-700 li a:hover {
  color: #afb42b;
}
.dropdown-menu-material-lime-800 li a:hover {
  color: #9e9d24;
}
.dropdown-menu-material-lime-900 li a:hover {
  color: #827717;
}
.dropdown-menu-material-lime-A100 li a:hover {
  color: #f4ff81;
}
.dropdown-menu-material-lime-A200 li a:hover {
  color: #eeff41;
}
.dropdown-menu-material-lime-A400 li a:hover {
  color: #c6ff00;
}
.dropdown-menu-material-lime-A700 li a:hover {
  color: #aeea00;
}
.dropdown-menu-material-yellow li a:hover {
  color: #ffeb3b;
}
.dropdown-menu-material-yellow-50 li a:hover {
  color: #fffde7;
}
.dropdown-menu-material-yellow-100 li a:hover {
  color: #fff9c4;
}
.dropdown-menu-material-yellow-200 li a:hover {
  color: #fff59d;
}
.dropdown-menu-material-yellow-300 li a:hover {
  color: #fff176;
}
.dropdown-menu-material-yellow-400 li a:hover {
  color: #ffee58;
}
.dropdown-menu-material-yellow-500 li a:hover {
  color: #ffeb3b;
}
.dropdown-menu-material-yellow-600 li a:hover {
  color: #fdd835;
}
.dropdown-menu-material-yellow-700 li a:hover {
  color: #fbc02d;
}
.dropdown-menu-material-yellow-800 li a:hover {
  color: #f9a825;
}
.dropdown-menu-material-yellow-900 li a:hover {
  color: #f57f17;
}
.dropdown-menu-material-yellow-A100 li a:hover {
  color: #ffff8d;
}
.dropdown-menu-material-yellow-A200 li a:hover {
  color: #ffff00;
}
.dropdown-menu-material-yellow-A400 li a:hover {
  color: #ffea00;
}
.dropdown-menu-material-yellow-A700 li a:hover {
  color: #ffd600;
}
.dropdown-menu-material-amber li a:hover {
  color: #ffc107;
}
.dropdown-menu-material-amber-50 li a:hover {
  color: #fff8e1;
}
.dropdown-menu-material-amber-100 li a:hover {
  color: #ffecb3;
}
.dropdown-menu-material-amber-200 li a:hover {
  color: #ffe082;
}
.dropdown-menu-material-amber-300 li a:hover {
  color: #ffd54f;
}
.dropdown-menu-material-amber-400 li a:hover {
  color: #ffca28;
}
.dropdown-menu-material-amber-500 li a:hover {
  color: #ffc107;
}
.dropdown-menu-material-amber-600 li a:hover {
  color: #ffb300;
}
.dropdown-menu-material-amber-700 li a:hover {
  color: #ffa000;
}
.dropdown-menu-material-amber-800 li a:hover {
  color: #ff8f00;
}
.dropdown-menu-material-amber-900 li a:hover {
  color: #ff6f00;
}
.dropdown-menu-material-amber-A100 li a:hover {
  color: #ffe57f;
}
.dropdown-menu-material-amber-A200 li a:hover {
  color: #ffd740;
}
.dropdown-menu-material-amber-A400 li a:hover {
  color: #ffc400;
}
.dropdown-menu-material-amber-A700 li a:hover {
  color: #ffab00;
}
.dropdown-menu-material-orange li a:hover {
  color: #ff9800;
}
.dropdown-menu-material-orange-50 li a:hover {
  color: #fff3e0;
}
.dropdown-menu-material-orange-100 li a:hover {
  color: #ffe0b2;
}
.dropdown-menu-material-orange-200 li a:hover {
  color: #ffcc80;
}
.dropdown-menu-material-orange-300 li a:hover {
  color: #ffb74d;
}
.dropdown-menu-material-orange-400 li a:hover {
  color: #ffa726;
}
.dropdown-menu-material-orange-500 li a:hover {
  color: #ff9800;
}
.dropdown-menu-material-orange-600 li a:hover {
  color: #fb8c00;
}
.dropdown-menu-material-orange-700 li a:hover {
  color: #f57c00;
}
.dropdown-menu-material-orange-800 li a:hover {
  color: #ef6c00;
}
.dropdown-menu-material-orange-900 li a:hover {
  color: #e65100;
}
.dropdown-menu-material-orange-A100 li a:hover {
  color: #ffd180;
}
.dropdown-menu-material-orange-A200 li a:hover {
  color: #ffab40;
}
.dropdown-menu-material-orange-A400 li a:hover {
  color: #ff9100;
}
.dropdown-menu-material-orange-A700 li a:hover {
  color: #ff6d00;
}
.dropdown-menu-material-deep-orange li a:hover {
  color: #ff5722;
}
.dropdown-menu-material-deep-orange-50 li a:hover {
  color: #fbe9e7;
}
.dropdown-menu-material-deep-orange-100 li a:hover {
  color: #ffccbc;
}
.dropdown-menu-material-deep-orange-200 li a:hover {
  color: #ffab91;
}
.dropdown-menu-material-deep-orange-300 li a:hover {
  color: #ff8a65;
}
.dropdown-menu-material-deep-orange-400 li a:hover {
  color: #ff7043;
}
.dropdown-menu-material-deep-orange-500 li a:hover {
  color: #ff5722;
}
.dropdown-menu-material-deep-orange-600 li a:hover {
  color: #f4511e;
}
.dropdown-menu-material-deep-orange-700 li a:hover {
  color: #e64a19;
}
.dropdown-menu-material-deep-orange-800 li a:hover {
  color: #d84315;
}
.dropdown-menu-material-deep-orange-900 li a:hover {
  color: #bf360c;
}
.dropdown-menu-material-deep-orange-A100 li a:hover {
  color: #ff9e80;
}
.dropdown-menu-material-deep-orange-A200 li a:hover {
  color: #ff6e40;
}
.dropdown-menu-material-deep-orange-A400 li a:hover {
  color: #ff3d00;
}
.dropdown-menu-material-deep-orange-A700 li a:hover {
  color: #dd2c00;
}
.dropdown-menu-material-brown li a:hover {
  color: #795548;
}
.dropdown-menu-material-brown-50 li a:hover {
  color: #efebe9;
}
.dropdown-menu-material-brown-100 li a:hover {
  color: #d7ccc8;
}
.dropdown-menu-material-brown-200 li a:hover {
  color: #bcaaa4;
}
.dropdown-menu-material-brown-300 li a:hover {
  color: #a1887f;
}
.dropdown-menu-material-brown-400 li a:hover {
  color: #8d6e63;
}
.dropdown-menu-material-brown-500 li a:hover {
  color: #795548;
}
.dropdown-menu-material-brown-600 li a:hover {
  color: #6d4c41;
}
.dropdown-menu-material-brown-700 li a:hover {
  color: #5d4037;
}
.dropdown-menu-material-brown-800 li a:hover {
  color: #4e342e;
}
.dropdown-menu-material-brown-900 li a:hover {
  color: #3e2723;
}
.dropdown-menu-material-brown-A100 li a:hover {
  color: #d7ccc8;
}
.dropdown-menu-material-brown-A200 li a:hover {
  color: #bcaaa4;
}
.dropdown-menu-material-brown-A400 li a:hover {
  color: #8d6e63;
}
.dropdown-menu-material-brown-A700 li a:hover {
  color: #5d4037;
}
.dropdown-menu-material-grey li a:hover {
  color: #9e9e9e;
}
.dropdown-menu-material-grey-50 li a:hover {
  color: #fafafa;
}
.dropdown-menu-material-grey-100 li a:hover {
  color: #f5f5f5;
}
.dropdown-menu-material-grey-200 li a:hover {
  color: #eeeeee;
}
.dropdown-menu-material-grey-300 li a:hover {
  color: #e0e0e0;
}
.dropdown-menu-material-grey-400 li a:hover {
  color: #bdbdbd;
}
.dropdown-menu-material-grey-500 li a:hover {
  color: #9e9e9e;
}
.dropdown-menu-material-grey-600 li a:hover {
  color: #757575;
}
.dropdown-menu-material-grey-700 li a:hover {
  color: #616161;
}
.dropdown-menu-material-grey-800 li a:hover {
  color: #424242;
}
.dropdown-menu-material-grey-900 li a:hover {
  color: #212121;
}
.dropdown-menu-material-grey-A100 li a:hover {
  color: #f5f5f5;
}
.dropdown-menu-material-grey-A200 li a:hover {
  color: #eeeeee;
}
.dropdown-menu-material-grey-A400 li a:hover {
  color: #bdbdbd;
}
.dropdown-menu-material-grey-A700 li a:hover {
  color: #616161;
}
.dropdown-menu-material-blue-grey li a:hover {
  color: #607d8b;
}
.dropdown-menu-material-blue-grey-50 li a:hover {
  color: #eceff1;
}
.dropdown-menu-material-blue-grey-100 li a:hover {
  color: #cfd8dc;
}
.dropdown-menu-material-blue-grey-200 li a:hover {
  color: #b0bec5;
}
.dropdown-menu-material-blue-grey-300 li a:hover {
  color: #90a4ae;
}
.dropdown-menu-material-blue-grey-400 li a:hover {
  color: #78909c;
}
.dropdown-menu-material-blue-grey-500 li a:hover {
  color: #607d8b;
}
.dropdown-menu-material-blue-grey-600 li a:hover {
  color: #546e7a;
}
.dropdown-menu-material-blue-grey-700 li a:hover {
  color: #455a64;
}
.dropdown-menu-material-blue-grey-800 li a:hover {
  color: #37474f;
}
.dropdown-menu-material-blue-grey-900 li a:hover {
  color: #263238;
}
.dropdown-menu-material-blue-grey-A100 li a:hover {
  color: #cfd8dc;
}
.dropdown-menu-material-blue-grey-A200 li a:hover {
  color: #b0bec5;
}
.dropdown-menu-material-blue-grey-A400 li a:hover {
  color: #78909c;
}
.dropdown-menu-material-blue-grey-A700 li a:hover {
  color: #455a64;
}
.alert {
  border: 0px;
  border-radius: 0;
}
.alert,
.alert-default {
  background-color: rgba(255, 255, 255, 0.84);
  color: rgba(255, 255, 255, 0.84);
}
.alert a,
.alert-default a,
.alert .alert-link,
.alert-default .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-black {
  background-color: #000000;
  color: rgba(255, 255, 255, 0.84);
}
.alert-black a,
.alert-black .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-white {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.84);
}
.alert-white a,
.alert-white .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-inverse {
  background-color: #3f51b5;
  color: rgba(255, 255, 255, 0.84);
}
.alert-inverse a,
.alert-inverse .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-primary {
  background-color: #009688;
  color: rgba(255, 255, 255, 0.84);
}
.alert-primary a,
.alert-primary .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-success {
  background-color: #4caf50;
  color: rgba(255, 255, 255, 0.84);
}
.alert-success a,
.alert-success .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-info {
  background-color: #03a9f4;
  color: rgba(255, 255, 255, 0.84);
}
.alert-info a,
.alert-info .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-warning {
  background-color: #ff5722;
  color: rgba(255, 255, 255, 0.84);
}
.alert-warning a,
.alert-warning .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-danger {
  background-color: #f44336;
  color: rgba(255, 255, 255, 0.84);
}
.alert-danger a,
.alert-danger .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-red {
  background-color: #f44336;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-red a,
.alert-material-red .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-red-50 {
  background-color: #ffebee;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-red-50 a,
.alert-material-red-50 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-red-100 {
  background-color: #ffcdd2;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-red-100 a,
.alert-material-red-100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-red-200 {
  background-color: #ef9a9a;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-red-200 a,
.alert-material-red-200 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-red-300 {
  background-color: #e57373;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-red-300 a,
.alert-material-red-300 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-red-400 {
  background-color: #ef5350;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-red-400 a,
.alert-material-red-400 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-red-500 {
  background-color: #f44336;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-red-500 a,
.alert-material-red-500 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-red-600 {
  background-color: #e53935;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-red-600 a,
.alert-material-red-600 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-red-700 {
  background-color: #d32f2f;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-red-700 a,
.alert-material-red-700 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-red-800 {
  background-color: #c62828;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-red-800 a,
.alert-material-red-800 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-red-900 {
  background-color: #b71c1c;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-red-900 a,
.alert-material-red-900 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-red-A100 {
  background-color: #ff8a80;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-red-A100 a,
.alert-material-red-A100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-red-A200 {
  background-color: #ff5252;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-red-A200 a,
.alert-material-red-A200 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-red-A400 {
  background-color: #ff1744;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-red-A400 a,
.alert-material-red-A400 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-red-A700 {
  background-color: #d50000;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-red-A700 a,
.alert-material-red-A700 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-pink {
  background-color: #e91e63;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-pink a,
.alert-material-pink .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-pink-50 {
  background-color: #fce4ec;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-pink-50 a,
.alert-material-pink-50 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-pink-100 {
  background-color: #f8bbd0;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-pink-100 a,
.alert-material-pink-100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-pink-200 {
  background-color: #f48fb1;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-pink-200 a,
.alert-material-pink-200 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-pink-300 {
  background-color: #f06292;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-pink-300 a,
.alert-material-pink-300 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-pink-400 {
  background-color: #ec407a;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-pink-400 a,
.alert-material-pink-400 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-pink-500 {
  background-color: #e91e63;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-pink-500 a,
.alert-material-pink-500 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-pink-600 {
  background-color: #d81b60;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-pink-600 a,
.alert-material-pink-600 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-pink-700 {
  background-color: #c2185b;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-pink-700 a,
.alert-material-pink-700 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-pink-800 {
  background-color: #ad1457;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-pink-800 a,
.alert-material-pink-800 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-pink-900 {
  background-color: #880e4f;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-pink-900 a,
.alert-material-pink-900 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-pink-A100 {
  background-color: #ff80ab;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-pink-A100 a,
.alert-material-pink-A100 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-pink-A200 {
  background-color: #ff4081;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-pink-A200 a,
.alert-material-pink-A200 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-pink-A400 {
  background-color: #f50057;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-pink-A400 a,
.alert-material-pink-A400 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-pink-A700 {
  background-color: #c51162;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-pink-A700 a,
.alert-material-pink-A700 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-purple {
  background-color: #9c27b0;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-purple a,
.alert-material-purple .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-purple-50 {
  background-color: #f3e5f5;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-purple-50 a,
.alert-material-purple-50 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-purple-100 {
  background-color: #e1bee7;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-purple-100 a,
.alert-material-purple-100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-purple-200 {
  background-color: #ce93d8;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-purple-200 a,
.alert-material-purple-200 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-purple-300 {
  background-color: #ba68c8;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-purple-300 a,
.alert-material-purple-300 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-purple-400 {
  background-color: #ab47bc;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-purple-400 a,
.alert-material-purple-400 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-purple-500 {
  background-color: #9c27b0;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-purple-500 a,
.alert-material-purple-500 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-purple-600 {
  background-color: #8e24aa;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-purple-600 a,
.alert-material-purple-600 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-purple-700 {
  background-color: #7b1fa2;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-purple-700 a,
.alert-material-purple-700 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-purple-800 {
  background-color: #6a1b9a;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-purple-800 a,
.alert-material-purple-800 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-purple-900 {
  background-color: #4a148c;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-purple-900 a,
.alert-material-purple-900 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-purple-A100 {
  background-color: #ea80fc;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-purple-A100 a,
.alert-material-purple-A100 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-purple-A200 {
  background-color: #e040fb;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-purple-A200 a,
.alert-material-purple-A200 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-purple-A400 {
  background-color: #d500f9;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-purple-A400 a,
.alert-material-purple-A400 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-purple-A700 {
  background-color: #aa00ff;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-purple-A700 a,
.alert-material-purple-A700 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-purple {
  background-color: #673ab7;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-purple a,
.alert-material-deep-purple .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-purple-50 {
  background-color: #ede7f6;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-deep-purple-50 a,
.alert-material-deep-purple-50 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-deep-purple-100 {
  background-color: #d1c4e9;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-deep-purple-100 a,
.alert-material-deep-purple-100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-deep-purple-200 {
  background-color: #b39ddb;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-purple-200 a,
.alert-material-deep-purple-200 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-purple-300 {
  background-color: #9575cd;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-purple-300 a,
.alert-material-deep-purple-300 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-purple-400 {
  background-color: #7e57c2;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-purple-400 a,
.alert-material-deep-purple-400 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-purple-500 {
  background-color: #673ab7;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-purple-500 a,
.alert-material-deep-purple-500 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-purple-600 {
  background-color: #5e35b1;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-purple-600 a,
.alert-material-deep-purple-600 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-purple-700 {
  background-color: #512da8;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-purple-700 a,
.alert-material-deep-purple-700 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-purple-800 {
  background-color: #4527a0;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-purple-800 a,
.alert-material-deep-purple-800 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-purple-900 {
  background-color: #311b92;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-purple-900 a,
.alert-material-deep-purple-900 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-purple-A100 {
  background-color: #b388ff;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-purple-A100 a,
.alert-material-deep-purple-A100 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-purple-A200 {
  background-color: #7c4dff;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-purple-A200 a,
.alert-material-deep-purple-A200 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-purple-A400 {
  background-color: #651fff;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-purple-A400 a,
.alert-material-deep-purple-A400 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-purple-A700 {
  background-color: #6200ea;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-purple-A700 a,
.alert-material-deep-purple-A700 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-indigo {
  background-color: #3f51b5;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-indigo a,
.alert-material-indigo .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-indigo-50 {
  background-color: #e8eaf6;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-indigo-50 a,
.alert-material-indigo-50 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-indigo-100 {
  background-color: #c5cae9;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-indigo-100 a,
.alert-material-indigo-100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-indigo-200 {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-indigo-200 a,
.alert-material-indigo-200 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-indigo-300 {
  background-color: #7986cb;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-indigo-300 a,
.alert-material-indigo-300 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-indigo-400 {
  background-color: #5c6bc0;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-indigo-400 a,
.alert-material-indigo-400 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-indigo-500 {
  background-color: #3f51b5;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-indigo-500 a,
.alert-material-indigo-500 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-indigo-600 {
  background-color: #3949ab;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-indigo-600 a,
.alert-material-indigo-600 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-indigo-700 {
  background-color: #303f9f;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-indigo-700 a,
.alert-material-indigo-700 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-indigo-800 {
  background-color: #283593;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-indigo-800 a,
.alert-material-indigo-800 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-indigo-900 {
  background-color: #1a237e;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-indigo-900 a,
.alert-material-indigo-900 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-indigo-A100 {
  background-color: #8c9eff;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-indigo-A100 a,
.alert-material-indigo-A100 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-indigo-A200 {
  background-color: #536dfe;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-indigo-A200 a,
.alert-material-indigo-A200 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-indigo-A400 {
  background-color: #3d5afe;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-indigo-A400 a,
.alert-material-indigo-A400 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-indigo-A700 {
  background-color: #304ffe;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-indigo-A700 a,
.alert-material-indigo-A700 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue {
  background-color: #2196f3;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue a,
.alert-material-blue .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-50 {
  background-color: #e3f2fd;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-blue-50 a,
.alert-material-blue-50 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-blue-100 {
  background-color: #bbdefb;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-blue-100 a,
.alert-material-blue-100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-blue-200 {
  background-color: #90caf9;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-blue-200 a,
.alert-material-blue-200 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-blue-300 {
  background-color: #64b5f6;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-blue-300 a,
.alert-material-blue-300 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-blue-400 {
  background-color: #42a5f5;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-400 a,
.alert-material-blue-400 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-500 {
  background-color: #2196f3;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-500 a,
.alert-material-blue-500 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-600 {
  background-color: #1e88e5;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-600 a,
.alert-material-blue-600 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-700 {
  background-color: #1976d2;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-700 a,
.alert-material-blue-700 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-800 {
  background-color: #1565c0;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-800 a,
.alert-material-blue-800 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-900 {
  background-color: #0d47a1;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-900 a,
.alert-material-blue-900 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-A100 {
  background-color: #82b1ff;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-blue-A100 a,
.alert-material-blue-A100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-blue-A200 {
  background-color: #448aff;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-A200 a,
.alert-material-blue-A200 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-A400 {
  background-color: #2979ff;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-A400 a,
.alert-material-blue-A400 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-A700 {
  background-color: #2962ff;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-A700 a,
.alert-material-blue-A700 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-light-blue {
  background-color: #03a9f4;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-light-blue a,
.alert-material-light-blue .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-light-blue-50 {
  background-color: #e1f5fe;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-blue-50 a,
.alert-material-light-blue-50 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-blue-100 {
  background-color: #b3e5fc;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-blue-100 a,
.alert-material-light-blue-100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-blue-200 {
  background-color: #81d4fa;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-blue-200 a,
.alert-material-light-blue-200 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-blue-300 {
  background-color: #4fc3f7;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-blue-300 a,
.alert-material-light-blue-300 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-blue-400 {
  background-color: #29b6f6;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-blue-400 a,
.alert-material-light-blue-400 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-blue-500 {
  background-color: #03a9f4;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-light-blue-500 a,
.alert-material-light-blue-500 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-light-blue-600 {
  background-color: #039be5;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-light-blue-600 a,
.alert-material-light-blue-600 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-light-blue-700 {
  background-color: #0288d1;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-light-blue-700 a,
.alert-material-light-blue-700 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-light-blue-800 {
  background-color: #0277bd;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-light-blue-800 a,
.alert-material-light-blue-800 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-light-blue-900 {
  background-color: #01579b;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-light-blue-900 a,
.alert-material-light-blue-900 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-light-blue-A100 {
  background-color: #80d8ff;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-blue-A100 a,
.alert-material-light-blue-A100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-blue-A200 {
  background-color: #40c4ff;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-blue-A200 a,
.alert-material-light-blue-A200 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-blue-A400 {
  background-color: #00b0ff;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-light-blue-A400 a,
.alert-material-light-blue-A400 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-light-blue-A700 {
  background-color: #0091ea;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-light-blue-A700 a,
.alert-material-light-blue-A700 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-cyan {
  background-color: #00bcd4;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-cyan a,
.alert-material-cyan .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-cyan-50 {
  background-color: #e0f7fa;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-cyan-50 a,
.alert-material-cyan-50 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-cyan-100 {
  background-color: #b2ebf2;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-cyan-100 a,
.alert-material-cyan-100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-cyan-200 {
  background-color: #80deea;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-cyan-200 a,
.alert-material-cyan-200 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-cyan-300 {
  background-color: #4dd0e1;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-cyan-300 a,
.alert-material-cyan-300 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-cyan-400 {
  background-color: #26c6da;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-cyan-400 a,
.alert-material-cyan-400 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-cyan-500 {
  background-color: #00bcd4;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-cyan-500 a,
.alert-material-cyan-500 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-cyan-600 {
  background-color: #00acc1;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-cyan-600 a,
.alert-material-cyan-600 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-cyan-700 {
  background-color: #0097a7;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-cyan-700 a,
.alert-material-cyan-700 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-cyan-800 {
  background-color: #00838f;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-cyan-800 a,
.alert-material-cyan-800 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-cyan-900 {
  background-color: #006064;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-cyan-900 a,
.alert-material-cyan-900 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-cyan-A100 {
  background-color: #84ffff;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-cyan-A100 a,
.alert-material-cyan-A100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-cyan-A200 {
  background-color: #18ffff;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-cyan-A200 a,
.alert-material-cyan-A200 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-cyan-A400 {
  background-color: #00e5ff;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-cyan-A400 a,
.alert-material-cyan-A400 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-cyan-A700 {
  background-color: #00b8d4;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-cyan-A700 a,
.alert-material-cyan-A700 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-teal {
  background-color: #009688;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-teal a,
.alert-material-teal .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-teal-50 {
  background-color: #e0f2f1;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-teal-50 a,
.alert-material-teal-50 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-teal-100 {
  background-color: #b2dfdb;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-teal-100 a,
.alert-material-teal-100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-teal-200 {
  background-color: #80cbc4;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-teal-200 a,
.alert-material-teal-200 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-teal-300 {
  background-color: #4db6ac;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-teal-300 a,
.alert-material-teal-300 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-teal-400 {
  background-color: #26a69a;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-teal-400 a,
.alert-material-teal-400 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-teal-500 {
  background-color: #009688;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-teal-500 a,
.alert-material-teal-500 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-teal-600 {
  background-color: #00897b;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-teal-600 a,
.alert-material-teal-600 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-teal-700 {
  background-color: #00796b;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-teal-700 a,
.alert-material-teal-700 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-teal-800 {
  background-color: #00695c;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-teal-800 a,
.alert-material-teal-800 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-teal-900 {
  background-color: #004d40;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-teal-900 a,
.alert-material-teal-900 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-teal-A100 {
  background-color: #a7ffeb;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-teal-A100 a,
.alert-material-teal-A100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-teal-A200 {
  background-color: #64ffda;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-teal-A200 a,
.alert-material-teal-A200 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-teal-A400 {
  background-color: #1de9b6;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-teal-A400 a,
.alert-material-teal-A400 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-teal-A700 {
  background-color: #00bfa5;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-teal-A700 a,
.alert-material-teal-A700 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-green {
  background-color: #4caf50;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-green a,
.alert-material-green .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-green-50 {
  background-color: #e8f5e9;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-green-50 a,
.alert-material-green-50 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-green-100 {
  background-color: #c8e6c9;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-green-100 a,
.alert-material-green-100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-green-200 {
  background-color: #a5d6a7;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-green-200 a,
.alert-material-green-200 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-green-300 {
  background-color: #81c784;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-green-300 a,
.alert-material-green-300 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-green-400 {
  background-color: #66bb6a;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-green-400 a,
.alert-material-green-400 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-green-500 {
  background-color: #4caf50;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-green-500 a,
.alert-material-green-500 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-green-600 {
  background-color: #43a047;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-green-600 a,
.alert-material-green-600 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-green-700 {
  background-color: #388e3c;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-green-700 a,
.alert-material-green-700 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-green-800 {
  background-color: #2e7d32;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-green-800 a,
.alert-material-green-800 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-green-900 {
  background-color: #1b5e20;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-green-900 a,
.alert-material-green-900 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-green-A100 {
  background-color: #b9f6ca;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-green-A100 a,
.alert-material-green-A100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-green-A200 {
  background-color: #69f0ae;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-green-A200 a,
.alert-material-green-A200 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-green-A400 {
  background-color: #00e676;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-green-A400 a,
.alert-material-green-A400 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-green-A700 {
  background-color: #00c853;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-green-A700 a,
.alert-material-green-A700 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-green {
  background-color: #8bc34a;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-green a,
.alert-material-light-green .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-green-50 {
  background-color: #f1f8e9;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-green-50 a,
.alert-material-light-green-50 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-green-100 {
  background-color: #dcedc8;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-green-100 a,
.alert-material-light-green-100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-green-200 {
  background-color: #c5e1a5;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-green-200 a,
.alert-material-light-green-200 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-green-300 {
  background-color: #aed581;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-green-300 a,
.alert-material-light-green-300 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-green-400 {
  background-color: #9ccc65;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-green-400 a,
.alert-material-light-green-400 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-green-500 {
  background-color: #8bc34a;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-green-500 a,
.alert-material-light-green-500 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-green-600 {
  background-color: #7cb342;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-light-green-600 a,
.alert-material-light-green-600 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-light-green-700 {
  background-color: #689f38;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-light-green-700 a,
.alert-material-light-green-700 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-light-green-800 {
  background-color: #558b2f;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-light-green-800 a,
.alert-material-light-green-800 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-light-green-900 {
  background-color: #33691e;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-light-green-900 a,
.alert-material-light-green-900 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-light-green-A100 {
  background-color: #ccff90;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-green-A100 a,
.alert-material-light-green-A100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-green-A200 {
  background-color: #b2ff59;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-green-A200 a,
.alert-material-light-green-A200 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-green-A400 {
  background-color: #76ff03;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-green-A400 a,
.alert-material-light-green-A400 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-green-A700 {
  background-color: #64dd17;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-light-green-A700 a,
.alert-material-light-green-A700 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-lime {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-lime a,
.alert-material-lime .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-lime-50 {
  background-color: #f9fbe7;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-lime-50 a,
.alert-material-lime-50 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-lime-100 {
  background-color: #f0f4c3;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-lime-100 a,
.alert-material-lime-100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-lime-200 {
  background-color: #e6ee9c;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-lime-200 a,
.alert-material-lime-200 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-lime-300 {
  background-color: #dce775;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-lime-300 a,
.alert-material-lime-300 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-lime-400 {
  background-color: #d4e157;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-lime-400 a,
.alert-material-lime-400 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-lime-500 {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-lime-500 a,
.alert-material-lime-500 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-lime-600 {
  background-color: #c0ca33;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-lime-600 a,
.alert-material-lime-600 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-lime-700 {
  background-color: #afb42b;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-lime-700 a,
.alert-material-lime-700 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-lime-800 {
  background-color: #9e9d24;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-lime-800 a,
.alert-material-lime-800 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-lime-900 {
  background-color: #827717;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-lime-900 a,
.alert-material-lime-900 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-lime-A100 {
  background-color: #f4ff81;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-lime-A100 a,
.alert-material-lime-A100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-lime-A200 {
  background-color: #eeff41;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-lime-A200 a,
.alert-material-lime-A200 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-lime-A400 {
  background-color: #c6ff00;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-lime-A400 a,
.alert-material-lime-A400 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-lime-A700 {
  background-color: #aeea00;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-lime-A700 a,
.alert-material-lime-A700 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-yellow {
  background-color: #ffeb3b;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-yellow a,
.alert-material-yellow .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-yellow-50 {
  background-color: #fffde7;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-yellow-50 a,
.alert-material-yellow-50 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-yellow-100 {
  background-color: #fff9c4;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-yellow-100 a,
.alert-material-yellow-100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-yellow-200 {
  background-color: #fff59d;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-yellow-200 a,
.alert-material-yellow-200 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-yellow-300 {
  background-color: #fff176;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-yellow-300 a,
.alert-material-yellow-300 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-yellow-400 {
  background-color: #ffee58;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-yellow-400 a,
.alert-material-yellow-400 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-yellow-500 {
  background-color: #ffeb3b;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-yellow-500 a,
.alert-material-yellow-500 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-yellow-600 {
  background-color: #fdd835;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-yellow-600 a,
.alert-material-yellow-600 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-yellow-700 {
  background-color: #fbc02d;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-yellow-700 a,
.alert-material-yellow-700 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-yellow-800 {
  background-color: #f9a825;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-yellow-800 a,
.alert-material-yellow-800 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-yellow-900 {
  background-color: #f57f17;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-yellow-900 a,
.alert-material-yellow-900 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-yellow-A100 {
  background-color: #ffff8d;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-yellow-A100 a,
.alert-material-yellow-A100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-yellow-A200 {
  background-color: #ffff00;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-yellow-A200 a,
.alert-material-yellow-A200 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-yellow-A400 {
  background-color: #ffea00;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-yellow-A400 a,
.alert-material-yellow-A400 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-yellow-A700 {
  background-color: #ffd600;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-yellow-A700 a,
.alert-material-yellow-A700 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-amber {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-amber a,
.alert-material-amber .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-amber-50 {
  background-color: #fff8e1;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-amber-50 a,
.alert-material-amber-50 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-amber-100 {
  background-color: #ffecb3;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-amber-100 a,
.alert-material-amber-100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-amber-200 {
  background-color: #ffe082;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-amber-200 a,
.alert-material-amber-200 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-amber-300 {
  background-color: #ffd54f;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-amber-300 a,
.alert-material-amber-300 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-amber-400 {
  background-color: #ffca28;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-amber-400 a,
.alert-material-amber-400 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-amber-500 {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-amber-500 a,
.alert-material-amber-500 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-amber-600 {
  background-color: #ffb300;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-amber-600 a,
.alert-material-amber-600 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-amber-700 {
  background-color: #ffa000;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-amber-700 a,
.alert-material-amber-700 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-amber-800 {
  background-color: #ff8f00;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-amber-800 a,
.alert-material-amber-800 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-amber-900 {
  background-color: #ff6f00;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-amber-900 a,
.alert-material-amber-900 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-amber-A100 {
  background-color: #ffe57f;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-amber-A100 a,
.alert-material-amber-A100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-amber-A200 {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-amber-A200 a,
.alert-material-amber-A200 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-amber-A400 {
  background-color: #ffc400;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-amber-A400 a,
.alert-material-amber-A400 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-amber-A700 {
  background-color: #ffab00;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-amber-A700 a,
.alert-material-amber-A700 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-orange {
  background-color: #ff9800;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-orange a,
.alert-material-orange .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-orange-50 {
  background-color: #fff3e0;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-orange-50 a,
.alert-material-orange-50 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-orange-100 {
  background-color: #ffe0b2;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-orange-100 a,
.alert-material-orange-100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-orange-200 {
  background-color: #ffcc80;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-orange-200 a,
.alert-material-orange-200 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-orange-300 {
  background-color: #ffb74d;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-orange-300 a,
.alert-material-orange-300 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-orange-400 {
  background-color: #ffa726;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-orange-400 a,
.alert-material-orange-400 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-orange-500 {
  background-color: #ff9800;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-orange-500 a,
.alert-material-orange-500 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-orange-600 {
  background-color: #fb8c00;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-orange-600 a,
.alert-material-orange-600 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-orange-700 {
  background-color: #f57c00;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-orange-700 a,
.alert-material-orange-700 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-orange-800 {
  background-color: #ef6c00;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-orange-800 a,
.alert-material-orange-800 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-orange-900 {
  background-color: #e65100;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-orange-900 a,
.alert-material-orange-900 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-orange-A100 {
  background-color: #ffd180;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-orange-A100 a,
.alert-material-orange-A100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-orange-A200 {
  background-color: #ffab40;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-orange-A200 a,
.alert-material-orange-A200 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-orange-A400 {
  background-color: #ff9100;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-orange-A400 a,
.alert-material-orange-A400 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-orange-A700 {
  background-color: #ff6d00;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-orange-A700 a,
.alert-material-orange-A700 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-orange {
  background-color: #ff5722;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-orange a,
.alert-material-deep-orange .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-orange-50 {
  background-color: #fbe9e7;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-deep-orange-50 a,
.alert-material-deep-orange-50 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-deep-orange-100 {
  background-color: #ffccbc;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-deep-orange-100 a,
.alert-material-deep-orange-100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-deep-orange-200 {
  background-color: #ffab91;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-deep-orange-200 a,
.alert-material-deep-orange-200 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-deep-orange-300 {
  background-color: #ff8a65;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-deep-orange-300 a,
.alert-material-deep-orange-300 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-deep-orange-400 {
  background-color: #ff7043;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-orange-400 a,
.alert-material-deep-orange-400 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-orange-500 {
  background-color: #ff5722;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-orange-500 a,
.alert-material-deep-orange-500 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-orange-600 {
  background-color: #f4511e;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-orange-600 a,
.alert-material-deep-orange-600 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-orange-700 {
  background-color: #e64a19;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-orange-700 a,
.alert-material-deep-orange-700 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-orange-800 {
  background-color: #d84315;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-orange-800 a,
.alert-material-deep-orange-800 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-orange-900 {
  background-color: #bf360c;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-orange-900 a,
.alert-material-deep-orange-900 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-orange-A100 {
  background-color: #ff9e80;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-deep-orange-A100 a,
.alert-material-deep-orange-A100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-deep-orange-A200 {
  background-color: #ff6e40;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-orange-A200 a,
.alert-material-deep-orange-A200 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-orange-A400 {
  background-color: #ff3d00;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-orange-A400 a,
.alert-material-deep-orange-A400 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-orange-A700 {
  background-color: #dd2c00;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-deep-orange-A700 a,
.alert-material-deep-orange-A700 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-brown {
  background-color: #795548;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-brown a,
.alert-material-brown .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-brown-50 {
  background-color: #efebe9;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-brown-50 a,
.alert-material-brown-50 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-brown-100 {
  background-color: #d7ccc8;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-brown-100 a,
.alert-material-brown-100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-brown-200 {
  background-color: #bcaaa4;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-brown-200 a,
.alert-material-brown-200 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-brown-300 {
  background-color: #a1887f;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-brown-300 a,
.alert-material-brown-300 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-brown-400 {
  background-color: #8d6e63;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-brown-400 a,
.alert-material-brown-400 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-brown-500 {
  background-color: #795548;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-brown-500 a,
.alert-material-brown-500 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-brown-600 {
  background-color: #6d4c41;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-brown-600 a,
.alert-material-brown-600 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-brown-700 {
  background-color: #5d4037;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-brown-700 a,
.alert-material-brown-700 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-brown-800 {
  background-color: #4e342e;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-brown-800 a,
.alert-material-brown-800 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-brown-900 {
  background-color: #3e2723;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-brown-900 a,
.alert-material-brown-900 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-brown-A100 {
  background-color: #d7ccc8;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-brown-A100 a,
.alert-material-brown-A100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-brown-A200 {
  background-color: #bcaaa4;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-brown-A200 a,
.alert-material-brown-A200 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-brown-A400 {
  background-color: #8d6e63;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-brown-A400 a,
.alert-material-brown-A400 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-brown-A700 {
  background-color: #5d4037;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-brown-A700 a,
.alert-material-brown-A700 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-grey {
  background-color: #9e9e9e;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-grey a,
.alert-material-grey .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-grey-50 {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-grey-50 a,
.alert-material-grey-50 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-grey-100 {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-grey-100 a,
.alert-material-grey-100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-grey-200 {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-grey-200 a,
.alert-material-grey-200 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-grey-300 {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-grey-300 a,
.alert-material-grey-300 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-grey-400 {
  background-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-grey-400 a,
.alert-material-grey-400 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-grey-500 {
  background-color: #9e9e9e;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-grey-500 a,
.alert-material-grey-500 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-grey-600 {
  background-color: #757575;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-grey-600 a,
.alert-material-grey-600 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-grey-700 {
  background-color: #616161;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-grey-700 a,
.alert-material-grey-700 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-grey-800 {
  background-color: #424242;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-grey-800 a,
.alert-material-grey-800 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-grey-900 {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-grey-900 a,
.alert-material-grey-900 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-grey-A100 {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-grey-A100 a,
.alert-material-grey-A100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-grey-A200 {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-grey-A200 a,
.alert-material-grey-A200 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-grey-A400 {
  background-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-grey-A400 a,
.alert-material-grey-A400 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-grey-A700 {
  background-color: #616161;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-grey-A700 a,
.alert-material-grey-A700 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-grey {
  background-color: #607d8b;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-grey a,
.alert-material-blue-grey .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-grey-50 {
  background-color: #eceff1;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-blue-grey-50 a,
.alert-material-blue-grey-50 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-blue-grey-100 {
  background-color: #cfd8dc;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-blue-grey-100 a,
.alert-material-blue-grey-100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-blue-grey-200 {
  background-color: #b0bec5;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-blue-grey-200 a,
.alert-material-blue-grey-200 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-blue-grey-300 {
  background-color: #90a4ae;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-grey-300 a,
.alert-material-blue-grey-300 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-grey-400 {
  background-color: #78909c;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-grey-400 a,
.alert-material-blue-grey-400 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-grey-500 {
  background-color: #607d8b;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-grey-500 a,
.alert-material-blue-grey-500 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-grey-600 {
  background-color: #546e7a;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-grey-600 a,
.alert-material-blue-grey-600 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-grey-700 {
  background-color: #455a64;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-grey-700 a,
.alert-material-blue-grey-700 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-grey-800 {
  background-color: #37474f;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-grey-800 a,
.alert-material-blue-grey-800 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-grey-900 {
  background-color: #263238;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-grey-900 a,
.alert-material-blue-grey-900 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-grey-A100 {
  background-color: #cfd8dc;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-blue-grey-A100 a,
.alert-material-blue-grey-A100 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-blue-grey-A200 {
  background-color: #b0bec5;
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-blue-grey-A200 a,
.alert-material-blue-grey-A200 .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.alert-material-blue-grey-A400 {
  background-color: #78909c;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-grey-A400 a,
.alert-material-blue-grey-A400 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-grey-A700 {
  background-color: #455a64;
  color: rgba(255, 255, 255, 0.84);
}
.alert-material-blue-grey-A700 a,
.alert-material-blue-grey-A700 .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-info,
.alert-danger,
.alert-warning,
.alert-success {
  color: rgba(255, 255, 255, 0.84);
}
.alert-default a,
.alert-default .alert-link {
  color: rgba(0, 0, 0, 0.84);
}
.progress {
  height: 4px;
  border-radius: 0;
  box-shadow: none;
  background: #c8c8c8;
}
.progress .progress-bar {
  box-shadow: none;
}
.progress .progress-bar,
.progress .progress-bar-default {
  background-color: #009688;
}
.progress .progress-bar-black {
  background-color: #000000;
}
.progress .progress-bar-white {
  background-color: #ffffff;
}
.progress .progress-bar-inverse {
  background-color: #3f51b5;
}
.progress .progress-bar-primary {
  background-color: #009688;
}
.progress .progress-bar-success {
  background-color: #4caf50;
}
.progress .progress-bar-info {
  background-color: #03a9f4;
}
.progress .progress-bar-warning {
  background-color: #ff5722;
}
.progress .progress-bar-danger {
  background-color: #f44336;
}
.progress .progress-bar-material-red {
  background-color: #f44336;
}
.progress .progress-bar-material-red-50 {
  background-color: #ffebee;
}
.progress .progress-bar-material-red-100 {
  background-color: #ffcdd2;
}
.progress .progress-bar-material-red-200 {
  background-color: #ef9a9a;
}
.progress .progress-bar-material-red-300 {
  background-color: #e57373;
}
.progress .progress-bar-material-red-400 {
  background-color: #ef5350;
}
.progress .progress-bar-material-red-500 {
  background-color: #f44336;
}
.progress .progress-bar-material-red-600 {
  background-color: #e53935;
}
.progress .progress-bar-material-red-700 {
  background-color: #d32f2f;
}
.progress .progress-bar-material-red-800 {
  background-color: #c62828;
}
.progress .progress-bar-material-red-900 {
  background-color: #b71c1c;
}
.progress .progress-bar-material-red-A100 {
  background-color: #ff8a80;
}
.progress .progress-bar-material-red-A200 {
  background-color: #ff5252;
}
.progress .progress-bar-material-red-A400 {
  background-color: #ff1744;
}
.progress .progress-bar-material-red-A700 {
  background-color: #d50000;
}
.progress .progress-bar-material-pink {
  background-color: #e91e63;
}
.progress .progress-bar-material-pink-50 {
  background-color: #fce4ec;
}
.progress .progress-bar-material-pink-100 {
  background-color: #f8bbd0;
}
.progress .progress-bar-material-pink-200 {
  background-color: #f48fb1;
}
.progress .progress-bar-material-pink-300 {
  background-color: #f06292;
}
.progress .progress-bar-material-pink-400 {
  background-color: #ec407a;
}
.progress .progress-bar-material-pink-500 {
  background-color: #e91e63;
}
.progress .progress-bar-material-pink-600 {
  background-color: #d81b60;
}
.progress .progress-bar-material-pink-700 {
  background-color: #c2185b;
}
.progress .progress-bar-material-pink-800 {
  background-color: #ad1457;
}
.progress .progress-bar-material-pink-900 {
  background-color: #880e4f;
}
.progress .progress-bar-material-pink-A100 {
  background-color: #ff80ab;
}
.progress .progress-bar-material-pink-A200 {
  background-color: #ff4081;
}
.progress .progress-bar-material-pink-A400 {
  background-color: #f50057;
}
.progress .progress-bar-material-pink-A700 {
  background-color: #c51162;
}
.progress .progress-bar-material-purple {
  background-color: #9c27b0;
}
.progress .progress-bar-material-purple-50 {
  background-color: #f3e5f5;
}
.progress .progress-bar-material-purple-100 {
  background-color: #e1bee7;
}
.progress .progress-bar-material-purple-200 {
  background-color: #ce93d8;
}
.progress .progress-bar-material-purple-300 {
  background-color: #ba68c8;
}
.progress .progress-bar-material-purple-400 {
  background-color: #ab47bc;
}
.progress .progress-bar-material-purple-500 {
  background-color: #9c27b0;
}
.progress .progress-bar-material-purple-600 {
  background-color: #8e24aa;
}
.progress .progress-bar-material-purple-700 {
  background-color: #7b1fa2;
}
.progress .progress-bar-material-purple-800 {
  background-color: #6a1b9a;
}
.progress .progress-bar-material-purple-900 {
  background-color: #4a148c;
}
.progress .progress-bar-material-purple-A100 {
  background-color: #ea80fc;
}
.progress .progress-bar-material-purple-A200 {
  background-color: #e040fb;
}
.progress .progress-bar-material-purple-A400 {
  background-color: #d500f9;
}
.progress .progress-bar-material-purple-A700 {
  background-color: #aa00ff;
}
.progress .progress-bar-material-deep-purple {
  background-color: #673ab7;
}
.progress .progress-bar-material-deep-purple-50 {
  background-color: #ede7f6;
}
.progress .progress-bar-material-deep-purple-100 {
  background-color: #d1c4e9;
}
.progress .progress-bar-material-deep-purple-200 {
  background-color: #b39ddb;
}
.progress .progress-bar-material-deep-purple-300 {
  background-color: #9575cd;
}
.progress .progress-bar-material-deep-purple-400 {
  background-color: #7e57c2;
}
.progress .progress-bar-material-deep-purple-500 {
  background-color: #673ab7;
}
.progress .progress-bar-material-deep-purple-600 {
  background-color: #5e35b1;
}
.progress .progress-bar-material-deep-purple-700 {
  background-color: #512da8;
}
.progress .progress-bar-material-deep-purple-800 {
  background-color: #4527a0;
}
.progress .progress-bar-material-deep-purple-900 {
  background-color: #311b92;
}
.progress .progress-bar-material-deep-purple-A100 {
  background-color: #b388ff;
}
.progress .progress-bar-material-deep-purple-A200 {
  background-color: #7c4dff;
}
.progress .progress-bar-material-deep-purple-A400 {
  background-color: #651fff;
}
.progress .progress-bar-material-deep-purple-A700 {
  background-color: #6200ea;
}
.progress .progress-bar-material-indigo {
  background-color: #3f51b5;
}
.progress .progress-bar-material-indigo-50 {
  background-color: #e8eaf6;
}
.progress .progress-bar-material-indigo-100 {
  background-color: #c5cae9;
}
.progress .progress-bar-material-indigo-200 {
  background-color: #9fa8da;
}
.progress .progress-bar-material-indigo-300 {
  background-color: #7986cb;
}
.progress .progress-bar-material-indigo-400 {
  background-color: #5c6bc0;
}
.progress .progress-bar-material-indigo-500 {
  background-color: #3f51b5;
}
.progress .progress-bar-material-indigo-600 {
  background-color: #3949ab;
}
.progress .progress-bar-material-indigo-700 {
  background-color: #303f9f;
}
.progress .progress-bar-material-indigo-800 {
  background-color: #283593;
}
.progress .progress-bar-material-indigo-900 {
  background-color: #1a237e;
}
.progress .progress-bar-material-indigo-A100 {
  background-color: #8c9eff;
}
.progress .progress-bar-material-indigo-A200 {
  background-color: #536dfe;
}
.progress .progress-bar-material-indigo-A400 {
  background-color: #3d5afe;
}
.progress .progress-bar-material-indigo-A700 {
  background-color: #304ffe;
}
.progress .progress-bar-material-blue {
  background-color: #2196f3;
}
.progress .progress-bar-material-blue-50 {
  background-color: #e3f2fd;
}
.progress .progress-bar-material-blue-100 {
  background-color: #bbdefb;
}
.progress .progress-bar-material-blue-200 {
  background-color: #90caf9;
}
.progress .progress-bar-material-blue-300 {
  background-color: #64b5f6;
}
.progress .progress-bar-material-blue-400 {
  background-color: #42a5f5;
}
.progress .progress-bar-material-blue-500 {
  background-color: #2196f3;
}
.progress .progress-bar-material-blue-600 {
  background-color: #1e88e5;
}
.progress .progress-bar-material-blue-700 {
  background-color: #1976d2;
}
.progress .progress-bar-material-blue-800 {
  background-color: #1565c0;
}
.progress .progress-bar-material-blue-900 {
  background-color: #0d47a1;
}
.progress .progress-bar-material-blue-A100 {
  background-color: #82b1ff;
}
.progress .progress-bar-material-blue-A200 {
  background-color: #448aff;
}
.progress .progress-bar-material-blue-A400 {
  background-color: #2979ff;
}
.progress .progress-bar-material-blue-A700 {
  background-color: #2962ff;
}
.progress .progress-bar-material-light-blue {
  background-color: #03a9f4;
}
.progress .progress-bar-material-light-blue-50 {
  background-color: #e1f5fe;
}
.progress .progress-bar-material-light-blue-100 {
  background-color: #b3e5fc;
}
.progress .progress-bar-material-light-blue-200 {
  background-color: #81d4fa;
}
.progress .progress-bar-material-light-blue-300 {
  background-color: #4fc3f7;
}
.progress .progress-bar-material-light-blue-400 {
  background-color: #29b6f6;
}
.progress .progress-bar-material-light-blue-500 {
  background-color: #03a9f4;
}
.progress .progress-bar-material-light-blue-600 {
  background-color: #039be5;
}
.progress .progress-bar-material-light-blue-700 {
  background-color: #0288d1;
}
.progress .progress-bar-material-light-blue-800 {
  background-color: #0277bd;
}
.progress .progress-bar-material-light-blue-900 {
  background-color: #01579b;
}
.progress .progress-bar-material-light-blue-A100 {
  background-color: #80d8ff;
}
.progress .progress-bar-material-light-blue-A200 {
  background-color: #40c4ff;
}
.progress .progress-bar-material-light-blue-A400 {
  background-color: #00b0ff;
}
.progress .progress-bar-material-light-blue-A700 {
  background-color: #0091ea;
}
.progress .progress-bar-material-cyan {
  background-color: #00bcd4;
}
.progress .progress-bar-material-cyan-50 {
  background-color: #e0f7fa;
}
.progress .progress-bar-material-cyan-100 {
  background-color: #b2ebf2;
}
.progress .progress-bar-material-cyan-200 {
  background-color: #80deea;
}
.progress .progress-bar-material-cyan-300 {
  background-color: #4dd0e1;
}
.progress .progress-bar-material-cyan-400 {
  background-color: #26c6da;
}
.progress .progress-bar-material-cyan-500 {
  background-color: #00bcd4;
}
.progress .progress-bar-material-cyan-600 {
  background-color: #00acc1;
}
.progress .progress-bar-material-cyan-700 {
  background-color: #0097a7;
}
.progress .progress-bar-material-cyan-800 {
  background-color: #00838f;
}
.progress .progress-bar-material-cyan-900 {
  background-color: #006064;
}
.progress .progress-bar-material-cyan-A100 {
  background-color: #84ffff;
}
.progress .progress-bar-material-cyan-A200 {
  background-color: #18ffff;
}
.progress .progress-bar-material-cyan-A400 {
  background-color: #00e5ff;
}
.progress .progress-bar-material-cyan-A700 {
  background-color: #00b8d4;
}
.progress .progress-bar-material-teal {
  background-color: #009688;
}
.progress .progress-bar-material-teal-50 {
  background-color: #e0f2f1;
}
.progress .progress-bar-material-teal-100 {
  background-color: #b2dfdb;
}
.progress .progress-bar-material-teal-200 {
  background-color: #80cbc4;
}
.progress .progress-bar-material-teal-300 {
  background-color: #4db6ac;
}
.progress .progress-bar-material-teal-400 {
  background-color: #26a69a;
}
.progress .progress-bar-material-teal-500 {
  background-color: #009688;
}
.progress .progress-bar-material-teal-600 {
  background-color: #00897b;
}
.progress .progress-bar-material-teal-700 {
  background-color: #00796b;
}
.progress .progress-bar-material-teal-800 {
  background-color: #00695c;
}
.progress .progress-bar-material-teal-900 {
  background-color: #004d40;
}
.progress .progress-bar-material-teal-A100 {
  background-color: #a7ffeb;
}
.progress .progress-bar-material-teal-A200 {
  background-color: #64ffda;
}
.progress .progress-bar-material-teal-A400 {
  background-color: #1de9b6;
}
.progress .progress-bar-material-teal-A700 {
  background-color: #00bfa5;
}
.progress .progress-bar-material-green {
  background-color: #4caf50;
}
.progress .progress-bar-material-green-50 {
  background-color: #e8f5e9;
}
.progress .progress-bar-material-green-100 {
  background-color: #c8e6c9;
}
.progress .progress-bar-material-green-200 {
  background-color: #a5d6a7;
}
.progress .progress-bar-material-green-300 {
  background-color: #81c784;
}
.progress .progress-bar-material-green-400 {
  background-color: #66bb6a;
}
.progress .progress-bar-material-green-500 {
  background-color: #4caf50;
}
.progress .progress-bar-material-green-600 {
  background-color: #43a047;
}
.progress .progress-bar-material-green-700 {
  background-color: #388e3c;
}
.progress .progress-bar-material-green-800 {
  background-color: #2e7d32;
}
.progress .progress-bar-material-green-900 {
  background-color: #1b5e20;
}
.progress .progress-bar-material-green-A100 {
  background-color: #b9f6ca;
}
.progress .progress-bar-material-green-A200 {
  background-color: #69f0ae;
}
.progress .progress-bar-material-green-A400 {
  background-color: #00e676;
}
.progress .progress-bar-material-green-A700 {
  background-color: #00c853;
}
.progress .progress-bar-material-light-green {
  background-color: #8bc34a;
}
.progress .progress-bar-material-light-green-50 {
  background-color: #f1f8e9;
}
.progress .progress-bar-material-light-green-100 {
  background-color: #dcedc8;
}
.progress .progress-bar-material-light-green-200 {
  background-color: #c5e1a5;
}
.progress .progress-bar-material-light-green-300 {
  background-color: #aed581;
}
.progress .progress-bar-material-light-green-400 {
  background-color: #9ccc65;
}
.progress .progress-bar-material-light-green-500 {
  background-color: #8bc34a;
}
.progress .progress-bar-material-light-green-600 {
  background-color: #7cb342;
}
.progress .progress-bar-material-light-green-700 {
  background-color: #689f38;
}
.progress .progress-bar-material-light-green-800 {
  background-color: #558b2f;
}
.progress .progress-bar-material-light-green-900 {
  background-color: #33691e;
}
.progress .progress-bar-material-light-green-A100 {
  background-color: #ccff90;
}
.progress .progress-bar-material-light-green-A200 {
  background-color: #b2ff59;
}
.progress .progress-bar-material-light-green-A400 {
  background-color: #76ff03;
}
.progress .progress-bar-material-light-green-A700 {
  background-color: #64dd17;
}
.progress .progress-bar-material-lime {
  background-color: #cddc39;
}
.progress .progress-bar-material-lime-50 {
  background-color: #f9fbe7;
}
.progress .progress-bar-material-lime-100 {
  background-color: #f0f4c3;
}
.progress .progress-bar-material-lime-200 {
  background-color: #e6ee9c;
}
.progress .progress-bar-material-lime-300 {
  background-color: #dce775;
}
.progress .progress-bar-material-lime-400 {
  background-color: #d4e157;
}
.progress .progress-bar-material-lime-500 {
  background-color: #cddc39;
}
.progress .progress-bar-material-lime-600 {
  background-color: #c0ca33;
}
.progress .progress-bar-material-lime-700 {
  background-color: #afb42b;
}
.progress .progress-bar-material-lime-800 {
  background-color: #9e9d24;
}
.progress .progress-bar-material-lime-900 {
  background-color: #827717;
}
.progress .progress-bar-material-lime-A100 {
  background-color: #f4ff81;
}
.progress .progress-bar-material-lime-A200 {
  background-color: #eeff41;
}
.progress .progress-bar-material-lime-A400 {
  background-color: #c6ff00;
}
.progress .progress-bar-material-lime-A700 {
  background-color: #aeea00;
}
.progress .progress-bar-material-yellow {
  background-color: #ffeb3b;
}
.progress .progress-bar-material-yellow-50 {
  background-color: #fffde7;
}
.progress .progress-bar-material-yellow-100 {
  background-color: #fff9c4;
}
.progress .progress-bar-material-yellow-200 {
  background-color: #fff59d;
}
.progress .progress-bar-material-yellow-300 {
  background-color: #fff176;
}
.progress .progress-bar-material-yellow-400 {
  background-color: #ffee58;
}
.progress .progress-bar-material-yellow-500 {
  background-color: #ffeb3b;
}
.progress .progress-bar-material-yellow-600 {
  background-color: #fdd835;
}
.progress .progress-bar-material-yellow-700 {
  background-color: #fbc02d;
}
.progress .progress-bar-material-yellow-800 {
  background-color: #f9a825;
}
.progress .progress-bar-material-yellow-900 {
  background-color: #f57f17;
}
.progress .progress-bar-material-yellow-A100 {
  background-color: #ffff8d;
}
.progress .progress-bar-material-yellow-A200 {
  background-color: #ffff00;
}
.progress .progress-bar-material-yellow-A400 {
  background-color: #ffea00;
}
.progress .progress-bar-material-yellow-A700 {
  background-color: #ffd600;
}
.progress .progress-bar-material-amber {
  background-color: #ffc107;
}
.progress .progress-bar-material-amber-50 {
  background-color: #fff8e1;
}
.progress .progress-bar-material-amber-100 {
  background-color: #ffecb3;
}
.progress .progress-bar-material-amber-200 {
  background-color: #ffe082;
}
.progress .progress-bar-material-amber-300 {
  background-color: #ffd54f;
}
.progress .progress-bar-material-amber-400 {
  background-color: #ffca28;
}
.progress .progress-bar-material-amber-500 {
  background-color: #ffc107;
}
.progress .progress-bar-material-amber-600 {
  background-color: #ffb300;
}
.progress .progress-bar-material-amber-700 {
  background-color: #ffa000;
}
.progress .progress-bar-material-amber-800 {
  background-color: #ff8f00;
}
.progress .progress-bar-material-amber-900 {
  background-color: #ff6f00;
}
.progress .progress-bar-material-amber-A100 {
  background-color: #ffe57f;
}
.progress .progress-bar-material-amber-A200 {
  background-color: #ffd740;
}
.progress .progress-bar-material-amber-A400 {
  background-color: #ffc400;
}
.progress .progress-bar-material-amber-A700 {
  background-color: #ffab00;
}
.progress .progress-bar-material-orange {
  background-color: #ff9800;
}
.progress .progress-bar-material-orange-50 {
  background-color: #fff3e0;
}
.progress .progress-bar-material-orange-100 {
  background-color: #ffe0b2;
}
.progress .progress-bar-material-orange-200 {
  background-color: #ffcc80;
}
.progress .progress-bar-material-orange-300 {
  background-color: #ffb74d;
}
.progress .progress-bar-material-orange-400 {
  background-color: #ffa726;
}
.progress .progress-bar-material-orange-500 {
  background-color: #ff9800;
}
.progress .progress-bar-material-orange-600 {
  background-color: #fb8c00;
}
.progress .progress-bar-material-orange-700 {
  background-color: #f57c00;
}
.progress .progress-bar-material-orange-800 {
  background-color: #ef6c00;
}
.progress .progress-bar-material-orange-900 {
  background-color: #e65100;
}
.progress .progress-bar-material-orange-A100 {
  background-color: #ffd180;
}
.progress .progress-bar-material-orange-A200 {
  background-color: #ffab40;
}
.progress .progress-bar-material-orange-A400 {
  background-color: #ff9100;
}
.progress .progress-bar-material-orange-A700 {
  background-color: #ff6d00;
}
.progress .progress-bar-material-deep-orange {
  background-color: #ff5722;
}
.progress .progress-bar-material-deep-orange-50 {
  background-color: #fbe9e7;
}
.progress .progress-bar-material-deep-orange-100 {
  background-color: #ffccbc;
}
.progress .progress-bar-material-deep-orange-200 {
  background-color: #ffab91;
}
.progress .progress-bar-material-deep-orange-300 {
  background-color: #ff8a65;
}
.progress .progress-bar-material-deep-orange-400 {
  background-color: #ff7043;
}
.progress .progress-bar-material-deep-orange-500 {
  background-color: #ff5722;
}
.progress .progress-bar-material-deep-orange-600 {
  background-color: #f4511e;
}
.progress .progress-bar-material-deep-orange-700 {
  background-color: #e64a19;
}
.progress .progress-bar-material-deep-orange-800 {
  background-color: #d84315;
}
.progress .progress-bar-material-deep-orange-900 {
  background-color: #bf360c;
}
.progress .progress-bar-material-deep-orange-A100 {
  background-color: #ff9e80;
}
.progress .progress-bar-material-deep-orange-A200 {
  background-color: #ff6e40;
}
.progress .progress-bar-material-deep-orange-A400 {
  background-color: #ff3d00;
}
.progress .progress-bar-material-deep-orange-A700 {
  background-color: #dd2c00;
}
.progress .progress-bar-material-brown {
  background-color: #795548;
}
.progress .progress-bar-material-brown-50 {
  background-color: #efebe9;
}
.progress .progress-bar-material-brown-100 {
  background-color: #d7ccc8;
}
.progress .progress-bar-material-brown-200 {
  background-color: #bcaaa4;
}
.progress .progress-bar-material-brown-300 {
  background-color: #a1887f;
}
.progress .progress-bar-material-brown-400 {
  background-color: #8d6e63;
}
.progress .progress-bar-material-brown-500 {
  background-color: #795548;
}
.progress .progress-bar-material-brown-600 {
  background-color: #6d4c41;
}
.progress .progress-bar-material-brown-700 {
  background-color: #5d4037;
}
.progress .progress-bar-material-brown-800 {
  background-color: #4e342e;
}
.progress .progress-bar-material-brown-900 {
  background-color: #3e2723;
}
.progress .progress-bar-material-brown-A100 {
  background-color: #d7ccc8;
}
.progress .progress-bar-material-brown-A200 {
  background-color: #bcaaa4;
}
.progress .progress-bar-material-brown-A400 {
  background-color: #8d6e63;
}
.progress .progress-bar-material-brown-A700 {
  background-color: #5d4037;
}
.progress .progress-bar-material-grey {
  background-color: #9e9e9e;
}
.progress .progress-bar-material-grey-50 {
  background-color: #fafafa;
}
.progress .progress-bar-material-grey-100 {
  background-color: #f5f5f5;
}
.progress .progress-bar-material-grey-200 {
  background-color: #eeeeee;
}
.progress .progress-bar-material-grey-300 {
  background-color: #e0e0e0;
}
.progress .progress-bar-material-grey-400 {
  background-color: #bdbdbd;
}
.progress .progress-bar-material-grey-500 {
  background-color: #9e9e9e;
}
.progress .progress-bar-material-grey-600 {
  background-color: #757575;
}
.progress .progress-bar-material-grey-700 {
  background-color: #616161;
}
.progress .progress-bar-material-grey-800 {
  background-color: #424242;
}
.progress .progress-bar-material-grey-900 {
  background-color: #212121;
}
.progress .progress-bar-material-grey-A100 {
  background-color: #f5f5f5;
}
.progress .progress-bar-material-grey-A200 {
  background-color: #eeeeee;
}
.progress .progress-bar-material-grey-A400 {
  background-color: #bdbdbd;
}
.progress .progress-bar-material-grey-A700 {
  background-color: #616161;
}
.progress .progress-bar-material-blue-grey {
  background-color: #607d8b;
}
.progress .progress-bar-material-blue-grey-50 {
  background-color: #eceff1;
}
.progress .progress-bar-material-blue-grey-100 {
  background-color: #cfd8dc;
}
.progress .progress-bar-material-blue-grey-200 {
  background-color: #b0bec5;
}
.progress .progress-bar-material-blue-grey-300 {
  background-color: #90a4ae;
}
.progress .progress-bar-material-blue-grey-400 {
  background-color: #78909c;
}
.progress .progress-bar-material-blue-grey-500 {
  background-color: #607d8b;
}
.progress .progress-bar-material-blue-grey-600 {
  background-color: #546e7a;
}
.progress .progress-bar-material-blue-grey-700 {
  background-color: #455a64;
}
.progress .progress-bar-material-blue-grey-800 {
  background-color: #37474f;
}
.progress .progress-bar-material-blue-grey-900 {
  background-color: #263238;
}
.progress .progress-bar-material-blue-grey-A100 {
  background-color: #cfd8dc;
}
.progress .progress-bar-material-blue-grey-A200 {
  background-color: #b0bec5;
}
.progress .progress-bar-material-blue-grey-A400 {
  background-color: #78909c;
}
.progress .progress-bar-material-blue-grey-A700 {
  background-color: #455a64;
}
.text-warning {
  color: #ff5722;
}
.text-primary {
  color: #009688;
}
.text-danger {
  color: #f44336;
}
.text-success {
  color: #4caf50;
}
.text-info {
  color: #03a9f4;
}
.nav-tabs {
  background: #009688;
}
.nav-tabs > li > a {
  color: #FFFFFF;
  border: 0;
  margin: 0;
}
.nav-tabs > li > a:hover {
  background-color: transparent;
  border: 0;
}
.nav-tabs > li > a,
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  background-color: transparent !important;
  border: 0 !important;
  color: #FFFFFF !important;
  font-weight: 500;
}
.nav-tabs > li.disabled > a,
.nav-tabs > li.disabled > a:hover {
  color: rgba(255, 255, 255, 0.5);
}
.popover,
.tooltip-inner {
  color: #ececec;
  line-height: 1em;
  background: rgba(101, 101, 101, 0.9);
  border: none;
  border-radius: 2px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
}
.tooltip,
.tooltip.in {
  opacity: 1;
}
.popover .arrow,
.tooltip .arrow,
.popover .tooltip-arrow,
.tooltip .tooltip-arrow {
  display: none;
}
.mdi,
icon {
  line-height: inherit;
  vertical-align: bottom;
}
.mdi,
icon,
.mdi-default,
icon-default {
  color: rgba(0, 0, 0, 0.84);
}
.mdi-black,
icon-black {
  color: #000000;
}
.mdi-white,
icon-white {
  color: #ffffff;
}
.mdi-inverse,
icon-inverse {
  color: #3f51b5;
}
.mdi-primary,
icon-primary {
  color: #009688;
}
.mdi-success,
icon-success {
  color: #4caf50;
}
.mdi-info,
icon-info {
  color: #03a9f4;
}
.mdi-warning,
icon-warning {
  color: #ff5722;
}
.mdi-danger,
icon-danger {
  color: #f44336;
}
.mdi-material-red,
icon-material-red {
  color: #f44336;
}
.mdi-material-red-50,
icon-material-red-50 {
  color: #ffebee;
}
.mdi-material-red-100,
icon-material-red-100 {
  color: #ffcdd2;
}
.mdi-material-red-200,
icon-material-red-200 {
  color: #ef9a9a;
}
.mdi-material-red-300,
icon-material-red-300 {
  color: #e57373;
}
.mdi-material-red-400,
icon-material-red-400 {
  color: #ef5350;
}
.mdi-material-red-500,
icon-material-red-500 {
  color: #f44336;
}
.mdi-material-red-600,
icon-material-red-600 {
  color: #e53935;
}
.mdi-material-red-700,
icon-material-red-700 {
  color: #d32f2f;
}
.mdi-material-red-800,
icon-material-red-800 {
  color: #c62828;
}
.mdi-material-red-900,
icon-material-red-900 {
  color: #b71c1c;
}
.mdi-material-red-A100,
icon-material-red-A100 {
  color: #ff8a80;
}
.mdi-material-red-A200,
icon-material-red-A200 {
  color: #ff5252;
}
.mdi-material-red-A400,
icon-material-red-A400 {
  color: #ff1744;
}
.mdi-material-red-A700,
icon-material-red-A700 {
  color: #d50000;
}
.mdi-material-pink,
icon-material-pink {
  color: #e91e63;
}
.mdi-material-pink-50,
icon-material-pink-50 {
  color: #fce4ec;
}
.mdi-material-pink-100,
icon-material-pink-100 {
  color: #f8bbd0;
}
.mdi-material-pink-200,
icon-material-pink-200 {
  color: #f48fb1;
}
.mdi-material-pink-300,
icon-material-pink-300 {
  color: #f06292;
}
.mdi-material-pink-400,
icon-material-pink-400 {
  color: #ec407a;
}
.mdi-material-pink-500,
icon-material-pink-500 {
  color: #e91e63;
}
.mdi-material-pink-600,
icon-material-pink-600 {
  color: #d81b60;
}
.mdi-material-pink-700,
icon-material-pink-700 {
  color: #c2185b;
}
.mdi-material-pink-800,
icon-material-pink-800 {
  color: #ad1457;
}
.mdi-material-pink-900,
icon-material-pink-900 {
  color: #880e4f;
}
.mdi-material-pink-A100,
icon-material-pink-A100 {
  color: #ff80ab;
}
.mdi-material-pink-A200,
icon-material-pink-A200 {
  color: #ff4081;
}
.mdi-material-pink-A400,
icon-material-pink-A400 {
  color: #f50057;
}
.mdi-material-pink-A700,
icon-material-pink-A700 {
  color: #c51162;
}
.mdi-material-purple,
icon-material-purple {
  color: #9c27b0;
}
.mdi-material-purple-50,
icon-material-purple-50 {
  color: #f3e5f5;
}
.mdi-material-purple-100,
icon-material-purple-100 {
  color: #e1bee7;
}
.mdi-material-purple-200,
icon-material-purple-200 {
  color: #ce93d8;
}
.mdi-material-purple-300,
icon-material-purple-300 {
  color: #ba68c8;
}
.mdi-material-purple-400,
icon-material-purple-400 {
  color: #ab47bc;
}
.mdi-material-purple-500,
icon-material-purple-500 {
  color: #9c27b0;
}
.mdi-material-purple-600,
icon-material-purple-600 {
  color: #8e24aa;
}
.mdi-material-purple-700,
icon-material-purple-700 {
  color: #7b1fa2;
}
.mdi-material-purple-800,
icon-material-purple-800 {
  color: #6a1b9a;
}
.mdi-material-purple-900,
icon-material-purple-900 {
  color: #4a148c;
}
.mdi-material-purple-A100,
icon-material-purple-A100 {
  color: #ea80fc;
}
.mdi-material-purple-A200,
icon-material-purple-A200 {
  color: #e040fb;
}
.mdi-material-purple-A400,
icon-material-purple-A400 {
  color: #d500f9;
}
.mdi-material-purple-A700,
icon-material-purple-A700 {
  color: #aa00ff;
}
.mdi-material-deep-purple,
icon-material-deep-purple {
  color: #673ab7;
}
.mdi-material-deep-purple-50,
icon-material-deep-purple-50 {
  color: #ede7f6;
}
.mdi-material-deep-purple-100,
icon-material-deep-purple-100 {
  color: #d1c4e9;
}
.mdi-material-deep-purple-200,
icon-material-deep-purple-200 {
  color: #b39ddb;
}
.mdi-material-deep-purple-300,
icon-material-deep-purple-300 {
  color: #9575cd;
}
.mdi-material-deep-purple-400,
icon-material-deep-purple-400 {
  color: #7e57c2;
}
.mdi-material-deep-purple-500,
icon-material-deep-purple-500 {
  color: #673ab7;
}
.mdi-material-deep-purple-600,
icon-material-deep-purple-600 {
  color: #5e35b1;
}
.mdi-material-deep-purple-700,
icon-material-deep-purple-700 {
  color: #512da8;
}
.mdi-material-deep-purple-800,
icon-material-deep-purple-800 {
  color: #4527a0;
}
.mdi-material-deep-purple-900,
icon-material-deep-purple-900 {
  color: #311b92;
}
.mdi-material-deep-purple-A100,
icon-material-deep-purple-A100 {
  color: #b388ff;
}
.mdi-material-deep-purple-A200,
icon-material-deep-purple-A200 {
  color: #7c4dff;
}
.mdi-material-deep-purple-A400,
icon-material-deep-purple-A400 {
  color: #651fff;
}
.mdi-material-deep-purple-A700,
icon-material-deep-purple-A700 {
  color: #6200ea;
}
.mdi-material-indigo,
icon-material-indigo {
  color: #3f51b5;
}
.mdi-material-indigo-50,
icon-material-indigo-50 {
  color: #e8eaf6;
}
.mdi-material-indigo-100,
icon-material-indigo-100 {
  color: #c5cae9;
}
.mdi-material-indigo-200,
icon-material-indigo-200 {
  color: #9fa8da;
}
.mdi-material-indigo-300,
icon-material-indigo-300 {
  color: #7986cb;
}
.mdi-material-indigo-400,
icon-material-indigo-400 {
  color: #5c6bc0;
}
.mdi-material-indigo-500,
icon-material-indigo-500 {
  color: #3f51b5;
}
.mdi-material-indigo-600,
icon-material-indigo-600 {
  color: #3949ab;
}
.mdi-material-indigo-700,
icon-material-indigo-700 {
  color: #303f9f;
}
.mdi-material-indigo-800,
icon-material-indigo-800 {
  color: #283593;
}
.mdi-material-indigo-900,
icon-material-indigo-900 {
  color: #1a237e;
}
.mdi-material-indigo-A100,
icon-material-indigo-A100 {
  color: #8c9eff;
}
.mdi-material-indigo-A200,
icon-material-indigo-A200 {
  color: #536dfe;
}
.mdi-material-indigo-A400,
icon-material-indigo-A400 {
  color: #3d5afe;
}
.mdi-material-indigo-A700,
icon-material-indigo-A700 {
  color: #304ffe;
}
.mdi-material-blue,
icon-material-blue {
  color: #2196f3;
}
.mdi-material-blue-50,
icon-material-blue-50 {
  color: #e3f2fd;
}
.mdi-material-blue-100,
icon-material-blue-100 {
  color: #bbdefb;
}
.mdi-material-blue-200,
icon-material-blue-200 {
  color: #90caf9;
}
.mdi-material-blue-300,
icon-material-blue-300 {
  color: #64b5f6;
}
.mdi-material-blue-400,
icon-material-blue-400 {
  color: #42a5f5;
}
.mdi-material-blue-500,
icon-material-blue-500 {
  color: #2196f3;
}
.mdi-material-blue-600,
icon-material-blue-600 {
  color: #1e88e5;
}
.mdi-material-blue-700,
icon-material-blue-700 {
  color: #1976d2;
}
.mdi-material-blue-800,
icon-material-blue-800 {
  color: #1565c0;
}
.mdi-material-blue-900,
icon-material-blue-900 {
  color: #0d47a1;
}
.mdi-material-blue-A100,
icon-material-blue-A100 {
  color: #82b1ff;
}
.mdi-material-blue-A200,
icon-material-blue-A200 {
  color: #448aff;
}
.mdi-material-blue-A400,
icon-material-blue-A400 {
  color: #2979ff;
}
.mdi-material-blue-A700,
icon-material-blue-A700 {
  color: #2962ff;
}
.mdi-material-light-blue,
icon-material-light-blue {
  color: #03a9f4;
}
.mdi-material-light-blue-50,
icon-material-light-blue-50 {
  color: #e1f5fe;
}
.mdi-material-light-blue-100,
icon-material-light-blue-100 {
  color: #b3e5fc;
}
.mdi-material-light-blue-200,
icon-material-light-blue-200 {
  color: #81d4fa;
}
.mdi-material-light-blue-300,
icon-material-light-blue-300 {
  color: #4fc3f7;
}
.mdi-material-light-blue-400,
icon-material-light-blue-400 {
  color: #29b6f6;
}
.mdi-material-light-blue-500,
icon-material-light-blue-500 {
  color: #03a9f4;
}
.mdi-material-light-blue-600,
icon-material-light-blue-600 {
  color: #039be5;
}
.mdi-material-light-blue-700,
icon-material-light-blue-700 {
  color: #0288d1;
}
.mdi-material-light-blue-800,
icon-material-light-blue-800 {
  color: #0277bd;
}
.mdi-material-light-blue-900,
icon-material-light-blue-900 {
  color: #01579b;
}
.mdi-material-light-blue-A100,
icon-material-light-blue-A100 {
  color: #80d8ff;
}
.mdi-material-light-blue-A200,
icon-material-light-blue-A200 {
  color: #40c4ff;
}
.mdi-material-light-blue-A400,
icon-material-light-blue-A400 {
  color: #00b0ff;
}
.mdi-material-light-blue-A700,
icon-material-light-blue-A700 {
  color: #0091ea;
}
.mdi-material-cyan,
icon-material-cyan {
  color: #00bcd4;
}
.mdi-material-cyan-50,
icon-material-cyan-50 {
  color: #e0f7fa;
}
.mdi-material-cyan-100,
icon-material-cyan-100 {
  color: #b2ebf2;
}
.mdi-material-cyan-200,
icon-material-cyan-200 {
  color: #80deea;
}
.mdi-material-cyan-300,
icon-material-cyan-300 {
  color: #4dd0e1;
}
.mdi-material-cyan-400,
icon-material-cyan-400 {
  color: #26c6da;
}
.mdi-material-cyan-500,
icon-material-cyan-500 {
  color: #00bcd4;
}
.mdi-material-cyan-600,
icon-material-cyan-600 {
  color: #00acc1;
}
.mdi-material-cyan-700,
icon-material-cyan-700 {
  color: #0097a7;
}
.mdi-material-cyan-800,
icon-material-cyan-800 {
  color: #00838f;
}
.mdi-material-cyan-900,
icon-material-cyan-900 {
  color: #006064;
}
.mdi-material-cyan-A100,
icon-material-cyan-A100 {
  color: #84ffff;
}
.mdi-material-cyan-A200,
icon-material-cyan-A200 {
  color: #18ffff;
}
.mdi-material-cyan-A400,
icon-material-cyan-A400 {
  color: #00e5ff;
}
.mdi-material-cyan-A700,
icon-material-cyan-A700 {
  color: #00b8d4;
}
.mdi-material-teal,
icon-material-teal {
  color: #009688;
}
.mdi-material-teal-50,
icon-material-teal-50 {
  color: #e0f2f1;
}
.mdi-material-teal-100,
icon-material-teal-100 {
  color: #b2dfdb;
}
.mdi-material-teal-200,
icon-material-teal-200 {
  color: #80cbc4;
}
.mdi-material-teal-300,
icon-material-teal-300 {
  color: #4db6ac;
}
.mdi-material-teal-400,
icon-material-teal-400 {
  color: #26a69a;
}
.mdi-material-teal-500,
icon-material-teal-500 {
  color: #009688;
}
.mdi-material-teal-600,
icon-material-teal-600 {
  color: #00897b;
}
.mdi-material-teal-700,
icon-material-teal-700 {
  color: #00796b;
}
.mdi-material-teal-800,
icon-material-teal-800 {
  color: #00695c;
}
.mdi-material-teal-900,
icon-material-teal-900 {
  color: #004d40;
}
.mdi-material-teal-A100,
icon-material-teal-A100 {
  color: #a7ffeb;
}
.mdi-material-teal-A200,
icon-material-teal-A200 {
  color: #64ffda;
}
.mdi-material-teal-A400,
icon-material-teal-A400 {
  color: #1de9b6;
}
.mdi-material-teal-A700,
icon-material-teal-A700 {
  color: #00bfa5;
}
.mdi-material-green,
icon-material-green {
  color: #4caf50;
}
.mdi-material-green-50,
icon-material-green-50 {
  color: #e8f5e9;
}
.mdi-material-green-100,
icon-material-green-100 {
  color: #c8e6c9;
}
.mdi-material-green-200,
icon-material-green-200 {
  color: #a5d6a7;
}
.mdi-material-green-300,
icon-material-green-300 {
  color: #81c784;
}
.mdi-material-green-400,
icon-material-green-400 {
  color: #66bb6a;
}
.mdi-material-green-500,
icon-material-green-500 {
  color: #4caf50;
}
.mdi-material-green-600,
icon-material-green-600 {
  color: #43a047;
}
.mdi-material-green-700,
icon-material-green-700 {
  color: #388e3c;
}
.mdi-material-green-800,
icon-material-green-800 {
  color: #2e7d32;
}
.mdi-material-green-900,
icon-material-green-900 {
  color: #1b5e20;
}
.mdi-material-green-A100,
icon-material-green-A100 {
  color: #b9f6ca;
}
.mdi-material-green-A200,
icon-material-green-A200 {
  color: #69f0ae;
}
.mdi-material-green-A400,
icon-material-green-A400 {
  color: #00e676;
}
.mdi-material-green-A700,
icon-material-green-A700 {
  color: #00c853;
}
.mdi-material-light-green,
icon-material-light-green {
  color: #8bc34a;
}
.mdi-material-light-green-50,
icon-material-light-green-50 {
  color: #f1f8e9;
}
.mdi-material-light-green-100,
icon-material-light-green-100 {
  color: #dcedc8;
}
.mdi-material-light-green-200,
icon-material-light-green-200 {
  color: #c5e1a5;
}
.mdi-material-light-green-300,
icon-material-light-green-300 {
  color: #aed581;
}
.mdi-material-light-green-400,
icon-material-light-green-400 {
  color: #9ccc65;
}
.mdi-material-light-green-500,
icon-material-light-green-500 {
  color: #8bc34a;
}
.mdi-material-light-green-600,
icon-material-light-green-600 {
  color: #7cb342;
}
.mdi-material-light-green-700,
icon-material-light-green-700 {
  color: #689f38;
}
.mdi-material-light-green-800,
icon-material-light-green-800 {
  color: #558b2f;
}
.mdi-material-light-green-900,
icon-material-light-green-900 {
  color: #33691e;
}
.mdi-material-light-green-A100,
icon-material-light-green-A100 {
  color: #ccff90;
}
.mdi-material-light-green-A200,
icon-material-light-green-A200 {
  color: #b2ff59;
}
.mdi-material-light-green-A400,
icon-material-light-green-A400 {
  color: #76ff03;
}
.mdi-material-light-green-A700,
icon-material-light-green-A700 {
  color: #64dd17;
}
.mdi-material-lime,
icon-material-lime {
  color: #cddc39;
}
.mdi-material-lime-50,
icon-material-lime-50 {
  color: #f9fbe7;
}
.mdi-material-lime-100,
icon-material-lime-100 {
  color: #f0f4c3;
}
.mdi-material-lime-200,
icon-material-lime-200 {
  color: #e6ee9c;
}
.mdi-material-lime-300,
icon-material-lime-300 {
  color: #dce775;
}
.mdi-material-lime-400,
icon-material-lime-400 {
  color: #d4e157;
}
.mdi-material-lime-500,
icon-material-lime-500 {
  color: #cddc39;
}
.mdi-material-lime-600,
icon-material-lime-600 {
  color: #c0ca33;
}
.mdi-material-lime-700,
icon-material-lime-700 {
  color: #afb42b;
}
.mdi-material-lime-800,
icon-material-lime-800 {
  color: #9e9d24;
}
.mdi-material-lime-900,
icon-material-lime-900 {
  color: #827717;
}
.mdi-material-lime-A100,
icon-material-lime-A100 {
  color: #f4ff81;
}
.mdi-material-lime-A200,
icon-material-lime-A200 {
  color: #eeff41;
}
.mdi-material-lime-A400,
icon-material-lime-A400 {
  color: #c6ff00;
}
.mdi-material-lime-A700,
icon-material-lime-A700 {
  color: #aeea00;
}
.mdi-material-yellow,
icon-material-yellow {
  color: #ffeb3b;
}
.mdi-material-yellow-50,
icon-material-yellow-50 {
  color: #fffde7;
}
.mdi-material-yellow-100,
icon-material-yellow-100 {
  color: #fff9c4;
}
.mdi-material-yellow-200,
icon-material-yellow-200 {
  color: #fff59d;
}
.mdi-material-yellow-300,
icon-material-yellow-300 {
  color: #fff176;
}
.mdi-material-yellow-400,
icon-material-yellow-400 {
  color: #ffee58;
}
.mdi-material-yellow-500,
icon-material-yellow-500 {
  color: #ffeb3b;
}
.mdi-material-yellow-600,
icon-material-yellow-600 {
  color: #fdd835;
}
.mdi-material-yellow-700,
icon-material-yellow-700 {
  color: #fbc02d;
}
.mdi-material-yellow-800,
icon-material-yellow-800 {
  color: #f9a825;
}
.mdi-material-yellow-900,
icon-material-yellow-900 {
  color: #f57f17;
}
.mdi-material-yellow-A100,
icon-material-yellow-A100 {
  color: #ffff8d;
}
.mdi-material-yellow-A200,
icon-material-yellow-A200 {
  color: #ffff00;
}
.mdi-material-yellow-A400,
icon-material-yellow-A400 {
  color: #ffea00;
}
.mdi-material-yellow-A700,
icon-material-yellow-A700 {
  color: #ffd600;
}
.mdi-material-amber,
icon-material-amber {
  color: #ffc107;
}
.mdi-material-amber-50,
icon-material-amber-50 {
  color: #fff8e1;
}
.mdi-material-amber-100,
icon-material-amber-100 {
  color: #ffecb3;
}
.mdi-material-amber-200,
icon-material-amber-200 {
  color: #ffe082;
}
.mdi-material-amber-300,
icon-material-amber-300 {
  color: #ffd54f;
}
.mdi-material-amber-400,
icon-material-amber-400 {
  color: #ffca28;
}
.mdi-material-amber-500,
icon-material-amber-500 {
  color: #ffc107;
}
.mdi-material-amber-600,
icon-material-amber-600 {
  color: #ffb300;
}
.mdi-material-amber-700,
icon-material-amber-700 {
  color: #ffa000;
}
.mdi-material-amber-800,
icon-material-amber-800 {
  color: #ff8f00;
}
.mdi-material-amber-900,
icon-material-amber-900 {
  color: #ff6f00;
}
.mdi-material-amber-A100,
icon-material-amber-A100 {
  color: #ffe57f;
}
.mdi-material-amber-A200,
icon-material-amber-A200 {
  color: #ffd740;
}
.mdi-material-amber-A400,
icon-material-amber-A400 {
  color: #ffc400;
}
.mdi-material-amber-A700,
icon-material-amber-A700 {
  color: #ffab00;
}
.mdi-material-orange,
icon-material-orange {
  color: #ff9800;
}
.mdi-material-orange-50,
icon-material-orange-50 {
  color: #fff3e0;
}
.mdi-material-orange-100,
icon-material-orange-100 {
  color: #ffe0b2;
}
.mdi-material-orange-200,
icon-material-orange-200 {
  color: #ffcc80;
}
.mdi-material-orange-300,
icon-material-orange-300 {
  color: #ffb74d;
}
.mdi-material-orange-400,
icon-material-orange-400 {
  color: #ffa726;
}
.mdi-material-orange-500,
icon-material-orange-500 {
  color: #ff9800;
}
.mdi-material-orange-600,
icon-material-orange-600 {
  color: #fb8c00;
}
.mdi-material-orange-700,
icon-material-orange-700 {
  color: #f57c00;
}
.mdi-material-orange-800,
icon-material-orange-800 {
  color: #ef6c00;
}
.mdi-material-orange-900,
icon-material-orange-900 {
  color: #e65100;
}
.mdi-material-orange-A100,
icon-material-orange-A100 {
  color: #ffd180;
}
.mdi-material-orange-A200,
icon-material-orange-A200 {
  color: #ffab40;
}
.mdi-material-orange-A400,
icon-material-orange-A400 {
  color: #ff9100;
}
.mdi-material-orange-A700,
icon-material-orange-A700 {
  color: #ff6d00;
}
.mdi-material-deep-orange,
icon-material-deep-orange {
  color: #ff5722;
}
.mdi-material-deep-orange-50,
icon-material-deep-orange-50 {
  color: #fbe9e7;
}
.mdi-material-deep-orange-100,
icon-material-deep-orange-100 {
  color: #ffccbc;
}
.mdi-material-deep-orange-200,
icon-material-deep-orange-200 {
  color: #ffab91;
}
.mdi-material-deep-orange-300,
icon-material-deep-orange-300 {
  color: #ff8a65;
}
.mdi-material-deep-orange-400,
icon-material-deep-orange-400 {
  color: #ff7043;
}
.mdi-material-deep-orange-500,
icon-material-deep-orange-500 {
  color: #ff5722;
}
.mdi-material-deep-orange-600,
icon-material-deep-orange-600 {
  color: #f4511e;
}
.mdi-material-deep-orange-700,
icon-material-deep-orange-700 {
  color: #e64a19;
}
.mdi-material-deep-orange-800,
icon-material-deep-orange-800 {
  color: #d84315;
}
.mdi-material-deep-orange-900,
icon-material-deep-orange-900 {
  color: #bf360c;
}
.mdi-material-deep-orange-A100,
icon-material-deep-orange-A100 {
  color: #ff9e80;
}
.mdi-material-deep-orange-A200,
icon-material-deep-orange-A200 {
  color: #ff6e40;
}
.mdi-material-deep-orange-A400,
icon-material-deep-orange-A400 {
  color: #ff3d00;
}
.mdi-material-deep-orange-A700,
icon-material-deep-orange-A700 {
  color: #dd2c00;
}
.mdi-material-brown,
icon-material-brown {
  color: #795548;
}
.mdi-material-brown-50,
icon-material-brown-50 {
  color: #efebe9;
}
.mdi-material-brown-100,
icon-material-brown-100 {
  color: #d7ccc8;
}
.mdi-material-brown-200,
icon-material-brown-200 {
  color: #bcaaa4;
}
.mdi-material-brown-300,
icon-material-brown-300 {
  color: #a1887f;
}
.mdi-material-brown-400,
icon-material-brown-400 {
  color: #8d6e63;
}
.mdi-material-brown-500,
icon-material-brown-500 {
  color: #795548;
}
.mdi-material-brown-600,
icon-material-brown-600 {
  color: #6d4c41;
}
.mdi-material-brown-700,
icon-material-brown-700 {
  color: #5d4037;
}
.mdi-material-brown-800,
icon-material-brown-800 {
  color: #4e342e;
}
.mdi-material-brown-900,
icon-material-brown-900 {
  color: #3e2723;
}
.mdi-material-brown-A100,
icon-material-brown-A100 {
  color: #d7ccc8;
}
.mdi-material-brown-A200,
icon-material-brown-A200 {
  color: #bcaaa4;
}
.mdi-material-brown-A400,
icon-material-brown-A400 {
  color: #8d6e63;
}
.mdi-material-brown-A700,
icon-material-brown-A700 {
  color: #5d4037;
}
.mdi-material-grey,
icon-material-grey {
  color: #9e9e9e;
}
.mdi-material-grey-50,
icon-material-grey-50 {
  color: #fafafa;
}
.mdi-material-grey-100,
icon-material-grey-100 {
  color: #f5f5f5;
}
.mdi-material-grey-200,
icon-material-grey-200 {
  color: #eeeeee;
}
.mdi-material-grey-300,
icon-material-grey-300 {
  color: #e0e0e0;
}
.mdi-material-grey-400,
icon-material-grey-400 {
  color: #bdbdbd;
}
.mdi-material-grey-500,
icon-material-grey-500 {
  color: #9e9e9e;
}
.mdi-material-grey-600,
icon-material-grey-600 {
  color: #757575;
}
.mdi-material-grey-700,
icon-material-grey-700 {
  color: #616161;
}
.mdi-material-grey-800,
icon-material-grey-800 {
  color: #424242;
}
.mdi-material-grey-900,
icon-material-grey-900 {
  color: #212121;
}
.mdi-material-grey-A100,
icon-material-grey-A100 {
  color: #f5f5f5;
}
.mdi-material-grey-A200,
icon-material-grey-A200 {
  color: #eeeeee;
}
.mdi-material-grey-A400,
icon-material-grey-A400 {
  color: #bdbdbd;
}
.mdi-material-grey-A700,
icon-material-grey-A700 {
  color: #616161;
}
.mdi-material-blue-grey,
icon-material-blue-grey {
  color: #607d8b;
}
.mdi-material-blue-grey-50,
icon-material-blue-grey-50 {
  color: #eceff1;
}
.mdi-material-blue-grey-100,
icon-material-blue-grey-100 {
  color: #cfd8dc;
}
.mdi-material-blue-grey-200,
icon-material-blue-grey-200 {
  color: #b0bec5;
}
.mdi-material-blue-grey-300,
icon-material-blue-grey-300 {
  color: #90a4ae;
}
.mdi-material-blue-grey-400,
icon-material-blue-grey-400 {
  color: #78909c;
}
.mdi-material-blue-grey-500,
icon-material-blue-grey-500 {
  color: #607d8b;
}
.mdi-material-blue-grey-600,
icon-material-blue-grey-600 {
  color: #546e7a;
}
.mdi-material-blue-grey-700,
icon-material-blue-grey-700 {
  color: #455a64;
}
.mdi-material-blue-grey-800,
icon-material-blue-grey-800 {
  color: #37474f;
}
.mdi-material-blue-grey-900,
icon-material-blue-grey-900 {
  color: #263238;
}
.mdi-material-blue-grey-A100,
icon-material-blue-grey-A100 {
  color: #cfd8dc;
}
.mdi-material-blue-grey-A200,
icon-material-blue-grey-A200 {
  color: #b0bec5;
}
.mdi-material-blue-grey-A400,
icon-material-blue-grey-A400 {
  color: #78909c;
}
.mdi-material-blue-grey-A700,
icon-material-blue-grey-A700 {
  color: #455a64;
}
.card {
  /***** Make height equal to width (http://stackoverflow.com/a/6615994) ****/
  display: inline-block;
  position: relative;
  width: 100%;
  /**************************************************************************/
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.84);
  background: #fff;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.card .card-height-indicator {
  margin-top: 100%;
}
.card .card-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.card .card-image {
  height: 60%;
  position: relative;
  overflow: hidden;
}
.card .card-image img {
  width: 100%;
  height: 100%;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  pointer-events: none;
}
.card .card-image .card-image-headline {
  position: absolute;
  bottom: 16px;
  left: 18px;
  color: #fff;
  font-size: 2em;
}
.card .card-body {
  height: 30%;
  padding: 18px;
}
.card .card-footer {
  height: 10%;
  padding: 18px;
}
.card .card-footer button {
  margin: 0 !important;
  position: relative;
  bottom: 25px;
  width: auto;
}
.card .card-footer button:first-child {
  left: -15px;
}
.modal-content {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
  border-radius: 2px;
  border: none;
}
.modal-content .modal-header {
  border-bottom: none;
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 0px;
  padding-left: 24px;
}
.modal-content .modal-body {
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
  padding-left: 24px;
}
.modal-content .modal-footer {
  border-top: none;
  padding: 7px;
}
.modal-content .modal-footer button {
  margin: 0;
  padding-left: 16px;
  padding-right: 16px;
  width: auto;
}
.modal-content .modal-footer button.pull-left {
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
  left: -5px;
}
.modal-content .modal-footer button + button {
  margin-bottom: 16px;
}
.modal-content .modal-body + .modal-footer {
  padding-top: 0;
}
.modal-backdrop {
  background: rgba(0, 0, 0, 0.3);
}
.label {
  border-radius: 1px;
}
.label,
.label-default {
  background-color: #9e9e9e;
}
.label-black {
  background-color: #000000;
}
.label-white {
  background-color: #ffffff;
}
.label-inverse {
  background-color: #3f51b5;
}
.label-primary {
  background-color: #009688;
}
.label-success {
  background-color: #4caf50;
}
.label-info {
  background-color: #03a9f4;
}
.label-warning {
  background-color: #ff5722;
}
.label-danger {
  background-color: #f44336;
}
.label-material-red {
  background-color: #f44336;
}
.label-material-red-50 {
  background-color: #ffebee;
}
.label-material-red-100 {
  background-color: #ffcdd2;
}
.label-material-red-200 {
  background-color: #ef9a9a;
}
.label-material-red-300 {
  background-color: #e57373;
}
.label-material-red-400 {
  background-color: #ef5350;
}
.label-material-red-500 {
  background-color: #f44336;
}
.label-material-red-600 {
  background-color: #e53935;
}
.label-material-red-700 {
  background-color: #d32f2f;
}
.label-material-red-800 {
  background-color: #c62828;
}
.label-material-red-900 {
  background-color: #b71c1c;
}
.label-material-red-A100 {
  background-color: #ff8a80;
}
.label-material-red-A200 {
  background-color: #ff5252;
}
.label-material-red-A400 {
  background-color: #ff1744;
}
.label-material-red-A700 {
  background-color: #d50000;
}
.label-material-pink {
  background-color: #e91e63;
}
.label-material-pink-50 {
  background-color: #fce4ec;
}
.label-material-pink-100 {
  background-color: #f8bbd0;
}
.label-material-pink-200 {
  background-color: #f48fb1;
}
.label-material-pink-300 {
  background-color: #f06292;
}
.label-material-pink-400 {
  background-color: #ec407a;
}
.label-material-pink-500 {
  background-color: #e91e63;
}
.label-material-pink-600 {
  background-color: #d81b60;
}
.label-material-pink-700 {
  background-color: #c2185b;
}
.label-material-pink-800 {
  background-color: #ad1457;
}
.label-material-pink-900 {
  background-color: #880e4f;
}
.label-material-pink-A100 {
  background-color: #ff80ab;
}
.label-material-pink-A200 {
  background-color: #ff4081;
}
.label-material-pink-A400 {
  background-color: #f50057;
}
.label-material-pink-A700 {
  background-color: #c51162;
}
.label-material-purple {
  background-color: #9c27b0;
}
.label-material-purple-50 {
  background-color: #f3e5f5;
}
.label-material-purple-100 {
  background-color: #e1bee7;
}
.label-material-purple-200 {
  background-color: #ce93d8;
}
.label-material-purple-300 {
  background-color: #ba68c8;
}
.label-material-purple-400 {
  background-color: #ab47bc;
}
.label-material-purple-500 {
  background-color: #9c27b0;
}
.label-material-purple-600 {
  background-color: #8e24aa;
}
.label-material-purple-700 {
  background-color: #7b1fa2;
}
.label-material-purple-800 {
  background-color: #6a1b9a;
}
.label-material-purple-900 {
  background-color: #4a148c;
}
.label-material-purple-A100 {
  background-color: #ea80fc;
}
.label-material-purple-A200 {
  background-color: #e040fb;
}
.label-material-purple-A400 {
  background-color: #d500f9;
}
.label-material-purple-A700 {
  background-color: #aa00ff;
}
.label-material-deep-purple {
  background-color: #673ab7;
}
.label-material-deep-purple-50 {
  background-color: #ede7f6;
}
.label-material-deep-purple-100 {
  background-color: #d1c4e9;
}
.label-material-deep-purple-200 {
  background-color: #b39ddb;
}
.label-material-deep-purple-300 {
  background-color: #9575cd;
}
.label-material-deep-purple-400 {
  background-color: #7e57c2;
}
.label-material-deep-purple-500 {
  background-color: #673ab7;
}
.label-material-deep-purple-600 {
  background-color: #5e35b1;
}
.label-material-deep-purple-700 {
  background-color: #512da8;
}
.label-material-deep-purple-800 {
  background-color: #4527a0;
}
.label-material-deep-purple-900 {
  background-color: #311b92;
}
.label-material-deep-purple-A100 {
  background-color: #b388ff;
}
.label-material-deep-purple-A200 {
  background-color: #7c4dff;
}
.label-material-deep-purple-A400 {
  background-color: #651fff;
}
.label-material-deep-purple-A700 {
  background-color: #6200ea;
}
.label-material-indigo {
  background-color: #3f51b5;
}
.label-material-indigo-50 {
  background-color: #e8eaf6;
}
.label-material-indigo-100 {
  background-color: #c5cae9;
}
.label-material-indigo-200 {
  background-color: #9fa8da;
}
.label-material-indigo-300 {
  background-color: #7986cb;
}
.label-material-indigo-400 {
  background-color: #5c6bc0;
}
.label-material-indigo-500 {
  background-color: #3f51b5;
}
.label-material-indigo-600 {
  background-color: #3949ab;
}
.label-material-indigo-700 {
  background-color: #303f9f;
}
.label-material-indigo-800 {
  background-color: #283593;
}
.label-material-indigo-900 {
  background-color: #1a237e;
}
.label-material-indigo-A100 {
  background-color: #8c9eff;
}
.label-material-indigo-A200 {
  background-color: #536dfe;
}
.label-material-indigo-A400 {
  background-color: #3d5afe;
}
.label-material-indigo-A700 {
  background-color: #304ffe;
}
.label-material-blue {
  background-color: #2196f3;
}
.label-material-blue-50 {
  background-color: #e3f2fd;
}
.label-material-blue-100 {
  background-color: #bbdefb;
}
.label-material-blue-200 {
  background-color: #90caf9;
}
.label-material-blue-300 {
  background-color: #64b5f6;
}
.label-material-blue-400 {
  background-color: #42a5f5;
}
.label-material-blue-500 {
  background-color: #2196f3;
}
.label-material-blue-600 {
  background-color: #1e88e5;
}
.label-material-blue-700 {
  background-color: #1976d2;
}
.label-material-blue-800 {
  background-color: #1565c0;
}
.label-material-blue-900 {
  background-color: #0d47a1;
}
.label-material-blue-A100 {
  background-color: #82b1ff;
}
.label-material-blue-A200 {
  background-color: #448aff;
}
.label-material-blue-A400 {
  background-color: #2979ff;
}
.label-material-blue-A700 {
  background-color: #2962ff;
}
.label-material-light-blue {
  background-color: #03a9f4;
}
.label-material-light-blue-50 {
  background-color: #e1f5fe;
}
.label-material-light-blue-100 {
  background-color: #b3e5fc;
}
.label-material-light-blue-200 {
  background-color: #81d4fa;
}
.label-material-light-blue-300 {
  background-color: #4fc3f7;
}
.label-material-light-blue-400 {
  background-color: #29b6f6;
}
.label-material-light-blue-500 {
  background-color: #03a9f4;
}
.label-material-light-blue-600 {
  background-color: #039be5;
}
.label-material-light-blue-700 {
  background-color: #0288d1;
}
.label-material-light-blue-800 {
  background-color: #0277bd;
}
.label-material-light-blue-900 {
  background-color: #01579b;
}
.label-material-light-blue-A100 {
  background-color: #80d8ff;
}
.label-material-light-blue-A200 {
  background-color: #40c4ff;
}
.label-material-light-blue-A400 {
  background-color: #00b0ff;
}
.label-material-light-blue-A700 {
  background-color: #0091ea;
}
.label-material-cyan {
  background-color: #00bcd4;
}
.label-material-cyan-50 {
  background-color: #e0f7fa;
}
.label-material-cyan-100 {
  background-color: #b2ebf2;
}
.label-material-cyan-200 {
  background-color: #80deea;
}
.label-material-cyan-300 {
  background-color: #4dd0e1;
}
.label-material-cyan-400 {
  background-color: #26c6da;
}
.label-material-cyan-500 {
  background-color: #00bcd4;
}
.label-material-cyan-600 {
  background-color: #00acc1;
}
.label-material-cyan-700 {
  background-color: #0097a7;
}
.label-material-cyan-800 {
  background-color: #00838f;
}
.label-material-cyan-900 {
  background-color: #006064;
}
.label-material-cyan-A100 {
  background-color: #84ffff;
}
.label-material-cyan-A200 {
  background-color: #18ffff;
}
.label-material-cyan-A400 {
  background-color: #00e5ff;
}
.label-material-cyan-A700 {
  background-color: #00b8d4;
}
.label-material-teal {
  background-color: #009688;
}
.label-material-teal-50 {
  background-color: #e0f2f1;
}
.label-material-teal-100 {
  background-color: #b2dfdb;
}
.label-material-teal-200 {
  background-color: #80cbc4;
}
.label-material-teal-300 {
  background-color: #4db6ac;
}
.label-material-teal-400 {
  background-color: #26a69a;
}
.label-material-teal-500 {
  background-color: #009688;
}
.label-material-teal-600 {
  background-color: #00897b;
}
.label-material-teal-700 {
  background-color: #00796b;
}
.label-material-teal-800 {
  background-color: #00695c;
}
.label-material-teal-900 {
  background-color: #004d40;
}
.label-material-teal-A100 {
  background-color: #a7ffeb;
}
.label-material-teal-A200 {
  background-color: #64ffda;
}
.label-material-teal-A400 {
  background-color: #1de9b6;
}
.label-material-teal-A700 {
  background-color: #00bfa5;
}
.label-material-green {
  background-color: #4caf50;
}
.label-material-green-50 {
  background-color: #e8f5e9;
}
.label-material-green-100 {
  background-color: #c8e6c9;
}
.label-material-green-200 {
  background-color: #a5d6a7;
}
.label-material-green-300 {
  background-color: #81c784;
}
.label-material-green-400 {
  background-color: #66bb6a;
}
.label-material-green-500 {
  background-color: #4caf50;
}
.label-material-green-600 {
  background-color: #43a047;
}
.label-material-green-700 {
  background-color: #388e3c;
}
.label-material-green-800 {
  background-color: #2e7d32;
}
.label-material-green-900 {
  background-color: #1b5e20;
}
.label-material-green-A100 {
  background-color: #b9f6ca;
}
.label-material-green-A200 {
  background-color: #69f0ae;
}
.label-material-green-A400 {
  background-color: #00e676;
}
.label-material-green-A700 {
  background-color: #00c853;
}
.label-material-light-green {
  background-color: #8bc34a;
}
.label-material-light-green-50 {
  background-color: #f1f8e9;
}
.label-material-light-green-100 {
  background-color: #dcedc8;
}
.label-material-light-green-200 {
  background-color: #c5e1a5;
}
.label-material-light-green-300 {
  background-color: #aed581;
}
.label-material-light-green-400 {
  background-color: #9ccc65;
}
.label-material-light-green-500 {
  background-color: #8bc34a;
}
.label-material-light-green-600 {
  background-color: #7cb342;
}
.label-material-light-green-700 {
  background-color: #689f38;
}
.label-material-light-green-800 {
  background-color: #558b2f;
}
.label-material-light-green-900 {
  background-color: #33691e;
}
.label-material-light-green-A100 {
  background-color: #ccff90;
}
.label-material-light-green-A200 {
  background-color: #b2ff59;
}
.label-material-light-green-A400 {
  background-color: #76ff03;
}
.label-material-light-green-A700 {
  background-color: #64dd17;
}
.label-material-lime {
  background-color: #cddc39;
}
.label-material-lime-50 {
  background-color: #f9fbe7;
}
.label-material-lime-100 {
  background-color: #f0f4c3;
}
.label-material-lime-200 {
  background-color: #e6ee9c;
}
.label-material-lime-300 {
  background-color: #dce775;
}
.label-material-lime-400 {
  background-color: #d4e157;
}
.label-material-lime-500 {
  background-color: #cddc39;
}
.label-material-lime-600 {
  background-color: #c0ca33;
}
.label-material-lime-700 {
  background-color: #afb42b;
}
.label-material-lime-800 {
  background-color: #9e9d24;
}
.label-material-lime-900 {
  background-color: #827717;
}
.label-material-lime-A100 {
  background-color: #f4ff81;
}
.label-material-lime-A200 {
  background-color: #eeff41;
}
.label-material-lime-A400 {
  background-color: #c6ff00;
}
.label-material-lime-A700 {
  background-color: #aeea00;
}
.label-material-yellow {
  background-color: #ffeb3b;
}
.label-material-yellow-50 {
  background-color: #fffde7;
}
.label-material-yellow-100 {
  background-color: #fff9c4;
}
.label-material-yellow-200 {
  background-color: #fff59d;
}
.label-material-yellow-300 {
  background-color: #fff176;
}
.label-material-yellow-400 {
  background-color: #ffee58;
}
.label-material-yellow-500 {
  background-color: #ffeb3b;
}
.label-material-yellow-600 {
  background-color: #fdd835;
}
.label-material-yellow-700 {
  background-color: #fbc02d;
}
.label-material-yellow-800 {
  background-color: #f9a825;
}
.label-material-yellow-900 {
  background-color: #f57f17;
}
.label-material-yellow-A100 {
  background-color: #ffff8d;
}
.label-material-yellow-A200 {
  background-color: #ffff00;
}
.label-material-yellow-A400 {
  background-color: #ffea00;
}
.label-material-yellow-A700 {
  background-color: #ffd600;
}
.label-material-amber {
  background-color: #ffc107;
}
.label-material-amber-50 {
  background-color: #fff8e1;
}
.label-material-amber-100 {
  background-color: #ffecb3;
}
.label-material-amber-200 {
  background-color: #ffe082;
}
.label-material-amber-300 {
  background-color: #ffd54f;
}
.label-material-amber-400 {
  background-color: #ffca28;
}
.label-material-amber-500 {
  background-color: #ffc107;
}
.label-material-amber-600 {
  background-color: #ffb300;
}
.label-material-amber-700 {
  background-color: #ffa000;
}
.label-material-amber-800 {
  background-color: #ff8f00;
}
.label-material-amber-900 {
  background-color: #ff6f00;
}
.label-material-amber-A100 {
  background-color: #ffe57f;
}
.label-material-amber-A200 {
  background-color: #ffd740;
}
.label-material-amber-A400 {
  background-color: #ffc400;
}
.label-material-amber-A700 {
  background-color: #ffab00;
}
.label-material-orange {
  background-color: #ff9800;
}
.label-material-orange-50 {
  background-color: #fff3e0;
}
.label-material-orange-100 {
  background-color: #ffe0b2;
}
.label-material-orange-200 {
  background-color: #ffcc80;
}
.label-material-orange-300 {
  background-color: #ffb74d;
}
.label-material-orange-400 {
  background-color: #ffa726;
}
.label-material-orange-500 {
  background-color: #ff9800;
}
.label-material-orange-600 {
  background-color: #fb8c00;
}
.label-material-orange-700 {
  background-color: #f57c00;
}
.label-material-orange-800 {
  background-color: #ef6c00;
}
.label-material-orange-900 {
  background-color: #e65100;
}
.label-material-orange-A100 {
  background-color: #ffd180;
}
.label-material-orange-A200 {
  background-color: #ffab40;
}
.label-material-orange-A400 {
  background-color: #ff9100;
}
.label-material-orange-A700 {
  background-color: #ff6d00;
}
.label-material-deep-orange {
  background-color: #ff5722;
}
.label-material-deep-orange-50 {
  background-color: #fbe9e7;
}
.label-material-deep-orange-100 {
  background-color: #ffccbc;
}
.label-material-deep-orange-200 {
  background-color: #ffab91;
}
.label-material-deep-orange-300 {
  background-color: #ff8a65;
}
.label-material-deep-orange-400 {
  background-color: #ff7043;
}
.label-material-deep-orange-500 {
  background-color: #ff5722;
}
.label-material-deep-orange-600 {
  background-color: #f4511e;
}
.label-material-deep-orange-700 {
  background-color: #e64a19;
}
.label-material-deep-orange-800 {
  background-color: #d84315;
}
.label-material-deep-orange-900 {
  background-color: #bf360c;
}
.label-material-deep-orange-A100 {
  background-color: #ff9e80;
}
.label-material-deep-orange-A200 {
  background-color: #ff6e40;
}
.label-material-deep-orange-A400 {
  background-color: #ff3d00;
}
.label-material-deep-orange-A700 {
  background-color: #dd2c00;
}
.label-material-brown {
  background-color: #795548;
}
.label-material-brown-50 {
  background-color: #efebe9;
}
.label-material-brown-100 {
  background-color: #d7ccc8;
}
.label-material-brown-200 {
  background-color: #bcaaa4;
}
.label-material-brown-300 {
  background-color: #a1887f;
}
.label-material-brown-400 {
  background-color: #8d6e63;
}
.label-material-brown-500 {
  background-color: #795548;
}
.label-material-brown-600 {
  background-color: #6d4c41;
}
.label-material-brown-700 {
  background-color: #5d4037;
}
.label-material-brown-800 {
  background-color: #4e342e;
}
.label-material-brown-900 {
  background-color: #3e2723;
}
.label-material-brown-A100 {
  background-color: #d7ccc8;
}
.label-material-brown-A200 {
  background-color: #bcaaa4;
}
.label-material-brown-A400 {
  background-color: #8d6e63;
}
.label-material-brown-A700 {
  background-color: #5d4037;
}
.label-material-grey {
  background-color: #9e9e9e;
}
.label-material-grey-50 {
  background-color: #fafafa;
}
.label-material-grey-100 {
  background-color: #f5f5f5;
}
.label-material-grey-200 {
  background-color: #eeeeee;
}
.label-material-grey-300 {
  background-color: #e0e0e0;
}
.label-material-grey-400 {
  background-color: #bdbdbd;
}
.label-material-grey-500 {
  background-color: #9e9e9e;
}
.label-material-grey-600 {
  background-color: #757575;
}
.label-material-grey-700 {
  background-color: #616161;
}
.label-material-grey-800 {
  background-color: #424242;
}
.label-material-grey-900 {
  background-color: #212121;
}
.label-material-grey-A100 {
  background-color: #f5f5f5;
}
.label-material-grey-A200 {
  background-color: #eeeeee;
}
.label-material-grey-A400 {
  background-color: #bdbdbd;
}
.label-material-grey-A700 {
  background-color: #616161;
}
.label-material-blue-grey {
  background-color: #607d8b;
}
.label-material-blue-grey-50 {
  background-color: #eceff1;
}
.label-material-blue-grey-100 {
  background-color: #cfd8dc;
}
.label-material-blue-grey-200 {
  background-color: #b0bec5;
}
.label-material-blue-grey-300 {
  background-color: #90a4ae;
}
.label-material-blue-grey-400 {
  background-color: #78909c;
}
.label-material-blue-grey-500 {
  background-color: #607d8b;
}
.label-material-blue-grey-600 {
  background-color: #546e7a;
}
.label-material-blue-grey-700 {
  background-color: #455a64;
}
.label-material-blue-grey-800 {
  background-color: #37474f;
}
.label-material-blue-grey-900 {
  background-color: #263238;
}
.label-material-blue-grey-A100 {
  background-color: #cfd8dc;
}
.label-material-blue-grey-A200 {
  background-color: #b0bec5;
}
.label-material-blue-grey-A400 {
  background-color: #78909c;
}
.label-material-blue-grey-A700 {
  background-color: #455a64;
}
.panel {
  border-radius: 2px;
  border: 0;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
}
.panel > .panel-heading,
.panel-default > .panel-heading {
  background-color: #eeeeee;
}
.panel-black > .panel-heading {
  background-color: #000000;
}
.panel-white > .panel-heading {
  background-color: #ffffff;
}
.panel-inverse > .panel-heading {
  background-color: #3f51b5;
}
.panel-primary > .panel-heading {
  background-color: #009688;
}
.panel-success > .panel-heading {
  background-color: #4caf50;
}
.panel-info > .panel-heading {
  background-color: #03a9f4;
}
.panel-warning > .panel-heading {
  background-color: #ff5722;
}
.panel-danger > .panel-heading {
  background-color: #f44336;
}
.panel-material-red > .panel-heading {
  background-color: #f44336;
}
.panel-material-red-50 > .panel-heading {
  background-color: #ffebee;
}
.panel-material-red-100 > .panel-heading {
  background-color: #ffcdd2;
}
.panel-material-red-200 > .panel-heading {
  background-color: #ef9a9a;
}
.panel-material-red-300 > .panel-heading {
  background-color: #e57373;
}
.panel-material-red-400 > .panel-heading {
  background-color: #ef5350;
}
.panel-material-red-500 > .panel-heading {
  background-color: #f44336;
}
.panel-material-red-600 > .panel-heading {
  background-color: #e53935;
}
.panel-material-red-700 > .panel-heading {
  background-color: #d32f2f;
}
.panel-material-red-800 > .panel-heading {
  background-color: #c62828;
}
.panel-material-red-900 > .panel-heading {
  background-color: #b71c1c;
}
.panel-material-red-A100 > .panel-heading {
  background-color: #ff8a80;
}
.panel-material-red-A200 > .panel-heading {
  background-color: #ff5252;
}
.panel-material-red-A400 > .panel-heading {
  background-color: #ff1744;
}
.panel-material-red-A700 > .panel-heading {
  background-color: #d50000;
}
.panel-material-pink > .panel-heading {
  background-color: #e91e63;
}
.panel-material-pink-50 > .panel-heading {
  background-color: #fce4ec;
}
.panel-material-pink-100 > .panel-heading {
  background-color: #f8bbd0;
}
.panel-material-pink-200 > .panel-heading {
  background-color: #f48fb1;
}
.panel-material-pink-300 > .panel-heading {
  background-color: #f06292;
}
.panel-material-pink-400 > .panel-heading {
  background-color: #ec407a;
}
.panel-material-pink-500 > .panel-heading {
  background-color: #e91e63;
}
.panel-material-pink-600 > .panel-heading {
  background-color: #d81b60;
}
.panel-material-pink-700 > .panel-heading {
  background-color: #c2185b;
}
.panel-material-pink-800 > .panel-heading {
  background-color: #ad1457;
}
.panel-material-pink-900 > .panel-heading {
  background-color: #880e4f;
}
.panel-material-pink-A100 > .panel-heading {
  background-color: #ff80ab;
}
.panel-material-pink-A200 > .panel-heading {
  background-color: #ff4081;
}
.panel-material-pink-A400 > .panel-heading {
  background-color: #f50057;
}
.panel-material-pink-A700 > .panel-heading {
  background-color: #c51162;
}
.panel-material-purple > .panel-heading {
  background-color: #9c27b0;
}
.panel-material-purple-50 > .panel-heading {
  background-color: #f3e5f5;
}
.panel-material-purple-100 > .panel-heading {
  background-color: #e1bee7;
}
.panel-material-purple-200 > .panel-heading {
  background-color: #ce93d8;
}
.panel-material-purple-300 > .panel-heading {
  background-color: #ba68c8;
}
.panel-material-purple-400 > .panel-heading {
  background-color: #ab47bc;
}
.panel-material-purple-500 > .panel-heading {
  background-color: #9c27b0;
}
.panel-material-purple-600 > .panel-heading {
  background-color: #8e24aa;
}
.panel-material-purple-700 > .panel-heading {
  background-color: #7b1fa2;
}
.panel-material-purple-800 > .panel-heading {
  background-color: #6a1b9a;
}
.panel-material-purple-900 > .panel-heading {
  background-color: #4a148c;
}
.panel-material-purple-A100 > .panel-heading {
  background-color: #ea80fc;
}
.panel-material-purple-A200 > .panel-heading {
  background-color: #e040fb;
}
.panel-material-purple-A400 > .panel-heading {
  background-color: #d500f9;
}
.panel-material-purple-A700 > .panel-heading {
  background-color: #aa00ff;
}
.panel-material-deep-purple > .panel-heading {
  background-color: #673ab7;
}
.panel-material-deep-purple-50 > .panel-heading {
  background-color: #ede7f6;
}
.panel-material-deep-purple-100 > .panel-heading {
  background-color: #d1c4e9;
}
.panel-material-deep-purple-200 > .panel-heading {
  background-color: #b39ddb;
}
.panel-material-deep-purple-300 > .panel-heading {
  background-color: #9575cd;
}
.panel-material-deep-purple-400 > .panel-heading {
  background-color: #7e57c2;
}
.panel-material-deep-purple-500 > .panel-heading {
  background-color: #673ab7;
}
.panel-material-deep-purple-600 > .panel-heading {
  background-color: #5e35b1;
}
.panel-material-deep-purple-700 > .panel-heading {
  background-color: #512da8;
}
.panel-material-deep-purple-800 > .panel-heading {
  background-color: #4527a0;
}
.panel-material-deep-purple-900 > .panel-heading {
  background-color: #311b92;
}
.panel-material-deep-purple-A100 > .panel-heading {
  background-color: #b388ff;
}
.panel-material-deep-purple-A200 > .panel-heading {
  background-color: #7c4dff;
}
.panel-material-deep-purple-A400 > .panel-heading {
  background-color: #651fff;
}
.panel-material-deep-purple-A700 > .panel-heading {
  background-color: #6200ea;
}
.panel-material-indigo > .panel-heading {
  background-color: #3f51b5;
}
.panel-material-indigo-50 > .panel-heading {
  background-color: #e8eaf6;
}
.panel-material-indigo-100 > .panel-heading {
  background-color: #c5cae9;
}
.panel-material-indigo-200 > .panel-heading {
  background-color: #9fa8da;
}
.panel-material-indigo-300 > .panel-heading {
  background-color: #7986cb;
}
.panel-material-indigo-400 > .panel-heading {
  background-color: #5c6bc0;
}
.panel-material-indigo-500 > .panel-heading {
  background-color: #3f51b5;
}
.panel-material-indigo-600 > .panel-heading {
  background-color: #3949ab;
}
.panel-material-indigo-700 > .panel-heading {
  background-color: #303f9f;
}
.panel-material-indigo-800 > .panel-heading {
  background-color: #283593;
}
.panel-material-indigo-900 > .panel-heading {
  background-color: #1a237e;
}
.panel-material-indigo-A100 > .panel-heading {
  background-color: #8c9eff;
}
.panel-material-indigo-A200 > .panel-heading {
  background-color: #536dfe;
}
.panel-material-indigo-A400 > .panel-heading {
  background-color: #3d5afe;
}
.panel-material-indigo-A700 > .panel-heading {
  background-color: #304ffe;
}
.panel-material-blue > .panel-heading {
  background-color: #2196f3;
}
.panel-material-blue-50 > .panel-heading {
  background-color: #e3f2fd;
}
.panel-material-blue-100 > .panel-heading {
  background-color: #bbdefb;
}
.panel-material-blue-200 > .panel-heading {
  background-color: #90caf9;
}
.panel-material-blue-300 > .panel-heading {
  background-color: #64b5f6;
}
.panel-material-blue-400 > .panel-heading {
  background-color: #42a5f5;
}
.panel-material-blue-500 > .panel-heading {
  background-color: #2196f3;
}
.panel-material-blue-600 > .panel-heading {
  background-color: #1e88e5;
}
.panel-material-blue-700 > .panel-heading {
  background-color: #1976d2;
}
.panel-material-blue-800 > .panel-heading {
  background-color: #1565c0;
}
.panel-material-blue-900 > .panel-heading {
  background-color: #0d47a1;
}
.panel-material-blue-A100 > .panel-heading {
  background-color: #82b1ff;
}
.panel-material-blue-A200 > .panel-heading {
  background-color: #448aff;
}
.panel-material-blue-A400 > .panel-heading {
  background-color: #2979ff;
}
.panel-material-blue-A700 > .panel-heading {
  background-color: #2962ff;
}
.panel-material-light-blue > .panel-heading {
  background-color: #03a9f4;
}
.panel-material-light-blue-50 > .panel-heading {
  background-color: #e1f5fe;
}
.panel-material-light-blue-100 > .panel-heading {
  background-color: #b3e5fc;
}
.panel-material-light-blue-200 > .panel-heading {
  background-color: #81d4fa;
}
.panel-material-light-blue-300 > .panel-heading {
  background-color: #4fc3f7;
}
.panel-material-light-blue-400 > .panel-heading {
  background-color: #29b6f6;
}
.panel-material-light-blue-500 > .panel-heading {
  background-color: #03a9f4;
}
.panel-material-light-blue-600 > .panel-heading {
  background-color: #039be5;
}
.panel-material-light-blue-700 > .panel-heading {
  background-color: #0288d1;
}
.panel-material-light-blue-800 > .panel-heading {
  background-color: #0277bd;
}
.panel-material-light-blue-900 > .panel-heading {
  background-color: #01579b;
}
.panel-material-light-blue-A100 > .panel-heading {
  background-color: #80d8ff;
}
.panel-material-light-blue-A200 > .panel-heading {
  background-color: #40c4ff;
}
.panel-material-light-blue-A400 > .panel-heading {
  background-color: #00b0ff;
}
.panel-material-light-blue-A700 > .panel-heading {
  background-color: #0091ea;
}
.panel-material-cyan > .panel-heading {
  background-color: #00bcd4;
}
.panel-material-cyan-50 > .panel-heading {
  background-color: #e0f7fa;
}
.panel-material-cyan-100 > .panel-heading {
  background-color: #b2ebf2;
}
.panel-material-cyan-200 > .panel-heading {
  background-color: #80deea;
}
.panel-material-cyan-300 > .panel-heading {
  background-color: #4dd0e1;
}
.panel-material-cyan-400 > .panel-heading {
  background-color: #26c6da;
}
.panel-material-cyan-500 > .panel-heading {
  background-color: #00bcd4;
}
.panel-material-cyan-600 > .panel-heading {
  background-color: #00acc1;
}
.panel-material-cyan-700 > .panel-heading {
  background-color: #0097a7;
}
.panel-material-cyan-800 > .panel-heading {
  background-color: #00838f;
}
.panel-material-cyan-900 > .panel-heading {
  background-color: #006064;
}
.panel-material-cyan-A100 > .panel-heading {
  background-color: #84ffff;
}
.panel-material-cyan-A200 > .panel-heading {
  background-color: #18ffff;
}
.panel-material-cyan-A400 > .panel-heading {
  background-color: #00e5ff;
}
.panel-material-cyan-A700 > .panel-heading {
  background-color: #00b8d4;
}
.panel-material-teal > .panel-heading {
  background-color: #009688;
}
.panel-material-teal-50 > .panel-heading {
  background-color: #e0f2f1;
}
.panel-material-teal-100 > .panel-heading {
  background-color: #b2dfdb;
}
.panel-material-teal-200 > .panel-heading {
  background-color: #80cbc4;
}
.panel-material-teal-300 > .panel-heading {
  background-color: #4db6ac;
}
.panel-material-teal-400 > .panel-heading {
  background-color: #26a69a;
}
.panel-material-teal-500 > .panel-heading {
  background-color: #009688;
}
.panel-material-teal-600 > .panel-heading {
  background-color: #00897b;
}
.panel-material-teal-700 > .panel-heading {
  background-color: #00796b;
}
.panel-material-teal-800 > .panel-heading {
  background-color: #00695c;
}
.panel-material-teal-900 > .panel-heading {
  background-color: #004d40;
}
.panel-material-teal-A100 > .panel-heading {
  background-color: #a7ffeb;
}
.panel-material-teal-A200 > .panel-heading {
  background-color: #64ffda;
}
.panel-material-teal-A400 > .panel-heading {
  background-color: #1de9b6;
}
.panel-material-teal-A700 > .panel-heading {
  background-color: #00bfa5;
}
.panel-material-green > .panel-heading {
  background-color: #4caf50;
}
.panel-material-green-50 > .panel-heading {
  background-color: #e8f5e9;
}
.panel-material-green-100 > .panel-heading {
  background-color: #c8e6c9;
}
.panel-material-green-200 > .panel-heading {
  background-color: #a5d6a7;
}
.panel-material-green-300 > .panel-heading {
  background-color: #81c784;
}
.panel-material-green-400 > .panel-heading {
  background-color: #66bb6a;
}
.panel-material-green-500 > .panel-heading {
  background-color: #4caf50;
}
.panel-material-green-600 > .panel-heading {
  background-color: #43a047;
}
.panel-material-green-700 > .panel-heading {
  background-color: #388e3c;
}
.panel-material-green-800 > .panel-heading {
  background-color: #2e7d32;
}
.panel-material-green-900 > .panel-heading {
  background-color: #1b5e20;
}
.panel-material-green-A100 > .panel-heading {
  background-color: #b9f6ca;
}
.panel-material-green-A200 > .panel-heading {
  background-color: #69f0ae;
}
.panel-material-green-A400 > .panel-heading {
  background-color: #00e676;
}
.panel-material-green-A700 > .panel-heading {
  background-color: #00c853;
}
.panel-material-light-green > .panel-heading {
  background-color: #8bc34a;
}
.panel-material-light-green-50 > .panel-heading {
  background-color: #f1f8e9;
}
.panel-material-light-green-100 > .panel-heading {
  background-color: #dcedc8;
}
.panel-material-light-green-200 > .panel-heading {
  background-color: #c5e1a5;
}
.panel-material-light-green-300 > .panel-heading {
  background-color: #aed581;
}
.panel-material-light-green-400 > .panel-heading {
  background-color: #9ccc65;
}
.panel-material-light-green-500 > .panel-heading {
  background-color: #8bc34a;
}
.panel-material-light-green-600 > .panel-heading {
  background-color: #7cb342;
}
.panel-material-light-green-700 > .panel-heading {
  background-color: #689f38;
}
.panel-material-light-green-800 > .panel-heading {
  background-color: #558b2f;
}
.panel-material-light-green-900 > .panel-heading {
  background-color: #33691e;
}
.panel-material-light-green-A100 > .panel-heading {
  background-color: #ccff90;
}
.panel-material-light-green-A200 > .panel-heading {
  background-color: #b2ff59;
}
.panel-material-light-green-A400 > .panel-heading {
  background-color: #76ff03;
}
.panel-material-light-green-A700 > .panel-heading {
  background-color: #64dd17;
}
.panel-material-lime > .panel-heading {
  background-color: #cddc39;
}
.panel-material-lime-50 > .panel-heading {
  background-color: #f9fbe7;
}
.panel-material-lime-100 > .panel-heading {
  background-color: #f0f4c3;
}
.panel-material-lime-200 > .panel-heading {
  background-color: #e6ee9c;
}
.panel-material-lime-300 > .panel-heading {
  background-color: #dce775;
}
.panel-material-lime-400 > .panel-heading {
  background-color: #d4e157;
}
.panel-material-lime-500 > .panel-heading {
  background-color: #cddc39;
}
.panel-material-lime-600 > .panel-heading {
  background-color: #c0ca33;
}
.panel-material-lime-700 > .panel-heading {
  background-color: #afb42b;
}
.panel-material-lime-800 > .panel-heading {
  background-color: #9e9d24;
}
.panel-material-lime-900 > .panel-heading {
  background-color: #827717;
}
.panel-material-lime-A100 > .panel-heading {
  background-color: #f4ff81;
}
.panel-material-lime-A200 > .panel-heading {
  background-color: #eeff41;
}
.panel-material-lime-A400 > .panel-heading {
  background-color: #c6ff00;
}
.panel-material-lime-A700 > .panel-heading {
  background-color: #aeea00;
}
.panel-material-yellow > .panel-heading {
  background-color: #ffeb3b;
}
.panel-material-yellow-50 > .panel-heading {
  background-color: #fffde7;
}
.panel-material-yellow-100 > .panel-heading {
  background-color: #fff9c4;
}
.panel-material-yellow-200 > .panel-heading {
  background-color: #fff59d;
}
.panel-material-yellow-300 > .panel-heading {
  background-color: #fff176;
}
.panel-material-yellow-400 > .panel-heading {
  background-color: #ffee58;
}
.panel-material-yellow-500 > .panel-heading {
  background-color: #ffeb3b;
}
.panel-material-yellow-600 > .panel-heading {
  background-color: #fdd835;
}
.panel-material-yellow-700 > .panel-heading {
  background-color: #fbc02d;
}
.panel-material-yellow-800 > .panel-heading {
  background-color: #f9a825;
}
.panel-material-yellow-900 > .panel-heading {
  background-color: #f57f17;
}
.panel-material-yellow-A100 > .panel-heading {
  background-color: #ffff8d;
}
.panel-material-yellow-A200 > .panel-heading {
  background-color: #ffff00;
}
.panel-material-yellow-A400 > .panel-heading {
  background-color: #ffea00;
}
.panel-material-yellow-A700 > .panel-heading {
  background-color: #ffd600;
}
.panel-material-amber > .panel-heading {
  background-color: #ffc107;
}
.panel-material-amber-50 > .panel-heading {
  background-color: #fff8e1;
}
.panel-material-amber-100 > .panel-heading {
  background-color: #ffecb3;
}
.panel-material-amber-200 > .panel-heading {
  background-color: #ffe082;
}
.panel-material-amber-300 > .panel-heading {
  background-color: #ffd54f;
}
.panel-material-amber-400 > .panel-heading {
  background-color: #ffca28;
}
.panel-material-amber-500 > .panel-heading {
  background-color: #ffc107;
}
.panel-material-amber-600 > .panel-heading {
  background-color: #ffb300;
}
.panel-material-amber-700 > .panel-heading {
  background-color: #ffa000;
}
.panel-material-amber-800 > .panel-heading {
  background-color: #ff8f00;
}
.panel-material-amber-900 > .panel-heading {
  background-color: #ff6f00;
}
.panel-material-amber-A100 > .panel-heading {
  background-color: #ffe57f;
}
.panel-material-amber-A200 > .panel-heading {
  background-color: #ffd740;
}
.panel-material-amber-A400 > .panel-heading {
  background-color: #ffc400;
}
.panel-material-amber-A700 > .panel-heading {
  background-color: #ffab00;
}
.panel-material-orange > .panel-heading {
  background-color: #ff9800;
}
.panel-material-orange-50 > .panel-heading {
  background-color: #fff3e0;
}
.panel-material-orange-100 > .panel-heading {
  background-color: #ffe0b2;
}
.panel-material-orange-200 > .panel-heading {
  background-color: #ffcc80;
}
.panel-material-orange-300 > .panel-heading {
  background-color: #ffb74d;
}
.panel-material-orange-400 > .panel-heading {
  background-color: #ffa726;
}
.panel-material-orange-500 > .panel-heading {
  background-color: #ff9800;
}
.panel-material-orange-600 > .panel-heading {
  background-color: #fb8c00;
}
.panel-material-orange-700 > .panel-heading {
  background-color: #f57c00;
}
.panel-material-orange-800 > .panel-heading {
  background-color: #ef6c00;
}
.panel-material-orange-900 > .panel-heading {
  background-color: #e65100;
}
.panel-material-orange-A100 > .panel-heading {
  background-color: #ffd180;
}
.panel-material-orange-A200 > .panel-heading {
  background-color: #ffab40;
}
.panel-material-orange-A400 > .panel-heading {
  background-color: #ff9100;
}
.panel-material-orange-A700 > .panel-heading {
  background-color: #ff6d00;
}
.panel-material-deep-orange > .panel-heading {
  background-color: #ff5722;
}
.panel-material-deep-orange-50 > .panel-heading {
  background-color: #fbe9e7;
}
.panel-material-deep-orange-100 > .panel-heading {
  background-color: #ffccbc;
}
.panel-material-deep-orange-200 > .panel-heading {
  background-color: #ffab91;
}
.panel-material-deep-orange-300 > .panel-heading {
  background-color: #ff8a65;
}
.panel-material-deep-orange-400 > .panel-heading {
  background-color: #ff7043;
}
.panel-material-deep-orange-500 > .panel-heading {
  background-color: #ff5722;
}
.panel-material-deep-orange-600 > .panel-heading {
  background-color: #f4511e;
}
.panel-material-deep-orange-700 > .panel-heading {
  background-color: #e64a19;
}
.panel-material-deep-orange-800 > .panel-heading {
  background-color: #d84315;
}
.panel-material-deep-orange-900 > .panel-heading {
  background-color: #bf360c;
}
.panel-material-deep-orange-A100 > .panel-heading {
  background-color: #ff9e80;
}
.panel-material-deep-orange-A200 > .panel-heading {
  background-color: #ff6e40;
}
.panel-material-deep-orange-A400 > .panel-heading {
  background-color: #ff3d00;
}
.panel-material-deep-orange-A700 > .panel-heading {
  background-color: #dd2c00;
}
.panel-material-brown > .panel-heading {
  background-color: #795548;
}
.panel-material-brown-50 > .panel-heading {
  background-color: #efebe9;
}
.panel-material-brown-100 > .panel-heading {
  background-color: #d7ccc8;
}
.panel-material-brown-200 > .panel-heading {
  background-color: #bcaaa4;
}
.panel-material-brown-300 > .panel-heading {
  background-color: #a1887f;
}
.panel-material-brown-400 > .panel-heading {
  background-color: #8d6e63;
}
.panel-material-brown-500 > .panel-heading {
  background-color: #795548;
}
.panel-material-brown-600 > .panel-heading {
  background-color: #6d4c41;
}
.panel-material-brown-700 > .panel-heading {
  background-color: #5d4037;
}
.panel-material-brown-800 > .panel-heading {
  background-color: #4e342e;
}
.panel-material-brown-900 > .panel-heading {
  background-color: #3e2723;
}
.panel-material-brown-A100 > .panel-heading {
  background-color: #d7ccc8;
}
.panel-material-brown-A200 > .panel-heading {
  background-color: #bcaaa4;
}
.panel-material-brown-A400 > .panel-heading {
  background-color: #8d6e63;
}
.panel-material-brown-A700 > .panel-heading {
  background-color: #5d4037;
}
.panel-material-grey > .panel-heading {
  background-color: #9e9e9e;
}
.panel-material-grey-50 > .panel-heading {
  background-color: #fafafa;
}
.panel-material-grey-100 > .panel-heading {
  background-color: #f5f5f5;
}
.panel-material-grey-200 > .panel-heading {
  background-color: #eeeeee;
}
.panel-material-grey-300 > .panel-heading {
  background-color: #e0e0e0;
}
.panel-material-grey-400 > .panel-heading {
  background-color: #bdbdbd;
}
.panel-material-grey-500 > .panel-heading {
  background-color: #9e9e9e;
}
.panel-material-grey-600 > .panel-heading {
  background-color: #757575;
}
.panel-material-grey-700 > .panel-heading {
  background-color: #616161;
}
.panel-material-grey-800 > .panel-heading {
  background-color: #424242;
}
.panel-material-grey-900 > .panel-heading {
  background-color: #212121;
}
.panel-material-grey-A100 > .panel-heading {
  background-color: #f5f5f5;
}
.panel-material-grey-A200 > .panel-heading {
  background-color: #eeeeee;
}
.panel-material-grey-A400 > .panel-heading {
  background-color: #bdbdbd;
}
.panel-material-grey-A700 > .panel-heading {
  background-color: #616161;
}
.panel-material-blue-grey > .panel-heading {
  background-color: #607d8b;
}
.panel-material-blue-grey-50 > .panel-heading {
  background-color: #eceff1;
}
.panel-material-blue-grey-100 > .panel-heading {
  background-color: #cfd8dc;
}
.panel-material-blue-grey-200 > .panel-heading {
  background-color: #b0bec5;
}
.panel-material-blue-grey-300 > .panel-heading {
  background-color: #90a4ae;
}
.panel-material-blue-grey-400 > .panel-heading {
  background-color: #78909c;
}
.panel-material-blue-grey-500 > .panel-heading {
  background-color: #607d8b;
}
.panel-material-blue-grey-600 > .panel-heading {
  background-color: #546e7a;
}
.panel-material-blue-grey-700 > .panel-heading {
  background-color: #455a64;
}
.panel-material-blue-grey-800 > .panel-heading {
  background-color: #37474f;
}
.panel-material-blue-grey-900 > .panel-heading {
  background-color: #263238;
}
.panel-material-blue-grey-A100 > .panel-heading {
  background-color: #cfd8dc;
}
.panel-material-blue-grey-A200 > .panel-heading {
  background-color: #b0bec5;
}
.panel-material-blue-grey-A400 > .panel-heading {
  background-color: #78909c;
}
.panel-material-blue-grey-A700 > .panel-heading {
  background-color: #455a64;
}
[class*="panel-"] > .panel-heading {
  color: rgba(255, 255, 255, 0.84);
  border: 0;
}
.panel-default > .panel-heading,
.panel:not([class*="panel-"]) > .panel-heading {
  color: rgba(0, 0, 0, 0.84);
}
.panel-footer {
  background-color: #eeeeee;
}
hr.on-dark {
  color: #1a1a1a;
}
hr.on-light {
  color: #ffffff;
}
@media (-webkit-min-device-pixel-ratio: 0.75), (min--moz-device-pixel-ratio: 0.75), (-o-device-pixel-ratio: 3/4), (min-device-pixel-ratio: 0.75), (min-resolution: 0.75dppx), (min-resolution: 120dpi) {
  hr {
    height: 0.75px;
  }
}
@media (-webkit-min-device-pixel-ratio: 1), (min--moz-device-pixel-ratio: 1), (-o-device-pixel-ratio: 1), (min-device-pixel-ratio: 1), (min-resolution: 1dppx), (min-resolution: 160dpi) {
  hr {
    height: 1px;
  }
}
@media (-webkit-min-device-pixel-ratio: 1.33), (min--moz-device-pixel-ratio: 1.33), (-o-device-pixel-ratio: 133/100), (min-device-pixel-ratio: 1.33), (min-resolution: 1.33dppx), (min-resolution: 213dpi) {
  hr {
    height: 1.333px;
  }
}
@media (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx), (min-resolution: 240dpi) {
  hr {
    height: 1.5px;
  }
}
@media (-webkit-min-device-pixel-ratio: 2), (min--moz-device-pixel-ratio: 2), (-o-device-pixel-ratio: 2/1), (min-device-pixel-ratio: 2), (min-resolution: 2dppx), (min-resolution: 380dpi) {
  hr {
    height: 2px;
  }
}
@media (-webkit-min-device-pixel-ratio: 3), (min--moz-device-pixel-ratio: 3), (-o-device-pixel-ratio: 3/1), (min-device-pixel-ratio: 3), (min-resolution: 3dppx), (min-resolution: 480dpi) {
  hr {
    height: 3px;
  }
}
@media (-webkit-min-device-pixel-ratio: 4), (min--moz-device-pixel-ratio: 4), (-o-device-pixel-ratio: 4/1), (min-device-pixel-ratio: 3), (min-resolution: 4dppx), (min-resolution: 640dpi) {
  hr {
    height: 4px;
  }
}
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}
*:focus {
  outline: 0;
}
.snackbar {
  background-color: #323232;
  color: rgba(255, 255, 255, 0.84);
  font-size: 14px;
  border-radius: 2px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
  height: 0;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in, height 0 linear 0.2s, padding 0 linear 0.2s, height 0 linear 0.2s;
  transform: translateY(200%);
}
.snackbar.snackbar-opened {
  padding: 14px 15px;
  margin-bottom: 20px;
  height: auto;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in, height 0 linear 0.2s, height 0 linear 0.2s;
  transform: none;
}
.snackbar.toast {
  border-radius: 200px;
}
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -ms-touch-action: none;
  user-select: none;
  box-sizing: border-box;
}
.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
}
.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}
.noUi-handle {
  position: relative;
  z-index: 1;
  box-sizing: border-box;
}
.noUi-stacking .noUi-handle {
  z-index: 10;
}
.noUi-stacking + .noUi-origin {
  *z-index: -1;
}
.noUi-state-tap .noUi-origin {
  transition: left 0.3s, top 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}
.noUi-horizontal {
  height: 10px;
}
.noUi-handle {
  box-sizing: border-box;
  width: 12px;
  height: 12px;
  left: -10px;
  top: -5px;
  cursor: ew-resize;
  border-radius: 100%;
  transition: all 0.2s ease-out;
  border: 1px solid;
}
.noUi-vertical .noUi-handle {
  margin-left: 5px;
  cursor: ns-resize;
}
.noUi-horizontal.noUi-extended {
  padding: 0 15px;
}
.noUi-horizontal.noUi-extended .noUi-origin {
  right: -15px;
}
.noUi-background {
  height: 2px;
  margin: 20px 0;
}
.noUi-origin {
  margin: 0;
  border-radius: 0;
  height: 2px;
  background: #c8c8c8;
}
.noUi-origin[style^="left: 0"] .noUi-handle {
  background-color: #fff;
  border: 2px solid #c8c8c8;
}
.noUi-origin[style^="left: 0"] .noUi-handle.noUi-active {
  border-width: 1px;
}
.noUi-target {
  border-radius: 2px;
}
.noUi-horizontal {
  height: 2px;
  margin: 15px 0;
}
.noUi-vertical {
  height: 100%;
  width: 2px;
  margin: 0 15px;
  display: inline-block;
}
.noUi-handle.noUi-active {
  transform: scale3d(2.5, 2.5, 1);
}
[disabled].noUi-slider {
  opacity: 0.5;
}
[disabled] .noUi-handle {
  cursor: not-allowed;
}
.slider {
  background: #c8c8c8;
}
.slider.noUi-connect,
.slider-default.noUi-connect {
  background-color: #009688;
}
.slider-black.noUi-connect {
  background-color: #000000;
}
.slider-white.noUi-connect {
  background-color: #ffffff;
}
.slider-inverse.noUi-connect {
  background-color: #3f51b5;
}
.slider-primary.noUi-connect {
  background-color: #009688;
}
.slider-success.noUi-connect {
  background-color: #4caf50;
}
.slider-info.noUi-connect {
  background-color: #03a9f4;
}
.slider-warning.noUi-connect {
  background-color: #ff5722;
}
.slider-danger.noUi-connect {
  background-color: #f44336;
}
.slider-material-red.noUi-connect {
  background-color: #f44336;
}
.slider-material-red-50.noUi-connect {
  background-color: #ffebee;
}
.slider-material-red-100.noUi-connect {
  background-color: #ffcdd2;
}
.slider-material-red-200.noUi-connect {
  background-color: #ef9a9a;
}
.slider-material-red-300.noUi-connect {
  background-color: #e57373;
}
.slider-material-red-400.noUi-connect {
  background-color: #ef5350;
}
.slider-material-red-500.noUi-connect {
  background-color: #f44336;
}
.slider-material-red-600.noUi-connect {
  background-color: #e53935;
}
.slider-material-red-700.noUi-connect {
  background-color: #d32f2f;
}
.slider-material-red-800.noUi-connect {
  background-color: #c62828;
}
.slider-material-red-900.noUi-connect {
  background-color: #b71c1c;
}
.slider-material-red-A100.noUi-connect {
  background-color: #ff8a80;
}
.slider-material-red-A200.noUi-connect {
  background-color: #ff5252;
}
.slider-material-red-A400.noUi-connect {
  background-color: #ff1744;
}
.slider-material-red-A700.noUi-connect {
  background-color: #d50000;
}
.slider-material-pink.noUi-connect {
  background-color: #e91e63;
}
.slider-material-pink-50.noUi-connect {
  background-color: #fce4ec;
}
.slider-material-pink-100.noUi-connect {
  background-color: #f8bbd0;
}
.slider-material-pink-200.noUi-connect {
  background-color: #f48fb1;
}
.slider-material-pink-300.noUi-connect {
  background-color: #f06292;
}
.slider-material-pink-400.noUi-connect {
  background-color: #ec407a;
}
.slider-material-pink-500.noUi-connect {
  background-color: #e91e63;
}
.slider-material-pink-600.noUi-connect {
  background-color: #d81b60;
}
.slider-material-pink-700.noUi-connect {
  background-color: #c2185b;
}
.slider-material-pink-800.noUi-connect {
  background-color: #ad1457;
}
.slider-material-pink-900.noUi-connect {
  background-color: #880e4f;
}
.slider-material-pink-A100.noUi-connect {
  background-color: #ff80ab;
}
.slider-material-pink-A200.noUi-connect {
  background-color: #ff4081;
}
.slider-material-pink-A400.noUi-connect {
  background-color: #f50057;
}
.slider-material-pink-A700.noUi-connect {
  background-color: #c51162;
}
.slider-material-purple.noUi-connect {
  background-color: #9c27b0;
}
.slider-material-purple-50.noUi-connect {
  background-color: #f3e5f5;
}
.slider-material-purple-100.noUi-connect {
  background-color: #e1bee7;
}
.slider-material-purple-200.noUi-connect {
  background-color: #ce93d8;
}
.slider-material-purple-300.noUi-connect {
  background-color: #ba68c8;
}
.slider-material-purple-400.noUi-connect {
  background-color: #ab47bc;
}
.slider-material-purple-500.noUi-connect {
  background-color: #9c27b0;
}
.slider-material-purple-600.noUi-connect {
  background-color: #8e24aa;
}
.slider-material-purple-700.noUi-connect {
  background-color: #7b1fa2;
}
.slider-material-purple-800.noUi-connect {
  background-color: #6a1b9a;
}
.slider-material-purple-900.noUi-connect {
  background-color: #4a148c;
}
.slider-material-purple-A100.noUi-connect {
  background-color: #ea80fc;
}
.slider-material-purple-A200.noUi-connect {
  background-color: #e040fb;
}
.slider-material-purple-A400.noUi-connect {
  background-color: #d500f9;
}
.slider-material-purple-A700.noUi-connect {
  background-color: #aa00ff;
}
.slider-material-deep-purple.noUi-connect {
  background-color: #673ab7;
}
.slider-material-deep-purple-50.noUi-connect {
  background-color: #ede7f6;
}
.slider-material-deep-purple-100.noUi-connect {
  background-color: #d1c4e9;
}
.slider-material-deep-purple-200.noUi-connect {
  background-color: #b39ddb;
}
.slider-material-deep-purple-300.noUi-connect {
  background-color: #9575cd;
}
.slider-material-deep-purple-400.noUi-connect {
  background-color: #7e57c2;
}
.slider-material-deep-purple-500.noUi-connect {
  background-color: #673ab7;
}
.slider-material-deep-purple-600.noUi-connect {
  background-color: #5e35b1;
}
.slider-material-deep-purple-700.noUi-connect {
  background-color: #512da8;
}
.slider-material-deep-purple-800.noUi-connect {
  background-color: #4527a0;
}
.slider-material-deep-purple-900.noUi-connect {
  background-color: #311b92;
}
.slider-material-deep-purple-A100.noUi-connect {
  background-color: #b388ff;
}
.slider-material-deep-purple-A200.noUi-connect {
  background-color: #7c4dff;
}
.slider-material-deep-purple-A400.noUi-connect {
  background-color: #651fff;
}
.slider-material-deep-purple-A700.noUi-connect {
  background-color: #6200ea;
}
.slider-material-indigo.noUi-connect {
  background-color: #3f51b5;
}
.slider-material-indigo-50.noUi-connect {
  background-color: #e8eaf6;
}
.slider-material-indigo-100.noUi-connect {
  background-color: #c5cae9;
}
.slider-material-indigo-200.noUi-connect {
  background-color: #9fa8da;
}
.slider-material-indigo-300.noUi-connect {
  background-color: #7986cb;
}
.slider-material-indigo-400.noUi-connect {
  background-color: #5c6bc0;
}
.slider-material-indigo-500.noUi-connect {
  background-color: #3f51b5;
}
.slider-material-indigo-600.noUi-connect {
  background-color: #3949ab;
}
.slider-material-indigo-700.noUi-connect {
  background-color: #303f9f;
}
.slider-material-indigo-800.noUi-connect {
  background-color: #283593;
}
.slider-material-indigo-900.noUi-connect {
  background-color: #1a237e;
}
.slider-material-indigo-A100.noUi-connect {
  background-color: #8c9eff;
}
.slider-material-indigo-A200.noUi-connect {
  background-color: #536dfe;
}
.slider-material-indigo-A400.noUi-connect {
  background-color: #3d5afe;
}
.slider-material-indigo-A700.noUi-connect {
  background-color: #304ffe;
}
.slider-material-blue.noUi-connect {
  background-color: #2196f3;
}
.slider-material-blue-50.noUi-connect {
  background-color: #e3f2fd;
}
.slider-material-blue-100.noUi-connect {
  background-color: #bbdefb;
}
.slider-material-blue-200.noUi-connect {
  background-color: #90caf9;
}
.slider-material-blue-300.noUi-connect {
  background-color: #64b5f6;
}
.slider-material-blue-400.noUi-connect {
  background-color: #42a5f5;
}
.slider-material-blue-500.noUi-connect {
  background-color: #2196f3;
}
.slider-material-blue-600.noUi-connect {
  background-color: #1e88e5;
}
.slider-material-blue-700.noUi-connect {
  background-color: #1976d2;
}
.slider-material-blue-800.noUi-connect {
  background-color: #1565c0;
}
.slider-material-blue-900.noUi-connect {
  background-color: #0d47a1;
}
.slider-material-blue-A100.noUi-connect {
  background-color: #82b1ff;
}
.slider-material-blue-A200.noUi-connect {
  background-color: #448aff;
}
.slider-material-blue-A400.noUi-connect {
  background-color: #2979ff;
}
.slider-material-blue-A700.noUi-connect {
  background-color: #2962ff;
}
.slider-material-light-blue.noUi-connect {
  background-color: #03a9f4;
}
.slider-material-light-blue-50.noUi-connect {
  background-color: #e1f5fe;
}
.slider-material-light-blue-100.noUi-connect {
  background-color: #b3e5fc;
}
.slider-material-light-blue-200.noUi-connect {
  background-color: #81d4fa;
}
.slider-material-light-blue-300.noUi-connect {
  background-color: #4fc3f7;
}
.slider-material-light-blue-400.noUi-connect {
  background-color: #29b6f6;
}
.slider-material-light-blue-500.noUi-connect {
  background-color: #03a9f4;
}
.slider-material-light-blue-600.noUi-connect {
  background-color: #039be5;
}
.slider-material-light-blue-700.noUi-connect {
  background-color: #0288d1;
}
.slider-material-light-blue-800.noUi-connect {
  background-color: #0277bd;
}
.slider-material-light-blue-900.noUi-connect {
  background-color: #01579b;
}
.slider-material-light-blue-A100.noUi-connect {
  background-color: #80d8ff;
}
.slider-material-light-blue-A200.noUi-connect {
  background-color: #40c4ff;
}
.slider-material-light-blue-A400.noUi-connect {
  background-color: #00b0ff;
}
.slider-material-light-blue-A700.noUi-connect {
  background-color: #0091ea;
}
.slider-material-cyan.noUi-connect {
  background-color: #00bcd4;
}
.slider-material-cyan-50.noUi-connect {
  background-color: #e0f7fa;
}
.slider-material-cyan-100.noUi-connect {
  background-color: #b2ebf2;
}
.slider-material-cyan-200.noUi-connect {
  background-color: #80deea;
}
.slider-material-cyan-300.noUi-connect {
  background-color: #4dd0e1;
}
.slider-material-cyan-400.noUi-connect {
  background-color: #26c6da;
}
.slider-material-cyan-500.noUi-connect {
  background-color: #00bcd4;
}
.slider-material-cyan-600.noUi-connect {
  background-color: #00acc1;
}
.slider-material-cyan-700.noUi-connect {
  background-color: #0097a7;
}
.slider-material-cyan-800.noUi-connect {
  background-color: #00838f;
}
.slider-material-cyan-900.noUi-connect {
  background-color: #006064;
}
.slider-material-cyan-A100.noUi-connect {
  background-color: #84ffff;
}
.slider-material-cyan-A200.noUi-connect {
  background-color: #18ffff;
}
.slider-material-cyan-A400.noUi-connect {
  background-color: #00e5ff;
}
.slider-material-cyan-A700.noUi-connect {
  background-color: #00b8d4;
}
.slider-material-teal.noUi-connect {
  background-color: #009688;
}
.slider-material-teal-50.noUi-connect {
  background-color: #e0f2f1;
}
.slider-material-teal-100.noUi-connect {
  background-color: #b2dfdb;
}
.slider-material-teal-200.noUi-connect {
  background-color: #80cbc4;
}
.slider-material-teal-300.noUi-connect {
  background-color: #4db6ac;
}
.slider-material-teal-400.noUi-connect {
  background-color: #26a69a;
}
.slider-material-teal-500.noUi-connect {
  background-color: #009688;
}
.slider-material-teal-600.noUi-connect {
  background-color: #00897b;
}
.slider-material-teal-700.noUi-connect {
  background-color: #00796b;
}
.slider-material-teal-800.noUi-connect {
  background-color: #00695c;
}
.slider-material-teal-900.noUi-connect {
  background-color: #004d40;
}
.slider-material-teal-A100.noUi-connect {
  background-color: #a7ffeb;
}
.slider-material-teal-A200.noUi-connect {
  background-color: #64ffda;
}
.slider-material-teal-A400.noUi-connect {
  background-color: #1de9b6;
}
.slider-material-teal-A700.noUi-connect {
  background-color: #00bfa5;
}
.slider-material-green.noUi-connect {
  background-color: #4caf50;
}
.slider-material-green-50.noUi-connect {
  background-color: #e8f5e9;
}
.slider-material-green-100.noUi-connect {
  background-color: #c8e6c9;
}
.slider-material-green-200.noUi-connect {
  background-color: #a5d6a7;
}
.slider-material-green-300.noUi-connect {
  background-color: #81c784;
}
.slider-material-green-400.noUi-connect {
  background-color: #66bb6a;
}
.slider-material-green-500.noUi-connect {
  background-color: #4caf50;
}
.slider-material-green-600.noUi-connect {
  background-color: #43a047;
}
.slider-material-green-700.noUi-connect {
  background-color: #388e3c;
}
.slider-material-green-800.noUi-connect {
  background-color: #2e7d32;
}
.slider-material-green-900.noUi-connect {
  background-color: #1b5e20;
}
.slider-material-green-A100.noUi-connect {
  background-color: #b9f6ca;
}
.slider-material-green-A200.noUi-connect {
  background-color: #69f0ae;
}
.slider-material-green-A400.noUi-connect {
  background-color: #00e676;
}
.slider-material-green-A700.noUi-connect {
  background-color: #00c853;
}
.slider-material-light-green.noUi-connect {
  background-color: #8bc34a;
}
.slider-material-light-green-50.noUi-connect {
  background-color: #f1f8e9;
}
.slider-material-light-green-100.noUi-connect {
  background-color: #dcedc8;
}
.slider-material-light-green-200.noUi-connect {
  background-color: #c5e1a5;
}
.slider-material-light-green-300.noUi-connect {
  background-color: #aed581;
}
.slider-material-light-green-400.noUi-connect {
  background-color: #9ccc65;
}
.slider-material-light-green-500.noUi-connect {
  background-color: #8bc34a;
}
.slider-material-light-green-600.noUi-connect {
  background-color: #7cb342;
}
.slider-material-light-green-700.noUi-connect {
  background-color: #689f38;
}
.slider-material-light-green-800.noUi-connect {
  background-color: #558b2f;
}
.slider-material-light-green-900.noUi-connect {
  background-color: #33691e;
}
.slider-material-light-green-A100.noUi-connect {
  background-color: #ccff90;
}
.slider-material-light-green-A200.noUi-connect {
  background-color: #b2ff59;
}
.slider-material-light-green-A400.noUi-connect {
  background-color: #76ff03;
}
.slider-material-light-green-A700.noUi-connect {
  background-color: #64dd17;
}
.slider-material-lime.noUi-connect {
  background-color: #cddc39;
}
.slider-material-lime-50.noUi-connect {
  background-color: #f9fbe7;
}
.slider-material-lime-100.noUi-connect {
  background-color: #f0f4c3;
}
.slider-material-lime-200.noUi-connect {
  background-color: #e6ee9c;
}
.slider-material-lime-300.noUi-connect {
  background-color: #dce775;
}
.slider-material-lime-400.noUi-connect {
  background-color: #d4e157;
}
.slider-material-lime-500.noUi-connect {
  background-color: #cddc39;
}
.slider-material-lime-600.noUi-connect {
  background-color: #c0ca33;
}
.slider-material-lime-700.noUi-connect {
  background-color: #afb42b;
}
.slider-material-lime-800.noUi-connect {
  background-color: #9e9d24;
}
.slider-material-lime-900.noUi-connect {
  background-color: #827717;
}
.slider-material-lime-A100.noUi-connect {
  background-color: #f4ff81;
}
.slider-material-lime-A200.noUi-connect {
  background-color: #eeff41;
}
.slider-material-lime-A400.noUi-connect {
  background-color: #c6ff00;
}
.slider-material-lime-A700.noUi-connect {
  background-color: #aeea00;
}
.slider-material-yellow.noUi-connect {
  background-color: #ffeb3b;
}
.slider-material-yellow-50.noUi-connect {
  background-color: #fffde7;
}
.slider-material-yellow-100.noUi-connect {
  background-color: #fff9c4;
}
.slider-material-yellow-200.noUi-connect {
  background-color: #fff59d;
}
.slider-material-yellow-300.noUi-connect {
  background-color: #fff176;
}
.slider-material-yellow-400.noUi-connect {
  background-color: #ffee58;
}
.slider-material-yellow-500.noUi-connect {
  background-color: #ffeb3b;
}
.slider-material-yellow-600.noUi-connect {
  background-color: #fdd835;
}
.slider-material-yellow-700.noUi-connect {
  background-color: #fbc02d;
}
.slider-material-yellow-800.noUi-connect {
  background-color: #f9a825;
}
.slider-material-yellow-900.noUi-connect {
  background-color: #f57f17;
}
.slider-material-yellow-A100.noUi-connect {
  background-color: #ffff8d;
}
.slider-material-yellow-A200.noUi-connect {
  background-color: #ffff00;
}
.slider-material-yellow-A400.noUi-connect {
  background-color: #ffea00;
}
.slider-material-yellow-A700.noUi-connect {
  background-color: #ffd600;
}
.slider-material-amber.noUi-connect {
  background-color: #ffc107;
}
.slider-material-amber-50.noUi-connect {
  background-color: #fff8e1;
}
.slider-material-amber-100.noUi-connect {
  background-color: #ffecb3;
}
.slider-material-amber-200.noUi-connect {
  background-color: #ffe082;
}
.slider-material-amber-300.noUi-connect {
  background-color: #ffd54f;
}
.slider-material-amber-400.noUi-connect {
  background-color: #ffca28;
}
.slider-material-amber-500.noUi-connect {
  background-color: #ffc107;
}
.slider-material-amber-600.noUi-connect {
  background-color: #ffb300;
}
.slider-material-amber-700.noUi-connect {
  background-color: #ffa000;
}
.slider-material-amber-800.noUi-connect {
  background-color: #ff8f00;
}
.slider-material-amber-900.noUi-connect {
  background-color: #ff6f00;
}
.slider-material-amber-A100.noUi-connect {
  background-color: #ffe57f;
}
.slider-material-amber-A200.noUi-connect {
  background-color: #ffd740;
}
.slider-material-amber-A400.noUi-connect {
  background-color: #ffc400;
}
.slider-material-amber-A700.noUi-connect {
  background-color: #ffab00;
}
.slider-material-orange.noUi-connect {
  background-color: #ff9800;
}
.slider-material-orange-50.noUi-connect {
  background-color: #fff3e0;
}
.slider-material-orange-100.noUi-connect {
  background-color: #ffe0b2;
}
.slider-material-orange-200.noUi-connect {
  background-color: #ffcc80;
}
.slider-material-orange-300.noUi-connect {
  background-color: #ffb74d;
}
.slider-material-orange-400.noUi-connect {
  background-color: #ffa726;
}
.slider-material-orange-500.noUi-connect {
  background-color: #ff9800;
}
.slider-material-orange-600.noUi-connect {
  background-color: #fb8c00;
}
.slider-material-orange-700.noUi-connect {
  background-color: #f57c00;
}
.slider-material-orange-800.noUi-connect {
  background-color: #ef6c00;
}
.slider-material-orange-900.noUi-connect {
  background-color: #e65100;
}
.slider-material-orange-A100.noUi-connect {
  background-color: #ffd180;
}
.slider-material-orange-A200.noUi-connect {
  background-color: #ffab40;
}
.slider-material-orange-A400.noUi-connect {
  background-color: #ff9100;
}
.slider-material-orange-A700.noUi-connect {
  background-color: #ff6d00;
}
.slider-material-deep-orange.noUi-connect {
  background-color: #ff5722;
}
.slider-material-deep-orange-50.noUi-connect {
  background-color: #fbe9e7;
}
.slider-material-deep-orange-100.noUi-connect {
  background-color: #ffccbc;
}
.slider-material-deep-orange-200.noUi-connect {
  background-color: #ffab91;
}
.slider-material-deep-orange-300.noUi-connect {
  background-color: #ff8a65;
}
.slider-material-deep-orange-400.noUi-connect {
  background-color: #ff7043;
}
.slider-material-deep-orange-500.noUi-connect {
  background-color: #ff5722;
}
.slider-material-deep-orange-600.noUi-connect {
  background-color: #f4511e;
}
.slider-material-deep-orange-700.noUi-connect {
  background-color: #e64a19;
}
.slider-material-deep-orange-800.noUi-connect {
  background-color: #d84315;
}
.slider-material-deep-orange-900.noUi-connect {
  background-color: #bf360c;
}
.slider-material-deep-orange-A100.noUi-connect {
  background-color: #ff9e80;
}
.slider-material-deep-orange-A200.noUi-connect {
  background-color: #ff6e40;
}
.slider-material-deep-orange-A400.noUi-connect {
  background-color: #ff3d00;
}
.slider-material-deep-orange-A700.noUi-connect {
  background-color: #dd2c00;
}
.slider-material-brown.noUi-connect {
  background-color: #795548;
}
.slider-material-brown-50.noUi-connect {
  background-color: #efebe9;
}
.slider-material-brown-100.noUi-connect {
  background-color: #d7ccc8;
}
.slider-material-brown-200.noUi-connect {
  background-color: #bcaaa4;
}
.slider-material-brown-300.noUi-connect {
  background-color: #a1887f;
}
.slider-material-brown-400.noUi-connect {
  background-color: #8d6e63;
}
.slider-material-brown-500.noUi-connect {
  background-color: #795548;
}
.slider-material-brown-600.noUi-connect {
  background-color: #6d4c41;
}
.slider-material-brown-700.noUi-connect {
  background-color: #5d4037;
}
.slider-material-brown-800.noUi-connect {
  background-color: #4e342e;
}
.slider-material-brown-900.noUi-connect {
  background-color: #3e2723;
}
.slider-material-brown-A100.noUi-connect {
  background-color: #d7ccc8;
}
.slider-material-brown-A200.noUi-connect {
  background-color: #bcaaa4;
}
.slider-material-brown-A400.noUi-connect {
  background-color: #8d6e63;
}
.slider-material-brown-A700.noUi-connect {
  background-color: #5d4037;
}
.slider-material-grey.noUi-connect {
  background-color: #9e9e9e;
}
.slider-material-grey-50.noUi-connect {
  background-color: #fafafa;
}
.slider-material-grey-100.noUi-connect {
  background-color: #f5f5f5;
}
.slider-material-grey-200.noUi-connect {
  background-color: #eeeeee;
}
.slider-material-grey-300.noUi-connect {
  background-color: #e0e0e0;
}
.slider-material-grey-400.noUi-connect {
  background-color: #bdbdbd;
}
.slider-material-grey-500.noUi-connect {
  background-color: #9e9e9e;
}
.slider-material-grey-600.noUi-connect {
  background-color: #757575;
}
.slider-material-grey-700.noUi-connect {
  background-color: #616161;
}
.slider-material-grey-800.noUi-connect {
  background-color: #424242;
}
.slider-material-grey-900.noUi-connect {
  background-color: #212121;
}
.slider-material-grey-A100.noUi-connect {
  background-color: #f5f5f5;
}
.slider-material-grey-A200.noUi-connect {
  background-color: #eeeeee;
}
.slider-material-grey-A400.noUi-connect {
  background-color: #bdbdbd;
}
.slider-material-grey-A700.noUi-connect {
  background-color: #616161;
}
.slider-material-blue-grey.noUi-connect {
  background-color: #607d8b;
}
.slider-material-blue-grey-50.noUi-connect {
  background-color: #eceff1;
}
.slider-material-blue-grey-100.noUi-connect {
  background-color: #cfd8dc;
}
.slider-material-blue-grey-200.noUi-connect {
  background-color: #b0bec5;
}
.slider-material-blue-grey-300.noUi-connect {
  background-color: #90a4ae;
}
.slider-material-blue-grey-400.noUi-connect {
  background-color: #78909c;
}
.slider-material-blue-grey-500.noUi-connect {
  background-color: #607d8b;
}
.slider-material-blue-grey-600.noUi-connect {
  background-color: #546e7a;
}
.slider-material-blue-grey-700.noUi-connect {
  background-color: #455a64;
}
.slider-material-blue-grey-800.noUi-connect {
  background-color: #37474f;
}
.slider-material-blue-grey-900.noUi-connect {
  background-color: #263238;
}
.slider-material-blue-grey-A100.noUi-connect {
  background-color: #cfd8dc;
}
.slider-material-blue-grey-A200.noUi-connect {
  background-color: #b0bec5;
}
.slider-material-blue-grey-A400.noUi-connect {
  background-color: #78909c;
}
.slider-material-blue-grey-A700.noUi-connect {
  background-color: #455a64;
}
.slider .noUi-connect,
.slider-default .noUi-connect {
  background-color: #009688;
}
.slider-black .noUi-connect {
  background-color: #000000;
}
.slider-white .noUi-connect {
  background-color: #ffffff;
}
.slider-inverse .noUi-connect {
  background-color: #3f51b5;
}
.slider-primary .noUi-connect {
  background-color: #009688;
}
.slider-success .noUi-connect {
  background-color: #4caf50;
}
.slider-info .noUi-connect {
  background-color: #03a9f4;
}
.slider-warning .noUi-connect {
  background-color: #ff5722;
}
.slider-danger .noUi-connect {
  background-color: #f44336;
}
.slider-material-red .noUi-connect {
  background-color: #f44336;
}
.slider-material-red-50 .noUi-connect {
  background-color: #ffebee;
}
.slider-material-red-100 .noUi-connect {
  background-color: #ffcdd2;
}
.slider-material-red-200 .noUi-connect {
  background-color: #ef9a9a;
}
.slider-material-red-300 .noUi-connect {
  background-color: #e57373;
}
.slider-material-red-400 .noUi-connect {
  background-color: #ef5350;
}
.slider-material-red-500 .noUi-connect {
  background-color: #f44336;
}
.slider-material-red-600 .noUi-connect {
  background-color: #e53935;
}
.slider-material-red-700 .noUi-connect {
  background-color: #d32f2f;
}
.slider-material-red-800 .noUi-connect {
  background-color: #c62828;
}
.slider-material-red-900 .noUi-connect {
  background-color: #b71c1c;
}
.slider-material-red-A100 .noUi-connect {
  background-color: #ff8a80;
}
.slider-material-red-A200 .noUi-connect {
  background-color: #ff5252;
}
.slider-material-red-A400 .noUi-connect {
  background-color: #ff1744;
}
.slider-material-red-A700 .noUi-connect {
  background-color: #d50000;
}
.slider-material-pink .noUi-connect {
  background-color: #e91e63;
}
.slider-material-pink-50 .noUi-connect {
  background-color: #fce4ec;
}
.slider-material-pink-100 .noUi-connect {
  background-color: #f8bbd0;
}
.slider-material-pink-200 .noUi-connect {
  background-color: #f48fb1;
}
.slider-material-pink-300 .noUi-connect {
  background-color: #f06292;
}
.slider-material-pink-400 .noUi-connect {
  background-color: #ec407a;
}
.slider-material-pink-500 .noUi-connect {
  background-color: #e91e63;
}
.slider-material-pink-600 .noUi-connect {
  background-color: #d81b60;
}
.slider-material-pink-700 .noUi-connect {
  background-color: #c2185b;
}
.slider-material-pink-800 .noUi-connect {
  background-color: #ad1457;
}
.slider-material-pink-900 .noUi-connect {
  background-color: #880e4f;
}
.slider-material-pink-A100 .noUi-connect {
  background-color: #ff80ab;
}
.slider-material-pink-A200 .noUi-connect {
  background-color: #ff4081;
}
.slider-material-pink-A400 .noUi-connect {
  background-color: #f50057;
}
.slider-material-pink-A700 .noUi-connect {
  background-color: #c51162;
}
.slider-material-purple .noUi-connect {
  background-color: #9c27b0;
}
.slider-material-purple-50 .noUi-connect {
  background-color: #f3e5f5;
}
.slider-material-purple-100 .noUi-connect {
  background-color: #e1bee7;
}
.slider-material-purple-200 .noUi-connect {
  background-color: #ce93d8;
}
.slider-material-purple-300 .noUi-connect {
  background-color: #ba68c8;
}
.slider-material-purple-400 .noUi-connect {
  background-color: #ab47bc;
}
.slider-material-purple-500 .noUi-connect {
  background-color: #9c27b0;
}
.slider-material-purple-600 .noUi-connect {
  background-color: #8e24aa;
}
.slider-material-purple-700 .noUi-connect {
  background-color: #7b1fa2;
}
.slider-material-purple-800 .noUi-connect {
  background-color: #6a1b9a;
}
.slider-material-purple-900 .noUi-connect {
  background-color: #4a148c;
}
.slider-material-purple-A100 .noUi-connect {
  background-color: #ea80fc;
}
.slider-material-purple-A200 .noUi-connect {
  background-color: #e040fb;
}
.slider-material-purple-A400 .noUi-connect {
  background-color: #d500f9;
}
.slider-material-purple-A700 .noUi-connect {
  background-color: #aa00ff;
}
.slider-material-deep-purple .noUi-connect {
  background-color: #673ab7;
}
.slider-material-deep-purple-50 .noUi-connect {
  background-color: #ede7f6;
}
.slider-material-deep-purple-100 .noUi-connect {
  background-color: #d1c4e9;
}
.slider-material-deep-purple-200 .noUi-connect {
  background-color: #b39ddb;
}
.slider-material-deep-purple-300 .noUi-connect {
  background-color: #9575cd;
}
.slider-material-deep-purple-400 .noUi-connect {
  background-color: #7e57c2;
}
.slider-material-deep-purple-500 .noUi-connect {
  background-color: #673ab7;
}
.slider-material-deep-purple-600 .noUi-connect {
  background-color: #5e35b1;
}
.slider-material-deep-purple-700 .noUi-connect {
  background-color: #512da8;
}
.slider-material-deep-purple-800 .noUi-connect {
  background-color: #4527a0;
}
.slider-material-deep-purple-900 .noUi-connect {
  background-color: #311b92;
}
.slider-material-deep-purple-A100 .noUi-connect {
  background-color: #b388ff;
}
.slider-material-deep-purple-A200 .noUi-connect {
  background-color: #7c4dff;
}
.slider-material-deep-purple-A400 .noUi-connect {
  background-color: #651fff;
}
.slider-material-deep-purple-A700 .noUi-connect {
  background-color: #6200ea;
}
.slider-material-indigo .noUi-connect {
  background-color: #3f51b5;
}
.slider-material-indigo-50 .noUi-connect {
  background-color: #e8eaf6;
}
.slider-material-indigo-100 .noUi-connect {
  background-color: #c5cae9;
}
.slider-material-indigo-200 .noUi-connect {
  background-color: #9fa8da;
}
.slider-material-indigo-300 .noUi-connect {
  background-color: #7986cb;
}
.slider-material-indigo-400 .noUi-connect {
  background-color: #5c6bc0;
}
.slider-material-indigo-500 .noUi-connect {
  background-color: #3f51b5;
}
.slider-material-indigo-600 .noUi-connect {
  background-color: #3949ab;
}
.slider-material-indigo-700 .noUi-connect {
  background-color: #303f9f;
}
.slider-material-indigo-800 .noUi-connect {
  background-color: #283593;
}
.slider-material-indigo-900 .noUi-connect {
  background-color: #1a237e;
}
.slider-material-indigo-A100 .noUi-connect {
  background-color: #8c9eff;
}
.slider-material-indigo-A200 .noUi-connect {
  background-color: #536dfe;
}
.slider-material-indigo-A400 .noUi-connect {
  background-color: #3d5afe;
}
.slider-material-indigo-A700 .noUi-connect {
  background-color: #304ffe;
}
.slider-material-blue .noUi-connect {
  background-color: #2196f3;
}
.slider-material-blue-50 .noUi-connect {
  background-color: #e3f2fd;
}
.slider-material-blue-100 .noUi-connect {
  background-color: #bbdefb;
}
.slider-material-blue-200 .noUi-connect {
  background-color: #90caf9;
}
.slider-material-blue-300 .noUi-connect {
  background-color: #64b5f6;
}
.slider-material-blue-400 .noUi-connect {
  background-color: #42a5f5;
}
.slider-material-blue-500 .noUi-connect {
  background-color: #2196f3;
}
.slider-material-blue-600 .noUi-connect {
  background-color: #1e88e5;
}
.slider-material-blue-700 .noUi-connect {
  background-color: #1976d2;
}
.slider-material-blue-800 .noUi-connect {
  background-color: #1565c0;
}
.slider-material-blue-900 .noUi-connect {
  background-color: #0d47a1;
}
.slider-material-blue-A100 .noUi-connect {
  background-color: #82b1ff;
}
.slider-material-blue-A200 .noUi-connect {
  background-color: #448aff;
}
.slider-material-blue-A400 .noUi-connect {
  background-color: #2979ff;
}
.slider-material-blue-A700 .noUi-connect {
  background-color: #2962ff;
}
.slider-material-light-blue .noUi-connect {
  background-color: #03a9f4;
}
.slider-material-light-blue-50 .noUi-connect {
  background-color: #e1f5fe;
}
.slider-material-light-blue-100 .noUi-connect {
  background-color: #b3e5fc;
}
.slider-material-light-blue-200 .noUi-connect {
  background-color: #81d4fa;
}
.slider-material-light-blue-300 .noUi-connect {
  background-color: #4fc3f7;
}
.slider-material-light-blue-400 .noUi-connect {
  background-color: #29b6f6;
}
.slider-material-light-blue-500 .noUi-connect {
  background-color: #03a9f4;
}
.slider-material-light-blue-600 .noUi-connect {
  background-color: #039be5;
}
.slider-material-light-blue-700 .noUi-connect {
  background-color: #0288d1;
}
.slider-material-light-blue-800 .noUi-connect {
  background-color: #0277bd;
}
.slider-material-light-blue-900 .noUi-connect {
  background-color: #01579b;
}
.slider-material-light-blue-A100 .noUi-connect {
  background-color: #80d8ff;
}
.slider-material-light-blue-A200 .noUi-connect {
  background-color: #40c4ff;
}
.slider-material-light-blue-A400 .noUi-connect {
  background-color: #00b0ff;
}
.slider-material-light-blue-A700 .noUi-connect {
  background-color: #0091ea;
}
.slider-material-cyan .noUi-connect {
  background-color: #00bcd4;
}
.slider-material-cyan-50 .noUi-connect {
  background-color: #e0f7fa;
}
.slider-material-cyan-100 .noUi-connect {
  background-color: #b2ebf2;
}
.slider-material-cyan-200 .noUi-connect {
  background-color: #80deea;
}
.slider-material-cyan-300 .noUi-connect {
  background-color: #4dd0e1;
}
.slider-material-cyan-400 .noUi-connect {
  background-color: #26c6da;
}
.slider-material-cyan-500 .noUi-connect {
  background-color: #00bcd4;
}
.slider-material-cyan-600 .noUi-connect {
  background-color: #00acc1;
}
.slider-material-cyan-700 .noUi-connect {
  background-color: #0097a7;
}
.slider-material-cyan-800 .noUi-connect {
  background-color: #00838f;
}
.slider-material-cyan-900 .noUi-connect {
  background-color: #006064;
}
.slider-material-cyan-A100 .noUi-connect {
  background-color: #84ffff;
}
.slider-material-cyan-A200 .noUi-connect {
  background-color: #18ffff;
}
.slider-material-cyan-A400 .noUi-connect {
  background-color: #00e5ff;
}
.slider-material-cyan-A700 .noUi-connect {
  background-color: #00b8d4;
}
.slider-material-teal .noUi-connect {
  background-color: #009688;
}
.slider-material-teal-50 .noUi-connect {
  background-color: #e0f2f1;
}
.slider-material-teal-100 .noUi-connect {
  background-color: #b2dfdb;
}
.slider-material-teal-200 .noUi-connect {
  background-color: #80cbc4;
}
.slider-material-teal-300 .noUi-connect {
  background-color: #4db6ac;
}
.slider-material-teal-400 .noUi-connect {
  background-color: #26a69a;
}
.slider-material-teal-500 .noUi-connect {
  background-color: #009688;
}
.slider-material-teal-600 .noUi-connect {
  background-color: #00897b;
}
.slider-material-teal-700 .noUi-connect {
  background-color: #00796b;
}
.slider-material-teal-800 .noUi-connect {
  background-color: #00695c;
}
.slider-material-teal-900 .noUi-connect {
  background-color: #004d40;
}
.slider-material-teal-A100 .noUi-connect {
  background-color: #a7ffeb;
}
.slider-material-teal-A200 .noUi-connect {
  background-color: #64ffda;
}
.slider-material-teal-A400 .noUi-connect {
  background-color: #1de9b6;
}
.slider-material-teal-A700 .noUi-connect {
  background-color: #00bfa5;
}
.slider-material-green .noUi-connect {
  background-color: #4caf50;
}
.slider-material-green-50 .noUi-connect {
  background-color: #e8f5e9;
}
.slider-material-green-100 .noUi-connect {
  background-color: #c8e6c9;
}
.slider-material-green-200 .noUi-connect {
  background-color: #a5d6a7;
}
.slider-material-green-300 .noUi-connect {
  background-color: #81c784;
}
.slider-material-green-400 .noUi-connect {
  background-color: #66bb6a;
}
.slider-material-green-500 .noUi-connect {
  background-color: #4caf50;
}
.slider-material-green-600 .noUi-connect {
  background-color: #43a047;
}
.slider-material-green-700 .noUi-connect {
  background-color: #388e3c;
}
.slider-material-green-800 .noUi-connect {
  background-color: #2e7d32;
}
.slider-material-green-900 .noUi-connect {
  background-color: #1b5e20;
}
.slider-material-green-A100 .noUi-connect {
  background-color: #b9f6ca;
}
.slider-material-green-A200 .noUi-connect {
  background-color: #69f0ae;
}
.slider-material-green-A400 .noUi-connect {
  background-color: #00e676;
}
.slider-material-green-A700 .noUi-connect {
  background-color: #00c853;
}
.slider-material-light-green .noUi-connect {
  background-color: #8bc34a;
}
.slider-material-light-green-50 .noUi-connect {
  background-color: #f1f8e9;
}
.slider-material-light-green-100 .noUi-connect {
  background-color: #dcedc8;
}
.slider-material-light-green-200 .noUi-connect {
  background-color: #c5e1a5;
}
.slider-material-light-green-300 .noUi-connect {
  background-color: #aed581;
}
.slider-material-light-green-400 .noUi-connect {
  background-color: #9ccc65;
}
.slider-material-light-green-500 .noUi-connect {
  background-color: #8bc34a;
}
.slider-material-light-green-600 .noUi-connect {
  background-color: #7cb342;
}
.slider-material-light-green-700 .noUi-connect {
  background-color: #689f38;
}
.slider-material-light-green-800 .noUi-connect {
  background-color: #558b2f;
}
.slider-material-light-green-900 .noUi-connect {
  background-color: #33691e;
}
.slider-material-light-green-A100 .noUi-connect {
  background-color: #ccff90;
}
.slider-material-light-green-A200 .noUi-connect {
  background-color: #b2ff59;
}
.slider-material-light-green-A400 .noUi-connect {
  background-color: #76ff03;
}
.slider-material-light-green-A700 .noUi-connect {
  background-color: #64dd17;
}
.slider-material-lime .noUi-connect {
  background-color: #cddc39;
}
.slider-material-lime-50 .noUi-connect {
  background-color: #f9fbe7;
}
.slider-material-lime-100 .noUi-connect {
  background-color: #f0f4c3;
}
.slider-material-lime-200 .noUi-connect {
  background-color: #e6ee9c;
}
.slider-material-lime-300 .noUi-connect {
  background-color: #dce775;
}
.slider-material-lime-400 .noUi-connect {
  background-color: #d4e157;
}
.slider-material-lime-500 .noUi-connect {
  background-color: #cddc39;
}
.slider-material-lime-600 .noUi-connect {
  background-color: #c0ca33;
}
.slider-material-lime-700 .noUi-connect {
  background-color: #afb42b;
}
.slider-material-lime-800 .noUi-connect {
  background-color: #9e9d24;
}
.slider-material-lime-900 .noUi-connect {
  background-color: #827717;
}
.slider-material-lime-A100 .noUi-connect {
  background-color: #f4ff81;
}
.slider-material-lime-A200 .noUi-connect {
  background-color: #eeff41;
}
.slider-material-lime-A400 .noUi-connect {
  background-color: #c6ff00;
}
.slider-material-lime-A700 .noUi-connect {
  background-color: #aeea00;
}
.slider-material-yellow .noUi-connect {
  background-color: #ffeb3b;
}
.slider-material-yellow-50 .noUi-connect {
  background-color: #fffde7;
}
.slider-material-yellow-100 .noUi-connect {
  background-color: #fff9c4;
}
.slider-material-yellow-200 .noUi-connect {
  background-color: #fff59d;
}
.slider-material-yellow-300 .noUi-connect {
  background-color: #fff176;
}
.slider-material-yellow-400 .noUi-connect {
  background-color: #ffee58;
}
.slider-material-yellow-500 .noUi-connect {
  background-color: #ffeb3b;
}
.slider-material-yellow-600 .noUi-connect {
  background-color: #fdd835;
}
.slider-material-yellow-700 .noUi-connect {
  background-color: #fbc02d;
}
.slider-material-yellow-800 .noUi-connect {
  background-color: #f9a825;
}
.slider-material-yellow-900 .noUi-connect {
  background-color: #f57f17;
}
.slider-material-yellow-A100 .noUi-connect {
  background-color: #ffff8d;
}
.slider-material-yellow-A200 .noUi-connect {
  background-color: #ffff00;
}
.slider-material-yellow-A400 .noUi-connect {
  background-color: #ffea00;
}
.slider-material-yellow-A700 .noUi-connect {
  background-color: #ffd600;
}
.slider-material-amber .noUi-connect {
  background-color: #ffc107;
}
.slider-material-amber-50 .noUi-connect {
  background-color: #fff8e1;
}
.slider-material-amber-100 .noUi-connect {
  background-color: #ffecb3;
}
.slider-material-amber-200 .noUi-connect {
  background-color: #ffe082;
}
.slider-material-amber-300 .noUi-connect {
  background-color: #ffd54f;
}
.slider-material-amber-400 .noUi-connect {
  background-color: #ffca28;
}
.slider-material-amber-500 .noUi-connect {
  background-color: #ffc107;
}
.slider-material-amber-600 .noUi-connect {
  background-color: #ffb300;
}
.slider-material-amber-700 .noUi-connect {
  background-color: #ffa000;
}
.slider-material-amber-800 .noUi-connect {
  background-color: #ff8f00;
}
.slider-material-amber-900 .noUi-connect {
  background-color: #ff6f00;
}
.slider-material-amber-A100 .noUi-connect {
  background-color: #ffe57f;
}
.slider-material-amber-A200 .noUi-connect {
  background-color: #ffd740;
}
.slider-material-amber-A400 .noUi-connect {
  background-color: #ffc400;
}
.slider-material-amber-A700 .noUi-connect {
  background-color: #ffab00;
}
.slider-material-orange .noUi-connect {
  background-color: #ff9800;
}
.slider-material-orange-50 .noUi-connect {
  background-color: #fff3e0;
}
.slider-material-orange-100 .noUi-connect {
  background-color: #ffe0b2;
}
.slider-material-orange-200 .noUi-connect {
  background-color: #ffcc80;
}
.slider-material-orange-300 .noUi-connect {
  background-color: #ffb74d;
}
.slider-material-orange-400 .noUi-connect {
  background-color: #ffa726;
}
.slider-material-orange-500 .noUi-connect {
  background-color: #ff9800;
}
.slider-material-orange-600 .noUi-connect {
  background-color: #fb8c00;
}
.slider-material-orange-700 .noUi-connect {
  background-color: #f57c00;
}
.slider-material-orange-800 .noUi-connect {
  background-color: #ef6c00;
}
.slider-material-orange-900 .noUi-connect {
  background-color: #e65100;
}
.slider-material-orange-A100 .noUi-connect {
  background-color: #ffd180;
}
.slider-material-orange-A200 .noUi-connect {
  background-color: #ffab40;
}
.slider-material-orange-A400 .noUi-connect {
  background-color: #ff9100;
}
.slider-material-orange-A700 .noUi-connect {
  background-color: #ff6d00;
}
.slider-material-deep-orange .noUi-connect {
  background-color: #ff5722;
}
.slider-material-deep-orange-50 .noUi-connect {
  background-color: #fbe9e7;
}
.slider-material-deep-orange-100 .noUi-connect {
  background-color: #ffccbc;
}
.slider-material-deep-orange-200 .noUi-connect {
  background-color: #ffab91;
}
.slider-material-deep-orange-300 .noUi-connect {
  background-color: #ff8a65;
}
.slider-material-deep-orange-400 .noUi-connect {
  background-color: #ff7043;
}
.slider-material-deep-orange-500 .noUi-connect {
  background-color: #ff5722;
}
.slider-material-deep-orange-600 .noUi-connect {
  background-color: #f4511e;
}
.slider-material-deep-orange-700 .noUi-connect {
  background-color: #e64a19;
}
.slider-material-deep-orange-800 .noUi-connect {
  background-color: #d84315;
}
.slider-material-deep-orange-900 .noUi-connect {
  background-color: #bf360c;
}
.slider-material-deep-orange-A100 .noUi-connect {
  background-color: #ff9e80;
}
.slider-material-deep-orange-A200 .noUi-connect {
  background-color: #ff6e40;
}
.slider-material-deep-orange-A400 .noUi-connect {
  background-color: #ff3d00;
}
.slider-material-deep-orange-A700 .noUi-connect {
  background-color: #dd2c00;
}
.slider-material-brown .noUi-connect {
  background-color: #795548;
}
.slider-material-brown-50 .noUi-connect {
  background-color: #efebe9;
}
.slider-material-brown-100 .noUi-connect {
  background-color: #d7ccc8;
}
.slider-material-brown-200 .noUi-connect {
  background-color: #bcaaa4;
}
.slider-material-brown-300 .noUi-connect {
  background-color: #a1887f;
}
.slider-material-brown-400 .noUi-connect {
  background-color: #8d6e63;
}
.slider-material-brown-500 .noUi-connect {
  background-color: #795548;
}
.slider-material-brown-600 .noUi-connect {
  background-color: #6d4c41;
}
.slider-material-brown-700 .noUi-connect {
  background-color: #5d4037;
}
.slider-material-brown-800 .noUi-connect {
  background-color: #4e342e;
}
.slider-material-brown-900 .noUi-connect {
  background-color: #3e2723;
}
.slider-material-brown-A100 .noUi-connect {
  background-color: #d7ccc8;
}
.slider-material-brown-A200 .noUi-connect {
  background-color: #bcaaa4;
}
.slider-material-brown-A400 .noUi-connect {
  background-color: #8d6e63;
}
.slider-material-brown-A700 .noUi-connect {
  background-color: #5d4037;
}
.slider-material-grey .noUi-connect {
  background-color: #9e9e9e;
}
.slider-material-grey-50 .noUi-connect {
  background-color: #fafafa;
}
.slider-material-grey-100 .noUi-connect {
  background-color: #f5f5f5;
}
.slider-material-grey-200 .noUi-connect {
  background-color: #eeeeee;
}
.slider-material-grey-300 .noUi-connect {
  background-color: #e0e0e0;
}
.slider-material-grey-400 .noUi-connect {
  background-color: #bdbdbd;
}
.slider-material-grey-500 .noUi-connect {
  background-color: #9e9e9e;
}
.slider-material-grey-600 .noUi-connect {
  background-color: #757575;
}
.slider-material-grey-700 .noUi-connect {
  background-color: #616161;
}
.slider-material-grey-800 .noUi-connect {
  background-color: #424242;
}
.slider-material-grey-900 .noUi-connect {
  background-color: #212121;
}
.slider-material-grey-A100 .noUi-connect {
  background-color: #f5f5f5;
}
.slider-material-grey-A200 .noUi-connect {
  background-color: #eeeeee;
}
.slider-material-grey-A400 .noUi-connect {
  background-color: #bdbdbd;
}
.slider-material-grey-A700 .noUi-connect {
  background-color: #616161;
}
.slider-material-blue-grey .noUi-connect {
  background-color: #607d8b;
}
.slider-material-blue-grey-50 .noUi-connect {
  background-color: #eceff1;
}
.slider-material-blue-grey-100 .noUi-connect {
  background-color: #cfd8dc;
}
.slider-material-blue-grey-200 .noUi-connect {
  background-color: #b0bec5;
}
.slider-material-blue-grey-300 .noUi-connect {
  background-color: #90a4ae;
}
.slider-material-blue-grey-400 .noUi-connect {
  background-color: #78909c;
}
.slider-material-blue-grey-500 .noUi-connect {
  background-color: #607d8b;
}
.slider-material-blue-grey-600 .noUi-connect {
  background-color: #546e7a;
}
.slider-material-blue-grey-700 .noUi-connect {
  background-color: #455a64;
}
.slider-material-blue-grey-800 .noUi-connect {
  background-color: #37474f;
}
.slider-material-blue-grey-900 .noUi-connect {
  background-color: #263238;
}
.slider-material-blue-grey-A100 .noUi-connect {
  background-color: #cfd8dc;
}
.slider-material-blue-grey-A200 .noUi-connect {
  background-color: #b0bec5;
}
.slider-material-blue-grey-A400 .noUi-connect {
  background-color: #78909c;
}
.slider-material-blue-grey-A700 .noUi-connect {
  background-color: #455a64;
}
.slider .noUi-handle,
.slider-default .noUi-handle {
  background-color: #009688;
}
.slider-black .noUi-handle {
  background-color: #000000;
}
.slider-white .noUi-handle {
  background-color: #ffffff;
}
.slider-inverse .noUi-handle {
  background-color: #3f51b5;
}
.slider-primary .noUi-handle {
  background-color: #009688;
}
.slider-success .noUi-handle {
  background-color: #4caf50;
}
.slider-info .noUi-handle {
  background-color: #03a9f4;
}
.slider-warning .noUi-handle {
  background-color: #ff5722;
}
.slider-danger .noUi-handle {
  background-color: #f44336;
}
.slider-material-red .noUi-handle {
  background-color: #f44336;
}
.slider-material-red-50 .noUi-handle {
  background-color: #ffebee;
}
.slider-material-red-100 .noUi-handle {
  background-color: #ffcdd2;
}
.slider-material-red-200 .noUi-handle {
  background-color: #ef9a9a;
}
.slider-material-red-300 .noUi-handle {
  background-color: #e57373;
}
.slider-material-red-400 .noUi-handle {
  background-color: #ef5350;
}
.slider-material-red-500 .noUi-handle {
  background-color: #f44336;
}
.slider-material-red-600 .noUi-handle {
  background-color: #e53935;
}
.slider-material-red-700 .noUi-handle {
  background-color: #d32f2f;
}
.slider-material-red-800 .noUi-handle {
  background-color: #c62828;
}
.slider-material-red-900 .noUi-handle {
  background-color: #b71c1c;
}
.slider-material-red-A100 .noUi-handle {
  background-color: #ff8a80;
}
.slider-material-red-A200 .noUi-handle {
  background-color: #ff5252;
}
.slider-material-red-A400 .noUi-handle {
  background-color: #ff1744;
}
.slider-material-red-A700 .noUi-handle {
  background-color: #d50000;
}
.slider-material-pink .noUi-handle {
  background-color: #e91e63;
}
.slider-material-pink-50 .noUi-handle {
  background-color: #fce4ec;
}
.slider-material-pink-100 .noUi-handle {
  background-color: #f8bbd0;
}
.slider-material-pink-200 .noUi-handle {
  background-color: #f48fb1;
}
.slider-material-pink-300 .noUi-handle {
  background-color: #f06292;
}
.slider-material-pink-400 .noUi-handle {
  background-color: #ec407a;
}
.slider-material-pink-500 .noUi-handle {
  background-color: #e91e63;
}
.slider-material-pink-600 .noUi-handle {
  background-color: #d81b60;
}
.slider-material-pink-700 .noUi-handle {
  background-color: #c2185b;
}
.slider-material-pink-800 .noUi-handle {
  background-color: #ad1457;
}
.slider-material-pink-900 .noUi-handle {
  background-color: #880e4f;
}
.slider-material-pink-A100 .noUi-handle {
  background-color: #ff80ab;
}
.slider-material-pink-A200 .noUi-handle {
  background-color: #ff4081;
}
.slider-material-pink-A400 .noUi-handle {
  background-color: #f50057;
}
.slider-material-pink-A700 .noUi-handle {
  background-color: #c51162;
}
.slider-material-purple .noUi-handle {
  background-color: #9c27b0;
}
.slider-material-purple-50 .noUi-handle {
  background-color: #f3e5f5;
}
.slider-material-purple-100 .noUi-handle {
  background-color: #e1bee7;
}
.slider-material-purple-200 .noUi-handle {
  background-color: #ce93d8;
}
.slider-material-purple-300 .noUi-handle {
  background-color: #ba68c8;
}
.slider-material-purple-400 .noUi-handle {
  background-color: #ab47bc;
}
.slider-material-purple-500 .noUi-handle {
  background-color: #9c27b0;
}
.slider-material-purple-600 .noUi-handle {
  background-color: #8e24aa;
}
.slider-material-purple-700 .noUi-handle {
  background-color: #7b1fa2;
}
.slider-material-purple-800 .noUi-handle {
  background-color: #6a1b9a;
}
.slider-material-purple-900 .noUi-handle {
  background-color: #4a148c;
}
.slider-material-purple-A100 .noUi-handle {
  background-color: #ea80fc;
}
.slider-material-purple-A200 .noUi-handle {
  background-color: #e040fb;
}
.slider-material-purple-A400 .noUi-handle {
  background-color: #d500f9;
}
.slider-material-purple-A700 .noUi-handle {
  background-color: #aa00ff;
}
.slider-material-deep-purple .noUi-handle {
  background-color: #673ab7;
}
.slider-material-deep-purple-50 .noUi-handle {
  background-color: #ede7f6;
}
.slider-material-deep-purple-100 .noUi-handle {
  background-color: #d1c4e9;
}
.slider-material-deep-purple-200 .noUi-handle {
  background-color: #b39ddb;
}
.slider-material-deep-purple-300 .noUi-handle {
  background-color: #9575cd;
}
.slider-material-deep-purple-400 .noUi-handle {
  background-color: #7e57c2;
}
.slider-material-deep-purple-500 .noUi-handle {
  background-color: #673ab7;
}
.slider-material-deep-purple-600 .noUi-handle {
  background-color: #5e35b1;
}
.slider-material-deep-purple-700 .noUi-handle {
  background-color: #512da8;
}
.slider-material-deep-purple-800 .noUi-handle {
  background-color: #4527a0;
}
.slider-material-deep-purple-900 .noUi-handle {
  background-color: #311b92;
}
.slider-material-deep-purple-A100 .noUi-handle {
  background-color: #b388ff;
}
.slider-material-deep-purple-A200 .noUi-handle {
  background-color: #7c4dff;
}
.slider-material-deep-purple-A400 .noUi-handle {
  background-color: #651fff;
}
.slider-material-deep-purple-A700 .noUi-handle {
  background-color: #6200ea;
}
.slider-material-indigo .noUi-handle {
  background-color: #3f51b5;
}
.slider-material-indigo-50 .noUi-handle {
  background-color: #e8eaf6;
}
.slider-material-indigo-100 .noUi-handle {
  background-color: #c5cae9;
}
.slider-material-indigo-200 .noUi-handle {
  background-color: #9fa8da;
}
.slider-material-indigo-300 .noUi-handle {
  background-color: #7986cb;
}
.slider-material-indigo-400 .noUi-handle {
  background-color: #5c6bc0;
}
.slider-material-indigo-500 .noUi-handle {
  background-color: #3f51b5;
}
.slider-material-indigo-600 .noUi-handle {
  background-color: #3949ab;
}
.slider-material-indigo-700 .noUi-handle {
  background-color: #303f9f;
}
.slider-material-indigo-800 .noUi-handle {
  background-color: #283593;
}
.slider-material-indigo-900 .noUi-handle {
  background-color: #1a237e;
}
.slider-material-indigo-A100 .noUi-handle {
  background-color: #8c9eff;
}
.slider-material-indigo-A200 .noUi-handle {
  background-color: #536dfe;
}
.slider-material-indigo-A400 .noUi-handle {
  background-color: #3d5afe;
}
.slider-material-indigo-A700 .noUi-handle {
  background-color: #304ffe;
}
.slider-material-blue .noUi-handle {
  background-color: #2196f3;
}
.slider-material-blue-50 .noUi-handle {
  background-color: #e3f2fd;
}
.slider-material-blue-100 .noUi-handle {
  background-color: #bbdefb;
}
.slider-material-blue-200 .noUi-handle {
  background-color: #90caf9;
}
.slider-material-blue-300 .noUi-handle {
  background-color: #64b5f6;
}
.slider-material-blue-400 .noUi-handle {
  background-color: #42a5f5;
}
.slider-material-blue-500 .noUi-handle {
  background-color: #2196f3;
}
.slider-material-blue-600 .noUi-handle {
  background-color: #1e88e5;
}
.slider-material-blue-700 .noUi-handle {
  background-color: #1976d2;
}
.slider-material-blue-800 .noUi-handle {
  background-color: #1565c0;
}
.slider-material-blue-900 .noUi-handle {
  background-color: #0d47a1;
}
.slider-material-blue-A100 .noUi-handle {
  background-color: #82b1ff;
}
.slider-material-blue-A200 .noUi-handle {
  background-color: #448aff;
}
.slider-material-blue-A400 .noUi-handle {
  background-color: #2979ff;
}
.slider-material-blue-A700 .noUi-handle {
  background-color: #2962ff;
}
.slider-material-light-blue .noUi-handle {
  background-color: #03a9f4;
}
.slider-material-light-blue-50 .noUi-handle {
  background-color: #e1f5fe;
}
.slider-material-light-blue-100 .noUi-handle {
  background-color: #b3e5fc;
}
.slider-material-light-blue-200 .noUi-handle {
  background-color: #81d4fa;
}
.slider-material-light-blue-300 .noUi-handle {
  background-color: #4fc3f7;
}
.slider-material-light-blue-400 .noUi-handle {
  background-color: #29b6f6;
}
.slider-material-light-blue-500 .noUi-handle {
  background-color: #03a9f4;
}
.slider-material-light-blue-600 .noUi-handle {
  background-color: #039be5;
}
.slider-material-light-blue-700 .noUi-handle {
  background-color: #0288d1;
}
.slider-material-light-blue-800 .noUi-handle {
  background-color: #0277bd;
}
.slider-material-light-blue-900 .noUi-handle {
  background-color: #01579b;
}
.slider-material-light-blue-A100 .noUi-handle {
  background-color: #80d8ff;
}
.slider-material-light-blue-A200 .noUi-handle {
  background-color: #40c4ff;
}
.slider-material-light-blue-A400 .noUi-handle {
  background-color: #00b0ff;
}
.slider-material-light-blue-A700 .noUi-handle {
  background-color: #0091ea;
}
.slider-material-cyan .noUi-handle {
  background-color: #00bcd4;
}
.slider-material-cyan-50 .noUi-handle {
  background-color: #e0f7fa;
}
.slider-material-cyan-100 .noUi-handle {
  background-color: #b2ebf2;
}
.slider-material-cyan-200 .noUi-handle {
  background-color: #80deea;
}
.slider-material-cyan-300 .noUi-handle {
  background-color: #4dd0e1;
}
.slider-material-cyan-400 .noUi-handle {
  background-color: #26c6da;
}
.slider-material-cyan-500 .noUi-handle {
  background-color: #00bcd4;
}
.slider-material-cyan-600 .noUi-handle {
  background-color: #00acc1;
}
.slider-material-cyan-700 .noUi-handle {
  background-color: #0097a7;
}
.slider-material-cyan-800 .noUi-handle {
  background-color: #00838f;
}
.slider-material-cyan-900 .noUi-handle {
  background-color: #006064;
}
.slider-material-cyan-A100 .noUi-handle {
  background-color: #84ffff;
}
.slider-material-cyan-A200 .noUi-handle {
  background-color: #18ffff;
}
.slider-material-cyan-A400 .noUi-handle {
  background-color: #00e5ff;
}
.slider-material-cyan-A700 .noUi-handle {
  background-color: #00b8d4;
}
.slider-material-teal .noUi-handle {
  background-color: #009688;
}
.slider-material-teal-50 .noUi-handle {
  background-color: #e0f2f1;
}
.slider-material-teal-100 .noUi-handle {
  background-color: #b2dfdb;
}
.slider-material-teal-200 .noUi-handle {
  background-color: #80cbc4;
}
.slider-material-teal-300 .noUi-handle {
  background-color: #4db6ac;
}
.slider-material-teal-400 .noUi-handle {
  background-color: #26a69a;
}
.slider-material-teal-500 .noUi-handle {
  background-color: #009688;
}
.slider-material-teal-600 .noUi-handle {
  background-color: #00897b;
}
.slider-material-teal-700 .noUi-handle {
  background-color: #00796b;
}
.slider-material-teal-800 .noUi-handle {
  background-color: #00695c;
}
.slider-material-teal-900 .noUi-handle {
  background-color: #004d40;
}
.slider-material-teal-A100 .noUi-handle {
  background-color: #a7ffeb;
}
.slider-material-teal-A200 .noUi-handle {
  background-color: #64ffda;
}
.slider-material-teal-A400 .noUi-handle {
  background-color: #1de9b6;
}
.slider-material-teal-A700 .noUi-handle {
  background-color: #00bfa5;
}
.slider-material-green .noUi-handle {
  background-color: #4caf50;
}
.slider-material-green-50 .noUi-handle {
  background-color: #e8f5e9;
}
.slider-material-green-100 .noUi-handle {
  background-color: #c8e6c9;
}
.slider-material-green-200 .noUi-handle {
  background-color: #a5d6a7;
}
.slider-material-green-300 .noUi-handle {
  background-color: #81c784;
}
.slider-material-green-400 .noUi-handle {
  background-color: #66bb6a;
}
.slider-material-green-500 .noUi-handle {
  background-color: #4caf50;
}
.slider-material-green-600 .noUi-handle {
  background-color: #43a047;
}
.slider-material-green-700 .noUi-handle {
  background-color: #388e3c;
}
.slider-material-green-800 .noUi-handle {
  background-color: #2e7d32;
}
.slider-material-green-900 .noUi-handle {
  background-color: #1b5e20;
}
.slider-material-green-A100 .noUi-handle {
  background-color: #b9f6ca;
}
.slider-material-green-A200 .noUi-handle {
  background-color: #69f0ae;
}
.slider-material-green-A400 .noUi-handle {
  background-color: #00e676;
}
.slider-material-green-A700 .noUi-handle {
  background-color: #00c853;
}
.slider-material-light-green .noUi-handle {
  background-color: #8bc34a;
}
.slider-material-light-green-50 .noUi-handle {
  background-color: #f1f8e9;
}
.slider-material-light-green-100 .noUi-handle {
  background-color: #dcedc8;
}
.slider-material-light-green-200 .noUi-handle {
  background-color: #c5e1a5;
}
.slider-material-light-green-300 .noUi-handle {
  background-color: #aed581;
}
.slider-material-light-green-400 .noUi-handle {
  background-color: #9ccc65;
}
.slider-material-light-green-500 .noUi-handle {
  background-color: #8bc34a;
}
.slider-material-light-green-600 .noUi-handle {
  background-color: #7cb342;
}
.slider-material-light-green-700 .noUi-handle {
  background-color: #689f38;
}
.slider-material-light-green-800 .noUi-handle {
  background-color: #558b2f;
}
.slider-material-light-green-900 .noUi-handle {
  background-color: #33691e;
}
.slider-material-light-green-A100 .noUi-handle {
  background-color: #ccff90;
}
.slider-material-light-green-A200 .noUi-handle {
  background-color: #b2ff59;
}
.slider-material-light-green-A400 .noUi-handle {
  background-color: #76ff03;
}
.slider-material-light-green-A700 .noUi-handle {
  background-color: #64dd17;
}
.slider-material-lime .noUi-handle {
  background-color: #cddc39;
}
.slider-material-lime-50 .noUi-handle {
  background-color: #f9fbe7;
}
.slider-material-lime-100 .noUi-handle {
  background-color: #f0f4c3;
}
.slider-material-lime-200 .noUi-handle {
  background-color: #e6ee9c;
}
.slider-material-lime-300 .noUi-handle {
  background-color: #dce775;
}
.slider-material-lime-400 .noUi-handle {
  background-color: #d4e157;
}
.slider-material-lime-500 .noUi-handle {
  background-color: #cddc39;
}
.slider-material-lime-600 .noUi-handle {
  background-color: #c0ca33;
}
.slider-material-lime-700 .noUi-handle {
  background-color: #afb42b;
}
.slider-material-lime-800 .noUi-handle {
  background-color: #9e9d24;
}
.slider-material-lime-900 .noUi-handle {
  background-color: #827717;
}
.slider-material-lime-A100 .noUi-handle {
  background-color: #f4ff81;
}
.slider-material-lime-A200 .noUi-handle {
  background-color: #eeff41;
}
.slider-material-lime-A400 .noUi-handle {
  background-color: #c6ff00;
}
.slider-material-lime-A700 .noUi-handle {
  background-color: #aeea00;
}
.slider-material-yellow .noUi-handle {
  background-color: #ffeb3b;
}
.slider-material-yellow-50 .noUi-handle {
  background-color: #fffde7;
}
.slider-material-yellow-100 .noUi-handle {
  background-color: #fff9c4;
}
.slider-material-yellow-200 .noUi-handle {
  background-color: #fff59d;
}
.slider-material-yellow-300 .noUi-handle {
  background-color: #fff176;
}
.slider-material-yellow-400 .noUi-handle {
  background-color: #ffee58;
}
.slider-material-yellow-500 .noUi-handle {
  background-color: #ffeb3b;
}
.slider-material-yellow-600 .noUi-handle {
  background-color: #fdd835;
}
.slider-material-yellow-700 .noUi-handle {
  background-color: #fbc02d;
}
.slider-material-yellow-800 .noUi-handle {
  background-color: #f9a825;
}
.slider-material-yellow-900 .noUi-handle {
  background-color: #f57f17;
}
.slider-material-yellow-A100 .noUi-handle {
  background-color: #ffff8d;
}
.slider-material-yellow-A200 .noUi-handle {
  background-color: #ffff00;
}
.slider-material-yellow-A400 .noUi-handle {
  background-color: #ffea00;
}
.slider-material-yellow-A700 .noUi-handle {
  background-color: #ffd600;
}
.slider-material-amber .noUi-handle {
  background-color: #ffc107;
}
.slider-material-amber-50 .noUi-handle {
  background-color: #fff8e1;
}
.slider-material-amber-100 .noUi-handle {
  background-color: #ffecb3;
}
.slider-material-amber-200 .noUi-handle {
  background-color: #ffe082;
}
.slider-material-amber-300 .noUi-handle {
  background-color: #ffd54f;
}
.slider-material-amber-400 .noUi-handle {
  background-color: #ffca28;
}
.slider-material-amber-500 .noUi-handle {
  background-color: #ffc107;
}
.slider-material-amber-600 .noUi-handle {
  background-color: #ffb300;
}
.slider-material-amber-700 .noUi-handle {
  background-color: #ffa000;
}
.slider-material-amber-800 .noUi-handle {
  background-color: #ff8f00;
}
.slider-material-amber-900 .noUi-handle {
  background-color: #ff6f00;
}
.slider-material-amber-A100 .noUi-handle {
  background-color: #ffe57f;
}
.slider-material-amber-A200 .noUi-handle {
  background-color: #ffd740;
}
.slider-material-amber-A400 .noUi-handle {
  background-color: #ffc400;
}
.slider-material-amber-A700 .noUi-handle {
  background-color: #ffab00;
}
.slider-material-orange .noUi-handle {
  background-color: #ff9800;
}
.slider-material-orange-50 .noUi-handle {
  background-color: #fff3e0;
}
.slider-material-orange-100 .noUi-handle {
  background-color: #ffe0b2;
}
.slider-material-orange-200 .noUi-handle {
  background-color: #ffcc80;
}
.slider-material-orange-300 .noUi-handle {
  background-color: #ffb74d;
}
.slider-material-orange-400 .noUi-handle {
  background-color: #ffa726;
}
.slider-material-orange-500 .noUi-handle {
  background-color: #ff9800;
}
.slider-material-orange-600 .noUi-handle {
  background-color: #fb8c00;
}
.slider-material-orange-700 .noUi-handle {
  background-color: #f57c00;
}
.slider-material-orange-800 .noUi-handle {
  background-color: #ef6c00;
}
.slider-material-orange-900 .noUi-handle {
  background-color: #e65100;
}
.slider-material-orange-A100 .noUi-handle {
  background-color: #ffd180;
}
.slider-material-orange-A200 .noUi-handle {
  background-color: #ffab40;
}
.slider-material-orange-A400 .noUi-handle {
  background-color: #ff9100;
}
.slider-material-orange-A700 .noUi-handle {
  background-color: #ff6d00;
}
.slider-material-deep-orange .noUi-handle {
  background-color: #ff5722;
}
.slider-material-deep-orange-50 .noUi-handle {
  background-color: #fbe9e7;
}
.slider-material-deep-orange-100 .noUi-handle {
  background-color: #ffccbc;
}
.slider-material-deep-orange-200 .noUi-handle {
  background-color: #ffab91;
}
.slider-material-deep-orange-300 .noUi-handle {
  background-color: #ff8a65;
}
.slider-material-deep-orange-400 .noUi-handle {
  background-color: #ff7043;
}
.slider-material-deep-orange-500 .noUi-handle {
  background-color: #ff5722;
}
.slider-material-deep-orange-600 .noUi-handle {
  background-color: #f4511e;
}
.slider-material-deep-orange-700 .noUi-handle {
  background-color: #e64a19;
}
.slider-material-deep-orange-800 .noUi-handle {
  background-color: #d84315;
}
.slider-material-deep-orange-900 .noUi-handle {
  background-color: #bf360c;
}
.slider-material-deep-orange-A100 .noUi-handle {
  background-color: #ff9e80;
}
.slider-material-deep-orange-A200 .noUi-handle {
  background-color: #ff6e40;
}
.slider-material-deep-orange-A400 .noUi-handle {
  background-color: #ff3d00;
}
.slider-material-deep-orange-A700 .noUi-handle {
  background-color: #dd2c00;
}
.slider-material-brown .noUi-handle {
  background-color: #795548;
}
.slider-material-brown-50 .noUi-handle {
  background-color: #efebe9;
}
.slider-material-brown-100 .noUi-handle {
  background-color: #d7ccc8;
}
.slider-material-brown-200 .noUi-handle {
  background-color: #bcaaa4;
}
.slider-material-brown-300 .noUi-handle {
  background-color: #a1887f;
}
.slider-material-brown-400 .noUi-handle {
  background-color: #8d6e63;
}
.slider-material-brown-500 .noUi-handle {
  background-color: #795548;
}
.slider-material-brown-600 .noUi-handle {
  background-color: #6d4c41;
}
.slider-material-brown-700 .noUi-handle {
  background-color: #5d4037;
}
.slider-material-brown-800 .noUi-handle {
  background-color: #4e342e;
}
.slider-material-brown-900 .noUi-handle {
  background-color: #3e2723;
}
.slider-material-brown-A100 .noUi-handle {
  background-color: #d7ccc8;
}
.slider-material-brown-A200 .noUi-handle {
  background-color: #bcaaa4;
}
.slider-material-brown-A400 .noUi-handle {
  background-color: #8d6e63;
}
.slider-material-brown-A700 .noUi-handle {
  background-color: #5d4037;
}
.slider-material-grey .noUi-handle {
  background-color: #9e9e9e;
}
.slider-material-grey-50 .noUi-handle {
  background-color: #fafafa;
}
.slider-material-grey-100 .noUi-handle {
  background-color: #f5f5f5;
}
.slider-material-grey-200 .noUi-handle {
  background-color: #eeeeee;
}
.slider-material-grey-300 .noUi-handle {
  background-color: #e0e0e0;
}
.slider-material-grey-400 .noUi-handle {
  background-color: #bdbdbd;
}
.slider-material-grey-500 .noUi-handle {
  background-color: #9e9e9e;
}
.slider-material-grey-600 .noUi-handle {
  background-color: #757575;
}
.slider-material-grey-700 .noUi-handle {
  background-color: #616161;
}
.slider-material-grey-800 .noUi-handle {
  background-color: #424242;
}
.slider-material-grey-900 .noUi-handle {
  background-color: #212121;
}
.slider-material-grey-A100 .noUi-handle {
  background-color: #f5f5f5;
}
.slider-material-grey-A200 .noUi-handle {
  background-color: #eeeeee;
}
.slider-material-grey-A400 .noUi-handle {
  background-color: #bdbdbd;
}
.slider-material-grey-A700 .noUi-handle {
  background-color: #616161;
}
.slider-material-blue-grey .noUi-handle {
  background-color: #607d8b;
}
.slider-material-blue-grey-50 .noUi-handle {
  background-color: #eceff1;
}
.slider-material-blue-grey-100 .noUi-handle {
  background-color: #cfd8dc;
}
.slider-material-blue-grey-200 .noUi-handle {
  background-color: #b0bec5;
}
.slider-material-blue-grey-300 .noUi-handle {
  background-color: #90a4ae;
}
.slider-material-blue-grey-400 .noUi-handle {
  background-color: #78909c;
}
.slider-material-blue-grey-500 .noUi-handle {
  background-color: #607d8b;
}
.slider-material-blue-grey-600 .noUi-handle {
  background-color: #546e7a;
}
.slider-material-blue-grey-700 .noUi-handle {
  background-color: #455a64;
}
.slider-material-blue-grey-800 .noUi-handle {
  background-color: #37474f;
}
.slider-material-blue-grey-900 .noUi-handle {
  background-color: #263238;
}
.slider-material-blue-grey-A100 .noUi-handle {
  background-color: #cfd8dc;
}
.slider-material-blue-grey-A200 .noUi-handle {
  background-color: #b0bec5;
}
.slider-material-blue-grey-A400 .noUi-handle {
  background-color: #78909c;
}
.slider-material-blue-grey-A700 .noUi-handle {
  background-color: #455a64;
}
.slider .noUi-handle,
.slider-default .noUi-handle {
  border-color: #009688;
}
.slider-black .noUi-handle {
  border-color: #000000;
}
.slider-white .noUi-handle {
  border-color: #ffffff;
}
.slider-inverse .noUi-handle {
  border-color: #3f51b5;
}
.slider-primary .noUi-handle {
  border-color: #009688;
}
.slider-success .noUi-handle {
  border-color: #4caf50;
}
.slider-info .noUi-handle {
  border-color: #03a9f4;
}
.slider-warning .noUi-handle {
  border-color: #ff5722;
}
.slider-danger .noUi-handle {
  border-color: #f44336;
}
.slider-material-red .noUi-handle {
  border-color: #f44336;
}
.slider-material-red-50 .noUi-handle {
  border-color: #ffebee;
}
.slider-material-red-100 .noUi-handle {
  border-color: #ffcdd2;
}
.slider-material-red-200 .noUi-handle {
  border-color: #ef9a9a;
}
.slider-material-red-300 .noUi-handle {
  border-color: #e57373;
}
.slider-material-red-400 .noUi-handle {
  border-color: #ef5350;
}
.slider-material-red-500 .noUi-handle {
  border-color: #f44336;
}
.slider-material-red-600 .noUi-handle {
  border-color: #e53935;
}
.slider-material-red-700 .noUi-handle {
  border-color: #d32f2f;
}
.slider-material-red-800 .noUi-handle {
  border-color: #c62828;
}
.slider-material-red-900 .noUi-handle {
  border-color: #b71c1c;
}
.slider-material-red-A100 .noUi-handle {
  border-color: #ff8a80;
}
.slider-material-red-A200 .noUi-handle {
  border-color: #ff5252;
}
.slider-material-red-A400 .noUi-handle {
  border-color: #ff1744;
}
.slider-material-red-A700 .noUi-handle {
  border-color: #d50000;
}
.slider-material-pink .noUi-handle {
  border-color: #e91e63;
}
.slider-material-pink-50 .noUi-handle {
  border-color: #fce4ec;
}
.slider-material-pink-100 .noUi-handle {
  border-color: #f8bbd0;
}
.slider-material-pink-200 .noUi-handle {
  border-color: #f48fb1;
}
.slider-material-pink-300 .noUi-handle {
  border-color: #f06292;
}
.slider-material-pink-400 .noUi-handle {
  border-color: #ec407a;
}
.slider-material-pink-500 .noUi-handle {
  border-color: #e91e63;
}
.slider-material-pink-600 .noUi-handle {
  border-color: #d81b60;
}
.slider-material-pink-700 .noUi-handle {
  border-color: #c2185b;
}
.slider-material-pink-800 .noUi-handle {
  border-color: #ad1457;
}
.slider-material-pink-900 .noUi-handle {
  border-color: #880e4f;
}
.slider-material-pink-A100 .noUi-handle {
  border-color: #ff80ab;
}
.slider-material-pink-A200 .noUi-handle {
  border-color: #ff4081;
}
.slider-material-pink-A400 .noUi-handle {
  border-color: #f50057;
}
.slider-material-pink-A700 .noUi-handle {
  border-color: #c51162;
}
.slider-material-purple .noUi-handle {
  border-color: #9c27b0;
}
.slider-material-purple-50 .noUi-handle {
  border-color: #f3e5f5;
}
.slider-material-purple-100 .noUi-handle {
  border-color: #e1bee7;
}
.slider-material-purple-200 .noUi-handle {
  border-color: #ce93d8;
}
.slider-material-purple-300 .noUi-handle {
  border-color: #ba68c8;
}
.slider-material-purple-400 .noUi-handle {
  border-color: #ab47bc;
}
.slider-material-purple-500 .noUi-handle {
  border-color: #9c27b0;
}
.slider-material-purple-600 .noUi-handle {
  border-color: #8e24aa;
}
.slider-material-purple-700 .noUi-handle {
  border-color: #7b1fa2;
}
.slider-material-purple-800 .noUi-handle {
  border-color: #6a1b9a;
}
.slider-material-purple-900 .noUi-handle {
  border-color: #4a148c;
}
.slider-material-purple-A100 .noUi-handle {
  border-color: #ea80fc;
}
.slider-material-purple-A200 .noUi-handle {
  border-color: #e040fb;
}
.slider-material-purple-A400 .noUi-handle {
  border-color: #d500f9;
}
.slider-material-purple-A700 .noUi-handle {
  border-color: #aa00ff;
}
.slider-material-deep-purple .noUi-handle {
  border-color: #673ab7;
}
.slider-material-deep-purple-50 .noUi-handle {
  border-color: #ede7f6;
}
.slider-material-deep-purple-100 .noUi-handle {
  border-color: #d1c4e9;
}
.slider-material-deep-purple-200 .noUi-handle {
  border-color: #b39ddb;
}
.slider-material-deep-purple-300 .noUi-handle {
  border-color: #9575cd;
}
.slider-material-deep-purple-400 .noUi-handle {
  border-color: #7e57c2;
}
.slider-material-deep-purple-500 .noUi-handle {
  border-color: #673ab7;
}
.slider-material-deep-purple-600 .noUi-handle {
  border-color: #5e35b1;
}
.slider-material-deep-purple-700 .noUi-handle {
  border-color: #512da8;
}
.slider-material-deep-purple-800 .noUi-handle {
  border-color: #4527a0;
}
.slider-material-deep-purple-900 .noUi-handle {
  border-color: #311b92;
}
.slider-material-deep-purple-A100 .noUi-handle {
  border-color: #b388ff;
}
.slider-material-deep-purple-A200 .noUi-handle {
  border-color: #7c4dff;
}
.slider-material-deep-purple-A400 .noUi-handle {
  border-color: #651fff;
}
.slider-material-deep-purple-A700 .noUi-handle {
  border-color: #6200ea;
}
.slider-material-indigo .noUi-handle {
  border-color: #3f51b5;
}
.slider-material-indigo-50 .noUi-handle {
  border-color: #e8eaf6;
}
.slider-material-indigo-100 .noUi-handle {
  border-color: #c5cae9;
}
.slider-material-indigo-200 .noUi-handle {
  border-color: #9fa8da;
}
.slider-material-indigo-300 .noUi-handle {
  border-color: #7986cb;
}
.slider-material-indigo-400 .noUi-handle {
  border-color: #5c6bc0;
}
.slider-material-indigo-500 .noUi-handle {
  border-color: #3f51b5;
}
.slider-material-indigo-600 .noUi-handle {
  border-color: #3949ab;
}
.slider-material-indigo-700 .noUi-handle {
  border-color: #303f9f;
}
.slider-material-indigo-800 .noUi-handle {
  border-color: #283593;
}
.slider-material-indigo-900 .noUi-handle {
  border-color: #1a237e;
}
.slider-material-indigo-A100 .noUi-handle {
  border-color: #8c9eff;
}
.slider-material-indigo-A200 .noUi-handle {
  border-color: #536dfe;
}
.slider-material-indigo-A400 .noUi-handle {
  border-color: #3d5afe;
}
.slider-material-indigo-A700 .noUi-handle {
  border-color: #304ffe;
}
.slider-material-blue .noUi-handle {
  border-color: #2196f3;
}
.slider-material-blue-50 .noUi-handle {
  border-color: #e3f2fd;
}
.slider-material-blue-100 .noUi-handle {
  border-color: #bbdefb;
}
.slider-material-blue-200 .noUi-handle {
  border-color: #90caf9;
}
.slider-material-blue-300 .noUi-handle {
  border-color: #64b5f6;
}
.slider-material-blue-400 .noUi-handle {
  border-color: #42a5f5;
}
.slider-material-blue-500 .noUi-handle {
  border-color: #2196f3;
}
.slider-material-blue-600 .noUi-handle {
  border-color: #1e88e5;
}
.slider-material-blue-700 .noUi-handle {
  border-color: #1976d2;
}
.slider-material-blue-800 .noUi-handle {
  border-color: #1565c0;
}
.slider-material-blue-900 .noUi-handle {
  border-color: #0d47a1;
}
.slider-material-blue-A100 .noUi-handle {
  border-color: #82b1ff;
}
.slider-material-blue-A200 .noUi-handle {
  border-color: #448aff;
}
.slider-material-blue-A400 .noUi-handle {
  border-color: #2979ff;
}
.slider-material-blue-A700 .noUi-handle {
  border-color: #2962ff;
}
.slider-material-light-blue .noUi-handle {
  border-color: #03a9f4;
}
.slider-material-light-blue-50 .noUi-handle {
  border-color: #e1f5fe;
}
.slider-material-light-blue-100 .noUi-handle {
  border-color: #b3e5fc;
}
.slider-material-light-blue-200 .noUi-handle {
  border-color: #81d4fa;
}
.slider-material-light-blue-300 .noUi-handle {
  border-color: #4fc3f7;
}
.slider-material-light-blue-400 .noUi-handle {
  border-color: #29b6f6;
}
.slider-material-light-blue-500 .noUi-handle {
  border-color: #03a9f4;
}
.slider-material-light-blue-600 .noUi-handle {
  border-color: #039be5;
}
.slider-material-light-blue-700 .noUi-handle {
  border-color: #0288d1;
}
.slider-material-light-blue-800 .noUi-handle {
  border-color: #0277bd;
}
.slider-material-light-blue-900 .noUi-handle {
  border-color: #01579b;
}
.slider-material-light-blue-A100 .noUi-handle {
  border-color: #80d8ff;
}
.slider-material-light-blue-A200 .noUi-handle {
  border-color: #40c4ff;
}
.slider-material-light-blue-A400 .noUi-handle {
  border-color: #00b0ff;
}
.slider-material-light-blue-A700 .noUi-handle {
  border-color: #0091ea;
}
.slider-material-cyan .noUi-handle {
  border-color: #00bcd4;
}
.slider-material-cyan-50 .noUi-handle {
  border-color: #e0f7fa;
}
.slider-material-cyan-100 .noUi-handle {
  border-color: #b2ebf2;
}
.slider-material-cyan-200 .noUi-handle {
  border-color: #80deea;
}
.slider-material-cyan-300 .noUi-handle {
  border-color: #4dd0e1;
}
.slider-material-cyan-400 .noUi-handle {
  border-color: #26c6da;
}
.slider-material-cyan-500 .noUi-handle {
  border-color: #00bcd4;
}
.slider-material-cyan-600 .noUi-handle {
  border-color: #00acc1;
}
.slider-material-cyan-700 .noUi-handle {
  border-color: #0097a7;
}
.slider-material-cyan-800 .noUi-handle {
  border-color: #00838f;
}
.slider-material-cyan-900 .noUi-handle {
  border-color: #006064;
}
.slider-material-cyan-A100 .noUi-handle {
  border-color: #84ffff;
}
.slider-material-cyan-A200 .noUi-handle {
  border-color: #18ffff;
}
.slider-material-cyan-A400 .noUi-handle {
  border-color: #00e5ff;
}
.slider-material-cyan-A700 .noUi-handle {
  border-color: #00b8d4;
}
.slider-material-teal .noUi-handle {
  border-color: #009688;
}
.slider-material-teal-50 .noUi-handle {
  border-color: #e0f2f1;
}
.slider-material-teal-100 .noUi-handle {
  border-color: #b2dfdb;
}
.slider-material-teal-200 .noUi-handle {
  border-color: #80cbc4;
}
.slider-material-teal-300 .noUi-handle {
  border-color: #4db6ac;
}
.slider-material-teal-400 .noUi-handle {
  border-color: #26a69a;
}
.slider-material-teal-500 .noUi-handle {
  border-color: #009688;
}
.slider-material-teal-600 .noUi-handle {
  border-color: #00897b;
}
.slider-material-teal-700 .noUi-handle {
  border-color: #00796b;
}
.slider-material-teal-800 .noUi-handle {
  border-color: #00695c;
}
.slider-material-teal-900 .noUi-handle {
  border-color: #004d40;
}
.slider-material-teal-A100 .noUi-handle {
  border-color: #a7ffeb;
}
.slider-material-teal-A200 .noUi-handle {
  border-color: #64ffda;
}
.slider-material-teal-A400 .noUi-handle {
  border-color: #1de9b6;
}
.slider-material-teal-A700 .noUi-handle {
  border-color: #00bfa5;
}
.slider-material-green .noUi-handle {
  border-color: #4caf50;
}
.slider-material-green-50 .noUi-handle {
  border-color: #e8f5e9;
}
.slider-material-green-100 .noUi-handle {
  border-color: #c8e6c9;
}
.slider-material-green-200 .noUi-handle {
  border-color: #a5d6a7;
}
.slider-material-green-300 .noUi-handle {
  border-color: #81c784;
}
.slider-material-green-400 .noUi-handle {
  border-color: #66bb6a;
}
.slider-material-green-500 .noUi-handle {
  border-color: #4caf50;
}
.slider-material-green-600 .noUi-handle {
  border-color: #43a047;
}
.slider-material-green-700 .noUi-handle {
  border-color: #388e3c;
}
.slider-material-green-800 .noUi-handle {
  border-color: #2e7d32;
}
.slider-material-green-900 .noUi-handle {
  border-color: #1b5e20;
}
.slider-material-green-A100 .noUi-handle {
  border-color: #b9f6ca;
}
.slider-material-green-A200 .noUi-handle {
  border-color: #69f0ae;
}
.slider-material-green-A400 .noUi-handle {
  border-color: #00e676;
}
.slider-material-green-A700 .noUi-handle {
  border-color: #00c853;
}
.slider-material-light-green .noUi-handle {
  border-color: #8bc34a;
}
.slider-material-light-green-50 .noUi-handle {
  border-color: #f1f8e9;
}
.slider-material-light-green-100 .noUi-handle {
  border-color: #dcedc8;
}
.slider-material-light-green-200 .noUi-handle {
  border-color: #c5e1a5;
}
.slider-material-light-green-300 .noUi-handle {
  border-color: #aed581;
}
.slider-material-light-green-400 .noUi-handle {
  border-color: #9ccc65;
}
.slider-material-light-green-500 .noUi-handle {
  border-color: #8bc34a;
}
.slider-material-light-green-600 .noUi-handle {
  border-color: #7cb342;
}
.slider-material-light-green-700 .noUi-handle {
  border-color: #689f38;
}
.slider-material-light-green-800 .noUi-handle {
  border-color: #558b2f;
}
.slider-material-light-green-900 .noUi-handle {
  border-color: #33691e;
}
.slider-material-light-green-A100 .noUi-handle {
  border-color: #ccff90;
}
.slider-material-light-green-A200 .noUi-handle {
  border-color: #b2ff59;
}
.slider-material-light-green-A400 .noUi-handle {
  border-color: #76ff03;
}
.slider-material-light-green-A700 .noUi-handle {
  border-color: #64dd17;
}
.slider-material-lime .noUi-handle {
  border-color: #cddc39;
}
.slider-material-lime-50 .noUi-handle {
  border-color: #f9fbe7;
}
.slider-material-lime-100 .noUi-handle {
  border-color: #f0f4c3;
}
.slider-material-lime-200 .noUi-handle {
  border-color: #e6ee9c;
}
.slider-material-lime-300 .noUi-handle {
  border-color: #dce775;
}
.slider-material-lime-400 .noUi-handle {
  border-color: #d4e157;
}
.slider-material-lime-500 .noUi-handle {
  border-color: #cddc39;
}
.slider-material-lime-600 .noUi-handle {
  border-color: #c0ca33;
}
.slider-material-lime-700 .noUi-handle {
  border-color: #afb42b;
}
.slider-material-lime-800 .noUi-handle {
  border-color: #9e9d24;
}
.slider-material-lime-900 .noUi-handle {
  border-color: #827717;
}
.slider-material-lime-A100 .noUi-handle {
  border-color: #f4ff81;
}
.slider-material-lime-A200 .noUi-handle {
  border-color: #eeff41;
}
.slider-material-lime-A400 .noUi-handle {
  border-color: #c6ff00;
}
.slider-material-lime-A700 .noUi-handle {
  border-color: #aeea00;
}
.slider-material-yellow .noUi-handle {
  border-color: #ffeb3b;
}
.slider-material-yellow-50 .noUi-handle {
  border-color: #fffde7;
}
.slider-material-yellow-100 .noUi-handle {
  border-color: #fff9c4;
}
.slider-material-yellow-200 .noUi-handle {
  border-color: #fff59d;
}
.slider-material-yellow-300 .noUi-handle {
  border-color: #fff176;
}
.slider-material-yellow-400 .noUi-handle {
  border-color: #ffee58;
}
.slider-material-yellow-500 .noUi-handle {
  border-color: #ffeb3b;
}
.slider-material-yellow-600 .noUi-handle {
  border-color: #fdd835;
}
.slider-material-yellow-700 .noUi-handle {
  border-color: #fbc02d;
}
.slider-material-yellow-800 .noUi-handle {
  border-color: #f9a825;
}
.slider-material-yellow-900 .noUi-handle {
  border-color: #f57f17;
}
.slider-material-yellow-A100 .noUi-handle {
  border-color: #ffff8d;
}
.slider-material-yellow-A200 .noUi-handle {
  border-color: #ffff00;
}
.slider-material-yellow-A400 .noUi-handle {
  border-color: #ffea00;
}
.slider-material-yellow-A700 .noUi-handle {
  border-color: #ffd600;
}
.slider-material-amber .noUi-handle {
  border-color: #ffc107;
}
.slider-material-amber-50 .noUi-handle {
  border-color: #fff8e1;
}
.slider-material-amber-100 .noUi-handle {
  border-color: #ffecb3;
}
.slider-material-amber-200 .noUi-handle {
  border-color: #ffe082;
}
.slider-material-amber-300 .noUi-handle {
  border-color: #ffd54f;
}
.slider-material-amber-400 .noUi-handle {
  border-color: #ffca28;
}
.slider-material-amber-500 .noUi-handle {
  border-color: #ffc107;
}
.slider-material-amber-600 .noUi-handle {
  border-color: #ffb300;
}
.slider-material-amber-700 .noUi-handle {
  border-color: #ffa000;
}
.slider-material-amber-800 .noUi-handle {
  border-color: #ff8f00;
}
.slider-material-amber-900 .noUi-handle {
  border-color: #ff6f00;
}
.slider-material-amber-A100 .noUi-handle {
  border-color: #ffe57f;
}
.slider-material-amber-A200 .noUi-handle {
  border-color: #ffd740;
}
.slider-material-amber-A400 .noUi-handle {
  border-color: #ffc400;
}
.slider-material-amber-A700 .noUi-handle {
  border-color: #ffab00;
}
.slider-material-orange .noUi-handle {
  border-color: #ff9800;
}
.slider-material-orange-50 .noUi-handle {
  border-color: #fff3e0;
}
.slider-material-orange-100 .noUi-handle {
  border-color: #ffe0b2;
}
.slider-material-orange-200 .noUi-handle {
  border-color: #ffcc80;
}
.slider-material-orange-300 .noUi-handle {
  border-color: #ffb74d;
}
.slider-material-orange-400 .noUi-handle {
  border-color: #ffa726;
}
.slider-material-orange-500 .noUi-handle {
  border-color: #ff9800;
}
.slider-material-orange-600 .noUi-handle {
  border-color: #fb8c00;
}
.slider-material-orange-700 .noUi-handle {
  border-color: #f57c00;
}
.slider-material-orange-800 .noUi-handle {
  border-color: #ef6c00;
}
.slider-material-orange-900 .noUi-handle {
  border-color: #e65100;
}
.slider-material-orange-A100 .noUi-handle {
  border-color: #ffd180;
}
.slider-material-orange-A200 .noUi-handle {
  border-color: #ffab40;
}
.slider-material-orange-A400 .noUi-handle {
  border-color: #ff9100;
}
.slider-material-orange-A700 .noUi-handle {
  border-color: #ff6d00;
}
.slider-material-deep-orange .noUi-handle {
  border-color: #ff5722;
}
.slider-material-deep-orange-50 .noUi-handle {
  border-color: #fbe9e7;
}
.slider-material-deep-orange-100 .noUi-handle {
  border-color: #ffccbc;
}
.slider-material-deep-orange-200 .noUi-handle {
  border-color: #ffab91;
}
.slider-material-deep-orange-300 .noUi-handle {
  border-color: #ff8a65;
}
.slider-material-deep-orange-400 .noUi-handle {
  border-color: #ff7043;
}
.slider-material-deep-orange-500 .noUi-handle {
  border-color: #ff5722;
}
.slider-material-deep-orange-600 .noUi-handle {
  border-color: #f4511e;
}
.slider-material-deep-orange-700 .noUi-handle {
  border-color: #e64a19;
}
.slider-material-deep-orange-800 .noUi-handle {
  border-color: #d84315;
}
.slider-material-deep-orange-900 .noUi-handle {
  border-color: #bf360c;
}
.slider-material-deep-orange-A100 .noUi-handle {
  border-color: #ff9e80;
}
.slider-material-deep-orange-A200 .noUi-handle {
  border-color: #ff6e40;
}
.slider-material-deep-orange-A400 .noUi-handle {
  border-color: #ff3d00;
}
.slider-material-deep-orange-A700 .noUi-handle {
  border-color: #dd2c00;
}
.slider-material-brown .noUi-handle {
  border-color: #795548;
}
.slider-material-brown-50 .noUi-handle {
  border-color: #efebe9;
}
.slider-material-brown-100 .noUi-handle {
  border-color: #d7ccc8;
}
.slider-material-brown-200 .noUi-handle {
  border-color: #bcaaa4;
}
.slider-material-brown-300 .noUi-handle {
  border-color: #a1887f;
}
.slider-material-brown-400 .noUi-handle {
  border-color: #8d6e63;
}
.slider-material-brown-500 .noUi-handle {
  border-color: #795548;
}
.slider-material-brown-600 .noUi-handle {
  border-color: #6d4c41;
}
.slider-material-brown-700 .noUi-handle {
  border-color: #5d4037;
}
.slider-material-brown-800 .noUi-handle {
  border-color: #4e342e;
}
.slider-material-brown-900 .noUi-handle {
  border-color: #3e2723;
}
.slider-material-brown-A100 .noUi-handle {
  border-color: #d7ccc8;
}
.slider-material-brown-A200 .noUi-handle {
  border-color: #bcaaa4;
}
.slider-material-brown-A400 .noUi-handle {
  border-color: #8d6e63;
}
.slider-material-brown-A700 .noUi-handle {
  border-color: #5d4037;
}
.slider-material-grey .noUi-handle {
  border-color: #9e9e9e;
}
.slider-material-grey-50 .noUi-handle {
  border-color: #fafafa;
}
.slider-material-grey-100 .noUi-handle {
  border-color: #f5f5f5;
}
.slider-material-grey-200 .noUi-handle {
  border-color: #eeeeee;
}
.slider-material-grey-300 .noUi-handle {
  border-color: #e0e0e0;
}
.slider-material-grey-400 .noUi-handle {
  border-color: #bdbdbd;
}
.slider-material-grey-500 .noUi-handle {
  border-color: #9e9e9e;
}
.slider-material-grey-600 .noUi-handle {
  border-color: #757575;
}
.slider-material-grey-700 .noUi-handle {
  border-color: #616161;
}
.slider-material-grey-800 .noUi-handle {
  border-color: #424242;
}
.slider-material-grey-900 .noUi-handle {
  border-color: #212121;
}
.slider-material-grey-A100 .noUi-handle {
  border-color: #f5f5f5;
}
.slider-material-grey-A200 .noUi-handle {
  border-color: #eeeeee;
}
.slider-material-grey-A400 .noUi-handle {
  border-color: #bdbdbd;
}
.slider-material-grey-A700 .noUi-handle {
  border-color: #616161;
}
.slider-material-blue-grey .noUi-handle {
  border-color: #607d8b;
}
.slider-material-blue-grey-50 .noUi-handle {
  border-color: #eceff1;
}
.slider-material-blue-grey-100 .noUi-handle {
  border-color: #cfd8dc;
}
.slider-material-blue-grey-200 .noUi-handle {
  border-color: #b0bec5;
}
.slider-material-blue-grey-300 .noUi-handle {
  border-color: #90a4ae;
}
.slider-material-blue-grey-400 .noUi-handle {
  border-color: #78909c;
}
.slider-material-blue-grey-500 .noUi-handle {
  border-color: #607d8b;
}
.slider-material-blue-grey-600 .noUi-handle {
  border-color: #546e7a;
}
.slider-material-blue-grey-700 .noUi-handle {
  border-color: #455a64;
}
.slider-material-blue-grey-800 .noUi-handle {
  border-color: #37474f;
}
.slider-material-blue-grey-900 .noUi-handle {
  border-color: #263238;
}
.slider-material-blue-grey-A100 .noUi-handle {
  border-color: #cfd8dc;
}
.slider-material-blue-grey-A200 .noUi-handle {
  border-color: #b0bec5;
}
.slider-material-blue-grey-A400 .noUi-handle {
  border-color: #78909c;
}
.slider-material-blue-grey-A700 .noUi-handle {
  border-color: #455a64;
}
.selectize-control.single,
.selectize-control.multi {
  padding: 0;
}
.selectize-control.single .selectize-input,
.selectize-control.multi .selectize-input,
.selectize-control.single .selectize-input.input-active,
.selectize-control.multi .selectize-input.input-active {
  cursor: text;
  background: transparent;
  box-shadow: none;
  border: 0;
  padding: 0;
  height: 100%;
  font-size: 14px;
  line-height: 30px;
}
.selectize-control.single .selectize-input .has-items,
.selectize-control.multi .selectize-input .has-items,
.selectize-control.single .selectize-input.input-active .has-items,
.selectize-control.multi .selectize-input.input-active .has-items {
  padding: 0;
}
.selectize-control.single .selectize-input:after,
.selectize-control.multi .selectize-input:after,
.selectize-control.single .selectize-input.input-active:after,
.selectize-control.multi .selectize-input.input-active:after {
  right: 5px;
  position: absolute;
  font-size: 7px;
  content: "\e894";
  font-family: "Material-Design-Icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.selectize-control.single .selectize-input input,
.selectize-control.multi .selectize-input input,
.selectize-control.single .selectize-input.input-active input,
.selectize-control.multi .selectize-input.input-active input {
  font-size: 14px;
  outline: 0px;
  border: 0px;
  background: transparent;
}
.selectize-control.single .selectize-input.label-floating-fix input,
.selectize-control.multi .selectize-input.label-floating-fix input,
.selectize-control.single .selectize-input.input-active.label-floating-fix input,
.selectize-control.multi .selectize-input.input-active.label-floating-fix input {
  opacity: 0;
}
.selectize-control.single .selectize-input > div,
.selectize-control.multi .selectize-input > div,
.selectize-control.single .selectize-input.input-active > div,
.selectize-control.multi .selectize-input.input-active > div,
.selectize-control.single .selectize-input > .item,
.selectize-control.multi .selectize-input > .item,
.selectize-control.single .selectize-input.input-active > .item,
.selectize-control.multi .selectize-input.input-active > .item {
  display: inline-block;
  margin: 0 8px 3px 0;
  padding: 0;
  background: transparent;
  border: 0;
}
.selectize-control.single .selectize-input > div:after,
.selectize-control.multi .selectize-input > div:after,
.selectize-control.single .selectize-input.input-active > div:after,
.selectize-control.multi .selectize-input.input-active > div:after,
.selectize-control.single .selectize-input > .item:after,
.selectize-control.multi .selectize-input > .item:after,
.selectize-control.single .selectize-input.input-active > .item:after,
.selectize-control.multi .selectize-input.input-active > .item:after {
  content: ",";
}
.selectize-control.single .selectize-input > div:last-of-type:after,
.selectize-control.multi .selectize-input > div:last-of-type:after,
.selectize-control.single .selectize-input.input-active > div:last-of-type:after,
.selectize-control.multi .selectize-input.input-active > div:last-of-type:after,
.selectize-control.single .selectize-input > .item:last-of-type:after,
.selectize-control.multi .selectize-input > .item:last-of-type:after,
.selectize-control.single .selectize-input.input-active > .item:last-of-type:after,
.selectize-control.multi .selectize-input.input-active > .item:last-of-type:after {
  content: "";
}
.selectize-control.single .selectize-input > div.active,
.selectize-control.multi .selectize-input > div.active,
.selectize-control.single .selectize-input.input-active > div.active,
.selectize-control.multi .selectize-input.input-active > div.active,
.selectize-control.single .selectize-input > .item.active,
.selectize-control.multi .selectize-input > .item.active,
.selectize-control.single .selectize-input.input-active > .item.active,
.selectize-control.multi .selectize-input.input-active > .item.active {
  font-weight: bold;
  background: transparent;
  border: 0;
}
.selectize-control.single .selectize-dropdown,
.selectize-control.multi .selectize-dropdown {
  position: absolute;
  z-index: 1000;
  border: 0;
  width: 100% !important;
  left: 0 !important;
  height: auto;
  background-color: #FFF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  padding: 0;
  margin-top: 3px;
}
.selectize-control.single .selectize-dropdown .active,
.selectize-control.multi .selectize-dropdown .active {
  background-color: inherit;
}
.selectize-control.single .selectize-dropdown .highlight,
.selectize-control.multi .selectize-dropdown .highlight {
  background-color: #d5d8ff;
}
.selectize-control.single .selectize-dropdown .selected,
.selectize-control.multi .selectize-dropdown .selected,
.selectize-control.single .selectize-dropdown .selected.active,
.selectize-control.multi .selectize-dropdown .selected.active {
  background-color: #EEEEEE;
}
.selectize-control.single .selectize-dropdown [data-selectable],
.selectize-control.multi .selectize-dropdown [data-selectable],
.selectize-control.single .selectize-dropdown .optgroup-header,
.selectize-control.multi .selectize-dropdown .optgroup-header {
  padding: 10px 20px;
  cursor: pointer;
}
.selectize-control.single .dropdown-active ~ .selectize-dropdown,
.selectize-control.multi .dropdown-active ~ .selectize-dropdown {
  display: block;
}
.dropdownjs:after {
  right: 5px;
  top: 3px;
  font-size: 25px;
  position: absolute;
  content: "\e8ac";
  font-family: "Material-Design-Icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  pointer-events: none;
  color: #757575;
}
/*# sourceMappingURL=material-fullpalette.css.map */